import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Row, Col } from "react-bootstrap";
import Popup from "./Popup";
import { FormGroup, FormWrapper } from "../";
import { useForm } from "react-hook-form";
import { CallUs } from "../../modules/Home/home.slice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import _ from "lodash";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { Textbox, ContactFn, brokerEmailFunction } from "components";
import { useMediaPredicate } from "react-media-hook";
import ReactGA from "react-ga4";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const mobileValidation = yup.object({
  name: yup
    .string()
    .required("Name is Required")
    .matches(/^([A-Za-z\s])+$/, "Must contain only alphabets"),
  email: yup
    .string()
    .email("Please enter valid email id")
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, "Please enter proper Email"),
  mobileNo: yup
    .string()
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(10, "Mobile No. should be 10 digits")
    .max(10, "Mobile No. should be 10 digits")
    .matches(/^[6-9][0-9]{9}$/, "Not valid number")
    .test("invalid", "Not valid number", (value) => {
      return !/^[9]{10}$/.test(value);
    }),
});

const CallMe = ({ show, onClose }) => {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    resolver: yupResolver(mobileValidation),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { temp_data } = useSelector((state) => state.proposal);
  const { temp_data: userDataHome, theme_conf } = useSelector(
    (state) => state.home
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const lessthan993 = useMediaPredicate("(max-width:993px)");
  const lessthan414 = useMediaPredicate("(max-width:414px)");
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const [msg, setMsg] = useState(false);

  const onSubmit = (data) => {
    setMsg(true);
    dispatch(
      CallUs({
        contactName: data.name,
        contactNo: data.mobileNo,
        email: data.email,
        enquiry_id: enquiry_id,
        link: window.location.href,
      })
    );

    setTimeout(() => {
      onClose(false);
      setMsg(false);
    }, 2500);
  };

  //prefill
  useEffect(() => {
    // name
    (userDataHome?.firstName ||
      userDataHome?.userProposal?.additonalData?.owner?.firstName ||
      temp_data?.userProposal?.additonalData?.owner?.email) &&
      setValue(
        "name",
        userDataHome?.firstName
          ? !userDataHome?.lastName
            ? userDataHome?.firstName
            : userDataHome?.firstName + " " + userDataHome?.lastName
          : userDataHome?.userProposal?.additonalData?.owner?.firstName
          ? // ? _.isEmpty(userDataHome?.userProposal?.additonalData?.owner?.firstName) ? "" :
            !userDataHome?.userProposal?.additonalData?.owner?.lastName
            ? userDataHome?.userProposal?.additonalData?.owner?.firstName
            : userDataHome?.userProposal?.additonalData?.owner?.firstName +
              " " +
              userDataHome?.userProposal?.additonalData?.owner?.lastName
          : temp_data?.userProposal?.additonalData?.owner?.firstName
          ? !temp_data?.userProposal?.additonalData?.owner?.lastNameName
            ? temp_data?.userProposal?.additonalData?.owner?.firstName
            : temp_data?.userProposal?.additonalData?.owner?.firstName +
              " " +
              temp_data?.userProposal?.additonalData?.owner?.lastNameName
          : ""
      );
    // email
    (userDataHome?.emailId ||
      userDataHome?.userProposal?.additonalData?.owner?.email ||
      temp_data?.userProposal?.additonalData?.owner?.email) &&
      setValue(
        "email",
        userDataHome?.emailId
          ? userDataHome?.emailId
          : userDataHome?.userProposal?.additonalData?.owner?.email
          ? userDataHome?.userProposal?.additonalData?.owner?.email
          : temp_data?.userProposal?.additonalData?.owner?.email
      );

    // mobile no
    (userDataHome?.mobileNo ||
      userDataHome?.userProposal?.additonalData?.owner?.mobileNumber ||
      temp_data?.userProposal?.additonalData?.owner?.mobileNumber) &&
      setValue(
        "mobileNo",
        userDataHome?.mobileNo
          ? userDataHome?.mobileNo
          : userDataHome?.userProposal?.additonalData?.owner?.mobileNumber
          ? userDataHome?.userProposal?.additonalData?.owner?.mobileNumber
          : temp_data?.userProposal?.additonalData?.owner?.mobileNumber
      );
  }, [userDataHome]);

  // GA Event Throw
  useEffect(() => {
    if (show) {
      process.env.REACT_APP_PROD !== "YES" &&
        process.env.REACT_APP_BROKER === "BAJAJ" &&
        process.env.REACT_APP_BASENAME !== "NA" &&
        ReactGA.event({
          category: "request_call_back_form_click",
          event: "request_call_back_form_click",
          action: "Click - Call me",
          action_type: "Click - Call me",
          business_lob: "Insurance",
          journey_status: "Feedback Stage",
        });
    }
  }, [show]);

  const content2 = (
    <MessageContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#4fcc6e"
        width="48px"
        height="48px"
      >
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
      </svg>
      <CallText>
        One of our experts will get back to you within few minutes
      </CallText>
    </MessageContainer>
  );
  const content1 = (
    <>
      <Content1>
        <Heading>NEED HELP?</Heading>
        <ContactText>
          Submit your details and we'll reach out supersoon!
        </ContactText>

        <form
          style={{ display: "flex", justifyContent: "center" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormWrapper
            style={{
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "80%",
            }}
          >
            <div className="w-100">
              <Textbox
                nonCircular
                md
                type="text"
                id="name"
                fieldName="Name"
                onInput={(e) =>
                  (e.target.value =
                    e.target.value.length <= 1
                      ? ("" + e.target.value).toUpperCase()
                      : e.target.value)
                }
                name="name"
                placeholder=" "
                register={register}
                error={errors?.name}
                fontWeight="bold"
              />
            </div>
            <div className="w-100">
              <Textbox
                style={{ marginBottom: "20px" }}
                nonCircular
                md
                type="tel"
                id="mobileNo"
                fieldName="Mobile No."
                name="mobileNo"
                placeholder=" "
                register={register}
                error={errors?.mobileNo}
                maxLength="10"
                fontWeight="bold"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9-/]/g, ""))
                }
              />
            </div>
            <div className="w-100">
              <Textbox
                nonCircular
                md
                type="email"
                id="email"
                fieldName="Email"
                name="email"
                placeholder=" "
                register={register}
                error={errors?.email}
                fontWeight="bold"
              />
            </div>
            <FormGroup
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "70px",
                width: "90%",
                flexDirection: "column",
              }}
              md
            >
              <Button
                type="submit"
                disabled={
                  (!watch("email") && !watch("mobileNo")) ||
                  errors?.name ||
                  (watch("email") && errors?.email) ||
                  (watch("mobileNo") && errors?.mobileNo)
                }
                style={{
                  width: lessthan414 && "80%",
                  height: "40px",
                  marginBottom: "15px",
                }}
              >
                {process.env?.REACT_APP_BROKER === "SRIDHAR"
                  ? "Call Me"
                  : "Contact Me"}
              </Button>
              {process.env.REACT_APP_BROKER !== "UIB" && (
                <>
                  {" "}
                  <ORLine>OR</ORLine>
                  <h4
                    style={{ display: "block", fontSize: "1rem" }}
                    className="text-secondary"
                  >
                    For immediate assistance call us at
                  </h4>
                  <p>
                    <b style={{ color: "black", fontSize: "16px" }}>
                      {theme_conf?.broker_config?.phone || ContactFn()}
                    </b>
                  </p>
                </>
              )}
              <ORLine>OR</ORLine>
              <h4
                style={{ display: "block", fontSize: "1rem" }}
                className="text-secondary"
              >
                {process.env.REACT_APP_BROKER === "UIB"
                  ? "For immediate assistance email us at"
                  : "You can reach us at"}
              </h4>
              <p>
                <b
                  style={{
                    color:
                      process.env.REACT_APP_BROKER === "UIB"
                        ? "#0067b1"
                        : "black",
                    fontSize: "16px",
                  }}
                >
                  {theme_conf?.broker_config?.email || brokerEmailFunction()}
                </b>
              </p>
            </FormGroup>
          </FormWrapper>
        </form>
      </Content1>
    </>
  );

  const Content3 = (
    <>
      <Content1>
        <Row className="d-flex flex-wrap w-100">
          <Col
            sm="2"
            md="2"
            lg="2"
            xl="2"
            className="d-flex justify-content-center align-content-center w-100 h-100"
          >
            <h1 className="w-100 h-100 p-1 text-center">
              <i
                className="fa fa-phone fa-flip-horizontal"
                style={{
                  color: Theme?.CallUs?.icon ? Theme?.CallUs?.icon : "#28a745",
                }}
              ></i>
            </h1>
          </Col>
          <Col
            sm="10"
            md="10"
            lg="10"
            xl="10"
            className="d-flex justify-content-center align-content-center w-100"
          >
            <h4 className="text-secondary">
              Feel free to call us on our no.{" "}
              <b style={{ color: "black" }}>
                {theme_conf?.broker_config?.phone || ContactFn()}
              </b>
              {process.env.REACT_APP_BROKER === "SHREE"
                ? ` or write to us at `
                : ""}
              {process.env.REACT_APP_BROKER === "SHREE" ? (
                <b style={{ color: "black" }}>insure@lakshmishree.com</b>
              ) : (
                <noscript />
              )}
            </h4>
          </Col>
        </Row>
      </Content1>
    </>
  );

  return (
    <>
      {!lessthan993 && (
        <Popup
          height={msg ? "200px" : "auto"}
          width="577px"
          show={show}
          onClose={onClose}
          content={
            msg
              ? content2
              : process.env?.REACT_APP_BROKER === "SRIYAH" ||
                process.env?.REACT_APP_BROKER === "FYNTUNE" ||
                process.env?.REACT_APP_BROKER === "UIB" ||
                process.env?.REACT_APP_BROKER === "SRIDHAR"
              ? content1
              : Content3
          }
          position="middle"
          animDuration="0s"
        />
      )}
    </>
  );
};

// PropTypes
CallMe.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

// DefaultTypes
CallMe.defaultProps = {
  show: false,
  onClose: () => {},
};

const CallText = styled.div`
  width: 340px;
  margin: 37px auto 37px;
  text-align: center;
  font-family: ${({ theme }) =>
    theme?.mediumFont?.fontFamily
      ? theme?.mediumFont?.fontFamily
      : "basier_squaremedium"};
  font-size: 16px;
  color: #000000;
`;
const MessageContainer = styled.div`
  padding: 10px;
  & svg {
    margin: 0 auto;
    width: 100%;
  }
`;

const Content1 = styled.div`
  height: 90%;
  /* padding: 60px 100px; */
  padding: 30px 50px;
`;

const ContactText = styled.h4`
  text-align: center;
  padding: 10px;
  /* font-weight: 500; */
  font-family: ${({ theme }) =>
    theme?.regularFont?.fontFamily
      ? theme?.regularFont?.fontFamily
      : "basier_squareregular"};
  font-size: 15px;
  /* margin-bottom: 20px; */
  color: #111;
`;
const Button = styled.button`
  &:disabled {
    background-color: #dfe3e8;
    color: #111;
    border: solid 1px #d2d3d4;
  }
  &:focus {
    outline: none;
  }
  display: inline-block;
  // border-radius: 5px;
  padding: 0px 25px;
  box-sizing: content-box;
  font-size: 17px;
  background-color: ${({ theme }) => theme.Header?.color || "#bdd400"};
  font-weight: 600;
  border: none;
  color: white;
  height: 60px;
`;

const Heading = styled.p`
  margin-bottom: 0px;
  font-size: 25px;
  text-align: center;
`;

const ORLine = styled.h6`
  font-size: 20px;
  overflow: hidden;
  text-align: center;
  :before,
  :after {
    background-color: #000;
    content: "";
    display: inline-block;
    height: 0.5px;
    position: relative;
    vertical-align: middle;
    width: 100px;
  }
  :before {
    right: 0.5em;
  }
  :after {
    left: 0.5em;
  }
`;

export default CallMe;
