import React from "react";
import "./landing-page.css";
import { reloadPage, UrlFn, Data } from "utils";
import styled, { createGlobalStyle } from "styled-components";
import logo from "utils/logo.svg";
import unilight from "utils/unilight.png";
import fyntune from "utils/fyntune.png";
import abibl from "utils/abibl.jpg";
import Cp from "utils/cp.png";
import gc from "utils/gc.png";
import ace from "utils/ace.png";
import sriyah from "utils/sriyah.jpg";
import epoch from "utils/epochlogo.png";
import pinc from "utils/pinc.png";
import analah from "utils/analah.jpeg";
import rb from "utils/rb.png";
import bajaj from "utils/bajaj.png";
import insuringall from "utils/insuringall.jpeg";
import bima from "utils/bima.png";
import uib from "utils/uib.png";
import sridhar from "utils/sridhar.png";
import shree from "utils/shree.png";
import policyera from "utils/policy-era.png";
import tata from "utils/tata.gif";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useMediaPredicate } from "react-media-hook";
import { Header } from "components/GlobalCard/style";
import { Dropdown } from "react-bootstrap";
import { TwoWheeler, DriveEta, LocalShipping } from "@mui/icons-material/";

export const LandingPage = () => {
  const history = useHistory();
  const { typeAccess } = useSelector((state) => state.login);
  const AccessControl =
    !_.isEmpty(typeAccess) &&
    process.env.REACT_APP_API_BASE_URL !== "https://olaapi.fynity.in/api"
      ? _.compact(
          typeAccess.map((item) =>
            item?.product_sub_type_code
              ? item?.product_sub_type_code.toLowerCase()
              : null
          )
        )
      : [];

  //decrypt

  const LogoFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return <Logo src={logo} alt="logo" onClick={() => reloadPage("/")} />;
      case "UNILIGHT":
        return (
          <Logo src={unilight} alt="logo" onClick={() => reloadPage("/")} />
        );
      case "CP":
        return <Logo src={Cp} alt="logo" onClick={() => reloadPage("/")} />;
      case "FYNTUNE":
        return (
          <Logo src={fyntune} alt="logo" onClick={() => reloadPage("/")} />
        );
      case "POLICYERA":
        return (
          <Logo src={policyera} alt="logo" onClick={() => reloadPage("/")} />
        );
      case "ABIBL":
        return (
          <Logo
            src={abibl}
            alt="logo"
            onClick={() => reloadPage("http://abibl-prod-dashboard.fynity.in/")}
          />
        );
      case "GRAM":
        return <Logo src={gc} alt="logo" onClick={() => reloadPage("/")} />;
      case "ACE":
        return <Logo src={ace} alt="logo" onClick={() => reloadPage("/")} />;
      case "SRIYAH":
        return <Logo src={sriyah} alt="logo" onClick={() => reloadPage("/")} />;
      case "PINC":
        return <Logo src={pinc} alt="logo" onClick={() => reloadPage("/")} />;
      case "EPOCH":
        return <Logo src={epoch} alt="logo" onClick={() => reloadPage("/")} />;
      case "RB":
        return <Logo src={rb} alt="logo" onClick={() => reloadPage("/")} />;
      case "SPA":
        return (
          <Logo src={insuringall} alt="logo" onClick={() => reloadPage("/")} />
        );
      case "BIMA":
        return <Logo src={bima} alt="logo" onClick={() => reloadPage("/")} />;
      case "ANALAH":
        return <Logo src={analah} alt="logo" onClick={() => reloadPage("/")} />;
      case "BAJAJ":
        return (
          <Logo
            src={bajaj}
            alt="logo"
            onClick={() => reloadPage("https://www.bajajcapitalinsurance.com/")}
          />
        );
      case "UIB":
        return <Logo src={uib} alt="logo" onClick={() => reloadPage("/")} />;
      case "SRIDHAR":
        return (
          <Logo src={sridhar} alt="logo" onClick={() => reloadPage("/")} />
        );
      case "SHREE":
        return <Logo src={shree} alt="logo" onClick={() => reloadPage("/")} />;
      case "TATA":
        return <Logo src={tata} alt="logo" onClick={() => reloadPage("/")} />;
      default:
        break;
    }
  };

  const OnTypeSelect = (type) => {
    console.log(type);
    const custom =
      process.env.REACT_APP_API_BASE_URL ===
        "https://uatmotorapis.aceinsurance.com/api" ||
      process.env.REACT_APP_API_BASE_URL ===
        "https://apicarbike.gramcover.com/api";

    if (!custom) {
      console.log("fire");
      if (type === "car") {
        history.push("/car/lead-page");
      }
      if (type === "bike") {
        history.push("/bike/lead-page");
      }
      if (type === "cv") {
        history.push("/cv/lead-page");
      }
    }
    if (custom) {
      //gram-prod
      if (
        process.env.REACT_APP_API_BASE_URL ===
        "https://apicarbike.gramcover.com/api"
      ) {
        if (type === "car") {
          reloadPage("https://car.gramcover.com/car/lead-page");
        }
        if (type === "bike") {
          reloadPage("https://bike.gramcover.com/bike/lead-page");
        }
        if (type === "cv") {
          reloadPage("https://cv.gramcover.com/cv/lead-page");
        }
      }
      //ACE UAT
      if (
        process.env.REACT_APP_API_BASE_URL ===
        "https://uatmotorapis.aceinsurance.com/api"
      ) {
        if (type === "car") {
          reloadPage("https://uatcar.aceinsurance.com/car/lead-page");
        }
        if (type === "bike") {
          reloadPage("https://uatbike.aceinsurance.com/bike/lead-page");
        }
        if (type === "cv") {
          reloadPage("https://uatpcv.aceinsurance.com/cv/lead-page");
        }
      }
      //ACE Prod
      if (
        process.env.REACT_APP_API_BASE_URL ===
        "https://motorapis.aceinsurance.com/api"
      ) {
        if (type === "car") {
          reloadPage("https://car.aceinsurance.com/car/lead-page");
        }
        if (type === "bike") {
          reloadPage("https://bike.aceinsurance.com/bike/lead-page");
        }
        if (type === "cv") {
          reloadPage("https://pcv.aceinsurance.com/cv/lead-page");
        }
      }
    }
  };

  const lessthan768 = useMediaPredicate("(max-width: 768px)");
  const lessthan576 = useMediaPredicate("(max-width: 576px)");

  return (
    <>
      <CircleDiv className="circle"></CircleDiv>
      <div className="blurred-wrapper">
        <MainHeader
          className="main-header containerLp"
          style={{ zIndex: "-1" }}
        >
          {LogoFn()}
          {process.env?.REACT_APP_BROKER !== "CP" && (
            <a
              role="button"
              tabindex="0"
              onClick={() => reloadPage(UrlFn())}
              className="btnLp sign-up"
            >
              Login
            </a>
          )}
        </MainHeader>
        <Section className="hero containerLp">
          <div className="content-wrapper">
            <h5
              className="tagline"
              style={{
                color:
                  lessthan576 &&
                  process.env.REACT_APP_BROKER === "ABIBL" &&
                  "#fff",
              }}
            >
              No speed limits on our service
            </h5>
            <h1
              className="title"
              style={{
                marginRight: "1rem",
                color:
                  lessthan576 &&
                  process.env.REACT_APP_BROKER === "ABIBL" &&
                  "#fff",
              }}
            >
              Compare <ColorText>quotes</ColorText> from every{" "}
              <ColorText>leading insurance company</ColorText> in seconds.
            </h1>
            <LookingText className="messageLp">
              Looking for Vehicle Insurance?
            </LookingText>
            <QuotesButton>
              {AccessControl.includes("car") && (
                <a
                  role="button"
                  tabindex="0"
                  onClick={() => OnTypeSelect("car")}
                  className="btnLp cta car_button text-center"
                  style={{
                    padding: "0px 10px",
                    height: "86px",
                    marginRight: "2rem",
                  }}
                >
                  <StyledDiv className="text-center" title="CAR">
                    {process.env?.REACT_APP_BROKER === "ACE" ? (
                      <DriveEta sx={{ fontSize: 82, color: "#0093c7" }} />
                    ) : (
                      <img
                        src={`${
                          process.env.REACT_APP_BASENAME !== "NA"
                            ? `/${process.env.REACT_APP_BASENAME}`
                            : ""
                        }/assets/images/car.svg`}
                        height="85"
                        width="85"
                      />
                    )}
                  </StyledDiv>
                  {/* Car */}
                </a>
              )}
              {AccessControl.includes("bike") && (
                <a
                  role="button"
                  tabindex="0"
                  onClick={() => OnTypeSelect("bike")}
                  className="btnLp cta bike_button text-center"
                  style={{
                    padding: "0px 10px",
                    height: "86px",
                    marginRight: "2rem",
                  }}
                >
                  <StyledDiv className="text-center" title="BIKE">
                    {process.env?.REACT_APP_BROKER === "ACE" ? (
                      <TwoWheeler sx={{ fontSize: 85, color: "#0093c7" }} />
                    ) : (
                      <img
                        src={`${
                          process.env.REACT_APP_BASENAME !== "NA"
                            ? `/${process.env.REACT_APP_BASENAME}`
                            : ""
                        }/assets/images/bike.svg`}
                        height="85"
                        width="85"
                      />
                    )}
                  </StyledDiv>
                  {/* Bike */}
                </a>
              )}
              {(AccessControl.includes("pcv") ||
                AccessControl.includes("gcv")) && (
                <a
                  role="button"
                  tabindex="0"
                  onClick={() => OnTypeSelect("cv")}
                  className="btnLp cta cv_button text-center"
                  style={{
                    padding: "0px 10px",
                    height: "86px",
                    marginRight: "2rem",
                  }}
                >
                  <StyledDiv className="text-center" title="COMMERCIAL VEHICLE">
                    {process.env?.REACT_APP_BROKER === "ACE" ? (
                      <LocalShipping sx={{ fontSize: 85, color: "#0093c7" }} />
                    ) : (
                      <img
                        src={`${
                          process.env.REACT_APP_BASENAME !== "NA"
                            ? `/${process.env.REACT_APP_BASENAME}`
                            : ""
                        }/assets/images/cv.svg`}
                        height="85"
                        width="85"
                      />
                    )}
                  </StyledDiv>
                  {/* Commercial Vehicle */}
                </a>
              )}
            </QuotesButton>
          </div>
          {!lessthan768 && (
            <div>
              <img
                className="car"
                src={`${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ""
                }/assets/images/insurance2.png`}
                style={{ width: "700px" }}
              />
            </div>
          )}
        </Section>
      </div>
      <GlobalStyle />
    </>
  );
};

export const GlobalStyle = createGlobalStyle`
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 100vh !important;
  min-height: 100vh !important;
}
`;

const Section = styled.section`
  font-family: ${({ theme }) => theme.LandingPage?.fontFamily || ""};
  font-weight: ${({ theme }) => theme.LandingPage?.fontWeight || ""};
`;

const Logo = styled.img`
  width: ${process.env.REACT_APP_BROKER === "ACE" ||
  process.env.REACT_APP_BROKER === "SRIYAH"
    ? "140px"
    : process.env.REACT_APP_BROKER === "PINC"
    ? "95px"
    : process.env.REACT_APP_BROKER === "RB" ||
      process.env.REACT_APP_BROKER === "SPA" ||
      process.env.REACT_APP_BROKER === "EPOCH" ||
      process.env.REACT_APP_BROKER === "BIMA" ||
      process.env.REACT_APP_BROKER === "BAJAJ" ||
      process.env.REACT_APP_BROKER === "UIB" ||
      process.env.REACT_APP_BROKER === "ANALAH" ||
      process.env.REACT_APP_BROKER === "SRIDHAR" ||
      process.env.REACT_APP_BROKER === "POLICYERA" ||
      process.env.REACT_APP_BROKER === "SHREE" ||
      process.env.REACT_APP_BROKER === "TATA"
    ? "auto"
    : "160px"};
  height: ${process.env.REACT_APP_BROKER !== "FYNTUNE"
    ? process.env.REACT_APP_BROKER === "ACE" ||
      process.env.REACT_APP_BROKER === "SRIYAH"
      ? "65px"
      : process.env.REACT_APP_BROKER === "PINC"
      ? "50px"
      : process.env.REACT_APP_BROKER === "RB"
      ? "90px"
      : process.env.REACT_APP_BROKER === "SPA"
      ? "62px"
      : process.env.REACT_APP_BROKER === "EPOCH" ||
        process.env.REACT_APP_BROKER === "TATA"
      ? "57px"
      : process.env.REACT_APP_BROKER === "BIMA"
      ? "60px"
      : process.env.REACT_APP_BROKER === "UIB" ||
        process.env.REACT_APP_BROKER === "SHREE"
      ? "55px"
      : process.env.REACT_APP_BROKER === "ANALAH"
      ? "60px"
      : process.env.REACT_APP_BROKER === "SRIDHAR"
      ? "70px"
      : process.env.REACT_APP_BROKER === "POLICYERA"
      ? "45px"
      : "50px"
    : "38px"};
  // -webkit-filter: invert(100%);
  // filter: invert(100%);
  @media (max-width: 768px) {
    width: ${process.env.REACT_APP_BROKER === "PINC"
      ? "80px"
      : process.env.REACT_APP_BROKER === "RB" ||
        process.env.REACT_APP_BROKER === "BIMA" ||
        process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "UIB" ||
        process.env.REACT_APP_BROKER === "ANALAH" ||
        process.env.REACT_APP_BROKER === "SRIDHAR" ||
        process.env.REACT_APP_BROKER === "POLICYERA" ||
        process.env.REACT_APP_BROKER === "SHREE" ||
        process.env.REACT_APP_BROKER === "TATA"
      ? "auto"
      : "130px"};
    height: ${process.env.REACT_APP_BROKER === "PINC"
      ? "40px"
      : process.env.REACT_APP_BROKER !== "FYNTUNE"
      ? process.env.REACT_APP_BROKER === "ACE" ||
        process.env.REACT_APP_BROKER === "SRIYAH"
        ? "65px"
        : process.env.REACT_APP_BROKER === "RB"
        ? "70px"
        : process.env.REACT_APP_BROKER === "BAJAJ"
        ? "40px"
        : process.env.REACT_APP_BROKER === "UIB" ||
          process.env.REACT_APP_BROKER === "SHREE"
        ? "45px"
        : process.env.REACT_APP_BROKER === "ANALAH" ||
          process.env.REACT_APP_BROKER === "TATA"
        ? "50px"
        : process.env.REACT_APP_BROKER === "SRIDHAR"
        ? "60px"
        : process.env.REACT_APP_BROKER === "POLICYERA"
        ? "40px"
        : "50px"
      : "38px"};
  }

  @media (max-width: 415px) {
    width: ${process.env.REACT_APP_BROKER === "PINC"
      ? "60px"
      : process.env.REACT_APP_BROKER === "RB" ||
        process.env.REACT_APP_BROKER === "BIMA" ||
        process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "UIB" ||
        process.env.REACT_APP_BROKER === "ANALAH" ||
        process.env.REACT_APP_BROKER === "SRIDHAR" ||
        process.env.REACT_APP_BROKER === "POLICYERA" ||
        process.env.REACT_APP_BROKER === "SHREE" ||
        process.env.REACT_APP_BROKER === "TATA"
      ? "auto"
      : "130px"};
    height: ${process.env.REACT_APP_BROKER === "PINC"
      ? "40px"
      : process.env.REACT_APP_BROKER !== "FYNTUNE"
      ? process.env.REACT_APP_BROKER === "ACE" ||
        process.env.REACT_APP_BROKER === "SRIYAH"
        ? "65px"
        : process.env.REACT_APP_BROKER === "RB"
        ? "70px"
        : process.env.REACT_APP_BROKER === "BIMA"
        ? "45px"
        : process.env.REACT_APP_BROKER === "BAJAJ" ||
          process.env.REACT_APP_BROKER === "UIB" ||
          process.env.REACT_APP_BROKER === "SHREE"
        ? "35px"
        : process.env.REACT_APP_BROKER === "ANALAH"
        ? "40px"
        : process.env.REACT_APP_BROKER === "SRIDHAR"
        ? "55px"
        : process.env.REACT_APP_BROKER === "POLICYERA"
        ? "30px"
        : process.env.REACT_APP_BROKER === "TATA"
        ? "45px"
        : "50px"
      : "38px"};
  }
  @media (max-width: 360px) {
    width: ${process.env.REACT_APP_BROKER === "RB"
      ? ""
      : process.env.REACT_APP_BROKER === "BIMA" ||
        process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "UIB" ||
        process.env.REACT_APP_BROKER === "ANALAH" ||
        process.env.REACT_APP_BROKER === "POLICYERA" ||
        process.env.REACT_APP_BROKER === "SHREE" ||
        process.env.REACT_APP_BROKER === "TATA"
      ? "auto"
      : "100px"};
    height: ${process.env.REACT_APP_BROKER === "BAJAJ"
      ? "30px"
      : process.env.REACT_APP_BROKER === "TATA" && "40px"};
  } ;
`;

const QuotesButton = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;

  @media (max-height: 420px) {
    margin-bottom: 80px;
  }

  @media (max-width: 766px) {
    justify-content: center;
    padding-left: 10px;
  }

  @media (max-height: 420px) {
    margin-bottom: 80px;
  }

  .car_button {
    img {
      // filter: brightness(0) invert(1);
      @media (max-width: 768px) {
        width: 50px;
        height: 50px;
      }
    }
    @media (max-width: 768px) {
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .bike_button {
    img {
      // filter: brightness(0) invert(1);
      @media (max-width: 768px) {
        width: 50px;
        height: 50px;
      }
    }
    @media (max-width: 768px) {
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .cv_button {
    img {
      @media (max-width: 768px) {
        width: 50px;
        height: 50px;
      }
    }
    p {
      color: #bdd400;
      // filter: invert(100%);
      font-size: 3rem;
      letter-spacing: 5px;
      @media (max-width: 768px) {
        font-size: 1.5rem;
        margin: 0;
        margin-bottom: 13px;
      }
    }
    padding: 1rem 1.1rem;
    @media (max-width: 768px) {
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

const CircleDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.LandingPage?.color || "#bdd400"};
  clip-path: circle(810px at right 950px);
  @media (max-width: 1170px) {
    clip-path: circle(860px at right 1110px);
  }
  @media (max-width: 1050px) {
    clip-path: circle(860px at right 1220px);
  }
  @media (max-width: 450px) {
    clip-path: circle(220px at top);
  }
  @media (max-width: 766px) {
    clip-path: circle(383px at top);
    margin-top: 75px;
    height: 85%;
  }
  @media (max-width: 576px) {
    clip-path: circle(290px at top);
  }
  @media (max-width: 450px) {
    clip-path: circle(220px at top);
  }
  @media (max-height: 380px) {
    display: none;
  }
`;
const ColorText = styled.text`
  color: ${({ theme }) => theme.LandingPage?.color || "green"};
  @media (max-width: 1030px) {
    color: ${({ theme }) => theme.LandingPage?.color3 || "green"};
  }
  // @media (max-width: 465px) {
  //   color: ${({ theme }) => theme.LandingPage?.color2 || "green"};
  // }
`;

const LookingText = styled.p`
  color: ${({ theme }) => theme.LandingPage?.color || "rgb(189, 212, 0)"};
  white-space: nowrap;
  @media (max-width: 1170px) {
    color: black;
  }
  @media (max-width: 766px) {
    color: ${({ theme }) =>
      theme.LandingPage?.color || "rgb(189, 212, 0)"} !important;
  }
`;

const StyledDiv = styled.div`
  filter: ${({ theme }) =>
    process.env.REACT_APP_BROKER === "ACE"
      ? ""
      : theme.LandingPage?.filter || "none"};
`;

const MainHeader = styled.header`
  @media (max-width: 766px) {
    height: 5rem;
    .btnLp {
      padding: 0.6rem 0.8rem;
    }
  }
  @media (max-width: 450px) {
    padding: 0px;
    img {
      margin-left: 20px;
    }
    a {
      margin-right: 20px;
    }
    .loginDrop {
      margin-right: 10px;
    }
  }

  @media (max-width: 320px) {
    img {
      margin: 0px !important;
    }
    a {
      margin-right: 0px !important;
    }
  }

  .loginBtn {
    background: ${({ theme }) =>
      theme.LandingPage?.loginBtnColor || "#bdd400 !important"};
  }
  .dropItem.active,
  .dropItem:active {
    background: ${({ theme }) =>
      theme.LandingPage?.loginBtnColor || "#bdd400 !important"};
  }
`;
