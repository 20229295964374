import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Loader, ContentFn } from "components";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { reloadPage } from "utils";
import _ from "lodash";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import styled from "styled-components";
import { Prefill, clear } from "modules/proposal/proposal.slice";
import swal from "sweetalert";
import { useMediaPredicate } from "react-media-hook";
import { ShareQuote } from "modules/Home/home.slice";
import { getLogoUrl } from "modules/quotesPage/quoteUtil";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const JourneyFailure = () => {
  const dispatch = useDispatch();
  const { temp_data, error } = useSelector((state) => state.proposal);
  const { theme_conf } = useSelector((state) => state.home);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const errMsg = query.get("msg");
  const lessthan767 = useMediaPredicate("(max-width: 767px)");

  //IOS check.
  let isMobileIOS = false; //initiate as false
  // device detection
  if (
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !window.MSStream &&
    lessthan767
  ) {
    isMobileIOS = true;
  }

  var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  const CardData = !_.isEmpty(temp_data)
    ? temp_data?.userProposal?.additonalData
      ? temp_data?.userProposal?.additonalData
      : {}
    : {};

  const UrlFn = (ut) => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return `https://ola-dashboard.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
      case "UNILIGHT":
        return `https://www.policyongo.com/${ut ? "employee" : "pos"}/login`;
      case "CP":
        return `https://www.comparepolicy.com/`;
      case "FYNTUNE":
        return "";
      case "ABIBL":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-preprod-carbike.fynity.in/api"
        ) {
          return `http://preprod-dasbhoard-abibl.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-carbike.fynity.in/api"
        ) {
          return `http://uat-dasbhoard-abibl.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else {
          return `http://abibl-prod-dashboard.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        }
      case "GRAM":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apigramcover-carbike.fynity.in/api"
        ) {
          return `http://uat-dasbhoard-gramcover.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else {
          return `https://dashboard.gramcover.com/${
            ut ? "employee" : "pos"
          }/login`;
        }
      case "ACE":
        return "https://crm.aceinsurance.com:5555/";
      case "SRIYAH":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://nammacover.com/"
          : "https://uat.nammacover.com/";
      case "PINC":
        return localStorage.getItem("setupTime")
          ? process.env.REACT_APP_PROD === "YES"
            ? "https://pinctreepos.app.link/prod_logo"
            : "https://pinctreepos.app.link/dev_logo"
          : "https://pinctree.com/";
      case "EPOCH":
        return `http://online.epochbrokers.com:8092/${
          ut ? "employee" : "customer"
        }/login`;
      case "RB":
        return "https://www.renewbuy.com/";
      case "SPA":
        return `https://uatdashboard.insuringall.com/${
          ut ? "employee" : "pos"
        }/login`;
      case "BIMA":
        return ``;
      case "BAJAJ":
        return Bajaj_rURL();
      case "UIB":
        return ``;
      case "SRIDHAR":
        return `https://uatdashboard.sibinsure.com/${
          ut ? "employee" : "pos"
        }/login`;
      case "SHREE":
        return `https://shreeinsure.com/${ut ? "employee" : "pos"}/login`;
      case "POLICYERA":
        return process.env.REACT_APP_PROD === "YES"
          ? `https://dashboard.policyera.com/${ut ? "employee" : "pos"}/login`
          : `https://uatdashboard.policyera.com/${
              ut ? "employee" : "pos"
            }/login`;
      case "ANALAH":
        return process.env.REACT_APP_PROD === "YES"
          ? `https://dashboard.analahinsurance.com/${
              ut ? "employee" : "pos"
            }/login`
          : `https://uatdashboard.analahinsurance.com/${
              ut ? "employee" : "pos"
            }/login`;
      case "TATA":
        return process.env.REACT_APP_PROD === "YES"
          ? `https://dashboard.lifekaplan.com/${ut ? "employee" : "pos"}/login`
          : `https://uatdashboard.lifekaplan.com/${
              ut ? "employee" : "pos"
            }/login`;
      default:
        return `https://ola-dashboard.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
    }
  };

  window.Android && window.Android.SendToPaymentFailPage("Payment failed");

  useEffect(() => {
    if (error) {
      swal(
        "Error",
        `${`Trace ID:- ${enquiry_id}.\n Error Message:- ${error}`}`,
        "error"
      );
    }
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  //load prefill data
  useEffect(() => {
    if (enquiry_id) dispatch(Prefill({ enquiryId: enquiry_id }, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  const Bajaj_rURL = (b2c) => {
    if (
      process.env.REACT_APP_API_BASE_URL ===
      "https://uatapimotor.bajajcapitalinsurance.com/api"
    ) {
      return b2c
        ? "https://buypolicyuat.bajajcapitalinsurance.com"
        : "https://partneruat.bajajcapitalinsurance.com";
    }
    if (
      process.env.REACT_APP_API_BASE_URL ===
      "https://stageapimotor.bajajcapitalinsurance.com/api"
    ) {
      return b2c
        ? window.location.href
        : "https://partnerstage.bajajcapitalinsurance.com";
    }
    if (
      process.env.REACT_APP_API_BASE_URL ===
      "https://apimotor.bajajcapitalinsurance.com/api"
    ) {
      return b2c
        ? window.location.href
        : "https://partner.bajajcapitalinsurance.com";
    }
  };

  //email - trigger
  useEffect(() => {
    if (
      enquiry_id &&
      CardData?.owner?.email &&
      temp_data?.selectedQuote?.productName
    )
      dispatch(
        ShareQuote({
          enquiryId: enquiry_id,
          notificationType: "email",
          domain: `http://${window.location.hostname}`,
          type: "paymentFailure",
          emailId: CardData?.owner?.email,
          email: CardData?.owner?.email,
          firstName: CardData?.owner?.firstName,
          lastName: CardData?.owner?.lastName,
          action: window.location.href,
          link: window.location.href,
          productName: temp_data?.selectedQuote?.productName,
          reInitiate: temp_data?.journeyStage?.proposalUrl,
          logo: getLogoUrl(),
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, temp_data]);

  return !_.isEmpty(temp_data) ? (
    <Row className="text-center w-100 mx-auto" style={{}}>
      <Top className="mx-auto" style={{ width: "50%" }}>
        <div className="mt-4 d-flex justify-content-center w-100">
          <img
            src={`${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ""
            }/assets/images/remove.png`}
            alt="errImg"
            height="100"
            width="100"
            className="failure_image"
          />
        </div>
        <div className="mt-4 d-flex flex-column justify-content-center w-100">
          <h4
            className="text-center w-100 text-danger font-weight-bold oops_text"
            style={{ fontSize: "2.3rem" }}
          >
            Oops!
          </h4>
          <h4 className="text-center w-100 text-danger font-weight-bold transaction_text">
            {errMsg ? errMsg : "Your transaction was unsuccessful!"}
          </h4>
        </div>
        <div className="mt-4 d-flex flex-column justify-content-center w-100">
          <p
            className="text-center w-100 error_text"
            style={{ fontSize: "1.1rem", color: "red" }}
          >
            Process could not be completed, please ensure the information you
            provided is Correct.
          </p>
          <p
            className="text-center w-100 content_text"
            style={{ fontSize: "1.1rem" }}
          >
            {theme_conf?.broker_config ? (
              <>
                In case of any further requirements, please contact us at
                <b> {theme_conf?.broker_config?.email}</b> or call us at our
                number
                <b> {theme_conf?.broker_config?.phone} </b>
              </>
            ) : (
              ContentFn()
            )}
          </p>
        </div>
        {temp_data?.journeyStage?.proposalUrl && (
          <div className="mt-2 d-flex justify-content-center w-100">
            <Button
              buttonStyle="outline-solid"
              hex1={
                Theme?.proposalProceedBtn?.hex1
                  ? Theme?.proposalProceedBtn?.hex1
                  : "#4ca729"
              }
              hex2={
                Theme?.proposalProceedBtn?.hex2
                  ? Theme?.proposalProceedBtn?.hex2
                  : "#4ca729"
              }
              borderRadius={
                Theme?.QuoteBorderAndFont?.borderRadius
                  ? Theme?.QuoteBorderAndFont?.borderRadius
                  : "25px"
              }
              type="submit"
              shadow={"none"}
              onClick={() =>
                reloadPage(
                  temp_data?.journeyStage?.proposalUrl?.includes("dropout")
                    ? `${temp_data?.journeyStage?.proposalUrl?.replace(
                        /quotes/gi,
                        "proposal-page"
                      )}`
                    : `${temp_data?.journeyStage?.proposalUrl?.replace(
                        /quotes/gi,
                        "proposal-page"
                      )}&dropout=true`
                )
              }
            >
              Retry Payment
            </Button>
          </div>
        )}
        {((process.env.REACT_APP_BROKER === "OLA" &&
          temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
            "driver-app") ||
          process.env.REACT_APP_BROKER !== "OLA") &&
          !(
            (isMobileIOS && !standalone && !safari) ||
            userAgent.includes("wv")
          ) && (
            <div style={{ margin: "20px" }}>
              <a
                style={{
                  textDecoration: "underline",
                  color: Theme?.proposalProceedBtn?.hex2
                    ? Theme?.proposalProceedBtn?.hex2
                    : "#4ca729",
                  cursor: "pointer",
                }}
                onClick={() => [
                  process.env.REACT_APP_BROKER === "OLA" &&
                  temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
                    "driver-app"
                    ? reloadPage(
                        process.env.REACT_APP_API_BASE_URL ===
                          "https://api-ola-uat.fynity.in/api"
                          ? "https://auth-repose-azure.stg.corp.olacabs.com/olamoney/kyc-web/wallet/driver/crosssell-dashboard"
                          : "https://supply-api.olacabs.com/crosssell-dashboard"
                      )
                    : !(
                        !_.isEmpty(temp_data?.agentDetails) &&
                        (temp_data?.agentDetails?.find(
                          (o) => o?.sellerType === "P"
                        ) ||
                          temp_data?.agentDetails?.find(
                            (o) => o?.sellerType === "E"
                          ))
                      ) ||
                      _.isEmpty(temp_data?.agentDetails) ||
                      process.env.REACT_APP_BROKER === "RB" ||
                      process.env.REACT_APP_BROKER === "UIB"
                    ? process.env.REACT_APP_BROKER === "BAJAJ"
                      ? reloadPage(Bajaj_rURL(true))
                      : process.env.REACT_APP_BROKER === "PINC"
                      ? reloadPage(UrlFn())
                      : reloadPage(
                          `${window.location.origin}${
                            process.env.REACT_APP_BASENAME !== "NA"
                              ? `/${process.env.REACT_APP_BASENAME}`
                              : ``
                          }/${
                            Number(temp_data?.productSubTypeId) === 1
                              ? "car"
                              : Number(temp_data?.productSubTypeId) === 2
                              ? "bike"
                              : "cv"
                          }/lead-page`
                        )
                    : //checking where to redirect
                    process.env.REACT_APP_BROKER === "BAJAJ"
                    ? reloadPage(Bajaj_rURL())
                    : temp_data?.agentDetails?.find(
                        (o) => o?.sellerType === "E"
                      )
                    ? reloadPage(UrlFn("E"))
                    : reloadPage(UrlFn()),
                  window.Android &&
                    window.Android.SendToHomePage("Redirecting to homepage"),
                ]}
              >
                Go to Homepage
              </a>
            </div>
          )}
      </Top>
    </Row>
  ) : (
    <Loader />
  );
};

const Top = styled.div`
  font-family: ${({ theme }) => theme.Payment?.fontFamily || ""};
  font-weight: ${({ theme }) => theme.Payment?.fontWeight || ""};
  @media (max-width: 767px) {
    width: 100% !important;
    padding: 0 30px;
    .failure_image {
      height: 50px;
      width: 50px;
    }
    .oops_text {
      font-size: 1.3rem !important;
    }
    .transaction_text {
      font-size: 1rem !important;
    }
    .error_text {
      font-size: 0.9rem !important;
    }
    .content_text {
      font-size: 0.8rem !important;
    }
  }
`;

export default JourneyFailure;
