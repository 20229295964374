import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompactCard, FloatButton } from "components";
import styled, { createGlobalStyle } from "styled-components";
import { Row, Form, Col } from "react-bootstrap";
import Checkbox from "../../components/inputs/checkbox/checkbox";
import { useForm } from "react-hook-form";
import { getIcList } from "modules/Home/home.slice";
import _ from "lodash";
import { useMediaPredicate } from "react-media-hook";
import swal from "sweetalert";
import { ThemeConf, ThemeConfPost } from "modules/Home/home.slice";

export const OtpConfig = () => {
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const lessthan993 = useMediaPredicate("(max-width:993px)");
  const { icList, theme_conf } = useSelector((state) => state.home);
  console.log({ theme_conf });
  const { register, handleSubmit, reset, errors, setValue, watch } = useForm({
    defaultValues: theme_conf?.otp_config
      ? {
          Broker: theme_conf?.otp_config?.Broker,
          IC: theme_conf?.otp_config?.IC,
        }
      : {},
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const setectedBroker = watch("Broker") ? watch("Broker") : "";
  console.log("onSubmit", watch("Broker"));

  const Brokerss = [
    {
      name: "Fyntune",
      url: "https://apimotor.fynity.in/api",
      broker: "FYNTUNE",
    },
    {
      name: "ABIBL UAT",
      url: "https://motor_api_uat.adityabirlainsurancebrokers.com/api",
      broker: "ABIBL",
    },
    {
      name: "ABIBL Production",
      url: "https://motor_api.adityabirlainsurancebrokers.com/api",
      broker: "ABIBL",
    },
    {
      name: "BIMA-Planner UAT",
      url: "https://uatapimotor.bimaplanner.com/api",
      broker: "BIMA",
    },
    {
      name: "BIMA-Planner Production",
      url: "https://apimotor.bimaplanner.com/api",
      broker: "BIMA",
    },
    {
      name: "TMIBASL UAT",
      url: "https://apiuatmotor.lifekaplan.com/api",
      broker: "TATA",
    },
    {
      name: "TMIBASL Pre-Prod",
      url: "https://preprodapimotor.lifekaplan.com/api",
    },
    {
      name: "TMIBASL Production",
      url: "https://apimotor.lifekaplan.com/api",
      broker: "TATA",
    },
    {
      name: "UIB UAT",
      url: "https://uatapicar.uibinsure.com/api",
      broker: "UIB",
    },
    {
      name: "UIB Production",
      url: "https://apimotor.uibinsure.com/api",
      broker: "UIB",
    },
    {
      name: "ANALAH UAT",
      url: "https://uatapimotor.analahinsurance.com/api",
      broker: "ANALAH",
    },
    {
      name: "Policy-Era UAT",
      url: "https://uatapimotor.policyera.com/api",
      broker: "POLICYERA",
    },
    {
      name: "Policy-Era Production",
      url: "https://apimotor.policyera.com/api",
      broker: "POLICYERA",
    },
    {
      name: "Sridhar UAT",
      url: "https://uatapimotor.sibinsure.com/api",
      broker: "SRIDHAR",
    },
    {
      name: "Sridhar Production",
      url: "https://apimotor.sibinsure.com/api",
      broker: "SRIDHAR",
    },
    {
      name: "Shree UAT",
      url: "https://uatapimotor.shreeinsure.com/api",
      broker: "SHREE",
    },
    {
      name: "Shree Production",
      url: "https://apimotor.shreeinsure.com/api",
      broker: "SHREE",
    },
    {
      name: "Bajaj UAT",
      url: "https://uatapimotor.bajajcapitalinsurance.com/api",
      broker: "BAJAJ",
    },
    {
      name: "Bajaj Pre-Prod",
      url: "https://stageapimotor.bajajcapitalinsurance.com/api",
      broker: "BAJAJ",
    },
    {
      name: "Bajaj Production",
      url: "https://apimotor.bajajcapitalinsurance.com/api",
      broker: "BAJAJ",
    },
    {
      name: "EPOCH UAT",
      url: "https://uatapimotor.policylo.com/api",
      broker: "EPOCH",
    },
    {
      name: "EPOCH Production",
      url: "https://apimotor.policylo.com/api",
      broker: "EPOCH",
    },
    {
      name: "SPA UAT",
      url: "https://apiuatmotor.insuringall.com/api",
      broker: "SPA",
    },
    {
      name: "SPA Pre-Prod",
      url: "https://apipreprodmotor.insuringall.com/api",
      broker: "SPA",
    },
    {
      name: "SPA Production",
      url: "https://apimotor.insuringall.com/api",
      broker: "SPA",
    },
    {
      name: "RB UAT",
      url: "https://apiuatmotor.rbstaging.in/api",
      broker: "RB",
    },
    {
      name: "RB Pre-Prod",
      url: "https://apipreprodmotor.rbstaging.in/api",
      broker: "RB",
    },
    {
      name: "RB Production",
      url: "https://apimotor.renewbuy.com/api",
      broker: "RB",
    },
    {
      name: "PINC UAT",
      url: "https://uat-apimotor.pincinsurance.com/api",
      broker: "PINC",
    },
    {
      name: "PINC Production",
      url: "https://apimotor.pincinsurance.com/api",
      broker: "PINC",
    },
    {
      name: "ACE UAT",
      url: "https://apicar-aceinsurance.fynity.in/api",
      broker: "ACE",
    },
    {
      name: "ACE Production",
      url: "https://motorapis.aceinsurance.com/api",
      broker: "ACE",
    },
    {
      name: "Nammacover UAT",
      url: "https://apicarbike-sriyah-uat.fynity.in/api",
      broker: "SRIYAH",
    },
    {
      name: "Nammacover Production",
      url: "https://apimotor.nammacover.com/api",
      broker: "SRIYAH",
    },
    {
      name: "Gramcover UAT",
      url: "https://apigramcover-carbike.fynity.in/api",
      broker: "GRAM",
    },
    {
      name: "Gramcover Pre-Prod",
      url: "https://apigramcover-carbike-preprod.fynity.in/api",
      broker: "GRAM",
    },
    {
      name: "Gramcover Production",
      url: "https://apicarbike.gramcover.com/api",
      broker: "GRAM",
    },
    {
      name: "OLA UAT",
      url: "https://api-ola-uat.fynity.in/api",
      broker: "OLA",
    },
    {
      name: "OLA Pre-Prod",
      url: "https://api-ola-preprod.fynity.in/api",
      broker: "OLA",
    },
    {
      name: "OLA Production",
      url: "https://olaapi.fynity.in/api",
      broker: "OLA",
    },
    {
      name: "UNILIGHT UAT",
      url: "https://api-unilight-uat.fynity.in/api",
      broker: "UNILIGHT",
    },
    {
      name: "UNILIGHT Pre-Prod",
      url: "https://api-unilight-preprod.fynity.in/api",
      broker: "UNILIGHT",
    },
    {
      name: "UNILIGHT Production",
      url: "https://apipcv.policyongo.com/api",
      broker: "UNILIGHT",
    },
  ];

  useEffect(() => {
    dispatch(getIcList());
  }, []);

  useEffect(() => {
    if (setectedBroker && theme_conf?.otp_config?.Broker === setectedBroker) {
      console.log("first 1");
      setValue("Broker", theme_conf?.otp_config?.Broker);
      icList.map((item) =>
        theme_conf?.otp_config?.IC?.includes(item)
          ? (document.getElementById(item).checked = true)
          : (document.getElementById(item).checked = false)
      );
      // reset(theme_conf?.otp_config);
    } else if (
      setectedBroker &&
      (_.isEmpty(theme_conf?.otp_config) ||
        theme_conf?.otp_config?.Broker !== setectedBroker)
    ) {
      // swal("their is no value to show ");
      setValue("Broker", setectedBroker);
      icList.map((item) =>
        item === "liberty_videocon" || item === "royal_sundaram"
          ? (document.getElementById(item).checked = true)
          : (document.getElementById(item).checked = false)
      );
    } else {
      if (!setectedBroker) {
        console.log("first 4");
        reset({});
      }
    }
  }, [setectedBroker, theme_conf?.otp_config]);

  const handleFetch = () => {
    const baseUrl = _.compact(
      Brokerss?.map((item) => (item.name === setectedBroker ? item.url : false))
    )[0];
    if (setectedBroker) {
      swal(`Fetching ${setectedBroker} IC's`);
      dispatch(ThemeConf(baseUrl));
    } else {
      swal(`First Select Broker !...`);
    }
  };

  const onSubmit = (data) => {
    const baseUrl = _.compact(
      Brokerss?.map((item) => (item.name === setectedBroker ? item.url : false))
    )[0];
    console.log("onSubmit::", data, baseUrl, _.isEmpty(_.compact(data?.IC)));

    if (!_.isEmpty(_.compact(data?.IC))) {
      dispatch(ThemeConfPost({ otp_config: JSON.stringify(data) }, baseUrl));
    } else {
      dispatch(ThemeConfPost({ otp_config: JSON.stringify({}) }, baseUrl));
    }
  };
  useEffect(() => {
    if (selectAll) {
      icList.map((item) => (document.getElementById(item).checked = true));
      setSelectAll(false);
    }
  }, [selectAll]);
  const handleReset = () => {
    reset(theme_conf?.otp_config);
    // icList.map((item) => (document.getElementById(item).checked = false));
  };
  return (
    <div>
      <CompactCard
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: "3%",
            }}
          >
            <b>OTP Configurator</b>
            <StyleDiv>
              <StyledButton color={"green"} onClick={() => setSelectAll(true)}>
                Select All
              </StyledButton>
              <StyledButton
                color={"gray"}
                style={{
                  cursor:
                    theme_conf?.otp_config?.Broker !== setectedBroker
                      ? "not-allowed"
                      : "pointer",
                }}
                disabled={theme_conf?.otp_config?.Broker !== setectedBroker}
                type="button"
                onClick={() => handleReset()}
              >
                Reset
              </StyledButton>
            </StyleDiv>
          </div>
        }
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row style={{ margin: "0px 0px 25px 0px" }}>
            <Col lg="6" md="12" sm="12">
              <Form.Control
                as="select"
                name="Broker"
                ref={register}
                id="Broker"
              >
                <option value={""}>select</option>
                {Brokerss?.map(({ name }) => (
                  <option value={name}>{name}</option>
                ))}
              </Form.Control>
            </Col>
            <Col lg="6" md="12" sm="12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  ...(lessthan993 && {
                    paddingTop: "20px",
                    justifyContent: "center",
                  }),
                }}
              >
                <StyledButton type="button" onClick={() => handleFetch()}>
                  Fetch Previous Data
                </StyledButton>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: "0px" }}>
            {icList?.map((item, index) => (
              <Col xs="12" sm="12" md="6" lg="3" xl="3">
                <div className="py-2 fname ">
                  <Checkbox
                    id={item}
                    register={register}
                    fieldName={_.capitalize(item.replace(/_/gi, " "))}
                    index={index}
                    name={"IC"}
                    tooltipData={""}
                  />
                </div>
              </Col>
            ))}
          </Row>
          <Row className="w-100 justify-content-end">
            <StyledButton type="submit" style={{ margin: "20px" }}>
              Submit
            </StyledButton>
          </Row>
        </Form>
      </CompactCard>
      <GlobalStyle />
    </div>
  );
};

const GlobalStyle = createGlobalStyle`
`;
const StyleDiv = styled.div`
  display: flex;
`;

const StyledButton = styled.button`
  font-size: 14px;
  margin: auto 10px;
  border-radius: 4px;
  background: ${({ color }) => (color ? color : "rgb(67 56 202)")};
  color: white;
  padding: 3px 10px;
  @media (max-width: 993px) {
    font-size: 12px;
  }
`;
