import React, { useEffect, useState } from "react";
import { Row, Col, Form, Spinner, Button as Btn } from "react-bootstrap";
import {
  TextInput,
  Button,
  ErrorMsg,
  Label,
  BackButton,
  Loader,
} from "components";
import { useForm } from "react-hook-form";
import moment from "moment";
import styled, { createGlobalStyle } from "styled-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { numOnly, toDate, reloadPage, RedirectFn, Encrypt } from "utils";
import _, { replace } from "lodash";
import {
  Url,
  DuplicateEnquiryId,
  clrDuplicateEnquiry,
} from "modules/proposal/proposal.slice";
import ThemeObj from "modules/theme-config/theme-config";
import {
  CancelAll,
  clear as clr,
  setQuotesList,
  error,
} from "modules/quotesPage/quote.slice";
import SecureLS from "secure-ls";
import {
  SaveQuoteData,
  set_temp_data,
  Category,
  clear,
  getFastLaneDatas,
  setFastLane,
  getFrontendUrl,
  tabClick as TabClick,
} from "modules/Home/home.slice";
import {
  SaveLead,
  SaveQuoteData as SaveQuoteDataQuotes,
  error as quotesError,
} from "../../../quotesPage/filterConatiner/quoteFilter.slice";
import { differenceInMonths, differenceInDays } from "date-fns";
import JourneyMismatch from "./journey-mismatch";
import { redirection } from "./helper";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

export const Registration = ({
  enquiry_id,
  type,
  token,
  errorProp,
  lessthan767,
  lessthan400,
  lessthan330,
  typeId,
  isMobileIOS,
  journey_type,
  stepper1,
  TypeReturn,
  isPartner,
  reg_no_url,
}) => {
  /*---------------- back button---------------------*/
  const back = () => {
    history.push(
      `/${type}/lead-page${token ? `?token=${token}` : ``}${
        typeId ? `&typeid=${typeId}` : ``
      }${journey_type ? `&journey_type=${journey_type}` : ``}`
    );
  };
  /*----------x----- back button-------x-------------*/
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    temp_data,
    saveQuoteData,
    category,
    fastLaneData,
    frontendurl,
    tabClick,
    theme_conf,
  } = useSelector((state) => state.home);

  console.log("frontendurl", frontendurl);

  const { duplicateEnquiry } = useSelector((state) => state.proposal);

  const [btnDisable, setbtnDisable] = useState(false);
  const [buffer, setBuffer] = useState(false);

  // validation schema
  const yupValidate = yup.object({
    regNo1: yup
      .string()
      .matches(/^[A-Z]{2}[-][0-9\s]{1,2}$/, "Invalid RTO Number")
      .required("Registration No. is required"),
    regNo2: yup
      .string()
      .matches(/^[A-Za-z\s]{1,3}$/, "Invalid Number")
      .nullable()
      .transform((v, o) => (o === "" ? null : v)),
    regNo3: yup
      .string()
      .required("Number is required")
      .matches(/^[0-9]{4}$/, "Invalid Number"),
  });

  const { handleSubmit, register, errors, setValue, watch } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onBlur",
  });

  //Initial boot
  useEffect(() => {
    dispatch(getFrontendUrl());
  }, []);

  var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  const isAndroidWebView =
    _.isEmpty(temp_data?.agentDetails?.filter((o) => o?.sellerType === "U")) &&
    window.location.hostname !== "localhost" &&
    process.env.REACT_APP_API_BASE_URL !==
      "https://apipreprodmotor.rbstaging.in/api";

  //modal state
  const [show, setShow] = useState(false);

  //fastlane logic to be discarded
  const onSubmitFastLane = () => {
    if (
      isAndroidWebView &&
      process.env.REACT_APP_BROKER === "RB" &&
      !localStorage?.SSO_user_motor
    ) {
      swal(
        "Attention",
        "Login to experience the new & faster journey",
        "info"
      ).then(() => {
        setbtnDisable(false);
        setBuffer(false);
        document.getElementById("widgetJslogin") &&
          document.getElementById("widgetJslogin").click();
      });
    } else {
      setbtnDisable(true);
      tabClick && dispatch(TabClick(false));
      if (
        process.env?.REACT_APP_API_BASE_URL ===
          "https://apimotor.fynity.in/api" ||
        process.env?.REACT_APP_BROKER === "ABIBL" ||
        process.env?.REACT_APP_API_BASE_URL ===
          "https://api-carbike.fynity.in/api" ||
        process.env?.REACT_APP_BROKER === "OLA" ||
        process.env?.REACT_APP_BROKER === "ACE" ||
        process.env?.REACT_APP_BROKER === "RB" ||
        // process.env?.REACT_APP_BROKER === "EPOCH" ||
        process.env?.REACT_APP_BROKER === "PINC" ||
        process.env?.REACT_APP_BROKER === "BAJAJ" ||
        process.env?.REACT_APP_BROKER === "SPA" ||
        process.env?.REACT_APP_BROKER === "TATA" ||
        (process.env.REACT_APP_BROKER === "GRAM")
      ) {
        setBuffer(true);
        let registration_no = regNo2
        ? `${
            Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
              ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
              : regNo1
          }-${regNo2}-${regNo3}`
        : `${
            Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
              ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
              : regNo1
          }--${regNo3}`
        const data = {
          ...(localStorage?.SSO_user && {
            tokenResp: JSON.parse(localStorage?.SSO_user),
          }),
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          registration_no: registration_no,
          unformatted_reg_no: registration_no.replace(/-/gi, ""),
          ...(TypeReturn(type) !== "cv" && {
            productSubType: TypeReturn(type) === "car" ? 1 : 2,
          }),
          ...(journey_type && {
            journeyType: journey_type,
          }),
          section: TypeReturn(type),
        };
        dispatch(getFastLaneDatas(data));
      } else {
        onSubmit(1);
      }
    }
  };

  useEffect(() => {
    //Cancel token
    dispatch(CancelAll(true));
    //quotes clr
    dispatch(setQuotesList([]));
    //quotes slice state clear
    dispatch(clr());
  }, []);

  //policy & business type calc
  //SAOD
  const saod = (regDate) => {
    let b = "01-09-2018";
    let c = regDate;
    let d = moment().format("DD-MM-YYYY");
    let diffMonthsOd = c && b && differenceInMonths(toDate(c), toDate(b));
    let diffDaysOd = c && b && differenceInDays(toDate(c), toDate(b));
    let diffMonthsOdCar = c && d && differenceInMonths(toDate(d), toDate(c));
    let diffDayOd = c && d && differenceInDays(toDate(d), toDate(c));

    return (
      (diffDaysOd >= 0 && diffDayOd > 270 && TypeReturn(type) === "bike") ||
      (diffDayOd > 270 && diffMonthsOdCar < 34 && TypeReturn(type) === "car")
    );
  };

  useEffect(() => {
    if (
      fastLaneData?.status === 100 &&
      (!_.isEmpty(fastLaneData?.results) ||
        process.env?.REACT_APP_BROKER === "ACE") &&
      (process.env?.REACT_APP_API_BASE_URL ===
        "https://apimotor.fynity.in/api" ||
        process.env?.REACT_APP_BROKER === "ABIBL" ||
        process.env?.REACT_APP_API_BASE_URL ===
          "https://api-carbike.fynity.in/api" ||
        process.env?.REACT_APP_BROKER === "OLA" ||
        process.env?.REACT_APP_BROKER === "ACE" ||
        process.env?.REACT_APP_BROKER === "RB" ||
        process.env?.REACT_APP_BROKER === "PINC" ||
        // process.env?.REACT_APP_BROKER === "EPOCH" ||
        process.env?.REACT_APP_BROKER === "SPA" ||
        process.env?.REACT_APP_BROKER === "BAJAJ" ||
        process.env?.REACT_APP_BROKER === "TATA" ||
        (process.env.REACT_APP_BROKER === "GRAM"))
    ) {
      let vehicleData =
        !_.isEmpty(fastLaneData?.results) && fastLaneData?.results[0]?.vehicle;
      set_temp_data({
        isRenewalRedirection: "N",
        newCar:
          fastLaneData?.additional_details?.businessType === "newbusiness",
        breakIn: fastLaneData?.additional_details?.businessType === "breakin",
        odOnly: fastLaneData?.additional_details?.policyType === "own_damage",
      });
      const quoteData = {
        enquiryId: temp_data?.enquiry_id || enquiry_id,
        ...(token && { token: token }),
        vehicleRegistrationNo: regNo2
          ? `${
              Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
                ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
                : regNo1
            }-${regNo2}-${regNo3}`
          : `${
              Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
                ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
                : regNo1
            }--${regNo3}`,
        userProductJourneyId: temp_data?.enquiry_id || enquiry_id,
        corpId: temp_data?.corpId,
        userId: temp_data?.userId,
        ...(TypeReturn(type) !== "cv" && {
          productSubTypeId: TypeReturn(type) === "car" ? 1 : 2,
        }), // from api
        fullName: temp_data?.firstName + " " + temp_data?.lastName,
        firstName: temp_data?.firstName,
        lastName: temp_data?.lastName,
        emailId: temp_data?.emailId,
        mobileNo: temp_data?.mobileNo,
        ...(journey_type && {
          journeyType: journey_type,
        }),
        ...(vehicleData && {
          policyType: saod(
            fastLaneData?.results[0]?.vehicle?.regn_dt.split("/").join("-")
          )
            ? "own_damage"
            : "comprehensive",
        }),
        ...(vehicleData && {
          businessType:
            Number(fastLaneData?.results[0]?.vehicle?.manu_yr) ===
            Number(new Date().getFullYear())
              ? "newbusiness"
              : "rollover",
        }),
        rto:
          Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
            ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
            : regNo1,
        ...(vehicleData && {
          manufactureYear:
            `${moment().format("DD-MM-YYYY").split("-")[1]}` -
            `${fastLaneData?.results[0]?.vehicle?.manu_yr}`,
        }),
        ...(vehicleData && {
          version: fastLaneData?.results[0]?.vehicle?.vehicle_cd,
        }), // from api
        ...(vehicleData && {
          versionName: fastLaneData?.results[0]?.vehicle?.fla_variant,
        }), // from api
        vehicleRegisterAt:
          Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
            ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
            : regNo1,
        vehicleRegisterDate:
          vehicleData?.regn_dt?.split("/").join("-") || "01-10-2016",
        ...(vehicleData && {
          vehicleRegisterDate: fastLaneData?.results[0]?.vehicle?.regn_dt
            .split("/")
            .join("-"),
        }),
        vehicleOwnerType: "I", // from api
        ...(vehicleData && {
          policyExpiryDate: fastLaneData?.results[0]?.insurance?.insurance_upto
            ? fastLaneData?.results[0]?.insurance?.insurance_upto
                .split("/")
                .join("-")
            : moment().format("DD-MM-YYYY"),
        }),
        hasExpired: "no", //from api
        isNcb: "Yes", //from api
        isClaim: "N", //from api
        ...(vehicleData && {
          fuelType:
            fastLaneData?.results[0]?.vehicle?.fla_fuel_type_desc || "PETROL",
        }),
        vehicleUsage: 2, //from api
        vehicleLpgCngKitValue: "", //from api,
        previousInsurer: temp_data?.prevIcFullName, //from api
        previousInsurerCode: temp_data?.prevIc, //from api
        previousPolicyType: "Comprehensive", //from api
        ...(vehicleData && {
          modelName: fastLaneData?.results[0]?.vehicle?.fla_model_desc,
          manfactureName: fastLaneData?.results[0]?.vehicle?.fla_maker_desc,
        }),
        ownershipChanged: "N", //from api
        ...(vehicleData && {
          engineNo:
            !_.isEmpty(fastLaneData?.results) &&
            fastLaneData?.results[0]?.vehicle?.eng_no,
          chassisNo:
            !_.isEmpty(fastLaneData?.results) &&
            fastLaneData?.results[0]?.vehicle?.chasi_no,
          vehicleColor:
            !_.isEmpty(fastLaneData?.results) &&
            fastLaneData?.results[0]?.vehicle?.color,
        }),
        leadJourneyEnd: true,
        stage: 11,
        preventKafkaPush: true,
      };
      // quotesFilter state
      (fastLaneData?.ft_product_code === TypeReturn(type) ||
        !fastLaneData?.ft_product_code) &&
        dispatch(
          SaveQuoteDataQuotes(
            {
              ..._.pick(
                quoteData,
                _.without(_.keys(quoteData), "policyExpiryDate")
              ),
              lsq_stage: "Quote Seen",
              ...(fastLaneData?.additional_details?.policyExpiryDate
                ? differenceInDays(
                    toDate(fastLaneData?.additional_details?.policyExpiryDate),
                    toDate(moment().format("DD-MM-YYYY"))
                  ) < 45
                  ? { ...fastLaneData?.additional_details }
                  : _.pick(
                      fastLaneData?.additional_details,
                      _.without(
                        _.keys(fastLaneData?.additional_details),
                        "policyExpiryDate"
                      )
                    )
                : { ...fastLaneData?.additional_details }),
            },
            fastLaneData?.RenwalData === "Y"
              ? false
              : process.env.REACT_APP_BROKER === "RB"
          )
        );
      //home state
      (fastLaneData?.ft_product_code === TypeReturn(type) ||
        !fastLaneData?.ft_product_code) &&
        dispatch(
          SaveQuoteData({
            isRenewalRedirection: "N",
            ..._.pick(
              quoteData,
              _.without(_.keys(quoteData), "policyExpiryDate")
            ),
            ...(isPartner === "Y" && { frontendTags: "Y" }),
            ...(localStorage?.SSO_user && {
              tokenResp: JSON.parse(localStorage?.SSO_user),
            }),
            lsq_stage: "RC Submitted",
            ...(fastLaneData?.additional_details?.policyExpiryDate
              ? differenceInDays(
                  toDate(fastLaneData?.additional_details?.policyExpiryDate),
                  toDate(moment().format("DD-MM-YYYY"))
                ) < 45
                ? { ...fastLaneData?.additional_details }
                : _.pick(
                    fastLaneData?.additional_details,
                    _.without(
                      _.keys(fastLaneData?.additional_details),
                      "policyExpiryDate"
                    )
                  )
              : {
                  ...(localStorage?.SSO_user && {
                    tokenResp: JSON.parse(localStorage?.SSO_user),
                  }),
                  ..._.pick(
                    fastLaneData?.additional_details,
                    _.without(
                      _.keys(fastLaneData?.additional_details),
                      "policyExpiryDate"
                    )
                  ),
                }),
          })
        );
      dispatch(
        SaveLead({
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          leadStageId: 2,
        })
      );
      dispatch(
        set_temp_data({
          fastlaneJourney: true,
          fastlaneNcbPopup: true,
          isRenewalRedirection: "N",
        })
      );

      // dispatch(SaveQuoteDataQuotesKey(null));
    } else {
      if (
        (fastLaneData?.status * 1 === 101 ||
          fastLaneData?.status * 1 === 108) &&
        !fastLaneData?.showMessage
      ) {
        onSubmit(1);
        console.log("fired_submit");
      } else {
        fastLaneData?.showMessage &&
          swal("Info", fastLaneData?.showMessage, "info").then(() => [
            dispatch(setFastLane(false)),
            setBuffer(false),
            setbtnDisable(false),
            setValue("regNo", ""),
          ]);
      }
    }
  }, [fastLaneData]);

  //journey mismatch
  useEffect(() => {
    if (
      fastLaneData?.ft_product_code !== TypeReturn(type) &&
      // TypeReturn(type) !== "cv" &&
      (process.env.REACT_APP_BROKER === "ABIBL" ||
        process.env.REACT_APP_BROKER === "RB" ||
        (process.env.REACT_APP_BROKER === "GRAM") ||
        process.env?.REACT_APP_BROKER === "SPA" ||
        process.env?.REACT_APP_BROKER === "PINC" ||
        // process.env.REACT_APP_BROKER === "EPOCH" ||
        process.env?.REACT_APP_BROKER === "BAJAJ" ||
        process.env?.REACT_APP_BROKER === "OLA" ||
        process.env?.REACT_APP_BROKER === "TATA" ||
        process.env.REACT_APP_BROKER === "ACE") &&
      fastLaneData?.status !== 101
    ) {
      setBuffer(false);
      setbtnDisable(false);
      setShow(fastLaneData?.ft_product_code);
    }
  }, [fastLaneData]);

  //Url
  useEffect(() => {
    if (
      enquiry_id &&
      temp_data?.journeyStage?.stage &&
      temp_data?.userProposal?.isBreakinCase !== "Y"
    ) {
      ![
        "Payment Initiated",
        "pg_redirection",
        "Policy Issued",
        "Policy Issued, but pdf not generated",
        "Policy Issued And PDF Generated",
        "payment success",
        "payment failed",
        "Inspection Accept",
      ].includes(
        ["payment success", "payment failed"].includes(
          temp_data?.journeyStage?.stage.toLowerCase()
        )
          ? temp_data?.journeyStage?.stage.toLowerCase()
          : temp_data?.journeyStage?.stage
      ) &&
        dispatch(
          Url({
            proposalUrl: window.location.href,
            quoteUrl: window.location.href,
            stage: "Lead Generation",
            userProductJourneyId: enquiry_id,
            ...(type !== "cv" && { section: type }),
          })
        );
    }
    if (
      temp_data?.journeyStage?.stage === "Payment Initiated" ||
      temp_data?.journeyStage?.stage.toLowerCase() === "payment failed"
    ) {
      dispatch(DuplicateEnquiryId({ enquiryId: enquiry_id }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.journeyStage?.stage]);

  //generate new enquiry id.
  useEffect(() => {
    if (duplicateEnquiry?.enquiryId) {
      swal(
        "Please Note",
        "Payment status is Incomplete. Proposal update required.",
        "info"
      ).then(() => {
        reloadPage(
          `${window.location.protocol}//${window.location.host}${
            process.env.REACT_APP_BASENAME !== "NA"
              ? `/${process.env.REACT_APP_BASENAME}`
              : ``
          }/${type}/registration?enquiry_id=${duplicateEnquiry?.enquiryId}${
            token ? `&token=${token}` : ""
          }${typeId ? `&typeid=${typeId}` : ``}${
            journey_type ? `&journey_type=${journey_type}` : ``
          }`
        );
      });
    }
    return () => {
      dispatch(clrDuplicateEnquiry());
    };
  }, [duplicateEnquiry]);

  //Journey already submitted
  const PaymentSuccessfulStages = [
    "Policy Issued And PDF Generated",
    "Policy Issued",
    "Policy Issued, but pdf not generated",
    "payment success",
  ];

  useEffect(() => {
    if (
      PaymentSuccessfulStages.includes(
        temp_data?.journeyStage?.stage.toLowerCase() === "payment success"
          ? "payment success"
          : temp_data?.journeyStage?.stage
      )
    ) {
      swal("Info", "This Proposal has already been submitted", "info").then(
        () =>
          temp_data?.journeyStage?.stage.toLowerCase() !== "payment failed"
            ? reloadPage(
                `${window.location.protocol}//${window.location.host}${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ``
                }/payment-success${
                  enquiry_id ? `?enquiry_id=${enquiry_id}` : ``
                }`
              )
            : reloadPage(
                `${window.location.protocol}//${window.location.host}${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ``
                }/payment-success${
                  enquiry_id ? `?enquiry_id=${enquiry_id}` : ``
                }`
              )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.journeyStage?.stage]);

  //redirected Prefill (Renewbuy)
  useEffect(() => {
    if (reg_no_url && reg_no_url !== "NULL") {
      setValue("regNo", reg_no_url);
    }
  }, [reg_no_url]);

  //prefill
  const regIpCheck = watch("regNo") || "";
  useEffect(() => {
    if (temp_data?.regNo && (!regIpCheck || regIpCheck === temp_data?.regNo)) {
      temp_data?.regNo !== "NEW" &&
        setValue(
          "regNo",
          `${temp_data?.regNo1.split("-")[0]}-${
            temp_data?.regNo1.split("-")[0] === "DL" && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
              ? Number(temp_data?.regNo1.split("-")[1])
              : temp_data?.regNo1.split("-")[1]
          }-${temp_data?.regNo2}-${temp_data?.regNo3}`.replace(/--/g, "-")
        );
    }
    if (temp_data?.regNo1) {
      setValue(
        "regNo1",
        `${temp_data?.regNo1.split("-")[0]}-${
          temp_data?.regNo1.split("-")[0] === "DL" && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
            ? Number(temp_data?.regNo1.split("-")[1])
            : temp_data?.regNo1.split("-")[1]
        }`
      );
    }
    if (temp_data?.regNo2) {
      setValue("regNo2", temp_data?.regNo2);
    }
    if (temp_data?.regNo3) {
      setValue("regNo3", temp_data?.regNo3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data]);

  //onSuccess
  useEffect(() => {
    if (saveQuoteData && TypeReturn(type)) {
      setBuffer(false);
      //fast lane redirection
      if (fastLaneData?.status === 100) {
        fastLaneData?.RenwalData === "Y" &&
          fastLaneData?.additional_details.previousPolicyType ===
            "Third Party" &&
          dispatch(TabClick(true));
        dispatch(
          set_temp_data({
            isRenewalRedirection: "N",
            //storing data in redux for fastlane / ongrid
            ...(fastLaneData?.additional_details && {
              policyType: fastLaneData?.additional_details.previousPolicyType,
              productSubTypeId:
                fastLaneData?.additional_details?.productSubTypeId,
              newCar:
                fastLaneData?.additional_details?.businessType ===
                "newbusiness",
              breakIn:
                fastLaneData?.additional_details?.businessType === "breakin",
              prevIc: fastLaneData?.additional_details?.previousInsurerCode,
              prevIcFullName: fastLaneData?.additional_details?.previousInsurer,
              odOnly:
                fastLaneData?.additional_details?.policyType === "own_damage",
            }),
          })
        );
        dispatch(CancelAll(false));
        setTimeout(() => {
          if (
            fastLaneData?.redirection_data?.is_redirection &&
            fastLaneData?.redirection_data?.redirection_url
          ) {
            reloadPage(fastLaneData?.redirection_data?.redirection_url);
          } else {
            //partial mmv logic
            if (!_.isEmpty(fastLaneData?.results)) {
              let { manfactureId, model, version, vehicleRegisterDate } =
                fastLaneData?.additional_details;
              //all data fetched
              if (manfactureId && model && version && vehicleRegisterDate) {
                history.push(
                  `/${type}/quotes?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }${journey_type ? `&journey_type=${journey_type}` : ``}`
                );
              }
              // reg date missing
              else if (manfactureId && model && version) {
                history.push(
                  `/${type}/vehicle-details?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }&stepperfill=${Encrypt("date")}${
                    journey_type ? `&journey_type=${journey_type}` : ``
                  }`
                );
              }
              //version missing
              else if (manfactureId && model) {
                history.push(
                  `/${type}/vehicle-details?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }&stepperfill=4${
                    journey_type ? `&journey_type=${journey_type}` : ``
                  }`
                );
              }
              //all data fetched
              else if (manfactureId) {
                history.push(
                  `/${type}/vehicle-details?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }&stepperfill=2${
                    journey_type ? `&journey_type=${journey_type}` : ``
                  }`
                );
              } else {
                history.push(
                  `/${type}/vehicle-details?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }&stepperfill=1${
                    journey_type ? `&journey_type=${journey_type}` : ``
                  }`
                );
              }
            }
          }
        }, 1000);
      }
      //Non fast lane redirection
      else {
        setBuffer(false);
        if (TypeReturn(type) === "cv" && TypeReturn(type)) {
          !_.isEmpty(fastLaneData)
            ? swal({
                title: "Please Note",
                text:
                  theme_conf?.broker_config?.fastlane_error_message ||
                  "We are unable to fetch your vehicle details at this moment. Please input your vehicle Make, Model, RTO details and proceed",
                icon: "info",
                buttons: {
                  catch: {
                    text: "Edit Reg. No.",
                    value: "confirm",
                  },
                  ...(!theme_conf?.broker_config?.journey_block && {
                    No: {
                      text: `Proceed`,
                      value: "No",
                    },
                  }),
                },
                dangerMode: true,
                closeOnClickOutside: false,
              }).then((caseValue) => {
                switch (caseValue) {
                  case "confirm":
                    navigator &&
                      navigator?.vibrate &&
                      navigator.vibrate([100, 0, 50]);
                    setValue("regNo", "");
                    break;
                  case "No":
                    history.push(
                      `/${type}/vehicle-type?enquiry_id=${
                        temp_data?.enquiry_id || enquiry_id
                      }${token ? `&token=${token}` : ``}${
                        typeId ? `&typeid=${typeId}` : ``
                      }${journey_type ? `&journey_type=${journey_type}` : ``}`
                    );
                    break;
                  default:
                }
              })
            : history.push(
                `/${type}/vehicle-type?enquiry_id=${
                  temp_data?.enquiry_id || enquiry_id
                }${token ? `&token=${token}` : ``}${
                  typeId ? `&typeid=${typeId}` : ``
                }${journey_type ? `&journey_type=${journey_type}` : ``}`
              );
        } else {
          if (temp_data?.productSubTypeCode && TypeReturn(type))
            !_.isEmpty(fastLaneData)
              ? swal({
                  title: "Please Note",
                  text:
                    theme_conf?.broker_config?.fastlane_error_message ||
                    "We are unable to fetch your vehicle details at this moment. Please input your vehicle Make, Model, RTO details and proceed",
                  icon: "info",
                  buttons: {
                    catch: {
                      text: "Edit Reg. No.",
                      value: "confirm",
                    },
                    ...(!theme_conf?.broker_config?.journey_block && {
                      No: {
                        text: `Proceed`,
                        value: "No",
                      },
                    }),
                  },
                  dangerMode: true,
                  closeOnClickOutside: false,
                }).then((caseValue) => {
                  switch (caseValue) {
                    case "confirm":
                      navigator &&
                        navigator?.vibrate &&
                        navigator.vibrate([100, 0, 50]);
                      setValue("regNo", "");
                      break;
                    case "No":
                      history.push(
                        `/${type}/vehicle-details?enquiry_id=${
                          temp_data?.enquiry_id || enquiry_id
                        }${token ? `&token=${token}` : ``}${
                          typeId ? `&typeid=${typeId}` : ``
                        }${journey_type ? `&journey_type=${journey_type}` : ``}`
                      );
                      break;
                    default:
                  }
                })
              : history.push(
                  `/${type}/vehicle-details?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }${journey_type ? `&journey_type=${journey_type}` : ``}`
                );
        }
      }
    }

    return () => {
      dispatch(clear("saveQuoteData"));
      saveQuoteData && dispatch(setFastLane(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveQuoteData, temp_data]);

  //onError
  useEffect(() => {
    if (errorProp) {
      setbtnDisable(false);
      setBuffer(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorProp]);

  //Product SubType (only when product category is not CV)
  useEffect(() => {
    if (TypeReturn(type) !== "cv" && TypeReturn(type)) {
      dispatch(Category({ productType: TypeReturn(type) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  /*-------Handling changes for Inputs-------*/
  //regNo1
  const handleChange = (e) => {
    // removing spaces but retaining its keyCode
    let value = e.target.value.trim();
    if (e.keyCode === 8) {
      value = "";
    } else if (value.length === 2) {
      e.target.value = value + "-";
    }
    if (value.length === 4) {
      //if the user enters single serial after rto state then by hitting space the user can move to next feild
      if (e.keyCode === 32) {
        document.querySelector(`input[name=regNo2]`).focus();
      }
    }
    if (value.length === 5) {
      //if the user enters single serial after rto state then by hitting alphabet the user can move to next feild
      if (!(value.slice(-1) * 1 || value.slice(-1) * 1 === 0)) {
        e.target.value = value.slice(0, -1);
        //to revalidate using yup
        document.querySelector(`input[name=regNo1]`).blur();
        //focusing input 2 $ setting value
        document.querySelector(`input[name=regNo2]`).focus();
        setValue("regNo2", value.slice(-1));
      } else {
        //arrow keys are excluded for navigation purposes
        if (
          e.keyCode !== 37 &&
          e.keyCode !== 38 &&
          e.keyCode !== 39 &&
          e.keyCode !== 40
        )
          document.querySelector(`input[name=regNo2]`).focus();
      }
    }
  };

  //regNo2
  const handleChange2 = (e) => {
    // removing spaces but retaining its keyCode
    let value = e.target.value;
    if (value.length === 3) {
      //If the 3rd element is a number, then focusing on 3rd input & setting it's value
      if (value.slice(-1) * 1 || value.slice(-1) * 1 === 0) {
        e.target.value = value.slice(0, -1);
        //to revalidate using yup
        document.querySelector(`input[name=regNo2]`).blur();
        //focusing input 3 & setting value
        document.querySelector(`input[name=regNo3]`).focus();
        setValue("regNo3", value.slice(-1));
      } else {
        //arrow keys are excluded for navigation purposes
        if (
          e.keyCode !== 37 &&
          e.keyCode !== 38 &&
          e.keyCode !== 39 &&
          e.keyCode !== 40
        )
          document.querySelector(`input[name=regNo3]`).focus();
      }
    }
    // on backspace , focus on previous input
    if (!value.length && (e.keyCode === 8 || e.keyCode === 46)) {
      document.querySelector(`input[name=regNo1]`).focus();
    }
    // if the input is empty , then by pressing space the focus should shift to next input.
    if (!value.trim().length && e.keyCode === 32) {
      document.querySelector(`input[name=regNo3]`).focus();
    }
  };

  //regNo3
  const handleChange3 = (e) => {
    // removing spaces but retaining its keyCode
    let value = e.target.value.trim();
    // on backspace , focus on previous input
    if (!value.length && (e.keyCode === 8 || e.keyCode === 46)) {
      //focus will shift to the middle input if it has any data, else the focus will be on the first input
      let regStr2 = watch("regNo2") || "";
      regStr2.trim()
        ? document.querySelector(`input[name=regNo2]`).focus()
        : document.querySelector(`input[name=regNo1]`).focus();
    }
  };
  /*---x---Handling changes for Inputs---x---*/
  const regIp = watch("regNo") || "";

  //setting the value of SingleRegIp to divided reg Ip's
  useEffect(() => {
    if (
      regIp.match(/^[A-Z]{2}[-][0-9]{1,2}[-\s][A-Z0-9]{1,3}[-\s][0-9]{4}$/) ||
      regIp.match(/^[A-Z]{2}[-][0-9]{1,2}[-\s][0-9]{4}$/)
    ) {
      setValue(
        "regNo1",
        regIp && regIp !== "NEW"
          ? `${regIp?.split("-")[0]}-${regIp?.split("-")[1]}`
          : ""
      );

      setValue(
        "regNo2",
        regIp && regIp && regIp !== "NEW"
          ? `${
              _.compact(regIp?.split("-"))?.length === 4
                ? regIp?.split("-")[2]
                : ""
            }`
          : ""
      );

      setValue(
        "regNo3",
        regIp && regIp !== "NEW"
          ? `${
              _.compact(regIp?.split("-"))?.length === 4
                ? regIp?.split("-")[3]
                : _.compact(regIp?.split("-"))[2]
            }`
          : ""
      );
    } else {
      setValue("regNo1", "");
      setValue("regNo2", "");
      setValue("regNo3", "");
    }
    if (!regIp) {
      setValue("regNo1", "");
      setValue("regNo2", "");
      setValue("regNo3", "");
    }
  }, [regIp]);

  //varibles for reg inputs
  let regNo1 = watch("regNo1");
  regNo1 = regNo1 ? regNo1.replace(/\s/g, "") : ""; //trim white-spaces
  let regNo2 = watch("regNo2");
  regNo2 = regNo2 ? regNo2.replace(/\s/g, "") : ""; //trim white-spaces
  let regNo3 = watch("regNo3");

  const onSubmit = (journeyType) => {
    if (
      isAndroidWebView &&
      process.env.REACT_APP_BROKER === "RB" &&
      !localStorage?.SSO_user_motor
    ) {
      swal(
        "Attention",
        "Login to experience the new & faster journey",
        "info"
      ).then(() => {
        setbtnDisable(false);
        setBuffer(false);
        document.getElementById("widgetJslogin") &&
          document.getElementById("widgetJslogin").click();
      });
    } else {
      dispatch(CancelAll(false));
      if (
        (process.env?.REACT_APP_API_BASE_URL ===
          "https://apimotor.fynity.in/api" ||
          process.env?.REACT_APP_BROKER === "ABIBL" ||
          process.env?.REACT_APP_API_BASE_URL ===
            "https://api-carbike.fynity.in/api" ||
          process.env?.REACT_APP_BROKER === "OLA" ||
          process.env?.REACT_APP_BROKER === "ACE" ||
          process.env?.REACT_APP_BROKER === "RB" ||
          // process.env?.REACT_APP_BROKER === "EPOCH" ||
          process.env?.REACT_APP_BROKER === "PINC" ||
          process.env?.REACT_APP_BROKER === "BAJAJ" ||
          process.env?.REACT_APP_BROKER === "SPA" ||
          process.env?.REACT_APP_BROKER === "TATA" ||
          (process.env.REACT_APP_BROKER === "GRAM")) &&
        Number(journeyType) === 1 &&
        fastLaneData?.status !== 101 &&
        fastLaneData?.status !== 108
      ) {
        onSubmitFastLane();
      } else {
        if (
          (Number(journeyType) === 1 && regNo1 && regNo3) ||
          Number(journeyType) === 2 ||
          Number(journeyType) === 3
        ) {
          if (Number(journeyType) !== 1) {
            tabClick && dispatch(TabClick(false));
            dispatch(
              set_temp_data({
                isRenewalRedirection: "N",
                journeyWithoutRegno: "Y",
                journeyType,
                newCar: journeyType * 1 === 3,
                regNo: null,
                regNo1: null,
                regNo2: null,
                regNo3: null,
                regDate: null,
                fastlaneJourney: false,
                ...(TypeReturn(type) !== "cv" &&
                  TypeReturn(type) &&
                  !_.isEmpty(category) && {
                    productSubTypeId: category?.product_sub_type_id,
                    productSubTypeCode: category?.product_sub_type_code,
                    productSubTypeName: category?.product_sub_type_code,
                  }),
                //clearing vehicle type
                // productSubTypeId: null,
                // productSubTypeCode: null,
                // productCategoryName: null,
                // carrierType: null,
              })
            );
            dispatch(
              SaveQuoteData({
                stage: "2",
                ...(isPartner === "Y" && { frontendTags: "Y" }),
                ...(localStorage?.SSO_user && {
                  tokenResp: JSON.parse(localStorage?.SSO_user),
                }),
                journeyWithoutRegno: "Y",
                vehicleRegistrationNo:
                  Number(journeyType) === 3 ? "NEW" : "NULL",
                ...(token && { token: token }),
                userProductJourneyId: enquiry_id,
                enquiryId: enquiry_id,
                vehicleRegisterDate: "NULL",
                policyExpiryDate: "NULL",
                previousInsurerCode: "NULL",
                previousInsurer: "NULL",
                previousPolicyType: "NULL",
                businessType: journeyType * 1 === 3 ? "newbusiness" : "NULL",
                policyType: "NULL",
                previousNcb: "NULL",
                applicableNcb: "NULL",
                fastlaneJourney: false,
                isRenewalRedirection: "N",
                ...(journey_type && {
                  journeyType: journey_type,
                }),
                ...(TypeReturn(type) !== "cv" &&
                  TypeReturn(type) &&
                  !_.isEmpty(category) && {
                    productSubTypeId: category?.product_sub_type_id,
                    productSubTypeCode: category?.product_sub_type_code,
                    productSubTypeName: category?.product_sub_type_code,
                  }),
              })
            );
          } else {
            if (
              (regNo1 &&
                regNo2 &&
                regNo3 &&
                `${regNo1}-${regNo2}-${regNo3}`.match(
                  /^[A-Z]{2}[-][0-9]{1,2}[-\s][A-Z0-9]{1,3}[-\s][0-9]{4}$/
                )) ||
              (regNo1 &&
                !regNo2 &&
                regNo3 &&
                `${regNo1}-${regNo3}`.match(
                  /^[A-Z]{2}[-][0-9]{1,2}[-\s][0-9]{4}$/
                ))
            ) {
              dispatch(
                set_temp_data({
                  journeyType,
                  regNo1,
                  regNo2,
                  regNo3,
                  regNo: regNo2
                    ? `${
                        Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
                          ? `${regNo1.split("-")[0]}-0${Number(
                              regNo1.split("-")[1]
                            )}`
                          : regNo1
                      }-${regNo2}-${regNo3}`
                    : `${
                        Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
                          ? `${regNo1.split("-")[0]}-0${Number(
                              regNo1.split("-")[1]
                            )}`
                          : regNo1
                      }--${regNo3}`,
                  vehicleRegisterDate: null,
                  journeyWithoutRegno: "N",
                  fastlaneJourney: false,
                  ...(TypeReturn(type) !== "cv" &&
                    TypeReturn(type) &&
                    !_.isEmpty(category) && {
                      productSubTypeId: category?.product_sub_type_id,
                      productSubTypeCode: category?.product_sub_type_code,
                      productSubTypeName: category?.product_sub_type_code,
                    }),
                  isRenewalRedirection: "N",
                  //clearing vehicle type
                  // productSubTypeId: null,
                  // productSubTypeCode: null,
                  // productCategoryName: null,
                  // carrierType: null,
                })
              );
              dispatch(
                SaveQuoteData({
                  stage: "2",
                  journeyWithoutRegno: "N",
                  vehicleRegistrationNo: regNo2
                    ? `${
                        Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
                          ? `${regNo1.split("-")[0]}-0${Number(
                              regNo1.split("-")[1]
                            )}`
                          : regNo1
                      }-${regNo2}-${regNo3}`
                    : `${
                        Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
                          ? `${regNo1.split("-")[0]}-0${Number(
                              regNo1.split("-")[1]
                            )}`
                          : regNo1
                      }--${regNo3}`,
                  rtoNumber:
                    Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
                      ? `${regNo1.split("-")[0]}-0${Number(
                          regNo1.split("-")[1]
                        )}`
                      : regNo1,
                  rto:
                    Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
                      ? `${regNo1.split("-")[0]}-0${Number(
                          regNo1.split("-")[1]
                        )}`
                      : regNo1,
                  userProductJourneyId: enquiry_id,
                  vehicleRegisterAt:
                    Number(regNo1.split("-")[1]) < 10 && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
                      ? `${regNo1.split("-")[0]}-0${Number(
                          regNo1.split("-")[1]
                        )}`
                      : regNo1,
                  ...(isPartner === "Y" && { frontendTags: "Y" }),
                  ...(journey_type && {
                    journeyType: journey_type,
                  }),
                  enquiryId: enquiry_id,
                  vehicleRegisterDate: "NULL",
                  policyExpiryDate: "NULL",
                  previousInsurerCode: "NULL",
                  previousInsurer: "NULL",
                  previousPolicyType: "NULL",
                  businessType: "NULL",
                  policyType: "NULL",
                  previousNcb: "NULL",
                  applicableNcb: "NULL",
                  fastlaneJourney: false,
                  isRenewalRedirection: "N",
                  ...(TypeReturn(type) !== "cv" &&
                    TypeReturn(type) &&
                    !_.isEmpty(category) && {
                      productSubTypeId: category?.product_sub_type_id,
                      productSubTypeCode: category?.product_sub_type_code,
                      productSubTypeName: category?.product_sub_type_code,
                    }),
                })
              );
              setTimeout(() => setbtnDisable(false), 2000);
            } else {
              swal("Warning", "Invalid Registration Number", "warning").then(
                () => setTimeout(() => setbtnDisable(false), 1000)
              );
            }
          }
        } else {
          swal("Error", "Please fill all the details", "error").then(() =>
            setTimeout(() => setbtnDisable(false), 1000)
          );
        }
      }
    }
  };
  /*========= single regNp ip =========*/

  //finding number of blocks
  const segmentIndexes = regIp && regIp.split("-");
  //finding the length of the rto block
  const segmentInfo =
    regIp &&
    segmentIndexes &&
    !_.isEmpty(segmentIndexes) &&
    segmentIndexes?.length >= 1
      ? segmentIndexes[1]?.length
      : "";

  //finding the length of middle segment
  let midBlockCheck =
    regIp &&
    regIp.length > (Number(segmentInfo) === 1 ? 4 : 5) &&
    regIp.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1;

  const MidsegmentInfo =
    regIp &&
    !midBlockCheck &&
    segmentIndexes &&
    !_.isEmpty(segmentIndexes) &&
    segmentIndexes?.length >= 2
      ? segmentIndexes[2]?.length
      : "";

  //setting maxlength of regFeild & indicating if the middle block is empty.
  let maxlen =
    regIp &&
    segmentInfo &&
    regIp.length > (Number(segmentInfo) === 1 ? 4 : 5) &&
    regIp.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1
      ? Number(segmentInfo) === 1
        ? "9"
        : "10"
      : Number(segmentInfo) === 1
      ? Number(MidsegmentInfo) === 1
        ? "11"
        : Number(MidsegmentInfo) === 2
        ? "12"
        : "13"
      : Number(MidsegmentInfo) === 1
      ? "12"
      : Number(MidsegmentInfo) === 2
      ? "13"
      : "14";

  const SingleKey = (e) => {
    let value = e.target.value.trim().replace(/--/g, "-");
    let middleBlock =
      value &&
      value.length > (Number(segmentInfo) === 1 ? 4 : 5) &&
      (value.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1 ||
        value.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1 === 0);
    //blocking keys when max len = e.target.value.length
    //handling blocks using length.
    /*-----rto block-----*/
    if (e.keyCode === 8 || e.keyCode === 46) {
      value = "";
    }
    if (value.length === 2 && !(e.keyCode === 8 || e.keyCode === 46)) {
      e.target.value = value.slice(-1) !== "-" ? value + "-" : value;
    }
    if (value.length === 5) {
      //if the user enters single serial after rto state then by hitting alphabet the user can generate next feild
      if (
        !(value.slice(-1) * 1 || value.slice(-1) * 1 === 0) &&
        !value.slice(-2).includes("-")
      ) {
        //slicing the last char, adding "-" and appending the last char
        e.target.value = value.slice(0, -1) + "-" + value.slice(-1);
      } else {
        //arrow keys are excluded for navigation purposes
        if (
          e.keyCode !== 37 &&
          e.keyCode !== 38 &&
          e.keyCode !== 39 &&
          e.keyCode !== 40
        )
          e.target.value = value.slice(-1) === "-" ? value : value + "-";
      }
    }
    /*--x--rto block--x--*/

    /*-----Middle & Last Block-----*/
    //if the user enters an alphabet after rto block
    if (value.length > 5) {
      //first two entries of the midle block are validated in onchangesingle
      //the third entry in middle block can be a number or an alphabet.
      if (!middleBlock && (value?.length > Number(segmentInfo) === 1 ? 7 : 8)) {
        //if the 2nd entry is a number
        if (
          (value.split("")[Number(segmentInfo) === 1 ? 7 : 8] * 1 ||
            value.split("")[Number(segmentInfo) === 1 ? 7 : 8] * 1 === 0) &&
          !value.slice(-2).includes("-") &&
          value.split("")[Number(segmentInfo) === 1 ? 7 : 8] !== "-" &&
          value.split("")[Number(segmentInfo) === 1 ? 6 : 7] !== "-"
        ) {
          e.target.value = value.slice(0, -1) + "-" + value.slice(-1);
        }
        //if the 2nd entry is a number
        else if (
          !middleBlock &&
          (value.split("")[Number(segmentInfo) === 1 ? 6 : 7] * 1 ||
            value.split("")[Number(segmentInfo) === 1 ? 6 : 7] * 1 === 0) &&
          (value?.length > Number(segmentInfo) === 1 ? 6 : 7) &&
          !value.slice(-2).includes("-") &&
          value.split("")[Number(segmentInfo) === 1 ? 7 : 8] !== "-"
        ) {
          e.target.value = value.slice(0, -1) + "-" + value.slice(-1);
        } else {
          //last block generation , if all the entries are non number
          if (
            !middleBlock &&
            value?.length === (Number(segmentInfo) === 1 ? 8 : 9) &&
            value.split("")[Number(segmentInfo) === 1 ? 7 : 8] !== "-" &&
            value.split("")[Number(segmentInfo) === 1 ? 6 : 7] !== "-"
          ) {
            e.target.value = value.slice(-2) === "-" ? value : value + "-";
          }
        }
      }

      //onSubmit
      if (
        !middleBlock &&
        MidsegmentInfo &&
        value.length ===
          (Number(segmentInfo) === 1
            ? Number(MidsegmentInfo) === 1
              ? 11
              : Number(MidsegmentInfo) === 2
              ? 12
              : 13
            : Number(MidsegmentInfo) === 1
            ? 12
            : Number(MidsegmentInfo) === 2
            ? 13
            : 14) &&
        e.keyCode !== 37 &&
        e.keyCode !== 37 &&
        e.keyCode !== 38 &&
        e.keyCode !== 39 &&
        e.keyCode !== 40 &&
        e.keyCode !== 8 &&
        e.keyCode !== 46
      ) {
        //onsubmit
        !stepper1 &&
          value.match(
            /^[A-Z]{2}[-][0-9]{1,2}[-\s][A-Z0-9]{1,3}[-\s][0-9]{4}$/
          ) &&
          temp_data?.regNo !== value &&
          setBuffer(true);

        !stepper1 &&
          value.match(
            /^[A-Z]{2}[-][0-9]{1,2}[-\s][A-Z0-9]{1,3}[-\s][0-9]{4}$/
          ) &&
          temp_data?.regNo !== value &&
          onSubmitFastLane();
      }
      if (
        middleBlock &&
        value.length === (Number(segmentInfo) === 1 ? 9 : 10) &&
        e.keyCode !== 37 &&
        e.keyCode !== 37 &&
        e.keyCode !== 38 &&
        e.keyCode !== 39 &&
        e.keyCode !== 40 &&
        e.keyCode !== 8 &&
        e.keyCode !== 46
      ) {
        //onsubmit
        !stepper1 &&
          value.match(/^[A-Z]{2}[-][0-9]{1,2}[-\s][0-9]{4}$/) &&
          temp_data?.regNo !== value &&
          setBuffer(true);

        !stepper1 &&
          value.match(/^[A-Z]{2}[-][0-9]{1,2}[-\s][0-9]{4}$/) &&
          temp_data?.regNo !== value &&
          onSubmitFastLane();
      }
      /*--x--Middle & Last Block--x--*/
    }
  };

  //Validations and key limitations
  const onChangeSingle = (e) => {
    let value = e.target.value.trim().replace(/--/g, "-");
    if (value.length < 3) {
      e.target.value = e.target.value.replace(/[^A-Za-z\s]/gi, "");
    }
    if (value.length === 4) {
      e.target.value =
        Number(value.split("")[3] * 1) || Number(value.split("")[3] * 1) === 0
          ? e.target.value
          : e.target.value.slice(0, -1);
    }
    let middleBlock =
      value &&
      value.length > (Number(segmentInfo) === 1 ? 4 : 5) &&
      (value.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1 ||
        value.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1 === 0);
    if (value.length > 5) {
      //checking the numeric block if middle block is not present
      if (value.length > (Number(segmentInfo) === 1 ? 6 : 7) && middleBlock) {
        e.target.value =
          e.target.value.slice(-1) * 1 || e.target.value.slice(-1) * 1 === 0
            ? e.target.value
            : e.target.value.slice(0, -1);
      }
      //middle block two alphabet check.
      if (
        value.length > (Number(segmentInfo) === 1 ? 5 : 6) &&
        value.length < (Number(segmentInfo) === 1 ? 8 : 9) &&
        !middleBlock
      ) {
        //checking if the middle block's 1st entry is an alphabet
        if (value.length === (Number(segmentInfo) === 1 ? 6 : 7)) {
          e.target.value = e.target.value
            .split("")
            [Number(segmentInfo) === 1 ? 5 : 6].match(/^[a-zA-Z]*$/)
            ? e.target.value
            : e.target.value.slice(0, -1);
        }
        // checking if the middle block's 2nd entry is an alphabet & alphabet
        if (value.length === (Number(segmentInfo) === 1 ? 7 : 8)) {
          e.target.value = e.target.value
            .split("")
            [Number(segmentInfo) === 1 ? 6 : 7].match(/^[a-zA-Z0-9]*$/)
            ? e.target.value
            : e.target.value.slice(0, -1);
        }
      }
      //The last block entries must be numeric
      if (
        !middleBlock &&
        MidsegmentInfo &&
        value.length >
          (Number(segmentInfo) === 1
            ? Number(MidsegmentInfo) === 2
              ? 8
              : 9
            : Number(MidsegmentInfo) === 2
            ? 9
            : 10)
      ) {
        //letter numeric check
        e.target.value =
          e.target.value.slice(-1) * 1 || e.target.value.slice(-1) * 1 === 0
            ? e.target.value
            : e.target.value.slice(0, -1);
      }
    }
  };

  /*-----on paste-----*/
  const onPaste = (e, validate) => {
    if (!validate) {
      e.preventDefault();
    }
    let value = !validate
      ? e.clipboardData
          .getData("Text")
          .trim()
          .replace(/[^A-Za-z0-9]/gi, "")
      : e || "";
    let strlength = value.length;
    //formatting text
    let rtoState =
      Number(strlength) >= 2 &&
      value.slice(0, 2).match(/^[a-zA-Z]*$/) &&
      value.slice(0, 2);
    let rtoNum =
      Number(strlength) >= 4 && value.slice(2, 4).match(/^[0-9]*$/)
        ? value.slice(2, 4)
        : Number(strlength) >= 4 && value.slice(2, 3).match(/^[0-9]*$/)
        ? value.slice(2, 3)
        : "";
    let middleBlockStart = rtoNum ? rtoNum.length + 2 : false;
    //is middle block ineligible
    let middleblockcheck =
      rtoNum &&
      Number(strlength) >= middleBlockStart &&
      value.split("")[middleBlockStart] * 1;
    //middle block
    let middleblock =
      (rtoNum &&
        Number(strlength) >= middleBlockStart + 2 &&
        !middleblockcheck &&
        (Number(strlength) >= rtoNum.length + 5 &&
        value.slice(middleBlockStart, middleBlockStart + 3).match(/^[a-zA-Z]*$/)
          ? value.slice(middleBlockStart, middleBlockStart + 3)
          : value
              .slice(middleBlockStart, middleBlockStart + 2)
              .match(/^[a-zA-Z]*$/)
          ? value.slice(middleBlockStart, middleBlockStart + 2)
          : value
              .slice(middleBlockStart, middleBlockStart + 1)
              .match(/^[a-zA-Z]*$/)
          ? value.slice(middleBlockStart, middleBlockStart + 1)
          : "")) ||
      "";

    let lastBlock =
      !middleblockcheck && rtoNum && middleblock
        ? //with middle block
          Number(strlength) >= rtoNum.length + middleblock?.length + 3 &&
          (Number(strlength) >= rtoNum.length + middleblock?.length + 6 &&
          value
            .slice(
              rtoNum.length + middleblock?.length + 2,
              rtoNum.length + middleblock?.length + 6
            )
            .match(/^[0-9]*$/)
            ? value.slice(
                rtoNum.length + middleblock?.length + 2,
                rtoNum.length + middleblock?.length + 6
              )
            : Number(strlength) >= rtoNum.length + middleblock?.length + 5 &&
              value
                .slice(
                  rtoNum.length + middleblock?.length + 2,
                  rtoNum.length + middleblock?.length + 5
                )
                .match(/^[0-9]*$/)
            ? value.slice(
                rtoNum.length + middleblock?.length + 2,
                rtoNum.length + middleblock?.length + 5
              )
            : Number(strlength) >= rtoNum.length + middleblock?.length + 4 &&
              value
                .slice(
                  rtoNum.length + middleblock?.length + 2,
                  rtoNum.length + middleblock?.length + 4
                )
                .match(/^[0-9]*$/)
            ? value.slice(
                rtoNum.length + middleblock?.length + 2,
                rtoNum.length + middleblock?.length + 4
              )
            : value
                .slice(
                  rtoNum.length + middleblock?.length + 2,
                  rtoNum.length + middleblock?.length + 3
                )
                .match(/^[0-9]*$/)
            ? value.slice(
                rtoNum.length + middleblock?.length + 2,
                rtoNum.length + middleblock?.length + 3
              )
            : "")
        : //without middle block
          Number(strlength) >= rtoNum.length + 3 &&
          (Number(strlength) >= rtoNum.length + 6 &&
          value.slice(rtoNum.length + 2, rtoNum.length + 6).match(/^[0-9]*$/)
            ? value.slice(rtoNum.length + 2, rtoNum.length + 6)
            : Number(strlength) >= rtoNum.length + 5 &&
              value
                .slice(rtoNum.length + 2, rtoNum.length + 5)
                .match(/^[0-9]*$/)
            ? value.slice(rtoNum.length + 2, rtoNum.length + 5)
            : Number(strlength) >= rtoNum.length + 4 &&
              value
                .slice(rtoNum.length + 2, rtoNum.length + 4)
                .match(/^[0-9]*$/)
            ? value.slice(rtoNum.length + 2, rtoNum.length + 4)
            : Number(strlength) >= rtoNum.length + 3 &&
              value
                .slice(rtoNum.length + 2, rtoNum.length + 3)
                .match(/^[0-9]*$/)
            ? value.slice(rtoNum.length + 2, rtoNum.length + 3)
            : "");

    let finalRegNum =
      rtoState &&
      rtoNum &&
      `${rtoState}-${rtoNum}${middleblock ? `-${middleblock}` : ""}${
        lastBlock ? `-${lastBlock}` : ""
      }`;

    !validate && finalRegNum && setValue("regNo", finalRegNum.toUpperCase());
    return !validate ? false : finalRegNum;
  };
  /*--x--on paste--x--*/

  /*====x==== single regNp ip ====x====*/

  /*-----journey change-----*/
  const journeyChange = () => {
    if (frontendurl) {
      if (frontendurl?.car_frontend_url && TypeReturn(show) === "car") {
        reloadPage(
          `${
            frontendurl?.car_frontend_url
          }/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`
        );
      } else if (
        frontendurl?.bike_frontend_url &&
        TypeReturn(show) === "bike"
      ) {
        reloadPage(
          `${
            frontendurl?.bike_frontend_url
          }/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`
        );
      } else if (frontendurl?.cv_frontend_url && TypeReturn(show) === "cv") {
        reloadPage(
          `${
            frontendurl?.cv_frontend_url
          }/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`
        );
      }
    } else {
      reloadPage(
        redirection(
          TypeReturn(show),
          process.env.REACT_APP_BROKER,
          process.env.REACT_APP_API_BASE_URL,
          token,
          enquiry_id
        )
      );
    }
  };
  /*--x--journey change--x--*/
  return !stepper1 ? (
    <>
      <StyledBack className={lessthan767 ? "ml-1 backBtn" : "backBtn"}>
        {(!theme_conf?.broker_config?.noBack || !token) && (
          <BackButton type="button" onClick={back}>
            {!lessthan767 ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className=""
                  viewBox="0 0 24 24"
                >
                  <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
                <text style={{ color: "black" }}>Back</text>
              </>
            ) : (
              <img
                src={`${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ""
                }/assets/images/back-button.png`}
                alt="bck"
              />
            )}
          </BackButton>
        )}
      </StyledBack>
      <Stylediv
        className="mt-1 mb-4 w-100 mx-auto d-flex flex-column align-content-center ElemFade mx-auto"
        style={
          !lessthan330 && lessthan767
            ? { position: "relative", top: "-42px" }
            : {}
        }
      >
        <Row className="text-center w-100 mx-auto">
          <div className="mt-4 d-flex flex-column align-content-center w-100 mx-auto">
            <StyledH3
              className={`text-center w-100 ${lessthan767 ? "mt-4" : "mt-1"}`}
              isMobileIOS={isMobileIOS}
            >
              {lessthan767 ? (
                <>
                  Issue policy in
                  <span
                    style={{
                      color:
                        Theme?.Registration?.proceedBtn?.background ||
                        "#bdd400",
                      fontSize: isMobileIOS ? "18px" : "20px",
                    }}
                  >
                    &nbsp;2&nbsp;
                  </span>
                  {`minutes`}
                </>
              ) : (
                "Let's begin with your vehicle registration number"
              )}
            </StyledH3>
          </div>
        </Row>
        <StyledRow
          className={`w-100 d-flex no-wrap ${
            lessthan767 ? (!lessthan330 ? "mt-3" : "mt-1") : "mt-5"
          } mx-auto justify-content-center`}
        >
          <StyledCol
            sm="12"
            md="12"
            lg="9"
            xl="9"
            className="p-0 my-2 mx-auto d-flex flex-column no-wrap RegNoPage"
          >
            <RowTag className="d-flex w-100 mx-auto justify-content-center flex-nowrap">
              <Col sm="12" md="12" lg="12" xl="12" className=" p-0 m-0">
                <TextInput
                  lg
                  noPadding
                  type="text"
                  // id="regNo1"
                  name="regNo"
                  placeholder="Enter Registration No. (MH-04-AR-7070)"
                  placeholderColor={"#FFFFF"}
                  ref={register}
                  maxLength={maxlen}
                  disabled={buffer}
                  onPaste={(e) => [
                    onPaste(e),
                    //returning formatted string
                    e,
                    //RC validation
                    e?.target?.value &&
                      (e?.target?.value?.match(
                        /^[A-Z]{2}[-][0-9]{1,2}[-\s][A-Z0-9]{1,3}[-\s][0-9]{4}$/
                      ) ||
                        e?.target?.value?.match(
                          /^[A-Z]{2}[-][0-9]{1,2}[-\s][0-9]{4}$/
                        )) && [
                        //setting value in input
                        setValue("regNo", e?.target?.value),
                        watch("regNo") &&
                          //Triggering proceed with a delay to allow input population before execution
                          setTimeout(
                            () =>
                              document.getElementById("proceedBtn") &&
                              document.getElementById("proceedBtn")?.click(),
                            20
                          ),
                      ],
                  ]}
                  onKeyUp={(e) => SingleKey(e)}
                  onKeyDown={(e) => SingleKey(e)}
                  onChange={onChangeSingle}
                  onInput={(e) => {
                    //keeping i/p blur when -- is replaced & validations are met then refocusing.
                    // eslint-disable-next-line no-sparse-arrays
                    return [
                      e.target.value?.match(/--/g) &&
                        (e.target.value
                          .replace(/--/g, "-")
                          .match(
                            /^[A-Z]{2}[-][0-9]{1,2}[-\s][A-Z0-9]{1,3}[-\s][0-9]{4}$/
                          ) ||
                          e.target.value
                            .replace(/--/g, "-")
                            .match(/^[A-Z]{2}[-][0-9]{1,2}[-\s][0-9]{4}$/)) &&
                        document.querySelector(`input[name=regNo]`).blur(),
                      ,
                      (e.target.value = e.target.value
                        .replace(/\s/gi, "")
                        .replace(/--/g, "-")
                        .toUpperCase()),
                    ];
                  }}
                />
              </Col>
              <Col sm="4" md="4" lg="4" xl="4" className=" p-0 m-0 d-none">
                <TextInput
                  lg
                  noPadding
                  type="text"
                  id="regNo1"
                  name="regNo1"
                  placeholder="MH-04"
                  placeholderColor={"#FFFFF"}
                  ref={register}
                  maxLength={"5"}
                  onKeyUp={handleChange}
                  error={
                    (!regNo1 ||
                      regNo1?.length >= 4 ||
                      (regNo1?.length >= 1 &&
                        regNo1?.length <= 2 &&
                        regNo1?.match(/[^A-Za-z\s]/gi))) &&
                    errors?.regNo1
                  }
                  onInput={(e) =>
                    (e.target.value = e.target.value
                      .replace(/\s/gi, "")
                      .toUpperCase())
                  }
                />
              </Col>
              <Col sm="4" md="4" lg="4" xl="4" className=" p-0 m-0 d-none">
                <TextInput
                  lg
                  noPadding
                  type="text"
                  id="regNo2"
                  name="regNo2"
                  placeholder="AR"
                  placeholderColor={"#FFFFF"}
                  onKeyUp={handleChange2}
                  ref={register}
                  error={(!regNo2 || regNo2?.length >= 2) && errors?.regNo2}
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length < 3
                        ? e.target.value
                            .replace(/[^A-Za-z\s]/gi, "")
                            .toUpperCase()
                        : e.target.value.toUpperCase())
                  }
                  onChange={(e) =>
                    e.target.value.length === 3
                      ? document.querySelector(`input[name=regNo3]`).focus()
                      : ""
                  }
                />
              </Col>
              <Col sm="4" md="4" lg="4" xl="4" className=" p-0 m-0 d-none">
                <TextInput
                  lg
                  noPadding
                  type="text"
                  id="regNo3"
                  name="regNo3"
                  placeholder="7070"
                  placeholderColor={"red"}
                  ref={register}
                  onKeyUp={handleChange3}
                  onChange={(e) =>
                    (e.target.value = e.target.value.toUpperCase().trim())
                  }
                  onKeyDown={numOnly}
                  maxLength={"4"}
                  error={(!regNo3 || regNo3?.length >= 3) && errors?.regNo3}
                  onInput={(e) =>
                    e.target.value.length === 4 ? onSubmitFastLane() : ""
                  }
                />
              </Col>
            </RowTag>
            {(errors?.regNo1?.message &&
            false &&
            (!regNo1 ||
              regNo1?.length >= 4 ||
              (regNo1?.length >= 1 &&
                regNo1?.length <= 2 &&
                !!regNo1?.match(/[^A-Za-z\s]/gi)))
              ? errors?.regNo1?.message
              : "") ||
            (errors.regNo2?.message && (!regNo2 || regNo2?.length >= 2)) ||
            (errors.regNo3?.message && (!regNo3 || regNo3?.length >= 3)) ? (
              <ErrorMsg>
                {(errors?.regNo1?.message &&
                (!regNo1 ||
                  regNo1?.length >= 4 ||
                  (regNo1?.length >= 1 &&
                    regNo1?.length <= 2 &&
                    !!regNo1?.match(/[^A-Za-z\s]/gi)))
                  ? errors?.regNo1?.message
                  : "") ||
                  (errors.regNo2?.message && (!regNo2 || regNo2?.length >= 2)
                    ? errors.regNo2?.message
                    : "") ||
                  (errors.regNo3?.message && (!regNo3 || regNo3?.length >= 3)
                    ? errors.regNo3?.message
                    : "")}
              </ErrorMsg>
            ) : (
              <Form.Text className="text-muted"></Form.Text>
            )}
          </StyledCol>
          <StyledCol
            sm="12"
            md="12"
            lg="2"
            xl="2"
            className="p-0 my-2 mx-auto d-flex justify-content-center w-100"
          >
            <Button
              id={"proceedBtn"}
              buttonStyle="outline-solid"
              style={
                (regNo1 && regNo3) || !btnDisable
                  ? { ...(lessthan767 && { width: "100%" }) }
                  : {
                      cursor: "not-allowed",
                      ...(lessthan767 && { width: "100%" }),
                    }
              }
              hex1={
                regNo1 && regNo3
                  ? Theme?.Registration?.proceedBtn?.background || "#bdd400"
                  : "#e7e7e7"
              }
              hex2={
                regNo1 && regNo3
                  ? Theme?.Registration?.proceedBtn?.background || "#bdd400"
                  : "#e7e7e7"
              }
              borderRadius={lessthan767 ? "30px" : "5px"}
              disabled={
                (regNo1 && regNo3 ? false : true) || btnDisable || buffer
              }
              onClick={() => {
                onSubmit(1);
                setbtnDisable(true);
              }}
              height="60px"
              type="submit"
            >
              {!buffer && (
                <text
                  style={{
                    color:
                      regNo1 && regNo3
                        ? Theme?.Registration?.proceedBtn?.color
                          ? Theme?.Registration?.proceedBtn?.color
                          : "black"
                        : "black",
                  }}
                >
                  {"Proceed"}
                </text>
              )}
              {buffer && (
                <>
                  <Spinner
                    variant="light"
                    as="span"
                    animation="grow"
                    size="sm"
                  />
                  <Spinner
                    variant="light"
                    as="span"
                    animation="grow"
                    size="sm"
                    className={"mx-1"}
                  />
                  <Spinner
                    variant="light"
                    as="span"
                    animation="grow"
                    size="sm"
                  />
                </>
              )}
            </Button>
          </StyledCol>
        </StyledRow>
        <Row className="w-100 d-flex no-wrap mt-2 justify-content-center mx-auto">
          <Col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            className="text-center mx-auto d-flex justify-content-center mt-4 w-100"
          >
            <StyledH4 className="text-center w-100 mx-auto">
              {lessthan767 ? "-------- OR --------" : "OR"}
            </StyledH4>
          </Col>
          <Col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            className="text-center mt-4 d-flex flex-wrap justify-content-center p-0 my-2 mx-auto"
          >
            <Button
              className="mx-2 my-2"
              disabled={btnDisable}
              buttonStyle="outline-solid"
              hex1={Theme?.Registration?.otherBtn?.hex1 || "#006400"}
              hex2={Theme?.Registration?.otherBtn?.hex2 || "#228B22"}
              shadow={"none"}
              style={{
                ...(lessthan767 && { width: lessthan400 ? "92%" : "80%" }),
              }}
              onClick={() => {
                navigator &&
                  navigator?.vibrate &&
                  navigator.vibrate([100, 0, 50]);
                onSubmit(2);
                setbtnDisable(true);
              }}
              borderRadius={lessthan767 ? "30px" : "5px"}
              type="submit"
            >
              <label
                style={{
                  cursor: "pointer",
                  fontSize: lessthan767 ? "11px" : "12px",
                }}
                className={lessthan767 ? "px-0 py-1 m-0" : "p-0 m-0"}
              >
                Proceed without Vehicle Number
              </label>
            </Button>
            <Button
              className="mx-2 my-2"
              disabled={btnDisable}
              buttonStyle="outline-solid"
              hex1={
                lessthan767 && process.env.REACT_APP_BROKER !== "RB"
                  ? "#fff"
                  : Theme?.Registration?.otherBtn?.hex1 || "#006400"
              }
              hex2={
                lessthan767 && process.env.REACT_APP_BROKER !== "RB"
                  ? "#fff"
                  : Theme?.Registration?.otherBtn?.hex2 || "#228B22"
              }
              borderRadius={lessthan767 ? "30px" : "5px"}
              color={
                lessthan767 && process.env.REACT_APP_BROKER !== "RB"
                  ? Theme?.Registration?.otherBtn?.hex1 || "#006400"
                  : ""
              }
              shadow={"none"}
              style={{
                ...(lessthan767 && { width: lessthan400 ? "92%" : "80%" }),
              }}
              onClick={() => {
                navigator &&
                  navigator?.vibrate &&
                  navigator.vibrate([100, 0, 50]);
                onSubmit(3);
                setbtnDisable(true);
              }}
              type="submit"
            >
              <label
                style={{ cursor: "pointer", fontSize: "12px" }}
                className="p-0 m-0"
              >
                Got a New Vehicle? Click Here!
              </label>
            </Button>
          </Col>

          <Col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            className="d-flex justify-content-center mt-3 noOutLine"
          >
            <Btn
              style={{
                display: theme_conf?.broker_config?.renewal
                  ? theme_conf?.broker_config?.renewal === "No"
                    ? "none"
                    : "block"
                  : (process.env.REACT_APP_BROKER === "FYNTUNE" ||
                      process.env.REACT_APP_BROKER === "BAJAJ" ||
                      process.env.REACT_APP_BROKER === "ACE" ||
                      process.env.REACT_APP_BROKER === "ABIBL") &&
                    "block",
              }}
              className={`renewBtn ${
                Theme?.leadPageBtn?.link ? Theme?.leadPageBtn?.link : ""
              }`}
              variant={"link"}
              type="button"
              disabled={btnDisable}
              onClick={() => {
                navigator &&
                  navigator?.vibrate &&
                  navigator.vibrate([100, 0, 50]);
                history.push(`/${type}/renewal${window.location.search}`);
              }}
            >
              Already brought from us? <u>Renew here</u> in 2 step
            </Btn>
          </Col>
        </Row>
      </Stylediv>
      {/*--------------------Journey Mismatch Modal-------------------*/}
      <JourneyMismatch
        enquiry_id={enquiry_id}
        show={TypeReturn(show)}
        onHide={() => setShow(false)}
        setValue={setValue}
        journeyChange={journeyChange}
        clearFastLane={() => dispatch(setFastLane(false))}
        frontendurl={frontendurl}
      />
      {/*---------------x----Journey Mismatch Modal--------x-----------*/}
      <GlobalStyle />
    </>
  ) : (
    <Loader />
  );
};

export const GlobalStyle = createGlobalStyle`
body {
 
  .renewBtn{
    background-color: #f3f7f5;
    color: ${({ theme }) => theme?.Registration?.otherBtn?.hex1 || "#006400"};
    font-family: ${({ theme }) => theme?.fontFamily && theme?.fontFamily};
  
  }
  .renewBtn:hover{
    text-decoration: none;
    color: ${({ theme }) => theme?.Registration?.otherBtn?.hex2 || "#228B22"};
  }
}
`;

const StyledCol = styled(Col)`
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    padding: 0 !important;
  }
`;

const StyledRow = styled(Row)`
  @media (max-width: 992px) {
    display: flex !important;
    flex-direction: column !important;
    align-content: center !important;
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    flex-wrap: no-wrap !important;
  }
`;

const RowTag = styled(Row)`
  // @media (max-width: 400px) {
  // 	display: flex !important;
  // 	margin-left: 0 !important;
  // 	margin-right: 0 !important;
  // 	flex-wrap: no-wrap !important;
  // 	width: auto !important;
  // }
  ${({ theme }) =>
    theme.fontFamily &&
    `.input {
    font-family: ${theme.fontFamily} || "sans-serif"} !important;
  }`}
`;

const StyledH4 = styled.h4`
  color: rgb(74, 74, 74);
  font-size: 27px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.regularFont?.fontColor || "rgb(74, 74, 74)"};
  ${process.env.REACT_APP_BROKER === "TATA" &&
  ` background: linear-gradient(to right, #00bcd4 0%, #ae15d4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;`}
  font-size: 30px;
  font-family: ${({ theme }) =>
    theme.regularFont?.headerFontFamily || "sans-serif"};
  @media (max-width: 767px) {
    font-size: ${(isMobileIOS) => (isMobileIOS ? "18px" : "20px")};
    font-weight: 800;
  }
`;

const StyledBack = styled.div`
  padding-bottom: 30px;
  margin-top: -20px;
  z-index: 999;
  ${process.env.REACT_APP_BROKER === "ABIBL"
    ? `@media (max-width: 780px) {
    position: relative;
    top: -120px;
    left: -10%;
  }
  @media (max-width: 769px) {
    position: relative;
    top: -125px;
    left: -11%;
  }
  @media (max-width: 600px) {
    position: relative;
    top: -120px;
    left: -10%;
  }`
    : `@media (max-width: 780px) {
      position: relative;
      top: -73px;
      left: -10%;
    }
    @media (max-width: 769px) {
      position: relative;
      top: -125px;
      left: -11%;
    }
    @media (max-width: 600px) {
      position: relative;
      top: -73px;
      left: -10%;
    }`}
`;

const Stylediv = styled.div`
  /* z-index: ${() =>
    process.env.REACT_APP_BROKER === "RB" && "-9999 !important"};
  margin: auto; */
`;
