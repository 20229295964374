import React from "react";
import { Badge } from "react-bootstrap";

const Badges = ({ title }) => {
  return (
    <Badge
      variant=""
      style={{
        cursor: "pointer",
        position: "relative",
      }}
    >
      {title}
    </Badge>
  );
};

export default Badges;
