import React from "react";
import { Table } from "react-bootstrap";
import _ from "lodash";
import { currencyFormater } from "utils";
import OptionalTooltip from "../OptionalTooltip";
import { TypeReturn } from "modules/type";
import Badges from "../Badges";
import { NoAddonCotainer } from "../ProductStyle";

export const AddonTable = ({
  quote,
  temp_data,
  addOnsAndOthers,
  type,
  GetAddonValue,
}) => {
  return (
    <Table className="addonTable">
      <tr
        style={{
          display:
            (temp_data?.odOnly ||
              temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType ===
                "C") &&
            "none",
        }}
      >
        {addOnsAndOthers?.selectedCpa?.includes(
          "Compulsory Personal Accident"
        ) && _.isEmpty(addOnsAndOthers?.isTenure) ? (
          <td className="addonValues">
            ₹ {currencyFormater(parseInt(quote?.compulsoryPaOwnDriver))}
          </td>
        ) : (
          <td className="addonValues">
            <OptionalTooltip id={"A1"} />
          </td>
        )}
      </tr>
      {TypeReturn(type) !== "cv" && temp_data?.newCar && (
        <tr
          style={{
            display:
              (temp_data?.odOnly ||
                temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType ===
                  "C") &&
              "none",
          }}
        >
          {!_.isEmpty(addOnsAndOthers?.isTenure) ? (
            <td className="addonValues">
              ₹ {currencyFormater(parseInt(quote?.multiYearCpa))}
            </td>
          ) : (
            <td className="addonValues">
              <OptionalTooltip id={"A2"} />
            </td>
          )}
        </tr>
      )}
      <tr>
        {GetAddonValue("zeroDepreciation", quote?.addonDiscountPercentage1) ===
        "N/S" ? (
          <td className="addonValues">
            <OptionalTooltip id={"A3"} />
          </td>
        ) : GetAddonValue(
            "zeroDepreciation",
            quote?.addonDiscountPercentage1
          ) === "N/A" ? (
          <td className="addonValues">
            {quote?.applicableAddons?.includes("zeroDepreciation") ? (
              <OptionalTooltip id={"A4"} />
            ) : (
              <Badges title={"Not Available"} />
            )}
          </td>
        ) : (
          <td className="addonValues">
            {GetAddonValue("zeroDepreciation", quote?.addonDiscountPercentage1)}
          </td>
        )}
      </tr>

      <tr>
        {GetAddonValue(
          "roadSideAssistance",
          quote?.addonDiscountPercentage1
        ) === "N/S" ? (
          <td className="addonValues">
            <OptionalTooltip id={"A5"} />
          </td>
        ) : GetAddonValue(
            "roadSideAssistance",
            quote?.addonDiscountPercentage1
          ) === "N/A" ? (
          <td className="addonValues">
            {quote?.applicableAddons?.includes("roadSideAssistance") ? (
              <OptionalTooltip id={"A6"} />
            ) : (
              <Badges title={"Not Available"} />
            )}
          </td>
        ) : (
          <td className="addonValues">
            {GetAddonValue(
              "roadSideAssistance",
              quote?.addonDiscountPercentage1
            )}
          </td>
        )}
      </tr>

      {TypeReturn(type) === "cv" && (
        <>
          <tr>
            {GetAddonValue("imt23", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A7"} />
              </td>
            ) : GetAddonValue("imt23", quote?.addonDiscountPercentage1) ===
              "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("imt23") ? (
                  <OptionalTooltip id={"A8"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue("imt23", quote?.addonDiscountPercentage1)}
              </td>
            )}
          </tr>
          {temp_data?.journeyCategory !== "GCV" && (
            <tr>
              {GetAddonValue("consumables", quote?.addonDiscountPercentage1) ===
              "N/S" ? (
                <td className="addonValues">
                  <OptionalTooltip id={"A9"} />
                </td>
              ) : GetAddonValue(
                  "consumables",
                  quote?.addonDiscountPercentage1
                ) === "N/A" ? (
                <td className="addonValues">
                  {quote?.applicableAddons?.includes("consumables") ? (
                    <OptionalTooltip id={"A10"} />
                  ) : (
                    <Badges title={"Not Available"} />
                  )}
                </td>
              ) : (
                <td className="addonValues">
                  {GetAddonValue(
                    "consumables",
                    quote?.addonDiscountPercentage1
                  )}
                </td>
              )}
            </tr>
          )}
        </>
      )}

      {(TypeReturn(type) === "car" || TypeReturn(type) === "bike") && (
        <>
          <tr
            style={{
              display: TypeReturn(type) === "bike" && "none",
            }}
          >
            {GetAddonValue("keyReplace", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A11"} />
              </td>
            ) : GetAddonValue("keyReplace", quote?.addonDiscountPercentage1) ===
              "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("keyReplace") ? (
                  <OptionalTooltip id={"A12"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue("keyReplace", quote?.addonDiscountPercentage1)}
              </td>
            )}
          </tr>
          <tr>
            {GetAddonValue(
              "engineProtector",
              quote?.addonDiscountPercentage1
            ) === "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A13"} />
              </td>
            ) : GetAddonValue(
                "engineProtector",
                quote?.addonDiscountPercentage1
              ) === "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("engineProtector") ? (
                  <OptionalTooltip id={"A14"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue(
                  "engineProtector",
                  quote?.addonDiscountPercentage1
                )}
              </td>
            )}
          </tr>
          <tr
            style={{
              display: TypeReturn(type) === "bike" && "none",
            }}
          >
            {GetAddonValue("ncbProtection", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A15"} />
              </td>
            ) : GetAddonValue(
                "ncbProtection",
                quote?.addonDiscountPercentage1
              ) === "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("ncbProtection") ? (
                  <OptionalTooltip id={"A16"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue(
                  "ncbProtection",
                  quote?.addonDiscountPercentage1
                )}
              </td>
            )}
          </tr>
          <tr>
            {GetAddonValue("consumables", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A17"} />
              </td>
            ) : GetAddonValue(
                "consumables",
                quote?.addonDiscountPercentage1
              ) === "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("consumables") ? (
                  <OptionalTooltip id={"A18"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue("consumables", quote?.addonDiscountPercentage1)}
              </td>
            )}
          </tr>
          <tr
            style={{
              display: TypeReturn(type) === "bike" && "none",
            }}
          >
            {GetAddonValue("tyreSecure", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A19"} />
              </td>
            ) : GetAddonValue("tyreSecure", quote?.addonDiscountPercentage1) ===
              "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("tyreSecure") ? (
                  <OptionalTooltip id={"A20"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue("tyreSecure", quote?.addonDiscountPercentage1)}
              </td>
            )}
          </tr>
          <tr>
            {GetAddonValue(
              "returnToInvoice",
              quote?.addonDiscountPercentage1
            ) === "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A21"} />
              </td>
            ) : GetAddonValue(
                "returnToInvoice",
                quote?.addonDiscountPercentage1
              ) === "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("returnToInvoice") ? (
                  <OptionalTooltip id={"A22"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue(
                  "returnToInvoice",
                  quote?.addonDiscountPercentage1
                )}
              </td>
            )}
          </tr>
          <tr
            style={{
              display: TypeReturn(type) === "bike" && "none",
            }}
          >
            {GetAddonValue("lopb", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A23"} />
              </td>
            ) : GetAddonValue("lopb", quote?.addonDiscountPercentage1) ===
              "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("lopb") ? (
                  <OptionalTooltip id={"A22"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue("lopb", quote?.addonDiscountPercentage1)}
              </td>
            )}
          </tr>
        </>
      )}

      <tr
        style={
          TypeReturn(type) === "cv"
            ? {
                display: "none",
              }
            : {}
        }
      >
        {GetAddonValue(
          "emergencyMedicalExpenses",
          quote?.addonDiscountPercentage1
        ) === "N/S" ? (
          <td className="addonValues">
            <OptionalTooltip id={"A27"} />
          </td>
        ) : GetAddonValue(
            "emergencyMedicalExpenses",
            quote?.addonDiscountPercentage1
          ) === "N/A" ? (
          <td className="addonValues">
            {quote?.applicableAddons?.includes("emergencyMedicalExpenses") ? (
              <OptionalTooltip id={"A28"} />
            ) : (
              <Badges title={"Not Available"} />
            )}
          </td>
        ) : (
          <td className="addonValues">
            {GetAddonValue(
              "emergencyMedicalExpenses",
              quote?.addonDiscountPercentage1
            )}
          </td>
        )}
      </tr>
    </Table>
  );
};

export const AddonTable1 = ({
  quote,
  temp_data,
  addOnsAndOthers,
  type,
  GetAddonValue,
}) => {
  return (
    <Table className="addonTable">
      <tr
        style={{
          display:
            (temp_data?.odOnly ||
              temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType ===
                "C") &&
            "none",
        }}
      >
        {addOnsAndOthers?.selectedCpa?.includes(
          "Compulsory Personal Accident"
        ) && _.isEmpty(addOnsAndOthers?.isTenure) ? (
          <td className="addonValues">
            ₹ {currencyFormater(parseInt(quote?.compulsoryPaOwnDriver))}
          </td>
        ) : (
          <td className="addonValues">
            <OptionalTooltip id={"A1"} />
          </td>
        )}
      </tr>
      {TypeReturn(type) !== "cv" && temp_data?.newCar && (
        <tr
          style={{
            display:
              (temp_data?.odOnly ||
                temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType ===
                  "C") &&
              "none",
          }}
        >
          {!_.isEmpty(addOnsAndOthers?.isTenure) ? (
            <td className="addonValues">
              ₹ {currencyFormater(parseInt(quote?.multiYearCpa))}
            </td>
          ) : (
            <td className="addonValues">
              <OptionalTooltip id={"A2"} />
            </td>
          )}
        </tr>
      )}
      <tr>
        {GetAddonValue("zeroDepreciation", quote?.addonDiscountPercentage1) ===
        "N/S" ? (
          <td className="addonValues">
            <OptionalTooltip id={"A3"} />
          </td>
        ) : GetAddonValue(
            "zeroDepreciation",
            quote?.addonDiscountPercentage1
          ) === "N/A" ? (
          <td className="addonValues">
            {quote?.applicableAddons?.includes("zeroDepreciation") ? (
              <OptionalTooltip id={"A4"} />
            ) : (
              <Badges title={"Not Available"} />
            )}
          </td>
        ) : (
          <td className="addonValues">
            {GetAddonValue("zeroDepreciation", quote?.addonDiscountPercentage1)}
          </td>
        )}
      </tr>

      <tr>
        {GetAddonValue(
          "roadSideAssistance",
          quote?.addonDiscountPercentage1
        ) === "N/S" ? (
          <td className="addonValues">
            <OptionalTooltip id={"A5"} />
          </td>
        ) : GetAddonValue(
            "roadSideAssistance",
            quote?.addonDiscountPercentage1
          ) === "N/A" ? (
          <td className="addonValues">
            {quote?.applicableAddons?.includes("roadSideAssistance") ? (
              <OptionalTooltip id={"A6"} />
            ) : (
              <Badges title={"Not Available"} />
            )}
          </td>
        ) : (
          <td className="addonValues">
            {GetAddonValue(
              "roadSideAssistance",
              quote?.addonDiscountPercentage1
            )}
          </td>
        )}
      </tr>

      {TypeReturn(type) === "cv" && (
        <>
          <tr>
            {GetAddonValue("imt23", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A7"} />
              </td>
            ) : GetAddonValue("imt23", quote?.addonDiscountPercentage1) ===
              "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("imt23") ? (
                  <OptionalTooltip id={"A8"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue("imt23", quote?.addonDiscountPercentage1)}
              </td>
            )}
          </tr>
          {temp_data?.journeyCategory !== "GCV" && (
            <tr>
              {GetAddonValue("consumables", quote?.addonDiscountPercentage1) ===
              "N/S" ? (
                <td className="addonValues">
                  <OptionalTooltip id={"A9"} />
                </td>
              ) : GetAddonValue(
                  "consumables",
                  quote?.addonDiscountPercentage1
                ) === "N/A" ? (
                <td className="addonValues">
                  {quote?.applicableAddons?.includes("consumables") ? (
                    <OptionalTooltip id={"A10"} />
                  ) : (
                    <Badges title={"Not Available"} />
                  )}
                </td>
              ) : (
                <td className="addonValues">
                  {GetAddonValue(
                    "consumables",
                    quote?.addonDiscountPercentage1
                  )}
                </td>
              )}
            </tr>
          )}
        </>
      )}

      {(TypeReturn(type) === "car" || TypeReturn(type) === "bike") && (
        <>
          <tr
            style={{
              display: TypeReturn(type) === "bike" && "none",
            }}
          >
            {GetAddonValue("keyReplace", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A11"} />
              </td>
            ) : GetAddonValue("keyReplace", quote?.addonDiscountPercentage1) ===
              "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("keyReplace") ? (
                  <OptionalTooltip id={"A12"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <NoAddonCotainer amount className="keyAddon">
                {GetAddonValue("keyReplace", quote?.addonDiscountPercentage1)}
              </NoAddonCotainer>
            )}
          </tr>
          <tr>
            {GetAddonValue(
              "engineProtector",
              quote?.addonDiscountPercentage1
            ) === "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A13"} />
              </td>
            ) : GetAddonValue(
                "engineProtector",
                quote?.addonDiscountPercentage1
              ) === "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("engineProtector") ? (
                  <OptionalTooltip id={"A14"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue(
                  "engineProtector",
                  quote?.addonDiscountPercentage1
                )}
              </td>
            )}
          </tr>
          <tr
            style={{
              display: TypeReturn(type) === "bike" && "none",
            }}
          >
            {GetAddonValue("ncbProtection", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A15"} />
              </td>
            ) : GetAddonValue(
                "ncbProtection",
                quote?.addonDiscountPercentage1
              ) === "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("ncbProtection") ? (
                  <OptionalTooltip id={"A16"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue(
                  "ncbProtection",
                  quote?.addonDiscountPercentage1
                )}
              </td>
            )}
          </tr>
          <tr>
            {GetAddonValue("consumables", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A17"} />
              </td>
            ) : GetAddonValue(
                "consumables",
                quote?.addonDiscountPercentage1
              ) === "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("consumables") ? (
                  <OptionalTooltip id={"A18"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue("consumables", quote?.addonDiscountPercentage1)}
              </td>
            )}
          </tr>
          <tr
            style={{
              display: TypeReturn(type) === "bike" && "none",
            }}
          >
            {GetAddonValue("tyreSecure", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A19"} />
              </td>
            ) : GetAddonValue("tyreSecure", quote?.addonDiscountPercentage1) ===
              "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("tyreSecure") ? (
                  <OptionalTooltip id={"A20"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue("tyreSecure", quote?.addonDiscountPercentage1)}
              </td>
            )}
          </tr>
          <tr>
            {GetAddonValue(
              "returnToInvoice",
              quote?.addonDiscountPercentage1
            ) === "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A21"} />
              </td>
            ) : GetAddonValue(
                "returnToInvoice",
                quote?.addonDiscountPercentage1
              ) === "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("returnToInvoice") ? (
                  <OptionalTooltip id={"A22"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue(
                  "returnToInvoice",
                  quote?.addonDiscountPercentage1
                )}
              </td>
            )}
          </tr>
          <tr
            style={{
              display: TypeReturn(type) === "bike" && "none",
            }}
          >
            {GetAddonValue("lopb", quote?.addonDiscountPercentage1) ===
            "N/S" ? (
              <td className="addonValues">
                <OptionalTooltip id={"A23"} />
              </td>
            ) : GetAddonValue("lopb", quote?.addonDiscountPercentage1) ===
              "N/A" ? (
              <td className="addonValues">
                {quote?.applicableAddons?.includes("lopb") ? (
                  <OptionalTooltip id={"A24"} />
                ) : (
                  <Badges title={"Not Available"} />
                )}
              </td>
            ) : (
              <td className="addonValues">
                {GetAddonValue("lopb", quote?.addonDiscountPercentage1)}
              </td>
            )}
          </tr>
        </>
      )}

      <tr
        style={
          TypeReturn(type) === "cv"
            ? {
                display: "none",
              }
            : {}
        }
      >
        {GetAddonValue(
          "emergencyMedicalExpenses",
          quote?.addonDiscountPercentage1
        ) === "N/S" ? (
          <td className="addonValues">
            <OptionalTooltip id={"A27"} />
          </td>
        ) : GetAddonValue(
            "emergencyMedicalExpenses",
            quote?.addonDiscountPercentage1
          ) === "N/A" ? (
          <td className="addonValues">
            {quote?.applicableAddons?.includes("emergencyMedicalExpenses") ? (
              <OptionalTooltip id={"A28"} />
            ) : (
              <Badges title={"Not Available"} />
            )}
          </td>
        ) : (
          <td className="addonValues">
            {GetAddonValue(
              "emergencyMedicalExpenses",
              quote?.addonDiscountPercentage1
            )}
          </td>
        )}
      </tr>
    </Table>
  );
};
