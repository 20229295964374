import React from "react";
import { Table } from "react-bootstrap";
import _ from "lodash";
import Badges from "../Badges";
import { currencyFormater } from "utils";
import { BlockedSections } from "modules/quotesPage/addOnCard/cardConfig";
import { TypeReturn } from "modules/type";

export const AdditionalCoverTable = ({
  addOnsAndOthers,
  quote,
  type,
  temp_data,
  shortTerm,
}) => {
  return (
    <Table className="additionalCoverTable">
      <>
        {!temp_data?.odOnly &&
          temp_data.journeyCategory === "GCV" &&
          !shortTerm && (
            <>
              <tr>
                <td className="additionalCoverValues">
                  {addOnsAndOthers?.selectedAdditions?.includes(
                    "LL paid driver/conductor/cleaner"
                  ) ? (
                    Number(quote?.defaultPaidDriver) == 0 ||
                    quote?.defaultPaidDriver === "N/A" ? (
                      <Badges title={"Not Available"} />
                    ) : (
                      `₹ ${currencyFormater(quote?.defaultPaidDriver)}`
                    )
                  ) : (
                    <Badges title={"Not Selected"} />
                  )}
                </td>
              </tr>
              <tr>
                <td className="additionalCoverValues">
                  {addOnsAndOthers?.selectedAdditions?.includes(
                    "PA paid driver/conductor/cleaner"
                  ) ? (
                    Number(quote?.motorAdditionalPaidDriver) === 0 ||
                    quote?.motorAdditionalPaidDriver === "N/A" ? (
                      <Badges title={"Not Available"} />
                    ) : (
                      `₹ ${currencyFormater(quote?.motorAdditionalPaidDriver)}`
                    )
                  ) : (
                    <Badges title={"Not Selected"} />
                  )}
                </td>
              </tr>
            </>
          )}

        {temp_data.journeyCategory !== "GCV" && !temp_data?.odOnly && (
          <>
            {BlockedSections(
              process.env.REACT_APP_BROKER,
              temp_data?.journeyCategory
            )?.includes("unnamed pa cover") ? (
              <noscript />
            ) : (
              <tr>
                <td className="additionalCoverValues">
                  {addOnsAndOthers?.selectedAdditions?.includes(
                    "Unnamed Passenger PA Cover"
                  ) ? (
                    Number(quote?.coverUnnamedPassengerValue) === 0 ||
                    quote?.coverUnnamedPassengerValue === "N/A" ||
                    quote?.coverUnnamedPassengerValue === "NA" ? (
                      <Badges title={"Not Available"} />
                    ) : (
                      `₹ ${currencyFormater(
                        quote?.companyAlias === "sbi" &&
                          addOnsAndOthers?.selectedCpa?.includes(
                            "Compulsory Personal Accident"
                          ) &&
                          !_.isEmpty(addOnsAndOthers?.isTenure)
                          ? quote?.coverUnnamedPassengerValue *
                              (type === "bike" ? 5 : 3)
                          : quote?.coverUnnamedPassengerValue
                      )}`
                    )
                  ) : (
                    <Badges title={"Not Selected"} />
                  )}
                </td>
              </tr>
            )}
            <tr
              style={{
                display:
                  (shortTerm ||
                    TypeReturn(type) === "bike" ||
                    temp_data?.journeyCategory === "GCV") &&
                  "none",
              }}
            >
              <td className="additionalCoverValues">
                {addOnsAndOthers?.selectedAdditions?.includes(
                  "PA cover for additional paid driver"
                ) ? (
                  !quote?.motorAdditionalPaidDriver ||
                  Number(quote?.motorAdditionalPaidDriver) === 0 ||
                  quote?.motorAdditionalPaidDriver === "N/A" ? (
                    <Badges title={"Not Available"} />
                  ) : (
                    `₹ ${currencyFormater(
                      quote?.companyAlias === "sbi" &&
                        addOnsAndOthers?.selectedCpa?.includes(
                          "Compulsory Personal Accident"
                        ) &&
                        !_.isEmpty(addOnsAndOthers?.isTenure)
                        ? quote?.motorAdditionalPaidDriver *
                            (type === "bike" ? 5 : 3)
                        : quote?.motorAdditionalPaidDriver
                    )}`
                  )
                ) : (
                  <Badges title={"Not Selected"} />
                )}
              </td>
            </tr>
            <tr>
              <td className="additionalCoverValues">
                {addOnsAndOthers?.selectedAdditions?.includes(
                  "LL paid driver"
                ) ? (
                  Number(quote?.defaultPaidDriver) === 0 ||
                  quote?.defaultPaidDriver === "N/A" ? (
                    <Badges title={"Not Available"} />
                  ) : (
                    `₹ ${currencyFormater(quote?.defaultPaidDriver)}`
                  )
                ) : (
                  <Badges title={"Not Selected"} />
                )}
              </td>
            </tr>
          </>
        )}
        {process.env.REACT_APP_BROKER !== "OLA" && (
          <tr>
            <td className="additionalCoverValues">
              {addOnsAndOthers?.selectedAdditions?.includes(
                "Geographical Extension"
              ) ? (
                Number(quote?.geogExtensionODPremium) === 0 ||
                quote?.geogExtensionODPremium === "N/A" ? (
                  <Badges title={"Not Available"} />
                ) : (
                  `₹ ${currencyFormater(quote?.geogExtensionODPremium)}`
                )
              ) : (
                <Badges title={"Not Selected"} />
              )}
            </td>
          </tr>
        )}
      </>
    </Table>
  );
};

export const AdditionalCoverTable1 = ({
  addOnsAndOthers,
  quote,
  type,
  temp_data,
  shortTerm,
}) => {
  return (
    <Table className="additionalCoverTable">
      {!temp_data?.odOnly &&
        temp_data.journeyCategory === "GCV" &&
        !shortTerm && (
          <>
            <tr>
              <td className="additionalCoverValues">
                {addOnsAndOthers?.selectedAdditions?.includes(
                  "LL paid driver/conductor/cleaner"
                ) ? (
                  Number(quote?.defaultPaidDriver) == 0 ||
                  quote?.defaultPaidDriver === "N/A" ? (
                    <Badges title={"Not Available"} />
                  ) : (
                    `₹ ${currencyFormater(quote?.defaultPaidDriver)}`
                  )
                ) : (
                  <Badges title={"Not Selected"} />
                )}
              </td>
            </tr>
            <tr>
              <td className="additionalCoverValues">
                {addOnsAndOthers?.selectedAdditions?.includes(
                  "PA paid driver/conductor/cleaner"
                ) ? (
                  Number(quote?.motorAdditionalPaidDriver) === 0 ||
                  quote?.motorAdditionalPaidDriver === "N/A" ? (
                    <Badges title={"Not Available"} />
                  ) : (
                    `₹ ${currencyFormater(quote?.motorAdditionalPaidDriver)}`
                  )
                ) : (
                  <Badges title={"Not Selected"} />
                )}
              </td>
            </tr>
          </>
        )}

      {temp_data.journeyCategory !== "GCV" && !temp_data?.odOnly && (
        <>
          {BlockedSections(
            process.env.REACT_APP_BROKER,
            temp_data?.journeyCategory
          ).includes("unnamed pa cover") ? (
            <noscript />
          ) : (
            <tr>
              <td className="additionalCoverValues">
                {addOnsAndOthers?.selectedAdditions?.includes(
                  "Unnamed Passenger PA Cover"
                ) ? (
                  Number(quote?.coverUnnamedPassengerValue) === 0 ||
                  quote?.coverUnnamedPassengerValue === "N/A" ||
                  quote?.coverUnnamedPassengerValue === "NA" ? (
                    <Badges title={"Not Available"} />
                  ) : (
                    `₹ ${currencyFormater(
                      quote?.companyAlias === "sbi" &&
                        addOnsAndOthers?.selectedCpa?.includes(
                          "Compulsory Personal Accident"
                        ) &&
                        !_.isEmpty(addOnsAndOthers?.isTenure)
                        ? quote?.coverUnnamedPassengerValue *
                            (type === "bike" ? 5 : 3)
                        : quote?.coverUnnamedPassengerValue
                    )}`
                  )
                ) : (
                  <Badges title={"Not Selected"} />
                )}
              </td>
            </tr>
          )}
          <tr
            style={{
              display:
                (shortTerm ||
                  TypeReturn(type) === "bike" ||
                  temp_data?.journeyCategory === "GCV") &&
                "none",
            }}
          >
            <td className="additionalCoverValues">
              {addOnsAndOthers?.selectedAdditions?.includes(
                "PA cover for additional paid driver"
              ) ? (
                !quote?.motorAdditionalPaidDriver ||
                Number(quote?.motorAdditionalPaidDriver) === 0 ||
                quote?.motorAdditionalPaidDriver === "N/A" ? (
                  <Badges title={"Not Available"} />
                ) : (
                  `₹ ${currencyFormater(
                    quote?.companyAlias === "sbi" &&
                      addOnsAndOthers?.selectedCpa?.includes(
                        "Compulsory Personal Accident"
                      ) &&
                      !_.isEmpty(addOnsAndOthers?.isTenure)
                      ? quote?.motorAdditionalPaidDriver *
                          (type === "bike" ? 5 : 3)
                      : quote?.motorAdditionalPaidDriver
                  )}`
                )
              ) : (
                <Badges title={"Not Selected"} />
              )}
            </td>
          </tr>
          <tr>
            <td className="additionalCoverValues">
              {addOnsAndOthers?.selectedAdditions?.includes(
                "LL paid driver"
              ) ? (
                Number(quote?.defaultPaidDriver) === 0 ||
                quote?.defaultPaidDriver === "N/A" ? (
                  <Badges title={"Not Available"} />
                ) : (
                  `₹ ${currencyFormater(quote?.defaultPaidDriver)}`
                )
              ) : (
                <Badges title={"Not Selected"} />
              )}
            </td>
          </tr>
        </>
      )}
      {process.env.REACT_APP_BROKER !== "OLA" && (
        <tr>
          <td className="additionalCoverValues">
            {addOnsAndOthers?.selectedAdditions?.includes(
              "Geographical Extension"
            ) ? (
              Number(quote?.geogExtensionODPremium) === 0 ||
              quote?.geogExtensionODPremium === "N/A" ? (
                <Badges title={"Not Available"} />
              ) : (
                `₹ ${currencyFormater(quote?.geogExtensionODPremium)}`
              )
            ) : (
              <Badges title={"Not Selected"} />
            )}
          </td>
        </tr>
      )}
    </Table>
  );
};
