import React from "react";
import { Table } from "react-bootstrap";
import Badges from "../Badges";
import { currencyFormater } from "utils";
import { TypeReturn } from "modules/type";

export const AccessoriesTable = ({
  addOnsAndOthers,
  quote,
  ElectricalValue,
  NonElectricalValue,
  type,
  BiFuelValue,
}) => {
  return (
    <Table className="accessoriesTable">
      <tr>
        <td className="accessoriesValues">
          {" "}
          {addOnsAndOthers?.selectedAccesories?.includes(
            "Electrical Accessories"
          ) ? (
            !quote?.motorElectricAccessoriesValue &&
            quote?.companyAlias !== "godigit" ? (
              <Badges title={"Not Available"} />
            ) : Number(quote?.motorElectricAccessoriesValue) === 0 &&
              quote?.companyAlias !== "godigit" ? (
              <Badges title={"Not Available"} />
            ) : quote?.companyAlias !== "godigit" ? (
              `₹ ${currencyFormater(
                Number(quote?.motorElectricAccessoriesValue) +
                  Number(ElectricalValue(quote, addOnsAndOthers))
              )}`
            ) : (
              <Badges title={"Included"} />
            )
          ) : (
            <Badges title={"Not Selected"} />
          )}
        </td>
      </tr>
      <tr>
        <td className="accessoriesValues">
          {addOnsAndOthers?.selectedAccesories?.includes(
            "Non-Electrical Accessories"
          ) ? (
            !quote?.motorNonElectricAccessoriesValue &&
            quote?.companyAlias !== "godigit" ? (
              <Badges title={"Not Available"} />
            ) : Number(quote?.motorNonElectricAccessoriesValue) === 0 &&
              quote?.companyAlias !== "godigit" ? (
              <Badges title={"Not Available"} />
            ) : quote?.companyAlias !== "godigit" ? (
              `₹ ${currencyFormater(
                Number(quote?.motorNonElectricAccessoriesValue) +
                  Number(NonElectricalValue(quote, addOnsAndOthers))
              )}`
            ) : (
              <Badges title={"Included"} />
            )
          ) : (
            <Badges title={"Not Selected"} />
          )}
        </td>
      </tr>
      <tr style={{ display: TypeReturn(type) === "bike" && "none" }}>
        <td className="accessoriesValues">
          {addOnsAndOthers?.selectedAccesories?.includes(
            "External Bi-Fuel Kit CNG/LPG"
          ) ? (
            Number(quote?.motorLpgCngKitValue) === 0 &&
            quote?.companyAlias !== "godigit" ? (
              <Badges title={"Not Available"} />
            ) : quote?.company_alias === "godigit" ? (
              "Included"
            ) : (
              `₹ ${currencyFormater(
                Number(quote?.motorLpgCngKitValue) +
                  Number(BiFuelValue(quote, addOnsAndOthers))
              )}`
            )
          ) : (
            <Badges title={"Not Selected"} />
          )}
        </td>
      </tr>
    </Table>
  );
};

export const AccessoriesTable1 = ({
  addOnsAndOthers,
  quote,
  ElectricalValue,
  NonElectricalValue,
  type,
  BiFuelValue,
}) => {
  return (
    <Table className="accessoriesTable">
      <tr>
        {" "}
        <td className="accessoriesValues">
          {addOnsAndOthers?.selectedAccesories?.includes(
            "Electrical Accessories"
          ) ? (
            !quote?.motorElectricAccessoriesValue &&
            quote?.companyAlias !== "godigit" ? (
              <Badges title={"Not Available"} />
            ) : Number(quote?.motorElectricAccessoriesValue) === 0 &&
              quote?.companyAlias !== "godigit" ? (
              <Badges title={"Not Available"} />
            ) : quote?.companyAlias !== "godigit" ? (
              `₹ ${currencyFormater(
                Number(quote?.motorElectricAccessoriesValue) +
                  Number(ElectricalValue(quote, addOnsAndOthers))
              )}`
            ) : (
              <Badges title={"Included"} />
            )
          ) : (
            <Badges title={"Not Selected"} />
          )}
        </td>
      </tr>
      <tr>
        <td className="accessoriesValues">
          {addOnsAndOthers?.selectedAccesories?.includes(
            "Non-Electrical Accessories"
          ) ? (
            !quote?.motorNonElectricAccessoriesValue &&
            quote?.companyAlias !== "godigit" ? (
              <Badges title={"Not Available"} />
            ) : Number(quote?.motorNonElectricAccessoriesValue) === 0 &&
              quote?.companyAlias !== "godigit" ? (
              <Badges title={"Not Available"} />
            ) : quote?.companyAlias !== "godigit" ? (
              `₹ ${currencyFormater(
                Number(quote?.motorNonElectricAccessoriesValue) +
                  Number(NonElectricalValue(quote, addOnsAndOthers))
              )}`
            ) : (
              <Badges title={"Included"} />
            )
          ) : (
            <Badges title={"Not Selected"} />
          )}
        </td>
      </tr>
      <tr style={{ display: TypeReturn(type) === "bike" && "none" }}>
        <td className="accessoriesValues">
          {addOnsAndOthers?.selectedAccesories?.includes(
            "External Bi-Fuel Kit CNG/LPG"
          ) ? (
            Number(quote?.motorLpgCngKitValue) === 0 &&
            quote?.companyAlias !== "godigit" ? (
              <Badges title={"Not Available"} />
            ) : quote?.company_alias === "godigit" ? (
              "Included"
            ) : (
              `₹ ${currencyFormater(
                Number(quote?.motorLpgCngKitValue) +
                  Number(BiFuelValue(quote, addOnsAndOthers))
              )}`
            )
          ) : (
            <Badges title={"Not Selected"} />
          )}
        </td>
      </tr>
    </Table>
  );
};
