import React from "react";
// import sriyah_brokerImg from "utils/sriyah_broker_logo.png";
import { useHistory, Link } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { useMediaPredicate } from "react-media-hook";
const Footer = () => {
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const todayDate = new Date();
  const history = useHistory();
  return (
    <FooterTag>
      <FooterContainer>
        <TopContainer>
          <div
            className="container"
            // style={{ fontFamily: `'Poppins', sans-serif !important` }}
          >
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="f_widget about-widget pl_40">
                  {" "}
                  <h3 className="f-title f_600 f_size_18 mb_40">
                    Insurances
                  </h3>{" "}
                  <ul className="list-unstyled f_list">
                    {" "}
                    <li>
                      <a href="#">Car Insurance</a>
                    </li>{" "}
                    <li>
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_PROD === "YES"
                            ? "https://health.nammacover.com/"
                            : "https://health-uat.nammacover.com/"
                        }
                      >
                        Health Insurance
                      </a>
                    </li>{" "}
                    <li>
                      <a href="#">Super Top Up Insurance</a>
                    </li>{" "}
                  </ul>{" "}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                {" "}
                <div className="f_widget about-widget">
                  {" "}
                  <h3 className="f-title f_600 f_size_18 mb_40">
                    At a glance
                  </h3>{" "}
                  <ul
                    className="list-unstyled f_list"
                    style={{ fontFamily: `'Poppins', sans-serif !important` }}
                  >
                    {" "}
                    <li>
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_PROD === "YES"
                            ? "https://nammacover.com/about_us"
                            : "https://uat.nammacover.com/about_us"
                        }
                      >
                        About Us
                      </a>
                    </li>{" "}
                    <li>
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_PROD === "YES"
                            ? "https://nammacover.com/terms_of_use"
                            : "https://uat.nammacover.com/terms_of_use"
                        }
                      >
                        Terms Of Use
                      </a>
                    </li>{" "}
                    <li>
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_PROD === "YES"
                            ? "https://nammacover.com/grievance_policy"
                            : "https://uat.nammacover.com/grievance_policy"
                        }
                      >
                        Grievance policy
                      </a>
                    </li>{" "}
                    {/* <li>
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_PROD === "YES"
                            ? "https://nammacover.com/employee"
                            : "https://uat.nammacover.com/employee"
                        }
                      >
                        Employee Login
                      </a>
                    </li>{" "}
                    <li>
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_PROD === "YES"
                            ? "https://nammacover.com/pos"
                            : "https://uat.nammacover.com/pos"
                        }
                      >
                        POS Login
                      </a>
                    </li>{" "} */}
                  </ul>{" "}
                </div>{" "}
              </div>
              <div className="col-lg-6 col-md-6">
                {" "}
                <div className="f_widget company_widget">
                  {" "}
                  <a
                    href={
                      process.env.REACT_APP_PROD === "YES"
                        ? "https://nammacover.com/"
                        : "https://uat.nammacover.com/"
                    }
                    className="f-logo"
                  >
                    <img
                      src={`${
                        process.env.REACT_APP_BASENAME !== "NA"
                          ? `/${process.env.REACT_APP_BASENAME}`
                          : ""
                      }/assets/images/sriyah-footer.png`}
                      className="logo_ic"
                      alt=""
                    />
                  </a>{" "}
                  <div className="widget-wrap">
                    {" "}
                    <p
                      className="color_grey_f"
                      style={{
                        color: "#1f1f1f",
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                      }}
                    >
                      An initiative of Sriyah Insurance Brokers Pvt. Ltd.
                    </p>{" "}
                    <p
                      className="color_grey_f"
                      style={{
                        color: "#1f1f1f",
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Sriyah Insurance Brokers Pvt. Ltd. is an Insurance
                      Regulatory &amp; Development Authority of India (IRDAI)
                      approved direct insurance brokers.
                    </p>{" "}
                    <p className="f_400 f_p f_size_15 mb-0 l_height34 color_grey_f">
                      <span>Email:</span>{" "}
                      <a href="mailto:care@nammacover.com" className="f_400">
                        care@nammacover.com
                      </a>
                    </p>{" "}
                    <p className="f_400 f_p f_size_15 mb-0 l_height34 color_grey_f">
                      <span>Phone:</span>{" "}
                      <a href="tel:18002030504" className="f_400">
                        1800 203 0504
                      </a>
                    </p>{" "}
                  </div>{" "}
                  {/* <form
                    action="#"
                    className="f_subscribe mailchimp no_validate"
                    method="post"
                    novalidate="true"
                    style={{ visibility: "hidden" }}
                  >
                    {" "}
                    <input
                      type="text"
                      name="EMAIL"
                      className="form-control memail"
                      placeholder="Email"
                    />{" "}
                    <button className="btn btn-submit" type="submit">
                      <i class="fas fa-arrow-right"></i>
                    </button>
                  </form>{" "} */}
                </div>{" "}
                <div className="f_social_icon" style={{ display: "none" }}>
                  {" "}
                  <a href="#" className="ti-facebook">
                    <i class="fab fa-facebook-f"></i>
                  </a>{" "}
                  <a href="#" className="ti-twitter-alt">
                    <i class="fab fa-twitter"></i>
                  </a>{" "}
                  <a href="#" className="ti-youtube">
                    <i class="fab fa-youtube"></i>
                  </a>{" "}
                  <a href="#" className="ti-linkedin">
                    <i class="fab fa-linkedin-in"></i>
                  </a>{" "}
                </div>{" "}
              </div>
            </div>
          </div>
        </TopContainer>

        <BottomContainer>
          <div className="container">
            <div classname="row align-items-center">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <p class="mb-0 f_400 color_grey">
                  IRDAI Registration No. 203 | License valid till : 26/08/2024 |
                  Category : Direct Broker | CIN : U66010KA2003PTC031462 <br />
                  Member of Insurance Brokers Association of India (IBAI).
                  Insurance is the subject matter of solicitation. <br />
                  For a seamless experience, use the latest version of Chrome,
                  Firefox or Edge <br />
                  <br /> Copyright {todayDate.getFullYear()} Sriyah Insurance
                  Brokers Pvt. Ltd.
                </p>
              </div>
            </div>
          </div>
        </BottomContainer>
      </FooterContainer>
      <GlobalStyle lessthan767={lessthan767} />
    </FooterTag>
  );
};

export const FooterContainer = styled.div`
  font-size: 16px;
  a {
    font: 300 16px/20px sans-serif;
    color: #1f1f1f;
    position: relative;
    transition: color 0.2s linear;
    display: inline-block;
    :hover {
      color: #f30909;
      text-decoration: none;
      outline: 0 !important;
      ::after {
        transform: scale(1, 1);
      }
    }
    ::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: 0;
      background: red;
      transform: scale(0, 1);
      transform-origin: 0% 100%;
      transition: transform 0.3s ease;
    }
  }
  h3 {
    font-family: ${({ theme }) =>
      theme?.fontFamily
        ? theme?.fontFamily
        : `"basiersquaremed", Arial, sans-serif`};
    margin-top: 5px;
    text-shadow: none;
    color: inherit;
  }
  .color_grey_f {
    color: #1f1f1f;
  }
  .mb_40 {
    margin-bottom: 40px;
  }
  .f_600 {
    font-weight: 600;
  }
  .f_400 {
    font-weight: 400;
  }
  .f_p {
    font-family: ${({ theme }) =>
      theme?.fontFamily ? theme?.fontFamily : `sans-serif`};
  }
  .l_height34 {
    line-height: 34px;
  }
  .f_size_15 {
    font-size: 15px;
  }
  .f_subscribe {
    position: relative;
    margin-top: 13px;
    margin-bottom: 12px;
  }
  .f_subscribe .form-control {
    font: 300 14px sans-serif;
    color: #333;
    border: 1px solid #e2e7f3;
    border-radius: 3px;
    background: #e9ecf3;
    padding-left: 25px;
    height: 54px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .f_size_18 {
    font-size: 18px;
  }
  .f_widget.about-widget .f_list {
    margin-bottom: 0px;
  }
  .f_widget.about-widget .f_list li {
    margin-bottom: 15px;
  }

  .f_subscribe button {
    position: absolute;
    right: 25px;
    background: transparent;
    padding: 0;
    color: #222d39;
    font-size: 16px;
    top: 52%;
    transform: translateY(-50%);
  }
`;

export const TopContainer = styled.div`
  border-bottom: 1px solid #e9ecf3;
  padding: 120px 0px 70px;
  background: #e0e0e0;
  display: flex;
  text-align: left;

  .logo_ic {
    width: 220px;
    height: auto;
  }
  .pl_40 {
    padding-left: 40px;
    @media screen and (max-width: 992px) {
      padding-left: 0px;
    }
  }
  .f_social_icon a {
    width: 46px;
    height: 46px;
    border-radius: 50% !important;
    font-size: 14px;
    line-height: 45px;
    color: #858da8;
    display: inline-block;
    background: #ebeef5;
    text-align: center;
    transition: all 0.2s linear;
    :hover {
      background: #515eb8;
      color: #fff;
    }
  }
  .f_social_icon a + a {
    margin-left: 10px;
  }
  p {
    font-size: 16px;
  }
  .f_widget .widget-wrap {
    margin-top: 35px;
  }
  input[type="text"] {
    -webkit-appearance: none;
    outline: none;
  }
  .f-title {
    color: #051441;
    font-family: ${({ theme }) =>
      theme?.fontFamily ? theme?.fontFamily : `"Poppins", sans-serif`};
  }
`;

const LeftContainer = styled.div``;

const RightContainer = styled.div``;

const BottomContainer = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
  background: #252525;
  p {
    color: #ccc;
    font-weight: 400;
  }
`;

const GlobalStyle = createGlobalStyle`
${({ lessthan767 }) =>
  lessthan767
    ? `
body::after {
    content: '';
    display: block;
    height: 1150px; /* Set same as footer's height */
  }`
    : `
body::after {
    content: '';
    display: block;
    height: 720px; /* Set same as footer's height */
  }
`}`;
const FooterTag = styled.footer`
  padding: unset !important;
`;

export default Footer;
