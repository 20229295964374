import axios from "axios";
import { stringify } from "querystring";
import SecureLS from "secure-ls";
import { DataEncrypt, DataDecrypt } from "utils";

const defaultOptions = {
  headers: {},
  queryParams: null,
};

export const restClient = axios.create();

restClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const err = error.response;
    const ls = new SecureLS();
    if (err.status === 401) {
      ls.remove("token");
      window.history.go("/");
    }
    return Promise.reject(error);
  }
);

const httpClient = async (
  url = "",
  options = defaultOptions,
  noBaseUrl,
  cancelToken,
  allowRaw,
  payloadKey,
  timeout
) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  let fullPath = noBaseUrl ? `${url}` : `${baseUrl}${url}`;
  let encryptionHeader =
    (process.env.REACT_APP_BROKER === "GRAM" ||
      process.env.REACT_APP_BROKER === "UIB") &&
    !(options?.data instanceof FormData);

  if (options.queryParams) {
    const queryString = stringify(options.queryParams);
    fullPath = `${fullPath}?${queryString}`;
  }

  // const token = ls?.get("token");
  restClient.defaults.headers.common[
    "lanninsport"
  ] = `NThmZjc0MGMzZGI1YjY3NDAyZjZlY2Y3OGQ4ODgyZjIjZmM4OTllZjc0NzBlZTY3MDUyZWQ5MmYwZjkwYTI2MTk=`;
  restClient.defaults.headers.common["Accept"] = `application/json`;
  // if (token) {
  //   restClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  // }
  if (encryptionHeader) {
    restClient.defaults.headers.common["x-encryption"] = `keep`;
  } else {
    restClient.defaults.headers.common["x-encryption"] = ``;
  }

  return await restClient({
    url: fullPath,
    method: options.method || "GET",
    data: encryptionHeader
      ? { payload: DataEncrypt(options.data) }
      : options.data,
    cancelToken: options.cancelToken,
    timeout: process.env.REACT_APP_PROD === "YES" ? timeout : false,
  })
    .then((resp) => {
      let responseStringified = encryptionHeader
        ? DataDecrypt(resp?.data?.data)
        : resp;
      let response = encryptionHeader
        ? responseStringified && {
            data: JSON.parse(responseStringified),
          }
        : responseStringified;

      return {
        data: response?.data || {},
        errors: response?.data.errors || response?.data.message,
        error: response?.data.error || response?.data.message,
        message: response?.data.message || response?.data?.msg,
        errorSpecific: response?.data?.errorSpecific,
        success:
          (resp?.status === 200 || resp?.status === 201) &&
          response?.data?.status,
        ...(allowRaw && { raw_response: response }),
      };
    })
    .catch((errResp) => {
      let errRespStringified = encryptionHeader
        ? DataDecrypt(errResp?.data?.data)
        : errResp;
      let err =
        encryptionHeader && errRespStringified
          ? JSON.parse(errRespStringified)
          : errResp;

      return {
        data: err,
        errors: err?.response?.data.errors,
        success: false, // mock status
        errorData: err?.response?.data, // mock status
        message:
          err?.response?.msg ||
          err?.response?.data?.message ||
          err?.response?.data?.m,
        errorSpecific:
          err?.response?.errorSpecific || err?.response?.data?.errorSpecific,
        ...(allowRaw && { raw_error: err }),
      };
    });
};

export default httpClient;
