import React, { useEffect } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import Theme from "modules/theme-config/theme-config";
import "bootstrap/dist/css/bootstrap.min.css";
import Router from "./routes";
import { useSelector, useDispatch } from "react-redux";
import ErrorBoundary from "./utils/ErrorBoundary";
import "./css/globalcss.css";
import { ThemeConf, LinkTrigger } from "modules/Home/home.slice";
import { TypeAccess } from "modules/login/login.slice";
import SecureLS from "secure-ls";
import _ from "lodash";
import {
  useLoginWidget,
  useUnloadBeacon,
  LogoLoader,
  NetworkStatus,
} from "components";
import { hotjar } from "react-hotjar";
import ReactGA from "react-ga4";
//cache busting
import { useClearCache } from "react-clear-cache";

const ls = new SecureLS();

const App = () => {
  /*---Theme---*/
  const dispatch = useDispatch();
  const { theme_conf, theme_conf_success } = useSelector((state) => state.home);
  const { temp_data } = useSelector((state) => state.proposal);

  //set-session
  var hours = 0.5; // to clear the localStorage after 30 mins
  var now = new Date().getTime();
  var setupTime = localStorage.getItem("setupTime");
  if (
    setupTime == null &&
    new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    }) &&
    new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })?.token &&
    !["payment-success", "proposal-page", "quotes"].includes(
      window.location.href
    )
  ) {
    localStorage.setItem("setupTime", now);
  } else {
    if (now - setupTime > hours * 60 * 60 * 1000) {
      localStorage.removeItem("setupTime");
    }
  }

  // will get fired if the page unloads while the component is mounted
  useUnloadBeacon({
    url: `${process.env.REACT_APP_API_BASE_URL}/linkDelivery`,
    payload: () => {
      let formdata = new FormData();
      formdata.append("dropout", "dropout");
      formdata.append(
        "user_product_journey_id",
        new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        }) &&
          new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          })?.enquiry_id
      );
      return formdata;
    },
  });

  // journey continue
  const windowState = window.location.href;
  useEffect(() => {
    new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    }) &&
      new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })?.enquiry_id &&
      dispatch(
        LinkTrigger(
          {
            user_product_journey_id:
              new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
              }) &&
              new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
              })?.enquiry_id,
            dropout: "continue",
          },
          true
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowState]);

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  //cache busting
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLatestVersion]);

  //load theme
  useEffect(() => {
    dispatch(ThemeConf());
  }, []);

  //store theme
  useEffect(() => {
    if (theme_conf?.theme_config && !_.isEmpty(theme_conf?.theme_config)) {
      ls.set("themeData", theme_conf?.theme_config);
      console.log({ theme_conf });
    } else {
      ls.remove("themeData");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme_conf?.theme_config]);
  /*-x-Theme-x-*/

  /*---Type-Access---*/
  useEffect(() => {
    dispatch(TypeAccess());

    //clearing LS if broker is not renewbuy
    if (process.env.REACT_APP_BROKER !== "RB") {
      localStorage.removeItem("SSO_user");
      localStorage.removeItem("SSO_user_motor");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /*-x-Type-Access-x-*/

  //RenewBuy SSO
  const SSO = () => {
    useLoginWidget();
  };
  process.env.REACT_APP_BROKER === "RB" && SSO();

  const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector("meta[name=viewport]");

    if (el !== null) {
      let content = el.getAttribute("content");
      let re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content)) {
        content = content.replace(re, "maximum-scale=1.0");
      } else {
        content = [content, "maximum-scale=1.0"].join(", ");
      }

      el.setAttribute("content", content);
    }
  };

  const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

  const checkIsIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (checkIsIOS()) {
    disableIosTextFieldZoom();
  }

  /*---hotjar---*/
  useEffect(() => {
    if (
      window.location.href.includes("/car/") &&
      !hotjar.initialized() &&
      process.env.REACT_APP_BROKER === "BAJAJ" &&
      process.env.REACT_APP_PROD === "YES"
    ) {
      hotjar.initialize("3110385", 6);
    }
    if (
      window.location.href.includes("/bike/") &&
      !hotjar.initialized() &&
      process.env.REACT_APP_BROKER === "BAJAJ" &&
      process.env.REACT_APP_PROD === "YES"
    ) {
      hotjar.initialize("3110405", 6);
    }
  }, [window.location.href]);

  //payment-success
  useEffect(() => {
    if (
      (window.location.href.includes("/payment-success/") ||
        window.location.href.includes("/payment-failure/")) &&
      !hotjar.initialized() &&
      process.env.REACT_APP_BROKER === "BAJAJ" &&
      process.env.REACT_APP_PROD === "YES"
    ) {
      if (temp_data?.productSubTypeCode === "CAR") {
        hotjar.initialize("3110385", 6);
      }
      if (temp_data?.productSubTypeCode === "BIKE") {
        hotjar.initialize("3110405", 6);
      }
    }
  }, [temp_data]);
  /*---hotjar---*/

 /*---Google analytics---*/
  // process.env.REACT_APP_GTAG !== "NA" && ReactGA.initialize(process.env.REACT_APP_GTAG);
  // process.env.REACT_APP_PROD === "YES" &&
  process.env.REACT_APP_BROKER === "BAJAJ" &&
  ReactGA.initialize("UA-45018221-13");
/*---Google analytics---*/

  return (
    <ErrorBoundary>
      <ThemeProvider
        theme={
          !_.isEmpty(theme_conf?.theme_config) && theme_conf?.theme_config
            ? theme_conf?.theme_config
            : Theme
        }
      >
        <GlobalStyle broker={process.env?.REACT_APP_BROKER === "ABIBL"} />
        {!theme_conf_success ? <LogoLoader /> : <Router />}{" "}
        <NetworkStatus />
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;

export const GlobalStyle = createGlobalStyle`
body {
	font-family: ${({ theme }) =>
    theme?.regularFont?.fontFamily
      ? theme?.regularFont?.fontFamily
      : "basier_squareregular"};
 .swal-button {
       background-color: ${({ broker }) =>
         broker ? "#c7222a !important" : ""};
             }

  .swal-button--cancel {
      color: #555;
      background-color: #efefef !important;
  }
    }
    .swal-text {
      font-family: ${({ theme }) =>
        theme?.regularFont?.fontFamily
          ? theme?.regularFont?.fontFamily
          : "basier_squareregular"};
    }
.backBtn button {
	top: 
	${({ theme }) =>
    theme?.BackButton?.backButtonTop
      ? theme?.BackButton?.backButtonTop
      : "136px"};
	font-family: ${({ theme }) =>
    theme?.regularFont?.fontFamily
      ? theme?.regularFont?.fontFamily
      : "basier_squareregular"};

@media (max-width: 767px) 
			{
				top: ${({ theme }) =>
          theme?.BackButton?.backButtonTopMobile
            ? theme?.BackButton?.backButtonTopMobile
            : "112px"};
			}

}
`;
