import styled from "styled-components";
import React, { useEffect } from "react";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import _ from "lodash";
import { Check } from "modules/Home/home.slice";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { Loader } from "components";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme1 = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

export const ServerError = () => {
  const { Live_Status, check_live_err } = useSelector((state) => state.home);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    !!Live_Status
      ? swal("Application status", "No issues found", "success").then(() =>
          history.push("/")
        )
      : check_live_err &&
        swal("Error", "Seems to be a server error please wait...", "error");
  }, [Live_Status, check_live_err]);

  //auto fire
  useEffect(() => {
    dispatch(Check());
  }, []);

  const checkLive = () => {
    dispatch(Check());
  };
  console.log(check_live_err, Live_Status);
  return check_live_err && !Live_Status ? (
    <div
      style={{
        display: "flex",
        height: "100vh",
        background: "linear-gradient(#111,#333,#111)",
        // `${Theme1 ? `linear-gradient(${Theme1?.proposalProceedBtn?.hex1},#333,${Theme1?.proposalProceedBtn?.hex1})`
        // : "linear-gradient(#111, #333, #111)"}`
      }}
    >
      <Container>
        <h1>500</h1>
        <h3>Server Error</h3>
        <h4>There seems to be a server error please wait...</h4>
        <Button onClick={checkLive}>TRY AGAIN</Button>
      </Container>
    </div>
  ) : !Live_Status ? (
    <Loader />
  ) : (
    <noscript />
  );
};

export const Container = styled.div`
  text-align: center;
  margin: auto;
  h1,
  h4,
  h3 {
    margin: 20px 0;
    line-height: 0.8;
    color: ${Theme1 ? `${Theme1?.proposalProceedBtn?.hex1}` : "#fff"};
  }

  h2,
  h3 {
    font-weight: 300;
    color: "#C8FFF4";
  }

  h1 {
    font-weight: 700;
    color: "#03DAC6";
    font-size: 8em;
  }

  h2 {
    margin: 30px 0;
  }

  h3 {
    font-size: 2.5em;
  }

  h4 {
    margin: 4px 15px;
  }
`;
export const Button = styled.button`
  padding: 6px 26px;
  margin: 30px 0;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  background: ${Theme1 ? `${Theme1?.proposalProceedBtn?.hex1}` : "#fff"};
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  &:hover {
    background: #fff;
    color: ${Theme1 ? `${Theme1?.proposalProceedBtn?.hex1}` : "#fff"};
  }
`;
