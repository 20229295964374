import React, { useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Loader, FloatButton, ContentFn } from "components";
import { Button as Btn } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  PolicyGen,
  clear,
} from "modules/payment-gateway/payment-gateway.slice";
import swal from "sweetalert";
import { downloadFile, reloadPage } from "utils";
import { Prefill } from "modules/proposal/proposal.slice";
import _ from "lodash";
import {
  ShareQuote,
  TriggerWhatsapp,
  getNdslUrl,
} from "modules/Home/home.slice";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import "./btn.css";
import styled, { createGlobalStyle } from "styled-components";
import { useMediaPredicate } from "react-media-hook";
import Carousel from "components/slider/Carousel";
import { getLogoUrl } from "modules/quotesPage/quoteUtil";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const JourneySuccess = () => {
  const dispatch = useDispatch();
  const { policy, error, loading, policyLoading } = useSelector(
    (state) => state.payment
  );
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const icrm = !localStorage.SSO_user;
  const { temp_data } = useSelector((state) => state.proposal);
  const { theme_conf, ndslUrl } = useSelector((state) => state.home);
  const lessthan767 = useMediaPredicate("(max-width: 767px)");

  const CardData = !_.isEmpty(temp_data)
    ? temp_data?.userProposal?.additonalData
      ? temp_data?.userProposal?.additonalData
      : {}
    : {};

  //IOS check.
  let isMobileIOS = false; //initiate as false
  // device detection
  if (
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !window.MSStream &&
    lessthan767
  ) {
    isMobileIOS = true;
  }

  var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  const formRef = useRef(null);
  const proceed = () => {
    formRef.current.submit();
  };

  const Inputs = !_.isEmpty(policy?.redirection_data?.post_data_proceed) ? (
    Object.keys(policy?.redirection_data?.post_data_proceed).map((k, i) => {
      return (
        <input
          type="hidden"
          name={`${k}`}
          value={policy?.redirection_data?.post_data_proceed[`${k}`]}
        />
      );
    })
  ) : (
    <noscript />
  );

  const FORM = (
    <form
      ref={formRef}
      id="proceed-for-check"
      action={policy?.redirection_data?.proceed}
      method="POST"
    >
      {Inputs}
    </form>
  );

  //on success script
  useEffect(() => {
    if (
      !_.isEmpty(temp_data)
      //&&
      // (!_.isEmpty(policy) || !policyLoading)
    ) {
      process.env.REACT_APP_BROKER === "TATA" &&
        dispatch(
          getNdslUrl({
            policy_no: policy?.policyNumber,
            email: temp_data?.emailId,
            contact: temp_data?.userMobile,
            first_name: temp_data?.firstName,
            middle_name: "",
            last_name: temp_data?.lastName,
            section: "motor",
            company: temp_data?.selectedQuote?.companyName,
            companyAlias: temp_data?.selectedQuote?.companyAlias,
          })
        );
      let respData = {
        //user details
        userFname: temp_data?.firstName,
        userLname: temp_data?.lastName,
        userMobile: temp_data?.userMobile,
        emailId: temp_data?.emailId,
        //agent details
        agentDetails:
          !_.isEmpty(temp_data?.agentDetails) &&
          temp_data?.agentDetails &&
          temp_data?.agentDetails?.filter((el) => el?.sellerType !== "U"),
        //policy details
        policyStartDate: temp_data?.userProposal?.policyStartDate,
        policyEndDate: temp_data?.userProposal?.policyEndDate,
        policyNumber: policy?.policyNumber,
        proposalNumber: policy?.proposalNumber,
        //pdf url
        ...(policy?.pdfUrl && { pdfUrl: policy?.pdfUrl }),
        //IC details
        ic: temp_data?.selectedQuote?.companyName,
        icLogo: temp_data?.selectedQuote?.companyLogo,
        //product details
        productName: temp_data?.selectedQuote?.productName,
        transactionId: enquiry_id,
        ...(policy?.final_payable_amount && {
          final_payable_amount: policy?.final_payable_amount,
        }),
      };
      process.env.REACT_APP_BROKER === "GRAM" &&
        window?.Android &&
        window.Android.SendToPaymentSuccessPage(JSON.stringify(respData));
    }
  }, [policy]);

  const UrlFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "https://ola-dashboard.fynity.in/";
      case "UNILIGHT":
        return "https://www.policyongo.com/pos/login";
      case "CP":
        return "https://www.comparepolicy.com/";
      case "FYNTUNE":
        return "";
      case "ABIBL":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-preprod-carbike.fynity.in/api"
        ) {
          return "http://preprod-dasbhoard-abibl.fynity.in/";
        } else if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-abibl.fynity.in/";
        } else {
          return "http://abibl-prod-dashboard.fynity.in/";
        }
      case "GRAM":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apigramcover-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-gramcover.fynity.in/";
        } else {
          return "https://dashboard.gramcover.com/";
        }
      case "ACE":
        return "https://crm.aceinsurance.com:5555/";
      case "SRIYAH":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://nammacover.com/"
          : "https://uat.nammacover.com/";
      case "PINC":
        return localStorage.getItem("setupTime")
          ? process.env.REACT_APP_PROD === "YES"
            ? "https://pinctreepos.app.link/prod_logo"
            : "https://pinctreepos.app.link/dev_logo"
          : "https://pinctree.com/";
      case "EPOCH":
        return `http://online.epochbrokers.com:8092/`;
      case "RB":
        return "https://www.renewbuy.com/";
      case "SPA":
        return `https://uatdashboard.insuringall.com/`;
      case "BIMA":
        return ``;
      case "BAJAJ":
        return `https://partnerstage.bajajcapitalinsurance.com`;
      case "UIB":
        return ``;
      case "SRIDHAR":
        return `https://uatdashboard.sibinsure.com/`;
      case "SHREE":
        return `https://shreeinsure.com/`;
      case "TATA":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://lifekaplan.com/"
          : "https://uat.lifekaplan.com/";
      case "POLICYERA":
        return process.env.REACT_APP_PROD === "YES"
          ? `https://dashboard.policyera.com/`
          : `https://uatdashboard.policyera.com/`;
      case "ANALAH":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://dashboard.analahinsurance.com/"
          : "https://uatdashboard.analahinsurance.com/";
      default:
        return "https://ola-dashboard.fynity.in/";
    }
  };

  //load prefill data
  useEffect(() => {
    if (enquiry_id) {
      dispatch(Prefill({ enquiryId: enquiry_id }, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  //email - trigger
  useEffect(() => {
    if (
      enquiry_id &&
      CardData?.owner?.email &&
      temp_data?.selectedQuote?.productName &&
      policy?.policyNumber
    )
      dispatch(
        ShareQuote({
          enquiryId: enquiry_id,
          notificationType: "email",
          domain: `http://${window.location.hostname}`,
          type: "paymentSuccess",
          emailId: CardData?.owner?.email,
          email: CardData?.owner?.email,
          firstName: CardData?.owner?.firstName,
          lastName: CardData?.owner?.lastName,
          action: window.location.href,
          link: window.location.href,
          productName: temp_data?.selectedQuote?.productName,
          policyNumber: policy?.policyNumber,
          logo: getLogoUrl(),
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, temp_data, policy]);

  //mobile - trigger
  useEffect(() => {
    if (
      enquiry_id &&
      CardData?.owner?.mobileNumber &&
      temp_data?.selectedQuote?.productName &&
      policy?.policyNumber
    )
      dispatch(
        ShareQuote({
          enquiryId: enquiry_id,
          notificationType: "sms",
          domain: `http://${window.location.hostname}`,
          type: "policyGeneratedSms",
          emailId: CardData?.owner?.email,
          mobileNo: CardData?.owner?.mobileNumber,
          firstName: CardData?.owner?.firstName,
          lastName: CardData?.owner?.lastName,
          action: window.location.href,
          link: window.location.href,
          productName: temp_data?.selectedQuote?.productName,
          policyNumber: policy?.policyNumber,
          logo: getLogoUrl(),
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, temp_data, policy]);

  //whatsapp - trigger
  useEffect(() => {
    if (enquiry_id && CardData?.owner?.mobileNumber)
      process.env.REACT_APP_BROKER === "OLA" &&
        dispatch(
          TriggerWhatsapp({
            enquiryId: enquiry_id,
            domain: `http://${window.location.hostname}`,
            type: "paymentSuccess",
            notificationType: "whatsapp",
            firstName: CardData?.owner?.firstName,
            lastName: CardData?.owner?.lastName,
            mobileNo: `91${CardData?.owner?.mobileNumber}`,
            to: `91${CardData?.owner?.mobileNumber}`,
            url: window.location.href,
            action: window.location.href,
            link: window.location.href,
            logo: getLogoUrl(),
          })
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy]);

  //Url
  // useEffect(() => {
  //   if (enquiry_id)
  //     dispatch(
  //       Url({
  //         proposalUrl: window.location.href,
  //         quoteUrl: window.location.href
  //           ? window.location.href?.replace(/payment-success/g, "quotes")
  //           : "",
  //         userProductJourneyId: enquiry_id,
  //       })
  //     );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [enquiry_id]);

  useEffect(() => {
    if (enquiry_id && !_.isEmpty(temp_data)) {
      dispatch(PolicyGen({ userProductJourneyId: enquiry_id }));
    } else {
      !enquiry_id &&
        swal("Error", "No enquiry id found", "error").then(() =>
          reloadPage(UrlFn())
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, temp_data]);

  useEffect(() => {
    if (error) {
      swal(
        "Error",
        `${`Trace ID:- ${enquiry_id}.\n Error Message:- ${error}`}`,
        "error"
      );
    }
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  //onload previous data clear
  useEffect(() => {
    dispatch(clear("pdf"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //policy pdf download
  useEffect(() => {
    if (policy?.pdfUrl) {
      downloadFile(`${policy?.pdfUrl}&download=true`, false, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy?.pdfUrl]);

  const Bajaj_rURL = (b2c) => {
    if (
      process.env.REACT_APP_API_BASE_URL ===
      "https://uatapimotor.bajajcapitalinsurance.com/api"
    ) {
      return b2c
        ? "https://buypolicyuat.bajajcapitalinsurance.com"
        : "https://partneruat.bajajcapitalinsurance.com";
    }
    if (
      process.env.REACT_APP_API_BASE_URL ===
      "https://stageapimotor.bajajcapitalinsurance.com/api"
    ) {
      return b2c
        ? window.location.href
        : "https://partnerstage.bajajcapitalinsurance.com";
    }
    if (
      process.env.REACT_APP_API_BASE_URL ===
      "https://apimotor.bajajcapitalinsurance.com/api"
    ) {
      return b2c
        ? window.location.href
        : "https://partner.bajajcapitalinsurance.com";
    }
  };

  return !_.isEmpty(temp_data) && !loading && !_.isEmpty(policy) ? (
    policy?.status === "FAILURE" && !policy?.pdfUrl ? (
      <>
        <Row className="text-center w-100 mx-auto">
          <div
            className="mt-4 d-flex flex-column justify-content-center w-100"
            style={{ paddingTop: "80px" }}
          >
            <p
              className="text-center w-100 policy_text"
              style={{ fontSize: "1.1rem", color: "#006600" }}
            >
              It seems your CKYC verification is not complete.
              {!policy?.redirection_data?.kyc_url ? (
                "No url provided by insurance company for CKYC verification.Please contact partner."
              ) : (
                <>
                  <a
                    target="_blank"
                    href={policy?.redirection_data?.kyc_url}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Click Here
                  </a>{" "}
                  to complete.
                </>
              )}
            </p>
            {temp_data?.selectedQuote?.companyAlias !== "acko" && (
              <>
                <p
                  className="text-center w-100 policy_text"
                  style={{ fontSize: "1.1rem", color: "#006600" }}
                >
                  OR
                </p>
                {FORM}
                <p
                  className="text-center w-100 policy_text"
                  style={{ fontSize: "1.1rem", color: "#006600" }}
                >
                  <span
                    onClick={proceed}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Proceed
                  </span>{" "}
                  if already verified.
                </p>
              </>
            )}
          </div>
        </Row>
      </>
    ) : (
      <>
        <Row className="text-center w-100 mx-auto">
          <Top className="mx-auto" style={{ width: "50%" }}>
            <div className="mt-4 d-flex justify-content-center w-100">
              <img
                src={`${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ""
                }/assets/images/like.${
                  process.env.REACT_APP_BROKER === "RB" ? "png" : "svg"
                }`}
                alt="errImg"
                height={process.env.REACT_APP_BROKER === "RB" ? "80" : "100"}
                width={process.env.REACT_APP_BROKER === "RB" ? "80" : "100"}
                className="success_image"
              />
            </div>
            <div className="mt-4 d-flex flex-column justify-content-center w-100">
              <h4
                className="text-center w-100 text-success font-weight-bold cong_text"
                style={{ fontSize: "2.3rem" }}
              >
                Congratulations!
              </h4>
            </div>
            <div className="mt-4 d-flex flex-column justify-content-center w-100">
              <p
                className="text-center w-100 policy_text"
                style={{ fontSize: "1.1rem", color: "#006600" }}
              >
                {`Your${
                  temp_data?.selectedQuote?.companyName
                    ? ` ${temp_data?.selectedQuote?.companyName}`
                    : ""
                } policy${
                  policy?.policyNumber || policy?.proposalNumber
                    ? ` with ${
                        policy?.policyNumber ? "policy" : "proposal"
                      } number "${
                        policy?.policyNumber || policy?.proposalNumber
                      }"`
                    : ""
                } has been issued & a soft copy will be shared to your registered email address shortly.`}
              </p>
              {((process.env.REACT_APP_BROKER === "RB" && icrm) ||
                process.env.REACT_APP_BROKER !== "RB") && (
                <>
                  <p
                    className="text-center w-100 mt-2 content_text email_contact_text"
                    style={{ fontSize: "1.1rem" }}
                  >
                    {ContentFn()}
                  </p>
                  {policy?.redirection_data?.kyc_url ? (
                    <>
                      <p
                        className="text-center w-100 mt-2 content_text email_contact_text"
                        style={{ fontSize: "1.1rem" }}
                      >
                        Customer is not CKYC verified, kindly proceed with
                        Manual CKYC otherwise Policy will get cancelled after
                        couple of days of issuance and claim will not be
                        settled.
                      </p>
                      <p
                        className="text-center w-100 mt-2 content_text email_contact_text"
                        style={{ fontSize: "1.1rem" }}
                      >
                        <a
                          target="_blank"
                          href={policy?.redirection_data?.kyc_url || ""}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Click Here to complete CKYC
                        </a>
                      </p>
                    </>
                  ) : (
                    <p
                      className="text-center w-100 mt-2 content_text email_contact_text"
                      style={{ fontSize: "1.1rem" }}
                    >
                      Please note that CKYC is mandatory from 01/01/2023 as per
                      guidelines. Policy purchased without CKYC will be
                      cancelled.
                    </p>
                  )}
                  {ndslUrl?.url && false && (
                    <>
                      <p
                        className="text-center w-100 mt-2 content_text email_contact_text"
                        style={{ fontSize: "1.1rem" }}
                      >
                        You can now manage all your insurance policies under a
                        single log-in account known as Electronic Insurance
                        Account (eIA). Opening an eIA is free.
                      </p>

                      <p
                        className="text-center w-100 mt-2 content_text email_contact_text"
                        style={{ fontSize: "1.1rem" }}
                      >
                        {`To open an eIA please click on the below link: ${ndslUrl?.url}`}
                      </p>
                    </>
                  )}
                  <Thank
                    className="text-center w-100 mt-2 thank_text"
                    style={{ fontSize: "1.1rem" }}
                  >
                    Thank you for contacting us.
                  </Thank>
                </>
              )}
            </div>
            {policy?.pdfUrl &&
            temp_data?.corporateVehiclesQuoteRequest?.journeyType !==
              "driver-app" ? (
              <div
                className="mb-4 d-flex justify-content-center w-100"
                style={{ marginTop: "-10px" }}
              >
                <p>
                  If download doesn't start automatically then{" "}
                  <span className="link">
                    <Btn
                      // style={{ marginTop: "-16px", marginLeft: "-9px" }}
                      style={{
                        all: "unset",
                        cursor: "pointer",
                      }}
                      variant="link"
                      onClick={() => {
                        downloadFile(policy?.pdfUrl, false, true);
                      }}
                    >
                      click here.
                    </Btn>
                  </span>
                </p>
              </div>
            ) : (
              <div
                className="mb-4 d-flex justify-content-center w-100"
                style={{ marginTop: "-10px" }}
              >
                <StyleP
                  lessthan767={lessthan767}
                  // style={{ color: "black", fontSize: lessthan767 && "0.7rem" }}
                  // variant="link"
                  // onClick={() => {
                  //   downloadFile(policy?.pdfUrl, false, true);
                  // }}
                >
                  Thank You for the transaction. Your policy will be generated
                  within 48 - 72 hours.
                </StyleP>
              </div>
            )}
            {process.env.REACT_APP_BROKER === "RB" && !icrm && (
              <>
                {policy?.redirection_data?.kyc_url ? (
                  <div style={{ marginTop: "-20px", marginBottom: "30px" }}>
                    <p
                      className="text-center w-100 content_text email_contact_text"
                      style={{ fontSize: "1.1rem" }}
                    >
                      Customer is not CKYC verified, kindly proceed with Manual
                      CKYC otherwise Policy will get cancelled after couple of
                      days of issuance and claim will not be settled.
                    </p>
                    <p
                      className="text-center w-100 mt-2 content_text email_contact_text"
                      style={{ fontSize: "1.1rem" }}
                    >
                      <a
                        target="_blank"
                        href={policy?.redirection_data?.kyc_url || ""}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Click Here to complete CKYC
                      </a>
                    </p>
                  </div>
                ) : (
                  <p
                    className="text-center w-100 mt-2 content_text email_contact_text"
                    style={{ fontSize: "1.1rem" }}
                  >
                    Please note that CKYC is mandatory from 01/01/2023 as per
                    guidelines. Policy purchased without CKYC will be cancelled
                  </p>
                )}
                <Heading>
                  {lessthan767
                    ? "You can earn more by selling"
                    : "Recommended business to earn more"}
                </Heading>
                {!lessthan767 ? (
                  <CardContainer>
                    <Card
                      card1
                      onClick={() =>
                        reloadPage(
                          "https://health.renewbuy.com/input/basic-details"
                        )
                      }
                    >
                      <CardIcon
                        src={`${
                          process.env.REACT_APP_BASENAME !== "NA"
                            ? `/${process.env.REACT_APP_BASENAME}`
                            : ""
                        }/assets/images/healthIns.png`}
                      />
                      <p>Health Insurance</p>
                      <small>You can earn</small>
                      <br />
                      <h5>₹ 1200*</h5>
                    </Card>
                    <Card card2>
                      <CardIcon
                        src={`${
                          process.env.REACT_APP_BASENAME !== "NA"
                            ? `/${process.env.REACT_APP_BASENAME}`
                            : ""
                        }/assets/images/life.png`}
                      />
                      <p>Life Insurance</p>
                      <small>You can earn</small>
                      <br />
                      <h5>₹ 4000*</h5>
                    </Card>
                    <p
                      style={{
                        background: "white",
                        color: "#9C9C9C",
                        borderRadius: "50%",
                        padding: "3px 5px",
                      }}
                    >
                      Or
                    </p>
                    <Card
                      onClick={() =>
                        reloadPage(
                          `${window.location.origin}${
                            process.env.REACT_APP_BASENAME !== "NA"
                              ? `/${process.env.REACT_APP_BASENAME}`
                              : ``
                          }/${
                            Number(temp_data?.productSubTypeId) === 1
                              ? "car"
                              : Number(temp_data?.productSubTypeId) === 2
                              ? "bike"
                              : "cv"
                          }/lead-page`
                        )
                      }
                    >
                      <CardIcon
                        motor
                        src={`${
                          process.env.REACT_APP_BASENAME !== "NA"
                            ? `/${process.env.REACT_APP_BASENAME}`
                            : ""
                        }/assets/images/motor.png`}
                      />
                      <p>Motor Policy</p>
                      <small>Issue another</small>
                    </Card>
                  </CardContainer>
                ) : (
                  <Carousel />
                )}
              </>
            )}
            {process.env.REACT_APP_BROKER === "RB" && !icrm && (
              <>
                {lessthan767 ? (
                  <p
                    style={{
                      fontSize: "9px",
                      color: "	#A8A8A8",
                      marginTop: "30px",
                      marginBottom: "-2px",
                    }}
                  >
                    T&C*
                  </p>
                ) : (
                  <noscript />
                )}
                <p style={{ fontSize: "9px", color: "	#A8A8A8" }}>
                  {`${
                    lessthan767 ? "" : "T&C* "
                  }This amount is indicative, based on 15% Payout on a net
                premium of Rs. 8000 for health and 40% payout on Life Term plan
                of net premium Rs.10000.`}
                </p>
              </>
            )}
            {process.env.REACT_APP_BROKER === "RB" && !icrm && (
              <>
                <p
                  className="text-center w-100 mt-2 content_text email_contact_text"
                  style={{ fontSize: "1.1rem" }}
                >
                  {theme_conf?.broker_config ? (
                    <>
                      In case of any further requirements, please contact us at
                      <b> {theme_conf?.broker_config?.email}</b> or call us at
                      our number
                      <b> {theme_conf?.broker_config?.phone} </b>
                    </>
                  ) : (
                    ContentFn()
                  )}
                </p>
                <Thank
                  className="text-center w-100 mt-2 thank_text"
                  style={{ fontSize: "1.1rem" }}
                >
                  Thank you for contacting us.
                </Thank>
              </>
            )}
            {((process.env.REACT_APP_BROKER === "OLA" &&
              temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
                "driver-app") ||
              process.env.REACT_APP_BROKER !== "OLA") &&
              !(
                (isMobileIOS && !standalone && !safari) ||
                userAgent.includes("wv")
              ) && (
                <div className="mt-2 d-flex justify-content-center w-100">
                  <Button
                    buttonStyle="outline-solid"
                    hex1={
                      Theme?.proposalProceedBtn?.hex1
                        ? Theme?.proposalProceedBtn?.hex1
                        : "#4ca729"
                    }
                    hex2={
                      Theme?.proposalProceedBtn?.hex2
                        ? Theme?.proposalProceedBtn?.hex2
                        : "#4ca729"
                    }
                    borderRadius={
                      Theme?.QuoteBorderAndFont?.borderRadius
                        ? Theme?.QuoteBorderAndFont?.borderRadius
                        : "25px"
                    }
                    type="submit"
                    shadow={"none"}
                    onClick={() => [
                      process.env.REACT_APP_BROKER === "OLA" &&
                      temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
                        "driver-app"
                        ? reloadPage(
                            process.env.REACT_APP_API_BASE_URL ===
                              "https://api-ola-uat.fynity.in/api"
                              ? "https://auth-repose-azure.stg.corp.olacabs.com/olamoney/kyc-web/wallet/driver/crosssell-dashboard"
                              : "https://supply-api.olacabs.com/crosssell-dashboard"
                          )
                        : !(
                            (temp_data?.agentDetails &&
                              !_.isEmpty(temp_data?.agentDetails) &&
                              !_.isEmpty(
                                temp_data?.agentDetails?.find(
                                  (o) => o?.sellerType === "E"
                                )
                              )) ||
                            (temp_data?.agentDetails &&
                              !_.isEmpty(temp_data?.agentDetails) &&
                              !_.isEmpty(
                                temp_data?.agentDetails?.find(
                                  (o) => o?.sellerType === "P"
                                )
                              ))
                          ) ||
                          process.env.REACT_APP_BROKER === "RB" ||
                          process.env.REACT_APP_BROKER === "UIB"
                        ? process.env.REACT_APP_BROKER === "BAJAJ"
                          ? reloadPage(Bajaj_rURL(true))
                          : process.env.REACT_APP_BROKER === "PINC"
                          ? reloadPage("https://pinctree.com/")
                          : reloadPage(
                              `${window.location.origin}${
                                process.env.REACT_APP_BASENAME !== "NA"
                                  ? `/${process.env.REACT_APP_BASENAME}`
                                  : ``
                              }/${
                                Number(temp_data?.productSubTypeId) === 1
                                  ? "car"
                                  : Number(temp_data?.productSubTypeId) === 2
                                  ? "bike"
                                  : "cv"
                              }/lead-page`
                            )
                        : process.env.REACT_APP_BROKER === "BAJAJ"
                        ? reloadPage(Bajaj_rURL())
                        : process.env.REACT_APP_BROKER === "PINC"
                        ? reloadPage(UrlFn())
                        : reloadPage(
                            policy?.redirection_data?.P ||
                              policy?.redirection_data?.E ||
                              UrlFn()
                          ),
                      window.Android &&
                        window.Android.SendToHomePage(
                          "Redirecting to homepage"
                        ),
                    ]}
                  >
                    Go To Homepage
                  </Button>
                </div>
              )}
            {process.env.REACT_APP_BROKER !== "OLA" && false && (
              <Col
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="d-flex justify-content-center mt-3 noOutLine"
              >
                <Btn
                  className="linkLine1"
                  variant={"link"}
                  type="button"
                  onClick={() => {
                    history.push(
                      `/feedback?enquiry_id=${enquiry_id}&&dropout=yes`
                    );
                  }}
                >
                  Provide Feedback
                </Btn>
              </Col>
            )}
          </Top>
          <GlobalStyle />
        </Row>
        <FloatButton />
      </>
    )
  ) : (
    <Loader />
  );
};

const GlobalStyle = createGlobalStyle`
.link {
  color: ${({ theme }) => theme?.Payment?.color} !important;
  &:hover {
    text-decoration: underline;
  }
}

.btn-link{
  color: ${({ theme }) =>
    process.env.REACT_APP_BROKER === "UIB" && theme?.Payment?.color}!important;

}
.email_contact_text{
  color : ${({ theme }) =>
    process.env.REACT_APP_BROKER === "UIB" &&
    theme.floatButton?.floatColor &&
    theme.floatButton?.floatColor}!important;
}
`;
const StyleP = styled.p`
  color: ${({ theme }) =>
    process.env.REACT_APP_BROKER === "UIB" && theme?.floatButton?.floatColor
      ? theme.floatButton?.floatColor
      : "black"}!important;
  font-size: ${({ lessthan767 }) => lessthan767 && "0.7rem"};
`;
const Top = styled.div`
  font-family: ${({ theme }) => theme.Payment?.fontFamily || ""};
  font-weight: ${({ theme }) => theme.Payment?.fontWeight || ""};
  @media (max-width: 767px) {
    width: 100% !important;
    padding: 0 30px;
    .success_image {
      height: 50px;
      width: 50px;
    }
    .cong_text {
      font-size: 1.3rem !important;
    }
    .policy_text {
      font-size: 0.9rem !important;
    }
    .content_text {
      font-size: 0.8rem !important;
    }
    .thank_text {
      font-size: 0.8rem !important;
    }
    .linkLine1 {
      font-size: 0.8rem !important;
    }
  }
`;

const Thank = styled.p`
  color: ${({ theme }) => theme.Payment?.color || "rgb(189,212,0)"};
`;

const Heading = styled.h4`
  margin: -15px 0px 30px 0px;
  color: gray;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px auto;
  height: 200px;
  width: 600px;
  border-radius: 15px;
  background: #292949;
  cursor: pointer;
`;

const Card = styled.div`
  height: 200px;
  width: 190px;
  color: #fff;
  padding: 15px;
  transition: 1s;
  &:hover {
    color: #fff;
    background-image: linear-gradient(
      to right,
      #ffb76b 0%,
      #ffa73d 30%,
      #ff7c00 60%,
      #ff7f04 100%
    );
    border-radius: 15px;
    transform: scale(1.1);
  }
`;

const CardIcon = styled.img`
  height: 36px;
  padding: ${(props) => props.motor && "4px 0px"};
  width: ${(props) => (props.motor ? "60px" : "36px")};
  margin: 15px auto;
`;

export default JourneySuccess;
