import React, { useState } from "react";
import { CompactCard } from "components";
import { Row, Badge } from "react-bootstrap";
import demoLogo from "../../../../assets/img/brand-digit.png";
import styled from "styled-components";
import { currencyFormater } from "utils";
import { differenceInDays, subMonths } from "date-fns";
import { toDate } from "utils";
import moment from "moment";
import { useSelector } from "react-redux";

export const InfoCardKnowMore = ({
  quote,
  finalPremium,
  totalPremium,
  gst,
  tab,
  totalPremiumC,
  revisedNcb,
  lessthan767,
}) => {
  const { temp_data } = useSelector((state) => state.home);

  return (
    <FormLeftCont>
      <FormLeftLogoNameWrap>
        <FormLeftLogo>
          <img src={quote?.companyLogo} alt="plan logo" />
        </FormLeftLogo>
        <FormLeftNameWrap>
          <FormLeftPlanName>{quote?.companyName}</FormLeftPlanName>
        </FormLeftNameWrap>
      </FormLeftLogoNameWrap>

      <FormLeftWrap>
        <FormTermDataRow>
          <FormleftTerm>
            <FormleftTermTxt>Cover Value (IDV) </FormleftTermTxt>
          </FormleftTerm>
          <FormRightTerm>
            <FormleftTermAmount>
              {tab === "tab2" ? (
                <Badge
                  variant="secondary"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Not Applicable
                </Badge>
              ) : (
                ` ₹ ${currencyFormater(quote?.idv)}`
              )}
            </FormleftTermAmount>
          </FormRightTerm>
        </FormTermDataRow>
        <FormTermDataRow>
          <FormleftTerm>
            <FormleftTermTxt>
              New NCB {tab === "tab2" ? <></> : `(${quote?.ncbDiscount}%)`}{" "}
            </FormleftTermTxt>
          </FormleftTerm>
          <FormRightTerm>
            <FormleftTermAmount>
              {tab === "tab2" ? (
                <Badge
                  variant="secondary"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Not Applicable
                </Badge>
              ) : (
                ` ₹ ${currencyFormater(revisedNcb)}`
              )}
            </FormleftTermAmount>
          </FormRightTerm>
        </FormTermDataRow>
        <FormTermDataRow>
          <FormleftTerm>
            <FormleftTermTxt>
              Final Premium
              <div>(Including GST)</div>
            </FormleftTermTxt>
          </FormleftTerm>
          <FormRightTerm>
            <FormleftTermAmount>
              ₹ {currencyFormater(finalPremium)}
            </FormleftTermAmount>
          </FormRightTerm>
        </FormTermDataRow>
        {temp_data?.corporateVehiclesQuoteRequest?.selectedGvw && (
          <FormTermDataRow>
            <FormleftTerm>
              <FormleftTermTxt>
                Gross Vehicle Weight <div>(lbs)</div>{" "}
              </FormleftTermTxt>
            </FormleftTerm>
            <FormRightTerm>
              <FormleftTermAmount>
                {" "}
                {temp_data?.selectedGvw}
              </FormleftTermAmount>
            </FormRightTerm>
          </FormTermDataRow>
        )}
      </FormLeftWrap>
    </FormLeftCont>
  );
};

// const FormLeftCont = styled.div`
//   width: 258px;
//   float: left;
//   position: relative;
//   border-radius: 12px;
//   background-color: #ffffff;
//   padding: 24px 15px 0 15px;
//   box-shadow: 0 8px 25px -5px rgba(0, 0, 0, 0.1),
//     0 10px 10px -5px rgba(0, 0, 0, 0.04);
//   border: solid 1px #e3e4e8;
//   @media (max-width: 992px) {
//     width: 100% !important;
//   }
// `;
const FormLeftCont = styled.div`
  width: 258px;
  float: left;
  position: relative;
  border-radius: 12px;
  // background-color: #ffffff;
  padding: 24px 15px 0 15px;
  // box-shadow: 0 8px 25px -5px rgba(0, 0, 0, 0.1),
  //   0 10px 10px -5px rgba(0, 0, 0, 0.04);
  // border: solid 1px #e3e4e8;
  @media (max-width: 992px) {
    width: 100% !important;
  }
`;

const FormLeftLogoNameWrap = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 0;
`;

const FormLeftLogo = styled.div`
  margin: 0px 0px 15px;
  display: flex;
  justify-content: center;
  & img {
    width: auto;

    height: 56px;
  }

  @media (max-width: 993px) {
    display: flex;
    justify-content: center;
  }
`;

const FormLeftNameWrap = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 12px;
  text-align: center;
`;

const FormLeftPlanName = styled.div`
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 14px;
  line-height: 18px;
  // color: #333;
  max-height: 38px;
  overflow: hidden;
`;

const FormLeftWrap = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 13px;
`;

const FormleftTerm = styled.div`
  float: left;
  width: 134px;
  margin-right: 0;
`;

const FormRightTerm = styled.div`
  float: left;
  text-align: right;
`;

const FormTermDataRow = styled.div`
  float: left;
  margin-bottom: 12px;
  @media (max-width: 993px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const FormleftTermTxt = styled.div`
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 12px;
  line-height: 18px;
  // color: #333;
  margin-left: 10px;
  & div {
    font-size: 10px !important;
    font-family: ${({ theme }) =>
      theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  }
`;

const FormleftTermAmount = styled.div`
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  font-size: 13px;
  line-height: 18px;
  // color: #333;
`;

export default InfoCardKnowMore;
