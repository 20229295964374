import React, { useState } from "react";
import { OnboardingConfig } from "modules/onboarding-config/onboarding-config";
import styled from "styled-components";
import { TabWrapper, Tab } from "components";
import Theme from "modules/Theme/Theme";
import { FieldConfig } from "modules";
import ProposalValidation from "modules/proposal/validations/ProposalValidation";
import { OtpConfig } from "modules/otp-config/otp-config";

const Config = () => {
  const [isActive, setActive] = useState("field");

  return (
    <>
      <Container>
        <TabWrapper width="290px" className="tabWrappers">
          <Tab
            isActive={Boolean(isActive === "theme")}
            onClick={() => setActive("theme")}
            className="shareTab"
            shareTab="shareTab"
          >
            Theme Config
          </Tab>
          <Tab
            isActive={Boolean(isActive === "field")}
            onClick={() => setActive("field")}
            className="shareTab"
            shareTab="shareTab"
          >
            Field Config
          </Tab>
          <Tab
            isActive={Boolean(isActive === "onborading")}
            onClick={() => setActive("onborading")}
            className="shareTab"
            shareTab="shareTab"
          >
            Onboarding Config
          </Tab>
          <Tab
            isActive={Boolean(isActive === "proposal-validation")}
            onClick={() => setActive("proposal-validation")}
            className="shareTab"
            shareTab="shareTab"
          >
            Proposal Validation
          </Tab>
          <Tab
            isActive={Boolean(isActive === "opt-config")}
            onClick={() => setActive("opt-config")}
            className="shareTab"
            shareTab="shareTab"
          >
            Otp Config
          </Tab>
        </TabWrapper>
      </Container>
      {isActive === "theme" ? (
        <Theme />
      ) : isActive === "field" ? (
        <FieldConfig />
      ) : isActive === "onborading" ? (
        <OnboardingConfig />
      ) : isActive === "proposal-validation" ? (
        <ProposalValidation />
      ) : (
        <OtpConfig />
      )}
    </>
  );
};

export default Config;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;
