import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Product from "../Product/Product";
import "./compare-products-list.scss";
import _ from "lodash";
import { useLocation } from "react-router";
import { currencyFormater, Encrypt } from "utils";
import {
  setComparePdfDatad,
  setValidQuotes,
} from "../../quotesPage/quote.slice";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import moment from "moment";
import { useMediaPredicate } from "react-media-hook";
import { TypeReturn } from "modules/type";
import { TopDiv } from "./CompareProductStyle";
import {
  BiFuelValue,
  ElectricalValue,
  NonElectricalValue,
} from "modules/helper";
import { ContactFn, brokerEmailFunction, getIRDAI } from "components";
import {
  getAddonName,
  getLogoUrl,
  getPolicyType,
} from "modules/quotesPage/quoteUtil";
import { GetAddonValueNoBadge, getBrokerWebsite } from "./helper";
import { BlockedSections } from "modules/quotesPage/addOnCard/cardConfig";
import PropTypes from "prop-types";

function CompareProductsList({
  compareQuotes,
  type,
  setPrevPopup,
  prevPopup,
  setSelectedId,
  setSelectedCompanyName,
  quoteComprehesive,
  setSelectedIcId,
  setSelectedCompanyAlias,
  setApplicableAddonsLits,
  scrollPosition,
  zdlp,
  setClaimList,
  zdlp_gdd,
  setClaimList_gdd,
}) {
  const ls = new SecureLS();
  const ThemeLS = ls.get("themeData");
  const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const { addOnsAndOthers, shortTerm, selectedTab } = useSelector(
    (state) => state.quotes
  );
  const { temp_data, theme_conf } = useSelector((state) => state.home);
  /// grouping addon based for private car

  const [quoteComprehesiveGrouped, setQuoteComprehesiveGrouped] =
    useState(quoteComprehesive);
  const [quoteComprehesiveGrouped1, setQuoteComprehesiveGrouped1] = useState(
    []
  );

  const GetValidAdditionalKeys = (additional) => {
    var y = Object.entries(additional)
      .filter(([, v]) => Number(v) > 0)
      .map(([k]) => k);
    return y;
  };

  useEffect(() => {
    let selectedAddons = addOnsAndOthers?.selectedAddons;
    //Adding new company alias
    let quoteComprehesiveMod = quoteComprehesive?.map((el) =>
      el?.gdd === "Y"
        ? { ...el, modifiedAlias: `gdd_${el?.companyAlias}` }
        : { ...el, modifiedAlias: el?.companyAlias }
    );
    var groupedQuotesByIC = _.groupBy(
      quoteComprehesiveMod,
      (i) => i.modifiedAlias
    );
    //----------ZD No of claims----------
    //NON GDD DIGIT
    //get list of markers
    let markerList =
      (!_.isEmpty(groupedQuotesByIC?.godigit) &&
        groupedQuotesByIC?.godigit.length > 1 &&
        _.compact(groupedQuotesByIC?.godigit.map((el) => el?.claimsCovered))) ||
      [];
    //setting list in state
    setClaimList(markerList);
    //selected marker
    let marker = selectedAddons?.includes("zeroDepreciation") ? zdlp : "";
    //Go digit claim selection: Default set to NULL
    groupedQuotesByIC =
      // Not aplicable in RB - update :13/03/2023 - enabled on RB
      process.env.REACT_APP_BROKER === "RB" && false
        ? groupedQuotesByIC
        : // Not aplicable if there s only one go digit quote
        marker && !_.isEmpty(markerList)
        ? //Filtered claim quote
          {
            ...groupedQuotesByIC,
            //If the selected marker is present , then the value of the godigit key is changed to the quote which has the marker key
            ...(!_.isEmpty(
              groupedQuotesByIC?.godigit.filter(
                (el) => el?.claimsCovered === marker
              )
            ) && {
              godigit: groupedQuotesByIC?.godigit.filter(
                (el) => el?.claimsCovered === marker
              ),
            }),
          }
        : //If no marker is selected then there is no need of filter.
          groupedQuotesByIC;

    //GDD QUOTES
    let markerList_gdd =
      (!_.isEmpty(groupedQuotesByIC?.gdd_godigit) &&
        groupedQuotesByIC?.gdd_godigit.length > 1 &&
        _.compact(
          groupedQuotesByIC?.gdd_godigit.map((el) => el?.claimsCovered)
        )) ||
      [];
    //setting list in state
    setClaimList_gdd(markerList_gdd);
    //selected marker
    let marker_gdd = selectedAddons?.includes("zeroDepreciation")
      ? zdlp_gdd
      : "";
    //Go digit claim selection: Default set to NULL
    groupedQuotesByIC =
      // Not aplicable in RB - update :13/03/2023 - enabled on RB
      process.env.REACT_APP_BROKER === "RB" && false
        ? groupedQuotesByIC
        : // Not aplicable if there s only one go digit quote
        marker_gdd && !_.isEmpty(markerList_gdd)
        ? //Filtered claim quote
          {
            ...groupedQuotesByIC,
            //If the selected marker is present , then the value of the godigit key is changed to the quote which has the marker key
            ...(!_.isEmpty(
              groupedQuotesByIC?.gdd_godigit.filter(
                (el) => el?.claimsCovered === marker_gdd
              )
            ) && {
              gdd_godigit: groupedQuotesByIC?.gdd_godigit.filter(
                (el) => el?.claimsCovered === marker_gdd
              ),
            }),
          }
        : //If no marker is selected then there is no need of filter.
          groupedQuotesByIC;
    //----x-----ZD No of claims-----x----
    var quoteComprehesiveGroupedUnique = [];
    let newList = !_.isEmpty(groupedQuotesByIC) ? _.map(groupedQuotesByIC) : [];
    newList.forEach((el) => {
      let BestMatch = [];
      let match = {};
      let quoteComprehesiveSortedFinalGrouped1 = el.map((i) => {
        if (_.isEmpty(match)) {
          match = i;
        } else {
          //get addon keys of last best
          let x1 =
            match?.addOnsData?.inBuilt &&
            Object.keys(match?.addOnsData?.inBuilt);
          let additional1 = match?.addOnsData?.additional;
          var y1 = GetValidAdditionalKeys(additional1);
          let z1 = [...x1, ...y1];
          let commonLast = selectedAddons
            ? selectedAddons.filter((m) => !_.isEmpty(z1) && z1?.includes(m))
            : 0;
          // get addon keys for current
          let x = i?.addOnsData?.inBuilt && Object.keys(i?.addOnsData?.inBuilt);
          let additional = i?.addOnsData?.additional;
          var y = GetValidAdditionalKeys(additional);
          let z = [...x, ...y];
          let commonCurrent = selectedAddons
            ? selectedAddons.filter((m) => !_.isEmpty(z) && z?.includes(m))
            : 0;

          // if current the elemenet has more addons common with selectedAddons than last then swap it with match.
          if (commonCurrent?.length > commonLast?.length) {
            match = i;
          }
          if (commonCurrent?.length === commonLast?.length) {
            //find premium of match & current elem when the addons matched are equal in number
            let matchAddonPremium = 0;
            let currentAddonPremium = 0;

            Object.entries(
              match?.addOnsData?.additional ? match?.addOnsData?.additional : {}
            ).forEach(([key, value]) => {
              matchAddonPremium =
                Number(matchAddonPremium) + (Number(value) ? Number(value) : 0);
            });

            //calculation matched - other addons
            Object.entries(
              match?.addOnsData?.other ? match?.addOnsData?.other : {}
            ).forEach(([key, value]) => {
              matchAddonPremium =
                Number(matchAddonPremium) + (Number(value) ? Number(value) : 0);
            });

            Object.entries(
              i?.addOnsData?.additional ? i?.addOnsData?.additional : {}
            ).forEach(([key, value]) => {
              currentAddonPremium =
                Number(currentAddonPremium) +
                (Number(value) ? Number(value) : 0);
            });

            //calculation matched - other addons
            Object.entries(
              i?.addOnsData?.other ? i?.addOnsData?.other : {}
            ).forEach(([key, value]) => {
              currentAddonPremium =
                Number(currentAddonPremium) +
                (Number(value) ? Number(value) : 0);
            });

            //calculation matched - other addons

            Object.entries(
              match?.addOnsData?.inBuilt ? match?.addOnsData?.inBuilt : {}
            ).forEach(([key, value]) => {
              matchAddonPremium =
                Number(matchAddonPremium) + (Number(value) ? Number(value) : 0);
            });

            Object.entries(
              i?.addOnsData?.inBuilt ? i?.addOnsData?.inBuilt : {}
            ).forEach(([key, value]) => {
              currentAddonPremium =
                Number(currentAddonPremium) +
                (Number(value) ? Number(value) : 0);
            });
            if (currentAddonPremium < matchAddonPremium) {
              match = i;
            }
          }
        }
      });
      !_.isEmpty(match) && BestMatch.push(match);
      //No need to sort after filtering only one best match.
      quoteComprehesiveGroupedUnique.push(BestMatch[0]);
    });

    if (
      TypeReturn(type) === "car" ||
      TypeReturn(type) === "bike" ||
      TypeReturn(type) === "cv"
    ) {
      let uniquedGroup = quoteComprehesiveGroupedUnique.filter(Boolean);

      let uniquedGroup1 = _.uniqBy(uniquedGroup, "modifiedAlias");

      setQuoteComprehesiveGrouped(uniquedGroup1);
    } else {
      let sortedGroupedcomp = _.sortBy(quoteComprehesive, [
        "finalPayableAmount",
      ]);

      setQuoteComprehesiveGrouped(sortedGroupedcomp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOnsAndOthers?.selectedAddons, quoteComprehesive, zdlp]);

  const [addonDiscountPercentage, setAddonDiscountPercentage] = useState(0);
  useEffect(() => {
    if (quoteComprehesiveGrouped) {
      let sortedAndGrouped = quoteComprehesiveGrouped.map((quote) => {
        let additional = quote?.addOnsData?.additional
          ? Object.keys(quote?.addOnsData?.additional)
          : [];
        let additionalList = quote?.addOnsData?.additional;
        let selectedAddons = addOnsAndOthers?.selectedAddons || [];
        let totalAdditional = 0;
        let totalPayableAmountWithAddon = 0;
        let totalPremiumA =
          quote?.finalOdPremium * 1 +
          ElectricalValue(quote) +
          NonElectricalValue(quote) +
          BiFuelValue(quote);
        //ncb calculation / discount part
        let totalPremiumc = quote?.finalTotalDiscount;
        let revisedNcb = quote?.deductionOfNcb;
        let otherDiscounts = quote?.icVehicleDiscount || 0;
        let addedNcb = 0;
        //addon calculation
        selectedAddons.forEach((el) => {
          if (
            !_.isEmpty(additional) &&
            additional?.includes(el) &&
            typeof additionalList[el] === "number"
          ) {
            totalAdditional =
              totalAdditional +
              (quote?.company_alias === "royal_sundaram"
                ? el === "ncbProtection" ||
                  el === "engineProtector" ||
                  el === "tyreSecure"
                  ? Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100 <
                      200 && Number(additionalList[el]) > 0
                    ? 200
                    : Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100
                  : el === "roadSideAssistance"
                  ? Number(additionalList[el])
                  : Number(additionalList[el]) -
                      (Number(additionalList[el]) * addonDiscountPercentage) /
                        100 <
                      100 && Number(additionalList[el]) > 0
                  ? 100
                  : Number(additionalList[el]) -
                    (Number(additionalList[el]) * addonDiscountPercentage) / 100
                : Number(additionalList[el]));
          }
        });

        let inbuilt = quote?.addOnsData?.inBuilt
          ? Object.keys(quote?.addOnsData?.inBuilt)
          : [];
        let allAddons = [
          "zeroDepreciation",
          "roadSideAssistance",
          "imt23",
          "keyReplace",
          "engineProtector",
          "ncbProtection",
          "consumables",
          "tyreSecure",
          "returnToInvoice",
          "lopb",
          "emergencyMedicalExpenses",
        ];
        let inbuiltList = quote?.addOnsData?.inBuilt;
        let totalInbuilt = 0;
        allAddons.forEach((el) => {
          if (
            !_.isEmpty(inbuilt) &&
            inbuilt?.includes(el) &&
            typeof inbuiltList[el] === "number"
          ) {
            totalInbuilt =
              totalInbuilt +
              (quote?.company_alias === "royal_sundaram"
                ? el === "ncbProtection" ||
                  el === "engineProtector" ||
                  el === "tyreSecure"
                  ? Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) /
                        100 <
                      200 && Number(inbuiltList[el]) > 0
                    ? 200
                    : Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
                  : el === "roadSideAssistance"
                  ? Number(inbuiltList[el])
                  : Number(inbuiltList[el]) -
                      (Number(inbuiltList[el]) * addonDiscountPercentage) /
                        100 <
                      100 && Number(inbuiltList[el]) > 0
                  ? 100
                  : Number(inbuiltList[el]) -
                    (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
                : Number(inbuiltList[el]));
          }
        });

        let others =
          (quote?.addOnsData?.other && Object.keys(quote?.addOnsData?.other)) ||
          [];
        let othersList = quote?.addOnsData?.other;
        let totalOther = 0;
        others.forEach((el) => {
          if (typeof othersList[el] === "number") {
            totalOther = totalOther + Number(othersList[el]);
          }
        });
        let totalAddon =
          Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);
        if (quote?.company_alias === "oriental" && TypeReturn(type) === "cv") {
          // For Oriental CV, you need to use following formula:NCB premium = (Total OD premium + Addons - discounts(anti theft)) * applicable NCB
          let extraOtherDiscounts = 0;
          let discountPercentageOriental = 0.7;
          //for ncb zd is included.
          extraOtherDiscounts = totalAddon * discountPercentageOriental;
          //for extradiscounts we don't need ZeroDep hence recalc total (addon * discount %) without zd ------
          //additional & selected
          let totalAdditional = 0;
          selectedAddons.forEach((el) => {
            if (
              additional?.includes(
                el === "zeroDepreciation" ? "nomatch" : el
              ) &&
              typeof additionalList[el] === "number"
            ) {
              totalAdditional = totalAdditional + Number(additionalList[el]);
            }
          });
          //Inbuilt
          let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
          let allAddons = [
            "roadSideAssistance",
            "imt23",
            "keyReplace",
            "engineProtector",
            "ncbProtection",
            "consumables",
            "tyreSecure",
            "returnToInvoice",
            "lopb",
            "emergencyMedicalExpenses",
          ];

          let inbuiltList = quote?.addOnsData?.inBuilt;
          let totalInbuilt = 0;
          allAddons.forEach((el) => {
            if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
              totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
            }
          });
          //other
          let others =
            (quote?.addOnsData?.other &&
              Object.keys(quote?.addOnsData?.other)) ||
            [];
          let othersList = quote?.addOnsData?.other;
          let totalOther = 0;
          others.forEach((el) => {
            if (typeof othersList[el] === "number") {
              totalOther = totalOther + Number(othersList[el]);
            }
          });

          let extraOtherDiscounts2 =
            (Number(totalAdditional) +
              Number(totalInbuilt) +
              Number(totalOther)) *
            discountPercentageOriental;
          addedNcb =
            ((totalAddon - extraOtherDiscounts2) * Number(quote?.ncbDiscount)) /
            100;

          revisedNcb = Number(quote?.deductionOfNcb) + Number(addedNcb);
          otherDiscounts =
            (quote?.icVehicleDiscount || 0) + Number(extraOtherDiscounts2);
          totalPremiumc =
            Number(quote?.finalTotalDiscount) +
            Number(addedNcb) +
            Number(extraOtherDiscounts2);
        } else if (
          (((selectedAddons?.includes("imt23") &&
            additional?.includes("imt23") &&
            typeof additionalList["imt23"] === "number") ||
            inbuilt?.includes("imt23")) &&
            quote?.company_alias === "hdfc_ergo") ||
          quote?.company_alias === "godigit" ||
          quote?.company_alias === "shriram" ||
          quote?.company_alias === "reliance" ||
          quote?.company_alias === "sbi"
        ) {
          if (
            selectedAddons?.includes("imt23") &&
            additional?.includes("imt23") &&
            typeof additionalList["imt23"] === "number"
          ) {
            addedNcb =
              (Number(additionalList["imt23"]) * Number(quote?.ncbDiscount)) /
              100;
          } else if (
            inbuilt?.includes("imt23") &&
            typeof inbuiltList["imt23"] === "number"
          ) {
            addedNcb = Number(
              (inbuiltList["imt23"] * Number(quote?.ncbDiscount)) / 100
            );
          }
          revisedNcb = Number(quote?.deductionOfNcb) + Number(addedNcb);
          totalPremiumc = Number(quote?.finalTotalDiscount) + Number(addedNcb);
        } else if (
          ((selectedAddons?.includes("imt23") &&
            additional?.includes("imt23") &&
            typeof additionalList["imt23"] === "number") ||
            inbuilt?.includes("imt23")) &&
          quote?.company_alias === "icici_lombard"
        ) {
          let othrDiscount =
            quote?.icVehicleDiscount * 1 ? quote?.icVehicleDiscount * 1 : 0;

          otherDiscounts = othrDiscount;
          revisedNcb =
            ((totalPremiumA +
              (selectedAddons?.includes("imt23") &&
              additional?.includes("imt23") &&
              additionalList["imt23"] * 1
                ? additionalList["imt23"] * 1
                : inbuiltList["imt23"] * 1)) *
              Number(quote?.ncbDiscount)) /
            100;
          totalPremiumc =
            ((selectedAddons?.includes("imt23") &&
            additional?.includes("imt23") &&
            additionalList["imt23"] * 1
              ? additionalList["imt23"] * 1
              : inbuiltList["imt23"] * 1) *
              Number(quote?.ncbDiscount)) /
              100 +
            (quote?.icVehicleDiscount * 1 ? quote?.icVehicleDiscount * 1 : 0) +
            (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0);
        } else if (
          TypeReturn(type) === "cv" &&
          quote?.company_alias === "magma"
        ) {
          if (
            ((selectedAddons?.includes("imt23") &&
              additional?.includes("imt23") &&
              typeof additionalList["imt23"] === "number") ||
              inbuilt?.includes("imt23")) &&
            quote?.company_alias === "magma"
          ) {
            if (quote?.imt23Discount * 1) {
              let otherDiscounts =
                quote?.icVehicleDiscount * 1
                  ? quote?.icVehicleDiscount * 1
                  : 0 + quote?.imt23Discount * 1;
              revisedNcb =
                ((totalPremiumA +
                  (selectedAddons?.includes("imt23") &&
                  additional?.includes("imt23") &&
                  additionalList["imt23"] * 1
                    ? additionalList["imt23"] * 1
                    : inbuiltList["imt23"] * 1) -
                  otherDiscounts) *
                  Number(quote?.ncbDiscount)) /
                100;
              totalPremiumc =
                revisedNcb +
                otherDiscounts +
                (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0);
            } else {
              otherDiscounts = quote?.icVehicleDiscount || 0;
              revisedNcb =
                ((totalPremiumA +
                  (selectedAddons?.includes("imt23") &&
                  additional?.includes("imt23") &&
                  additionalList["imt23"] * 1
                    ? additionalList["imt23"] * 1
                    : inbuiltList["imt23"] * 1) -
                  otherDiscounts) *
                  Number(quote?.ncbDiscount)) /
                100;
              totalPremiumc =
                revisedNcb +
                otherDiscounts +
                (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0);
            }
          } else {
            otherDiscounts = quote?.icVehicleDiscount || 0;
            revisedNcb =
              ((totalPremiumA - otherDiscounts) * Number(quote?.ncbDiscount)) /
              100;
            totalPremiumc =
              revisedNcb +
              otherDiscounts +
              (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0);
          }
        } else if (
          ((selectedAddons?.includes("imt23") &&
            additional?.includes("imt23") &&
            typeof additionalList["imt23"] === "number") ||
            inbuilt?.includes("imt23")) &&
          quote?.company_alias === "bajaj_allianz" &&
          temp_data?.journeyCategory === "GCV" &&
          quote?.isCvJsonKit
        ) {
          if (
            (selectedAddons?.includes("imt23") &&
              additional?.includes("imt23") &&
              typeof additionalList["imt23"] === "number") ||
            (inbuilt?.includes("imt23") &&
              typeof inbuiltList["imt23"] === "number")
          ) {
            let othrDiscount =
              (quote?.icVehicleDiscount * 1
                ? quote?.icVehicleDiscount * 1
                : 0) * 1.15;

            otherDiscounts = othrDiscount;
            revisedNcb =
              ((totalPremiumA +
                (selectedAddons?.includes("imt23") &&
                additional?.includes("imt23") &&
                additionalList["imt23"] * 1
                  ? additionalList["imt23"] * 1
                  : inbuiltList["imt23"] * 1)) *
                Number(quote?.ncbDiscount)) /
              100;
            totalPremiumc =
              ((selectedAddons?.includes("imt23") &&
              additional?.includes("imt23") &&
              additionalList["imt23"] * 1
                ? additionalList["imt23"] * 1
                : inbuiltList["imt23"] * 1) *
                Number(quote?.ncbDiscount)) /
                100 +
              (quote?.icVehicleDiscount * 1
                ? quote?.icVehicleDiscount * 1
                : 0) *
                1.15 +
              (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0);
          }
        } else if (
          ((selectedAddons?.includes("imt23") &&
            additional?.includes("imt23") &&
            typeof additionalList["imt23"] === "number") ||
            inbuilt?.includes("imt23")) &&
          quote?.company_alias === "universal_sompo" &&
          temp_data?.journeyCategory === "GCV" &&
          quote?.isCvJsonKit
        ) {
          if (
            (selectedAddons?.includes("imt23") &&
              additional?.includes("imt23") &&
              typeof additionalList["imt23"] === "number") ||
            (inbuilt?.includes("imt23") &&
              typeof inbuiltList["imt23"] === "number")
          ) {
            let othrDiscount =
              (quote?.icVehicleDiscount * 1
                ? quote?.icVehicleDiscount * 1
                : 0) * 1.15;

            otherDiscounts = othrDiscount;
            revisedNcb = Number(quote?.deductionOfNcb) * 1.15;
            totalPremiumc =
              Number(quote?.deductionOfNcb) * 1.15 +
              (quote?.icVehicleDiscount * 1
                ? quote?.icVehicleDiscount * 1
                : 0) *
                1.15 +
              (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0);
          }
        } else if (
          quote?.company_alias === "royal_sundaram" &&
          TypeReturn(type) === "car" &&
          addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")
        ) {
          const g1 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "engineProtector",
          ]; // 10 % on final addons
          const g2 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "lopb",
            "engineProtector",
          ]; //15% on finaladdons
          const g3 = [
            "zeroDepreciation",
            "returnToInvoice",
            "ncbProtection",
            "lopb",
            "tyreSecure",
            "keyReplace",
            "engineProtector",
          ]; // 20 % on final addons
          let addonsSelectedKeys = addOnsAndOthers?.selectedAddons;
          let addonsSelected = _.compact(
            addonsSelectedKeys.map((v) =>
              Object.keys(quote?.addOnsData?.inBuilt).includes(v) ||
              quote?.addOnsData?.additional[v] * 1
                ? v
                : false
            )
          );
          if (_.intersection(g3, addonsSelected)?.length >= 4) {
            setAddonDiscountPercentage(20);
            revisedNcb = Number(quote?.deductionOfNcb);
            totalPremiumc = Number(quote?.finalTotalDiscount);
          } else if (_.intersection(g2, addonsSelected)?.length === 3) {
            setAddonDiscountPercentage(15);
            revisedNcb = Number(quote?.deductionOfNcb);
            totalPremiumc = Number(quote?.finalTotalDiscount);
          } else if (_.intersection(g1, addonsSelected)?.length === 2) {
            setAddonDiscountPercentage(10);
            revisedNcb = Number(quote?.deductionOfNcb);
            totalPremiumc = Number(quote?.finalTotalDiscount);
          } else {
            setAddonDiscountPercentage(0);
            revisedNcb = Number(quote?.deductionOfNcb);
            totalPremiumc = Number(quote?.finalTotalDiscount);
          }
          otherDiscounts = quote?.icVehicleDiscount || 0;
        } else if (
          quote?.company_alias === "royal_sundaram" &&
          TypeReturn(type) === "car" &&
          !addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")
        ) {
          setAddonDiscountPercentage(0);
          revisedNcb = Number(quote?.deductionOfNcb);
          totalPremiumc = Number(quote?.finalTotalDiscount);
          otherDiscounts = quote?.icVehicleDiscount || 0;
        } else if (
          quote?.company_alias === "oriental" &&
          TypeReturn(type) === "car"
        ) {
          // re-eval required addons with others
          //additional & selected
          let totalAdditional = 0;
          selectedAddons.forEach((el) => {
            if (
              additional?.includes(
                ![
                  "zeroDepreciation",
                  "engineProtector",
                  "returnToInvoice",
                  "lopb",
                ].includes(el)
                  ? "nomatch"
                  : el
              ) &&
              typeof additionalList[el] === "number"
            ) {
              totalAdditional = totalAdditional + Number(additionalList[el]);
            }
          });
          //Inbuilt
          let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
          let allAddons = [
            "zeroDepreciation",
            "engineProtector",
            "returnToInvoice",
            "lopb",
          ];

          let inbuiltList = quote?.addOnsData?.inBuilt;
          let totalInbuilt = 0;
          allAddons.forEach((el) => {
            if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
              totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
            }
          });
          //other
          let others =
            (quote?.addOnsData?.other &&
              Object.keys(quote?.addOnsData?.other)) ||
            [];
          let othersList = quote?.addOnsData?.other;
          let totalOther = 0;
          others.forEach((el) => {
            if (typeof othersList[el] === "number") {
              totalOther = totalOther + Number(othersList[el]);
            }
          });
          let NcbTotalAddon =
            Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);
          revisedNcb = Math.round(
            ((totalPremiumA * 1 +
              NcbTotalAddon * 1 -
              (Number(quote?.finalTotalDiscount) -
                Number(quote?.deductionOfNcb) -
                (Number(quote.tppdDiscount)
                  ? Number(quote.tppdDiscount)
                  : 0))) *
              Number(quote?.ncbDiscount)) /
              100
          );
          totalPremiumc =
            Number(quote?.finalTotalDiscount) -
            //deducting the ncb sent by backend
            Number(quote?.deductionOfNcb) +
            //calculating ncb and adding it to total discount
            Math.round(
              ((totalPremiumA * 1 +
                NcbTotalAddon * 1 -
                (Number(quote?.finalTotalDiscount) -
                  Number(quote?.deductionOfNcb) -
                  (Number(quote.tppdDiscount)
                    ? Number(quote.tppdDiscount)
                    : 0))) *
                Number(quote?.ncbDiscount)) /
                100
            );
        } else if (
          quote?.company_alias === "united_india" &&
          TypeReturn(type) === "car"
        ) {
          // re-eval required addons with others
          //additional & selected
          let totalAdditional = 0;
          selectedAddons.forEach((el) => {
            if (
              additional?.includes(
                !["zeroDepreciation", "returnToInvoice", "lopb"].includes(el)
                  ? "nomatch"
                  : el
              ) &&
              typeof additionalList[el] === "number"
            ) {
              totalAdditional = totalAdditional + Number(additionalList[el]);
            }
          });
          //Inbuilt
          let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
          let allAddons = ["zeroDepreciation", "returnToInvoice", "lopb"];

          let inbuiltList = quote?.addOnsData?.inBuilt;
          let totalInbuilt = 0;
          allAddons.forEach((el) => {
            if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
              totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
            }
          });
          //other
          let others =
            (quote?.addOnsData?.other &&
              Object.keys(quote?.addOnsData?.other)) ||
            [];
          let othersList = quote?.addOnsData?.other;
          let totalOther = 0;
          others.forEach((el) => {
            if (typeof othersList[el] === "number") {
              totalOther = totalOther + Number(othersList[el]);
            }
          });
          let NcbTotalAddon =
            Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);
          revisedNcb = Math.round(
            ((totalPremiumA * 1 +
              NcbTotalAddon * 1 -
              (Number(quote?.finalTotalDiscount) -
                Number(quote?.deductionOfNcb) -
                (Number(quote.tppdDiscount)
                  ? Number(quote.tppdDiscount)
                  : 0))) *
              Number(quote?.ncbDiscount)) /
              100
          );
          totalPremiumc =
            Number(quote?.finalTotalDiscount) -
            //deducting the ncb sent by backend
            Number(quote?.deductionOfNcb) +
            //calculating ncb and adding it to total discount
            Math.round(
              ((totalPremiumA * 1 +
                NcbTotalAddon * 1 -
                (Number(
                  quote?.finalTotalDiscount ? quote?.finalTotalDiscount : 0
                ) -
                  Number(quote?.deductionOfNcb ? quote?.deductionOfNcb : 0) -
                  Number(quote?.tppdDiscount ? quote?.tppdDiscount : 0))) *
                Number(quote?.ncbDiscount ? quote?.ncbDiscount : 0)) /
                100
            );
        } else {
          revisedNcb = Number(quote?.deductionOfNcb);
          totalPremiumc = Number(quote?.finalTotalDiscount);
          otherDiscounts = quote?.icVehicleDiscount || 0;
        }
        //cpa part
        let totalPremiumB = quote?.finalTpPremium;
        let selectedCpa = addOnsAndOthers?.selectedCpa;
        let cpa = 0;
        if (selectedCpa?.includes("Compulsory Personal Accident")) {
          if (!_.isEmpty(addOnsAndOthers?.isTenure)) {
            cpa = quote?.multiYearCpa ? quote?.multiYearCpa : 0;
          } else {
            cpa = quote?.compulsoryPaOwnDriver;
          }
        } else {
          cpa = 0;
        }
        totalPremiumB =
          Number(quote?.finalTpPremium) +
          Number(cpa) + //adding un-named passenger cover in multi year cpa sbi.
          (quote?.companyAlias === "sbi" &&
          selectedCpa?.includes("Compulsory Personal Accident") &&
          !_.isEmpty(addOnsAndOthers?.isTenure) &&
          quote?.coverUnnamedPassengerValue * 1
            ? quote?.coverUnnamedPassengerValue *
              (TypeReturn(type) === "bike" ? 4 : 2)
            : 0) +
          //adding additional paid driver cover in multi year cpa sbi.
          (quote?.companyAlias === "sbi" &&
          selectedCpa?.includes("Compulsory Personal Accident") &&
          !_.isEmpty(addOnsAndOthers?.isTenure) &&
          quote?.motorAdditionalPaidDriver * 1
            ? quote?.motorAdditionalPaidDriver *
              (TypeReturn(type) === "bike" ? 4 : 2)
            : 0);

        let applicableAddons = [];
        if (temp_data?.tab !== "tab2") {
          var addonsSelectedList = [];
          if (!_.isEmpty(selectedAddons) || !_.isEmpty(inbuilt)) {
            selectedAddons.forEach((el) => {
              if (
                additional?.includes(el) &&
                Number(additionalList[el]) !== 0
              ) {
                var newList = {
                  name: getAddonName(el),
                  premium: Number(additionalList[el]),
                };
                addonsSelectedList.push(newList);
              }
            });
            inbuilt.forEach((el) => {
              var newList = {
                name: getAddonName(el),
                premium: Number(inbuiltList[el]),
              };
              addonsSelectedList.push(newList);
            });

            applicableAddons = addonsSelectedList;
          } else {
            applicableAddons = [];
          }
        }
        //uv loading
        let uwLoading = 0;
        if (
          quote?.companyAlias === "shriram" &&
          TypeReturn(type) === "bike" &&
          (quote?.policyType === "Comprehensive" ||
            quote?.policyType === "Own Damage") &&
          totalPremiumA +
            totalAddon -
            totalPremiumc +
            (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0) <
            50
        ) {
          uwLoading =
            50 -
            (totalPremiumA +
              totalAddon -
              totalPremiumc +
              (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0));
        } else {
          uwLoading = 0;
        }

        let totalLoading = 0;

        if (true) {
          if (
            Number(quote?.totalLoadingAmount) > 0 ||
            Number(quote?.underwritingLoadingAmount)
          ) {
            totalLoading =
              Number(quote?.totalLoadingAmount) ||
              Number(quote?.underwritingLoadingAmount);
          } else {
            totalLoading = 0;
          }
        } else {
          totalLoading = 0;
        }

        let totalPremium =
          Number(totalAddon) +
          Number(totalPremiumA) +
          Number(totalPremiumB) -
          Number(totalPremiumc) +
          Number(uwLoading) +
          Number(totalLoading);
        let totalPremiumGst = parseInt((totalPremium * 18) / 100);

        if (temp_data?.journeyCategory === "GCV") {
          if (quote.company_alias === "oriental") {
            //In Oriental , TPPD discount is not added to third party liability for GST calc
            totalPremiumGst =
              parseInt(((totalPremium - quote?.tppdPremiumAmount) * 18) / 100) +
              (quote?.tppdPremiumAmount * 12) / 100;
          } else if (quote.company_alias === "sbi") {
            //In sbi , Basic tp - 12%, rest 18%
            totalPremiumGst =
              parseInt(((totalPremium - quote?.tppdPremiumAmount) * 18) / 100) +
              (quote?.tppdPremiumAmount * 12) / 100;
          } else if (quote.company_alias === "godigit") {
            // GST calc for other IC's in GCV
            totalPremiumGst = parseInt(
              //basic tp
              ((quote?.tppdPremiumAmount -
                //tppd discount
                (Number(quote?.tppdDiscount)
                  ? Number(quote?.tppdDiscount)
                  : 0) +
                //cng/lpg
                (quote?.cngLpgTp * 1 ? quote?.cngLpgTp * 1 : 0)) *
                12) /
                100 +
                //rest of the liability values
                ((totalPremiumB -
                  quote?.tppdPremiumAmount +
                  //total od + addons - ncb
                  totalPremiumA +
                  totalAddon -
                  (totalPremiumc -
                    (Number(quote?.tppdDiscount)
                      ? Number(quote?.tppdDiscount)
                      : 0)) -
                  (quote?.cngLpgTp * 1 ? quote?.cngLpgTp * 1 : 0)) *
                  18) /
                  100
            );
          } else if (quote.company_alias === "universal_sompo") {
            // GST calc for other IC's in GCV
            totalPremiumGst = parseInt(
              ((totalPremium -
                quote?.tppdPremiumAmount +
                (Number(quote?.tppdDiscount)
                  ? Number(quote?.tppdDiscount)
                  : 0)) *
                18) /
                100 +
                (quote?.tppdPremiumAmount * 0.12 -
                  (Number(quote?.tppdDiscount)
                    ? Number(quote?.tppdDiscount)
                    : 0) *
                    0.18)
            );
          } else {
            // GST calc for other IC's in GCV
            totalPremiumGst =
              parseInt(
                ((totalPremium -
                  quote?.tppdPremiumAmount +
                  (Number(quote?.tppdDiscount)
                    ? Number(quote?.tppdDiscount)
                    : 0)) *
                  18) /
                  100
              ) +
              ((quote?.tppdPremiumAmount -
                (Number(quote?.tppdDiscount)
                  ? Number(quote?.tppdDiscount)
                  : 0)) *
                12) /
                100;
          }
        }
        let FinalPremium = totalPremium + totalPremiumGst;
        return {
          ...quote,
          totalPremiumA1: totalPremiumA,
          totalAddon1: totalAddon,
          finalPremium1: FinalPremium,
          totalPremium1: totalPremium,
          totalPremiumB1: totalPremiumB,
          totalPremiumc1: totalPremiumc,
          addonDiscountPercentage1: addonDiscountPercentage,
          applicableAddons1: applicableAddons,
          gst1: totalPremiumGst,
          revisedNcb1: revisedNcb,
        };
      });
      let sortedGroupedcomp1 = _.sortBy(sortedAndGrouped, [
        "totalPayableAmountWithAddon",
      ]);
      setQuoteComprehesiveGrouped1(sortedGroupedcomp1);
    }
  }, [
    addOnsAndOthers?.selectedAddons,
    quoteComprehesiveGrouped,
    addOnsAndOthers?.selectedCpa,
  ]);

  const [icLists, setICLists] = useState([]);
  useEffect(() => {
    if (compareQuotes) {
      setICLists(
        _.compact(
          compareQuotes.map((x) =>
            x?.modifiedAlias ? x.modifiedAlias : x?.company_alias
          )
        )
      );
    }
  }, [compareQuotes]);

  const [newGroupedQuotesCompare, setNewGroupedQuotesCompare] =
    useState(compareQuotes);
  useEffect(() => {
    if (quoteComprehesiveGrouped1) {
      let FilteredGroupedByIc = quoteComprehesiveGrouped1?.filter(
        ({ modifiedAlias, company_alias }) =>
          modifiedAlias
            ? icLists?.includes(modifiedAlias)
            : icLists?.includes(company_alias)
      );
      let filteredExistingQuote = compareQuotes?.filter(
        ({ minIdv }) => minIdv === 1
      );
      setNewGroupedQuotesCompare(
        _.concat(FilteredGroupedByIc, filteredExistingQuote)
      );
    }
  }, [quoteComprehesiveGrouped1, compareQuotes, icLists]);
  const [validQuote, setValidQuote] = useState(false);
  useEffect(() => {
    if (newGroupedQuotesCompare) {
      let validQuote = !_.isEmpty(newGroupedQuotesCompare)
        ? _.compact(
            newGroupedQuotesCompare.map(({ companyName }) =>
              companyName ? companyName : null
            )
          )
        : [];
      setValidQuote(validQuote);
      dispatch(setValidQuotes(validQuote));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newGroupedQuotesCompare]);

  //pdf json creations
  useEffect(() => {
    if (newGroupedQuotesCompare) {
      var premiumBreakupArray = [];
      premiumBreakupArray.push([
        "Own Damage Premium",
        "Third Party Premium",
        "Add On Premium",
        `Total Discount (NCB ${temp_data?.newNcb} Incl.)`,
        `GST`,
        "Gross Premium (incl. GST)",
      ]);
      premiumBreakupArray.push([
        `₹ ${currencyFormater(newGroupedQuotesCompare[0]?.totalPremiumA1 * 1)}`,
        `₹ ${currencyFormater(
          newGroupedQuotesCompare[0]?.totalPremiumB1 -
            (newGroupedQuotesCompare[0]?.tppdDiscount * 1 || 0)
        )}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[0]?.totalAddon1)}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[0]?.totalPremiumc1)}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[0]?.gst1)}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[0]?.finalPremium1)}`,
      ]);
      premiumBreakupArray.push([
        `₹ ${currencyFormater(newGroupedQuotesCompare[1]?.totalPremiumA1 * 1)}`,
        `₹ ${currencyFormater(
          newGroupedQuotesCompare[1]?.totalPremiumB1 -
            (newGroupedQuotesCompare[1]?.tppdDiscount * 1 || 0)
        )}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[1]?.totalAddon1)}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[1]?.totalPremiumc1)}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[1]?.gst1)}`,
        `₹ ${currencyFormater(newGroupedQuotesCompare[1]?.finalPremium1)}`,
      ]);
      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        premiumBreakupArray.push([
          `₹ ${currencyFormater(
            newGroupedQuotesCompare[2]?.totalPremiumA1 * 1
          )}`,
          `₹ ${currencyFormater(
            newGroupedQuotesCompare[2]?.totalPremiumB1 -
              (newGroupedQuotesCompare[2]?.tppdDiscount * 1 || 0)
          )}`,
          `₹ ${currencyFormater(newGroupedQuotesCompare[2]?.totalAddon1)}`,
          `₹ ${currencyFormater(newGroupedQuotesCompare[2]?.totalPremiumc1)}`,
          `₹ ${currencyFormater(newGroupedQuotesCompare[2]?.gst1)}`,
          `₹ ${currencyFormater(newGroupedQuotesCompare[2]?.finalPremium1)}`,
        ]);
      }
      var accessoriesArray = [];
      if (temp_data.journeyCategory === "GCV") {
        accessoriesArray.push([
          `Electrical Accessories ${
            addOnsAndOthers?.selectedAccesories?.includes(
              "Electrical Accessories"
            )
              ? `(${addOnsAndOthers?.vehicleElectricAccessories})`
              : ""
          }  `,
          `Non Electrical Accessories ${
            addOnsAndOthers?.selectedAccesories?.includes(
              "Non-Electrical Accessories"
            )
              ? `(${addOnsAndOthers?.vehicleNonElectricAccessories})`
              : ""
          } `,

          `Bi Fuel Kit  ${
            addOnsAndOthers?.selectedAccesories?.includes(
              "External Bi-Fuel Kit CNG/LPG"
            )
              ? `(${addOnsAndOthers?.externalBiFuelKit})`
              : ``
          } `,
          `Trailer  ${
            addOnsAndOthers?.selectedAccesories?.includes("Trailer")
              ? `(${addOnsAndOthers?.trailerCover})`
              : ``
          } `,
        ]);
      } else {
        accessoriesArray.push([
          `Electrical Accessories ${
            addOnsAndOthers?.selectedAccesories?.includes(
              "Electrical Accessories"
            )
              ? `(${addOnsAndOthers?.vehicleElectricAccessories})`
              : ""
          }  `,
          `Non Electrical Accessories ${
            addOnsAndOthers?.selectedAccesories?.includes(
              "Non-Electrical Accessories"
            )
              ? `(${addOnsAndOthers?.vehicleNonElectricAccessories})`
              : ""
          } `,
          TypeReturn(type) === "bike"
            ? ""
            : `Bi Fuel Kit  ${
                addOnsAndOthers?.selectedAccesories?.includes(
                  "External Bi-Fuel Kit CNG/LPG"
                )
                  ? `(${addOnsAndOthers?.externalBiFuelKit})`
                  : ``
              } `,
        ]);
      }
      if (temp_data.journeyCategory === "GCV") {
        accessoriesArray.push([
          addOnsAndOthers?.selectedAccesories?.includes(
            "Electrical Accessories"
          )
            ? Number(
                newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
              ) !== 0
              ? `₹ ${currencyFormater(
                  Number(
                    newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
                  ) +
                    ElectricalValue(newGroupedQuotesCompare[0], addOnsAndOthers)
                )}`
              : newGroupedQuotesCompare[0]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes(
            "Non-Electrical Accessories"
          )
            ? Number(
                newGroupedQuotesCompare[0]?.motorNonElectricAccessoriesValue
              ) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[0]?.motorNonElectricAccessoriesValue +
                    NonElectricalValue(
                      newGroupedQuotesCompare[0],
                      addOnsAndOthers
                    )
                )}`
              : newGroupedQuotesCompare[0]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes(
            "External Bi-Fuel Kit CNG/LPG"
          )
            ? Number(newGroupedQuotesCompare[0]?.motorLpgCngKitValue) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[0]?.motorLpgCngKitValue +
                    BiFuelValue(newGroupedQuotesCompare[0], addOnsAndOthers)
                )}`
              : newGroupedQuotesCompare[0]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",

          addOnsAndOthers?.selectedAccesories?.includes("Trailer")
            ? Number(newGroupedQuotesCompare[0]?.trailerValue) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[0]?.trailerValue
                )}`
              : newGroupedQuotesCompare[0]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",
        ]);
      } else {
        accessoriesArray.push([
          addOnsAndOthers?.selectedAccesories?.includes(
            "Electrical Accessories"
          )
            ? newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
              ? Number(
                  newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[0]?.motorElectricAccessoriesValue
                    ) + ElectricalValue(newGroupedQuotesCompare[0])
                  )}`
                : newGroupedQuotesCompare[0]?.companyAlias === "godigit"
                ? "Included"
                : "Not Available"
              : newGroupedQuotesCompare[0]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes(
            "Non-Electrical Accessories"
          )
            ? newGroupedQuotesCompare[0]?.motorNonElectricAccessoriesValue
              ? Number(
                  newGroupedQuotesCompare[0]?.motorNonElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[0]
                        ?.motorNonElectricAccessoriesValue
                    ) + NonElectricalValue(newGroupedQuotesCompare[0])
                  )}`
                : newGroupedQuotesCompare[0]?.companyAlias === "godigit"
                ? "Included"
                : "Not Available"
              : newGroupedQuotesCompare[0]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",
          TypeReturn(type) === "bike"
            ? ""
            : addOnsAndOthers?.selectedAccesories?.includes(
                "External Bi-Fuel Kit CNG/LPG"
              )
            ? Number(newGroupedQuotesCompare[0]?.motorLpgCngKitValue) !== 0
              ? `₹ ${currencyFormater(
                  Number(newGroupedQuotesCompare[0]?.motorLpgCngKitValue) +
                    BiFuelValue(newGroupedQuotesCompare[0])
                )}`
              : newGroupedQuotesCompare[0]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",
        ]);
      }
      if (temp_data.journeyCategory === "GCV") {
        accessoriesArray.push([
          addOnsAndOthers?.selectedAccesories?.includes(
            "Electrical Accessories"
          )
            ? Number(
                newGroupedQuotesCompare[1]?.motorElectricAccessoriesValue
              ) !== 0
              ? `₹ ${currencyFormater(
                  Number(
                    newGroupedQuotesCompare[1]?.motorElectricAccessoriesValue
                  ) + ElectricalValue(newGroupedQuotesCompare[1])
                )}`
              : newGroupedQuotesCompare[1]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes(
            "Non-Electrical Accessories"
          )
            ? Number(
                newGroupedQuotesCompare[1]?.motorNonElectricAccessoriesValue
              ) !== 0
              ? `₹ ${currencyFormater(
                  Number(
                    newGroupedQuotesCompare[1]?.motorNonElectricAccessoriesValue
                  ) + NonElectricalValue(newGroupedQuotesCompare[1])
                )}`
              : newGroupedQuotesCompare[1]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes(
            "External Bi-Fuel Kit CNG/LPG"
          )
            ? Number(newGroupedQuotesCompare[1]?.motorLpgCngKitValue) !== 0
              ? `₹ ${currencyFormater(
                  Number(newGroupedQuotesCompare[1]?.motorLpgCngKitValue) +
                    BiFuelValue(newGroupedQuotesCompare[1])
                )}`
              : newGroupedQuotesCompare[1]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",

          addOnsAndOthers?.selectedAccesories?.includes("Trailer")
            ? Number(newGroupedQuotesCompare[1]?.trailerValue) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[1]?.trailerValue
                )}`
              : newGroupedQuotesCompare[1]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",
        ]);
      } else {
        accessoriesArray.push([
          addOnsAndOthers?.selectedAccesories?.includes(
            "Electrical Accessories"
          )
            ? newGroupedQuotesCompare[1]?.motorElectricAccessoriesValue
              ? Number(
                  newGroupedQuotesCompare[1]?.motorElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[1]?.motorElectricAccessoriesValue
                    ) + ElectricalValue(newGroupedQuotesCompare[1])
                  )}`
                : newGroupedQuotesCompare[1]?.companyAlias === "godigit"
                ? "Included"
                : "Not Available"
              : newGroupedQuotesCompare[1]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",
          addOnsAndOthers?.selectedAccesories?.includes(
            "Non-Electrical Accessories"
          )
            ? newGroupedQuotesCompare[1]?.motorNonElectricAccessoriesValue
              ? Number(
                  newGroupedQuotesCompare[1]?.motorNonElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[1]
                        ?.motorNonElectricAccessoriesValue
                    ) + NonElectricalValue(newGroupedQuotesCompare[1])
                  )}`
                : newGroupedQuotesCompare[1]?.companyAlias === "godigit"
                ? "Included"
                : "Not Available"
              : newGroupedQuotesCompare[1]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : "Not Selected",
          TypeReturn(type) === "bike"
            ? ""
            : addOnsAndOthers?.selectedAccesories?.includes(
                "External Bi-Fuel Kit CNG/LPG"
              )
            ? Number(newGroupedQuotesCompare[1]?.motorLpgCngKitValue) !== 0
              ? `₹ ${currencyFormater(
                  Number(newGroupedQuotesCompare[1]?.motorLpgCngKitValue) +
                    BiFuelValue(newGroupedQuotesCompare[1])
                )}`
              : newGroupedQuotesCompare[1]?.companyAlias === "godigit"
              ? "Included"
              : "Not Available"
            : newGroupedQuotesCompare[1]?.companyAlias === "godigit"
            ? "Included"
            : "Not Selected",
        ]);
      }
      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        if (temp_data.journeyCategory === "GCV") {
          accessoriesArray.push([
            addOnsAndOthers?.selectedAccesories?.includes(
              "Electrical Accessories"
            )
              ? Number(
                  newGroupedQuotesCompare[2]?.motorElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[2]?.motorElectricAccessoriesValue
                    ) + ElectricalValue(newGroupedQuotesCompare[2])
                  )}`
                : newGroupedQuotesCompare[2]?.companyAlias === "godigit"
                ? "Included"
                : "Not Available"
              : "Not Selected",
            addOnsAndOthers?.selectedAccesories?.includes(
              "Non-Electrical Accessories"
            )
              ? Number(
                  newGroupedQuotesCompare[2]?.motorNonElectricAccessoriesValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    Number(
                      newGroupedQuotesCompare[2]
                        ?.motorNonElectricAccessoriesValue
                    ) + NonElectricalValue(newGroupedQuotesCompare[2])
                  )}`
                : newGroupedQuotesCompare[2]?.companyAlias === "godigit"
                ? "Included"
                : "Not Available"
              : "Not Selected",

            addOnsAndOthers?.selectedAccesories?.includes(
              "External Bi-Fuel Kit CNG/LPG"
            )
              ? Number(newGroupedQuotesCompare[2]?.motorLpgCngKitValue) !== 0
                ? `₹ ${currencyFormater(
                    Number(newGroupedQuotesCompare[2]?.motorLpgCngKitValue) +
                      BiFuelValue(newGroupedQuotesCompare[2])
                  )}`
                : newGroupedQuotesCompare[2]?.companyAlias === "godigit"
                ? "Included"
                : "Not Available"
              : "Not Selected",

            addOnsAndOthers?.selectedAccesories?.includes("Trailer")
              ? Number(newGroupedQuotesCompare[2]?.trailerValue) !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[2]?.trailerValue
                  )}`
                : newGroupedQuotesCompare[2]?.companyAlias === "godigit"
                ? "Included"
                : "Not Available"
              : "Not Selected",
          ]);
        } else {
          accessoriesArray.push([
            addOnsAndOthers?.selectedAccesories?.includes(
              "Electrical Accessories"
            )
              ? newGroupedQuotesCompare[2]?.motorElectricAccessoriesValue
                ? Number(
                    newGroupedQuotesCompare[2]?.motorElectricAccessoriesValue
                  ) !== 0
                  ? `₹ ${currencyFormater(
                      Number(
                        newGroupedQuotesCompare[2]
                          ?.motorElectricAccessoriesValue
                      ) + ElectricalValue(newGroupedQuotesCompare[2])
                    )}`
                  : newGroupedQuotesCompare[2]?.companyAlias === "godigit"
                  ? "Included"
                  : "Not Available"
                : newGroupedQuotesCompare[2]?.companyAlias === "godigit"
                ? "Included"
                : "Not Available"
              : "Not Selected",
            addOnsAndOthers?.selectedAccesories?.includes(
              "Non-Electrical Accessories"
            )
              ? newGroupedQuotesCompare[2]?.motorNonElectricAccessoriesValue
                ? Number(
                    newGroupedQuotesCompare[2]?.motorNonElectricAccessoriesValue
                  ) !== 0
                  ? `₹ ${currencyFormater(
                      Number(
                        newGroupedQuotesCompare[2]
                          ?.motorNonElectricAccessoriesValue
                      ) + NonElectricalValue(newGroupedQuotesCompare[2])
                    )}`
                  : newGroupedQuotesCompare[2]?.companyAlias === "godigit"
                  ? "Included"
                  : "Not Available"
                : newGroupedQuotesCompare[2]?.companyAlias === "godigit"
                ? "Included"
                : "Not Available"
              : "Not Selected",
            TypeReturn(type) === "bike"
              ? ""
              : addOnsAndOthers?.selectedAccesories?.includes(
                  "External Bi-Fuel Kit CNG/LPG"
                )
              ? Number(newGroupedQuotesCompare[2]?.motorLpgCngKitValue) !== 0 &&
                newGroupedQuotesCompare[2]?.motorLpgCngKitValue !== undefined
                ? `₹ ${currencyFormater(
                    Number(newGroupedQuotesCompare[2]?.motorLpgCngKitValue) +
                      BiFuelValue(newGroupedQuotesCompare[2])
                  )}`
                : newGroupedQuotesCompare[2]?.companyAlias === "godigit"
                ? "Included"
                : "Not Available"
              : "Not Selected",
          ]);
        }
      }
      //additional array
      var additionalArray = [];
      additionalArray.push([
        temp_data?.odOnly ||
        shortTerm ||
        TypeReturn(type) === "bike" ||
        temp_data.journeyCategory === "GCV"
          ? ""
          : `PA Cover For Additional Paid Driver  ${
              addOnsAndOthers?.selectedAdditions?.includes(
                "PA cover for additional paid driver"
              )
                ? ` (₹ ${
                    addOnsAndOthers?.additionalPaidDriver === "₹ 2 lac"
                      ? "2 lac"
                      : "1 lac"
                  })`
                : ""
            } `,
        temp_data?.odOnly ||
        temp_data.journeyCategory === "GCV" ||
        BlockedSections(
          process.env.REACT_APP_BROKER,
          temp_data?.journeyCategory
        )?.includes("unnamed pa cover")
          ? ""
          : `Umnamed Passenger PA Cover  ${
              addOnsAndOthers?.selectedAdditions?.includes(
                "Unnamed Passenger PA Cover"
              )
                ? `(₹ ${
                    addOnsAndOthers?.unNamedCoverValue === "₹ 2 lac"
                      ? "2 lac"
                      : "1 lac"
                  })`
                : ""
            }   `,
        !temp_data?.odOnly
          ? `LL Paid Driver  ${
              addOnsAndOthers?.selectedAdditions?.includes("LL paid driver")
                ? "₹ 1 Lac"
                : ""
            } `
          : "",
        process.env.REACT_APP_BROKER !== "OLA" &&
          // !temp_data?.odOnly &&
          `Geographical Extension`,
      ]);
      additionalArray.push([
        temp_data?.odOnly ||
        shortTerm ||
        TypeReturn(type) === "bike" ||
        temp_data.journeyCategory === "GCV"
          ? ""
          : addOnsAndOthers?.selectedAdditions?.includes(
              "PA cover for additional paid driver"
            )
          ? newGroupedQuotesCompare[0]?.motorAdditionalPaidDriver &&
            Number(newGroupedQuotesCompare[0]?.motorAdditionalPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[0]?.companyAlias === "sbi" &&
                  addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) &&
                  !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? newGroupedQuotesCompare[0]?.motorAdditionalPaidDriver *
                      (TypeReturn(type) === "bike" ? 5 : 3)
                  : newGroupedQuotesCompare[0]?.motorAdditionalPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
        temp_data?.odOnly ||
        temp_data.journeyCategory === "GCV" ||
        BlockedSections(
          process.env.REACT_APP_BROKER,
          temp_data?.journeyCategory
        )?.includes("unnamed pa cover")
          ? ""
          : addOnsAndOthers?.selectedAdditions?.includes(
              "Unnamed Passenger PA Cover"
            )
          ? Number(newGroupedQuotesCompare[0]?.coverUnnamedPassengerValue) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[0]?.companyAlias === "sbi" &&
                  addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) &&
                  !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? newGroupedQuotesCompare[0]?.coverUnnamedPassengerValue *
                      (TypeReturn(type) === "bike" ? 5 : 3)
                  : newGroupedQuotesCompare[0]?.coverUnnamedPassengerValue
              )}`
            : "Not Available"
          : "Not Selected",
        !temp_data?.odOnly
          ? addOnsAndOthers?.selectedAdditions?.includes("LL paid driver")
            ? Number(newGroupedQuotesCompare[0]?.defaultPaidDriver) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[0]?.defaultPaidDriver
                )}`
              : "Not Available"
            : "Not Selected"
          : "",
        process.env.REACT_APP_BROKER !== "OLA" &&
        // !temp_data?.odOnly &&
        addOnsAndOthers?.selectedAdditions?.includes("Geographical Extension")
          ? Number(newGroupedQuotesCompare[0]?.geogExtensionODPremium) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[0]?.geogExtensionODPremium
              )}`
            : "Not Available"
          : "Not Selected",
      ]);
      additionalArray.push([
        temp_data?.odOnly ||
        shortTerm ||
        TypeReturn(type) === "bike" ||
        temp_data.journeyCategory === "GCV"
          ? ""
          : addOnsAndOthers?.selectedAdditions?.includes(
              "PA cover for additional paid driver"
            )
          ? newGroupedQuotesCompare[1]?.motorAdditionalPaidDriver &&
            Number(newGroupedQuotesCompare[1]?.motorAdditionalPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[1]?.companyAlias === "sbi" &&
                  addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) &&
                  !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? newGroupedQuotesCompare[1]?.motorAdditionalPaidDriver *
                      (TypeReturn(type) === "bike" ? 5 : 3)
                  : newGroupedQuotesCompare[1]?.motorAdditionalPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
        temp_data?.odOnly ||
        temp_data.journeyCategory === "GCV" ||
        BlockedSections(
          process.env.REACT_APP_BROKER,
          temp_data?.journeyCategory
        )?.includes("unnamed pa cover")
          ? ""
          : addOnsAndOthers?.selectedAdditions?.includes(
              "Unnamed Passenger PA Cover"
            )
          ? Number(newGroupedQuotesCompare[1]?.coverUnnamedPassengerValue) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[1]?.companyAlias === "sbi" &&
                  addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) &&
                  !_.isEmpty(addOnsAndOthers?.isTenure)
                  ? newGroupedQuotesCompare[1]?.coverUnnamedPassengerValue *
                      (TypeReturn(type) === "bike" ? 5 : 3)
                  : newGroupedQuotesCompare[1]?.coverUnnamedPassengerValue
              )}`
            : "Not Available"
          : "Not Selected",
        !temp_data?.odOnly
          ? addOnsAndOthers?.selectedAdditions?.includes("LL paid driver")
            ? Number(newGroupedQuotesCompare[1]?.defaultPaidDriver) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[1]?.defaultPaidDriver
                )}`
              : "Not Available"
            : "Not Selected"
          : "",
        process.env.REACT_APP_BROKER !== "OLA" &&
        // !temp_data?.odOnly &&
        addOnsAndOthers?.selectedAdditions?.includes("Geographical Extension")
          ? Number(newGroupedQuotesCompare[1]?.geogExtensionODPremium) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[1]?.geogExtensionODPremium
              )}`
            : "Not Available"
          : "Not Selected",
      ]);
      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        additionalArray.push([
          temp_data?.odOnly ||
          shortTerm ||
          TypeReturn(type) === "bike" ||
          temp_data.journeyCategory === "GCV"
            ? ""
            : addOnsAndOthers?.selectedAdditions?.includes(
                "PA cover for additional paid driver"
              )
            ? newGroupedQuotesCompare[2]?.motorAdditionalPaidDriver &&
              Number(newGroupedQuotesCompare[2]?.motorAdditionalPaidDriver) !==
                0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[2]?.motorAdditionalPaidDriver
                )}`
              : "Not Available"
            : "Not Selected",
          temp_data?.odOnly ||
          temp_data.journeyCategory === "GCV" ||
          BlockedSections(
            process.env.REACT_APP_BROKER,
            temp_data?.journeyCategory
          )?.includes("unnamed pa cover")
            ? ""
            : addOnsAndOthers?.selectedAdditions?.includes(
                "Unnamed Passenger PA Cover"
              )
            ? Number(newGroupedQuotesCompare[2]?.coverUnnamedPassengerValue) !==
              0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[2]?.companyAlias === "sbi" &&
                    addOnsAndOthers?.selectedCpa?.includes(
                      "Compulsory Personal Accident"
                    ) &&
                    !_.isEmpty(addOnsAndOthers?.isTenure)
                    ? newGroupedQuotesCompare[2]?.coverUnnamedPassengerValue *
                        (TypeReturn(type) === "bike" ? 5 : 3)
                    : newGroupedQuotesCompare[2]?.coverUnnamedPassengerValue
                )}`
              : "Not Available"
            : "Not Selected",
          !temp_data?.odOnly
            ? addOnsAndOthers?.selectedAdditions?.includes("LL paid driver")
              ? Number(newGroupedQuotesCompare[2]?.defaultPaidDriver) !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[2]?.defaultPaidDriver
                  )}`
                : "Not Available"
              : "Not Selected"
            : "",
          process.env.REACT_APP_BROKER !== "OLA" &&
          // !temp_data?.odOnly &&
          addOnsAndOthers?.selectedAdditions?.includes("Geographical Extension")
            ? Number(newGroupedQuotesCompare[2]?.geogExtensionODPremium) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[2]?.geogExtensionODPremium
                )}`
              : "Not Available"
            : "Not Selected",
        ]);
      }
      var additionalArrayGcv = [];
      additionalArrayGcv.push([
        !shortTerm &&
          !temp_data?.odOnly &&
          temp_data.journeyCategory === "GCV" &&
          `LL paid driver/conductor/cleaner`,
        !shortTerm &&
          !temp_data?.odOnly &&
          temp_data.journeyCategory === "GCV" &&
          `PA paid driver/conductor/cleaner`,
        process.env.REACT_APP_BROKER !== "OLA" &&
          // !temp_data?.odOnly &&
          `Geographical Extension`,
      ]);
      additionalArrayGcv.push([
        !shortTerm &&
        !temp_data?.odOnly &&
        temp_data.journeyCategory === "GCV" &&
        addOnsAndOthers?.selectedAdditions?.includes(
          "LL paid driver/conductor/cleaner"
        )
          ? Number(newGroupedQuotesCompare[0]?.defaultPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[0]?.defaultPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
        !shortTerm &&
        !temp_data?.odOnly &&
        temp_data.journeyCategory === "GCV" &&
        addOnsAndOthers?.selectedAdditions?.includes(
          "PA paid driver/conductor/cleaner"
        )
          ? Number(newGroupedQuotesCompare[0]?.motorAdditionalPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[0]?.motorAdditionalPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
        process.env.REACT_APP_BROKER !== "OLA" &&
        // !temp_data?.odOnly &&
        addOnsAndOthers?.selectedAdditions?.includes("Geographical Extension")
          ? Number(newGroupedQuotesCompare[0]?.geogExtensionODPremium) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[0]?.geogExtensionODPremium
              )}`
            : "Not Available"
          : "Not Selected",
      ]);
      additionalArrayGcv.push([
        !shortTerm &&
        !temp_data?.odOnly &&
        temp_data.journeyCategory === "GCV" &&
        addOnsAndOthers?.selectedAdditions?.includes(
          "LL paid driver/conductor/cleaner"
        )
          ? Number(newGroupedQuotesCompare[1]?.defaultPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[1]?.defaultPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
        !shortTerm &&
        !temp_data?.odOnly &&
        temp_data.journeyCategory === "GCV" &&
        addOnsAndOthers?.selectedAdditions?.includes(
          "PA paid driver/conductor/cleaner"
        )
          ? Number(newGroupedQuotesCompare[1]?.motorAdditionalPaidDriver) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[1]?.motorAdditionalPaidDriver
              )}`
            : "Not Available"
          : "Not Selected",
        process.env.REACT_APP_BROKER !== "OLA" &&
        // !temp_data?.odOnly &&
        addOnsAndOthers?.selectedAdditions?.includes("Geographical Extension")
          ? Number(newGroupedQuotesCompare[1]?.geogExtensionODPremium) !== 0
            ? `₹ ${currencyFormater(
                newGroupedQuotesCompare[1]?.geogExtensionODPremium
              )}`
            : "Not Available"
          : "Not Selected",
      ]);
      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        additionalArrayGcv.push([
          !shortTerm &&
          !temp_data?.odOnly &&
          temp_data.journeyCategory === "GCV" &&
          addOnsAndOthers?.selectedAdditions?.includes(
            "LL paid driver/conductor/cleaner"
          )
            ? Number(newGroupedQuotesCompare[2]?.defaultPaidDriver) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[2]?.defaultPaidDriver
                )}`
              : "Not Available"
            : "Not Selected",
          !shortTerm &&
          !temp_data?.odOnly &&
          temp_data.journeyCategory === "GCV" &&
          addOnsAndOthers?.selectedAdditions?.includes(
            "PA paid driver/conductor/cleaner"
          )
            ? Number(newGroupedQuotesCompare[2]?.motorAdditionalPaidDriver) !==
              0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[2]?.motorAdditionalPaidDriver
                )}`
              : "Not Available"
            : "Not Selected",
          process.env.REACT_APP_BROKER !== "OLA" &&
          // !temp_data?.odOnly &&
          addOnsAndOthers?.selectedAdditions?.includes("Geographical Extension")
            ? Number(newGroupedQuotesCompare[2]?.geogExtensionODPremium) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[2]?.geogExtensionODPremium
                )}`
              : "Not Available"
            : "Not Selected",
        ]);
      }
      //discount array
      var discountArray = [];
      discountArray.push(
        // eslint-disable-next-line no-sparse-arrays
        _.compact([
          temp_data.journeyCategory !== "GCV"
            ? `Vehicle is fitted with ARAI  ${
                addOnsAndOthers?.selectedDiscount?.includes(
                  "Is the vehicle fitted with ARAI approved anti-theft device?"
                )
                  ? ""
                  : ""
              } `
            : "",
          TypeReturn(type) !== "cv"
            ? `Voluntary Deductible ${
                addOnsAndOthers?.selectedDiscount?.includes(
                  "Voluntary Discounts"
                )
                  ? ""
                  : ""
              } `
            : "",
          !temp_data?.odOnly &&
            temp_data.journeyCategory === "GCV" &&
            `Vehicle Limited to Own Premises`,
          !temp_data?.odOnly && `TPPD Cover`,
          ,
        ])
      );
      discountArray.push(
        _.compact([
          temp_data.journeyCategory !== "GCV"
            ? addOnsAndOthers?.selectedDiscount?.includes(
                "Is the vehicle fitted with ARAI approved anti-theft device?"
              )
              ? Number(
                  newGroupedQuotesCompare[0]?.antitheftDiscount
                    ? newGroupedQuotesCompare[0]?.antitheftDiscount
                    : 0
                ) !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[0]?.antitheftDiscount
                  )}`
                : "Not Available"
              : "Not Selected"
            : "",
          TypeReturn(type) !== "cv"
            ? addOnsAndOthers?.selectedDiscount?.includes("Voluntary Discounts")
              ? addOnsAndOthers?.volDiscountValue !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[0]?.voluntaryExcess
                  )}` === "₹ 0"
                  ? "Not Available"
                  : `₹ ${currencyFormater(
                      newGroupedQuotesCompare[0]?.voluntaryExcess
                    )}`
                : "Not Available"
              : "Not Selected"
            : "",
          !temp_data?.odOnly && temp_data.journeyCategory === "GCV"
            ? addOnsAndOthers?.selectedDiscount?.includes(
                "Vehicle Limited to Own Premises"
              )
              ? Number(
                  newGroupedQuotesCompare[0]?.coverUnnamedPassengerValue
                ) !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[0]?.coverUnnamedPassengerValue
                  )}` === "₹ 0"
                  ? "Not Available"
                  : `₹ ${currencyFormater(
                      newGroupedQuotesCompare[0]?.coverUnnamedPassengerValue
                    )}`
                : "Not Available"
              : temp_data?.odOnly
              ? ""
              : "Not Selected"
            : "",
          addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")
            ? Number(newGroupedQuotesCompare[0]?.tppdDiscount) !== 0
              ? `₹ ${currencyFormater(
                  newGroupedQuotesCompare[0]?.tppdDiscount
                )}` === "₹ 0"
                ? "Not Available"
                : `₹ ${currencyFormater(
                    newGroupedQuotesCompare[0]?.tppdDiscount
                  )}`
              : "Not Available"
            : temp_data?.odOnly
            ? ""
            : "Not Selected",
        ])
      );
      if (Number(newGroupedQuotesCompare[1]?.idv) > 0) {
        discountArray.push(
          _.compact([
            temp_data.journeyCategory !== "GCV"
              ? addOnsAndOthers?.selectedDiscount?.includes(
                  "Is the vehicle fitted with ARAI approved anti-theft device?"
                )
                ? Number(
                    newGroupedQuotesCompare[1]?.antitheftDiscount
                      ? newGroupedQuotesCompare[1]?.antitheftDiscount
                      : 0
                  ) !== 0
                  ? `₹ ${currencyFormater(
                      newGroupedQuotesCompare[1]?.antitheftDiscount
                    )}`
                  : "Not Available"
                : "Not Selected"
              : "",
            TypeReturn(type) !== "cv"
              ? addOnsAndOthers?.selectedDiscount?.includes(
                  "Voluntary Discounts"
                )
                ? addOnsAndOthers?.volDiscountValue !== 0
                  ? `₹ ${currencyFormater(
                      newGroupedQuotesCompare[1]?.voluntaryExcess
                    )}` === "₹ 0"
                    ? "Not Available"
                    : `₹ ${currencyFormater(
                        newGroupedQuotesCompare[1]?.voluntaryExcess
                      )}`
                  : "Not Available"
                : "Not Selected"
              : "",
            !temp_data?.odOnly && temp_data.journeyCategory === "GCV"
              ? addOnsAndOthers?.selectedDiscount?.includes(
                  "Vehicle Limited to Own Premises"
                )
                ? Number(
                    newGroupedQuotesCompare[1]?.coverUnnamedPassengerValue
                  ) !== 0
                  ? `₹ ${currencyFormater(
                      newGroupedQuotesCompare[1]?.coverUnnamedPassengerValue
                    )}` === "₹ 0"
                    ? "Not Available"
                    : `₹ ${currencyFormater(
                        newGroupedQuotesCompare[1]?.coverUnnamedPassengerValue
                      )}`
                  : "Not Available"
                : temp_data?.odOnly
                ? ""
                : "Not Selected"
              : "",
            addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")
              ? Number(newGroupedQuotesCompare[1]?.tppdDiscount) !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[1]?.tppdDiscount
                  )}` === "₹ 0"
                  ? "Not Available"
                  : `₹ ${currencyFormater(
                      newGroupedQuotesCompare[1]?.tppdDiscount
                    )}`
                : "Not Available"
              : temp_data?.odOnly
              ? ""
              : "Not Selected",
          ])
        );
      }
      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        discountArray.push(
          _.compact([
            temp_data.journeyCategory !== "GCV"
              ? addOnsAndOthers?.selectedDiscount?.includes(
                  "Is the vehicle fitted with ARAI approved anti-theft device?"
                )
                ? Number(
                    newGroupedQuotesCompare[2]?.antitheftDiscount
                      ? newGroupedQuotesCompare[2]?.antitheftDiscount
                      : 0
                  ) !== 0
                  ? `₹ ${currencyFormater(
                      newGroupedQuotesCompare[2]?.antitheftDiscount
                        ? newGroupedQuotesCompare[2]?.antitheftDiscount
                        : 0
                    )}`
                  : "Not Available"
                : "Not Selected"
              : "",
            TypeReturn(type) !== "cv"
              ? addOnsAndOthers?.selectedDiscount?.includes(
                  "Voluntary Discounts"
                )
                ? addOnsAndOthers?.volDiscountValue !== 0
                  ? `₹ ${currencyFormater(
                      newGroupedQuotesCompare[2]?.voluntaryExcess
                    )}` === "₹ 0"
                    ? "Not Available"
                    : `₹ ${currencyFormater(
                        newGroupedQuotesCompare[2]?.voluntaryExcess
                      )}`
                  : "Not Available"
                : "Not Selected"
              : "",
            temp_data.journeyCategory === "GCV"
              ? addOnsAndOthers?.selectedDiscount?.includes(
                  "Vehicle Limited to Own Premises"
                )
                ? Number(
                    newGroupedQuotesCompare[2]?.coverUnnamedPassengerValue
                  ) !== 0
                  ? `₹ ${currencyFormater(
                      newGroupedQuotesCompare[2]?.coverUnnamedPassengerValue
                    )}` === "₹ 0"
                    ? "Not Available"
                    : `₹ ${currencyFormater(
                        newGroupedQuotesCompare[2]?.coverUnnamedPassengerValue
                      )}`
                  : "Not Available"
                : temp_data?.odOnly
                ? ""
                : "Not Selected"
              : "",
            addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")
              ? Number(newGroupedQuotesCompare[2]?.tppdDiscount) !== 0
                ? `₹ ${currencyFormater(
                    newGroupedQuotesCompare[2]?.tppdDiscount
                  )}` === "₹ 0"
                  ? "Not Available"
                  : `₹ ${currencyFormater(
                      newGroupedQuotesCompare[2]?.tppdDiscount
                    )}`
                : "Not Available"
              : temp_data?.odOnly
              ? ""
              : "Not Selected",
          ])
        );
      }
      var AddonArray = [];
      if (TypeReturn(type) === "car") {
        AddonArray.push(
          [
            "Compulsory Personal Accident",
            "Zero Depreciation",
            "Road Side Assistance",
            "Key Replacement",
            "Engine Protector",
            "NCB Protection",
            "Consumable",
            "Tyre Secure",
            "Return To Invoice",
            "Loss of Personal Belongings",
            "Emergency Medical Expenses",
          ].filter((x) =>
            temp_data?.odOnly ||
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
              ? x !== "Compulsory Personal Accident"
              : x
          )
        );
      } else if (TypeReturn(type) === "bike") {
        AddonArray.push(
          [
            "Compulsory Personal Accident",
            "Zero Depreciation",
            "Road Side Assistance",
            "Engine Protector",
            "Consumable",
            "Return To Invoice",
            "Emergency Medical Expenses",
          ].filter((x) =>
            temp_data?.odOnly ||
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
              ? x !== "Compulsory Personal Accident"
              : x
          )
        );
      } else if (TypeReturn(type) === "cv") {
        if (temp_data.journeyCategory === "GCV") {
          AddonArray.push(
            [
              "Compulsory Personal Accident",
              "Zero Depreciation",
              "Road Side Assistance",
              "IMT - 23",
              // "Emergency Medical Expenses",
            ].filter((x) =>
              temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
                ? x !== "Compulsory Personal Accident"
                : x
            )
          );
        } else {
          AddonArray.push(
            [
              "Compulsory Personal Accident",
              "Zero Depreciation",
              "Road Side Assistance",
              "IMT - 23",
              "Consumable",
              // "Emergency Medical Expenses",
            ].filter((x) =>
              temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
                ? x !== "Compulsory Personal Accident"
                : x
            )
          );
        }
      }
      if (TypeReturn(type) === "car") {
        if (
          temp_data?.odOnly ||
          temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
        ) {
          AddonArray.push([
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "emergencyMedicalExpenses",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
          ]);
        } else {
          AddonArray.push([
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            )
              ? `₹ ${currencyFormater(
                  parseInt(
                    !_.isEmpty(addOnsAndOthers?.isTenure)
                      ? newGroupedQuotesCompare[0]?.multiYearCpa
                      : newGroupedQuotesCompare[0]?.compulsoryPaOwnDriver
                  )
                )}`
              : "Optional",
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "emergencyMedicalExpenses",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
          ]);
        }
      } else if (TypeReturn(type) === "bike") {
        if (
          temp_data?.odOnly ||
          temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
        ) {
          AddonArray.push([
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "emergencyMedicalExpenses",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
          ]);
        } else {
          if (
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
          ) {
            AddonArray.push([
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "emergencyMedicalExpenses",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
            ]);
          } else {
            AddonArray.push([
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? `₹ ${currencyFormater(
                    parseInt(
                      !_.isEmpty(addOnsAndOthers?.isTenure)
                        ? newGroupedQuotesCompare[0]?.multiYearCpa
                        : newGroupedQuotesCompare[0]?.compulsoryPaOwnDriver
                    )
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "emergencyMedicalExpenses",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
            ]);
          }
        }
      } else if (TypeReturn(type) === "cv") {
        if (temp_data.journeyCategory === "GCV") {
          AddonArray.push([
            temp_data?.odOnly
              ? ""
              : addOnsAndOthers?.selectedCpa?.includes(
                  "Compulsory Personal Accident"
                )
              ? `₹ ${currencyFormater(
                  parseInt(newGroupedQuotesCompare[0]?.compulsoryPaOwnDriver)
                )}`
              : "Optional",
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "imt23",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[0],
              addOnsAndOthers
            )}`,
          ]);
        } else {
          if (
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
          ) {
            AddonArray.push([
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "imt23",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
            ]);
          } else {
            AddonArray.push([
              temp_data?.odOnly
                ? ""
                : addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  )
                ? `₹ ${currencyFormater(
                    parseInt(newGroupedQuotesCompare[0]?.compulsoryPaOwnDriver)
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "imt23",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[0]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[0],
                addOnsAndOthers
              )}`,
            ]);
          }
        }
      }
      if (TypeReturn(type) === "car") {
        if (
          temp_data?.odOnly ||
          temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
        ) {
          AddonArray.push([
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "emergencyMedicalExpenses",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
          ]);
        } else {
          AddonArray.push([
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            )
              ? `₹ ${currencyFormater(
                  parseInt(
                    !_.isEmpty(addOnsAndOthers?.isTenure)
                      ? newGroupedQuotesCompare[1]?.multiYearCpa
                      : newGroupedQuotesCompare[1]?.compulsoryPaOwnDriver
                  )
                )}`
              : "Optional",
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "emergencyMedicalExpenses",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
          ]);
        }
      } else if (TypeReturn(type) === "bike") {
        if (
          temp_data?.odOnly ||
          temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
        ) {
          AddonArray.push([
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "emergencyMedicalExpenses",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
          ]);
        } else {
          AddonArray.push([
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            )
              ? `₹ ${currencyFormater(
                  parseInt(
                    !_.isEmpty(addOnsAndOthers?.isTenure)
                      ? newGroupedQuotesCompare[1]?.multiYearCpa
                      : newGroupedQuotesCompare[1]?.compulsoryPaOwnDriver
                  )
                )}`
              : "Optional",
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "emergencyMedicalExpenses",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
          ]);
        }
      } else if (TypeReturn(type) === "cv") {
        if (temp_data.journeyCategory === "GCV") {
          AddonArray.push([
            temp_data?.odOnly
              ? ""
              : addOnsAndOthers?.selectedCpa?.includes(
                  "Compulsory Personal Accident"
                )
              ? `₹ ${currencyFormater(
                  parseInt(newGroupedQuotesCompare[1]?.compulsoryPaOwnDriver)
                )}`
              : "Optional",
            `${GetAddonValueNoBadge(
              "zeroDepreciation",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "roadSideAssistance",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "imt23",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "keyReplace",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "engineProtector",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "ncbProtection",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "consumables",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "tyreSecure",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "returnToInvoice",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
            `${GetAddonValueNoBadge(
              "lopb",
              newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
              newGroupedQuotesCompare[1],
              addOnsAndOthers
            )}`,
          ]);
        } else {
          if (
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
          ) {
            AddonArray.push([
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "imt23",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
            ]);
          } else {
            AddonArray.push([
              temp_data?.odOnly
                ? ""
                : addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  )
                ? `₹ ${currencyFormater(
                    parseInt(newGroupedQuotesCompare[1]?.compulsoryPaOwnDriver)
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "imt23",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[1]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[1],
                addOnsAndOthers
              )}`,
            ]);
          }
        }
      }
      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        if (TypeReturn(type) === "car") {
          if (
            temp_data?.odOnly ||
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
          ) {
            AddonArray.push([
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "emergencyMedicalExpenses",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
            ]);
          } else {
            AddonArray.push([
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? `₹ ${currencyFormater(
                    parseInt(
                      !_.isEmpty(addOnsAndOthers?.isTenure)
                        ? newGroupedQuotesCompare[2]?.multiYearCpa
                        : newGroupedQuotesCompare[2]?.compulsoryPaOwnDriver
                    )
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "emergencyMedicalExpenses",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
            ]);
          }
        } else if (TypeReturn(type) === "bike") {
          if (
            temp_data?.odOnly ||
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
          ) {
            AddonArray.push([
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "emergencyMedicalExpenses",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
            ]);
          } else {
            AddonArray.push([
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? `₹ ${currencyFormater(
                    parseInt(
                      !_.isEmpty(addOnsAndOthers?.isTenure)
                        ? newGroupedQuotesCompare[2]?.multiYearCpa
                        : newGroupedQuotesCompare[2]?.compulsoryPaOwnDriver
                    )
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "emergencyMedicalExpenses",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
            ]);
          }
        } else if (TypeReturn(type) === "cv") {
          if (temp_data.journeyCategory === "GCV") {
            AddonArray.push([
              temp_data?.odOnly
                ? ""
                : addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  )
                ? `₹ ${currencyFormater(
                    parseInt(newGroupedQuotesCompare[2]?.compulsoryPaOwnDriver)
                  )}`
                : "Optional",
              `${GetAddonValueNoBadge(
                "zeroDepreciation",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "roadSideAssistance",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "imt23",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "keyReplace",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "engineProtector",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "ncbProtection",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "consumables",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "tyreSecure",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "returnToInvoice",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
              `${GetAddonValueNoBadge(
                "lopb",
                newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                newGroupedQuotesCompare[2],
                addOnsAndOthers
              )}`,
            ]);
          } else {
            if (
              temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
            ) {
              AddonArray.push([
                `${GetAddonValueNoBadge(
                  "zeroDepreciation",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "roadSideAssistance",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "imt23",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "keyReplace",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "engineProtector",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "ncbProtection",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "consumables",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "tyreSecure",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "returnToInvoice",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "lopb",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
              ]);
            } else {
              AddonArray.push([
                temp_data?.odOnly
                  ? ""
                  : addOnsAndOthers?.selectedCpa?.includes(
                      "Compulsory Personal Accident"
                    )
                  ? `₹ ${currencyFormater(
                      parseInt(
                        newGroupedQuotesCompare[2]?.compulsoryPaOwnDriver
                      )
                    )}`
                  : "Optional",
                `${GetAddonValueNoBadge(
                  "zeroDepreciation",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers,
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "roadSideAssistance",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "imt23",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "keyReplace",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "engineProtector",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "ncbProtection",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "consumables",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "tyreSecure",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "returnToInvoice",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
                `${GetAddonValueNoBadge(
                  "lopb",
                  newGroupedQuotesCompare[2]?.addonDiscountPercentage1,
                  newGroupedQuotesCompare[2],
                  addOnsAndOthers
                )}`,
              ]);
            }
          }
        }
      }
      var totalPremiumArray = [];
      totalPremiumArray.push(
        `₹ ${currencyFormater(parseInt(newGroupedQuotesCompare[0]?.gst1))}`
      );
      totalPremiumArray.push(
        `₹ ${currencyFormater(parseInt(newGroupedQuotesCompare[1]?.gst1))}`
      );

      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        totalPremiumArray.push(
          `₹ ${currencyFormater(parseInt(newGroupedQuotesCompare[2]?.gst1))}`
        );
      }
      var insurerDetailsArray = [];
      insurerDetailsArray.push({
        logo: newGroupedQuotesCompare[0]?.companyLogo,
        idv: `IDV: ${`₹ ${currencyFormater(
          parseInt(newGroupedQuotesCompare[0]?.idv)
        )}`}`,
        buy_link: `${window.location.protocol}//${
          window.location.host
        }${window.location.pathname.replace(
          /compare-quote/g,
          "quotes"
        )}?enquiry_id=${enquiry_id}`,
        expiryDate: moment().format("DD-MM-YYYY"),
        buy_link_text: `Buy Now ₹ ${currencyFormater(
          parseInt(newGroupedQuotesCompare[0]?.finalPremium1)
        )}`,
        productId: newGroupedQuotesCompare[0]?.policyId,
        selectedType: selectedTab === "tab2" ? Encrypt(selectedTab) : "",
        selectedTerm:
          shortTerm && selectedTab !== "tab2" ? Encrypt(shortTerm) : "",
      });
      insurerDetailsArray.push({
        logo: newGroupedQuotesCompare[1]?.companyLogo,
        idv: `IDV: ${`₹ ${currencyFormater(
          parseInt(newGroupedQuotesCompare[1]?.idv)
        )}`}`,
        buy_link: `${window.location.protocol}//${
          window.location.host
        }${window.location.pathname.replace(
          /compare-quote/g,
          "quotes"
        )}?enquiry_id=${enquiry_id}`,
        expiryDate: moment().format("DD-MM-YYYY"),
        buy_link_text: `Buy Now ₹ ${currencyFormater(
          parseInt(newGroupedQuotesCompare[1]?.finalPremium1)
        )}`,
        productId: newGroupedQuotesCompare[1]?.policyId,
        selectedType: selectedTab === "tab2" ? Encrypt(selectedTab) : "",
        selectedTerm:
          shortTerm && selectedTab !== "tab2" ? Encrypt(shortTerm) : "",
      });
      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        insurerDetailsArray.push({
          logo: newGroupedQuotesCompare[2]?.companyLogo,
          idv: `IDV: ${`₹ ${currencyFormater(
            parseInt(newGroupedQuotesCompare[2]?.idv)
          )}`}`,
          buy_link: `${window.location.protocol}//${
            window.location.host
          }${window.location.pathname.replace(
            /compare-quote/g,
            "quotes"
          )}?enquiry_id=${enquiry_id}`,
          expiryDate: moment().format("DD-MM-YYYY"),
          buy_link_text: `Buy Now ₹ ${currencyFormater(
            parseInt(newGroupedQuotesCompare[2]?.finalPremium1)
          )}`,
          productId: newGroupedQuotesCompare[2]?.policyId,
          selectedType: selectedTab === "tab2" ? Encrypt(selectedTab) : "",
          selectedTerm:
            shortTerm && selectedTab !== "tab2" ? Encrypt(shortTerm) : "",
        });
      }
      var uspList = [];
      uspList.push([
        newGroupedQuotesCompare[0]?.usp && newGroupedQuotesCompare[0]?.usp[0]
          ? newGroupedQuotesCompare[0]?.usp[0]?.usp_desc
          : "x",
        newGroupedQuotesCompare[0]?.usp && newGroupedQuotesCompare[0]?.usp[1]
          ? newGroupedQuotesCompare[0]?.usp[1]?.usp_desc
          : "x",
        newGroupedQuotesCompare[0]?.usp && newGroupedQuotesCompare[0]?.usp[2]
          ? newGroupedQuotesCompare[0]?.usp[2]?.usp_desc
          : "x",
      ]);
      uspList.push([
        newGroupedQuotesCompare[1]?.usp && newGroupedQuotesCompare[1]?.usp[0]
          ? newGroupedQuotesCompare[1]?.usp[0]?.usp_desc
          : "x",

        newGroupedQuotesCompare[1]?.usp && newGroupedQuotesCompare[1]?.usp[1]
          ? newGroupedQuotesCompare[1]?.usp[1]?.usp_desc
          : "x",
        newGroupedQuotesCompare[1]?.usp && newGroupedQuotesCompare[1]?.usp[2]
          ? newGroupedQuotesCompare[1]?.usp[2]?.usp_desc
          : "x",
      ]);

      if (Number(newGroupedQuotesCompare[2]?.idv) > 0) {
        uspList.push([
          newGroupedQuotesCompare[2]?.usp && newGroupedQuotesCompare[2]?.usp[0]
            ? newGroupedQuotesCompare[2]?.usp[0]?.usp_desc
            : "x",

          newGroupedQuotesCompare[2]?.usp && newGroupedQuotesCompare[2]?.usp[1]
            ? newGroupedQuotesCompare[2]?.usp[1]?.usp_desc
            : "x",

          newGroupedQuotesCompare[2]?.usp && newGroupedQuotesCompare[2]?.usp[2]
            ? newGroupedQuotesCompare[2]?.usp[2]?.usp_desc
            : "x",
        ]);
      }
      // 3 and 5 years cpa condition
      const index = AddonArray[0][0].indexOf("Compulsory Personal Accident");
      AddonArray[0][index] = `Compulsory Personal Accident ${
        !_.isEmpty(addOnsAndOthers?.isTenure) && TypeReturn(type) === "car"
          ? "(3 Years)"
          : !_.isEmpty(addOnsAndOthers?.isTenure) && TypeReturn(type) === "bike"
          ? "(5 Years)"
          : ""
      }`;
      var data = {
        broker_logo: getLogoUrl(),
        broker_name:
          process.env?.REACT_APP_BROKER === "TATA"
            ? "TMIBASL"
            : process.env?.REACT_APP_TITLE,
        broker_website:
          `http://${window.location.hostname}` || getBrokerWebsite(),
        broker_phone: theme_conf?.broker_config?.phone || ContactFn(),
        quote_color_code: (Theme?.QuoteCard?.color || "#bdd400").slice(1),
        broker_email: theme_conf?.broker_config?.email || brokerEmailFunction(),
        policy_type:
          newGroupedQuotesCompare[0]?.productName === "Comprehensive" &&
          temp_data?.newCar &&
          TypeReturn(type) !== "cv"
            ? `Bundled(1 yr OD + ${TypeReturn(type) === "car" ? 3 : 5} yr TP)`
            : newGroupedQuotesCompare[0]?.productName,
        policy_type_logo: getPolicyType(type),
        vehicle_reg_no_title: "Vehicle Reg No",
        vehicle_reg_no: temp_data?.regNo || temp_data?.rtoNumber,
        vehicle_model_title: "Make and Model",
        vehicle_model: `${newGroupedQuotesCompare[0]?.mmvDetail?.manfName?.replace(
          /&/,
          "and"
        )}-${newGroupedQuotesCompare[0]?.mmvDetail?.modelName}-${
          newGroupedQuotesCompare[0]?.mmvDetail?.versionName
        }-${newGroupedQuotesCompare[0]?.mmvDetail?.cubicCapacity}cc `,
        vehicle_reg_date_title: "Reg Date / Mfg Year",
        vehicle_reg_date: newGroupedQuotesCompare[0]?.vehicleRegisterDate,
        IRDIANumber: theme_conf?.broker_config?.irdanumber || getIRDAI(),
        vehicle_insurance_type_title: "Policy Type / Plan Type",
        vehicle_insurance_type: `${
          newGroupedQuotesCompare[0]?.policyType === "Comprehensive" &&
          temp_data?.newCar &&
          TypeReturn(type) !== "cv"
            ? "Bundled Policy"
            : newGroupedQuotesCompare[0]?.policyType
        } / ${
          TypeReturn(type) === "cv"
            ? newGroupedQuotesCompare[0]?.premiumTypeCode === "short_term_3" ||
              newGroupedQuotesCompare[0]?.premiumTypeCode ===
                "short_term_3_breakin"
              ? "3 Months"
              : newGroupedQuotesCompare[0]?.premiumTypeCode ===
                  "short_term_6" ||
                newGroupedQuotesCompare[0]?.premiumTypeCode ===
                  "short_term_6_breakin"
              ? "6 Months"
              : newGroupedQuotesCompare[0]?.policyType === "Comprehensive"
              ? `(1yr OD + 1yr TP)`
              : "Annual"
            : newGroupedQuotesCompare[0]?.policyType === "Comprehensive"
            ? `(1yr OD + ${
                temp_data?.newCar
                  ? TypeReturn(type) === "car"
                    ? "3"
                    : "5"
                  : "1"
              }yr TP)`
            : temp_data?.newCar
            ? `${TypeReturn(type) === "car" ? "3" : "5"} years`
            : "Annual"
        }`,
        quote_id_title: "Quote ID: ",
        quote_id: enquiry_id,
        quote_date_title: "Quote Date: ",
        quote_date: moment().format("DD-MM-YYYY"),
        customer_name_title: "Customer Name :",
        customer_name:
          temp_data?.firstName && temp_data?.lastName
            ? temp_data?.firstName + " " + temp_data?.lastName
            : temp_data?.firstName || temp_data?.lastName
            ? temp_data?.firstName || temp_data?.lastName
            : "",
        customer_phone_title: "Customer Number :",
        customer_phone: temp_data?.mobileNo || " ",
        customer_email_title: "Customer Email :",
        customer_email: temp_data?.emailId || " ",
        ...(!_.isEmpty(temp_data?.agentDetails) &&
          !_.isEmpty(
            temp_data?.agentDetails.filter((item) => item.sellerType === "P")
          ) && {
            pos_name_title:
              process.env.REACT_APP_BROKER === "EPOCH"
                ? "Policylo Team"
                : "POS Name:",
            pos_name: temp_data?.agentDetails.filter(
              (item) => item.sellerType === "P"
            )[0]?.agentName,
            pos_phone_title:
              process.env.REACT_APP_BROKER === "EPOCH" ? "" : "POS Phone:",
            pos_phone: temp_data?.agentDetails.filter(
              (item) => item.sellerType === "P"
            )[0]?.agentMobile,
          }),
        ic_usp_title: "Insurer USP",
        ic_usp: uspList,
        premium_breakup_title: "Premium Breakup",
        premium_breakup: premiumBreakupArray,
        accessories_title: "Accessories",
        accessories: accessoriesArray,
        ...{
          additonal_cover_title: "Additional Covers",
          additonal_cover:
            temp_data.journeyCategory === "GCV"
              ? additionalArrayGcv
              : additionalArray?.map(
                  (item) => !_.isEmpty(item) && _.compact(item)
                ),
        },
        discount_title: " Discounts/Deductibles ",
        discounts:
          temp_data?.odOnly ||
          temp_data.journeyCategory === "GCV" ||
          BlockedSections(
            process.env.REACT_APP_BROKER,
            temp_data?.journeyCategory
          )?.includes("unnamed pa cover")
            ? []
            : discountArray,
        addons_title: "Addons",
        addons: AddonArray,
        premium_breakup_total_title: "GST",
        premium_breakup_total: totalPremiumArray,
        insurance_details: insurerDetailsArray,
        color_scheme: Theme?.links?.color,
        selectedGvw: temp_data?.corporateVehiclesQuoteRequest?.selectedGvw,
      };
    }
    dispatch(setComparePdfDatad(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newGroupedQuotesCompare]);
  const lessThan767 = useMediaPredicate("(max-width: 767px)");
  return (
    <TopDiv>
      <div className="compare-products-list">
        <ul className="cd-products-columns">
          {(lessThan767
            ? newGroupedQuotesCompare.slice(0, 2)
            : newGroupedQuotesCompare
          )?.map((item, index) => (
            <Product
              quote={item}
              index={index}
              length={newGroupedQuotesCompare?.length}
              type={type}
              setPrevPopup={setPrevPopup}
              prevPopup={prevPopup}
              setSelectedId={setSelectedId}
              setSelectedCompanyName={setSelectedCompanyName}
              validQuote={validQuote}
              popupCard={false}
              setSelectedIcId={setSelectedIcId}
              setSelectedCompanyAlias={setSelectedCompanyAlias}
              setApplicableAddonsLits={setApplicableAddonsLits}
              scrollPosition={scrollPosition}
            />
          ))}
        </ul>
      </div>
    </TopDiv>
  );
}

export default CompareProductsList;

// PropTypes
CompareProductsList.propTypes = {
  compareQuotes: PropTypes.array,
  type: PropTypes.string,
  setPrevPopup: PropTypes.func,
  prevPopup: PropTypes.bool,
  setSelectedId: PropTypes.func,
  setSelectedCompanyName: PropTypes.func,
  quoteComprehesive: PropTypes.array,
  setSelectedIcId: PropTypes.func,
  setSelectedCompanyAlias: PropTypes.func,
  setApplicableAddonsLits: PropTypes.func,
  scrollPosition: PropTypes.number,
  zdlp: PropTypes.string,
  setClaimList: PropTypes.func,
  zdlp_gdd: PropTypes.string,
  setClaimList_gdd: PropTypes.func,
};

// DefaultTypes
CompareProductsList.defaultProps = {
  compareQuotes: [],
  type: "",
  setPrevPopup: () => {},
  prevPopup: false,
  setSelectedId: () => {},
  setSelectedCompanyName: () => {},
  quoteComprehesive: [],
  setSelectedIcId: () => {},
  setSelectedCompanyAlias: () => {},
  setApplicableAddonsLits: () => {},
  scrollPosition: 0,
  zdlp: "",
  setClaimList: () => {},
  zdlp_gdd: "",
  setClaimList_gdd: () => {},
};
