export const redirection = (type, broker, url, token, enquiry_id, renewal) => {
  switch (type) {
    case "car":
      if (broker === "ABIBL") {
        //uat
        if (url === "https://apiabibl-carbike.fynity.in/api") {
          return `https://abibl-car-bike.fynity.in/car/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //preprod
        if (url === "https://apiabibl-preprod-carbike.fynity.in/api") {
          return `https://abibl-preprod-car-bike.fynity.in/car/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //prod
        if (url === "https://apiprod-abibl.fynity.in/api") {
          return `https://carprod-abibl.fynity.in/car/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
      if (broker === "ACE") {
        //uat
        if (url === "https://apicar-aceinsurance.fynity.in/api") {
          return `https://carbike-uat-aceinsurance.fynity.in/car/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //preprod
        if (url === "https://uatmotorapis.aceinsurance.com/api") {
          return `https://uatcar.aceinsurance.com/car/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //prod
        if (url === "https://motorapis.aceinsurance.com/api") {
          return `https://car.aceinsurance.com/car/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
      if (broker === "EPOCH") {
        //uat
        if (url === "https://uatapimotor.policylo.com/api") {
          return `https://uatcar.policylo.com/car/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
      if (broker === "RB") {
        //uat
        if (url === "https://apiuatmotor.rbstaging.in/api") {
          return `https://uatcar.rbstaging.in/car/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //preprod
        if (url === "https://apipreprodmotor.rbstaging.in/api") {
          return `https://prepodcar.rbstaging.in/car/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
    case "bike":
      if (broker === "ABIBL") {
        //uat
        if (url === "https://apiabibl-carbike.fynity.in/api") {
          return `https://abibl-car-bike.fynity.in/bike/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //preprod
        if (url === "https://apiabibl-preprod-carbike.fynity.in/api") {
          return `https://abibl-preprod-car-bike.fynity.in/bike/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //prod
        if (url === "https://apiprod-abibl.fynity.in/api") {
          return `https://carprod-abibl.fynity.in/bike/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
      if (broker === "ACE") {
        //uat
        if (url === "https://apicar-aceinsurance.fynity.in/api") {
          return `https://carbike-uat-aceinsurance.fynity.in/bike/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //preprod
        if (url === "https://uatmotorapis.aceinsurance.com/api") {
          return `https://uatbike.aceinsurance.com/bike/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //prod
        if (url === "https://motorapis.aceinsurance.com/api") {
          return `https://bike.aceinsurance.com/bike/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
      if (broker === "EPOCH") {
        //uat
        if (url === "https://uatapimotor.policylo.com/api") {
          return `https://uatcar.policylo.com/bike/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
      if (broker === "RB") {
        //uat
        if (url === "https://apiuatmotor.rbstaging.in/api") {
          return `https://uatcar.rbstaging.in/bike/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //preprod
        if (url === "https://apipreprodmotor.rbstaging.in/api") {
          return `https://prepodcar.rbstaging.in/bike/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
    case "cv":
      if (broker === "ABIBL") {
        //uat
        if (url === "https://apiabibl-carbike.fynity.in/api") {
          return `https://abibl-car-bike.fynity.in/cv/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //preprod
        if (url === "https://apiabibl-preprod-carbike.fynity.in/api") {
          return `https://abibl-preprod-car-bike.fynity.in/cv/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //prod
        if (url === "https://apiprod-abibl.fynity.in/api") {
          return `https://carprod-abibl.fynity.in/cv/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
      if (broker === "ACE") {
        //uat
        if (url === "https://apicar-aceinsurance.fynity.in/api") {
          return `https://carbike-uat-aceinsurance.fynity.in/cv/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //preprod
        if (url === "https://uatmotorapis.aceinsurance.com/api") {
          return `https://uatpcv.aceinsurance.com/cv/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //prod
        if (url === "https://motorapis.aceinsurance.com/api") {
          return `https://pcv.aceinsurance.com/cv/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
      if (broker === "EPOCH") {
        //uat
        if (url === "https://uatapimotor.policylo.com/api") {
          return `https://uatcar.policylo.com/cv/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
      if (broker === "RB") {
        //uat
        if (url === "https://apiuatmotor.rbstaging.in/api") {
          return `https://uatcar.rbstaging.in/cv/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
        //preprod
        if (url === "https://apipreprodmotor.rbstaging.in/api") {
          return `https://prepodcar.rbstaging.in/cv/${
            renewal ? `renewal` : `registration`
          }?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ""}`;
        }
      }
    default:
      break;
  }
};
