export const fieldList = [
  "gstNumber",
  "maritalStatus",
  "occupation",
  "panNumber",
  "dob",
  "gender",
  "vehicleColor",
  "hypothecationCity",
  "cpaOptOut",
  "email",
  "pucNo",
  "pucExpiry",
  "representativeName",
  "cpaOptIn",
  "ncb",
  "inspectionType",
  "ckyc",
  "fileupload",
  "poi",
  "poa",
  "photo",
  "fatherName",
  "relationType",
  "organizationType",
];
