import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PopUp from 'components/Popup/Popup'


function FilePicker({
    register,
    regname,
    reset,
    id
}) {
    const [open,setOpen]=useState(false)
    const [fileName,setFileName]=useState("Upload Here")
    const [disabled,setDisabled]=useState(true)
    const [Image, setImage] = useState();
    const returnFile = (e) => {
        setFileName(e.target?.files[0]?.name)
        setImage(e.target?.files[0])
        setDisabled(false)
        if (reset) {
          e.target.value = null;
        }
      }
      console.log(id)
    return (
        <div style={{display:"flex"}}>
            <Label htmlFor={id}>
                {fileName} </Label>  
                <Input
                    type={'file'}
                    id={id}
                    ref={register}
                    name={regname}
                    onClick={(event) => {
                        event.target.value = null;
                        setFileName('Upload Here');
                      }}
                      onInput={returnFile}
                />
            {!disabled&&<Preview onClick={()=>{setOpen(true)}}>Preview</Preview>}
            <PopUp 
            show={open}
            onClose={setOpen}
            content={
                Image&&
                <div style={{display:"flex"}}>
                <Img
              src={URL.createObjectURL(Image)}
              alt="Thumb"
            />
            </div>
            }
            position="center"
            height={"auto"}
            />
        </div>
    )
}

export default FilePicker

export const Input = styled.input`
display: none;
`
export const Label = styled.label`
background: rgb(67 56 202);
padding: 4px 6px;
cursor: pointer;
color:white;
border: 0;
font-weight:300;
flex:1;
`
export const Preview = styled.label`
background: rgb(67 56 202);
padding: 4px 6px;
cursor: pointer;
color:white;
border:0;
font-weight:300;
// border-left:1px solid #999;
`
export const Img=styled.img`
max-height:500px;
max-width:500px;
margin: auto;
padding: 40px;
`

