import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Error404 from "components/ErrorPages/404";
import { NewError } from "components/ErrorPages/NewError";
import ErrorPage from "components/ErrorPages/errorPage";
import PaymentFail from "components/ErrorPages/payment-failure";
import JournerSuccess from "components/ErrorPages/journey-success";
import PaymentSuccess from "components/ErrorPages/payment-success";
import {
  Loader,
  AbiblHeader,
  Header,
  Layout,
  AbiblFooter,
  Footer,
  SriyahFooter,
  BajajFooter,
  TataFooter,
  BajajB2CFooter,
} from "components";
import {
  Home,
  QuotesPage,
  Proposal,
  Login,
  ComparePage,
  Feedback,
  LandingPage,
  Inspection,
  FieldConfig,
} from "modules";
import PaymentConfirmation from "modules/proposal/payment-confirmation";
import ProposalValidation from "modules/proposal/validations/ProposalValidation";
import { OnboardingConfig } from "modules/onboarding-config/onboarding-config";
import { Payment } from "modules/payment-gateway/payment";
import GeneratePdf from "modules/GeneratePdf/GeneratePdf";
import Theme from "modules/Theme/Theme";
import { AdminPanel } from "./modules/admin-panel/AdminPanel";
import { CarGetDataMmv } from "./modules/car-getdata-mmv/CarGetDataMmv";
import _ from "lodash";
import { subroutes } from "modules/type";
import { ServerError } from "modules/ServerError/ServerError";
import { reloadPage } from "utils";
import { userIdentifier } from "modules/login/login.slice";
import Config from "config/config";

//Auth
const PrivateRoute = ({ component: Component, props, ...rest }) => {
  const typeRoute = window.location.pathname.split("/");
  const type =
    !_.isEmpty(typeRoute) && process.env.REACT_APP_BASENAME === "NA"
      ? typeRoute.length >= 1
        ? typeRoute[1]
        : []
      : typeRoute.length >= 2
      ? typeRoute[2]
      : [];

  return rest?.repurpose && type && [...subroutes].includes(type) ? (
    <Redirect to={`/${type}/lead-page`} />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        type && [...subroutes].includes(type) ? (
          <Component {...props} />
        ) : process.env.REACT_APP_BASENAME !== "NA" ? (
          <Component {...props} />
        ) : (
          <Error404 {...props} />
        )
      }
    />
  );
};

const BajajRedirection = () => {
  return process.env.REACT_APP_PROD === "YES"
    ? process.env.REACT_APP_BASENAME === "general-insurance"
      ? window.location.origin
      : "https://partner.bajajcapitalinsurance.com"
    : process.env.REACT_APP_API_BASE_URL ===
      "https://stageapimotor.bajajcapitalinsurance.com/api"
    ? process.env.REACT_APP_BASENAME === "general-insurance"
      ? window.location.origin
      : "https://partnerstage.bajajcapitalinsurance.com"
    : //UAT
    process.env.REACT_APP_BASENAME === "general-insurance"
    ? "https://buypolicyuat.bajajcapitalinsurance.com/"
    : "https://partneruat.bajajcapitalinsurance.com";
};
const Router = (props) => {
  return (
    <BrowserRouter
      basename={
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }
    >
      <Suspense fallback={<Loader />}>
        <Switch>
          {process.env.REACT_APP_BROKER === "ACE" ? (
            <Route exact path="/">
              <Redirect to="/404" />
            </Route>
          ) : (
            <Route exact path="/">
              <Redirect to="/landing-page" />
            </Route>
          )}
          <Route
            exact
            path="/landing-page"
            component={
              process.env.REACT_APP_BROKER === "ACE" &&
              window.location?.hostname !== "localhost"
                ? () => {
                    reloadPage(
                      "https://crm.aceinsurance.com:5555/Admin/Dashboard"
                    );
                    return <noscript />;
                  }
                : process.env.REACT_APP_BROKER === "BAJAJ" &&
                  window.location?.hostname !== "localhost"
                ? () => {
                    reloadPage(BajajRedirection());
                    return <noscript />;
                  }
                : LandingPage
            }
          />
          {/* <Route exact path="/login" component={Login} /> */}
          <Layout>
            {process.env.REACT_APP_BROKER === "ABIBL" ? (
              <AbiblHeader />
            ) : (
              <Header />
            )}
            <Switch>
              <PrivateRoute exact path="/:type/lead-page" component={Home} />
              <PrivateRoute exact path="/:type/journey-type" component={Home} />
              <PrivateRoute exact path="/:type/registration" component={Home} />
              <PrivateRoute exact path="/:type/vehicle-type" component={Home} />
              <PrivateRoute
                exact
                path="/:type/vehicle-details"
                component={Home}
              />
              <PrivateRoute exact path="/:type/quotes" component={QuotesPage} />
              <PrivateRoute
                exact
                path="/:type/compare-quote"
                component={ComparePage}
              />
              <PrivateRoute
                exact
                path="/:type/proposal-page"
                component={Proposal}
              />
              <PrivateRoute
                exact
                path="/:type/payment-confirmation"
                component={PaymentConfirmation}
              />
              <PrivateRoute
                exact
                path="/:type/payment-gateway"
                component={Payment}
              />
              <Route exact path="/payment-success" component={PaymentSuccess} />
              <Route exact path="/payment-failure" component={PaymentFail} />
              <PrivateRoute
                exact
                path="/:type/successful"
                component={JournerSuccess}
              />
              <Route exact path="/feedback" component={Feedback} />
              <PrivateRoute exact path="/:type/renewal" component={Home} />
              <Route
                exact
                path="/motor/check-inspection-status"
                component={Inspection}
              />
              <Route exact path="/admin" component={AdminPanel} />
              <Route exact path="/mmv" component={CarGetDataMmv} />
              <Route exact path="/generate-pdf" component={GeneratePdf} />
              {localStorage?.configKey &&
              userIdentifier.includes(atob(localStorage?.configKey)) ? (
                <>
                  <Route exact path="/config" component={Config} />
                </>
              ) : (
                <Route exact path="/config" component={Login} />
              )}
              <Route
                exact
                path="/loader"
                component={(props) => <Loader {...props} />}
              />
              <Route
                exact
                path="/error"
                component={
                  process.env.REACT_APP_BROKER === "RB" ? ErrorPage : NewError
                }
              />
              <Route exact path="/404" component={Error404} />
              <Route exact path="/heart-beat" component={ServerError} />
              <PrivateRoute exact path="/:type" repurpose={true} />
              <Route component={Error404} />
            </Switch>
            {process.env.REACT_APP_BROKER === "ABIBL" ? (
              <AbiblFooter />
            ) : process.env.REACT_APP_BROKER === "SRIYAH" ? (
              <SriyahFooter />
            ) : process.env.REACT_APP_BROKER === "BAJAJ" ? (
              process.env.REACT_APP_BASENAME === "general-insurance" ? (
                <BajajB2CFooter />
              ) : (
                <BajajFooter />
              )
            ) : process.env.REACT_APP_BROKER === "TATA" ? (
              <TataFooter />
            ) : (
              <Footer />
            )}
          </Layout>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
