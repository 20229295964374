import React, { useState, useEffect } from "react";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TextInput, ErrorMsg, Label, BackButton, Loader } from "components";
import { useForm } from "react-hook-form";
import styled, { createGlobalStyle } from "styled-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useLocation } from "react-router";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  VehicleType as Type,
  set_temp_data,
  brandType,
  SaveQuoteData,
  clear,
} from "modules/Home/home.slice";
import _ from "lodash";
import { scrollToTargetAdjusted, reloadPage, RedirectFn } from "utils";
import {
  Url,
  DuplicateEnquiryId,
  clrDuplicateEnquiry,
} from "modules/proposal/proposal.slice";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { useMediaPredicate } from "react-media-hook";
import swal from "sweetalert";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

export const VehicleType = ({
  enquiry_id,
  type,
  token,
  errorProp,
  typeId,
  lessthan767,
  journey_type,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { vehicleType, temp_data, loading, saveQuoteData } = useSelector(
    (state) => state.home
  );

  const { duplicateEnquiry } = useSelector((state) => state.proposal);

  const lessthan600 = useMediaPredicate("(max-width: 600px)");
  const lessthan400 = useMediaPredicate("(max-width: 400px)");
  const lessthan350 = useMediaPredicate("(max-width: 350px)");

  /*---------------- back button---------------------*/
  const back = () => {
    if (temp_data?.isRenewalRedirection === "Y") {
      history.push(
        `/${type}/renewal?enquiry_id=${temp_data?.enquiry_id || enquiry_id}${
          token ? `&token=${token}` : ``
        }${typeId ? `&typeid=${typeId}` : ``}${
          journey_type ? `&journey_type=${journey_type}` : ``
        }`
      );
    } else {
      history.push(
        `/${type}/registration?enquiry_id=${
          temp_data?.enquiry_id || enquiry_id
        }${token ? `&token=${token}` : ``}${typeId ? `&typeid=${typeId}` : ``}${
          journey_type ? `&journey_type=${journey_type}` : ``
        }`
      );
    }
  };
  /*----------x----- back button-------x-------------*/

  //load vehicle data
  useEffect(() => {
    dispatch(
      Type({
        ...(process.env.REACT_APP_BROKER === "PINC" &&
          process.env.REACT_APP_PRODUCT !== "NA" && {
            filterProduct: process.env.REACT_APP_PRODUCT,
          }),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selected, setSelected] = useState(false);
  const [gcvCarrierType, setgcvCarrierType] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);

  //Url
  useEffect(() => {
    if (
      enquiry_id &&
      temp_data?.journeyStage?.stage &&
      temp_data?.userProposal?.isBreakinCase !== "Y"
    ) {
      ![
        "Payment Initiated",
        "pg_redirection",
        "Policy Issued",
        "Policy Issued, but pdf not generated",
        "Policy Issued And PDF Generated",
        "payment success",
        "payment failed",
        "Inspection Accept",
      ].includes(
        ["payment success", "payment failed"].includes(
          temp_data?.journeyStage?.stage.toLowerCase()
        )
          ? temp_data?.journeyStage?.stage.toLowerCase()
          : temp_data?.journeyStage?.stage
      ) &&
        dispatch(
          Url({
            proposalUrl: window.location.href,
            quoteUrl: window.location.href,
            stage: "Lead Generation",
            userProductJourneyId: enquiry_id,
          })
        );
    }
    if (
      temp_data?.journeyStage?.stage === "Payment Initiated" ||
      temp_data?.journeyStage?.stage.toLowerCase() === "payment failed"
    ) {
      dispatch(DuplicateEnquiryId({ enquiryId: enquiry_id }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.journeyStage?.stage]);

  //generate new enquiry id.
  useEffect(() => {
    if (duplicateEnquiry?.enquiryId) {
      swal(
        "Please Note",
        "Payment status is Incomplete. Proposal update required.",
        "info"
      ).then(() => {
        reloadPage(
          `${window.location.protocol}//${window.location.host}${
            process.env.REACT_APP_BASENAME !== "NA"
              ? `/${process.env.REACT_APP_BASENAME}`
              : ``
          }/${type}/vehicle-type?enquiry_id=${duplicateEnquiry?.enquiryId}${
            token ? `&token=${token}` : ""
          }${typeId ? `&typeid=${typeId}` : ``}${
            journey_type ? `&journey_type=${journey_type}` : ``
          }`
        );
      });
    }
    return () => {
      dispatch(clrDuplicateEnquiry());
    };
  }, [duplicateEnquiry]);

  //Journey already submitted
  const PaymentSuccessfulStages = [
    "Policy Issued And PDF Generated",
    "Policy Issued",
    "Policy Issued, but pdf not generated",
    "payment success",
  ];

  useEffect(() => {
    if (
      PaymentSuccessfulStages.includes(
        temp_data?.journeyStage?.stage.toLowerCase() === "payment success"
          ? "payment success"
          : temp_data?.journeyStage?.stage
      )
    ) {
      swal("Info", "This Proposal has already been submitted", "info").then(
        () =>
          temp_data?.journeyStage?.stage.toLowerCase() !== "payment failed"
            ? reloadPage(
                `${window.location.protocol}//${window.location.host}${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ``
                }/payment-success${
                  enquiry_id ? `?enquiry_id=${enquiry_id}` : ``
                }`
              )
            : reloadPage(
                `${window.location.protocol}//${window.location.host}${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : ``
                }/payment-success${
                  enquiry_id ? `?enquiry_id=${enquiry_id}` : ``
                }`
              )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data?.journeyStage?.stage]);

  // prefill data
  useEffect(() => {
    if (temp_data?.productSubTypeId) {
      setSelected(temp_data?.productSubTypeId);
    }
    if (temp_data?.gcvCarrierType) {
      setgcvCarrierType(temp_data?.gcvCarrierType);
    }
  }, [temp_data]);

  const { handleSubmit, register, errors } = useForm({
    // resolver: yupResolver(yupValidate),
    // mode: "all",
    // reValidateMode: "onBlur",
  });

  //onSuccess
  useEffect(() => {
    if (saveQuoteData) {
      history.push(
        `/${type}/vehicle-details?enquiry_id=${
          temp_data?.enquiry_id || enquiry_id
        }${token ? `&token=${token}` : ``}${typeId ? `&typeid=${typeId}` : ``}${
          journey_type ? `&journey_type=${journey_type}` : ``
        }`
      );
    }

    return () => {
      dispatch(clear("saveQuoteData"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveQuoteData]);

  //onError
  useEffect(() => {
    if (errorProp) {
      setbtnDisable(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorProp]);

  const onSubmit = (VehicalType, cType) => {
    let productSubTypeId = vehicleType?.filter(
      ({ productSubTypeId }) => Number(productSubTypeId) === Number(VehicalType)
    );
    dispatch(
      set_temp_data({
        productSubTypeId:
          Number(VehicalType) || Number(productSubTypeId[0]?.productSubTypeId),
        productSubTypeCode: productSubTypeId[0]?.productSubTypeCode,
        productCategoryName: productSubTypeId[0]?.productCategoryName,
        gcvCarrierType: cType,
        //clearing brand temp data
        // manfId: null,
        // manfName: null,
      })
    );
    //quotes save
    dispatch(
      SaveQuoteData({
        stage: "3",
        userProductJourneyId: enquiry_id,
        ...(token && { token: token }),
        enquiryId: enquiry_id,
        productSubTypeName: productSubTypeId[0]?.productSubTypeCode,
        productSubTypeId:
          Number(VehicalType) || Number(productSubTypeId[0]?.productSubTypeId),
        gcvCarrierType: cType,
        ...(journey_type && {
          journeyType: journey_type,
        }),
      })
    );
    //clearing brand data & enabling buttons
    setTimeout(setbtnDisable(false), 2000);
    dispatch(brandType([]));
  };

  const conditionCheck = () => {
    if (!_.isEmpty(vehicleType) && selected)
      return vehicleType?.filter(
        ({ productSubTypeId }) => productSubTypeId === selected
      )[0]?.productCategoryName;
  };

  useEffect(() => {
    if (!loading) scrollToTargetAdjusted("targetDiv", 45);
  }, [loading]);

  return (
    <>
      {!loading ? (
        <>
          <StyledBack className={lessthan767 ? "ml-1 backBtn" : "backBtn"}>
            <BackButton type="button" onClick={back}>
              {!lessthan767 ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                    viewBox="0 0 24 24"
                  >
                    <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                  <text style={{ color: "black" }}>Back</text>
                </>
              ) : (
                <img
                  src={`${
                    process.env.REACT_APP_BASENAME !== "NA"
                      ? `/${process.env.REACT_APP_BASENAME}`
                      : ""
                  }/assets/images/back-button.png`}
                  alt="bck"
                />
              )}
            </BackButton>
          </StyledBack>
          <div
            className="ml-4 my-4 ElemFade"
            id={"targetDiv"}
            style={
              !lessthan350 && lessthan767
                ? { position: "relative", top: "-37.53px" }
                : {}
            }
          >
            <Row className="text-center w-100">
              <div className="mt-4 d-flex flex-column justify-content-center w-100">
                <StyledH3 className="text-center w-100">
                  {lessthan767
                    ? lessthan400
                      ? "Select vehicle type"
                      : "Select type of vehicle"
                    : "Choose the type of your vehicle"}
                </StyledH3>
              </div>
            </Row>
            <Row className="d-flex justify-content-center w-100 mt-4">
              {vehicleType?.map(
                (
                  {
                    productSubTypeId,
                    productSubTypeCode,
                    productSubTypelogo,
                    productCategoryName,
                    productSubTypeDesc,
                  },
                  index
                ) => (
                  <Col
                    xs="4"
                    sm="4"
                    md="4"
                    lg="3"
                    xl="3"
                    className={`d-flex justify-content-center ${
                      lessthan600 ? "my-1 mx-0 px-0 w-100" : "my-2 px-1 w-100"
                    }`}
                  >
                    <StyledDiv className="mx-1 d-flex justify-content-center h-100 w-100">
                      <Button
                        disabled={btnDisable}
                        variant={
                          selected === Number(productSubTypeId)
                            ? Theme?.VehicleType?.buttonVariant || "success"
                            : Theme?.VehicleType?.outlineVariant ||
                              "outline-success"
                        }
                        // style={{ minWidth: "150px", maxWidth: "150px" }}
                        className="btn-filter text-center h-100 w-100 d-flex flex-column align-content-between"
                        type="button"
                        onClick={() => {
                          if (productCategoryName === "GCV") {
                            // setSelected(Number(productSubTypeId));
                            navigator &&
                              navigator?.vibrate &&
                              navigator.vibrate([100, 0, 50]);
                            onSubmit(productSubTypeId, "PUBLIC");
                            setbtnDisable(true);
                            // scrollToTargetAdjusted("carrierTypeDiv", 45);
                          } else {
                            navigator &&
                              navigator?.vibrate &&
                              navigator.vibrate([100, 0, 50]);
                            onSubmit(productSubTypeId);
                            setbtnDisable(true);
                          }
                        }}
                      >
                        <div
                          className="w-100 h-100 d-flex flex-column justify-content-center align-content-center mx-auto p-0"
                          // style={{ minWidth: "100px", maxWidth: "100px" }}
                        >
                          <div
                            style={
                              lessthan600
                                ? { maxHeight: "25px", minHeight: "25px" }
                                : { maxHeight: "50px", minHeight: "50px" }
                            }
                            className="text-center w-100"
                          >
                            <img
                              src={productSubTypelogo}
                              alt="productSubTypelogo"
                              className={
                                selected === Number(productSubTypeId)
                                  ? "filter-white"
                                  : "filter-green"
                              }
                              height={
                                lessthan400 ? "40" : lessthan600 ? "45" : "65"
                              }
                              width={lessthan600 ? "40" : "65"}
                            />
                          </div>
                          <div
                            style={
                              lessthan600
                                ? { maxHeight: "40px", minHeight: "40px" }
                                : { maxHeight: "50px", minHeight: "50px" }
                            }
                            className="text-center w-100 h-100 mt-2  overflow-auto d-flex flex-column justify-content-center
														"
                          >
                            <label
                              style={{
                                fontSize: lessthan400
                                  ? "8.5px"
                                  : lessthan600
                                  ? "9px"
                                  : "12px",
                                fontWeight: "1000",
                              }}
                              className="text-center w-100 label-text mb-0"
                            >
                              {productSubTypeDesc ===
                              "PICK UP/DELIVERY/REFRIGERATED VAN"
                                ? "Pickup-Delivery Van"
                                : productSubTypeDesc === "TANKER/BULKER"
                                ? "Tanker-Bulker"
                                : productSubTypeDesc === "DUMPER/TIPPER"
                                ? "Dumper-Tipper"
                                : productSubTypeDesc || "N/A"}
                            </label>
                            {productSubTypeDesc && false ? (
                              <label
                                style={{
                                  fontSize: lessthan600 ? "8px" : "9.5px",
                                  fontWeight: "600",
                                  whiteSpace: "pre-wrap",
                                }}
                                className="text-center m-0 p-0 w-100 label-text"
                              >
                                {productSubTypeDesc ===
                                "PICK UP/DELIVERY/REFRIGERATED VAN"
                                  ? "PICK-UP/DELIVERY VAN"
                                  : productSubTypeDesc || "N/A"}
                              </label>
                            ) : (
                              <noscript />
                            )}
                          </div>
                        </div>
                      </Button>
                    </StyledDiv>
                  </Col>
                )
              )}
            </Row>
            {conditionCheck() === "GCV" && false && (
              <Row className={"d-flex justify-content-center w-100 mt-5"}>
                <StyledH3
                  className="w-100 text-center mb-4"
                  id={"carrierTypeDiv"}
                >
                  Choose your vehicle carrier type
                </StyledH3>
                <Col
                  xs="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  className="d-flex justify-content-center my-1"
                >
                  <OverlayTrigger
                    key={"placement"}
                    placement={"top"}
                    style={{ zIndex: "999" }}
                    overlay={
                      <Tooltip id={`tooltip-1`}>
                        If the vehicle is used to transport your own goods only.
                      </Tooltip>
                    }
                  >
                    <Button
                      disabled={btnDisable}
                      onClick={() => {
                        setgcvCarrierType("PRIVATE");
                        onSubmit(selected, "PRIVATE");
                        setbtnDisable(true);
                      }}
                      variant={
                        gcvCarrierType === "PRIVATE"
                          ? Theme?.VehicleType?.buttonVariant || "success"
                          : Theme?.VehicleType?.outlineVariant ||
                            "outline-success"
                      }
                    >
                      <text style={{ fontSize: lessthan600 ? "14px" : "16px" }}>
                        Private Carrier
                      </text>
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col
                  xs="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  className="d-flex justify-content-center my-1"
                >
                  <OverlayTrigger
                    key={"placement"}
                    placement={"top"}
                    style={{ zIndex: "999" }}
                    overlay={
                      <Tooltip id={`tooltip-2`}>
                        If the vehicle is used to transport goods for other
                        people
                      </Tooltip>
                    }
                  >
                    <Button
                      disabled={btnDisable}
                      onClick={() => {
                        setgcvCarrierType("PUBLIC");
                        onSubmit(selected, "PUBLIC");
                        setbtnDisable(true);
                      }}
                      variant={
                        gcvCarrierType === "PUBLIC"
                          ? Theme?.VehicleType?.buttonVariant || "success"
                          : Theme?.VehicleType?.outlineVariant ||
                            "outline-success"
                      }
                    >
                      <text style={{ fontSize: lessthan600 ? "14px" : "16px" }}>
                        Public Carrier
                      </text>
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}
      <GlobleStyle />
    </>
  );
};

const GlobleStyle = createGlobalStyle`
 
 ${({ theme }) =>
   theme?.fontFamily &&
   `
      .label-text {
	font-family: ${theme?.fontFamily} !important;
}`};

 

`;

const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.regularFont?.fontColor || "rgb(74, 74, 74)"};
  font-size: 30px;
  font-family: ${({ theme }) =>
    theme.regularFont?.headerFontFamily || "sans-serif"};
  @media (max-width: 767px) {
    font-size: 20px;
  }
  /* ${process.env.REACT_APP_BROKER === "RB" &&
  `
    background: -webkit-linear-gradient(45deg,#339AEE,#387FB9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `} */
`;

const StyledDiv = styled.div`
  ${process.env.REACT_APP_BROKER === "PINC" &&
  `
.btn-outline-danger {
  color: deeppink !important;

  &:active, &.active, &:hover  {
    color: #fff !important;
    background: deeppink !important;
  }
}

.btn-danger {
  color: #fff !important;
  background: deeppink !important;

    &:active, &.active, &:hover  {
      color: #fff !important;
      background: deeppink !important;
    }
}
`}

  ${process.env.REACT_APP_BROKER === "RB" &&
  `
.btn-outline-danger {
  color: #F27F21 !important;
  border-color: #F27F21 !important;

  &:active, &.active, &:hover  {
    color: #fff !important;
    background: #F27F21 !important;
  }
}

.btn-danger {
  color: #fff !important;
  background: #F27F21 !important;

    &:active, &.active, &:hover  {
      color: #fff !important;
      background: #F27F21 !important;
    }
}
`}
${process.env.REACT_APP_BROKER === "SPA" &&
  `
.btn-outline-danger {
  color: #3877D6 !important;
  border-color: #3877D6 !important;

  &:active, &.active, &:hover  {
    color: #fff !important;
    background: #3877D6 !important;
  }
}

.btn-danger {
  color: #fff !important;
  background: #3877D6 !important;

    &:active, &.active, &:hover  {
      color: #fff !important;
      background: #3877D6 !important;
    }
}
`}
${process.env.REACT_APP_BROKER === "EPOCH" &&
  `
.btn-outline-danger {
  color: #27adb0 !important;
  border-color: #27adb0 !important;

  &:active, &.active, &:hover  {
    color: #fff !important;
    background: #27adb0 !important;
  }
}

.btn-danger {
  color: #fff !important;
  background: #27adb0 !important;

    &:active, &.active, &:hover  {
      color: #fff !important;
      background: #27adb0 !important;
    }
}
`}

${process.env.REACT_APP_BROKER === "ANALAH" &&
  `
.btn-outline-danger {
  color: #092C4C !important;
  border-color: #092C4C !important;

  &:active, &.active, &:hover  {
    color: #fff !important;
    background: #092C4C !important;
  }
}

.btn-danger {
  color: #fff !important;
  background: #092C4C !important;

    &:active, &.active, &:hover  {
      color: #fff !important;
      background: #092C4C !important;
    }
}
`}
${process.env.REACT_APP_BROKER === "BAJAJ" &&
  `
.btn-outline-danger {
  color: #ED1C24 !important;
  border-color: #ED1C24 !important;

  &:active, &.active, &:hover  {
    color: #fff !important;
    background: #ED1C24 !important;
  }
}

.btn-danger {
  color: #fff !important;
  background: #ED1C24 !important;

    &:active, &.active, &:hover  {
      color: #fff !important;
      background: #ED1C24 !important;
    }
}
`}
${process.env.REACT_APP_BROKER === "UIB" &&
  `
.btn-outline-danger {
  color: #006BFA !important;
  border-color: #006BFA !important;

  &:active, &.active, &:hover  {
    color: #fff !important;
    background: #006BFA !important;
  }
}

.btn-danger {
  color: #fff !important;
  background: #006BFA !important;

    &:active, &.active, &:hover  {
      color: #fff !important;
      background: #006BFA !important;
    }
}
`}
${process.env.REACT_APP_BROKER === "ACE" &&
  `
.btn-outline-danger {
  color: #23974e !important;
  border-color: #23974e !important;

  &:active, &.active, &:hover {
    color: #fff !important;
    background: #23974e !important;
    border-color: #23974e !important;
  }
  &:focus{
      box-shadow: 0px 0px 0px 0.2rem rgba(35,151,78,0.5)!important;
    }
}

.btn-danger {
  color: #fff !important;
  background: #23974e !important;
   border-color: #23974e !important;

    &:active, &.active, &:hover  {
      color: #fff !important;
      background: #23974e !important;
      border-color: #23974e !important;
    }
    &:focus{
      box-shadow: 0px 0px 0px 0.2rem  rgba(35,151,78,0.5)!important;
    }
}
`}
${process.env.REACT_APP_BROKER === "TATA" &&
  `
.btn-outline-danger {
  color: #0099f2  !important;
  border-color: #0099f2  !important;

  &:active, &.active, &:hover {
    color: #fff !important;
    background: #0099f2  !important;
    border: 1px solid #0099f2 !important;
  }
}

.btn-danger {
  color: #fff !important;
  background: #0099f2  !important;
  border: 1px solid #0099f2 !important;

    &:active, &.active, &:hover  {
      color: #fff !important;
      background: #0099f2  !important;
      border: 1px solid #0099f2 !important;
    }
}
`}

  .filter-green {
    filter: ${({ theme }) =>
      theme?.VehicleType?.filterIconCol ||
      `invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg)
			brightness(90%) contrast(119%)}`};
  }

  .btn-filter:hover .filter-green {
    filter: brightness(0) invert(1);
  }

  .filter-white {
    filter: brightness(0) invert(1);
  }
`;

const StyledBack = styled.div`
  padding-bottom: 30px;
  margin-top: -20px;
  z-index: 999;
  ${process.env.REACT_APP_BROKER === "ABIBL"
    ? `@media (max-width: 780px) {
    position: relative;
    top: -120px;
    left: -10%;
  }
  @media (max-width: 769px) {
    position: relative;
    top: -125px;
    left: -11%;
  }
  @media (max-width: 600px) {
    position: relative;
    top: -120px;
    left: -10%;
  }`
    : `@media (max-width: 780px) {
      position: relative;
      top: -73px;
      left: -10%;
    }
    @media (max-width: 769px) {
      position: relative;
      top: -125px;
      left: -11%;
    }
    @media (max-width: 600px) {
      position: relative;
      top: -73px;
      left: -10%;
    }`}
`;