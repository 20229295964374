import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createGlobalStyle } from "styled-components";
import App from "./App";
import "./index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { typeRoute } from "modules/type";
import _ from "lodash";
import DocumentMeta from "react-document-meta";
//css
import "./css/style.css";
// redux store
import store from "./app/store";

//block console.log
if (process.env.REACT_APP_PROD === "YES") console.log = () => {};

//analytics
if (process.env.REACT_APP_CLARTIY !== "NA") {
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", process.env.REACT_APP_CLARITY);
}

process.env.REACT_APP_PROD !== "YES" &&
  process.env.REACT_APP_SENTRY &&
  process.env.REACT_APP_SENTRY !== "NA" &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
const meta = {
  title:
    process.env.REACT_APP_BROKER === "BAJAJ"
      ? `${
          typeRoute() === "bike" ? "2 Wheeler" : _.capitalize(typeRoute())
        } Insurance Policy, Motor Insurance, Bike Insurance, Best Rates`
      : process.env.REACT_APP_TITLE,
  description:
    process.env.REACT_APP_BROKER === "BAJAJ"
      ? typeRoute() === "bike"
        ? "Two Wheeler Insurance: Buy/Renew Bike Insurance Online, Bajaj Capital Top Insurance seller Platform in India"
        : typeRoute() === "car"
        ? "Compare your Car Valuation, Buy & Renew Your Motor insurance with Bajaj Capital Top Insurance seller Platform in India"
        : process.env.REACT_APP_META
      : process.env.REACT_APP_META,
  meta: {
    charset: "utf-8",
    property: {
      "og:url": `${window.location.hostname}${
        process.env.REACT_APP_BASENAME === "NA"
          ? ""
          : `/${process.env.REACT_APP_BASENAME}`
      }`,
    },
  },
};

const GlobalStyle = createGlobalStyle`
 
${({ theme }) =>
  theme?.fontFamily &&
  `.body{
  font-family : ${theme?.fontFamily} !important }`};
`;

ReactDOM.render(
  <Provider store={store}>
    <DocumentMeta {...meta}>
      <App />
    </DocumentMeta>
    <GlobalStyle />
  </Provider>,
  document.getElementById("root")
);
