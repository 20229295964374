import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompactCard as Card, Button } from "components";
import {
  scrollToTargetAdjusted,
  getLogoCvType,
  reloadPage,
  Encrypt,
  scrollToTop,
  toDate,
} from "utils";
import { Button as Btn, Row, Col, Spinner, Modal } from "react-bootstrap";
import { Label, ShiftingLabel, SubmitDiv } from "./style.js";
import "./bootstrapMod.scss";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { useHistory } from "react-router";
import swal from "sweetalert";
import moment from "moment";
import { Save, clear, SubmitData, Lead, OTP, Finsall } from "./proposal.slice";
import { ShareQuote, clear as clr } from "../../modules/Home/home.slice";
import { Whatsapp, SaveAddonsData } from "modules/quotesPage/quote.slice";
import { useMediaPredicate } from "react-media-hook";
import { differenceInMonths, differenceInDays } from "date-fns";
import { TypeReturn } from "modules/type.js";
import { Identities, identitiesCompany } from "./cards/data.js";
/*---modals---*/
import OTPPopup from "./otp";
import PaymentModal from "./payment-modal";
import PreSubmit from "./pre-submit";
import CkycMandate from "./ckyc-mandate";
/*---theme---*/
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
/*---cards---*/
import OwnerCard from "./cards/owner-card";
import NomineeCard from "./cards/nominee-card";
import VehicleCard from "./cards/vehicle-card";
import PolicyCard from "./cards/policy-card";
/*---summary---*/
import SummaryOwner from "./summary/summary-owner";
import SummaryProposal from "./summary/summary-proposal";
import SummaryVehicle from "./summary/summary-vehicle";

const FormSection = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const lessthan768 = useMediaPredicate("(max-width: 768px)");
  const lessthan376 = useMediaPredicate("(max-width: 376px)");

  const ls = new SecureLS();
  const ThemeLS = ls.get("themeData");
  const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

  const {
    error,
    submit,
    submitProcess,
    otp,
    verifyOtp,
    finUrl,
    verifyCkycnum,
    error_other,
    errorSpecific,
  } = useSelector((state) => state.proposal);
  const { share, theme_conf } = useSelector((state) => state.home);
  const { cpaSet } = useSelector((state) => state.quotes);
  //modals.
  const [show, setShow] = useState(false); //otp modal
  const [show1, setShow1] = useState(false); // payment confirmation modal
  const [show2, setShow2] = useState(false); // presubmit conditions modal
  const [show3, setShow3] = useState(false); // ckyc-mandate modal
  const [otp_id, setotp_id] = useState();
  const [rsKycStatus, setrsKycStatus] = useState(null);

  //CKYC Documents
  const [panCardFile, setPanCardFile] = useState();
  const [file, setFile] = useState();
  const [form60, setForm60] = useState();
  const [poi_file, setpoi_file] = useState();
  const [poa_file, setpoa_file] = useState();
  const [photo, setPhoto] = useState();
  const [loading, setLoading] = useState(false);
  const [resubmit, setResubmit] = useState(false);
  const [uploadFile, setuploadFile] = useState(false);
  const [verifiedData, setVerifiedData] = useState();
  const [fileUploadError, setFileUploadError] = useState(false);
  // const [panCardFile, setPanCardFile] = useState()
  //fetched data
  const TempData = !_.isEmpty(props?.temp) ? props?.temp : {};
  //temp card data
  const CardData = !_.isEmpty(TempData)
    ? TempData?.userProposal?.additonalData
      ? TempData?.userProposal?.additonalData
      : {}
    : {};
  const companyAlias = !_.isEmpty(TempData?.selectedQuote)
    ? TempData?.selectedQuote?.companyAlias
    : "";
  const Additional = !_.isEmpty(props?.Additional) ? props?.Additional : {};
  const OwnerPA = !_.isEmpty(Additional?.compulsoryPersonalAccident)
    ? Additional?.compulsoryPersonalAccident?.filter(
        ({ name }) => name === "Compulsory Personal Accident"
      )
    : [];
  const Tenure = !_.isEmpty(Additional?.compulsoryPersonalAccident)
    ? Additional?.compulsoryPersonalAccident?.filter(({ tenure }) => tenure)
    : [];
  const ReasonPA = !_.isEmpty(Additional?.compulsoryPersonalAccident)
    ? Additional?.compulsoryPersonalAccident?.filter(
        ({ reason }) => reason && reason !== "cpa not applicable to company"
      )
    : [];
  //IC's / Brokers / journey which requires nominee data in all cases
  const NomineeBroker =
    (TempData?.selectedQuote?.companyAlias === "royal_sundaram" ||
      TempData?.selectedQuote?.companyAlias === "edelweiss" ||
      "" ||
      "") &&
    TempData?.corporateVehiclesQuoteRequest?.policyType !== "own_damage";
  //condition check for when to display CPA on the nominee card.
  //config
  const configCon =
    props?.fields.includes("cpaOptIn") &&
    TempData?.corporateVehiclesQuoteRequest?.policyType !== "own_damage";
  const conditionChk =
    !_.isEmpty(OwnerPA) || NomineeBroker || configCon ? true : false;
  //CPA check
  const PACondition =
    !_.isEmpty(ReasonPA) &&
    TempData?.corporateVehiclesQuoteRequest?.vehicleOwnerType !== "C" &&
    TempData?.corporateVehiclesQuoteRequest?.policyType !== "own_damage"
      ? true
      : false;
  //previous policy details check
  const PolicyCon =
    //when previous insurer is present and not new
    (TempData?.corporateVehiclesQuoteRequest?.previousInsurer &&
      TempData?.corporateVehiclesQuoteRequest?.previousInsurer !== "NEW" &&
      //incase of adrila hard coding
      TempData?.selectedQuote?.businessType !== "New Business" &&
      TempData?.corporateVehiclesQuoteRequest?.previousPolicyType !==
        "Not sure") ||
    //when third party is selected and business type is not new & previous policy type is not sure
    (TempData?.selectedQuote?.policyType === "Third Party" &&
      TempData?.selectedQuote?.businessType !== "New Business" &&
      TempData?.corporateVehiclesQuoteRequest?.previousPolicyType !==
        "Not sure") ||
    //breakin over 90 days
    ((!TempData?.corporateVehiclesQuoteRequest?.previousInsurer ||
      TempData?.corporateVehiclesQuoteRequest?.previousInsurerCode ===
        "Not selected") &&
      TempData?.corporateVehiclesQuoteRequest?.businessType === "breakin") ||
    //when previos policy type is TP and current policy type is comprehensive / not sure
    TempData?.corporateVehiclesQuoteRequest?.previousPolicyType ===
      "Third-party";
  //when previous policy expiry is not sure but previous policy type is Own Damage. (removed after #5115)
  // || (TempData?.corporateVehiclesQuoteRequest?.previousPolicyType ===
  //   "Not sure" &&
  //   TempData?.corporateVehiclesQuoteRequest?.policyType === "own_damage");

  //excluding validations of policy details in these cases
  const PolicyValidationExculsion =
    //when previous policy expiry is not sure but previous policy type is Own Damage, only TP validations are required
    TempData?.corporateVehiclesQuoteRequest?.previousPolicyType ===
      "Not sure" &&
    TempData?.corporateVehiclesQuoteRequest?.policyType === "own_damage";

  //Is NCB Applicable?
  const isNcbApplicable =
    TempData?.corporateVehiclesQuoteRequest?.previousPolicyType ===
      "Third-party" || TempData?.selectedQuote?.policyType === "Third Party";
  //TP details Inclusion (Renewal window)
  const tpDetailsRequired =
    TempData?.selectedQuote?.policyType === "Own Damage" ||
    TempData?.corporateVehiclesQuoteRequest?.policyType === "own_damage" ||
    //If diff between reg date & current date is 34 or above 34 but less than 36
    //For car
    ((((TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
      moment().format("DD-MM-YYYY") &&
      differenceInMonths(
        toDate(moment().format("DD-MM-YYYY")),
        toDate(TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate)
      )) >= 34 &&
      (TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
        moment().format("DD-MM-YYYY") &&
        differenceInDays(
          toDate(moment().format("DD-MM-YYYY")),
          toDate(TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate)
        )) > 270 &&
      //If diff between reg date & current date = 36, we check if the diff of days between reg date & current date <= 1095
      ((TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
        moment().format("DD-MM-YYYY") &&
        differenceInMonths(
          toDate(moment().format("DD-MM-YYYY")),
          toDate(TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate)
        )) < 36 ||
        ((TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
          moment().format("DD-MM-YYYY") &&
          differenceInMonths(
            toDate(moment().format("DD-MM-YYYY")),
            toDate(TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate)
          )) === 36 &&
          (TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
            moment().format("DD-MM-YYYY") &&
            differenceInDays(
              toDate(moment().format("DD-MM-YYYY")),
              toDate(
                TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate
              )
            )) <= 1095)) &&
      props?.TypeReturn(props?.type) === "car") || //If diff between reg date & current date is 58 or above 58 but less than 60
      //For bike
      ((TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
        moment().format("DD-MM-YYYY") &&
        differenceInDays(
          toDate(TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate),
          toDate(moment().format("01-09-2018"))
        )) >= 0 &&
        (TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
          moment().format("DD-MM-YYYY") &&
          differenceInMonths(
            toDate(moment().format("DD-MM-YYYY")),
            toDate(TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate)
          )) >= 58 &&
        (TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
          moment().format("DD-MM-YYYY") &&
          differenceInDays(
            toDate(moment().format("DD-MM-YYYY")),
            toDate(TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate)
          )) > 270 &&
        //If diff between reg date & current date = 60, we check if the diff of days between reg date & current date <= 1095
        ((TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
          moment().format("DD-MM-YYYY") &&
          differenceInMonths(
            toDate(moment().format("DD-MM-YYYY")),
            toDate(TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate)
          )) < 60 ||
          ((TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
            moment().format("DD-MM-YYYY") &&
            differenceInMonths(
              toDate(moment().format("DD-MM-YYYY")),
              toDate(
                TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate
              )
            )) === 60 &&
            (TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
              moment().format("DD-MM-YYYY") &&
              differenceInDays(
                toDate(moment().format("DD-MM-YYYY")),
                toDate(
                  TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate
                )
              )) <= 1095)) &&
        //Only appliable for car
        props?.TypeReturn(props?.type) === "bike") ||
      // empty OR/AND to offset the hook render order. Do not remove
      "") &&
      new Date().getFullYear() -
        Number(
          TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate?.slice(
            TempData?.corporateVehiclesQuoteRequest?.vehicleRegisterDate
              ?.length - 4
          )
        ) >=
        1 && //Checking if prev policy type is TP or Compreensive
      (TempData?.corporateVehiclesQuoteRequest?.previousPolicyType ===
        "Third-party" ||
        ["Comprehensive", "Own-damage"]?.includes(
          TempData?.corporateVehiclesQuoteRequest?.previousPolicyType
        )) &&
      //previousPolicyTypeIdentifier indicates false OD/Multi-Yr TP/Bundle in prev type policy. Hence it should not be Y
      TempData?.corporateVehiclesQuoteRequest?.previousPolicyTypeIdentifier !==
        "Y");

  //open to function - used in summary page.
  const removeOpenTo = () => {
    var queryUrl = window.location.search.substring(1);

    // is there anything there ?
    if (queryUrl.length) {
      // are the new history methods available ?
      if (
        window.history != undefined &&
        window.history.pushState != undefined
      ) {
        // if pushstate exists, add a new state to the history, this changes the url without reloading the page
        const newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          `?enquiry_id=${props?.enquiry_id}${
            props?.token ? `&token=${props?.token}` : ``
          }${props?.typeId ? `&typeid=${props?.typeId}` : ``}${
            props?.journey_type ? `&journey_type=${props?.journey_type}` : ``
          }`;
        window.history.pushState({ path: newurl }, "", newurl);
      }
    }
  };
  /*------------- Dropout-status -----------------*/
  const [dropout, setDropout] = useState(false);
  useEffect(() => {
    if (props.dropout || props.breakinCase || rsKycStatus?.kyc_status) {
      setDropout(true);
    }
  }, [props.dropout, props.breakinCase, rsKycStatus]);
  /*------x------ Dropout-status -------x---------*/

  useEffect(() => {
    setrsKycStatus(props?.rsKycStatus);
    dispatch(clear("rskycStatus"));
  }, [props?.rsKycStatus]);

  /*------------ckyc mandate ----------------*/
  useEffect(() => {
    if (
      !_.isEmpty(TempData) &&
      CardData?.owner?.popupPreview !== "Y" &&
      owner?.popupPreview !== "Y" &&
      !show &&
      (theme_conf?.broker_config?.ckyc_mandate ||
        process.env.REACT_APP_BROKER === "RB")
    ) {
      setShow3(true);
    }
  }, [TempData?.userProposal?.additionalData]);
  /*-----x------ckyc mandate--------x-------*/

  /*---------------------form data--------------------*/
  //saving the proposal card data in state

  const [review, setReview] = useState({});
  const [owner, setOwner] = useState({});
  const [tempOwner, setTempOwner] = useState({});
  const [nominee, setNominee] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [prepolicy, setPrepolicy] = useState({});
  /*-----------------x---form data-x------------------*/

  /*-----------------Switchers (form/summary) ------------------------*/
  const [formOwner, setFormOwner] = useState("form");
  const [formNominee, setFormNominee] = useState("hidden");
  const [formVehicle, setFormVehicle] = useState("hidden");
  const [formPrepolicy, setFormPrepolicy] = useState("hidden");
  /*-----------------Switchers section end--------------------------*/

  /*--------------------form switchers----------------------------*/
  useEffect(() => {
    if (Number(TempData?.ownerTypeId) === 2 || !conditionChk) {
      if (formOwner === "form") {
        setFormVehicle("hidden");
        setFormPrepolicy("hidden");
      }
      if (formVehicle === "form") {
        setFormPrepolicy("hidden");
      }
    } else {
      if (formOwner === "form") {
        setFormNominee("hidden");
        setFormVehicle("hidden");
        setFormPrepolicy("hidden");
      }
      if (formNominee === "form") {
        setFormVehicle("hidden");
        setFormPrepolicy("hidden");
      }
      if (formVehicle === "form") {
        setFormPrepolicy("hidden");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOwner, formNominee, formVehicle, formPrepolicy]);
  /*---------x----------form switchers----------------x-----------*/

  /*--------------------form onSubmits----------------------------*/

  //validation for format of files
  useEffect(() => {
    if (photo) {
      companyAlias !== "shriram"
        ? photo?.type !== "image/jpeg" &&
          photo?.type !== "image/jpg" &&
          photo?.type !== "image/bmp" &&
          swal(
            "Error",
            "Please upload photo in jpg,jpeg or bmp format",
            "error"
          ).then(() => {
            setPhoto();
          })
        : photo?.type !== "image/png" &&
          photo?.type !== "application/pdf" &&
          swal(
            "Error",
            "Please upload photo in png or pdf format",
            "error"
          ).then(() => {
            setPhoto();
          });
    }
  }, [photo]);

  useEffect(() => {
    if (poi_file) {
      (companyAlias === "iffco_tokio" || companyAlias === "magma") &&
        poi_file?.type !== "application/pdf" &&
        poi_file?.type !== "image/jpeg" &&
        swal("Error", "Please upload file in jpg or pdf format", "error").then(
          () => {
            setpoi_file();
          }
        );

      companyAlias === "shriram" &&
        poi_file?.type !== "application/pdf" &&
        poi_file?.type !== "image/png" &&
        poi_file?.type !== "image/xlsx" &&
        swal(
          "Error",
          "Please upload file in png, xlsx or pdf format",
          "error"
        ).then(() => {
          setpoi_file();
        });
    }
  }, [poi_file]);

  useEffect(() => {
    if (poa_file) {
      (companyAlias === "iffco_tokio" || companyAlias === "magma") &&
        poa_file?.type !== "application/pdf" &&
        poa_file?.type !== "image/jpeg" &&
        swal("Error", "Please upload file in jpg or pdf format", "error").then(
          () => {
            setpoa_file();
          }
        );

      companyAlias === "shriram" &&
        poa_file?.type !== "application/pdf" &&
        poa_file?.type !== "image/png" &&
        poa_file?.type !== "image/xlsx" &&
        swal(
          "Error",
          "Please upload file in png, xlsx or pdf format",
          "error"
        ).then(() => {
          setpoa_file();
        });
    }
  }, [poa_file]);

  //owner
  const onSubmitOwner = async (data) => {
    if (
      (props?.fields?.includes("photo") &&
        !photo &&
        companyAlias !== "shriram" && companyAlias !== "iffco_tokio") ||
        (companyAlias === "iffco_tokio" && (uploadFile && props?.fields?.includes("photo")) && !photo) ||
      (uploadFile &&
        props?.fields?.includes("poi") &&
        !poi_file &&
        companyAlias !== "bajaj_allianz" &&
        companyAlias !== "tata_aig") ||
      (uploadFile && props?.fields?.includes("poa") && !poa_file) ||
      (data?.identity === "form60" && !form60)
    ) {
      setFileUploadError(true);
    } else {
      let ckycPostSubmit =
        TempData?.selectedQuote?.companyAlias === "godigit" ||
        TempData?.selectedQuote?.companyAlias === "tata_aig" ||
        TempData?.selectedQuote?.companyAlias === "bajaj_allianz" ||
        TempData?.selectedQuote?.companyAlias === "royal_sundaram" ||
        TempData?.selectedQuote?.companyAlias === "kotak" ||
        TempData?.selectedQuote?.companyAlias === "raheja" ||
        TempData?.selectedQuote?.companyAlias === "new_india" ||
        TempData?.selectedQuote?.companyAlias === "shriram"||
        TempData?.selectedQuote?.companyAlias === "sbi";

      const selectedIdentity = data?.identity
        ? Number(TempData?.ownerTypeId) === 1
          ? Identities(companyAlias, true).find(
              (each) => each.id === data?.identity
            )
          : identitiesCompany(companyAlias, true)?.find(
              (each) => each.id === data?.identity
            )
        : "";
      const selectedpoiIdentity =
        data?.poi_identity && TempData
          ? Number(TempData?.ownerTypeId) === 1
            ? Identities(companyAlias, true).find(
                (each) => each.id === data?.poi_identity
              )
            : identitiesCompany(companyAlias, true)?.find(
                (each) => each.id === data?.poi_identity
              )
          : "";
      const selectedpoaIdentity =
        data?.poa_identity && TempData
          ? Number(TempData?.ownerTypeId) === 1
            ? Identities(companyAlias, true).find(
                (each) => each.id === data?.poa_identity
              )
            : identitiesCompany(companyAlias, true)?.find(
                (each) => each.id === data?.poa_identity
              )
          : "";

      //CKYC verification API is not fired with save API in post submit ckyc case
      props?.fields?.includes("ckyc") && !ckycPostSubmit && !resubmit
        ? setTempOwner(data)
        : setOwner(data);

      let OwnerRequest = {
        ...data,
        ...(props?.fields?.includes("ckyc") &&
          !poi_file &&
          // !photo &&
          !poa_file && {
            ckycType: props?.fields?.includes("ckyc")
              ? data?.isckycPresent === "YES"
                ? "ckyc_number"
                : data?.identity === "aadharNumber"
                ? "aadhar_card"
                : data?.identity === "panNumber"
                ? "pan_card"
                : data?.identity === "form60"
                ? "form60"
                : data?.identity === "drivingLicense"
                ? "driving_license"
                : data?.identity === "voterId"
                ? "voter_id"
                : data?.identity === "passportNumber"
                ? "passport"
                : null
              : null,
            ckycTypeValue: props?.fields?.includes("ckyc")
              ? data?.isckycPresent === "YES"
                ? data?.ckycNumber
                : data[data?.identity]
              : null,
          }),
        // ckycNumber:verifyCkycnum?.ckyc_id ,
        stage: "1",
        lastProposalModifiedTime: TempData?.lastProposalModifiedTime,
        street: "street",
        ownerType: Number(TempData?.ownerTypeId) === 1 ? "I" : "C",
        businessType: TempData?.corporateVehiclesQuoteRequest?.businessType,
        productType: TempData?.corporateVehiclesQuoteRequest?.policyType,
        icName: TempData?.selectedQuote?.companyName,
        icId: TempData?.selectedQuote?.insuraneCompanyId,
        idv: TempData?.quoteLog?.idv,
        userProductJourneyId: props.enquiry_id,
        officeEmail: data?.email,
        ...{
          sellerType: TempData?.quoteAgent?.sellerType
            ? TempData?.quoteAgent?.sellerType
            : null,
        },
        ...(props?.token && {
          agentId: TempData?.quoteAgent?.agentId,
          agentName: TempData?.quoteAgent?.agentName,
          agentMobile: TempData?.quoteAgent?.agentMobile,
          agentEmail: TempData?.quoteAgent?.agentEmail,
          token: props?.token,
        }),
        additionalDetails: {
          review: CardData?.review,
          owner: data,
        },
      };

      const ckycPayload = {
        companyAlias,
        lastProposalModifiedTime: TempData?.lastProposalModifiedTime,
        mode:
          companyAlias === "united_india" || companyAlias === "oriental"
            ? "ckyc"
            : data?.isckycPresent === "YES"
            ? companyAlias === "united_india" || companyAlias === "oriental"
              ? "api"
              : "ckyc_number"
            : poa_file || poi_file || photo
            ? "documents"
            : data?.identity === "aadharNumber"
            ? companyAlias === "icici_lombard"
              ? "aadhar_with_dob"
              : "aadhar"
            : data?.identity === "panNumber"
            ? "pan_number_with_dob"
            : data?.identity === "passportNumber"
            ? "passport"
            : data?.identity === "voterId"
            ? "voter_id"
            : data?.identity === "drivingLicense"
            ? "driving_license"
            : null,
        ...(data?.relationType && {
          relationType: data?.relationType,
          [`${data?.relationType}`]: data?.[`${data?.relationType}`],
        }),
        enquiryId: props?.enquiry_id,
        ...(ckycPostSubmit && {
          userProductJourneyId: props.enquiry_id,
          step: "5",
        }),
      };
      const formdata = new FormData();
      formdata.append("companyAlias", companyAlias);
      formdata.append(
        "mode",
        companyAlias === "united_india" || companyAlias === "oriental"
          ? "ckyc"
          : data?.isckycPresent === "YES"
          ? "ckyc_number"
          : poa_file || poi_file || photo
          ? "documents"
          : data?.identity === "aadharNumber"
          ? companyAlias === "icici_lombard"
            ? "aadhar_with_dob"
            : "aadhar"
          : "pan_number_with_dob"
      );
      formdata.append("enquiryId", props?.enquiry_id);
      poa_file &&
        formdata.append(`poa_${selectedpoaIdentity?.fileKey}`, poa_file);
      poi_file &&
        formdata.append(`poi_${selectedpoiIdentity?.fileKey}`, poi_file);
      form60 && formdata.append(`form60`, form60);
      photo && formdata.append(`photo`, photo);
      data?.relationType && formdata.append("relationType", data?.relationType);
      data?.relationType &&
        formdata.append(
          `${data?.relationType}`,
          data?.[`${data?.relationType}`]
        );
      //In case of post submit ckyc, we are storing the documents in user proposal using an additional step "5" through the save API.
      ckycPostSubmit &&
        formdata.append("userProductJourneyId", props.enquiry_id);
      ckycPostSubmit && formdata.append("step", 5);
      ckycPostSubmit &&
        formdata.append(
          "lastProposalModifiedTime",
          TempData?.lastProposalModifiedTime
        );

      if (!!resubmit) {
        //submit normally in case of ckyc verification and data prefill sent by ic
        dispatch(Save(OwnerRequest));
        companyAlias !== "reliance" && setResubmit(false);
      } else if (ckycPostSubmit) {
        // Dispatching step 1 of save normally in case of tata & digit
        dispatch(Save(OwnerRequest));
        dispatch(
          Save(poa_file || poi_file || photo || form60 ? formdata : ckycPayload)
        );
      } else {
        //Dispatching save and then ckyc confirmation as per the generic ckyc flow
        setLoading(true);
        dispatch(
          Save(
            OwnerRequest,
            props?.fields?.includes("ckyc") ? true : false,
            poa_file || poi_file || photo || form60 ? formdata : ckycPayload,
            setLoading
          )
        );
      }

      // remove openTo
      if (props?.openTo === "owner") {
        removeOpenTo();
      }
    }
  };

  //switch(owner -> nominee)
  useEffect(() => {
    if (Number(TempData?.ownerTypeId) === 2 || !conditionChk) {
      if (!_.isEmpty(owner)) {
        setFormOwner("summary");
        setFormVehicle("form");
      }
    } else {
      if (!_.isEmpty(owner)) {
        setFormOwner("summary");
        setFormNominee("form");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner]);

  //nominee
  const onSubmitNominee = (data) => {
    setNominee(data);
    let NomineeRequest = {
      ...data,
      stage: "2",
      lastProposalModifiedTime: TempData?.lastProposalModifiedTime,
      userProductJourneyId: props.enquiry_id,
      ownerType: Number(TempData?.ownerTypeId) === 1 ? "I" : "C",
      ...(props?.token && { token: props?.token }),
      additionalDetails: {
        review: CardData?.review,
        owner: owner,
        nominee: { ...data },
      },
    };
    dispatch(Save(NomineeRequest));
  };

  //switch(nominee -> vehicle)
  useEffect(() => {
    if (!_.isEmpty(nominee)) {
      setFormNominee("summary");
      setFormVehicle("form");
    }
  }, [nominee]);

  //vehicle
  const onSubmitVehicle = (data) => {
    setVehicle(data);
    let VehicleRequest = {
      ...data,
      ...(data?.hypothecationCity && {
        financerLocation: data?.hypothecationCity,
      }),
      stage: "3",
      lastProposalModifiedTime: TempData?.lastProposalModifiedTime,
      ownerType: Number(TempData?.ownerTypeId) === 1 ? "I" : "C",
      userProductJourneyId: props.enquiry_id,
      ...(props?.token && { token: props?.token }),
      additionalDetails: {
        owner: owner,
        nominee: nominee,
        vehicle: { ...data },
      },
    };
    dispatch(Save(VehicleRequest));
    // remove openTo
    if (props?.openTo === "vehicle") {
      removeOpenTo();
    }
  };

  //switch(vehicle -> pre-policy)
  useEffect(() => {
    if (!_.isEmpty(vehicle)) {
      setFormVehicle("summary");
      setFormPrepolicy("form");
    }
  }, [vehicle]);

  //pre-policy
  const onSubmitPrepolicy = (data) => {
    setPrepolicy(data);
    let PrepolicyRequest = {
      ...data,
      stage: "4",
      lastProposalModifiedTime: TempData?.lastProposalModifiedTime,
      ownerType: Number(TempData?.ownerTypeId) === 1 ? "I" : "C",
      userProductJourneyId: props.enquiry_id,
      ...(props?.token && { token: props?.token }),
      additionalDetails: {
        review: CardData?.review,
        owner: owner,
        nominee: nominee,
        vehicle: vehicle,
        prepolicy: { ...data },
      },
    };
    dispatch(Save(PrepolicyRequest));
  };

  //switch(pre-policy -> review & submit)
  useEffect(() => {
    if (!_.isEmpty(prepolicy)) {
      setFormPrepolicy("summary");
    }
  }, [prepolicy]);
  /*---------x----------form onSubmits----------------x-----------*/

  /*--------------------Review & Submit Section End-------------------*
  /*--------------- Handle Addon declaration -------------*/
  //Addon availability check
  const ZD_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Zero Depreciation") ? true : false;
  };
  //Road Side Assistance
  const RSA_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Road Side Assistance") ? true : false;
  };
  console.log("fsdsfhbsdhbdsfhb", RSA_Validation());
  //Key Replacement
  const KR_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Key Replacement") ? true : false;
  };
  //NCB Protection
  const NCB_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("NCB Protection") ? true : false;
  };
  //Tyre Secure
  const TS_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Tyre Secure") ? true : false;
  };
  const ES_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Engine Protector") ? true : false;
  };
  const CO_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Consumable") ? true : false;
  };
  //Loss of Personal Belongings
  const LOPB_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Loss of Personal Belongings")
      ? true
      : false;
  };
  //Return To Invoice
  const RTI_Validation = () => {
    //getting list of addons applied to quote
    let applicableAddons = !_.isEmpty(TempData?.addons?.applicableAddons)
      ? TempData?.addons?.applicableAddons?.map((x) => x?.name)
      : [];

    return applicableAddons?.includes("Return To Invoice") ? true : false;
  };
  //accessories
  //Electrical
  const ElectricalKit = () => {
    let accessories = !_.isEmpty(TempData?.addons?.accessories)
      ? TempData?.addons?.accessories?.map((x) => x?.name)
      : [];

    return accessories?.includes("Electrical Accessories") ? true : false;
  };
  //Non-Electrical
  const NonElectricalKit = () => {
    let accessories = !_.isEmpty(TempData?.addons?.accessories)
      ? TempData?.addons?.accessories?.map((x) => x?.name)
      : [];

    return accessories?.includes("Non-Electrical Accessories") ? true : false;
  };
  //LPG/CNG
  const Kit = () => {
    let accessories = !_.isEmpty(TempData?.addons?.accessories)
      ? TempData?.addons?.accessories?.map((x) => x?.name)
      : [];

    return accessories?.includes("External Bi-Fuel Kit CNG/LPG")
      ? // TempData?.corporateVehiclesQuoteRequest?.fuelType === "CNG" ||
        // TempData?.corporateVehiclesQuoteRequest?.fuelType === "LPG"
        true
      : false;
  };

  const conOr =
    RTI_Validation() ||
    TS_Validation() ||
    ES_Validation() ||
    CO_Validation() ||
    Kit();

  const conTataAig = TempData?.selectedQuote?.companyAlias === "tata_aig";
  const conChola = TempData?.selectedQuote?.companyAlias === "cholla_mandalam";
  const conRSA = TempData?.selectedQuote?.companyAlias === "royal_sundaram";
  const conFG = TempData?.selectedQuote?.companyAlias === "future_generali";
  const conLGI = TempData?.selectedQuote?.companyAlias === "liberty_videocon";
  const conRaheja = TempData?.selectedQuote?.companyAlias === "raheja";
  const conKotak = TempData?.selectedQuote?.companyAlias === "kotak";
  const conHdfc = TempData?.selectedQuote?.companyAlias === "hdfc_ergo";
  const conSBI = TempData?.selectedQuote?.companyAlias === "sbi";
  const conBAJAJ = TempData?.selectedQuote?.companyAlias === "bajaj_allianz";
  const conDIGIT = TempData?.selectedQuote?.companyAlias === "godigit";
  const conSHRIRAM = TempData?.selectedQuote?.companyAlias === "shriram";
  //Listing addon/Kit Requirements
  const ZD_req = !_.isEmpty(
    _.compact([
      conTataAig,
      conRSA,
      conLGI,
      conFG,
      conHdfc,
      conSBI,
      conBAJAJ,
      conDIGIT,
      conSHRIRAM,
      conKotak,
    ])
  );
  const RSA_req = !_.isEmpty(_.compact([conBAJAJ, conFG]));
  const CO_req = !_.isEmpty(_.compact([conTataAig, conFG]));
  const KR_req = !_.isEmpty(_.compact([conBAJAJ, conFG]));
  const ES_req = !_.isEmpty(_.compact([conTataAig, conLGI, conFG, conBAJAJ]));
  const NCB_req = !_.isEmpty(_.compact([conFG]));
  const TS_req = !_.isEmpty(_.compact([conTataAig, conFG]));
  const RTI_req = !_.isEmpty(_.compact([conFG, conHdfc, conSBI, conTataAig]));
  const LOPB_req = !_.isEmpty(_.compact([conFG, conBAJAJ]));
  //Accessories
  const EA_req = !_.isEmpty(_.compact([conFG]));
  const NEA_req = !_.isEmpty(_.compact([conFG]));
  const kit_req = !_.isEmpty(_.compact([conTataAig, conFG, conKotak, conSBI]));

  const addonFunction = (suppfn, excludeCon, AllowIn) => {
    return (ZD_Validation() || excludeCon) && AllowIn && suppfn;
  };

  const conTS = addonFunction(TS_Validation(), true, TS_req);
  const conES = addonFunction(ES_Validation(), true, ES_req);
  const conCO = addonFunction(CO_Validation(), true, CO_req);
  const conRTI = addonFunction(RTI_Validation(), true, RTI_req);
  const conZD = addonFunction(ZD_Validation(), false, ZD_req);
  const conRS = addonFunction(RSA_Validation(), true, RSA_req);
  const conKR = addonFunction(KR_Validation(), true, KR_req);
  const conNCB = addonFunction(NCB_Validation(), true, NCB_req);
  const conLOPB = addonFunction(LOPB_Validation(), true, LOPB_req);
  const conEAKit = addonFunction(ElectricalKit(), true, EA_req);
  const conNEAKit = addonFunction(NonElectricalKit(), true, NEA_req);
  const conKit = addonFunction(Kit(), true, kit_req);

  const [zd_rti_condition, setZd_rti_condition] = useState({
    ...(conTS && { tyreSecure: true }),
    ...(conES && { engineProtector: true }),
    ...(conCO && { consumables: true }),
    ...(conRTI && { returnToInvoice: true }),
    ...(conZD && { zeroDepreciation: true }),
    ...(conRS && { roadSideAssistance: true }),
    ...(conKR && { keyReplace: true }),
    ...(conNCB && { ncbProtection: true }),
    ...(conLOPB && { lopb: true }),
    ...(conEAKit && { electricleKit: true }),
    ...(conNEAKit && { nonElectricleKit: true }),
    ...(conKit && { externalBiKit: true }),
  });

  const shortHandAddonAndAll = (addon) => {
    switch (addon) {
      case "roadSideAssistance":
        return "Road Side Assistance";
      case "zeroDepreciation":
        return "Zero Depreciation";
      case "imt23":
        return "IMT - 23";
      case "keyReplace":
        return "Key Replacement";
      case "engineProtector":
        return "Engine Protector";
      case "ncbProtection":
        return "NCB Protection";
      case "consumables":
        return "Consumable";
      case "tyreSecure":
        return "Tyre Secure";
      case "returnToInvoice":
        return "Return To Invoice";
      case "lopb":
        return "Loss of Personal Belongings";
      case "emergencyMedicalExpenses":
        return "Emergency Medical Expenses";
      case "electricleKit":
        return "Electrical Accessories";
      case "nonElectricleKit":
        return "Non-Electrical Accessories";
      case "externalBiKit":
        return "External Bi-Fuel Kit CNG/LPG";
      default:
        return "";
    }
  };

  const addonarr = _.compact([
    conTS ? "tyreSecure" : "",
    conES ? "engineProtector" : "",
    conCO ? "consumables" : "",
    conRTI ? "returnToInvoice" : "",
    conZD ? "zeroDepreciation" : "",
    conRS ? "roadSideAssistance" : "",
    conKR ? "keyReplace" : "",
    conNCB ? "ncbProtection" : "",
    conLOPB ? "lopb" : "",
    conEAKit ? "electricleKit" : "",
    conNEAKit ? "nonElectricleKit" : "",
    conKit ? "externalBiKit" : "",
  ]);

  //applicable domains for zd
  const brokerList = true;

  const domainsApplicable =
    process.env.REACT_APP_API_BASE_URL === "https://apimotor.fynity.in/api" ||
    brokerList;

  const ZD_preview_conditions =
    //applicable addons check
    !_.isEmpty(addonarr) &&
    //when previous insurer is present and not new and is not third party
    TempData?.corporateVehiclesQuoteRequest?.previousInsurer &&
    TempData?.corporateVehiclesQuoteRequest?.previousInsurer !== "NEW" &&
    TempData?.selectedQuote?.policyType !== "Third Party" &&
    TempData?.corporateVehiclesQuoteRequest?.previousPolicyType !==
      "Not sure" &&
    //Domain specific block
    domainsApplicable &&
    //product specific block
    props?.TypeReturn(props?.type) !== null &&
    true;

  /*-------x------- Handle Addon declaration ------x------*/

  const [finalSubmit, setFinalSubmit] = useState(true);

  const nomineeCheckCon =
    (PACondition && props?.fields.includes("cpaOptOut")) ||
    "" ||
    PolicyCon ||
    "";
  const finalSubmitCheck = [
    formOwner,
    Number(TempData?.ownerTypeId) !== 2 && conditionChk && formNominee,
    formVehicle,
    nomineeCheckCon && formPrepolicy,
  ];
  useEffect(() => {
    if (_.compact(finalSubmitCheck).every((elem) => elem === "summary")) {
      setFinalSubmit(true);
    } else {
      setFinalSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOwner, formNominee, formVehicle, formPrepolicy]);

  const PolicyId = TempData?.selectedQuote?.policyId || "";

  const onFinalSubmit = () => {
    if (PolicyId && companyAlias && props?.enquiry_id) {
      // navigator && navigator?.vibrate && navigator.vibrate([100, 0, 50]);
      console.log("submitted");
      if (
        TempData?.userProposal?.isInspectionDone === "Y" &&
        props?.TypeReturn(props?.type) !== "bike"
      ) {
        setShow1(true);
      } else {
        //pre-submit conditions here
        //Magma iib
        if (
          Number(TempData?.quoteLog?.icId) === 41 &&
          TempData?.userProposal?.iibresponserequired === "Y"
        ) {
          //when proposal is previously submitted.
          if (
            ["Payment Initiated", "payment failed"].includes(
              ["payment failed"].includes(
                TempData?.journeyStage?.stage.toLowerCase()
              )
                ? TempData?.journeyStage?.stage.toLowerCase()
                : TempData?.journeyStage?.stage
            )
          ) {
            //opening payment modal.
            setShow1(true);
          } else {
            setShow2(true);
          }
        }
        // journey without presubmit conditions
        else {
          //when proposal is previously submitted.
          if (
            ["Payment Initiated", "payment failed"].includes(
              ["payment failed"].includes(
                TempData?.journeyStage?.stage.toLowerCase()
              )
                ? TempData?.journeyStage?.stage.toLowerCase()
                : TempData?.journeyStage?.stage
            ) &&
            TempData?.userProposal?.policyStartDate &&
            new Date(
              TempData?.userProposal?.policyStartDate.split("-")[2],
              TempData?.userProposal?.policyStartDate.split("-")[1] * 1 - 1,
              TempData?.userProposal?.policyStartDate.split("-")[0]
            )
              .setHours(0, 0, 0, 0)
              .valueOf() >= new Date().setHours(0, 0, 0, 0).valueOf()
          ) {
            //opening payment modal.
            setShow1(true);
          } else if (
            companyAlias === "royal_sundaram" &&
            rsKycStatus?.kyc_status
          ) {
            setShow1(true);
          } else {
            dispatch(
              SubmitData(
                {
                  lastProposalModifiedTime: TempData?.lastProposalModifiedTime,
                  policyId: PolicyId,
                  companyAlias: companyAlias,
                  userProductJourneyId: props?.enquiry_id,
                  enquiryId: props?.enquiry_id,
                  ...(ZD_preview_conditions && {
                    declaredAddons: zd_rti_condition,
                  }),
                  ...(((!isNcbApplicable &&
                    TempData?.corporateVehiclesQuoteRequest
                      ?.previousPolicyExpiryDate &&
                    TempData?.corporateVehiclesQuoteRequest
                      ?.previousPolicyExpiryDate !== "New" &&
                    props?.fields?.includes("ncb") &&
                    differenceInDays(
                      toDate(moment().format("DD-MM-YYYY")),
                      toDate(
                        TempData?.corporateVehiclesQuoteRequest
                          ?.previousPolicyExpiryDate
                      )
                    ) < 90) ||
                    props?.fields?.includes("cpaOptIn")) && {
                    proposalSideCardUpdate: "Y",
                  }),
                  ...(TempData?.selectedQuote?.isRenewal === "Y" && {
                    is_renewal: "Y",
                  }),
                },
                props?.TypeReturn(props?.type)
              )
            );
          }
          //proposal submit
        }
      }
    } else {
      swal(
        "Error",
        `${`Trace ID:- ${
          props?.enquiry_id
        }.\n Error Message:- ${"Server Error"}`}`,
        "error"
      );
    }
  };
  /*--------------Pre-Submit--------------*/
  //Magma - iib
  const selection = (action) => {
    dispatch(
      SubmitData(
        {
          lastProposalModifiedTime: TempData?.lastProposalModifiedTime,
          policyId: PolicyId,
          companyAlias: companyAlias,
          userProductJourneyId: props?.enquiry_id,
          enquiryId: props?.enquiry_id,
          iibncb: action ? true : false,
          ...(ZD_preview_conditions && { declaredAddons: zd_rti_condition }),
        },
        props?.TypeReturn(props?.type)
      )
    );
  };
  /*-----x--------Pre-Submit--------x-----*/

  /*-----Finsal Validation-----*/
  const FinsallValidation = () => {
    swal({
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter PAN",
          type: "text",
        },
      },
      title: "Please Note",
      text: "PAN Number is required",
      showCancelButton: true,
      closeOnClickOutside: false,
    }).then((inputValue) => {
      if (inputValue === "" || inputValue === null) {
        swal("PAN is required!").then(() => {
          //recursion on failure
          FinsallValidation();
        });
      }
      if (
        inputValue &&
        inputValue.match(
          /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/
        )
      ) {
        dispatch(
          Finsall({
            enquiryId: props?.enquiry_id,
            panNumber: inputValue,
          })
        );
      } else {
        inputValue &&
          swal("Invalid PAN Number!").then(() => {
            //recursion on failure
            FinsallValidation();
          });
      }
      setDropout(true);
    });
  };
  /*--x--Finsal Validation--x--*/

  /*--------------OTP--------------*/
  //Payment
  const payment = (isFinsal) => {
    if (props?.enquiry_id) {
      if (
        Number(TempData?.quoteLog?.icId) !== 32 &&
        Number(TempData?.quoteLog?.icId) !== 35 &&
        process.env.REACT_APP_BROKER !== "RB"
      ) {
        //Normal PG Journey
        if (!isFinsal) {
          !_.isEmpty(TempData?.agentDetails) &&
          !_.isEmpty(
            TempData?.agentDetails.find((item) => item?.category === "Essone")
          )
            ? swal({
                title: "Confirm Action",
                text: `Are you sure you want to make the payment?`,
                icon: "info",
                buttons: {
                  cancel: "Cancel",
                  catch: {
                    text: "Confirm",
                    value: "confirm",
                  },
                },
                dangerMode: true,
              }).then((caseValue) => {
                switch (caseValue) {
                  case "confirm":
                    history.push(
                      `/${props?.type}/payment-gateway?enquiry_id=${
                        props?.enquiry_id
                      }${props?.token ? `&token=${props?.token}` : ``}${
                        TempData?.userProposal?.isBreakinCase
                          ? `&breakin=${Encrypt(true)}`
                          : ``
                      }${props?.icr ? `&icr=${props?.icr}` : ``}`
                    );
                    break;
                  default:
                }
              })
            : history.push(
                `/${props?.type}/payment-gateway?enquiry_id=${
                  props?.enquiry_id
                }${props?.token ? `&token=${props?.token}` : ``}${
                  props?.typeId ? `&typeid=${props?.typeId}` : ``
                }${
                  TempData?.userProposal?.isBreakinCase
                    ? `&breakin=${Encrypt(true)}`
                    : ``
                }${
                  props?.journey_type
                    ? `&journey_type=${props?.journey_type}`
                    : ``
                }${props?.icr ? `&icr=${props?.icr}` : ``}`
              );
        }
        //Finsal
        if (isFinsal) {
          if (TempData?.userProposal?.panNumber) {
            dispatch(
              Finsall({
                enquiryId: props?.enquiry_id,
                panNumber: TempData?.userProposal?.panNumber,
              })
            );
            setDropout(true);
          } else {
            setShow1(false);
            swal({
              content: {
                element: "input",
                attributes: {
                  placeholder: "Enter PAN",
                  type: "text",
                },
              },
              title: "Please Note",
              text: "PAN Number is required",
              showCancelButton: true,
              closeOnClickOutside: false,
            }).then((inputValue) => {
              if (inputValue === "" || inputValue === null) {
                swal("PAN is required!").then(() => {
                  //recursion on failure
                  FinsallValidation();
                });
              }
              if (
                inputValue &&
                inputValue.match(
                  /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/
                )
              ) {
                dispatch(
                  Finsall({
                    enquiryId: props?.enquiry_id,
                    panNumber: inputValue,
                  })
                );
              } else {
                inputValue &&
                  swal("Invalid PAN Number!").then(() => {
                    //recursion on failure
                    FinsallValidation();
                  });
              }
              setDropout(true);
            });
          }
        }
      } else {
        setShow1(true);
        dispatch(
          ShareQuote(
            {
              enquiryId: props?.enquiry_id,
              notificationType: "all",
              domain: `http://${window.location.hostname}`,
              type: "otpSms",
              applicableNcb:
                TempData?.corporateVehiclesQuoteRequest?.applicableNcb,
              mobileNo: CardData?.owner?.mobileNumber,
              policyEndDate: TempData?.selectedQuote?.policyEndDate,
              policyStartDate: TempData?.selectedQuote?.policyStartDate,
              premiumAmount: TempData?.quoteLog?.finalPremiumAmount,
              productName: TempData?.selectedQuote?.productName,
              registrationNumber: CardData?.vehicle?.vehicaleRegistrationNumber,
              emailId: CardData?.owner?.email,
            },
            true
          )
        );
      }
    } else {
      swal("Error", "No enquiry id found", "error");
    }
  };

  //clear previous success
  useEffect(() => {
    dispatch(clr("share"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (share) {
      setShow(true);
    }

    return () => {
      dispatch(clr("share"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [share]);

  //finsall redirection
  useEffect(() => {
    if (finUrl?.redirectionLink) {
      reloadPage(finUrl?.redirectionLink);
    }
  }, [finUrl?.redirectionLink]);

  //on otp enter
  const otpSuccess = () => {
    if (props?.enquiry_id) {
      !_.isEmpty(TempData?.agentDetails) &&
      !_.isEmpty(
        TempData?.agentDetails.find((item) => item?.category === "Essone")
      )
        ? swal({
            title: "Confirm Action",
            text: `Are you sure you want to make the payment?`,
            icon: "info",
            buttons: {
              cancel: "Cancel",
              catch: {
                text: "Confirm",
                value: "confirm",
              },
            },
            dangerMode: true,
          }).then((caseValue) => {
            switch (caseValue) {
              case "confirm":
                history.push(
                  `/${props?.type}/payment-gateway?enquiry_id=${
                    props?.enquiry_id
                  }${props?.token ? `&token=${props?.token}` : ``}${
                    TempData?.userProposal?.isBreakinCase
                      ? `&breakin=${Encrypt(true)}`
                      : ``
                  }${props?.icr ? `&icr=${props?.icr}` : ``}`
                );
                break;
              default:
            }
          })
        : history.push(
            `/${props?.type}/payment-gateway?enquiry_id=${props?.enquiry_id}${
              props?.token ? `&token=${props?.token}` : ``
            }${props?.typeId ? `&typeid=${props?.typeId}` : ``}${
              TempData?.userProposal?.isBreakinCase
                ? `&breakin=${Encrypt(true)}`
                : ``
            }${
              props?.journey_type ? `&journey_type=${props?.journey_type}` : ``
            }${props?.icr ? `&icr=${props?.icr}` : ``}`
          );
    } else {
      swal("Error", "No enquiry id found", "error");
    }
  };

  /*------x-------OTP--------x-----*/

  const onSubmitFunc = () => {
    let Data = { ...submit, enquiry_id: props?.enquiry_id };
    // lead trigger after submit & Email trigger
    !["Payment Initiated", "payment failed"].includes(
      ["payment failed"].includes(TempData?.journeyStage?.stage.toLowerCase())
        ? TempData?.journeyStage?.stage.toLowerCase()
        : TempData?.journeyStage?.stage
    ) &&
      (submit?.is_breakin !== "Y" ||
        props?.TypeReturn(props?.type) === "bike") &&
      process.env.REACT_APP_BROKER !== "ACE" &&
      dispatch(
        ShareQuote({
          enquiryId: props?.enquiry_id,
          notificationType: "email",
          domain: `http://${window.location.hostname}`,
          type: "proposalCreated",
          emailId: owner?.email,
          firstName: owner?.firstName,
          lastName: owner?.lastName,
          productName: TempData?.selectedQuote?.productName,
          link: window.location.href.replace(/proposal-page/g, "proposal-page"),
          logo: props?.getLogoUrl(),
        })
      );
    dispatch(Lead({ enquiryId: props?.enquiry_id, leadStageId: 3 }));
    if (
      submit?.is_breakin &&
      submit?.is_breakin === "Y" &&
      !props?.breakinCase &&
      props?.TypeReturn(props?.type) !== "bike"
    ) {
      history.push(
        `/${props?.type}/successful?enquiry_id=${
          props?.enquiry_id
        }&inspection_no=${submit?.inspection_number}${
          dropout ? `&dropout=${Encrypt(true)}` : ``
        }${props?.token ? `&token=${props?.token}` : ``}&IC=${
          TempData?.quoteLog?.icAlias
        }${props?.typeId ? `&typeid=${props?.typeId}` : ``}${
          props?.journey_type ? `&journey_type=${props?.journey_type}` : ``
        }${
          submit?.finalPayableAmount * 1
            ? `&xmc=${window.btoa(submit?.finalPayableAmount)}`
            : ``
        }`
      );
    } else {
      setShow1(true);
    }
  };

  // useEffect(() => {
  //   if(companyAlias === "tata_aig" && verifyCkycnum?.verification_status) {

  //   }
  // },[verifyCkycnum])

  //onSuccess
  useEffect(() => {
    if (submit) {
      if (companyAlias === "tata_aig" && props?.fields?.includes("ckyc")) {
        if (submit?.verification_status) {
          swal("Success", "CKYC verified.", "success").then(() => onSubmitFunc());
          
        } else if (!submit?.verification_status && submit?.otp_id) {
          setotp_id(submit?.otp_id);
          setShow(true);
        } else if (!submit?.verification_status && !submit?.otp_id) {
          swal("Error", submit?.message, "error");
        }
      } else {
        !!submit?.kyc_status && swal("Success", "CKYC verified.", "success");
        onSubmitFunc()
        TempData?.selectedQuote?.companyAlias === "royal_sundaram" &&
          setResubmit(true);
      }
    }

    return () => {
      dispatch(clear("submit"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  //to store checkbox value
  const [terms_condition, setTerms_condition] = useState(
    process.env?.REACT_APP_BROKER !== "" ? true : false
  );

  //T&C
  const BrokerName = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "Ola Financial Services Private Limited";
      case "UNILIGHT":
        return "UNILIGHT Insurance Brokers Limited";
      case "CP":
        return "Compare Policy Private Limited";
      case "FYNTUNE":
        return "Fyntune Solutions Pvt. Ltd.";
      case "ABIBL":
        return "Aditya Birla Insurance Broker Limited";
      case "GRAM":
        return "GramCover Insurance Brokers Private Limited";
      case "ACE":
        return "ACE Insurance Broker Pvt. Ltd.";
      case "SRIYAH":
        return "Sriyah Insurance Brokers Pvt. Ltd";
      case "PINC":
        return "Pioneer Insurance & Reinsurance Brokers Pvt. Ltd.";
      case "EPOCH":
        return "Epoch Insurance Brokers Pvt. Ltd.";
      case "RB":
        return "D2C Insurance Broking Pvt. Ltd.";
      case "SPA":
        return "SPA Insurance Broking Services Ltd.";
      case "BIMA":
        return "SS Insurance Broking Services Ltd.";
      case "BAJAJ":
        return "Bajaj Capital Insurance Broking Limited";
      case "ANALAH":
        return "ANALAH Insurance Broking Limited";
      case "UIB":
        return "UIB Insurance Brokers (India) Pvt. Ltd.";
      case "SRIDHAR":
        return "Sridhar Insurance Brokers (P) Ltd.";
      case "SHREE":
        return "LAKSHMISHREE CAPITAL SERVICES PRIVATE LIMITED.";
      case "POLICYERA":
        return "Policy Era Insurance Broking LLP.";
      case "TATA":
        return "Tata Motors Insurance Broking And Advisory Services Limited.";
      default:
        return "Ola Financial Services Private Limited";
    }
  };

  //addon block condition
  const disableProceed =
    !(
      _.isEmpty(zd_rti_condition) ||
      !Object.values(zd_rti_condition)?.includes(false) ||
      (process.env.REACT_APP_PROD !== "YES" &&
        TempData?.selectedQuote?.isBreakinApplicable)
    ) && process.env.REACT_APP_PROD !== "YES";

  //button condition check
  const btnColour =
    terms_condition &&
    !props?.overTime &&
    ((props?.breakinCase && TempData?.userProposal?.isInspectionDone === "Y") ||
      props?.TypeReturn(props?.type) === "bike" ||
      !props?.breakinCase) &&
    (_.isEmpty(zd_rti_condition) ||
      !Object.values(zd_rti_condition)?.includes(false) ||
      (process.env.REACT_APP_PROD !== "YES" &&
        TempData?.selectedQuote?.isBreakinApplicable) ||
      "")
      ? Theme?.proposalProceedBtn?.hex2
        ? Theme?.proposalProceedBtn?.hex2
        : "#4ca729"
      : "#787878";

  const btnCon =
    (terms_condition &&
    !props?.overTime &&
    ((props?.breakinCase && TempData?.userProposal?.isInspectionDone === "Y") ||
      props?.TypeReturn(props?.type) === "bike" ||
      !props?.breakinCase) &&
    (_.isEmpty(zd_rti_condition) ||
      !Object.values(zd_rti_condition)?.includes(false) ||
      (process.env.REACT_APP_PROD !== "YES" &&
        TempData?.selectedQuote?.isBreakinApplicable) ||
      "")
      ? false
      : true) || submitProcess
      ? true
      : false;

  const ncbCheck =
    TempData?.corporateVehiclesQuoteRequest?.previousPolicyType &&
    TempData?.selectedQuote?.policyType !== "Third Party" &&
    TempData?.corporateVehiclesQuoteRequest?.previousNcb
      ? TempData?.corporateVehiclesQuoteRequest?.previousNcb * 1
        ? `I confirm that NCB percentage declared is correct and no claims were made in the previous policy.`
        : ""
      : "" || "";

  const FinalSubmit = (
    <Row style={{ padding: "10.5px" }}>
      <Col xl="12" lg="12" md="12" sm="12">
        <SubmitDiv>
          <label className="checkbox-container">
            <input
              className="bajajCheck"
              id="checkboxId"
              defaultChecked={false}
              name="accept"
              type="checkbox"
              disabled={submitProcess}
              value={terms_condition}
              checked={terms_condition ? true : false}
              onChange={(e) => {
                process.env.REACT_APP_BROKER !== ""
                  ? setTerms_condition(e.target.checked)
                  : //for OLA , A confirmation is required before checking the T&C
                  e.target.checked
                  ? swal({
                      title: "Confirm Action",
                      text: `Are you sure you want to submit the proposal?`,
                      icon: "info",
                      buttons: {
                        cancel: "Cancel",
                        catch: {
                          text: "Confirm",
                          value: "confirm",
                        },
                      },
                      dangerMode: true,
                    }).then((caseValue) => {
                      switch (caseValue) {
                        case "confirm":
                          setTerms_condition(true);
                          break;
                        default:
                      }
                    })
                  : setTerms_condition(e.target.checked);
              }}
            />
            <span className="checkmark"></span>
          </label>
          <p
            className="privacyPolicy"
            style={{ cursor: "pointer" }}
            onClick={() => document.getElementById("checkboxId").click()}
          >
            {`${
              theme_conf?.broker_config?.p_declaration ||
              `I confirm all the details shared are correct and accurate as per my knowledge.
			        I agree with all the T&C and my vehicle has a valid PUC certificate.
			        I also declare that the information provided above is true and accept that if it is found to be false, it may impact claims.
			        I agree any changes to the details post payment might require additional payment.
             ${BrokerName()} (including its representatives) shall not be held liable for any changes due to incorrect information.`
            }
              ${
                TempData?.gcvCarrierType === "PRIVATE"
                  ? `Valid documents supporting Private Carrier Cover required during claims.
                     Otherwise, it may lead to claim rejection by insurer.`
                  : ""
              }
              ${
                TempData?.corporateVehiclesQuoteRequest?.previousNcb
                  ? ncbCheck
                  : ""
              }
              `}
          </p>
        </SubmitDiv>
      </Col>
      {ZD_preview_conditions &&
        addonarr?.map((item) => (
          <Col xl="12" lg="12" md="12" sm="12">
            <SubmitDiv>
              <label className="checkbox-container">
                <input
                  className="bajajCheck"
                  defaultChecked={false}
                  name={item}
                  disabled={submitProcess}
                  type="checkbox"
                  value={zd_rti_condition[item] ? true : false}
                  checked={zd_rti_condition[item] ? true : false}
                  onChange={(e) => {
                    setZd_rti_condition({
                      ...zd_rti_condition,
                      [item]: e.target.checked,
                    });
                  }}
                />
                <span className="checkmark"></span>
              </label>
              <p className="privacyPolicy">{`I confirm that ${shortHandAddonAndAll(
                item
              )} 
 was available in my previous policy.`}</p>
            </SubmitDiv>
          </Col>
        ))}
      <Col
        sm="12"
        md="12"
        lg="12"
        xl="12"
        className="d-flex justify-content-center"
      >
        {disableProceed ? (
          <SubmitDiv className="ElemFade">
            <p
              style={{
                fontSize: "15px",
                color: "#D80000 ",
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              {
                "Vehicle inspection required to take this add on. Break in journey not allowed."
              }
            </p>
          </SubmitDiv>
        ) : (
          <Button
            type="submit"
            buttonStyle="outline-solid"
            hex1={btnColour}
            hex2={btnColour}
            borderRadius="5px"
            color="white"
            disabled={btnCon}
            onClick={onFinalSubmit}
            shadow={"none"}
          >
            <text
              style={{
                fontSize: lessthan768 ? "12px" : "15px",
                padding: "-20px",
                margin: "-20px -5px -20px -5px",
                fontWeight: "400",
              }}
            >
              {props?.overTime ? (
                ` Please try again in ${
                  Math.floor(props?.overTime / 60) +
                  " Min : " +
                  Math.floor(props?.overTime % 60)
                } Sec`
              ) : submitProcess ? (
                "Processing "
              ) : !props?.breakinCase ? (
                "Review & Submit"
              ) : (props?.breakinCase &&
                  TempData?.userProposal?.isInspectionDone === "Y") ||
                props?.TypeReturn(props?.type) === "bike" ? (
                "Proceed"
              ) : (
                <text>
                  <i className="fa fa-info-circle" /> Inspection Pending
                </text>
              )}
              {submitProcess ? (
                <Spinner animation="grow" variant="light" size="sm" />
              ) : (
                <noscript />
              )}
            </text>
          </Button>
        )}
      </Col>
    </Row>
  );
  /*---------------x----Review & Submit Section End----x--------------*/

  /*---------------------------card titles------------------------*/
  //Card Summary Title Function
  const titleFn = (titleName, stateName) => {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <Label style={{ width: "95%" }}>{titleName}</Label>
        {(!props?.breakinCase || props?.TypeReturn(props?.type) === "bike") &&
          !props?.icr &&
          !rsKycStatus?.kyc_status && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "-2px",
              }}
            >
              <Btn
                size="sm"
                style={{ background: "transparent", border: "none" }}
                variant="light"
                onClick={() => {
                  if (
                    ["Payment Initiated", "payment failed"].includes(
                      ["payment failed"].includes(
                        TempData?.journeyStage?.stage.toLowerCase()
                      )
                        ? TempData?.journeyStage?.stage.toLowerCase()
                        : TempData?.journeyStage?.stage
                    )
                  ) {
                    swal({
                      title: "Confirm Action",
                      text: `Payment status is Incomplete. To edit the Proposal an update is required.`,
                      icon: "info",
                      buttons: {
                        cancel: "Cancel",
                        catch: {
                          text: "Confirm",
                          value: "confirm",
                        },
                      },
                      dangerMode: true,
                    }).then((caseValue) => {
                      switch (caseValue) {
                        case "confirm":
                          return props?.GenerateDulicateEnquiry();
                          break;
                        default:
                      }
                    });
                  } else {
                    navigator &&
                      navigator?.vibrate &&
                      navigator.vibrate([100, 0, 50]);
                    stateName("form");
                    setDropout(false);
                  }
                }}
              >
                <i
                  class="fa fa-pencil-square-o"
                  aria-hidden="true"
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "19px",
                  }}
                />
              </Btn>
            </div>
          )}
      </div>
    );
  };
  //owner
  const titleOwnerSummary = titleFn("Vehicle Owner Details", setFormOwner);
  //nominee
  const titleNomineeSummary = titleFn(
    process.env.REACT_APP_BROKER === "OLA" &&
      TempData?.corporateVehiclesQuoteRequest?.journeyType === "embeded-excel"
      ? "Nominee Details"
      : "Nominee Details",
    setFormNominee
  );
  //vehicle
  const titleVehicleSummary = titleFn("Vehicle Details", setFormVehicle);
  //pre-policy
  const titlePrepolicySummary = titleFn(
    PolicyCon && PACondition && props?.fields?.includes("cpaOptOut")
      ? "Previous Policy & CPA Details"
      : PolicyCon
      ? "Previous Policy Details"
      : "CPA Details",
    setFormPrepolicy
  );

  /*--------------- Handle-page-scroll -------------*/
  //using html to scroll instead of refs
  useEffect(() => {
    if (formOwner === "form") {
      !dropout &&
        (lessthan768 ? scrollToTop() : scrollToTargetAdjusted("owner", 45));
    }
    if (formNominee === "form") {
      !dropout && scrollToTargetAdjusted("nominee", 45);
    }
    if (formVehicle === "form") {
      !dropout && scrollToTargetAdjusted("vehicle", 45);
    }
    if (formPrepolicy === "form") {
      !dropout && scrollToTargetAdjusted("prepolicy", 45);
    }
    //scroll to t&c checkbox
    if (_.compact(finalSubmitCheck).every((elem) => elem === "summary")) {
      process.env.REACT_APP_BROKER !== "OLA" &&
        !dropout &&
        scrollToTargetAdjusted("review-submit");
    }

    //eslint-disable-next-line
  }, [formOwner, formNominee, formVehicle, formPrepolicy]);
  /*-------x------- Handle-page-scroll ------x------*/

  /*--------------- Handle-dropout-fill -------------*/

  useLayoutEffect(() => {
    if (dropout && !_.isEmpty(CardData)) {
      if (!_.isEmpty(CardData?.owner)) {
        // setTimeout(() => {
        setOwner(CardData?.owner);
        setFormOwner("summary");
        // }, 1);
      }
      if (!_.isEmpty(CardData?.nominee)) {
        setTimeout(() => {
          setNominee(CardData?.nominee);
          setFormNominee("summary");
        }, 3);
      }
      if (!_.isEmpty(CardData?.vehicle)) {
        setTimeout(() => {
          setVehicle(CardData?.vehicle);
          setFormVehicle("summary");
        }, 5);
      }
      if (!_.isEmpty(CardData?.prepolicy)) {
        setTimeout(() => {
          setPrepolicy(CardData?.prepolicy);
          setFormPrepolicy("summary");
        }, 7);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CardData, dropout]);
  /*-------x------- Handle-dropout-fill ------x------*/

  /*--------------- Handle-step-error -------------*/
  useEffect(() => {
    if (error) {
      swal({
        title: "Error",
        text: props?.enquiry_id
          ? `${`Trace ID:- ${props?.enquiry_id}.\n Error Message:- ${error}`}`
          : error,
        icon: "error",
        buttons: {
          cancel: "Dismiss",
          ...(errorSpecific && {catch: {
            text: "See more details",
            value: "confirm",
          }}),
        },
        dangerMode: true,
      }).then((caseValue) => {
        switch (caseValue) {
          case "confirm":
            swal(
              "Error",
              props?.enquiry_id
                ? `${`Trace ID:- ${props?.enquiry_id}.\n Error Message:- ${errorSpecific}`}`
                : errorSpecific,
              "error"
            );
            break;
          default:
        }
        //step error flow 
        if (Number(TempData?.ownerTypeId) !== 2 && conditionChk) {
          //general flow
          if (formOwner === "summary" && formNominee === "form") {
            setFormOwner("form");
          }
          if (formNominee === "summary" && formVehicle === "form") {
            setFormNominee("form");
          }
          if (formVehicle === "summary" && formPrepolicy === "form") {
            setFormVehicle("form");
          }
          if (
            _.compact(finalSubmitCheck).every((elem) => elem === "summary") &&
            (PolicyCon || (PACondition && props?.fields?.includes("cpaOptOut")))
          ) {
            setFormPrepolicy("form");
          }
        } else {
          //flow without nominee
          if (formOwner === "summary" && formVehicle === "form") {
            setFormOwner("form");
          }
          if (formVehicle === "summary" && formPrepolicy === "form") {
            setFormVehicle("form");
          }
          if (
            _.compact(finalSubmitCheck).every((elem) => elem === "summary") &&
            (PolicyCon || (PACondition && props?.fields?.includes("cpaOptOut")))
          ) {
            setFormPrepolicy("form");
          }
        }
        //reloading page in certain case
        if (
          error === "Payment Link Already Generated..!" ||
          error === "Payment Initiated"
        ) {
          dispatch(props?.DuplicateEnquiryId({ enquiryId: props?.enquiry_id }));
        }
        //redirecting user if payment is already done for the proposal
        if (error === "Transaction Already Completed") {
          reloadPage(
            `${window.location.protocol}//${window.location.host}${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ``
            }/payment-success${
              props?.enquiry_id ? `?enquiry_id=${props?.enquiry_id}` : ``
            }`
          );
        }
        if (
          error ===
          "Proposal integrity check failed. You will be redirected to quote page."
        ) {
          reloadPage(window.location.href.replace(/proposal-page/g, "quotes"));
        }
        if (error === "Proposal integrity check failed.") {
          reloadPage(window.location.href);
        }
      });
    }

    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  //Open particular card on submit error
  useEffect(() => {
    if (error_other) {
      //Tata aig reset to first card
      (error_other.includes("Try With CIN Number in Case of Company") ||
        error_other.includes(
          "Please upload photograph to complete proposal."
        ) || 
        error_other.includes(
          "Ckyc verification failed, please try using a different ID."
        )
        ) &&
        setFormOwner("form");
        // reset to document upoload
        if(error_other.includes(
          "CKYC verification failed. Try other method"
        )){
          setuploadFile(true)
          setFormOwner("form"); 
        }
      if (
        error_other ===
        "Proposal integrity check failed. You will be redirected to quote page."
      ) {
        reloadPage(window.location.href.replace(/proposal-page/g, "quotes"));
      }
      if (error_other === "Proposal integrity check failed.") {
        reloadPage(window.location.href);
      }
    }
  }, [error_other]);
  /*-------x------- Handle-step-error ------x------*/

  /*--------------- Handle redirectional-edit -------------*/
  useEffect(() => {
    if (props?.openTo) {
      //set info
      if (!_.isEmpty(CardData?.owner)) {
        setOwner(CardData?.owner);
      }
      if (!_.isEmpty(CardData?.nominee)) {
        setNominee(CardData?.nominee);
      }
      if (!_.isEmpty(CardData?.vehicle)) {
        setVehicle(CardData?.vehicle);
      }
      if (!_.isEmpty(CardData?.prepolicy)) {
        setPrepolicy(CardData?.prepolicy);
      }
      //set form
      setTimeout(() => {
        switch (props?.openTo) {
          case "owner":
            setFormOwner("form");
            setFormNominee("hidden");
            setFormVehicle("hidden");
            setFormPrepolicy("hidden");
            scrollToTargetAdjusted("owner", 45);
            break;
          case "vehicle":
            setFormOwner("summary");
            setFormNominee("summary");
            setFormVehicle("form");
            setFormPrepolicy("hidden");
            scrollToTargetAdjusted("vehicle", 45);
            break;
        }
      }, 200);
    }
  }, [props?.openTo]);
  /*-------x------- Handle redirectional-edit ------x------*/

  /*-----Handling non-availablity of Multi-Year-CPA-----*/
  useEffect(() => {
    if (
      !_.isEmpty(Tenure) &&
      !TempData?.selectedQuote?.multiYearCpa * 1 &&
      !cpaSet &&
      !PACondition
    ) {
      let data1 = {
        isProposal: true,
        enquiryId: TempData?.enquiry_id || props?.enquiry_id,
        addonData: {
          compulsory_personal_accident: [
            {
              reason:
                "I have another motor policy with PA owner driver cover in my name",
            },
          ],
        },
      };
      dispatch(SaveAddonsData(data1, true));
      // swal({
      //   title: "Confirm Action",
      //   text: `${
      //     props?.TypeReturn(props?.type) === "bike" ? "5" : "3"
      //   } Years CPA is not available in this quote. Would you like to OPT for 1 Year CPA ?`,
      //   icon: "info",
      //   buttons: {
      //     catch: {
      //       text: "Yes",
      //       value: "confirm",
      //     },
      //     No: {
      //       text: "No",
      //       value: "No",
      //     },
      //   },
      //   dangerMode: true,
      //   closeOnClickOutside: false,
      // }).then((caseValue) => {
      //   switch (caseValue) {
      //     case "confirm":
      //       var data1 = {
      //         enquiryId: TempData?.enquiry_id || props?.enquiry_id,
      //         addonData: {
      //           compulsory_personal_accident: [
      //             { name: "Compulsory Personal Accident" },
      //           ],
      //         },
      //       };
      //       dispatch(SaveAddonsData(data1, true));
      //       break;
      //     case "No":
      //       var data1 = {
      //         enquiryId: TempData?.enquiry_id || props?.enquiry_id,
      //         addonData: {
      //           compulsory_personal_accident: [
      //             {
      //               reason:
      //                 "I have another motor policy with PA owner driver cover in my name",
      //             },
      //           ],
      //         },
      //       };
      //       dispatch(SaveAddonsData(data1, true));
      //       break;
      //     default:
      //   }
      // });
    } else if (
      _.isEmpty(Tenure) &&
      !_.isEmpty(OwnerPA) &&
      !TempData?.selectedQuote?.compulsoryPaOwnDriver * 1 &&
      !cpaSet &&
      !PACondition
    ) {
      let data1 = {
        isProposal: true,
        enquiryId: TempData?.enquiry_id || props?.enquiry_id,
        addonData: {
          compulsory_personal_accident: [
            {
              reason:
                "I have another motor policy with PA owner driver cover in my name",
            },
          ],
        },
      };
      dispatch(SaveAddonsData(data1, true));
      // swal({
      //   title: "Confirm Action",
      //   text: ` One Year CPA is not available in this quote. Would you like to OPT for ${
      //     props?.TypeReturn(props?.type) === "bike" ? "5" : "3"
      //   } Years CPA ?`,
      //   icon: "info",
      //   buttons: {
      //     catch: {
      //       text: "Yes",
      //       value: "confirm",
      //     },
      //     No: {
      //       text: "No",
      //       value: "No",
      //     },
      //   },
      //   dangerMode: true,
      //   closeOnClickOutside: false,
      // }).then((caseValue) => {
      //   switch (caseValue) {
      //     case "confirm":
      //       var data1 = {
      //         enquiryId: TempData?.enquiry_id || props?.enquiry_id,
      //         addonData: {
      //           compulsory_personal_accident: [
      //             {
      //               name: "Compulsory Personal Accident",
      //               tenure: props?.TypeReturn(props?.type) === "bike" ? 5 : 3,
      //             },
      //           ],
      //         },
      //       };
      //       dispatch(SaveAddonsData(data1, true));
      //       break;
      //     case "No":
      //       var data1 = {
      //         enquiryId: TempData?.enquiry_id || props?.enquiry_id,
      //         addonData: {
      //           compulsory_personal_accident: [
      //             {
      //               reason:
      //                 "I have another motor policy with PA owner driver cover in my name",
      //             },
      //           ],
      //         },
      //       };
      //       dispatch(SaveAddonsData(data1, true));
      //       break;
      //     default:
      //   }
      // });
    }
  }, [Additional]);
  /*--x--Handling non-availablity of Multi-Year-CPA--x--*/
  return (
    <>
      <div>
        {/*--------------------Proposal Form-----------------------------------*/}
        <Card
          title={
            formOwner === "form" ? (
              <Label>Vehicle Owner Details</Label>
            ) : (
              titleOwnerSummary
            )
          }
          removeBottomHeader={true}
          backgroundColor={
            formOwner === "hidden" ? `` : formOwner === "summary" ? 1 : 2
          }
          paddingTop={formOwner === "summary" ? "8px" : "4.5px"}
          borderRadius={
            formOwner === "hidden" ? "5px 5px 5px 5px" : "5px 5px 0px 0px"
          }
          image={
            props?.TypeReturn(props?.type) === "bike"
              ? `${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : "" || ""
                }/assets/images/vehicle/bike3.png`
              : props?.TypeReturn(props?.type) === "car"
              ? `${
                  process.env.REACT_APP_BASENAME !== "NA"
                    ? `/${process.env.REACT_APP_BASENAME}`
                    : "" || ""
                }/assets/images/taxi-car1.png`
              : getLogoCvType(Number(TempData?.productSubTypeId))
          }
          imageStyle={
            lessthan768
              ? {
                  position: "relative",
                  top: "1px",
                  left: "18px",
                }
              : {
                  position: "relative",
                  top: "-4px",
                  left: "18px",
                }
          }
          imageTagStyle={{
            boxShadow: "1.753px -3.595px 35px #d9d8d8",
            borderRadius: "15px",
            border:
              Theme?.proposalProceedBtn?.hex1 && !lessthan768
                ? `1px solid ${Theme?.proposalProceedBtn?.hex1} !important`
                : "1px solid #495057",
            background:
              process.env.REACT_APP_BROKER === "TATA" &&
              Theme?.proposalCardActive?.background &&
              true
                ? Theme?.proposalCardActive?.background || ""
                : Theme?.proposalProceedBtn?.hex1
                ? `${Theme?.proposalProceedBtn?.hex1}`
                : "rgba(125,142,3,1)",
            ...(process.env.REACT_APP_BROKER === "BAJAJ" && {
              visibility: "hidden",
            }),
            ...(TypeReturn(props?.type) === "cv" && {
              padding:
                TempData?.productSubTypeId * 1 === 6 ? "0px" : "7px" || "",
            }),
          }}
          id="owner"
        >
          {formOwner === "form" ? (
            <div className="ElemFade m-0 p-1">
              <OwnerCard
                poi_file={poi_file}
                setpoi_file={setpoi_file}
                poa_file={poa_file}
                setpoa_file={setpoa_file}
                form60={form60}
                setForm60={setForm60}
                photo={photo}
                setPhoto={setPhoto}
                uploadFile={uploadFile}
                setuploadFile={setuploadFile}
                verifiedData={verifiedData}
                setVerifiedData={setVerifiedData}
                fileUploadError={fileUploadError}
                onSubmitOwner={onSubmitOwner}
                owner={owner}
                setOwner={setOwner}
                tempOwner={tempOwner}
                loading={loading}
                setResubmit={setResubmit}
                resubmit={resubmit}
                CardData={CardData}
                Theme={Theme}
                conditionChk={conditionChk}
                lessthan768={lessthan768}
                lessthan376={lessthan376}
                enquiry_id={props?.enquiry_id}
                fields={props?.fields}
                type={props?.TypeReturn(props?.type)}
                token={props?.token}
              />
            </div>
          ) : (
            <SummaryOwner
              summary={owner}
              lessthan768={lessthan768}
              fields={props?.fields}
            />
          )}
        </Card>
        {/*---------------------------End of Proposal Card------------------------*/}
        {/*---------------------------Nominee Details Card-----------------------*/}
        {Number(TempData?.ownerTypeId) !== 2 && conditionChk && (
          <Card
            title={
              formNominee === "summary" ? (
                titleNomineeSummary
              ) : (
                <Label colState={formNominee}>
                  {process.env.REACT_APP_BROKER === "OLA" &&
                  TempData?.corporateVehiclesQuoteRequest?.journeyType ===
                    "embeded-excel"
                    ? "Nominee Details"
                    : "Nominee Details"}
                </Label>
              )
            }
            backgroundColor={
              formNominee === "hidden" ? `` : formNominee === "summary" ? 1 : 2
            }
            paddingTop={lessthan768 ? "10px" : "6px"}
            borderRadius={
              formNominee === "hidden" ? "5px 5px 5px 5px" : "5px 5px 0px 0px"
            }
            removeBottomHeader={true}
            marginTop={formNominee === "hidden" ? "5px" : ""}
            id="nominee"
          >
            <div
              style={
                formNominee === "hidden"
                  ? {
                      maxHeight: "0",
                      transition: "max-height 0.4s ease-in-out",
                    }
                  : {
                      maxHeight: "100%",
                      transition: "max-height 0.4s ease-in-out",
                    }
              }
            >
              {formNominee === "form" ? (
                <div className="ElemFade m-0 p-1">
                  <NomineeCard
                    onSubmitNominee={onSubmitNominee}
                    nominee={nominee}
                    CardData={CardData}
                    Theme={Theme}
                    lessthan768={lessthan768}
                    lessthan376={lessthan376}
                    PACondition={PACondition}
                    enquiry_id={props?.enquiry_id}
                    dropout={dropout}
                    NomineeBroker={NomineeBroker}
                    type={props?.TypeReturn(props?.type)}
                    Tenure={Tenure}
                    fields={props?.fields}
                  />
                </div>
              ) : formNominee === "summary" ? (
                <div className="m-0 p-1">
                  <SummaryProposal
                    data={nominee}
                    lessthan768={lessthan768}
                    fields={props?.fields}
                  />
                </div>
              ) : (
                <noscript />
              )}
            </div>
          </Card>
        )}
        {/*---------------x----End of Nominee Details Card--------x-----------*/}
        {/*---------------------------Vehicle Details Card-----------------------*/}
        <Card
          title={
            formVehicle === "summary" ? (
              titleVehicleSummary
            ) : (
              <Label colState={formVehicle}>Vehicle Details</Label>
            )
          }
          backgroundColor={
            formVehicle === "hidden" ? `` : formVehicle === "summary" ? 1 : 2
          }
          paddingTop={lessthan768 ? "10px" : "6px"}
          borderRadius={
            formVehicle === "hidden" ? "5px 5px 5px 5px" : "5px 5px 0px 0px"
          }
          removeBottomHeader={true}
          marginTop={formVehicle === "hidden" ? "5px" : ""}
          id="vehicle"
        >
          <div
            style={
              formVehicle === "hidden"
                ? { maxHeight: "0", transition: "max-height 0.4s ease-in-out" }
                : {
                    maxHeight: "100%",
                    transition: "max-height 0.4s ease-in-out",
                  }
            }
          >
            {formVehicle === "form" ? (
              <div className="ElemFade m-0 p-1">
                <VehicleCard
                  onSubmitVehicle={onSubmitVehicle}
                  vehicle={vehicle}
                  CardData={CardData}
                  Theme={Theme}
                  type={props?.type}
                  lessthan768={lessthan768}
                  lessthan376={lessthan376}
                  fields={props?.fields}
                  PolicyCon={PolicyCon}
                  TypeReturn={props?.TypeReturn}
                  enquiry_id={props?.enquiry_id}
                  token={props?.token}
                />
              </div>
            ) : formVehicle === "summary" ? (
              <div className="m-0 p-1">
                <SummaryVehicle
                  summary={vehicle}
                  Theme={Theme}
                  temp={TempData}
                  lessthan768={lessthan768}
                  fields={props?.fields}
                />
              </div>
            ) : (
              <noscript />
            )}
          </div>
        </Card>
        {/*---------------x----End of Vehicle Details Card--------x-----------*/}
        {/*---------------------------Policy Details Card-----------------------*/}
        {(PolicyCon ||
          (PACondition && props?.fields?.includes("cpaOptOut"))) && (
          <Card
            title={
              formPrepolicy === "summary" ? (
                titlePrepolicySummary
              ) : (
                <Label colState={formPrepolicy}>
                  {PolicyCon &&
                  PACondition &&
                  props?.fields?.includes("cpaOptOut")
                    ? "Previous Policy & CPA Details"
                    : PolicyCon
                    ? "Previous Policy Details"
                    : "CPA Details"}
                </Label>
              )
            }
            backgroundColor={
              formPrepolicy === "hidden"
                ? ``
                : formPrepolicy === "summary"
                ? 1
                : 2
            }
            paddingTop={lessthan768 ? "10px" : "6px"}
            borderRadius={
              formPrepolicy === "hidden" ? "5px 5px 5px 5px" : "5px 5px 0px 0px"
            }
            removeBottomHeader={true}
            marginTop={formPrepolicy === "hidden" ? "5px" : ""}
            id="prepolicy"
          >
            <div
              style={
                formPrepolicy === "hidden"
                  ? {
                      maxHeight: "0",
                      transition: "max-height 0.4s ease-in-out",
                    }
                  : {
                      maxHeight: "100%",
                      transition: "max-height 0.4s ease-in-out",
                    }
              }
            >
              {formPrepolicy === "form" ? (
                <div className="ElemFade m-0 p-1">
                  <PolicyCard
                    onSubmitPrepolicy={onSubmitPrepolicy}
                    prepolicy={prepolicy}
                    CardData={CardData}
                    prevPolicyCon={PolicyCon}
                    PACon={PACondition && props?.fields?.includes("cpaOptOut")}
                    enquiry_id={props?.enquiry_id}
                    Theme={Theme}
                    type={props?.type}
                    OwnDamage={tpDetailsRequired ? true : false}
                    lessthan768={lessthan768}
                    lessthan376={lessthan376}
                    isNcbApplicable={isNcbApplicable}
                    TypeReturn={props?.TypeReturn}
                    fields={props?.fields}
                    PolicyValidationExculsion={PolicyValidationExculsion}
                  />
                </div>
              ) : formPrepolicy === "summary" ? (
                <div className="m-0 p-1">
                  <SummaryProposal
                    data={prepolicy}
                    lessthan768={lessthan768}
                    PolicyValidationExculsion={PolicyValidationExculsion}
                  />
                </div>
              ) : (
                <noscript />
              )}
            </div>
          </Card>
        )}
        {/*---------------x----End of Policy Details Card--------x-----------*/}
        {/*---------------x----Review & Submit--------x-----------*/}
        <div id="review-submit">{finalSubmit && FinalSubmit}</div>
        {/*---------------x----End of Review & Submit--------x-----------*/}
      </div>
      {/*--------------------OTP Modal-------------------*/}
      <OTPPopup
        enquiry_id={props?.enquiry_id}
        show={show}
        onHide={() => setShow(false)}
        mobileNumber={owner?.mobileNumber}
        otpSuccess={() => otpSuccess()}
        email={owner?.email}
        ckyc={
          props?.fields?.includes("ckyc") &&
          companyAlias &&
          companyAlias === "tata_aig"
            ? true
            : false
        }
        otp_id={otp_id}
        companyAlias={companyAlias}
        stage={"submit"}
      />
      {/*---------------x----OTP Modal--------x-----------*/}
      {/*---------------------Payment Modal--------------------*/}
      <PaymentModal
        ckycPresent={props?.fields?.includes("ckyc")}
        rsKycStatus={rsKycStatus}
        setrsKycStatus={props?.setrsKycStatus}
        companyAlias={companyAlias}
        submit={submit}
        enquiry_id={props?.enquiry_id}
        show={show1}
        onHide={() => setShow1(false)}
        type={props?.type}
        payment={payment}
        token={props?.token}
      />
      {/*---------------x----Payment Modalt--------x-----------*/}
      {/*--------------------Pre-Submit Modal-------------------*/}
      <PreSubmit
        enquiry_id={props?.enquiry_id}
        show={show2}
        onHide={() => setShow2(false)}
        selection={() => selection()}
      />
      {/*---------------x----Pre-Submit Modal--------x-----------*/}
      {/*--------------------CKYC-Mandate Modal-------------------*/}
      <CkycMandate
        theme_conf={theme_conf && theme_conf}
        enquiry_id={props?.enquiry_id}
        show={show3}
        onHide={() => setShow3(false)}
      />
      {/*---------------x----CKYC-Mandate Modal--------x-----------*/}
    </>
  );
};

export default FormSection;
