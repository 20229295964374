import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import logo from "utils/logo.svg";
import unilight from "utils/unilight.png";
import ace from "utils/ace.png";
import fyntune from "utils/fyntune.png";
import abibl from "utils/abibl.jpg";
import gc from "utils/gc.png";
import sriyah from "utils/sriyah.jpg";
import epoch from "utils/epochlogo.png";
import Cp from "utils/cp.png";
import pinc from "utils/pinc.png";
import rb from "utils/rb.png";
import bajaj from "utils/bajaj.png";
import tata from "utils/tata.gif";
import analah from "utils/analah.jpeg";
import bima from "utils/bima.png";
import uib from "utils/uib.png";
import sridhar from "utils/sridhar.png";
import shree from "utils/shree.png";
import insuringall from "utils/insuringall.jpeg";
import policyera from "utils/policy-era.png";
import LinearProgress from "@mui/material/LinearProgress";

export const LogoLoader = () => {
  const LogoFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return logo;
      case "UNILIGHT":
        return unilight;
      case "CP":
        return Cp;
      case "FYNTUNE":
        return fyntune;
      case "POLICYERA":
        return policyera;
      case "ABIBL":
        return abibl;
      case "GRAM":
        return gc;
      case "ACE":
        return ace;
      case "SRIYAH":
        return sriyah;
      case "EPOCH":
        return epoch;
      case "PINC":
        return pinc;
      case "RB":
        return rb;
      case "BAJAJ":
        return bajaj;
      case "TATA":
        return tata;
      case "SPA":
        return insuringall;
      case "BIMA":
        return bima;
      case "ANALAH":
        return analah;
      case "UIB":
        return uib;
      case "SRIDHAR":
        return sridhar;
      case "SHREE":
        return shree;
      default:
        break;
    }
  };
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div className="wrapper">
        <h1 className="brand">
          <Logo src={LogoFn()} alt="logo" />
        </h1>
        <ProgressBar>
          <LinearProgress className="custom-progress" />
        </ProgressBar>
        {/* <div className="loading-bar"></div> */}
      </div>
      <GlobalStyle />
    </div>
  );
};

const GlobalStyle = createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
/*use flexbox to centered element*/
div.wrapper {
    margin: auto;
   background: ${({ theme }) => (theme.dark ? "#00000078" : "#fffdfd78")};
   z-index: 20000000 !important;
}
h1.brand {
  font-size: 30px;
}
h1.brand span:nth-child(1) {
  background-color: transparent;
}
div.loading-bar {
  width: 160px;
  height: 6px;
  background-color: #d6cec2;
  border-radius: 10px;
  margin-top: 5px;
  overflow: hidden;
  position: relative;
}
div.loading-bar::after {
  content: '';
  width: 50px;
  height: 6px;
  position: absolute;
  background-color: #0074b4;
  transform: translateX(-20px);
  animation: loop 2s ease infinite;
}
@keyframes loop {
  0%,100% {
    transform: translateX(-28px);
  }
  50% {
    transform: translateX(78px)
  }
}
`;

const ColorText = styled.span`
  color: ${({ theme }) => theme.LandingPage?.color || "green"};
  font-weight: 600;
  @media (max-width: 1030px) {
    color: ${({ theme }) => theme.LandingPage?.color3 || "green"};
  }
`;

const ProgressBar = styled.div`
  .custom-progress > * {
    background-color: #0074b4 !important;
  }
  .custom-progress {
    background-color: #d3d3d3 !important;
  }
`;

const Logo = styled.img`
  //   transform: scale(2)
  width: ${process.env.REACT_APP_BROKER === "ACE"
    ? "187.5px"
    : process.env.REACT_APP_BROKER === "SRIYAH"
    ? "157.5px"
    : process.env.REACT_APP_BROKER === "PINC"
    ? "142.5px"
    : process.env.REACT_APP_BROKER === "RB"
    ? "auto"
    : "auto"};
  height: ${process.env.REACT_APP_BROKER !== "FYNTUNE"
    ? process.env.REACT_APP_BROKER === "ACE" ||
      process.env.REACT_APP_BROKER === "SRIYAH"
      ? "76.5px"
      : process.env.REACT_APP_BROKER === "PINC"
      ? "75px"
      : process.env.REACT_APP_BROKER === "RB"
      ? "81px"
      : "70px"
    : "38px"};
  @media (max-width: 768px) {
    width: ${process.env.REACT_APP_BROKER === "ACE"
      ? "115px"
      : process.env.REACT_APP_BROKER === "SRIYAH"
      ? "85px"
      : process.env.REACT_APP_BROKER === "PINC"
      ? "80px"
      : process.env.REACT_APP_BROKER === "RB"
      ? "auto"
      : "auto"};
    height: ${process.env.REACT_APP_BROKER === "PINC"
      ? "45px"
      : process.env.REACT_APP_BROKER !== "FYNTUNE"
      ? "45px"
      : process.env.REACT_APP_BROKER === "RB"
      ? "70px"
      : "32px"};
  }
  @media (max-width: 415px) {
    width: ${process.env.REACT_APP_BROKER === "ACE"
      ? "115px"
      : process.env.REACT_APP_BROKER === "SRIYAH"
      ? "85px"
      : process.env.REACT_APP_BROKER === "PINC"
      ? "86px"
      : process.env.REACT_APP_BROKER === "RB"
      ? "auto"
      : "auto"};
    height: ${process.env.REACT_APP_BROKER !== "FYNTUNE"
      ? process.env.REACT_APP_BROKER === "ACE" ||
        process.env.REACT_APP_BROKER === "SRIYAH"
        ? "51px"
        : process.env.REACT_APP_BROKER === "PINC"
        ? "46px"
        : process.env.REACT_APP_BROKER === "RB"
        ? "70px"
        : "35px"
      : "38px"};
  }
`;
