export const Identities = (company, uploadFile, poi, poa) => {
  return [
    {
      name: "PAN Number",
      id: "panNumber",
      placeholder: "Upload PAN Card Image",
      length: 10,
      fileKey: "panCard",
    },
    {
      name: "GST Number",
      id: "gstNumber",
      placeholder: "Upload GST Number Certificate",
      length: 15,
      fileKey: "gst_certificate",
    },
    {
      name: "Driving License Number",
      id: "drivingLicense",
      placeholder: "Upload Driving License Image",
      fileKey: "driving_license",
    },
    {
      name: "Voter ID Number",
      id: "voterId",
      placeholder: "Upload Voter ID Card Image",
      fileKey: "voter_card",
    },
    {
      name: "Passport Number",
      id: "passportNumber",
      placeholder: "Upload Passport Image",
      length: 8,
      fileKey: "passport_image",
    },
    {
      name: "e-Insurance Account Number",
      id: "eiaNumber",
      fileKey: "eiaNumber",
    },
    {
      name: "Adhaar Number",
      id: "aadharNumber",
      placeholder: "Upload Adhaar Card Image",
      length: 12,
      fileKey: "aadharCard",
    },
    {
      name: "NREGA Job Card",
      id: "nregaJobCard",
      placeholder: "Upload NREGA Card Image",
      length: 18,
      fileKey: "nrega_job_card_image",
    },
    {
      name: "National Population Letter",
      id: "nationalPopulationRegisterLetter",
      placeholder: "Upload Letter",
      length: 20,
      fileKey: "national_population_register_letter_image",
    },
    {
      name: "Registration Certificate",
      id: "registrationCertificate",
      placeholder: "Upload Certificate",
      length: 20,
      fileKey: "registration_certificate_image",
    },
    {
      name: "Certificate of Incorporation",
      id: "cretificateOfIncorporaion",
      placeholder: "Upload Certificate",
      length: 20,
      fileKey: "certificate_of_incorporation_image",
    },
  ];
};

export const identitiesCompany = (company, uploadFile, poi, poa) => {
  return [
    {
      name: "PAN Number",
      id: "panNumber",
      placeholder: "Upload PAN Card Image",
      length: 10,
      fileKey: "panCard",
    },
    {
      name: "GST Number",
      id: "gstNumber",
      placeholder: "Upload GST Number Certificate",
      length: 15,
      fileKey: "gst_certificate",
    },
    {
      name: "Driving License Number",
      id: "drivingLicense",
      placeholder: "Upload Driving License Image",
      fileKey: "driving_license",
    },
    {
      name: "Voter ID Number",
      id: "voterId",
      placeholder: "Upload Voter ID Card Image",
      fileKey: "voter_card",
    },
    {
      name: "Passport Number",
      id: "passportNumber",
      placeholder: "Upload Passport Image",
      length: 8,
      fileKey: "passport_image",
    },
    {
      name: "e-Insurance Account Number",
      id: "eiaNumber",
      fileKey: "eiaNumber",
    },
    {
      name: "Adhaar Number",
      id: "aadharNumber",
      placeholder: "Upload Adhaar Card Image",
      length: 12,
      fileKey: "aadharCard",
    },
    {
      name: "CIN Number",
      id: "cinNumber",
      placeholder: "Upload CIN Number Certificate",
      fileKey: "cinNumber",
    },
    {
      name: "Registration Certificate",
      id: "registrationCertificate",
      placeholder: "Upload Certificate",
      length: 20,
      fileKey: "registration_certificate_image",
    },
    {
      name: "Certificate of Incorporation",
      id: "cretificateOfIncorporaion",
      placeholder: "Upload Certificate",
      length: 20,
      fileKey: "certificate_of_incorporation_image",
    },
  ];
};
