import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import logo from "utils/logo.svg";
import unilight from "utils/unilight.png";
import ace from "utils/ace.png";
import Cp from "utils/cp.png";
import fyntune from "utils/fyntune.png";
import abibl from "utils/abibl.jpg";
import gc from "utils/gc.png";
import sriyah from "utils/sriyah.jpg";
import epoch from "utils/epochlogo.png";
import pinc from "utils/pinc.png";
import analah from "utils/analah.jpeg";
import rb from "utils/rb.png";
import bima from "utils/bima.png";
import bajaj from "utils/bajaj.png";
import uib from "utils/uib.png";
import sridhar from "utils/sridhar.png";
import shree from "utils/shree.png";
import insuringall from "utils/insuringall.jpeg";
import tata from "utils/tata.gif";
import policyera from "utils/policy-era.png";
import CallMe from "../Popup/CallMe";
import { SendQuotes } from "../Popup/SendQuotes";
import { useLocation } from "react-router";
import { useOutsideClick } from "../../hoc";
// import { setSearchQuery } from '../../modules/home/home.slice';
import { useDispatch, useSelector } from "react-redux";
import { reloadPage } from "../../utils";
import _ from "lodash";
import { RemoveToken } from "modules/login/login.slice";
import { Button, ContactFn } from "components";
import { useMediaPredicate } from "react-media-hook";
import { setTempData } from "../../modules/quotesPage/filterConatiner/quoteFilter.slice";
import { downloadFile } from "utils";
import { TypeReturn } from "modules/type";
import LogoutIcon from "@mui/icons-material/Logout";
import { MdOutlineMessage } from "react-icons/md";
import swal from "sweetalert";

const Header = (props) => {
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const lessthan360 = useMediaPredicate("(max-width: 360px)");
  const lessthan993 = useMediaPredicate("(max-width: 993px)");
  const location = useLocation();
  const loc = location.pathname ? location.pathname.split("/") : "";
  const type = !_.isEmpty(loc) ? (loc?.length >= 2 ? loc[1] : "") : "";

  //IOS check.
  let isMobileIOS = false; //initiate as false
  // device detection
  if (
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !window.MSStream &&
    lessthan767
  ) {
    isMobileIOS = true;
  }

  var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  const includeRoute = [
    `/${type}/proposal-page`,
    `/${type}/review`,
    `/${type}/quotes`,
    `/${type}/compare-quote`,
  ];

  const loginIncludeRoute = [
    `/${type}/registration`,
    `/${type}/lead-page`,
    `/${type}/renewal`,
  ];

  const RegistrationRoute = [`/${type}/registration`];

  const IncludeLogin = [
    `/${type}/lead-page`,
    `/payment-success`,
    `/payment-failure`,
    `/${type}/successful`,
  ];

  const includeRouteStickyNavBar = [`/${type}/quotes`];
  const includeRouteQID = [
    `/${type}/proposal-page`,
    `/${type}/review`,
    `/${type}/quotes`,
  ];
  const includeRouteShare = [
    `/${type}/quotes`,
    `/${type}/compare-quote`,
    `/${type}/proposal-page`,
    `/${type}/payment-confirmation`,
    `/payment-success`,
  ];
  const excludeRoute = [
    `/${type}/fg/payment/success`,
    `/${type}/payment/success`,
    `/${type}/bharti/payment/success`,
    `/${type}/otp-verification-tata`,
    `/${type}/payment/failed`,
    `/${type}/payment-gateway`,
    `/${type}/404`,
  ];

  const excludeRoutePayment = [
    `/${type}/fg/payment/success`,
    `/${type}/payment/success`,
    `/${type}/bharti/payment/success`,
    `/${type}/payment/failed`,
    `/${type}/mailed-policy`,
    `/${type}/`,
    `/${type}/404`,
  ];

  const [modal, setModal] = useState(false);
  const [sendQuotes, setSendQuotes] = useState(false);
  const { temp_data, tokenData, theme_conf } = useSelector(
    (state) => state.home
  );
  const { temp_data: temp } = useSelector((state) => state.proposal);
  const { removeToken } = useSelector((state) => state.login);

  const {
    comparePdfData,
    quoteComprehesive,
    quotetThirdParty,
    quoteShortTerm,
    compareQuotes,
    validQuote,
    quotesLoaded,
  } = useSelector((state) => state.quotes);

  const [navCheck, setNavCheck] = useState(false);
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const id = query.get("enquiry_id");
  const isPartner = query.get("is_partner");
  const token = query.get("token") || localStorage?.SSO_user_motor;

  useEffect(() => {
    if (process.env.REACT_APP_BROKER === "PINC" && token) {
      localStorage.setItem("rm_token", token);
    } else {
      localStorage.removeItem("rm_token");
    }
  }, [token]);

  useEffect(() => {
    if (removeToken?.redirectionUrl) {
      localStorage.removeItem("rm_token");
      reloadPage(removeToken?.redirectionUrl);
    }
  }, [removeToken]);

  useEffect(() => {
    if (sendQuotes) {
      dispatch(
        setTempData({
          sendQuote: true,
        })
      );
    } else {
      dispatch(
        setTempData({
          sendQuote: false,
        })
      );
    }
  }, [sendQuotes]);

  const handleRedirection = () => {
    dispatch(
      RemoveToken({
        enquiryId: id ? id : "NULL",
        token: token,
      })
    );
  };

  const dropDownRef = useRef(null);
  useOutsideClick(dropDownRef, () => setNavCheck(false));

  const filArr = !_.isEmpty(temp_data?.agentDetails)
    ? temp_data?.agentDetails.filter(
        (item) => item.sellerType === "P" || item.sellerType === "E"
      )
    : !_.isEmpty(temp?.agentDetails)
    ? temp?.agentDetails.filter(
        (item) => item.sellerType === "P" || item.sellerType === "E"
      )
    : [];

  const showAgentDetails = () => {
    swal({
      title: "Agent Details",
      text: `      
                ${
                  !_.isEmpty(filArr)
                    ? filArr[0]?.agentName
                    : tokenData?.seller_name
                } \n${
        !_.isEmpty(filArr)
          ? filArr[0]?.userName
            ? filArr[0]?.userName
            : filArr[0]?.agentId
          : tokenData?.user_name
      }`,
    });
  };

  const Bajaj_rURL = (b2c) => {
    return process.env.REACT_APP_PROD === "YES"
      ? process.env.REACT_APP_BASENAME === "general-insurance"
        ? token
          ? "https://dashboard.bajajcapitalinsurance.com/customer/login"
          : window.location.origin
        : "https://partner.bajajcapitalinsurance.com"
      : process.env.REACT_APP_API_BASE_URL ===
        "https://stageapimotor.bajajcapitalinsurance.com/api"
      ? process.env.REACT_APP_BASENAME === "general-insurance"
        ? token
          ? "https://stagedashboard.bajajcapitalinsurance.com/customer/login"
          : window.location.origin
        : "https://partnerstage.bajajcapitalinsurance.com"
      : //UAT
      process.env.REACT_APP_BASENAME === "general-insurance"
      ? token
        ? "https://uatdashboard.bajajcapitalinsurance.com/customer/login"
        : "https://buypolicyuat.bajajcapitalinsurance.com/"
      : "https://partneruat.bajajcapitalinsurance.com";
  };

  const LogoFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return logo;
      case "UNILIGHT":
        return unilight;
      case "CP":
        return Cp;
      case "FYNTUNE":
        return fyntune;
      case "POLICYERA":
        return policyera;
      case "ABIBL":
        return abibl;
      case "GRAM":
        return gc;
      case "ACE":
        return ace;
      case "SRIYAH":
        return sriyah;
      case "EPOCH":
        return epoch;
      case "PINC":
        return pinc;
      case "RB":
        return rb;
      case "SPA":
        return insuringall;
      case "BIMA":
        return bima;
      case "ANALAH":
        return analah;
      case "BAJAJ":
        return bajaj;
      case "UIB":
        return uib;
      case "SRIDHAR":
        return sridhar;
      case "SHREE":
        return shree;
      case "TATA":
        return tata;
      default:
        break;
    }
  };

  let ut =
    //home state
    (temp_data?.agentDetails &&
      !_.isEmpty(temp_data?.agentDetails) &&
      !_.isEmpty(
        temp_data?.agentDetails?.find((o) => o?.sellerType === "E")
      )) ||
    //proposal state
    (temp?.agentDetails &&
      !_.isEmpty(temp?.agentDetails) &&
      !_.isEmpty(temp?.agentDetails?.find((o) => o?.sellerType === "E"))) ||
    //token data
    tokenData?.usertype === "E" ||
    tokenData?.seller_type === "E" ||
    //b to c
    !token;

  //Is Pos
  let ut2 =
    (temp_data?.agentDetails &&
      !_.isEmpty(temp_data?.agentDetails) &&
      !_.isEmpty(
        temp_data?.agentDetails?.find((o) => o?.sellerType === "E")
      )) ||
    //proposal state
    (temp?.agentDetails &&
      !_.isEmpty(temp?.agentDetails) &&
      !_.isEmpty(temp?.agentDetails?.find((o) => o?.sellerType === "E"))) ||
    (temp_data?.agentDetails &&
      !_.isEmpty(temp_data?.agentDetails) &&
      !_.isEmpty(
        temp_data?.agentDetails?.find((o) => o?.sellerType === "P")
      )) ||
    //proposal state
    (temp?.agentDetails &&
      !_.isEmpty(temp?.agentDetails) &&
      !_.isEmpty(temp?.agentDetails?.find((o) => o?.sellerType === "P")));

  const UrlFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return `https://ola-dashboard.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
      case "UNILIGHT":
        return `https://www.policyongo.com/${ut ? "employee" : "pos"}/login`;
      case "CP":
        return `https://www.comparepolicy.com/`;
      case "FYNTUNE":
        return "";
      case "ABIBL":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-preprod-carbike.fynity.in/api"
        ) {
          return `http://preprod-dasbhoard-abibl.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-carbike.fynity.in/api"
        ) {
          return `http://uat-dasbhoard-abibl.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else {
          return `http://abibl-prod-dashboard.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        }
      case "GRAM":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apigramcover-carbike.fynity.in/api"
        ) {
          return `http://uat-dasbhoard-gramcover.fynity.in/${
            ut ? "employee" : "pos"
          }/login`;
        } else {
          return `https://dashboard.gramcover.com/${
            ut ? "employee" : "pos"
          }/login`;
        }
      case "ACE":
        return "https://crm.aceinsurance.com:5555/";
      case "SRIYAH":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://nammacover.com/"
          : "https://uat.nammacover.com/";
      case "PINC":
        return localStorage.getItem("setupTime")
          ? process.env.REACT_APP_PROD === "YES"
            ? "https://pinctreepos.app.link/prod_logo"
            : "https://pinctreepos.app.link/dev_logo"
          : "https://pinctree.com/";
      case "EPOCH":
        return `http://online.epochbrokers.com:8092/${
          ut ? "employee" : "customer"
        }/login`;
      case "RB":
        return "https://www.renewbuy.com/";
      case "SRIDHAR":
        return "https://www.sibinsure.com/";
      case "SPA":
        return `https://uatdashboard.insuringall.com/${
          ut ? "employee" : "pos"
        }/login`;
      case "BIMA":
        return ``;
      case "BAJAJ":
        return Bajaj_rURL();
      case "TATA":
        return process.env.REACT_APP_PROD === "YES"
          ? `https://dashboard.lifekaplan.com/${ut ? "employee" : "pos"}/login`
          : `https://uatdashboard.lifekaplan.com/${
              ut ? "employee" : "pos"
            }/login`;
      case "POLICYERA":
        return process.env.REACT_APP_PROD === "YES"
          ? `https://dashboard.policyera.com/${ut ? "employee" : "pos"}/login`
          : `https://uatdashboard.policyera.com/${
              ut ? "employee" : "pos"
            }/login`;
      case "ANALAH":
        return process.env.REACT_APP_PROD === "YES"
          ? `https://dashboard.analahinsurance.com/${
              ut ? "employee" : "pos"
            }/login`
          : `https://uatdashboard.analahinsurance.com/${
              ut ? "employee" : "pos"
            }/login`;
      default:
        return `https://ola-dashboard.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
    }
  };

  function copyToClipboard(text) {
    var selected = false;
    var el = document.createElement("textarea");
    el.value = text;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    if (document.getSelection().rangeCount > 0) {
      selected = document.getSelection().getRangeAt(0);
    }
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }

  function handlePdfDownlaod() {
    if (comparePdfData) {
      let stringifiedData = JSON.stringify(comparePdfData);
      var b = new Buffer(stringifiedData);
      var s = b.toString("base64");
      let downlaodPDF = `${process.env?.REACT_APP_API_BASE_URL}/policyComparePdf?data=${stringifiedData}`;

      downloadFile(downlaodPDF, false, true);
    }
  }

  let pdfBackground = validQuote?.length > 1 ? "#fff" : "#8080802e" || "";

  return (
    <header>
      <Navbar
        RegistrationRoute={RegistrationRoute}
        location={location}
        quotes={includeRouteStickyNavBar?.includes(location.pathname)}
        lessthan767={lessthan767}
        visiblityLogin={
          (location.pathname === `/${type}/lead-page` ||
            location.pathname === `/${type}/registration` ||
            location.pathname === `/${type}/renewal`) &&
          ((!_.isEmpty(temp_data?.agentDetails) &&
            _.isEmpty(
              temp_data?.agentDetails?.filter((o) => o?.source === "cse")
            ) &&
            _.isEmpty(
              temp_data?.agentDetails?.filter((o) => o?.sellerType === "U")
            )) ||
            _.isEmpty(temp_data?.agentDetails))
        }
        webView={
          (isMobileIOS && !standalone && !safari) || userAgent.includes("wv")
        }
      >
        <div>
          <a
            className="logo"
            href={
              ((isMobileIOS && !standalone && !safari) ||
                userAgent.includes("wv")) &&
              process.env.REACT_APP_BROKER === "RB"
                ? `${window.location.href}`
                : token ||
                  ["SRIYAH", "TATA", "POLICYERA", "SRIDHAR"].includes(
                    process.env.REACT_APP_BROKER
                  ) ||
                  ut2
                ? UrlFn()
                : process.env.REACT_APP_BROKER === "BAJAJ"
                ? Bajaj_rURL(true)
                : `${window.location.origin}${
                    process.env.REACT_APP_BASENAME !== "NA"
                      ? `/${process.env.REACT_APP_BASENAME}`
                      : ``
                  }/${
                    Number(temp?.productSubTypeId) === 1
                      ? "car"
                      : Number(temp?.productSubTypeId) === 2
                      ? "bike"
                      : type && ["car", "bike", "cv"].includes(TypeReturn(type))
                      ? type
                      : temp?.journeyCategory
                      ? temp?.journeyCategory.toLowerCase() === "pcv" ||
                        temp?.journeyCategory.toLowerCase() === "gcv"
                        ? "cv"
                        : temp?.journeyCategory.toLowerCase()
                      : "cv"
                  }/lead-page`
            }
          >
            <Logo src={LogoFn()} alt="logo" />
          </a>
        </div>
        <div
          className="d-flex my-auto"
          style={{
            ...(process.env.REACT_APP_BROKER === "RB" &&
              loginIncludeRoute?.includes(location.pathname) && {
                justifyContent: "space-between",
                width: lessthan767 ? "210px" : lessthan360 ? "190px" : "",
              }),
          }}
        >
          {!excludeRoute.includes(location.pathname) && (
            <>
              <CallButton
                id={"callus2"}
                style={{
                  ...(lessthan767 &&
                    id && {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }),
                }}
              >
                {process.env.REACT_APP_BROKER !== "RB" &&
                token &&
                lessthan767 &&
                query.get("token") &&
                (localStorage?.SSO_user_motor ||
                  process.env.REACT_APP_BROKER !== "RB") &&
                true ? (
                  <div
                    className={token && lessthan767 ? "w-100 d-flex" : ""}
                    style={{ justifyContent: "space-evenly" }}
                  >
                    <div>
                      {(!_.isEmpty(tokenData) || !_.isEmpty(filArr)) && (
                        <PosWrapper lessthan767={lessthan767}>
                          <PosId
                            className="circle"
                            onClick={() => showAgentDetails()}
                          >
                            <PosId latter lessthan767={lessthan767}>
                              <text
                                style={{
                                  marginBottom: "0.2px",
                                  color: "white",
                                }}
                              >
                                {!_.isEmpty(filArr)
                                  ? filArr[0]?.agentName
                                      .trim()[0]
                                      ?.toUpperCase() || ""
                                  : (tokenData?.seller_name[0] &&
                                      tokenData?.seller_name
                                        .trim()[0]
                                        ?.toUpperCase()) ||
                                    ""}
                              </text>
                            </PosId>
                          </PosId>
                          {!lessthan993 && (
                            <div>
                              <PosDiv>
                                <PosId>
                                  {!_.isEmpty(filArr)
                                    ? filArr[0]?.agentName
                                    : tokenData?.seller_name}
                                </PosId>
                                <PosId small>
                                  {!_.isEmpty(filArr)
                                    ? filArr[0]?.userName
                                      ? filArr[0]?.userName
                                      : filArr[0]?.agentId
                                    : tokenData?.user_name}
                                </PosId>
                              </PosDiv>
                              {process.env.REACT_APP_BROKER === "PINC" ? (
                                <LogoutIcon
                                  onClick={() => {
                                    handleRedirection();
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    margin: lessthan767
                                      ? "3px 0px 0px 4px"
                                      : "3px 0px 0px 13px",
                                  }}
                                />
                              ) : (
                                <noscript />
                              )}
                            </div>
                          )}
                        </PosWrapper>
                      )}
                    </div>
                    {lessthan767 && id && (
                      <div
                        style={{
                          fontSize: lessthan360 ? "9.5px" : "10px",
                          marginTop: "-2px",
                        }}
                      >
                        Trace ID :<br /> {id}
                      </div>
                    )}
                  </div>
                ) : lessthan767 ? (
                  <div
                    style={{
                      fontSize: lessthan360 ? "9px" : "11px",
                      marginTop: lessthan767 ? "5px" : "-15px",
                    }}
                  >
                    Trace ID : {id}
                  </div>
                ) : (
                  <noscript />
                )}
              </CallButton>
              {includeRoute.includes(location.pathname) &&
                (ut || process.env.REACT_APP_BROKER !== "OLA") &&
                (location.pathname === `/${type}/quotes` ||
                  location.pathname === `/${type}/compare-quote` ||
                  location.pathname === `/${type}/proposal-page`) && (
                  <CallButton
                    style={{ display: "none" }}
                    id={lessthan767 ? "shareQuotes1" : "shareQuotes2"}
                    onClick={() => {
                      setSendQuotes(true);
                    }}
                  >
                    <SendQuery className="fa fa-share-alt" />
                  </CallButton>
                )}
              <div>
                {location.pathname === `/${type}/compare-quote` &&
                  !lessthan993 && (
                    <ConfirmButton
                      className="d-flex align-items-center justify-content-center"
                      onClick={() => copyToClipboard(id)}
                      style={{
                        // width: "180px",
                        cursor: "copy",
                        width: "250px",
                      }}
                    >
                      <label
                        className="m-0 p-0"
                        style={{
                          fontSize: "14px",
                          paddingTop: "3px",
                          cursor: "copy",
                        }}
                      >
                        Trace ID : {id}
                      </label>
                    </ConfirmButton>
                  )}
              </div>
              <div>
                {process.env.REACT_APP_BROKER !== "RB" &&
                  token &&
                  !lessthan993 &&
                  (!_.isEmpty(tokenData) || !_.isEmpty(filArr)) &&
                  query.get("token") &&
                  (localStorage?.SSO_user_motor ||
                    process.env.REACT_APP_BROKER !== "RB") && (
                    <PosWrapper>
                      <PosId className="circle">
                        <PosId latter lessthan767={lessthan767}>
                          <text
                            style={{ marginBottom: "0.2px", color: "white" }}
                          >
                            {!_.isEmpty(filArr)
                              ? filArr[0]?.agentName.trim()[0]?.toUpperCase() ||
                                ""
                              : (tokenData?.seller_name[0] &&
                                  tokenData?.seller_name
                                    .trim()[0]
                                    ?.toUpperCase()) ||
                                ""}
                          </text>
                        </PosId>
                      </PosId>
                      {!lessthan993 && (
                        <div
                          style={{
                            display: "flex",
                            marginRight: lessthan767 ? "0px" : "30px",
                          }}
                        >
                          <PosDiv>
                            <PosId>
                              {!_.isEmpty(filArr)
                                ? filArr[0]?.agentName
                                : tokenData?.seller_name}
                            </PosId>
                            <PosId small>
                              {!_.isEmpty(filArr)
                                ? filArr[0]?.userName
                                  ? filArr[0]?.userName
                                  : filArr[0]?.agentId
                                : tokenData?.user_name}
                            </PosId>
                          </PosDiv>

                          {process.env.REACT_APP_BROKER === "PINC" ? (
                            <LogoutIcon
                              onClick={() => {
                                handleRedirection();
                              }}
                              style={{
                                cursor: "pointer",
                                margin: lessthan767
                                  ? "3px 0px 0px 4px"
                                  : "3px 0px 0px 13px",
                              }}
                            />
                          ) : (
                            <noscript />
                          )}
                        </div>
                      )}
                    </PosWrapper>
                  )}
              </div>
              <div>
                {location.pathname === `/${type}/compare-quote` &&
                  !lessthan767 && (
                    <ConfirmButton
                      className="d-flex align-items-center justify-content-center"
                      onClick={
                        validQuote?.length > 1
                          ? handlePdfDownlaod
                          : console.log("Run", validQuote)
                      }
                      id={"comparePdfDownload"}
                      style={{
                        background: pdfBackground,
                        cursor:
                          validQuote?.length > 1 ? "pointer" : "not-allowed",
                      }}
                    >
                      <i
                        class="fa fa-download"
                        aria-hidden="true"
                        style={{
                          fontSize: "14px",
                          cursor:
                            validQuote?.length > 1 ? "pointer" : "not-allowed",
                          margin: "0px 5px",
                        }}
                      ></i>

                      <label
                        className="m-0 p-0"
                        style={{
                          fontSize: "14px",
                          paddingTop: "3px",
                          cursor:
                            validQuote?.length > 1 ? "pointer" : "not-allowed",
                        }}
                      >
                        PDF
                      </label>
                    </ConfirmButton>
                  )}
              </div>
              <div>
                {includeRouteShare.includes(location.pathname) &&
                  !lessthan767 &&
                  (ut || process.env.REACT_APP_BROKER !== "OLA") && (
                    <ConfirmButton
                      id={"shareQuotes1"}
                      style={{
                        // ...(process.env.REACT_APP_BROKER === "OLA" &&
                        //   loc[2] === "proposal-page" && {
                        //     visibility: "hidden",
                        //   }),
                        cursor:
                          (((quoteComprehesive &&
                            quoteComprehesive.length >= 1) ||
                            (quotetThirdParty &&
                              quotetThirdParty.length >= 1) ||
                            (quoteShortTerm && quoteShortTerm.length >= 1)) &&
                            !quotesLoaded) ||
                          loc[2] === "proposal-page" ||
                          loc[1] === "payment-success"
                            ? "pointer"
                            : "not-allowed",
                      }}
                      className="d-flex align-items-center justify-content-center"
                      onClick={() =>
                        ((((quoteComprehesive &&
                          quoteComprehesive.length >= 1) ||
                          (quotetThirdParty && quotetThirdParty.length >= 1) ||
                          (quoteShortTerm && quoteShortTerm.length >= 1)) &&
                          !quotesLoaded) ||
                          loc[2] === "proposal-page" ||
                          loc[1] === "payment-success") &&
                        setSendQuotes(true)
                      }
                    >
                      <i
                        class="fa mr-2 fa-share-alt"
                        style={{
                          fontSize: "14px",
                        }}
                      ></i>

                      <label
                        className="m-0 p-0"
                        style={{
                          fontSize: "14px",
                          paddingTop: "3px",
                          cursor:
                            (((quoteComprehesive &&
                              quoteComprehesive.length >= 1) ||
                              (quotetThirdParty &&
                                quotetThirdParty.length >= 1) ||
                              (quoteShortTerm && quoteShortTerm.length >= 1)) &&
                              !quotesLoaded) ||
                            loc[2] === "proposal-page" ||
                            loc[1] === "payment-success"
                              ? "pointer"
                              : "not-allowed",
                        }}
                      >
                        Share{" "}
                        {loc[2] === "proposal-page"
                          ? "Proposal"
                          : loc[2] === "payment-confirmation"
                          ? "Payment"
                          : loc[1] === "payment-success"
                          ? "Policy"
                          : "Quotes"}
                      </label>
                    </ConfirmButton>
                  )}
              </div>
              {/* SSO login Element please do not remove and edit */}

              <div id="loginWidget" className={`pos-el-login`}></div>
              {location.pathname !== `/${type}/login` && (
                <div style={{ display: !lessthan767 ? "contents" : "none" }}>
                  <ConfirmButton
                    className="d-flex align-items-center justify-content-center callusHover"
                    onClick={() => setModal(true)}
                    id={"callus1"}
                  >
                    {process.env.REACT_APP_BROKER === "UIB" ? (
                      <MdOutlineMessage className="mr-2 mdoutline" />
                    ) : (
                      <img
                        src={`${
                          process.env.REACT_APP_BASENAME !== "NA"
                            ? `/${process.env.REACT_APP_BASENAME}`
                            : ""
                        }/assets/images/tlphn.png`}
                        alt="phone"
                        className="mr-2 box-decoration"
                        height="19"
                        style={{ cursor: "pointer" }}
                      />
                    )}

                    <label
                      className="m-0 p-0"
                      style={{
                        fontSize: "14px",
                        paddingTop: "3px",
                        cursor: "pointer",
                      }}
                    >
                      Contact Us
                    </label>
                  </ConfirmButton>
                </div>
              )}
            </>
          )}
        </div>
      </Navbar>
      {modal && <CallMe show={modal} onClose={setModal} />}
      {sendQuotes && (
        <SendQuotes
          show={sendQuotes}
          onClose={setSendQuotes}
          sendPdf={loc[2] === "compare-quote" ? true : false}
          comparePdfData={comparePdfData}
          type={type}
        />
      )}
    </header>
  );
};

const Navbar = styled.div`
  background-color: #ffffff;
  position: ${({ sticky }) =>
    sticky ? " fixed !important" : `relative !important`};
  z-index: ${({ sticky }) => (sticky ? "1000" : `0`)};

  width: 100%;
  border-bottom: solid 1.5px #e3e4e8;
  margin: 0;

  height: 60px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    padding: 18px 25px 18px 15px;
  }

  @media (max-width: 993px) {
    position: relative !important;
    height: 72px;
  }
  @media (max-width: 767px) {
    position: relative !important;
    height: 48px;
    z-index: ${({ RegistrationRoute, location }) =>
      process.env.REACT_APP_BROKER === "RB" &&
      RegistrationRoute.includes(location.pathname)
        ? "9999 !important"
        : "0"};
  }
  #profileDetailBox {
    ${(props) => (!props?.visiblityLogin ? `display: none !important;` : ``)};
  }
  .jav_widgetxtR {
    ${(props) => (!props?.visiblityLogin ? `display: none !important;` : ``)};
  }
  .circleStyle {
    ${(props) =>
      !props?.lessthan767
        ? `position: relative !important; left: -15px !important; top: -5px !important;`
        : `position: relative !important; top: -12px !important;`};
    ${(props) => (!props?.visiblityLogin ? `display: none !important;` : ``)};
  }
  .loginStyle1 {
    ${(props) =>
      !props?.lessthan767
        ? `position: relative !important;
        left: -15px !important;
        height: 34px;
        top: -4.5px !important;
        width: 135px;
        margin: 0 30px 0 0;
        position: relative;
        border-radius: 4px;
        border: 1px;`
        : ``};
    ${(props) => (!props?.visiblityLogin ? `display: none !important;` : ``)};
  }
  #jsWidgetlogout {
    ${(props) => (props?.wevView ? `display: none !important;` : ``)};
  }
  padding: ${({ quotes }) =>
    quotes ? "18px 68px 18px 75px" : `18px 30px 18px 45px`};
`;
const CallButton = styled.span`
  display: ${({ show }) => (show ? "visible" : "none")};
  @media (max-width: 767px) {
    margin-top: -12.3px !important;
    display: inline-block;
    padding-top: 5px;
    margin-right: 0px;
    & > a > svg {
      width: 38px;
      height: 38px;
      border: 1px solid #777;
      padding: 4px;
      border-radius: 50%;
    }
  }
`;

const ConfirmButton = styled.button`
  display: none;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  position: relative;
  top: -4.5px;
  transition: 0.2s ease-in-out;
  background-color: ${({ broker }) => (broker ? "#D0D0D0D0" : "#ffffff")};
  border: ${({ theme, broker }) =>
    broker ? "#A8A8A8" : theme.Header?.border || "1px solid #bdd400"};
  padding: 11px 0;
  border-radius: 4px;
  z-index: 2;
  width: 135px;
  height: 34px;
  font-size: 16px;
  color: ${({ theme }) => theme.regularFont?.textColor || "#000000"};
  margin-right: 0px;
  font-weight: 400;
  outline: none;
  margin-right: 30px;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed !important;
  }
  .mdoutline {
    font-size: 20px;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${({ theme }) =>
      theme?.proposalProceedBtn?.hex1 || "#bdd400"}!important;
    label,
    i {
      color: #fff !important;
    }
    .mdoutline {
      color: #fff;
    }
    img {
      filter: invert(97%) sepia(83%) saturate(3866%) hue-rotate(256deg)
        brightness(150%) contrast(98%);
    }
  }
  /* &:hover > label{
  color: #fff !important;
  
} */
  @media (max-width: 767px) {
    margin-top: -12.3px !important;
    display: inline-block;
    padding-top: 5px;
    margin-right: 0px;
    & > a > svg {
      width: 38px;
      height: 38px;
      border: 1px solid #777;
      padding: 4px;
      border-radius: 50%;
    }
    & svg {
      width: 12px;
      height: 8px;
      margin-right: 6px;
    }
    &:hover {
      background-color: ${({ theme, broker }) =>
        broker ? "#D0D0D0D0" : theme.Header?.color || "#bdd400"};
      color: ${({ theme, broker }) => (broker ? "#000" : "#ffffff")};
      .box-decoration {
        filter: brightness(0) invert(1);
      }
    }
  }
  @media (max-width: 993px) {
    margin-top: 6px;
  }
`;

const SideMenu = styled.nav`
  & > div {
    background: #f6f6f6;
  }
  .checkbox {
    display: none !important;
  }
  .button {
    cursor: pointer;
    margin: 0;
  }
  .button svg {
    cursor: pointer;
  }
  .nav {
    height: 100%;
    background-color: black;
    width: 0;
    position: fixed;
    right: 0;
    top: 0;
    opacity: 0;
    z-index: 1500;
    transition: all 0.2s;
    visibility: hidden;
    transition: width 500ms ease-in-out, visibility 250ms ease-in-out,
      opacity 200ms ease-in-out;
  }
  .list {
    list-style-type: none;
    padding: 0;
    margin-top: 30px;
    li a {
      padding: 8px 8px 8px 32px;
      text-decoration: none;
      font-family: ${({ theme }) =>
        theme?.fontFamily ? theme?.fontFamily : `"basier_squaremedium"`};
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      display: block;
    }
  }

  li a:link,
  li a:visited {
    text-decoration: none;
  }
  li a:hover {
    background-color: #056b88;
  }

  .checkbox:checked ~ .nav {
    width: 280px;
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: column;
  }

  .close {
    opacity: 1;
    float: right;
    margin-right: 20px;
    margin-top: 20px;
    padding: 5px;
    font-size: 0;
    cursor: pointer;
    align-self: flex-end;
  }
  .close:hover {
    background-color: #056b88;
    opacity: 1;
  }
  .close svg {
    width: 20px;
    height: 20px;
  }
  .close:not(:disabled):not(.disabled):focus,
  .close:not(:disabled):not(.disabled):hover {
    opacity: 1;
  }
`;
const PosWrapper = styled.div`
  display: flex;
  position: relative;
  top: ${({ lessthan767 }) => (lessthan767 ? `1px` : `-5px`)};
  .circle {
    padding: ${({ lessthan767 }) => (lessthan767 ? `5px` : `3px`)};
    margin: 0px 8px 10px;
    border-radius: ${({ lessthan767 }) => (lessthan767 ? `6px` : `100%`)};
    background: ${(props) =>
      props?.theme?.Stepper?.stepperColor?.background || "#bdd400"};
    /* line-height: 35px; */
    /* position: relative;
    top: -3px; */
  }

  @media (max-width: 768px) {
    // position: unset !important;
    display: unset !important;
  }
`;
const PosId = styled.span`
  font-size: ${({ small, latter, lessthan767 }) =>
    lessthan767 ? "14px" : small ? "9.5px" : latter ? "18px" : "12px"};
  padding: ${({ latter, lessthan767 }) =>
    lessthan767 ? "5px" : latter && "8.5px 9px"};
`;
const PosDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  position: relative;
  top: -4.5;
  margin-top: 0.4px;
  /* margin-right: 30px; */
  /* padding-top: "2px"; */
  /* position: relative; */
  /* top: -8px; */
`;

const Logo = styled.img`
  width: ${process.env.REACT_APP_BROKER === "ACE"
    ? "125px"
    : process.env.REACT_APP_BROKER === "PINC"
    ? "95px"
    : process.env.REACT_APP_BROKER === "SRIYAH" ||
      process.env.REACT_APP_BROKER === "RB" ||
      process.env.REACT_APP_BROKER === "EPOCH" ||
      process.env.REACT_APP_BROKER === "BIMA" ||
      process.env.REACT_APP_BROKER === "BAJAJ" ||
      process.env.REACT_APP_BROKER === "UIB" ||
      process.env.REACT_APP_BROKER === "ANALAH" ||
      process.env.REACT_APP_BROKER === "SRIDHAR" ||
      process.env.REACT_APP_BROKER === "POLICYERA" ||
      process.env.REACT_APP_BROKER === "SHREE" ||
      process.env.REACT_APP_BROKER === "TATA"
    ? "auto"
    : process.env.REACT_APP_BROKER === "SPA"
    ? "260px"
    : "160px"};
  height: ${process.env.REACT_APP_BROKER !== "FYNTUNE"
    ? process.env.REACT_APP_BROKER === "ACE" ||
      process.env.REACT_APP_BROKER === "SRIYAH"
      ? "51px"
      : process.env.REACT_APP_BROKER === "PINC" ||
        process.env.REACT_APP_BROKER === "BIMA" ||
        process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "SHREE"
      ? "50px"
      : process.env.REACT_APP_BROKER === "RB"
      ? "81px"
      : process.env.REACT_APP_BROKER === "SPA"
      ? "60px"
      : process.env.REACT_APP_BROKER === "EPOCH" ||
        process.env.REACT_APP_BROKER === "TATA"
      ? "57px"
      : process.env.REACT_APP_BROKER === "UIB"
      ? "42px"
      : process.env.REACT_APP_BROKER === "ANALAH" ||
        process.env.REACT_APP_BROKER === "SRIDHAR"
      ? "55px"
      : "45px"
    : "38px"};
  @media (max-width: 768px) {
    width: ${process.env.REACT_APP_BROKER === "ACE"
      ? "115px"
      : process.env.REACT_APP_BROKER === "SRIYAH"
      ? "85px"
      : process.env.REACT_APP_BROKER === "PINC"
      ? "80px"
      : process.env.REACT_APP_BROKER === "RB" ||
        process.env.REACT_APP_BROKER === "SPA" ||
        process.env.REACT_APP_BROKER === "BIMA" ||
        process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "UIB" ||
        process.env.REACT_APP_BROKER === "ANALAH" ||
        process.env.REACT_APP_BROKER === "SRIDHAR" ||
        process.env.REACT_APP_BROKER === "POLICYERA" ||
        process.env.REACT_APP_BROKER === "SHREE" ||
        process.env.REACT_APP_BROKER === "TATA"
      ? "auto"
      : "130px"};
    height: ${process.env.REACT_APP_BROKER === "PINC"
      ? "45px"
      : process.env.REACT_APP_BROKER === "RB"
      ? "60px"
      : process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "SHREE"
      ? "40px"
      : process.env.REACT_APP_BROKER === "UIB"
      ? "35px"
      : process.env.REACT_APP_BROKER === "POLICYERA"
      ? "35px"
      : process.env.REACT_APP_BROKER === "TATA"
      ? "45px"
      : process.env.REACT_APP_BROKER !== "FYNTUNE"
      ? "45px"
      : "32px"};
  }
  @media (max-width: 415px) {
    width: ${process.env.REACT_APP_BROKER === "ACE"
      ? "115px"
      : process.env.REACT_APP_BROKER === "SRIYAH"
      ? "85px"
      : process.env.REACT_APP_BROKER === "PINC"
      ? "86px"
      : process.env.REACT_APP_BROKER === "RB" ||
        process.env.REACT_APP_BROKER === "SPA" ||
        process.env.REACT_APP_BROKER === "BIMA" ||
        process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "UIB" ||
        process.env.REACT_APP_BROKER === "ANALAH" ||
        process.env.REACT_APP_BROKER === "SRIDHAR" ||
        process.env.REACT_APP_BROKER === "POLICYERA" ||
        process.env.REACT_APP_BROKER === "SHREE" ||
        process.env.REACT_APP_BROKER === "TATA"
      ? "auto"
      : "130px"};
    height: ${process.env.REACT_APP_BROKER !== "FYNTUNE"
      ? process.env.REACT_APP_BROKER === "ACE" ||
        process.env.REACT_APP_BROKER === "SRIYAH"
        ? "auto"
        : process.env.REACT_APP_BROKER === "PINC"
        ? "46px"
        : process.env.REACT_APP_BROKER === "RB"
        ? "60px"
        : process.env.REACT_APP_BROKER === "SPA"
        ? "34px"
        : process.env.REACT_APP_BROKER === "BAJAJ"
        ? "30px"
        : process.env.REACT_APP_BROKER === "ANALAH"
        ? "40px"
        : process.env.REACT_APP_BROKER === "POLICYERA"
        ? "30px"
        : process.env.REACT_APP_BROKER === "TATA"
        ? "38px"
        : process.env.REACT_APP_BROKER === "UIB" ||
          process.env.REACT_APP_BROKER === "SHREE"
        ? "35px"
        : "45px"
      : "38px"};
  }
`;

const SendQuery = styled.i`
  color: #656565;
  max-height: 38px;
  font-size: 25px;
  border: 1px solid #777777;
  border-radius: 50px;
  padding: 6px 8px 7px 7px;
  cursor: pointer;
`;

const QuoteId = styled.button`
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  background-color: #ffffff;
  border: none;
  padding: 11px 0;
  border-radius: 4px;
  z-index: 2;
  width: 161px;
  height: 48px;
  font-size: 12px;
  color: #000000;
  margin-right: 0px;
  font-weight: 600;
  outline: none;
  margin-right: 30px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  @media (max-width: 768px) {
    width: auto;
  }
  & svg {
    width: 27px;
    height: 24px;
    margin-right: 6px;
  }
  &:hover {
    background-color: #bdd400;
    color: #ffffff;
  }
`;

export default Header;
