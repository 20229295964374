import HttpClient from "api/httpClient";

// const getCkycurl = () => {
// switch (process.env.REACT_APP_BROKER) {
//   // case "OLA":
//   //   return "logo";
//   // case "UNILIGHT":
//   //   return unilight;
//   // case "CP":
//   //   return Cp;
//   // case "FYNTUNE":
//   //   return "https://ckyc-api.fynity.in/api/v1/ckyc-verifications";
//   // case "POLICYERA":
//   //   return policyera;
//   // case "ABIBL":
//   //   return abibl;
//   // case "GRAM":
//   //   return gc;
//   // case "ACE":
//   //   return ace;
//   // case "SRIYAH":
//   //   return "https://uatckyc-api.nammacover.com";
//   case "EPOCH":
//     return "https://uatapimotor.policylo.com/api";
//   // case "PINC":
//   //   return pinc;
//   case "RB":
//     return "https://apiuatmotor.rbstaging.in/api";
//   case "SPA":
//     return "https://apiuatmotor.insuringall.com/api";
//   case "BIMA":
//     return "https://uatapimotor.bimaplanner.com/api";
//   // case "ANALAH":
//   //   return analah;
//   case "BAJAJ":
//     return "https://uatapimotor.bajajcapitalinsurance.com/api";
//   // case "UIB":
//   //   return uib;
//   case "SRIDHAR":
//     return "https://uatapimotor.sibinsure.com/api";
//   case "SHREE":
//     return "https://uatapimotor.shreeinsure.com/api";
//   // case "TATA":
//   //   return tata;
//   default:
//     return "https://apimotor.fynity.in/api"
//     break;
// }
// }

//proposal-save
const save = (data) => HttpClient("/save", { method: "POST", data });

//proposal-submit
const submit = ({ typeRoute, data }) =>
  HttpClient(
    `/${typeRoute && typeRoute !== "cv" ? `${typeRoute}/` : ""}submit`,
    { method: "POST", data }
  );

//get gender
const gender = (data) =>
  HttpClient(
    `/getGender?companyAlias=${data?.companyAlias}${
      data?.enquiryId ? `&enquiryId=${data?.enquiryId}` : ""
    }`,
    {
      method: "GET",
    }
  );

//get nominee relation
const relations = (data) =>
  HttpClient(
    `/getNomineeRelationship?companyAlias=${data?.companyAlias}${
      data?.enquiryId ? `&enquiryId=${data?.enquiryId}` : ""
    }`,
    {
      method: "GET",
    }
  );

//get financer
const financer = (data) =>
  HttpClient(
    `/getFinancerList?companyAlias=${data?.companyAlias}&searchString=${data?.searchString}&enquiryId=${data?.enquiryId}`,
    { method: "GET" }
  );

//get agreement
const agreement = (data) =>
  HttpClient(
    `/getFinancerAgreementType?companyAlias=${data?.companyAlias}${
      data?.enquiryId ? `&enquiryId=${data?.enquiryId}` : ""
    }`,
    {
      method: "GET",
    }
  );

//get pincode
const pincode = (data) =>
  HttpClient(
    `/getPincode?pincode=${data?.pincode}&companyAlias=${data?.companyAlias}${
      data?.enquiryId ? `&enquiryId=${data?.enquiryId}` : ""
    }`,
    { method: "GET" }
  );

//prefill
const prefill = (data) =>
  HttpClient("/getUserRequestedData", { method: "POST", data });

//occupation
const occupation = (data) =>
  HttpClient(
    `/getOccupation?companyAlias=${data?.companyAlias}${
      data?.enquiryId ? `&enquiryId=${data?.enquiryId}` : ""
    }`,
    {
      method: "GET",
    }
  );

//previous insurer
const prevIc = (data) =>
  HttpClient(`/getPreviousInsurerList`, { method: "POST", data });

const saveLeadData = (data) =>
  HttpClient("/updateUserJourney", { method: "POST", data });

//check Addon Data
const checkAddon = (data) =>
  HttpClient("/cvApplicableAddons", { method: "POST", data });

//brochure/wordings
const wording = (data) =>
  HttpClient(
    `/getWordingsPdf?enquiryId=${data?.policyId}${
      data?.enquiry_id ? `&enquiryId=${data?.enquiry_id}` : ""
    }`,
    {
      method: "GET",
      data,
    }
  );

//url
const url = (data) => HttpClient(`/updateJourneyUrl`, { method: "POST", data });

//save addon
export const saveAddons = (data) =>
  HttpClient(`/saveAddonData`, { method: "POST", data });

//vehicle category
export const category = (data) =>
  HttpClient(`/getVehicleCategories`, { method: "GET", data });

//vehicle usage
export const usage = (id) =>
  HttpClient(`/getVehicleUsageTypes?vehicleCategoryId=${id}`, {
    method: "GET",
  });

//get OTP
export const otp = (data) =>
  HttpClient(`/ComparePolicySmsOtp`, { method: "POST", data });

//verify OTP
export const verifyOtp = (data) =>
  HttpClient(`/verifysmsotp?enquiryId=${data?.enquiryId}&otp=${data?.otp}`, {
    method: "POST",
    data,
  });

export const verifyCkycnum = (data) =>
  HttpClient(`/ckyc-verifications`, {
    method: "POST",
    data,
  });

export const godigitKyc = (data) =>
  HttpClient(`/GodigitKycStatus`, {
    method: "POST",
    data,
  });

export const RSKyc = (data) =>
  HttpClient(`/royalSundaramKycStatus`, {
    method: "POST",
    data,
  });

//duplicate enquiry id
const duplicateEnquiry = (data) =>
  HttpClient("/createDuplicateJourney", { method: "POST", data });

//Adrila Journey Redirection
const adrila = (data) =>
  HttpClient(
    `/getVehicleDetails?enquiryId=${data?.enquiryId}&registration_no=${
      data?.registration_no
    }${data?.productSubType ? `&productSubType=${data?.productSubType}` : ""}${
      data?.section ? `&section=${data?.section}` : ""
    }${data?.type ? `&type=${data?.type}` : ""}${
      data?.vehicleValidation
        ? `&vehicleValidation=${data?.vehicleValidation}`
        : ""
    }`,
    {
      method: "GET",
    }
  );

//get all IC
const getIc = (baseUrl) =>
  HttpClient(
    baseUrl ? `${baseUrl}/getIcList` : "/getIcList",
    { method: "GET" },
    baseUrl ? true : false
  );

//get all fields
const fields = (data, baseUrl) =>
  HttpClient(
    `${baseUrl ? baseUrl : ""}/getProposalFields?company_alias=${
      data?.company_alias
    }&section=${data?.section}&owner_type=${data?.owner_type}`,
    { method: "POST", data },
    baseUrl ? true : false
  );

const setFields = (data, baseUrl) =>
  HttpClient(
    `${baseUrl ? baseUrl : ""}/addProposalfield`,
    { method: "POST", data },
    baseUrl ? true : false
  );

//get all fields
const GetOrgfields = (data) =>
  HttpClient(`/getOrganizationTypes?companyAlias=${data?.company_alias}`, {
    method: "GET",
  });

const finsall = (data) =>
  HttpClient("/finsall/saveOrUpdateBankSelector", { method: "POST", data });

const sbiColors = (data) =>   HttpClient(`/getColor?companyAlias=${data}`, { method: "GET", data });


const accessToken = (data) =>
  HttpClient("/accessToken", { method: "POST", data });

export default {
  save,
  gender,
  relations,
  financer,
  agreement,
  pincode,
  prefill,
  occupation,
  submit,
  prevIc,
  saveLeadData,
  checkAddon,
  wording,
  url,
  saveAddons,
  category,
  usage,
  otp,
  verifyOtp,
  duplicateEnquiry,
  adrila,
  getIc,
  fields,
  setFields,
  finsall,
  sbiColors,
  verifyCkycnum,
  godigitKyc,
  RSKyc,
  accessToken,
  GetOrgfields,
};
