import React from "react";
import { Table } from "react-bootstrap";
import _ from "lodash";
import { currencyFormater } from "utils";

export const PremiumTable = ({ quote }) => {
  return (
    <Table className="premiumTable">
      <tr>
        <td className="premiumValues">
          ₹ {currencyFormater(quote?.totalPremiumA1)}
        </td>
      </tr>

      <tr>
        <td className="premiumValues">
          ₹{" "}
          {currencyFormater(
            quote?.totalPremiumB1 - (quote?.tppdDiscount * 1 || 0)
          )}
        </td>
      </tr>
      <tr>
        <td className="premiumValues">
          ₹ {currencyFormater(quote?.totalAddon1)}
        </td>
      </tr>
      <tr>
        <td className="premiumValues">
          ₹ {currencyFormater(quote?.totalPremiumc1)}
        </td>
      </tr>
      <tr>
        <td className="premiumValues">₹ {currencyFormater(quote?.gst1)}</td>
      </tr>
      <tr>
        <td className="premiumValues">
          <strong> ₹ {currencyFormater(quote?.finalPremium1)}</strong>
        </td>
      </tr>
    </Table>
  );
};

export const PremiumTable1 = ({ quote }) => {
  return (
    <Table className="premiumTable">
      <tr>
        <td className="premiumValues">
          ₹ {currencyFormater(quote?.totalPremiumA1)}
        </td>
      </tr>

      <tr>
        <td className="premiumValues">
          ₹{" "}
          {currencyFormater(
            quote?.totalPremiumB1 - (quote?.tppdDiscount * 1 || 0)
          )}
        </td>
      </tr>
      <tr>
        <td className="premiumValues">
          ₹ {currencyFormater(quote?.totalAddon1)}
        </td>
      </tr>
      <tr>
        <td className="premiumValues">
          ₹ {currencyFormater(quote?.totalPremiumc1)}
        </td>
      </tr>
      <tr>
        <td className="premiumValues">₹ {currencyFormater(quote?.gst1)}</td>
      </tr>
      <tr>
        <td className="premiumValues totalPremiumDiv">
          <strong> ₹ {currencyFormater(quote?.finalPremium1)}</strong>
        </td>
      </tr>
    </Table>
  );
};
