import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import {
  CustomAccordion,
  AccordionHeader,
  AccordionContent,
  ErrorMsg,
  CustomRadio,
} from "components";
import { useMediaPredicate } from "react-media-hook";
import CustomTooltip from "../../../components/tooltip/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../../../components/inputs/checkbox/checkbox";
import { Row, Col, Form, Button, Badge } from "react-bootstrap";
import _, { isEmpty } from "lodash";
import { numOnly, numOnlyNoZero } from "utils";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import {
  setSelectedQuote,
  SetaddonsAndOthers,
  UpdateQuotesData,
  setQuotesList,
  SaveAddonsData,
} from "../quote.slice";
import {
  clear,
  AddOnList as getAddons,
  VolunaryList as getVoluntaryList,
} from "../quote.slice";
import { setTempData } from "../filterConatiner/quoteFilter.slice";
import moment from "moment";
import {
  subMonths,
  subDays,
  addDays,
  differenceInDays,
  differenceInMonths,
} from "date-fns";
import CpaPopup from "../quotesPopup/cpaPopup/cpaPopup";
import ZeroDepPopup from "../quotesPopup/zeroDepPopup/zeroDepPopup";
import { set_temp_data } from "modules/Home/home.slice";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import swal from "sweetalert";
import { CancelAll } from "modules/quotesPage/quote.slice";
import tooltip from "../../../assets/img/tooltip.svg";
// import DateInput from "../../../Home/steps/car-details/DateInput";
import DateInput from "../../Home/steps/car-details/DateInput";
import SecureLS from "secure-ls";
import ThemeObj from "modules/theme-config/theme-config";
import { toDate } from "utils";
import { BlockedSections } from "./cardConfig";
import { HiOutlineRefresh } from "react-icons/hi";

/*---------------date config----------------*/
const notSureDate = subDays(new Date(Date.now()), 90);
const formatedDate = moment(notSureDate).format("DD-MM-YYYY");
/*-----x---------date config-----x----------*/

//---------------------Theme imports----------------------------

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme1 = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;
console.log("Theme1", Theme1);
export const AddOnsCard = ({
  tab,
  type,
  setShortTerm3,
  setShortTerm6,
  policyTypeCode,
}) => {
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const userData = useSelector((state) => state.home);
  const { temp_data, theme_conf } = useSelector((state) => state.home);
  const {
    voluntaryList,
    addOnsAndOthers,
    quotesList,
    quotetThirdParty,
    quoteComprehesive,
  } = useSelector((state) => state.quotes);
  const { tempData, saveQuote } = useSelector((state) => state.quoteFilter);
  const dispatch = useDispatch();
  const { handleSubmit, register, watch, control, errors, setValue } = useForm({
    // resolver: yupResolver(),
  });

  //---------------calling voluntary list api-----------------------------

  useEffect(() => {
    if (voluntaryList?.length === 0) {
      dispatch(
        getVoluntaryList({
          productSubTypeId: temp_data?.productSubTypeId
            ? temp_data?.productSubTypeId
            : type === "bike"
            ? 2
            : 1,
        })
      );
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [voluntaryList?.length]);

  // -------------------defining all states--------------------------------
  const [accordionId, setAccordionId] = useState(0);
  const [showUpdateButtonAccesories, setShowUpdateButtonAccesories] =
    useState(false);
  const [showUpdateButtonAddtions, setShowUpdateButtonAdditions] =
    useState(false);
  const [showUpdateButtonInsurer, setShowUpdateButtonInsurer] = useState(false);
  const [showUpdateButtonDiscount, setShowUpdateButtonDiscount] =
    useState(false);
  const [showUpdateButtonVoluntary, setShowUpdateButtonVoluntary] =
    useState(false);
  const [eventKey, setEventKey] = useState(false);
  const [openAll, setOpenAll] = useState(false);
  const unNamedCover = ["₹ 1 lac", ...(type !== "bike" ? ["₹ 2 lac"] : [])];
  var newVoluntaryList = voluntaryList?.map((a) => a.deductibleAmount);
  const volDiscount = [...newVoluntaryList];
  console.log(volDiscount, "volDiscount");
  const sh3Enable =
    process.env.REACT_APP_BROKER === "ACE" &&
    type === "cv" &&
    temp_data?.journeyCategory === "PCV" &&
    temp_data?.journeySubCategory === "TAXI";
  const [annualCompPolicy, setAnnualCompPolicy] = useState(
    sh3Enable ? false : true
  );
  const [shortCompPolicy3, setShortCompPolicy3] = useState(
    sh3Enable ? true : false
  );
  const [shortCompPolicy6, setShortCompPolicy6] = useState(false || "");
  const [cpa, setCpa] = useState(
    !_.isEmpty(theme_conf?.broker_config?.cpa)
      ? theme_conf?.broker_config?.cpa === "Yes" && !temp_data?.odOnly
        ? true
        : false
      : (process.env.REACT_APP_BROKER === "ABIBL" ||
          process.env.REACT_APP_BROKER === "RB" ||
          process.env.REACT_APP_BROKER === "OLA" ||
          process.env.REACT_APP_BROKER === "SPA" ||
          process.env.REACT_APP_BROKER === "BAJAJ" ||
          process.env.REACT_APP_BROKER === "POLICYERA") &&
        !temp_data?.odOnly
      ? true
      : false
  );
  console.log({ cpa });
  const [multiCpa, setMultiCpa] = useState(false);
  const [cpaPopup, setCpaPopup] = useState(false);
  const [zDPopup, setZDPopup] = useState(false);

  //----------------------addon states-------------------------
  const [rsa, setRsa] = useState(false);
  const [zeroDep, setZeroDep] = useState(false);
  const [imt23, setImt23] = useState(false);
  const [keyReplace, setKeyReplace] = useState(false);
  const [engineProtector, setEngineProtector] = useState(false);
  const [ncbProtection, setNcbProtectiont] = useState(false);
  const [consumables, setConsumables] = useState(false);
  const [tyreSecure, setTyreSecure] = useState(false);
  const [returnToInvoice, setReturnToInvoice] = useState(false);
  const [lopb, setLopb] = useState(false);
  const [emergencyMedicalExpenses, setEmergencyMedicalExpenses] =
    useState(false);
  const [wrange, setWrange] = useState(temp_data?.selectedGvw);
  const accesories = watch("accesories");
  const selectedAccesories = accesories?.filter(Boolean) || [];
  const additional = watch("additional");
  const selectedAdditions = additional?.filter(Boolean);
  const insurer = watch("insurer");
  const selectedInsurer = insurer?.filter(Boolean);
  const discount = watch("discount");
  const selectedDiscount = discount?.filter(Boolean);
  //gcv additions
  const LLpaidItmes = watch("LLpaidItmes");
  const selectedLLpaidItmes = LLpaidItmes?.filter(Boolean) || [];
  const PApaidItmes = watch("PApaidItmes");
  //geo-extension countries
  const countries = watch("country") || [];
  const [geoCountries, settGeoCountries] = useState([]);
  console.log("countriescountries", countries);
  //input textFields
  const ElectricAmount = watch("amountElectric") || 0;
  const NonElectricAmount = watch("amountNonElectric") || 0;
  const ExternalAmount = watch("amountLpg") || 0;
  const TrailerAmount = watch("amountTrailer") || 0;
  const LLNumberDriver = watch("LLNumberDriver") || 0;
  const LLNumberConductor = watch("LLNumberConductor") || 0;
  const LLNumberCleaner = watch("LLNumberCleaner") || 0;
  const MemberId = watch("memberId") || 0;
  const [volDiscountValue, setVolDiscountValue] = useState(volDiscount[0]);
  const [unNamedCoverValue, setUnNamedCoverValue] = useState(unNamedCover[0]);
  const [paPaidDriverGCV, setPaPaidDriverGCV] = useState(unNamedCover[0]);
  const [additionalPaidDriver, setAdditionalPaidDriver] = useState(
    unNamedCover[0]
  );

  //--------------------setting Short term flag ----------------------------
  useEffect(() => {
    if (
      !_.isEmpty(temp_data) &&
      temp_data?.journeyCategory === "PCV" &&
      temp_data?.journeySubCategory === "TAXI" &&
      // _.isEmpty(temp_data?.quoteLog?.premiumJson) &&
      process.env.REACT_APP_BROKER === "ACE"
    ) {
      setShortCompPolicy3(true);
      setAnnualCompPolicy(false);
      setShortCompPolicy6(false);
    }
  }, [sh3Enable]);

  useEffect(() => {
    if (shortCompPolicy3) {
      setShortTerm3(true);
    } else {
      setShortTerm3(false);
    }
  }, [shortCompPolicy3]);

  useEffect(() => {
    if (shortCompPolicy6) {
      setShortTerm6(true);
    } else {
      setShortTerm6(false);
    }
  }, [shortCompPolicy6]);

  useEffect(() => {
    if (
      !_.isEmpty(temp_data?.addons?.compulsoryPersonalAccident) &&
      temp_data?.addons?.compulsoryPersonalAccident[0]?.reason
    ) {
      setCpa(false);
    }
    if (
      temp_data?.addons?.compulsoryPersonalAccident &&
      !temp_data?.addons?.compulsoryPersonalAccident[0]?.reason &&
      !cpa
    ) {
      setCpa(theme_conf?.broker_config?.cpa === "Yes" ? true : false);
    }
  }, [temp_data?.addons]);

  //--------------------setting tabs in redux on click of tab----------------------------

  useEffect(() => {
    if (tab === "tab2") {
      dispatch(
        set_temp_data({
          tab: "tab2",
        })
      );
    } else {
      dispatch(
        set_temp_data({
          tab: "tab1",
        })
      );
    }
  }, [tab]);

  //---------------------on submitting discount------------------------------

  const onSubmitDiscount = () => {
    dispatch(CancelAll(true)); // cancel all apis loading (quotes apis)
    var data = {
      selectedDiscount: selectedDiscount,
      volDiscountValue: volDiscountValue,
    };
    dispatch(SetaddonsAndOthers(data));
    var newSelectedDiscount = [];
    if (
      selectedDiscount?.includes(
        "Is the vehicle fitted with ARAI approved anti-theft device?"
      )
    ) {
      var newD = {
        name: "anti-theft device",
      };
      newSelectedDiscount.push(newD);
    }
    if (selectedDiscount?.includes("Voluntary Discounts")) {
      var newD = {
        name: "voluntary_insurer_discounts",
        sumInsured: volDiscountValue !== "None" ? volDiscountValue : 0,
      };
      newSelectedDiscount.push(newD);
    }
    if (selectedDiscount?.includes("Vehicle Limited to Own Premises")) {
      var newD = {
        name: "Vehicle Limited to Own Premises",
      };
      newSelectedDiscount.push(newD);
    }
    if (selectedDiscount?.includes("TPPD Cover")) {
      var newD = {
        name: "TPPD Cover",
      };
      newSelectedDiscount.push(newD);
    }
    var data1 = {
      enquiryId: userData.temp_data?.enquiry_id || enquiry_id,
      //type: "discount",
      addonData: { discounts: newSelectedDiscount },
    };
    dispatch(SaveAddonsData(data1));
    dispatch(CancelAll(false)); // resetting cancel all apis loading so quotes will restart (quotes apis)
  };

  console.log(accesories, "accesories");
  // console.log(additional, "additional")
  console.log(LLpaidItmes, "LLpaidItmes");
  //-------------------------on submitting accesories----------------------------

  const onSubmitAccesories = () => {
    if (
      _.isEmpty(electricalErr) &&
      _.isEmpty(nonElectricalErr) &&
      _.isEmpty(externalErr)
    ) {
      if (
        (accesories?.includes("Electrical Accessories") &&
          (!ElectricAmount ||
            ElectricAmount === "0" ||
            ElectricAmount === 0)) ||
        (accesories?.includes("Non-Electrical Accessories") &&
          (!NonElectricAmount ||
            NonElectricAmount === "0" ||
            NonElectricAmount === 0)) ||
        (accesories?.includes("External Bi-Fuel Kit CNG/LPG") &&
          (!ExternalAmount || ExternalAmount === "0" || ExternalAmount === 0))
      ) {
        errorAlert();
      } else {
        dispatch(CancelAll(true)); // cancel all apis loading (quotes apis)
        var data = {
          selectedAccesories: selectedAccesories,
          vehicleElectricAccessories: selectedAccesories?.includes(
            "Electrical Accessories"
          )
            ? Number(ElectricAmount)
            : 0,
          vehicleNonElectricAccessories: selectedAccesories?.includes(
            "Non-Electrical Accessories"
          )
            ? Number(NonElectricAmount)
            : 0,
          externalBiFuelKit: selectedAccesories?.includes(
            "External Bi-Fuel Kit CNG/LPG"
          )
            ? Number(ExternalAmount)
            : 0,
          trailerCover: selectedAccesories?.includes("Trailer")
            ? Number(TrailerAmount)
            : 0,
        };
        dispatch(SetaddonsAndOthers(data));

        var newSelectedAccesories = [];

        console.log("selectedAccesories", selectedAccesories);
        console.log("fueldata", temp_data?.fuel);

        if (
          selectedAccesories?.includes("External Bi-Fuel Kit CNG/LPG") &&
          temp_data?.fuel !== "CNG"
        ) {
          var newD = {
            name: "External Bi-Fuel Kit CNG/LPG",
            sumInsured: Number(ExternalAmount),
          };
          newSelectedAccesories.push(newD);
        } else {
          // let data = newSelectedAccesories.filter(x => x !== "External Bi-Fuel Kit CNG/LPG")
          // console.log("filter1", data)
          // newSelectedAccesories.push(...data)
        }
        if (selectedAccesories?.includes("Non-Electrical Accessories")) {
          var newD = {
            name: "Non-Electrical Accessories",
            sumInsured: Number(NonElectricAmount),
          };
          newSelectedAccesories.push(newD);
        }
        if (selectedAccesories?.includes("Electrical Accessories")) {
          var newD = {
            name: "Electrical Accessories",
            sumInsured: Number(ElectricAmount),
          };
          newSelectedAccesories.push(newD);
        }
        if (selectedAccesories?.includes("Trailer")) {
          var newD = {
            name: "Trailer",
            sumInsured: Number(TrailerAmount),
          };
          newSelectedAccesories.push(newD);
        }
        console.log("newSelectedAccesories", newSelectedAccesories);

        var data1 = {
          enquiryId: userData.temp_data?.enquiry_id || enquiry_id,
          addonData: { accessories: newSelectedAccesories },
        };
        dispatch(SaveAddonsData(data1));
        dispatch(CancelAll(false)); // resetting cancel all apis loading so quotes will restart (quotes apis)
      }
    }
  };

  const errorAlert = () => {
    swal("Error", "Values cannot be empty", "error");
  };

  //-------------------- on submitting additionals-------------------------------

  console.log("selectedAdditions", selectedAdditions);

  const onSubmitAdditions = () => {
    if (
      (LLpaidItmes?.includes("DriverLL") &&
        (!LLNumberDriver || LLNumberDriver === "0" || LLNumberDriver === 0)) ||
      (LLpaidItmes?.includes("ConductorLL") &&
        (!LLNumberConductor ||
          LLNumberConductor === "0" ||
          LLNumberConductor === 0)) ||
      (LLpaidItmes?.includes("CleanerLL") &&
        (!LLNumberCleaner ||
          LLNumberCleaner === "0" ||
          LLNumberCleaner === 0)) ||
      (selectedAdditions?.includes("Auto Mobile") && !MemberId)
    ) {
      errorAlert();
    } else {
      dispatch(CancelAll(true)); // cancel all apis loading (quotes apis)
      var data = {
        selectedAdditions: selectedAdditions,
        LLpaidItmes: selectedLLpaidItmes,
        unNamedCoverValue: unNamedCoverValue,
        additionalPaidDriver: additionalPaidDriver,
        LLNumberDriver: LLNumberDriver,
        LLNumberConductor: LLNumberConductor,
        LLNumberCleaner: LLNumberCleaner,
        paPaidDriverGCV: paPaidDriverGCV,
        countries: countries,
      };
      var newSelectedAddition = [];
      if (selectedAdditions?.includes("PA cover for additional paid driver")) {
        var newD = {
          name: "PA cover for additional paid driver",
          sumInsured: additionalPaidDriver === "₹ 2 lac" ? 200000 : 100000,
        };
        newSelectedAddition.push(newD);
      }
      if (selectedAdditions?.includes("Unnamed Passenger PA Cover")) {
        var newD = {
          name: "Unnamed Passenger PA Cover",
          sumInsured: unNamedCoverValue === "₹ 2 lac" ? 200000 : 100000,
        };
        newSelectedAddition.push(newD);
      }
      if (selectedAdditions?.includes("LL paid driver/conductor/cleaner")) {
        var newD = {
          name: "LL paid driver/conductor/cleaner",
          selectedLLpaidItmes: selectedLLpaidItmes,
          LLNumberDriver: selectedLLpaidItmes?.includes("DriverLL")
            ? Number(LLNumberDriver)
            : 0,
          LLNumberConductor: selectedLLpaidItmes?.includes("ConductorLL")
            ? Number(LLNumberConductor)
            : 0,
          LLNumberCleaner: selectedLLpaidItmes?.includes("CleanerLL")
            ? Number(LLNumberCleaner)
            : 0,
        };
        newSelectedAddition.push(newD);
      }
      if (selectedAdditions?.includes("PA paid driver/conductor/cleaner")) {
        var newD = {
          name: "PA paid driver/conductor/cleaner",
          sumInsured: paPaidDriverGCV === "₹ 2 lac" ? 200000 : 100000,
        };
        newSelectedAddition.push(newD);
      }
      if (selectedAdditions?.includes("Geographical Extension")) {
        var newD = {
          name: "Geographical Extension",
          countries: countries,
        };
        newSelectedAddition.push(newD);
      }
      if (selectedAdditions?.includes("LL paid driver")) {
        var newD = {
          name: "LL paid driver",
          sumInsured: 100000,
        };
        newSelectedAddition.push(newD);
      }
      var data1 = {
        enquiryId: userData.temp_data?.enquiry_id || enquiry_id,
        addonData: { additional_covers: newSelectedAddition },
      };
      dispatch(SetaddonsAndOthers(data));
      dispatch(SaveAddonsData(data1));
      dispatch(CancelAll(false)); // resetting cancel all apis loading so quotes will restart (quotes apis)
    }
  };

  const onSubmitRange = (selectedRange) => {
    console.log("selectedRange", selectedRange);
    selectedRange && dispatch(set_temp_data({ selectedGvw: selectedRange }));
  };

  //resetting cpa in case of saod ( if it's preselected)
  useEffect(() => {
    if (cpa && temp_data?.odOnly) {
      setCpa(false);
      setMultiCpa(false);
    }
  }, [temp_data?.odOnly]);
  //deselecting cpa in case of NB/TP
  useEffect(() => {
    if (
      cpa &&
      temp_data?.newCar &&
      type !== "cv" &&
      temp_data?.tab === "tab2"
    ) {
      setCpa(false);
    }
  }, [temp_data?.tab]);

  //----------updateApiAddonsAndOthers / updateQuote REquest data api functionality // updating anything from quote page
  // this below useEffect is used for updating anything from quote page written in addon as this api was made to update addons

  const [upd, SetUpd] = useState(true); //to not update on reload

  useEffect(() => {
    if (saveQuote) {
      setTimeout(() => {
        SetUpd(false);
      }, 3000);
    }
  }, [saveQuote]);

  useEffect(() => {
    if (saveQuote && !upd) {
      dispatch(CancelAll(true)); // cancel all apis loading (quotes apis)
      var data = {
        enquiryId: userData.temp_data?.enquiry_id || enquiry_id,
        vehicleIdv: tempData.idvChoosed,
        idvChangedType: tempData?.idvType,
        vehicleElectricAccessories: Number(
          addOnsAndOthers?.vehicleElectricAccessories
        ),
        vehicleNonElectricAccessories: Number(
          addOnsAndOthers?.vehicleNonElectricAccessories
        ),
        externalBiFuelKit: Number(addOnsAndOthers?.externalBiFuelKit),
        OwnerDriverPaCover: addOnsAndOthers?.selectedCpa?.includes(
          "Compulsory Personal Accident"
        )
          ? "Y"
          : "N",
        antiTheft: addOnsAndOthers?.selectedDiscount?.includes(
          "Is the vehicle fitted with ARAI approved anti-theft device?"
        )
          ? "Y"
          : "N",
        UnnamedPassengerPaCover: addOnsAndOthers?.selectedAdditions?.includes(
          "Unnamed Passenger PA Cover"
        )
          ? addOnsAndOthers?.unNamedCoverValue === "₹ 2 lac"
            ? 200000
            : 100000
          : null,

        voluntarydeductableAmount:
          addOnsAndOthers?.volDiscountValue !== "None" &&
          addOnsAndOthers?.selectedDiscount?.includes("Voluntary Discounts")
            ? addOnsAndOthers?.volDiscountValue
            : 0,
        isClaim: temp_data?.noClaimMade ? "N" : "Y",
        previousNcb: temp_data?.ncb ? temp_data?.ncb?.slice(0, -1) : 0,
        applicableNcb: temp_data?.carOwnership
          ? 0
          : temp_data?.newNcb
          ? temp_data?.newNcb?.slice(0, -1)
          : 0,

        previousInsurer:
          userData.temp_data?.prevIcFullName?.length !== "NEW"
            ? userData.temp_data?.prevIcFullName === "New"
              ? "NEW"
              : userData.temp_data?.prevIcFullName
            : "NEW",
        previousInsurerCode:
          userData.temp_data?.prevIc !== "New"
            ? userData.temp_data?.prevIc === "New"
              ? "NEW"
              : userData.temp_data?.prevIc
            : "NEW",

        manufactureYear: temp_data?.manfDate,
        policyExpiryDate:
          userData.temp_data?.expiry === "Not Sure" ||
          userData.temp_data?.expiry === "New"
            ? "New"
            : userData.temp_data?.expiry,
        vehicleRegisterDate: temp_data?.regDate,
        previousPolicyType: !temp_data?.newCar
          ? tempData?.policyType === "New"
            ? "Not sure"
            : tempData?.policyType
          : "NEW",
        ownershipChanged: temp_data?.carOwnership ? "Y" : "N",
        isIdvChanged:
          tempData.idvChoosed && tempData.idvChoosed !== 0 ? "Y" : "N",
        businessType: temp_data?.newCar
          ? "newbusiness"
          : temp_data?.breakIn
          ? "breakin"
          : "rollover",

        policyType: temp_data?.odOnly ? "own_damage" : "comprehensive",
        vehicleOwnerType: userData.temp_data?.ownerTypeId === 1 ? "I" : "C",
        version: temp_data?.versionId,
        versionName: temp_data?.versionName,
        fuelType: temp_data?.fuel,
        gcvCarrierType: temp_data?.gcvCarrierType,
        isPopupShown: temp_data?.isPopupShown === "Y" ? "Y" : "N",
        isNcbVerified: temp_data?.isNcbVerified === "Y" ? "Y" : "N",
        isRenewal: temp_data?.isRenewal === "Y" ? "Y" : "N",
        isOdDiscountApplicable:
          temp_data?.isOdDiscountApplicable === "Y" ? "Y" : "N",
        // ...(temp_data?.zeroDepInLastPolicy && {
        //   zeroDepInLastPolicy: temp_data?.zeroDepInLastPolicy,
        // }),
        zeroDepInLastPolicy:
          process.env.REACT_APP_API_BASE_URL ===
            "https://apimotor.fynity.in/api" ||
          process.env.REACT_APP_BROKER === "ABIBL"
            ? "Y"
            : temp_data?.zeroDepInLastPolicy && temp_data?.zeroDepInLastPolicy,
        rtoNumber: temp_data?.rtoNumber,
        rto: temp_data?.rtoNumber,
        rtoId: temp_data?.rtoNumber,
        rtoCode: temp_data?.rtoNumber,
        vehicleRegisterAt: temp_data?.rtoNumber,
        manfId: temp_data?.manfId,
        manfName: temp_data?.manfName,
        manfactureId: temp_data?.manfId,
        manfactureName: temp_data?.manfName,
        modelId: temp_data?.modelId,
        modelName: temp_data?.modelName,
        model: temp_data?.modelId,
        prevShortTerm: temp_data?.prevShortTerm * 1,
        isClaimVerified: temp_data?.isClaimVerified,
        isToastShown: temp_data?.isToastShown,
        isRedirectionDone: temp_data?.isRedirectionDone,
        selectedGvw: temp_data?.selectedGvw,
        defaultGvw: temp_data?.defaultGvw,
        infoToaster: temp_data?.infoToaster,
        previousPolicyTypeIdentifier: temp_data?.previousPolicyTypeIdentifier,
        isMultiYearPolicy:
          !_.isEmpty(temp_data?.regDate?.split("-")) &&
          temp_data?.regDate?.split("-")[2] * 1 === 2019 &&
          temp_data?.isMultiYearPolicy
            ? "Y"
            : "N",
        previousPolicyTypeIdentifierCode: policyTypeCode(),
        // !_.isEmpty(temp_data?.regDate?.split("-")) &&
        // temp_data?.regDate?.split("-")[2] * 1 === 2019 &&
        // temp_data?.previousPolicyTypeIdentifierCode
        //   ? temp_data?.previousPolicyTypeIdentifierCode
        //   : null,
        isNcbConfirmed: temp_data?.isNcbConfirmed,
      };

      dispatch(UpdateQuotesData(data));
      dispatch(clear());
      dispatch(CancelAll(false)); // resetting cancel all apis loading so quotes will restart (quotes apis)
    }
  }, [
    saveQuote,
    addOnsAndOthers?.selectedAccesories,
    addOnsAndOthers?.vehicleElectricAccessories,
    addOnsAndOthers?.vehicleNonElectricAccessories,
    addOnsAndOthers?.externalBiFuelKit,
    addOnsAndOthers?.selectedAdditions,
    addOnsAndOthers?.unNamedCoverValue,
    addOnsAndOthers?.additionalPaidDriver,
    addOnsAndOthers?.selectedDiscount,
    addOnsAndOthers?.volDiscountValue,
    tempData?.idvChoosed,
    tempData?.idvType,
    temp_data?.ncb,
    temp_data?.expiry,
    temp_data?.prevIc,
    userData.temp_data?.prevIcFullName,
    temp_data?.manfDate,
    temp_data?.regDate,
    userData.temp_data?.expiry,
    tempData?.policyType,
    temp_data?.noClaimMade,
    temp_data?.newCar,
    temp_data?.breakIn,
    temp_data?.carOwnership,
    temp_data?.ownerTypeId,
    temp_data?.gcvCarrierType,
    temp_data?.fuel,
    temp_data?.versionId,
    temp_data?.versionName,
    temp_data?.odOnly,
    temp_data?.reloaded,
    temp_data?.isPopupShown,
    temp_data?.isNcbVerified,
    temp_data?.zeroDepInLastPolicy,
    temp_data?.rtoNumber,
    temp_data?.prevShortTerm,
    temp_data?.isOdDiscountApplicable,
    temp_data?.isClaimVerified,
    temp_data?.isToastShown,
    temp_data?.isRedirectionDone,
    temp_data?.selectedGvw,
    temp_data?.previousPolicyTypeIdentifier,
    temp_data?.isMultiYearPolicy,
  ]);

  // useEffect(() => {
  //   if (temp_data?.fuel === "CNG") {
  //     console.log("submitacc");
  //     onSubmitAccesories();
  //     // return document?.getElementById("updateAccesoriesButton")?.click()
  //     // onSubmitAccesories()
  //   }
  // }, [temp_data?.fuel]);

  //---------------- update buttons conditions accesories-----------------------
  console.log(ElectricAmount, "ElectricAmount");

  useEffect(() => {
    if (!_.isEqual(selectedAccesories, addOnsAndOthers.selectedAccesories)) {
      setShowUpdateButtonAccesories(true);
    } else if (
      Number(ElectricAmount) !== addOnsAndOthers.vehicleElectricAccessories ||
      Number(NonElectricAmount) !==
        addOnsAndOthers.vehicleNonElectricAccessories ||
      Number(ExternalAmount) !== addOnsAndOthers.externalBiFuelKit ||
      Number(TrailerAmount) !== addOnsAndOthers.trailerCover
    ) {
      setShowUpdateButtonAccesories(true);
    } else {
      setShowUpdateButtonAccesories(false);
    }
  }, [
    selectedAccesories,
    addOnsAndOthers.selectedAccesories,
    addOnsAndOthers.vehicleElectricAccessories,
    ElectricAmount,
    NonElectricAmount,
    ExternalAmount,
    addOnsAndOthers.vehicleNonElectricAccessories,
    addOnsAndOthers.externalBiFuelKit,
    TrailerAmount,
    addOnsAndOthers.trailerCover,
  ]);

  const [updateBtnShow, setUpdateBtnShow] = useState(false);

  useEffect(() => {
    if (ElectricAmount || NonElectricAmount || ExternalAmount) {
      setUpdateBtnShow(true);
    } else {
      setUpdateBtnShow(false);
    }
  }, [ElectricAmount, NonElectricAmount, ExternalAmount]);
  console.log("addOnsAndOthers.selectedAdditions", addOnsAndOthers);
  // ------------------------update buttons conditions additions-----------------------

  console.log(selectedAdditions?.flat(1), addOnsAndOthers, temp_data);

  useEffect(() => {
    if (
      !_.isEqual(selectedAdditions, addOnsAndOthers.selectedAdditions) &&
      !temp_data?.odOnly
    ) {
      _.isEmpty(addOnsAndOthers.selectedAdditions)
        ? !_.isEqual(
            selectedAdditions?.flat(1),
            addOnsAndOthers.selectedAdditions
          ) &&
          !temp_data?.odOnly &&
          setShowUpdateButtonAdditions(true)
        : setShowUpdateButtonAdditions(true);
    } else if (
      //covers which are not available in saod
      ((unNamedCoverValue !== addOnsAndOthers?.unNamedCoverValue ||
        additionalPaidDriver !== addOnsAndOthers?.additionalPaidDriver ||
        Number(LLNumberDriver) !== Number(addOnsAndOthers?.LLNumberDriver) ||
        Number(LLNumberConductor) !==
          Number(addOnsAndOthers?.LLNumberConductor) ||
        Number(LLNumberCleaner) !== Number(addOnsAndOthers?.LLNumberCleaner) ||
        paPaidDriverGCV !== addOnsAndOthers?.paPaidDriverGCV) &&
        !temp_data?.odOnly) ||
      // covers which are available in saod
      !_.isEqual(
        addOnsAndOthers?.countries ? addOnsAndOthers?.countries : [],
        countries
      )
    ) {
      setShowUpdateButtonAdditions(true);
    } else if (
      !_.isEqual(selectedLLpaidItmes, addOnsAndOthers?.LLpaidItmes) &&
      !temp_data?.odOnly
    ) {
      setShowUpdateButtonAdditions(true);
    } else {
      setShowUpdateButtonAdditions(false);
    }
  }, [
    selectedAdditions,
    addOnsAndOthers?.selectedAdditions,
    unNamedCoverValue,
    additionalPaidDriver,
    addOnsAndOthers?.additionalPaidDriver,
    addOnsAndOthers?.unNamedCoverValue,
    LLNumberDriver,
    LLNumberConductor,
    LLNumberCleaner,
    addOnsAndOthers?.paPaidDriverGCV,
    addOnsAndOthers?.LLNumberCleaner,
    addOnsAndOthers?.LLNumberConductor,
    addOnsAndOthers?.LLNumberDriver,
    selectedLLpaidItmes,
    addOnsAndOthers?.LLpaidItmes,
    countries,
  ]);

  // -----------------update buttons conditions discount-----------------------

  useEffect(() => {
    if (!_.isEqual(selectedDiscount, addOnsAndOthers.selectedDiscount))
      setShowUpdateButtonDiscount(true);
    else if (volDiscountValue !== addOnsAndOthers.volDiscountValue) {
      setShowUpdateButtonDiscount(true);
    } else {
      setShowUpdateButtonDiscount(false);
    }
  }, [
    volDiscountValue,
    addOnsAndOthers.volDiscountValue,
    selectedDiscount,
    addOnsAndOthers.selectedDiscoun,
  ]);

  //--------------------- handleAddonChnage-------------------------

  const [prevZeroDep, setPrevZeroDep] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setPrevZeroDep(false);
    }, 1500);
  }, []);

  useEffect(() => {
    if (!upd) {
      var addons = [];
      var addons2 = [];

      if (rsa) {
        addons.push("roadSideAssistance");
        addons2.push({ name: "Road Side Assistance" });
      } else {
        addons.push(false);
        addons2.push(false);
      }

      if (zeroDep) {
        addons.push("zeroDepreciation");
        addons2.push({ name: "Zero Depreciation", prevZeroDep: "Yes" });
      } else {
        addons.push(false);
        addons2.push(false);
      }

      if (imt23) {
        addons.push("imt23");
        addons2.push({ name: "IMT - 23" });
      } else {
        addons.push(false);
        addons2.push(false);
      }

      //motor addons

      if (keyReplace) {
        addons.push("keyReplace");
        addons2.push({ name: "Key Replacement" });
      } else {
        addons.push(false);
        addons2.push(false);
      }

      if (engineProtector) {
        addons.push("engineProtector");
        addons2.push({ name: "Engine Protector" });
      } else {
        addons.push(false);
        addons2.push(false);
      }

      if (ncbProtection) {
        addons.push("ncbProtection");
        addons2.push({ name: "NCB Protection" });
      } else {
        addons.push(false);
        addons2.push(false);
      }

      if (consumables) {
        addons.push("consumables");
        addons2.push({ name: "Consumable" });
      } else {
        addons.push(false);
        addons2.push(false);
      }

      if (tyreSecure) {
        addons.push("tyreSecure");
        addons2.push({ name: "Tyre Secure" });
      } else {
        addons.push(false);
        addons2.push(false);
      }

      if (returnToInvoice) {
        addons.push("returnToInvoice");
        addons2.push({ name: "Return To Invoice" });
      } else {
        addons.push(false);
        addons2.push(false);
      }

      if (lopb) {
        addons.push("lopb");
        addons2.push({ name: "Loss of Personal Belongings" });
      } else {
        addons.push(false);
        addons2.push(false);
      }

      if (emergencyMedicalExpenses) {
        addons.push("emergencyMedicalExpenses");
        addons2.push({ name: "Emergency Medical Expenses" });
      } else {
        addons.push(false);
        addons2.push(false);
      }

      var data = {
        selectedAddons: addons.filter(Boolean),
      };
      var data1 = {
        enquiryId: userData.temp_data?.enquiry_id || enquiry_id,
        //	type: "addons",
        addonData: { addons: addons2.filter(Boolean) },
      };
      dispatch(SetaddonsAndOthers(data));
      dispatch(SaveAddonsData(data1));
    }
  }, [
    rsa,
    zeroDep,
    upd,
    imt23,
    keyReplace,
    engineProtector,
    ncbProtection,
    consumables,
    tyreSecure,
    returnToInvoice,
    lopb,
    emergencyMedicalExpenses,
  ]);

  //---------------------handdling cpa changes-------------------------

  useEffect(() => {
    if (!upd) {
      if (temp_data?.ownerTypeId === 1) {
        if (!cpa && !multiCpa) {
          // if (!tempData?.cpaReason) {
          //	setCpaPopup(true);
          // }
          var selectedCpa = [];
          var tenureConst = [];
          var data1 = {
            enquiryId: userData.temp_data?.enquiry_id || enquiry_id,
            //type: "compulsory_personal_accident",
            addonData: {
              compulsory_personal_accident: [
                {
                  reason:
                    "I have another motor policy with PA owner driver cover in my name",
                },
              ],
            },
          };

          dispatch(SaveAddonsData(data1));
        } else if (cpa) {
          //	setCpaPopup(false);
          var selectedCpa = ["Compulsory Personal Accident"];
          var tenureConst = [];
          var data1 = {
            enquiryId: userData.temp_data?.enquiry_id || enquiry_id,
            isTenure: tenureConst,
            //type: "compulsory_personal_accident",
            addonData: {
              compulsory_personal_accident: [
                { name: "Compulsory Personal Accident" },
              ],
            },
          };

          dispatch(SaveAddonsData(data1));
        } else if (multiCpa) {
          //	setCpaPopup(false);
          var selectedCpa = ["Compulsory Personal Accident"];
          var tenureConst = [type === "car" ? 3 : 5];
          var data1 = {
            enquiryId: userData.temp_data?.enquiry_id || enquiry_id,
            isTenure: tenureConst,
            //type: "compulsory_personal_accident",
            addonData: {
              compulsory_personal_accident: [
                {
                  name: "Compulsory Personal Accident",
                  tenure: type === "car" ? 3 : 5,
                },
              ],
            },
          };

          dispatch(SaveAddonsData(data1));
        }

        var data = {
          selectedCpa: selectedCpa,
          isTenure: tenureConst,
        };
        dispatch(SetaddonsAndOthers(data));
      } else if (temp_data?.ownerTypeId === 2) {
        var data2 = {
          selectedCpa: [],
        };
        dispatch(SetaddonsAndOthers(data2));
        var data1 = {
          enquiryId: userData.temp_data?.enquiry_id || enquiry_id,
          type: "compulsory_personal_accident",
          addonData: {
            compulsory_personal_accident: [
              { reason: "cpa not applicable to company" },
            ],
          },
        };
        dispatch(SaveAddonsData(data1));
      }
    }
  }, [cpa, temp_data?.ownerTypeId, upd, multiCpa]);

  // ----------------prefill addoncard-------------------------

  const [amountElectricPrefill, setAmountElectricPrefill] = useState("");
  const [amountNonElectricPrefill, setAmountNonElectricPrefill] = useState("");
  const [amountCngPrefill, setAmountEngPrefill] = useState("");
  const [amountTrailerPrefill, setAmountTrailerPrefill] = useState(0);
  const [LLCountPrefillDriver, setLLCountPrefillDriver] = useState(0);
  const [LLCountPrefillConductor, setLLCountPrefillConductor] = useState(0);
  const [LLCountPrefillCleaner, setLLCountPrefillCleaner] = useState(0);

  // const [maxIdvErrors,setMaxIdvErrors]=useState({
  //   electrical:"",
  //   nonElectrical:"",
  //   ExtrnalKit:""
  // })
  const [electricalErr, setElectricalErr] = useState("");
  const [nonElectricalErr, setNonElectricalErr] = useState("");
  const [externalErr, setExternalErr] = useState("");

  // useEffect(() => {
  //   // if(tempData.idvChoosed && tempData.idvChoosed !== 0){
  //   //   if ( ElectricAmount ) {
  //   //     ElectricAmount > tempData?.idvChoosed * 0.5
  //   //       ? setElectricalErr(
  //   //           `Value should be less than ${tempData?.idvChoosed * 0.5}`
  //   //         )
  //   //       : setElectricalErr("");
  //   //   }

  //   //   if ( NonElectricAmount ) {
  //   //     NonElectricAmount > tempData?.idvChoosed * 0.5
  //   //       ? setNonElectricalErr(
  //   //           `Value should be less than ${tempData?.idvChoosed * 0.5}`
  //   //         )
  //   //       : setNonElectricalErr("");
  //   //   }

  //   //   if ( ExternalAmount ) {
  //   //     ExternalAmount > tempData?.idvChoosed * 0.5
  //   //       ? setExternalErr(
  //   //           `Value should be less than ${tempData?.idvChoosed * 0.5}`
  //   //         )
  //   //       : setExternalErr("");
  //   //   }
  //   // } else {
  //   if (ElectricAmount && tempData?.minIdv && tempData?.minIdv > 0) {
  //     ElectricAmount > tempData?.minIdv * 0.5
  //       ? setElectricalErr(
  //           `Value should be less than ${tempData?.minIdv * 0.5}`
  //         )
  //       : setElectricalErr("");
  //   }

  //   if (NonElectricAmount && tempData?.minIdv && tempData?.minIdv > 0) {
  //     NonElectricAmount > tempData?.minIdv * 0.5
  //       ? setNonElectricalErr(
  //           `Value should be less than ${tempData?.minIdv * 0.5}`
  //         )
  //       : setNonElectricalErr("");
  //   }

  //   if (ExternalAmount && tempData?.minIdv && tempData?.minIdv > 0) {
  //     ExternalAmount > tempData?.minIdv * 0.5
  //       ? setExternalErr(`Value should be less than ${tempData?.minIdv * 0.5}`)
  //       : setExternalErr("");
  //   }
  // }, [ElectricAmount, NonElectricAmount, ExternalAmount, tempData?.minIdv]);

  // useEffect(()=>{
  //   if(tempData.idvChoosed && tempData.idvChoosed !== 0){
  //     setInputDisabled(false)
  //   } else {
  //     !tempData?.minIdv ? setInputDisabled(true) : setInputDisabled(false)
  //   }
  // },[tempData.idvChoosed,tempData?.minIdv])

  //prefill api functinalities

  useEffect(() => {
    let AddonDataPrefill = temp_data?.addons;

    let accesoriesPrefil = AddonDataPrefill?.accessories?.map(
      (item) => item.name
    );

    let additionalCoversPrefil = AddonDataPrefill?.additionalCovers?.map(
      (item) => item.name
    );

    let discountPrefill = AddonDataPrefill?.discounts?.map((item) => item.name);

    let addonPrefill = AddonDataPrefill?.addons?.map((item) => item.name);

    let cpaPrefill = _.compact(
      AddonDataPrefill?.compulsoryPersonalAccident?.map((item) => item.name)
    );

    let isTenure = _.compact(
      AddonDataPrefill?.compulsoryPersonalAccident?.map((item) => item.tenure)
    );
    console.log("isTenureisTenure", _.compact(isTenure));
    let AmountElectrical = _.filter(AddonDataPrefill?.accessories, {
      name: "Electrical Accessories",
    }).map((v) => v.sumInsured);
    let AmountNonElectrical = _.filter(AddonDataPrefill?.accessories, {
      name: "Non-Electrical Accessories",
    }).map((v) => v.sumInsured);
    let AmountCng = _.filter(AddonDataPrefill?.accessories, {
      name: "External Bi-Fuel Kit CNG/LPG",
    }).map((v) => v.sumInsured);

    let AmountTrailer = _.filter(AddonDataPrefill?.accessories, {
      name: "Trailer",
    }).map((v) => v.sumInsured);

    let AmountPaCover = _.filter(AddonDataPrefill?.additionalCovers, {
      name: "PA cover for additional paid driver",
    }).map((v) => v.sumInsured);

    let AmountUnNamedCover = _.filter(AddonDataPrefill?.additionalCovers, {
      name: "Unnamed Passenger PA Cover",
    }).map((v) => v.sumInsured);

    let LLItmesPrefill = _.filter(AddonDataPrefill?.additionalCovers, {
      name: "LL paid driver/conductor/cleaner",
    }).map((v) => v.selectedLLpaidItmes);

    let LLCountDriver = _.filter(AddonDataPrefill?.additionalCovers, {
      name: "LL paid driver/conductor/cleaner",
    }).map((v) => v.lLNumberDriver);
    let LLCountConductor = _.filter(AddonDataPrefill?.additionalCovers, {
      name: "LL paid driver/conductor/cleaner",
    }).map((v) => v.lLNumberConductor);
    let LLCountCleaner = _.filter(AddonDataPrefill?.additionalCovers, {
      name: "LL paid driver/conductor/cleaner",
    }).map((v) => v.lLNumberCleaner);

    let AmountPaCoverGCV = _.filter(AddonDataPrefill?.additionalCovers, {
      name: "PA paid driver/conductor/cleaner",
    }).map((v) => v.sumInsured);

    let AmountVolIns = _.filter(AddonDataPrefill?.discounts, {
      name: "voluntary_insurer_discounts",
    }).map((v) => v.sumInsured);

    let prefillCountries = _.filter(AddonDataPrefill?.additionalCovers, {
      name: "Geographical Extension",
    }).map((v) => v.countries);

    //prefilll accesories
    console.log("cccp", cpa, isTenure);

    var newAccesoriesPrefilled = [];
    if (accesoriesPrefil?.includes("Electrical Accessories")) {
      newAccesoriesPrefilled.push("Electrical Accessories");
      setAmountElectricPrefill(AmountElectrical[0]);
    } else {
      newAccesoriesPrefilled.push(false);
    }
    if (accesoriesPrefil?.includes("Non-Electrical Accessories")) {
      newAccesoriesPrefilled.push("Non-Electrical Accessories");
      setAmountNonElectricPrefill(AmountNonElectrical[0]);
    } else {
      newAccesoriesPrefilled.push(false);
    }
    if (
      accesoriesPrefil?.includes("External Bi-Fuel Kit CNG/LPG")
      // ||
      // Number(temp_data?.vehicleLpgCngKitValue) > 0
    ) {
      newAccesoriesPrefilled.push("External Bi-Fuel Kit CNG/LPG");

      if (accesoriesPrefil?.includes("External Bi-Fuel Kit CNG/LPG")) {
        setAmountEngPrefill(AmountCng[0]);
      } else {
        //	setAmountEngPrefill(Number(temp_data?.vehicleLpgCngKitValue));
      }
    } else {
      newAccesoriesPrefilled.push(false);
    }
    if (accesoriesPrefil?.includes("Trailer")) {
      newAccesoriesPrefilled.push("Trailer");
      setAmountTrailerPrefill(AmountTrailer[0]);
    } else {
      newAccesoriesPrefilled.push(false);
    }

    setValue("accesories", newAccesoriesPrefilled);

    //prefilll additions

    var newAdditionsPrefilled = [];
    var newLLPrefillItmes = [];
    if (
      additionalCoversPrefil?.includes("PA cover for additional paid driver")
    ) {
      newAdditionsPrefilled.push("PA cover for additional paid driver");
    } else {
      newAdditionsPrefilled.push(false);
    }
    if (additionalCoversPrefil?.includes("Unnamed Passenger PA Cover")) {
      newAdditionsPrefilled.push("Unnamed Passenger PA Cover");
    } else {
      newAdditionsPrefilled.push(false);
    }
    if (additionalCoversPrefil?.includes("LL paid driver/conductor/cleaner")) {
      if (LLItmesPrefill[0]?.includes("DriverLL")) {
        newLLPrefillItmes.push("DriverLL");
        setLLCountPrefillDriver(LLCountDriver[0]);
      } else {
        newLLPrefillItmes.push(false);
      }
      if (LLItmesPrefill[0]?.includes("ConductorLL")) {
        newLLPrefillItmes.push("ConductorLL");
        setLLCountPrefillConductor(LLCountConductor[0]);
      } else {
        newLLPrefillItmes.push(false);
      }
      if (LLItmesPrefill[0]?.includes("CleanerLL")) {
        newLLPrefillItmes.push("CleanerLL");
        setLLCountPrefillCleaner(LLCountCleaner[0]);
      } else {
        newLLPrefillItmes.push(false);
      }

      newAdditionsPrefilled.push("LL paid driver/conductor/cleaner");
    } else {
      newAdditionsPrefilled.push(false);
    }
    if (additionalCoversPrefil?.includes("PA paid driver/conductor/cleaner")) {
      newAdditionsPrefilled.push("PA paid driver/conductor/cleaner");
    } else {
      newAdditionsPrefilled.push(false);
    }
    if (additionalCoversPrefil?.includes("LL paid driver")) {
      newAdditionsPrefilled.push("LL paid driver");
    } else {
      newAdditionsPrefilled.push(false);
    }
    if (additionalCoversPrefil?.includes("Geographical Extension")) {
      newAdditionsPrefilled.push("Geographical Extension");
    } else {
      newAdditionsPrefilled.push(false);
    }

    setValue("additional", newAdditionsPrefilled);

    setTimeout(() => {
      !_.isEmpty(prefillCountries) && setValue("country", prefillCountries[0]);
    }, 2000);
    setTimeout(() => {
      setValue("LLpaidItmes", newLLPrefillItmes);
    }, 2000);
    setUnNamedCoverValue(
      AmountUnNamedCover[0] === 200000 ? "₹ 2 lac" : "₹ 1 lac"
    );
    setAdditionalPaidDriver(
      AmountPaCover[0] === 200000 ? "₹ 2 lac" : "₹ 1 lac"
    );

    setPaPaidDriverGCV(AmountPaCoverGCV[0] === 200000 ? "₹ 2 lac" : "₹ 1 lac");

    //prefillLLpaidDriverItems

    //prefill discount

    var newDiscountPrefilled = [];
    if (discountPrefill?.includes("anti-theft device")) {
      newDiscountPrefilled.push(
        "Is the vehicle fitted with ARAI approved anti-theft device?"
      );
    } else {
      newDiscountPrefilled.push(false);
    }
    if (discountPrefill?.includes("voluntary_insurer_discounts")) {
      newDiscountPrefilled.push("Voluntary Discounts");
    } else {
      newDiscountPrefilled.push(false);
    }

    if (discountPrefill?.includes("Vehicle Limited to Own Premises")) {
      newDiscountPrefilled.push("Vehicle Limited to Own Premises");
    } else {
      newDiscountPrefilled.push(false);
    }

    if (discountPrefill?.includes("TPPD Cover")) {
      newDiscountPrefilled.push("TPPD Cover");
    } else {
      newDiscountPrefilled.push(false);
    }

    setValue("discount", newDiscountPrefilled);

    setVolDiscountValue(
      AmountVolIns ? (AmountVolIns[0] > 0 ? AmountVolIns[0] : 2500) : 2500
    );

    //prefill addon

    let prevZeroDep = _.filter(AddonDataPrefill?.addons, {
      name: "Zero Depreciation",
    }).map((v) => v.prevZeroDep);

    if (prevZeroDep && prevZeroDep[0] === "Yes") {
      setPrevZeroDep(true);
    } else {
      setPrevZeroDep(false);
    }

    if (addonPrefill?.includes("Road Side Assistance")) {
      setRsa(true);
    } else {
      setRsa(false);
    }
    if (addonPrefill?.includes("Zero Depreciation")) {
      setZeroDep(true);
    } else {
      setZeroDep(false);
    }
    if (addonPrefill?.includes("IMT - 23")) {
      setImt23(true);
    } else {
      setImt23(false);
    }

    //mototaddonprefill

    if (addonPrefill?.includes("Key Replacement")) {
      setKeyReplace(true);
    } else {
      setKeyReplace(false);
    }
    if (addonPrefill?.includes("Engine Protector")) {
      setEngineProtector(true);
    } else {
      setEngineProtector(false);
    }
    if (addonPrefill?.includes("NCB Protection")) {
      setNcbProtectiont(true);
    } else {
      setNcbProtectiont(false);
    }
    if (addonPrefill?.includes("Consumable")) {
      setConsumables(true);
    } else {
      setConsumables(false);
    }
    if (addonPrefill?.includes("Tyre Secure")) {
      setTyreSecure(true);
    } else {
      setTyreSecure(false);
    }
    if (addonPrefill?.includes("Return To Invoice")) {
      setReturnToInvoice(true);
    } else {
      setReturnToInvoice(false);
    }
    if (addonPrefill?.includes("Loss of Personal Belongings")) {
      setLopb(true);
    } else {
      setLopb(false);
    }
    if (addonPrefill?.includes("Emergency Medical Expenses")) {
      setEmergencyMedicalExpenses(true);
    } else {
      setEmergencyMedicalExpenses(false);
    }
    //prefill Cpa
    if (!_.isEmpty(cpaPrefill)) {
      if (
        !cpaPrefill?.includes("Compulsory Personal Accident") ||
        temp_data?.odOnly
      ) {
        setCpa(false);
        setMultiCpa(false);
      } else {
        if (!_.isEmpty(_.compact(isTenure))) {
          setMultiCpa(true);
          setCpa(false);
        } else {
          // if(temp_data?.addons?.compulsoryPersonalAccident[0]?.reason
          //   && temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y"){
          //   setCpa(false)
          //  } else {
          setCpa(true);
          //  }
          setMultiCpa(false);
        }
      }
    }
    let cpaPrefillReason = AddonDataPrefill?.compulsoryPersonalAccident?.map(
      (item) => item.reason
    );
    if (cpaPrefillReason) {
      if (cpaPrefillReason.length !== 0) {
        dispatch(
          setTempData({
            cpaReason: cpaPrefillReason[0],
          })
        );
      } else {
        dispatch(
          setTempData({
            cpaReason: false,
          })
        );
      }
    }
    //-----------------prefill redux---------------------------

    var dataRedux = {
      selectedCpa:
        temp_data?.ownerTypeId === 1 &&
        (cpa || multiCpa) &&
        cpaPrefill?.includes("Compulsory Personal Accident")
          ? ["Compulsory Personal Accident"]
          : [],
      isTenure: _.compact(isTenure),
      selectedAccesories: newAccesoriesPrefilled?.filter(Boolean),
      vehicleElectricAccessories: newAccesoriesPrefilled?.includes(
        "Electrical Accessories"
      )
        ? AmountElectrical[0]
        : 0,
      vehicleNonElectricAccessories: newAccesoriesPrefilled?.includes(
        "Non-Electrical Accessories"
      )
        ? AmountNonElectrical[0]
        : 0,
      externalBiFuelKit: newAccesoriesPrefilled?.includes(
        "External Bi-Fuel Kit CNG/LPG"
      )
        ? AmountCng[0]
        : 0,
      trailerCover: newAccesoriesPrefilled?.includes("Trailer")
        ? AmountTrailer[0]
        : 0,

      selectedAdditions: newAdditionsPrefilled?.filter(Boolean),
      unNamedCoverValue:
        AmountUnNamedCover[0] === 200000 ? "₹ 2 lac" : "₹ 1 lac",
      additionalPaidDriver: AmountPaCover[0] === 200000 ? "₹ 2 lac" : "₹ 1 lac",
      paPaidDriverGCV: AmountPaCoverGCV[0] === 200000 ? "₹ 2 lac" : "₹ 1 lac",

      LLNumberDriver: newAdditionsPrefilled?.includes(
        "LL paid driver/conductor/cleaner"
      )
        ? LLCountDriver[0] > 0
          ? Number(LLCountDriver[0])
          : 0
        : 0,
      LLNumberConductor: newAdditionsPrefilled?.includes(
        "LL paid driver/conductor/cleaner"
      )
        ? LLCountConductor[0] > 0
          ? Number(LLCountConductor[0])
          : 0
        : 0,
      LLNumberCleaner: newAdditionsPrefilled?.includes(
        "LL paid driver/conductor/cleaner"
      )
        ? Number(LLCountCleaner[0]) > 0
          ? Number(LLCountCleaner[0])
          : 0
        : 0,

      selectedDiscount: newDiscountPrefilled?.filter(Boolean),
      volDiscountValue: AmountVolIns
        ? AmountVolIns[0] > 0
          ? AmountVolIns[0]
          : 2500
        : 2500,
      selectedInsurer: [],

      LLpaidItmes: newAdditionsPrefilled?.includes(
        "LL paid driver/conductor/cleaner"
      )
        ? newLLPrefillItmes?.filter(Boolean)
        : [],
      countries: !_.isEmpty(prefillCountries) ? prefillCountries[0] : [],
    };

    dispatch(SetaddonsAndOthers(dataRedux));
  }, [temp_data?.addons, temp_data?.vehicleLpgCngKitValue]);

  // useEffect(() => {
  //   let addonPrefill = temp_data?.addons?.addons?.map((item) => item.name);
  //   console.log("addonPrefill", addonPrefill);
  //   if (!addonPrefill || _.isEmpty(addonPrefill)) {
  //     setNcbProtectiont(
  //       theme_conf?.broker_config?.broker === process.env.REACT_APP_BROKER
  //         ? theme_conf?.broker_config?.ncbconfig === "Yes"
  //           ? true
  //           : false
  //         : false
  //     );
  //   }
  // }, [temp_data?.addons?.addons]);

  //------------------GCV journey flag setting--------------------------

  const [gcvJourney, setgcvJourney] = useState(false);

  useEffect(() => {
    if (temp_data?.journeyCategory === "GCV") {
      setgcvJourney(true);
    } else {
      setgcvJourney(false);
    }
  }, [gcvJourney, temp_data?.journeyCategory]);

  //----------------- car/bike flag setting--------------------------

  const [motor, setMotor] = useState(false);
  const [bike, setBike] = useState(false);
  useEffect(() => {
    if (type === "car") {
      setMotor(true);
    } else if (type === "bike") {
      setBike(true);
    } else {
      setMotor(false);
      setBike(false);
    }
  }, [type]);

  //-----------------------validation gcv inputs for driver/conductor--------------------

  const handleChangeCountLL = (name) => {
    if (name === "LLNumberDriver") {
      if (Number(LLNumberDriver) > 3) {
        swal("Info", "Driver number cannot be more than 3", "info").then(() =>
          setValue("LLNumberDriver", "")
        );
      }
    }
    if (name === "LLNumberConductor") {
      if (Number(LLNumberConductor) > 3) {
        swal("Info", "Conductor number cannot be more than 3", "info").then(
          () => setValue("LLNumberConductor", "")
        );
      }
    }
    if (name === "LLNumberCleaner") {
      if (Number(LLNumberCleaner) > 3) {
        swal("Info", "Cleaner number cannot be more than 3", "info").then(() =>
          setValue("LLNumberCleaner", "")
        );
      }
    }
  };

  //---------------------------clear all button  (clearing all selections on addon card)-------------------------

  const [clearAll, setClearAll] = useState(0);

  const [clearButtonCondition, setClearButtonCondition] = useState(false);

  useEffect(() => {
    if (clearAll > 0) {
      setCpa(false);
      setMultiCpa(false);
      setRsa(false);
      setZeroDep(false);
      setImt23(false);
      setKeyReplace(false);
      setEngineProtector(false);
      setNcbProtectiont(false);
      setConsumables(false);
      setTyreSecure(false);
      setReturnToInvoice(false);
      setLopb(false);
      setEmergencyMedicalExpenses(false);

      if (
        !_.isEmpty(selectedDiscount) ||
        !_.isEmpty(selectedAdditions) ||
        !_.isEmpty(selectedAccesories)
      ) {
        setValue("accesories", [false, false, false, false]);
        setValue("additional", [false, false, false, false, false, false]);
        setValue("discount", [false, false, false, false]);

        var data1 = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          addonData: {
            accessories: [],
            discounts: [],
            additional_covers: [],
          },
        };

        dispatch(SaveAddonsData(data1));

        var data = {
          selectedDiscount: [],
          selectedAdditions: [],
          selectedAccesories: [],
          vehicleElectricAccessories: 0,
          vehicleNonElectricAccessories: 0,
          externalBiFuelKit: 0,
          trailerCover: 0,
          LLNumberDriver: 0,
          LLNumberConductor: 0,
          LLNumberCleaner: 0,
          LLpaidItmes: [],
          countries: [],
          isTenure: [],
          selectedCpa: [],
        };
        dispatch(CancelAll(true)); // cancel all apis loading (quotes apis)
        dispatch(SetaddonsAndOthers(data));
        dispatch(CancelAll(false)); // resetting cancel all apis loading so quotes will restart (quotes apis)
      }
    }
  }, [clearAll]);

  useEffect(() => {
    if (cpa || multiCpa) {
      setClearButtonCondition(true);
    } else if (!_.isEmpty(multiCpa)) {
      setClearButtonCondition(true);
    } else if (!_.isEmpty(addOnsAndOthers?.selectedAddons)) {
      setClearButtonCondition(true);
    } else if (!_.isEmpty(selectedDiscount)) {
      setClearButtonCondition(true);
    } else if (!_.isEmpty(selectedAdditions)) {
      setClearButtonCondition(true);
    } else if (!_.isEmpty(selectedAccesories)) {
      setClearButtonCondition(true);
    } else {
      setClearButtonCondition(false);
    }
  }, [
    selectedDiscount,
    selectedAccesories,
    selectedAdditions,
    addOnsAndOthers?.selectedAddons,
    cpa,
    multiCpa,
  ]);

  //quick save for additionals for non gcv journey

  // useEffect(() => {
  // 	if (!gcvJourney) {
  // 		setTimeout(() => {
  // 			document.getElementById(`updateAdditionsButton`) &&
  // 				document.getElementById(`updateAdditionsButton`).click();
  // 		}, 500);
  // 	}
  // }, [showUpdateButtonAddtions]);

  const selectedDate = watch("date1");

  const handleValueChange = (date) => {
    let newDate = moment(date).format("DD-MM-YYYY");
    // console.log(newDate, "newdate")
    setValue("date1", newDate);
  };

  // useEffect(() => {
  //   setValue("date1", selectedDate)
  // }, [selectedDate])
  const extPath = `${
    process.env.REACT_APP_BASENAME !== "NA"
      ? `/${process.env.REACT_APP_BASENAME}`
      : ""
  }`;
  return (
    <CardOtherItem style={{ overflow: "visible" }}>
      {clearButtonCondition && (
        <ClearAllButton
          id="clearAllAddons"
          onClick={() => {
            setClearAll(clearAll + 1);
          }}
        >
          Clear all
          {/* <img
            className="clearImage"
            src={`${extPath}/assets/images/clearAll.png`}
          /> */}
          <HiOutlineRefresh className="clearImage" />
        </ClearAllButton>
      )}
      <Col
        lg={12}
        md={12}
        style={{
          textAlign: "left",
          padding: lessthan767 ? "0px 12px 16px 12px" : "16px 12px",
        }}
      >
        <ColllapseAllContainer
          onClick={() => {
            if (!eventKey) {
              setEventKey(true);
              setAccordionId(accordionId + 1);
              //setClearAll(clearAll + 1);
            } else {
              setOpenAll(true);
            }
          }}
        >
          <Badge
            variant="outline-dark"
            size="sm"
            style={{
              cursor: "pointer",
              border: "1px solid #6b6e71",
            }}
          >
            {eventKey ? (
              <AddIcon style={{ color: "#6b6e71", fontSize: "15px" }} />
            ) : (
              <RemoveIcon style={{ color: "#6b6e71", fontSize: "15px" }} />
            )}
          </Badge>
        </ColllapseAllContainer>

        <AddOnTitle className="d-flex pl-1">
          <span>
            <CustomTooltip
              rider="true"
              id="RiderInbuilt__Tooltip"
              place={"right"}
              customClassName="mt-3 riderPageTooltip "
            >
              <div
                data-tip="<h3 >Addons and Covers</h3> <div>Additional covers which you may add in your policy for better financial protection of your car or the individuals traveling in your car.</div>"
                data-html={true}
                data-for="RiderInbuilt__Tooltip"
              >
                Addons & Covers
              </div>
            </CustomTooltip>
          </span>
        </AddOnTitle>
        <Col md={12} style={{ padding: "0" }}>
          <AccordionTab>
            {((tab === "tab1" &&
              (process.env?.REACT_APP_BROKER === "OLA" ||
                process.env?.REACT_APP_BROKER === "ACE" ||
                process.env?.REACT_APP_BROKER === "FYNTUNE") &&
              type === "cv" &&
              temp_data?.journeyCategory === "PCV" &&
              temp_data?.journeySubCategory === "TAXI") ||
              "") && (
              <CustomAccordion
                noPadding
                eventKey={eventKey}
                defaultOpen
                setEventKey={setEventKey}
                id={`planType${accordionId}`}
                openAll={openAll}
                setOpenAll={setOpenAll}
                disabled={lessthan767 ? true : false}
              >
                <AccordionHeader quotes={lessthan767 ? true : false}>
                  Plan Type{" "}
                </AccordionHeader>
                <AccordionContent>
                  <CardBlock>
                    <>
                      <FilterMenuBoxCheckConatiner>
                        <div className="filterMenuBoxCheck">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={"Annual Policy (1 yr OD + 1 yr TP)"}
                            value={"Annual Policy (1 yr OD + 1 yr TP)"}
                            defaultChecked={annualCompPolicy}
                            //value={cpa}
                            checked={annualCompPolicy}
                            onChange={(e) => {
                              setShortCompPolicy3(false);
                              setAnnualCompPolicy(true);
                              setShortCompPolicy6(false);
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor={"Annual Policy (1 yr OD + 1 yr TP)"}
                          >
                            {"Annual Policy (1 yr OD + 1 yr TP)"}{" "}
                          </label>

                          <span style={{ marginLeft: "3px" }}></span>
                        </div>
                      </FilterMenuBoxCheckConatiner>
                      <FilterMenuBoxCheckConatiner>
                        <div className="filterMenuBoxCheck">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={"Short Term Policy (3 months)"}
                            value={"Short Term Policy (3 months)"}
                            defaultChecked={shortCompPolicy3}
                            //value={cpa}
                            checked={shortCompPolicy3}
                            onChange={(e) => {
                              setShortCompPolicy3(true);
                              setShortCompPolicy6(false);
                              setAnnualCompPolicy(false);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"Short Term Policy (3 months)"}
                          >
                            {"Short Term Policy (3 months)"}{" "}
                          </label>

                          <span style={{ marginLeft: "3px" }}></span>
                        </div>
                      </FilterMenuBoxCheckConatiner>
                      <FilterMenuBoxCheckConatiner>
                        <div className="filterMenuBoxCheck">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={"Short Term Policy (6 months)"}
                            value={"Short Term Policy (6 months)"}
                            defaultChecked={shortCompPolicy6}
                            //value={cpa}
                            checked={shortCompPolicy6}
                            onChange={(e) => {
                              setShortCompPolicy6(true);
                              setShortCompPolicy3(false);
                              setAnnualCompPolicy(false);
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor={"Short Term Policy (6 months)"}
                          >
                            {"Short Term Policy (6 months)"}{" "}
                          </label>

                          <span style={{ marginLeft: "3px" }}></span>
                        </div>
                      </FilterMenuBoxCheckConatiner>
                    </>
                  </CardBlock>
                </AccordionContent>
              </CustomAccordion>
            )}

            {temp_data?.ownerTypeId === 1 && !temp_data?.odOnly && (
              <CustomAccordion
                noPadding
                eventKey={eventKey}
                defaultOpen
                setEventKey={setEventKey}
                id={`cpa${accordionId}`}
                openAll={openAll}
                setOpenAll={setOpenAll}
                disabled={lessthan767 ? true : false}
              >
                <AccordionHeader quotes={lessthan767 ? true : false}>
                  CPA{" "}
                </AccordionHeader>
                <AccordionContent>
                  <CardBlock>
                    <>
                      {!(
                        temp_data?.newCar &&
                        type !== "cv" &&
                        temp_data?.tab === "tab2"
                      ) && (
                        <FilterMenuBoxCheckConatiner>
                          <div className="filterMenuBoxCheck">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={"Compulsory Personal Accident"}
                              value={"Compulsory Personal Accident"}
                              defaultChecked={cpa}
                              //value={cpa}
                              checked={cpa}
                              onChange={(e) => {
                                setCpa(!cpa);
                              }}
                              onClick={() => setMultiCpa(false)}
                            />

                            <CustomTooltip
                              rider="true"
                              id="cpa1__Tooltipvol"
                              place={"right"}
                              customClassName="mt-3  "
                            >
                              <label
                                data-tip={
                                  !lessthan767 &&
                                  "<h3 >Compulsory Personal Accident</h3> <div>Compulsory Personal Accident cover protects you against partial, total disability, or death caused due to an accident. As per the IRDAI notice. Personal Accident (PA) Cover is mandatory if the car is owned by an individual.</div>"
                                }
                                data-html={!lessthan767 && true}
                                data-for={!lessthan767 && "cpa1__Tooltipvol"}
                                className="form-check-label"
                                htmlFor={"Compulsory Personal Accident"}
                              >
                                {"Compulsory Personal Accident"}{" "}
                              </label>
                            </CustomTooltip>

                            <span style={{ marginLeft: "3px" }}>
                              {lessthan767 ? (
                                <CustomTooltip
                                  rider="true"
                                  id="cpa1__Tooltipvol_m"
                                  place={"right"}
                                  customClassName="mt-3 "
                                  allowClick
                                >
                                  <img
                                    data-tip="<h3 >Compulsory Personal Accident</h3> <div>Compulsory Personal Accident cover protects you against partial, total disability, or death caused due to an accident. As per the IRDAI notice. Personal Accident (PA) Cover is mandatory if the car is owned by an individual.</div>"
                                    data-html={true}
                                    data-for="cpa1__Tooltipvol_m"
                                    src={tooltip}
                                    alt="tooltip"
                                    // className="toolTipRiderChild"
                                  />
                                </CustomTooltip>
                              ) : (
                                <noscript />
                              )}
                            </span>
                          </div>
                        </FilterMenuBoxCheckConatiner>
                      )}
                      {type !== "cv" && temp_data?.newCar && (
                        <FilterMenuBoxCheckConatiner>
                          <div className="filterMenuBoxCheck">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={"Compulsory Personal Accident 1"}
                              value={"Compulsory Personal Accident 1"}
                              defaultChecked={multiCpa}
                              //value={multiCpa}
                              checked={multiCpa}
                              onChange={(e) => {
                                setMultiCpa(!multiCpa);
                              }}
                              onClick={() => setCpa(false)}
                            />

                            <CustomTooltip
                              rider="true"
                              id="cpa1__Tooltipvol"
                              place={"right"}
                              customClassName="mt-3  "
                            >
                              <label
                                data-tip={
                                  !lessthan767 &&
                                  "<h3 >Compulsory Personal Accident</h3> <div>Compulsory Personal Accident cover protects you against partial, total disability, or death caused due to an accident. As per the IRDAI notice. Personal Accident (PA) Cover is mandatory if the car is owned by an individual.</div>"
                                }
                                data-html={!lessthan767 && true}
                                data-for={!lessthan767 && "cpa1__Tooltipvol"}
                                className="form-check-label"
                                htmlFor={"Compulsory Personal Accident 1"}
                              >
                                {`Compulsory Personal Accident (${
                                  type === "car" ? "3" : "5"
                                } years)`}{" "}
                              </label>
                            </CustomTooltip>

                            <span style={{ marginLeft: "3px" }}>
                              {lessthan767 ? (
                                <CustomTooltip
                                  rider="true"
                                  id="cpa1__Tooltipvol_m"
                                  place={"right"}
                                  customClassName="mt-3 "
                                  allowClick
                                >
                                  <img
                                    data-tip="<h3 >Compulsory Personal Accident</h3> <div>Compulsory Personal Accident cover protects you against partial, total disability, or death caused due to an accident. As per the IRDAI notice. Personal Accident (PA) Cover is mandatory if the car is owned by an individual.</div>"
                                    data-html={true}
                                    data-for="cpa1__Tooltipvol_m"
                                    src={tooltip}
                                    alt="tooltip"
                                    // className="toolTipRiderChild"
                                  />
                                </CustomTooltip>
                              ) : (
                                <noscript />
                              )}
                            </span>
                          </div>
                        </FilterMenuBoxCheckConatiner>
                      )}
                    </>
                  </CardBlock>
                </AccordionContent>
              </CustomAccordion>
            )}
            <CustomAccordion
              id={`addons${accordionId}`}
              noPadding
              defaultOpen
              eventKey={eventKey}
              setEventKey={setEventKey}
              openAll={openAll}
              setOpenAll={setOpenAll}
              //disable={tab === "tab1" ? true : false}
              disabled={lessthan767 ? true : false}
            >
              <AccordionHeader quotes={lessthan767 ? true : false}>
                Addons
              </AccordionHeader>
              <AccordionContent>
                {tab === "tab1" && (
                  //!shortCompPolicy3 && !shortCompPolicy6 &&
                  <CardBlock>
                    <FilterMenuBoxCheckConatiner>
                      <div className="filterMenuBoxCheck">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={"Zero Depreciation"}
                          value={"Zero Depreciation"}
                          defaultChecked={zeroDep}
                          checked={zeroDep}
                          // value={zeroDep}
                          onChange={(e) => {
                            setZeroDep(e.target.checked);
                          }}
                        />

                        <CustomTooltip
                          rider="true"
                          id="zero__Tooltipvol"
                          place={"right"}
                          customClassName="mt-3"
                        >
                          <label
                            data-tip={
                              !lessthan767 &&
                              "<h3 >Zero Depreciation</h3> <div>Also called Nil Depreciation cover or Bumper-to-Bumper cover. An add-on which gives you complete cover on any body parts of the car excluding tyres and batteries. Insurer will pay entire cost of body parts, ignoring the year-on-year depreciation in value of these parts.</div>"
                            }
                            data-html={!lessthan767 && true}
                            data-for={!lessthan767 && "zero__Tooltipvol"}
                            className={"form-check-label"}
                            htmlFor={"Zero Depreciation"}
                          >
                            {"Zero Depreciation"}{" "}
                          </label>
                        </CustomTooltip>
                        <span style={{ marginLeft: "3px" }}>
                          {lessthan767 ? (
                            <CustomTooltip
                              rider="true"
                              id="zero__Tooltipvol_m"
                              place={"left"}
                              customClassName="mt-3 "
                              allowClick
                            >
                              <img
                                data-tip="<h3 >Zero Depreciation</h3> <div>Also called Nil Depreciation cover or Bumper-to-Bumper cover. An add-on which gives you complete cover on any body parts of the car excluding tyres and batteries. Insurer will pay entire cost of body parts, ignoring the year-on-year depreciation in value of these parts.</div>"
                                data-html={true}
                                data-for="zero__Tooltipvol_m"
                                src={tooltip}
                                alt="tooltip"
                                // className="toolTipRiderChild"
                              />
                            </CustomTooltip>
                          ) : (
                            <noscript />
                          )}
                        </span>
                      </div>
                    </FilterMenuBoxCheckConatiner>

                    {temp_data?.parent?.productSubTypeCode !==
                      "MISCELLANEOUS-CLASS" && (
                      <FilterMenuBoxCheckConatiner>
                        <div className="filterMenuBoxCheck">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={"Road Side Assistance"}
                            value={"Road Side Assistance"}
                            // value={rsa}
                            defaultChecked={rsa}
                            //value={cpa}
                            checked={rsa}
                            onChange={(e) => {
                              setRsa(e.target.checked);
                            }}
                          />

                          <CustomTooltip
                            rider="true"
                            id="rsa__Tooltipvol"
                            place={"right"}
                            customClassName="mt-3  "
                          >
                            <label
                              data-tip={
                                !lessthan767 &&
                                "<h3 >Road Side Assistance</h3> <div>Roadside Assistance Coverage means a professional technician comes to your rescue when your car breaks down in the middle of the journey leaving you stranded.</div>"
                              }
                              data-html={!lessthan767 && true}
                              data-for={!lessthan767 && "rsa__Tooltipvol"}
                              className="form-check-label"
                              htmlFor={"Road Side Assistance"}
                            >
                              {"Road Side Assistance"}{" "}
                            </label>
                          </CustomTooltip>
                          <span style={{ marginLeft: "3px" }}>
                            {lessthan767 ? (
                              <CustomTooltip
                                rider="true"
                                id="rsa__Tooltipvol_m"
                                place={"left"}
                                customClassName="mt-3 "
                                allowClick
                              >
                                <img
                                  data-tip="<h3 >Road Side Assistance</h3> <div>Roadside Assistance Coverage means a professional technician comes to your rescue when your car breaks down in the middle of the journey leaving you stranded.</div>"
                                  data-html={true}
                                  data-for="rsa__Tooltipvol_m"
                                  src={tooltip}
                                  alt="tooltip"
                                  // className="toolTipRiderChild"
                                />
                              </CustomTooltip>
                            ) : (
                              <noscript />
                            )}
                          </span>
                        </div>
                      </FilterMenuBoxCheckConatiner>
                    )}

                    {type === "cv" && (
                      <>
                        <FilterMenuBoxCheckConatiner>
                          <div className="filterMenuBoxCheck">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={"IMT - 23"}
                              value={"IMT - 23"}
                              // value={imt23}
                              defaultChecked={imt23}
                              //value={cpa}
                              checked={imt23}
                              onChange={(e) => {
                                setImt23(e.target.checked);
                              }}
                            />

                            <CustomTooltip
                              rider="true"
                              id="imtTooltipvol"
                              place={"right"}
                              customClassName="mt-3  "
                            >
                              <label
                                data-tip={
                                  !lessthan767 &&
                                  `<h3 >IMT - 23</h3> <div>COVER FOR LAMPS TYRES / TUBES MUDGUARDS BONNET
			/SIDE PARTS BUMPERS HEADLIGHTS AND PAINTWORK OF
			DAMAGED PORTION ONLY .</div>`
                                }
                                data-html={!lessthan767 && true}
                                data-for={!lessthan767 && "imtTooltipvol"}
                                className="form-check-label"
                                htmlFor={"IMT - 23"}
                              >
                                {"IMT - 23"}{" "}
                              </label>
                            </CustomTooltip>
                            <span style={{ marginLeft: "3px" }}>
                              {lessthan767 ? (
                                <CustomTooltip
                                  rider="true"
                                  id="imtTooltipvol_m"
                                  place={"right"}
                                  customClassName="mt-3 "
                                  allowClick
                                >
                                  <img
                                    data-tip={`<h3 >IMT - 23</h3> <div>COVER FOR LAMPS TYRES / TUBES MUDGUARDS BONNET
                                /SIDE PARTS BUMPERS HEADLIGHTS AND PAINTWORK OF
                                DAMAGED PORTION ONLY .</div>`}
                                    data-html={true}
                                    data-for={"imtTooltipvol_m"}
                                    src={tooltip}
                                    alt="tooltip"
                                    // className="toolTipRiderChild"
                                  />
                                </CustomTooltip>
                              ) : (
                                <noscript />
                              )}
                            </span>
                          </div>
                        </FilterMenuBoxCheckConatiner>
                      </>
                    )}
                    {!gcvJourney &&
                      temp_data?.parent?.productSubTypeCode !==
                        "MISCELLANEOUS-CLASS" && (
                        <FilterMenuBoxCheckConatiner>
                          <div className="filterMenuBoxCheck">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={"Consumable"}
                              value={"Consumable"}
                              // value={consumables}
                              defaultChecked={consumables}
                              checked={consumables}
                              onChange={(e) => {
                                setConsumables(e.target.checked);
                              }}
                            />

                            <CustomTooltip
                              rider="true"
                              id="consumableTooltipvol"
                              place={"right"}
                              customClassName="mt-3  "
                            >
                              <label
                                data-tip={
                                  !lessthan767 &&
                                  "<h3 >Consumable</h3> <div>The consumables in car insurance are those items that are subject to the constant wear and tear. They are continuously consumed by the car during its life for e.g nut and bolt, screw, washer, grease, lubricant, clips, A/C gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and related parts.</div>"
                                }
                                data-html={!lessthan767 && true}
                                data-for={
                                  !lessthan767 && "consumableTooltipvol"
                                }
                                className="form-check-label"
                                htmlFor={"Consumable"}
                              >
                                {"Consumable"}{" "}
                              </label>
                            </CustomTooltip>
                            <span style={{ marginLeft: "3px" }}>
                              {lessthan767 ? (
                                <CustomTooltip
                                  rider="true"
                                  id="consumableTooltipvol_m"
                                  place={"left"}
                                  customClassName="mt-3 "
                                  allowClick
                                >
                                  <img
                                    data-tip="<h3 >Consumable</h3> <div>The consumables in car insurance are those items that are subject to the constant wear and tear. They are continuously consumed by the car during its life for e.g nut and bolt, screw, washer, grease, lubricant, clips, A/C gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and related parts.</div>"
                                    data-html={true}
                                    data-for="consumableTooltipvol_m"
                                    src={tooltip}
                                    alt="tooltip"
                                    // className="toolTipRiderChild"
                                  />
                                </CustomTooltip>
                              ) : (
                                <noscript />
                              )}
                            </span>
                          </div>
                        </FilterMenuBoxCheckConatiner>
                      )}
                    {(motor || bike) && true && (
                      <>
                        {!bike && (
                          <FilterMenuBoxCheckConatiner>
                            <div className="filterMenuBoxCheck">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={"Key Replacement"}
                                value={"Key Replacement"}
                                // value={keyReplace}
                                defaultChecked={keyReplace}
                                checked={keyReplace}
                                onChange={(e) => {
                                  setKeyReplace(e.target.checked);
                                }}
                              />
                              <CustomTooltip
                                rider="true"
                                id="keyTooltipvol"
                                place={"right"}
                                customClassName="mt-3  "
                              >
                                <label
                                  className="form-check-label"
                                  htmlFor={"Key Replacement"}
                                  data-tip={
                                    !lessthan767 &&
                                    "<h3 >Key Replacement</h3> <div>An add-on which covers cost of car keys and lock replacement or locksmith charges incase of your car keys is stolen.</div>"
                                  }
                                  data-html={!lessthan767 && true}
                                  data-for={!lessthan767 && "keyTooltipvol"}
                                  alt="tooltip"
                                >
                                  {"Key Replacement"}{" "}
                                </label>
                              </CustomTooltip>
                              <span style={{ marginLeft: "3px" }}>
                                {lessthan767 ? (
                                  <CustomTooltip
                                    rider="true"
                                    id="keyTooltipvol_m"
                                    place={"right"}
                                    customClassName="mt-3 "
                                    allowClick
                                  >
                                    <img
                                      data-tip={
                                        "<h3 >Key Replacement</h3> <div>An add-on which covers cost of car keys and lock replacement or locksmith charges incase of your car keys is stolen.</div>"
                                      }
                                      data-html={true}
                                      data-for={"keyTooltipvol_m"}
                                      src={tooltip}
                                      alt="tooltip"
                                      // className="toolTipRiderChild"
                                    />
                                  </CustomTooltip>
                                ) : (
                                  <noscript />
                                )}
                              </span>
                            </div>
                          </FilterMenuBoxCheckConatiner>
                        )}

                        <FilterMenuBoxCheckConatiner>
                          <div className="filterMenuBoxCheck">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={"Engine Protector"}
                              value={"Engine Protector"}
                              // value={engineProtector}
                              defaultChecked={engineProtector}
                              checked={engineProtector}
                              onChange={(e) => {
                                setEngineProtector(e.target.checked);
                              }}
                            />

                            <CustomTooltip
                              rider="true"
                              id="engTooltipvol"
                              place={"right"}
                              customClassName="mt-3  "
                            >
                              <label
                                className="form-check-label"
                                htmlFor={"Engine Protector"}
                                data-tip={
                                  !lessthan767 &&
                                  "<h3 >Engine Protector</h3> <div>Engine protection cover in car insurance provides coverage towards damages or losses to the insured vehicle’s engine. The add-on compensates you for the replacement or repair of your car’s engine or parts.</div>"
                                }
                                data-html={!lessthan767 && true}
                                data-for={!lessthan767 && "engTooltipvol"}
                              >
                                {"Engine Protector"}{" "}
                              </label>
                            </CustomTooltip>
                            <span style={{ marginLeft: "3px" }}>
                              {lessthan767 ? (
                                <CustomTooltip
                                  rider="true"
                                  id="engTooltipvol_m"
                                  place={"right"}
                                  customClassName="mt-3 "
                                  allowClick
                                >
                                  <img
                                    data-tip={
                                      "<h3 >Engine Protector</h3> <div>Engine protection cover in car insurance provides coverage towards damages or losses to the insured vehicle’s engine. The add-on compensates you for the replacement or repair of your car’s engine or parts.</div>"
                                    }
                                    data-html={true}
                                    data-for={"engTooltipvol_m"}
                                    src={tooltip}
                                    alt="tooltip"
                                    // className="toolTipRiderChild"
                                  />
                                </CustomTooltip>
                              ) : (
                                <noscript />
                              )}
                            </span>
                          </div>
                        </FilterMenuBoxCheckConatiner>

                        {!bike && (
                          <FilterMenuBoxCheckConatiner>
                            <div className="filterMenuBoxCheck">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={"NCB Protection"}
                                value={"NCB Protection"}
                                // value={ncbProtection}
                                defaultChecked={ncbProtection}
                                checked={ncbProtection}
                                onChange={(e) => {
                                  setNcbProtectiont(e.target.checked);
                                }}
                              />

                              <CustomTooltip
                                rider="true"
                                id="ncbProtTooltipvol"
                                place={"right"}
                                customClassName="mt-3  "
                              >
                                <label
                                  className="form-check-label"
                                  data-tip={
                                    !lessthan767 &&
                                    "<h3 >NCB Protection</h3> <div>The NCB Protector protects Your Earned No claim Bonus, in the event of an Own Damage claim made for Partial Loss including claims for Windshield glass, Total Loss, and Theft of vehicle/ accessories. The No Claim Bonus will not get impacted for the first 2 claims preferred during the course of this policy per year.</div>"
                                  }
                                  data-html={!lessthan767 && true}
                                  data-for={!lessthan767 && "ncbProtTooltipvol"}
                                  htmlFor={"NCB Protection"}
                                >
                                  {"NCB Protection"}{" "}
                                </label>
                              </CustomTooltip>
                              <span style={{ marginLeft: "3px" }}>
                                {lessthan767 ? (
                                  <CustomTooltip
                                    rider="true"
                                    id="ncbProtTooltipvol_m"
                                    place={"right"}
                                    customClassName="mt-3 "
                                    allowClick
                                  >
                                    <img
                                      data-tip={
                                        "<h3 >NCB Protection</h3> <div>The NCB Protector protects Your Earned No claim Bonus, in the event of an Own Damage claim made for Partial Loss including claims for Windshield glass, Total Loss, and Theft of vehicle/ accessories. The No Claim Bonus will not get impacted for the first 2 claims preferred during the course of this policy per year.</div>"
                                      }
                                      data-html={true}
                                      data-for={"ncbProtTooltipvol_m"}
                                      src={tooltip}
                                      alt="tooltip"
                                      // className="toolTipRiderChild"
                                    />
                                  </CustomTooltip>
                                ) : (
                                  <noscript />
                                )}
                              </span>
                            </div>
                          </FilterMenuBoxCheckConatiner>
                        )}

                        {!bike && (
                          <FilterMenuBoxCheckConatiner>
                            <div className="filterMenuBoxCheck">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={"Tyre Secure"}
                                value={"Tyre Secure"}
                                // value={tyreSecure}
                                defaultChecked={tyreSecure}
                                checked={tyreSecure}
                                onChange={(e) => {
                                  setTyreSecure(e.target.checked);
                                }}
                              />

                              <CustomTooltip
                                rider="true"
                                id="tyreTooltipvol"
                                place={"right"}
                                customClassName="mt-3  "
                              >
                                <label
                                  data-tip={
                                    !lessthan767 &&
                                    "<h3 >Tyre Secure</h3> <div>This is an add-on cover which covers the damages to the tyre of the car caused due to accidental external means. The cost of tyre replacement, rebalancing, removal and refitting is covered.</div>"
                                  }
                                  data-html={!lessthan767 && true}
                                  data-for={!lessthan767 && "tyreTooltipvol"}
                                  className="form-check-label"
                                  htmlFor={"Tyre Secure"}
                                >
                                  {"Tyre Secure"}{" "}
                                </label>
                              </CustomTooltip>
                              <span style={{ marginLeft: "3px" }}>
                                {lessthan767 ? (
                                  <CustomTooltip
                                    rider="true"
                                    id="tyreTooltipvol_m"
                                    place={"right"}
                                    customClassName="mt-3 "
                                    allowClick
                                  >
                                    <img
                                      data-tip={
                                        "<h3 >Tyre Secure</h3> <div>This is an add-on cover which covers the damages to the tyre of the car caused due to accidental external means. The cost of tyre replacement, rebalancing, removal and refitting is covered.</div>"
                                      }
                                      data-html={true}
                                      data-for={"tyreTooltipvol_m"}
                                      src={tooltip}
                                      alt="tooltip"
                                      // className="toolTipRiderChild"
                                    />
                                  </CustomTooltip>
                                ) : (
                                  <noscript />
                                )}
                              </span>
                            </div>
                          </FilterMenuBoxCheckConatiner>
                        )}
                        <FilterMenuBoxCheckConatiner>
                          <div className="filterMenuBoxCheck">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={"Return To Invoice"}
                              value={"Return To Invoice"}
                              // value={returnToInvoice}
                              defaultChecked={returnToInvoice}
                              checked={returnToInvoice}
                              onChange={(e) => {
                                setReturnToInvoice(e.target.checked);
                              }}
                            />

                            <CustomTooltip
                              rider="true"
                              id="roiTooltipvol"
                              place={"right"}
                              customClassName="mt-3  "
                            >
                              <label
                                data-tip={
                                  !lessthan767 &&
                                  "<h3 >Return To Invoice</h3> <div>Return to Invoice cover is an add-on cover offered in a comprehensive vehicle insurance plan. It allows the insured customer to receive full compensation, i.e. the last complete invoice value of their vehicle, in case it has been stolen or damaged beyond repair.</div>"
                                }
                                data-html={!lessthan767 && true}
                                data-for={!lessthan767 && "roiTooltipvol"}
                                className="form-check-label"
                                htmlFor={"Return To Invoice"}
                              >
                                {"Return To Invoice"}{" "}
                              </label>
                            </CustomTooltip>
                            <span style={{ marginLeft: "3px" }}>
                              {lessthan767 ? (
                                <CustomTooltip
                                  rider="true"
                                  id="roiTooltipvol_m"
                                  place={"right"}
                                  customClassName="mt-3 "
                                  allowClick
                                >
                                  <img
                                    data-tip={
                                      "<h3 >Return To Invoice</h3> <div>Return to Invoice is an add-on option which covers the gap between the insured declared value and the invoice value of your car along with the registration and other applicable taxes.</div>"
                                    }
                                    data-html={true}
                                    data-for={"roiTooltipvol_m"}
                                    src={tooltip}
                                    alt="tooltip"
                                    // className="toolTipRiderChild"
                                  />
                                </CustomTooltip>
                              ) : (
                                <noscript />
                              )}
                            </span>
                          </div>
                        </FilterMenuBoxCheckConatiner>
                        {!bike && (
                          <FilterMenuBoxCheckConatiner>
                            <div className="filterMenuBoxCheck">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={"Loss of Personal Belongings"}
                                value={"Loss of Personal Belongings"}
                                // value={lopb}
                                defaultChecked={lopb}
                                checked={lopb}
                                onChange={(e) => {
                                  setLopb(e.target.checked);
                                }}
                              />

                              <CustomTooltip
                                rider="true"
                                id="lopb__Tooltipvol"
                                place={"right"}
                                customClassName="mt-3  "
                              >
                                <label
                                  data-tip={
                                    !lessthan767 &&
                                    "<h3 >Loss of Personal Belongings</h3> <div>With this cover in place, your insurer will cover losses arising due to damage or theft of your personal Belongings from the insured car as per the terms and conditions of the policy.</div>"
                                  }
                                  data-html={!lessthan767 && true}
                                  data-for={!lessthan767 && "lopb__Tooltipvol"}
                                  className="form-check-label"
                                  htmlFor={"Loss of Personal Belongings"}
                                >
                                  {"Loss of Personal Belongings"}{" "}
                                </label>
                              </CustomTooltip>
                              <span style={{ marginLeft: "3px" }}>
                                {lessthan767 ? (
                                  <CustomTooltip
                                    rider="true"
                                    id="lopb__Tooltipvol_m"
                                    place={"left"}
                                    customClassName="mt-3 "
                                    allowClick
                                  >
                                    <img
                                      data-tip={
                                        "<h3 >Loss of Personal Belongings</h3> <div>With this cover in place, your insurer will cover losses arising due to damage or theft of your personal Belongings from the insured car as per the terms and conditions of the policy.</div>"
                                      }
                                      data-html={true}
                                      data-for={"lopb__Tooltipvol_m"}
                                      src={tooltip}
                                      alt="tooltip"
                                      // className="toolTipRiderChild"
                                    />
                                  </CustomTooltip>
                                ) : (
                                  <noscript />
                                )}
                              </span>
                            </div>
                          </FilterMenuBoxCheckConatiner>
                        )}
                        <FilterMenuBoxCheckConatiner>
                          <div className="filterMenuBoxCheck">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={"Emergency Medical Expenses"}
                              value={"Emergency Medical Expenses"}
                              // value={tyreSecure}
                              defaultChecked={emergencyMedicalExpenses}
                              checked={emergencyMedicalExpenses}
                              onChange={(e) => {
                                setEmergencyMedicalExpenses(e.target.checked);
                              }}
                            />
                            <CustomTooltip
                              rider="true"
                              id="tyreTooltipvol"
                              place={"right"}
                              customClassName="mt-3  "
                            >
                              <label
                                data-tip={
                                  !lessthan767 &&
                                  "<h3 >Emergency Medical Expenses</h3> <div></div>"
                                }
                                data-html={!lessthan767 && true}
                                data-for={!lessthan767 && "tyreTooltipvol"}
                                className="form-check-label"
                                htmlFor={"Emergency Medical Expenses"}
                              >
                                {"Emergency Medical Expenses"}{" "}
                              </label>
                            </CustomTooltip>
                            <span style={{ marginLeft: "3px" }}>
                              {lessthan767 ? (
                                <CustomTooltip
                                  rider="true"
                                  id="tyreTooltipvol_m"
                                  place={"right"}
                                  customClassName="mt-3 "
                                  allowClick
                                >
                                  <img
                                    data-tip={
                                      "<h3 >Emergency Medical Expenses</h3> <div>This is an add-on cover which covers the damages to the tyre of the car caused due to accidental external means. The cost of tyre replacement, rebalancing, removal and refitting is covered.</div>"
                                    }
                                    data-html={true}
                                    data-for={"tyreTooltipvol_m"}
                                    src={tooltip}
                                    alt="tooltip"
                                    // className="toolTipRiderChild"
                                  />
                                </CustomTooltip>
                              ) : (
                                <noscript />
                              )}
                            </span>
                          </div>
                        </FilterMenuBoxCheckConatiner>
                      </>
                    )}
                  </CardBlock>
                )}
              </AccordionContent>
            </CustomAccordion>

            <CustomAccordion
              id={`accessories${accordionId}`}
              noPadding
              defaultOpen
              eventKey={eventKey}
              setEventKey={setEventKey}
              openAll={openAll}
              setOpenAll={setOpenAll}
              disabled={lessthan767 ? true : false}
            >
              <AccordionHeader quotes={lessthan767 ? true : false}>
                Accessories
              </AccordionHeader>

              <AccordionContent>
                <CardBlock>
                  <div className={tab === "tab2" ? "hideAddon" : "showAddon"}>
                    <Checkbox
                      id={"Electrical Accessories"}
                      fieldName={"Electrical Accessories"}
                      register={register}
                      index={0}
                      name="accesories"
                      tooltipData={
                        "Electrical accessories include music system, air conditioners, different sort of lights such as brake or fog light, etc."
                      }
                    />
                    {selectedAccesories?.includes("Electrical Accessories") && (
                      <>
                        <InputFieldSmall>
                          <Form.Control
                            type="text"
                            placeholder="Enter value "
                            name="amountElectric"
                            defaultValue={amountElectricPrefill}
                            minlength="2"
                            ref={register}
                            onInput={(e) =>
                              (e.target.value =
                                e.target.value.length <= 1
                                  ? "" + e.target.value.replace(/[^0-9]/gi, "")
                                  : e.target.value)
                            }
                            errors={electricalErr}
                            size="sm"
                            maxLength="6"
                            onKeyDown={(e) => {
                              numOnlyNoZero(e);
                            }}
                          />
                          {!!electricalErr && (
                            <div style={{ marginLeft: "42px" }}>
                              <ErrorMsg fontSize={"12px"}>
                                {electricalErr}
                              </ErrorMsg>
                            </div>
                          )}
                        </InputFieldSmall>
                      </>
                    )}
                    <div className={false ? "hideAddon" : "showAddon"}>
                      <Checkbox
                        id={"Non-Electrical Accessories"}
                        fieldName={"Non-Electrical Accessories"}
                        register={register}
                        index={1}
                        name="accesories"
                        tooltipData={
                          "Non-electrical accessories include the interior fitting in the car, seat covers, alloy wheels etc"
                        }
                      />
                      {selectedAccesories?.includes(
                        "Non-Electrical Accessories"
                      ) && (
                        <>
                          <InputFieldSmall>
                            <Form.Control
                              type="text"
                              placeholder="Enter value "
                              defaultValue={amountNonElectricPrefill}
                              name="amountNonElectric"
                              minlength="2"
                              ref={register}
                              onInput={(e) =>
                                (e.target.value =
                                  e.target.value.length <= 1
                                    ? "" +
                                      e.target.value.replace(/[^0-9]/gi, "")
                                    : e.target.value)
                              }
                              errors={errors.amountNonElectric}
                              size="sm"
                              maxLength="6"
                              onKeyDown={(e) => {
                                numOnlyNoZero(e);
                              }}
                            />
                            {!!nonElectricalErr && (
                              <div style={{ marginLeft: "42px" }}>
                                <ErrorMsg fontSize={"12px"}>
                                  {nonElectricalErr}
                                </ErrorMsg>
                              </div>
                            )}
                          </InputFieldSmall>
                        </>
                      )}
                    </div>
                  </div>
                  {!(
                    (
                      temp_data?.fuel === "CNG" ||
                      temp_data?.fuel === "ELECTRIC"
                    )
                    // &&
                    // Number(temp_data?.vehicleLpgCngKitValue) === 0
                  ) &&
                    temp_data?.parent?.productSubTypeCode !==
                      "MISCELLANEOUS-CLASS" &&
                    true && (
                      <>
                        <div className={bike ? "hideAddon" : "showAddon"}>
                          <Checkbox
                            id={"External Bi-Fuel Kit CNG/LPG"}
                            fieldName={"External Bi-Fuel Kit CNG/LPG"}
                            register={register}
                            index={2}
                            name="accesories"
                            tooltipData={
                              "It covers the damages to the externally fitted fuel kit such as CNG/LPG."
                            }
                          />
                          {selectedAccesories?.includes(
                            "External Bi-Fuel Kit CNG/LPG"
                          ) && (
                            <>
                              <InputFieldSmall>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter value "
                                  name="amountLpg"
                                  defaultValue={amountCngPrefill}
                                  minlength="2"
                                  ref={register}
                                  onInput={(e) =>
                                    (e.target.value =
                                      e.target.value.length <= 1
                                        ? "" +
                                          e.target.value.replace(/[^0-9]/gi, "")
                                        : e.target.value)
                                  }
                                  errors={errors.amountLpg}
                                  size="sm"
                                  maxLength="6"
                                  onKeyDown={(e) => {
                                    numOnlyNoZero(e);
                                  }}
                                />
                                {!!externalErr && (
                                  <div style={{ marginLeft: "42px" }}>
                                    <ErrorMsg fontSize={"12px"}>
                                      {externalErr}
                                    </ErrorMsg>
                                  </div>
                                )}
                              </InputFieldSmall>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  {gcvJourney && process.env.REACT_APP_BROKER === "FYNTUNE" && (
                    <div>
                      <Checkbox
                        id={"Trailer"}
                        fieldName={"Trailer"}
                        register={register}
                        index={3}
                        name="accesories"
                        tooltipData={"Trailer"}
                      />
                      {selectedAccesories?.includes("Trailer") && (
                        <>
                          <InputFieldSmall>
                            <Form.Control
                              type="text"
                              placeholder="Enter value "
                              name="amountTrailer"
                              defaultValue={amountTrailerPrefill}
                              minlength="2"
                              ref={register}
                              onInput={(e) =>
                                (e.target.value =
                                  e.target.value.length <= 1
                                    ? "" +
                                      e.target.value.replace(/[^0-9]/gi, "")
                                    : e.target.value)
                              }
                              errors={errors.amountTrailer}
                              size="sm"
                              maxLength="6"
                              onKeyDown={(e) => {
                                numOnlyNoZero(e);
                              }}
                            />
                            {!!errors.amountTrailer && (
                              <ErrorMsg fontSize={"12px"}>
                                {errors.amountTrailer.message}
                              </ErrorMsg>
                            )}
                          </InputFieldSmall>
                        </>
                      )}
                    </div>
                  )}
                  {showUpdateButtonAccesories && (
                    <ButtonContainer>
                      <ButtonSub
                        id="updateAccesoriesButton"
                        onClick={handleSubmit(onSubmitAccesories)}
                      >
                        Update
                      </ButtonSub>
                    </ButtonContainer>
                  )}
                </CardBlock>
              </AccordionContent>
            </CustomAccordion>

            <CustomAccordion
              noPadding
              defaultOpen
              eventKey={eventKey}
              setEventKey={setEventKey}
              id={`addition${accordionId}`}
              openAll={openAll}
              setOpenAll={setOpenAll}
              disabled={lessthan767 ? true : false}
            >
              <AccordionHeader quotes={lessthan767 ? true : false}>
                Additional Covers
              </AccordionHeader>

              <AccordionContent>
                <div className={"showAddon"}>
                  <CardBlock>
                    {!temp_data?.odOnly && true && (
                      <>
                        <div
                          className={
                            gcvJourney ||
                            shortCompPolicy3 ||
                            shortCompPolicy6 ||
                            bike
                              ? "hideAddon"
                              : "showAddon"
                          }
                        >
                          <div>
                            <Checkbox
                              id={"PA cover for additional paid driver"}
                              fieldName={"PA cover for additional paid driver"}
                              register={register}
                              index={0}
                              name="additional"
                              tooltipData={
                                "Under this cover, the insurer pays compensation to your driver in case of any injury, disability or even death."
                              }
                            />

                            {selectedAdditions?.includes(
                              "PA cover for additional paid driver"
                            ) && (
                              <>
                                {unNamedCover.map((item, index) => (
                                  <CustomRadio
                                    id={item}
                                    name="additionalPaidDriver"
                                    fieldName={item}
                                    index={9}
                                    register={register}
                                    items={unNamedCover}
                                    setNewChecked={setAdditionalPaidDriver}
                                    selected={additionalPaidDriver}
                                  />
                                ))}
                              </>
                            )}
                          </div>
                        </div>

                        <div
                          className={
                            gcvJourney ||
                            BlockedSections(
                              process.env.REACT_APP_BROKER,
                              temp_data?.journeyCategory
                            ).includes("unnamed pa cover")
                              ? "hideAddon"
                              : "showAddon"
                          }
                        >
                          <div>
                            <Checkbox
                              id={"Unnamed Passenger PA Cover"}
                              fieldName={"Unnamed Passenger PA Cover"}
                              register={register}
                              index={1}
                              name="additional"
                              tooltipData={
                                "Covers all the passengers of your car in case of death or disability due to an accident. Naming of individuals is not required"
                              }
                            />

                            {selectedAdditions?.includes(
                              "Unnamed Passenger PA Cover"
                            ) && (
                              <>
                                {unNamedCover.map((item, index) => (
                                  <CustomRadio
                                    id={item}
                                    name="unNamedCovervalue"
                                    fieldName={item}
                                    index={9}
                                    register={register}
                                    items={unNamedCover}
                                    setNewChecked={setUnNamedCoverValue}
                                    selected={unNamedCoverValue}
                                  />
                                ))}
                              </>
                            )}
                          </div>
                        </div>

                        <div
                          className={
                            !gcvJourney || shortCompPolicy3 || shortCompPolicy6
                              ? "hideAddon"
                              : "showAddon"
                          }
                        >
                          <div>
                            <Checkbox
                              id={"LL paid driver/conductor/cleaner"}
                              fieldName={"LL paid driver/conductor/cleaner"}
                              register={register}
                              index={2}
                              name="additional"
                              tooltipData={""}
                            />

                            {selectedAdditions?.includes(
                              "LL paid driver/conductor/cleaner"
                            ) && (
                              <SubCheckBox>
                                <Checkbox
                                  id={"DriverLL"}
                                  fieldName={"Driver"}
                                  register={register}
                                  index={0}
                                  name="LLpaidItmes"
                                />
                                {selectedLLpaidItmes?.includes("DriverLL") && (
                                  <>
                                    <InputFieldSmall>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Count"
                                        name="LLNumberDriver"
                                        defaultValue={LLCountPrefillDriver}
                                        minlength="1"
                                        ref={register}
                                        onInput={(e) =>
                                          (e.target.value =
                                            e.target.value.length <= 1
                                              ? "" + e.target.value
                                              : e.target.value)
                                        }
                                        errors={errors.LLNumberDriver}
                                        size="sm"
                                        maxLength="1"
                                        onKeyDown={numOnly}
                                        onChange={handleChangeCountLL(
                                          "LLNumberDriver"
                                        )}
                                      />
                                      {!!errors.LLNumberDriver && (
                                        <ErrorMsg fontSize={"12px"}>
                                          {errors.LLNumberDriver.message}
                                        </ErrorMsg>
                                      )}
                                    </InputFieldSmall>
                                  </>
                                )}
                                <Checkbox
                                  id={"ConductorLL"}
                                  fieldName={"Conductor"}
                                  register={register}
                                  index={1}
                                  name="LLpaidItmes"
                                />
                                {selectedLLpaidItmes?.includes(
                                  "ConductorLL"
                                ) && (
                                  <>
                                    <InputFieldSmall>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Number of Conductors"
                                        name="LLNumberConductor"
                                        defaultValue={LLCountPrefillConductor}
                                        minlength="1"
                                        maxLength="1"
                                        ref={register}
                                        onInput={(e) =>
                                          (e.target.value =
                                            e.target.value.length <= 1
                                              ? "" + e.target.value
                                              : e.target.value)
                                        }
                                        errors={errors.LLNumberConductor}
                                        size="sm"
                                        // maxLength="1"
                                        onKeyDown={numOnly}
                                        onChange={handleChangeCountLL(
                                          "LLNumberConductor"
                                        )}
                                      />
                                      {!!errors.LLNumberConductor && (
                                        <ErrorMsg fontSize={"12px"}>
                                          {errors.LLNumberConductor.message}
                                        </ErrorMsg>
                                      )}
                                    </InputFieldSmall>
                                  </>
                                )}
                                <Checkbox
                                  id={"CleanerLL"}
                                  fieldName={"Cleaner"}
                                  register={register}
                                  index={2}
                                  name="LLpaidItmes"
                                />
                                {selectedLLpaidItmes?.includes("CleanerLL") && (
                                  <>
                                    <InputFieldSmall>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Number of Cleaner"
                                        name="LLNumberCleaner"
                                        defaultValue={LLCountPrefillCleaner}
                                        minlength="1"
                                        maxLength="1"
                                        ref={register}
                                        onInput={(e) =>
                                          (e.target.value =
                                            e.target.value.length <= 1
                                              ? "" + e.target.value
                                              : e.target.value)
                                        }
                                        errors={errors.LLNumberCleaner}
                                        size="sm"
                                        // maxLength="10"
                                        onKeyDown={numOnly}
                                        onChange={handleChangeCountLL(
                                          "LLNumberCleaner"
                                        )}
                                      />
                                      {!!errors.LLNumberCleaner && (
                                        <ErrorMsg fontSize={"12px"}>
                                          {errors.LLNumberCleaner.message}
                                        </ErrorMsg>
                                      )}
                                    </InputFieldSmall>
                                  </>
                                )}
                              </SubCheckBox>
                            )}
                          </div>

                          {
                            <div>
                              <Checkbox
                                id={"PA paid driver/conductor/cleaner"}
                                fieldName={"PA paid driver/conductor/cleaner"}
                                register={register}
                                index={3}
                                name="additional"
                                tooltipData={""}
                              />

                              {selectedAdditions?.includes(
                                "PA paid driver/conductor/cleaner"
                              ) && (
                                <>
                                  {unNamedCover.map((item, index) => (
                                    <CustomRadio
                                      id={item}
                                      name="paPaidDriverGCV"
                                      fieldName={item}
                                      index={5654}
                                      register={register}
                                      items={unNamedCover}
                                      setNewChecked={setPaPaidDriverGCV}
                                      selected={paPaidDriverGCV}
                                    />
                                  ))}
                                </>
                              )}
                            </div>
                          }
                        </div>

                        <div className={gcvJourney ? "hideAddon" : "showAddon"}>
                          <Checkbox
                            id={"LL paid driver"}
                            fieldName={"LL paid driver"}
                            register={register}
                            index={4}
                            name="additional"
                            tooltipData={
                              "Under this cover, the insurer shall indemnify the insured against the insured's legal liability under the Workmen's Compensation Act, 1923 , the Fatal Accidents Act, 1855 or at Common Law"
                            }
                          />
                        </div>
                      </>
                    )}
                    {process.env.REACT_APP_BROKER !== "OLA" && (
                      <div>
                        <Checkbox
                          id={"Geographical Extension"}
                          fieldName={"Geographical Extension"}
                          register={register}
                          index={5}
                          name="additional"
                          tooltipData={
                            "Motor insurance policy can be extended to provide coverage to your private vehicles outside India as well. There are a few geographical zones where you can get the benefits of your insurance policy. The 6 neighboring countries of India where you can take the benefit of your motor insurance policy are Bangladesh, Nepal, Bhutan, Pakistan, Maldives and Sri Lanka."
                          }
                        />
                        {selectedAdditions?.includes(
                          "Geographical Extension"
                        ) && (
                          <SubCheckBox>
                            {[
                              "Sri Lanka",
                              "Bhutan",
                              "Nepal",
                              "Bangladesh",
                              "Pakistan",
                              "Maldives",
                            ].map((item, index) => (
                              <Checkbox
                                id={item}
                                register={register}
                                fieldName={item}
                                index={index}
                                name={"country"}
                                tooltipData={""}
                              />
                            ))}
                          </SubCheckBox>
                        )}
                      </div>
                    )}
                    {/* <div>
                      <Checkbox
                        id={"Auto Mobile"}
                        fieldName={"Auto Mobile"}
                        register={register}
                        index={5}
                        name="additional"
                        // tooltipData={
                        //   "Under this cover, the insurer pays compensation to your driver in case of any injury, disability or even death."
                        // }
                      />
                    </div>
                    
                    { selectedAdditions?.includes("Auto Mobile") && (
                       <>
                       <InputFieldSmall>
                         <Form.Control
                           type="text"
                           placeholder="Member Id"
                           name="memberId"
                          //  defaultValue={amountElectricPrefill}
                          //  minlength="2"
                           ref={register}
                          //  onInput={(e) =>
                          //    (e.target.value =
                          //      e.target.value.length <= 1
                          //        ? "" + e.target.value
                          //        : e.target.value)
                          //  }
                          //  errors={errors.amountElectric}
                           size="sm"
                          //  maxLength="6"
                          //  onKeyDown={(e) => {
                          //    numOnlyNoZero(e);
                          //  }}
                         />
                         {!!errors.amountElectric && (
                           <ErrorMsg fontSize={"12px"}>
                             {errors.amountElectric.message}
                           </ErrorMsg>
                         )}
                       </InputFieldSmall>
                       <StyledDatePicker>
                  <div className="py-2 dateTimeFive" style={{ paddingRight: '8px', textAlign: 'right' }}>
                    <Controller
                  control={control}
                      name="date1"
                      defaultValue={temp_data?.regDate}
                      render={({ onChange, onBlur, value, name }) => (
                        <DateInput
                          value={value}
                          // selected={
                          //   // newRegDate || temp_data?.regDate
                          //   //   ? toDate(newRegDate || temp_data?.regDate)
                          //   //   : false
                          //   // selectedDate ||
                          //   // new Date()
                          // }
                          // maxDate={policyMax}
                          name={name}
                          onChange={onChange}
                          ref={register}
                          onValueChange={(date) => {
                            handleValueChange(date);
                          }}
                          // rangeMax={
                          //   !temp_data?.newCar
                          //     ? getYear(new Date(Date.now()) + 1)
                          //     : false
                          // }
                          // readOnly={
                          //   temp_data?.newCar || isNull(temp_data?.regDate)
                          // }
                          withPortal={true}
                        />
                      )}
                    />
                    {!!errors.date1 && (
                      <ErrorMsg fontSize={"12px"}>
                        {errors.date1.message}
                      </ErrorMsg>
                    )}
                  </div>
                </StyledDatePicker>
                     </>
                    ) } */}
                    {showUpdateButtonAddtions && (
                      <ButtonContainer
                        style={
                          {
                            // ...(!gcvJourney && { display: "none" }),
                          }
                        }
                      >
                        <ButtonSub
                          //	disabled={!showUpdateButton}
                          id="updateAdditionsButton"
                          onClick={handleSubmit(onSubmitAdditions)}
                        >
                          Update
                        </ButtonSub>
                      </ButtonContainer>
                    )}
                  </CardBlock>
                </div>
              </AccordionContent>
            </CustomAccordion>

            {(process.env.REACT_APP_BROKER !== "ACE" ||
              type !== "cv" ||
              "") && (
              <div className={gcvJourney ? "showAddon" : "showAddon"}>
                <CustomAccordion
                  noPadding
                  eventKey={eventKey}
                  setEventKey={setEventKey}
                  id={`discount${accordionId}`}
                  openAll={openAll}
                  defaultOpen
                  setOpenAll={setOpenAll}
                  disabled={lessthan767 ? true : false}
                >
                  <AccordionHeader quotes={lessthan767 ? true : false}>
                    Discounts/Deductibles
                  </AccordionHeader>

                  <AccordionContent>
                    <div className={false ? "hideAddon" : "showAddon"}>
                      <CardBlock>
                        <>
                          <div
                            className={
                              gcvJourney || tab === "tab2"
                                ? "hideAddon"
                                : "showAddon"
                            }
                          >
                            <Checkbox
                              id={
                                "Is the vehicle fitted with ARAI approved anti-theft device?"
                              }
                              fieldName={
                                "Vehicle is fitted with ARAI approved anti-theft device"
                              }
                              register={register}
                              index={0}
                              name="discount"
                            />
                          </div>

                          <div
                            className={
                              type === "cv" ||
                              tab === "tab2" ||
                              BlockedSections(
                                process.env.REACT_APP_BROKER
                              ).includes("voluntary discounts")
                                ? "hideAddon"
                                : "showAddon"
                            }
                          >
                            <Checkbox
                              id={"Voluntary Discounts"}
                              fieldName={"Voluntary Deductible"}
                              register={register}
                              index={1}
                              name="discount"
                              tooltipData={
                                "This is the limit chosen by the policyholder to meet a part of the claim from his own pocket before raising it to the insurer. Choosing a higher amount of Voluntary Deductible causes a lowering in premiums through discounts."
                              }
                            />

                            {selectedDiscount?.includes(
                              "Voluntary Discounts"
                            ) && (
                              <>
                                {volDiscount.map((item, index) => (
                                  <CustomRadio
                                    id={item}
                                    name="volDiscount"
                                    fieldName={item}
                                    index={10}
                                    register={register}
                                    items={volDiscount}
                                    setNewChecked={setVolDiscountValue}
                                    selected={
                                      volDiscountValue || volDiscount[0]
                                    }
                                  />
                                ))}
                              </>
                            )}
                          </div>

                          <div
                            className={
                              bike || type === "car" ? "hideAddon" : "showAddon"
                            }
                          >
                            <>
                              <div className={bike ? "hideAddon" : "showAddon"}>
                                <Checkbox
                                  id={"Vehicle Limited to Own Premises"}
                                  fieldName={"Vehicle Limited to Own Premises"}
                                  register={register}
                                  index={2}
                                  name="discount"
                                  tooltipData={""}
                                />
                              </div>
                            </>
                          </div>
                          <div
                            className={
                              temp_data?.odOnly ? "hideAddon" : "showAddon"
                            }
                          >
                            <Checkbox
                              id={"TPPD Cover"}
                              fieldName={"TPPD Cover"}
                              register={register}
                              index={3}
                              name="discount"
                              tooltipData={""}
                            />
                          </div>
                          {/* <div
                            className={
                              !bike || tab === "tab2"
                                ? "hideAddon"
                                : "showAddon"
                            }
                          >
                            <Checkbox
                              id={"Automobile Association of India Discount"}
                              fieldName={
                                "Automobile Association of India Discount"
                              }
                              register={register}
                              index={4}
                              name="discount"
                            />
                          </div> */}
                          <>
                            {showUpdateButtonDiscount && (
                              <ButtonContainer>
                                <ButtonSub
                                  //	disabled={!showUpdateButton}
                                  id="updateDiscountsButton"
                                  onClick={handleSubmit(onSubmitDiscount)}
                                >
                                  Update
                                </ButtonSub>
                              </ButtonContainer>
                            )}
                          </>
                        </>
                      </CardBlock>
                    </div>
                  </AccordionContent>
                </CustomAccordion>
              </div>
            )}
            {/* range start  */}
            {temp_data?.journeyCategory === "GCV" &&
            temp_data?.selectedGvw * 1 ? (
              <div className="">
                <CustomAccordion
                  noPadding
                  eventKey={eventKey}
                  setEventKey={setEventKey}
                  id={`range${accordionId}`}
                  openAll={openAll}
                  defaultOpen
                  setOpenAll={setOpenAll}
                  disabled={lessthan767 ? true : false}
                >
                  <AccordionHeader quotes={lessthan767 ? true : false}>
                    Gross Vehicle Weight
                  </AccordionHeader>

                  <AccordionContent>
                    <div style={{ padding: "10px 20px" }}>
                      <p id="counter" style={{ fontSize: "13px" }}>
                        {" "}
                        {`GVW Range : (${
                          temp_data?.defaultGvw * 1 -
                          temp_data?.defaultGvw * 0.1
                        }-${
                          temp_data?.defaultGvw * 1 +
                          temp_data?.defaultGvw * 0.1
                        })`}
                      </p>
                      <input
                        style={{
                          marginTop: "-10px",
                          accentColor:
                            Theme1?.Stepper?.stepperColor?.background ||
                            "#bdd400",
                        }}
                        type="range"
                        id="range"
                        name="range"
                        register={register}
                        value={wrange || temp_data?.selectedGvw * 1}
                        min={`${
                          temp_data?.defaultGvw * 1 -
                          temp_data?.defaultGvw * 0.1
                        }`}
                        max={`${
                          temp_data?.defaultGvw * 1 +
                          temp_data?.defaultGvw * 0.1
                        }`}
                        onChange={(e) => setWrange(e.target.value)}
                      />
                      <p id="counter" style={{ fontSize: "13px" }}>
                        Selected GVW : {wrange || temp_data?.selectedGvw}
                      </p>
                      {((wrange && wrange * 1 !== temp_data?.selectedGvw * 1) ||
                        "") && (
                        <ButtonContainer style={{ marginTop: "-10px" }}>
                          <ButtonSub
                            id="submitRange"
                            onClick={() => onSubmitRange(wrange || "")}
                          >
                            Submit
                          </ButtonSub>
                        </ButtonContainer>
                      )}
                    </div>
                  </AccordionContent>
                </CustomAccordion>
              </div>
            ) : (
              <noscript />
            )}
            {/* range end  */}
          </AccordionTab>
        </Col>
      </Col>
      {cpaPopup && temp_data?.ownerTypeId === 1 && (
        <CpaPopup
          show={cpaPopup && temp_data?.ownerTypeId === 1}
          setCpa={setCpa}
          onClose={setCpaPopup}
          cpa={cpa}
        />
      )}

      {zDPopup && (
        <ZeroDepPopup
          show={zDPopup}
          setZeroDep={setZeroDep}
          onClose={setZDPopup}
          zeroDep={zeroDep}
        />
      )}
    </CardOtherItem>
  );
};

const AddOnTitle = styled.div`
  float: left;
  width: 100%;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "basier_squareregular"};
  font-size: 16px;
  line-height: 20px;
  color: #333;
  padding-bottom: 10px;
  @media (max-width: 767px) {
    display: none !important;
  }
`;
const CardOtherItem = styled.div`
  display: inline-block;
  margin-top: "20px";
  position: relative;
  margin-right: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 90px;
  overflow-x: hidden;

  @media (max-width: 990px) {
    bottom: 0px;
  }
  @media (max-width: 767px) {
    bottom: -12px;
  }
  .hideAddon {
    display: none;
  }
  .showAddon {
    display: block;
  }
`;

const AccordionTab = styled.div`
  text-align: left;
  margin-top: 40px;
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px;
    max-height: 38px;
  }
  .arrow {
    color: #6b6e71;
    font-size: 20px;
  }
  @media (max-width: 767px) {
    margin-top: 0px;
  }
`;

const CardBlock = styled.div`
  -moz-box-flex: 1;
  flex: 1 1 auto;
  color: #232323;
  padding: 10px 10px;
  border-top: 1px soild #000;
  border-radius: 0;
  .hideAddon {
    display: none;
  }
  .showAddon {
    display: block;
  }
`;
const InputFieldSmall = styled.div`
  margin-top: 6px;
  margin-bottom: 12px;
  .form-control {
    display: block;
    font-size: 12px;
    width: 85%;
    margin-left: 35px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #999;
    border-radius: 50px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-control:active,
  .form-control:focus {
    border: solid 2px #000;
    border-radius: 0px !important;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonSub = styled.button`
  margin-top: 6px;
  background-color: #fff !important;
  border: ${({ theme }) => theme.QuoteCard?.border2 || "1px solid #060"};
  color: #000 !important;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 12px;
  line-height: 40px;
  border-radius: ${({ theme }) =>
    theme.QuoteBorderAndFont?.borderRadius || "30px"};
  margin-left: 0;
  outline: none;
  width: 100px;
  margin-top: 20px;
  line-height: 24px;
  height: 34px;

  &:hover {
    background-color: ${({ theme }) =>
      theme.QuoteCard?.color2 || "#060 !important"};
    border: ${({ theme }) => theme.QuoteCard?.border2 || "1px solid #060"};
    color: #fff !important;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const ColllapseAllContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 12px;
  .badge {
    margin-right: 10px;
    padding: 2px;
    border-radius: 50%;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const FilterMenuBoxCheckConatiner = styled.div`
  .filterMenuBoxCheck input[type="checkbox"]:checked + label:before {
    background-color: ${({ theme }) => theme.CheckBox?.color || "#bdd400"};
    border: ${({ theme }) => theme.CheckBox?.border || "1px solid #bdd400"};
    box-shadow: ${({ theme }) =>
      theme.QuoteBorderAndFont?.shadowCheck || "none"};
    filter: ${({ theme }) =>
      theme.QuoteBorderAndFont?.filterPropertyCheckBox || "none"};
  }
`;

const SubCheckBox = styled.div`
  width: 95%;
  padding: 20px;
`;

const ClearAllButton = styled.button`
  background: ${({ theme }) => theme.FilterConatiner?.lightColor || " #f3ff91"};
  color: ${({ theme }) =>
    theme?.FilterConatiner?.clearAllTextColor
      ? theme?.FilterConatiner?.clearAllTextColor
      : "black"};
  font-weight: bold;
  width: max-content;
  padding: 8px 12px;
  border-radius: ${({ theme }) =>
    theme.QuoteBorderAndFont?.borderRadius || "24px"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-family: ${({ theme }) =>
    theme?.fontFamily ? theme?.fontFamily : `"basier_squareregular"`};
  position: relative;
  bottom: 35px;
  margin-bottom: 10px;
  border: none;
  left: 15px;
  .clearImage {
    margin-left: 10px;
    color: ${({ theme }) =>
      theme?.FilterConatiner?.clearAllTextColor
        ? theme?.FilterConatiner?.clearAllTextColor
        : "black"};
  }
  @media (max-width: 993px) {
    bottom: -6px;
    left: 2px;
    display: none;
  }
`;
