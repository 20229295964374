import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button as Btn } from "react-bootstrap";
import { Textbox, Button, Error, Loader } from "components";
import { useForm } from "react-hook-form";
import styled, { createGlobalStyle } from "styled-components";
import * as yup from "yup";
import { numOnly, reloadPage, RedirectFn } from "utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  set_temp_data,
  Enquiry,
  clear,
  SaveQuoteData,
  TokenValidation,
} from "modules/Home/home.slice";
import "./lead.scss";
import swal from "sweetalert";
import { Url } from "modules/proposal/proposal.slice";
import _ from "lodash";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import Realistic from "components/canvas-confetti/CanvasConfetti";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EastIcon from "@mui/icons-material/East";
import OTPPopup from "modules/proposal/otp";
import { TypeReturn, TypeCategory } from "modules/type";
import { getLogoUrl } from "modules/quotesPage/quoteUtil";
import ReactGA from "react-ga4";

const ls = new SecureLS();
//theme variable
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

export const LeadPage = ({ type, lessthan767, isPartner }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const typeId = query.get("typeid");
  const journey_type = query.get("journey_type");
  const source = query.get("source");
  const {
    temp_data,
    enquiry_id,
    saveQuoteData,
    tokenData,
    tokenFailure,
    error,
    rd_link,
    leadLoad,
    theme_conf,
  } = useSelector((state) => state.home);

  // validation schema
  const yupValidate = yup.object({
    emailId: theme_conf?.broker_config?.lead_email
      ? yup
          .string()
          .email("Please enter valid email id")
          .required("Email id is required")
          .trim()
      : yup.string().email("Please enter valid email id").trim(),
    mobileNo: theme_conf?.broker_config?.mobileNo
      ? yup
          .string()
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .required("Mobile No. is required")
          .min(10, "Mobile No. should be 10 digits")
          .max(10, "Mobile No. should be 10 digits")
          .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
      : yup
          .string()
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .min(10, "Mobile No. should be 10 digits")
          .max(10, "Mobile No. should be 10 digits")
          .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number"),
    lastName: yup
      .string()
      .nullable()
      .transform((v, o) => (o === "" ? null : v))
      .max(50, "Last Name can be upto 50 chars")
      .matches(/^([A-Za-z\s])+$/, "Must contain only alphabets")
      .trim(),
    firstName: yup
      .string()
      .nullable()
      .transform((v, o) => (o === "" ? null : v))
      .matches(/^([[0-9A-Za-z\s.'&_+@!#,-])+$/, "Entered value is invalid")
      .min(2, "Minimum 2 chars required")
      .max(50, "First Name can be upto 50 chars")
      .trim(),
    fullName: theme_conf?.broker_config?.fullName
      ? yup
          .string()
          .required("Name is required")
          .matches(/^([[0-9A-Za-z\s.'&_+@!#,-])+$/, "Entered value is invalid")
          .min(2, "Minimum 1 chars required")
          .max(101, "Must be less than 101 chars")
          .trim()
      : yup
          .string()
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .matches(/^([[0-9A-Za-z\s.'&_+@!#,-])+$/, "Entered value is invalid")
          .min(1, "Minimum 1 chars required")
          .max(101, "Must be less than 101 chars")
          .trim(),
    whatsappNo: yup
      .string()
      .nullable()
      .transform((v, o) => (o === "" ? null : v))
      .min(10, "Whatsapp No. should be 10 digits")
      .max(10, "Whatsapp No. should be 10 digits")
      .matches(/^[6-9][0-9]{9}$/, "Invalid Whatsapp number"),
  });

  const [btnDisable, setbtnDisable] = useState(false);
  const [consent, setConsent] = useState(true);
  const [show, setShow] = useState(false);
  const [sameNumber, setSameNumber] = useState(
    temp_data?.whatsappNo ? false : true
  );

  const notify = () => {
    toast(
      "Congratulations! Your work email ID is eligible for exclusive discounts and corporate offers.",
      "Custom style",
      {
        toastId: "customId",
        className: "black-background",
        bodyClassName: "grow-font-size",
      }
    );
  };

  const { handleSubmit, register, errors, reset, watch, setValue } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const handleChange = () => {};

  //Url
  useEffect(() => {
    if (enquiry_id?.enquiryId)
      dispatch(
        Url({
          proposalUrl: window.location.href,
          quoteUrl: window.location.href,
          stage: "Lead Generation",
          userProductJourneyId: enquiry_id?.enquiryId,
          ...(type !== "cv" && { section: type }),
          ...{ skipCrm: "Y" },
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  //prefill
  useEffect(() => {
    if (
      temp_data?.firstName ||
      temp_data?.lastName ||
      temp_data?.mobileNo ||
      temp_data?.emailId ||
      temp_data?.whatsappNo
    ) {
      if (temp_data?.whatsappNo === temp_data?.mobileNo) {
        setSameNumber(true);
      } else {
        setSameNumber(false);
      }
      reset({
        firstName: temp_data?.firstName,
        lastName: temp_data?.lastName,
        fullName: _.isEmpty(temp_data?.firstName)
          ? ""
          : _.isEmpty(temp_data?.lastName)
          ? temp_data?.firstName
          : temp_data?.firstName + " " + temp_data?.lastName,
        mobileNo: temp_data?.mobileNo,
        emailId: temp_data?.emailId,
        whatsappNo: temp_data?.whatsappNo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data]);

  //validate Token
  useEffect(() => {
    if (token && process.env.REACT_APP_BROKER !== "RB") {
      let decodedToken = decodeURIComponent((token + "").replace(/\+/g, "%20"));
      dispatch(
        TokenValidation({
          token: String(decodedToken).replace(/'/g, ""),
          ...(journey_type && {
            journeyType: journey_type,
          }),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  //onError
  useEffect(() => {
    if (tokenFailure) {
      swal("Error", tokenFailure, "error").then(() =>
        reloadPage(rd_link || RedirectFn(token))
      );
    }
    setbtnDisable(false);
    return () => {
      dispatch(clear("token"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFailure]);

  const [success, setSuccess] = useState(false);
  const [skip, setSkip] = useState(false);

  const otpSuccess = () => {
    if (enquiry_id?.enquiryId) {
      setSuccess(true);
      swal({
        icon: "success",
        title: "Success",
        text: "Otp Verified Successfully",
      });
    }
  };

  //onSuccess
  useEffect(() => {
    if (enquiry_id?.enquiryId) {
      dispatch(set_temp_data({ enquiry_id: enquiry_id?.enquiryId }));
    }
    if (success || skip) {
      if (enquiry_id?.enquiryId) {
        dispatch(
          SaveQuoteData({
            stage: "1",
            ...(isPartner === "Y" && { frontendTags: "Y" }),
            ...(journey_type === "Z3JhbWNvdmVyLWFwcC1qb3VybmV5" && {
              addtionalData: tokenData,
            }),
            ...(type !== "cv" && { section: type }),
            firstName: temp_data?.firstName,
            lastName: temp_data?.lastName,
            fullName: temp_data?.fullName,
            mobileNo: temp_data?.mobileNo,
            whatsappNo: temp_data?.whatsappNo,
            emailId: temp_data?.emailId,
            userProductJourneyId: enquiry_id?.enquiryId,
            enquiryId: enquiry_id?.enquiryId,
            ...(token && { token: token }),
            ...((!_.isEmpty(tokenData) || localStorage?.SSO_user) && {
              tokenResp: !_.isEmpty(tokenData)
                ? tokenData
                : JSON.parse(localStorage?.SSO_user),
            }),
            ...((journey_type || source) && {
              journeyType: journey_type || source,
            }),
            ...(["ABIBL", "ACE"].includes(process.env.REACT_APP_BROKER) && {
              whatsappConsent: consent,
            }),
            ...(typeId && { productSubTypeId: typeId }),
            ...{
              sellerType:
                tokenData?.usertype || tokenData?.seller_type
                  ? tokenData?.usertype || tokenData?.seller_type
                  : null,
            },
            ...(tokenData?.category && { categoryName: tokenData?.category }),
            ...(tokenData?.relationSbi && {
              relationSbi: tokenData?.relationSbi,
            }),
            ...(token && {
              ...(tokenData?.first_name && {
                userfirstName: tokenData?.first_name,
              }),
              ...(tokenData?.last_name && {
                userlastName: tokenData?.last_name,
              }),
              ...(tokenData?.user_name && {
                userName: tokenData?.user_name,
              }),
              agentId: tokenData?.seller_id,
              agentName: tokenData?.seller_name,
              agentMobile: tokenData?.mobile,
              agentEmail: tokenData?.email,
              ...((tokenData?.usertype === "P" ||
                tokenData?.seller_type === "P") && {
                panNo: tokenData?.pan_no,
                aadharNo: tokenData?.aadhar_no,
              }),
            }),
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, temp_data.firstName, success]);

  //on save success
  useEffect(() => {
    if (saveQuoteData && temp_data?.enquiry_id) {
      //GA event throw
      process.env.REACT_APP_PROD !== "YES" &&
      process.env.REACT_APP_BROKER === "BAJAJ" &&
        process.env.REACT_APP_BASENAME !== "NA" &&
        ReactGA.event({
          category: `${TypeReturn(type)}_Insurance_form_submit_success`,
          event: `${TypeReturn(type)}_Insurance_form_submit_success`,
          action: "Click - Submit",
          action_type: "Click - Submit",
          business_lob: "Insurance",
          journey_status: "Get Quote Stage",
          input_details: "OTP successfully submitted",
        });
      enquiry_id?.corporate_id && notify();
      enquiry_id?.corporate_id &&
        document.getElementById("fireBtn") &&
        document.getElementById("fireBtn").click();
      setTimeout(
        () => {
          history.push(
            `/${type}/registration?enquiry_id=${
              enquiry_id?.enquiryId || temp_data?.enquiry_id
            }${token ? `&token=${token}` : ``}${
              typeId ? `&typeid=${typeId}` : ``
            }${journey_type ? `&journey_type=${journey_type}` : ``}`
          );
        },
        enquiry_id?.corporate_id ? 5000 : 0
      );
    }
    return () => {
      temp_data?.enquiry_id &&
        setTimeout(
          () => dispatch(clear("saveQuoteData")),
          enquiry_id?.corporate_id ? 4500 : 0
        );
      temp_data?.enquiry_id &&
        setTimeout(
          () => dispatch(clear("enquiry_id")),
          enquiry_id?.corporate_id ? 4500 : 0
        );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveQuoteData, temp_data?.enquiry_id, show]);

  useEffect(() => {
    if (error) {
      setbtnDisable(false);
    }
  }, [error]);

  //check values
  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const fullName = watch("fullName");
  const mobileNo = watch("mobileNo");
  const emailId = watch("emailId");
  const whatsappNo = watch("whatsappNo");

  //prefill whatsapp No
  useEffect(() => {
    if (!temp_data?.whatsappNo && sameNumber) {
      setValue("whatsappNo", mobileNo);
    }
  }, [mobileNo, sameNumber]);

  useEffect(() => {
    if (fullName) {
      let FullnameCheck = fullName.split(" ");
      console.log(FullnameCheck);
      if (!_.isEmpty(FullnameCheck) && FullnameCheck?.length === 1) {
        let fname = FullnameCheck[0];
        setValue("firstName", fname);
      }
      if (!_.isEmpty(FullnameCheck) && FullnameCheck?.length > 1) {
        let fname = FullnameCheck.slice(0, -1).join(" ");
        let lname = FullnameCheck.slice(-1)[0];
        setValue("firstName", fname);
        setValue("lastName", lname);
        console.log(fname, lname);
      } else {
        setValue("lastName", "");
      }
    } else {
      setValue("firstName", "");
      setValue("lastName", "");
    }
  }, [fullName]);

  const isRenewbuy = process.env.REACT_APP_BROKER === "RB";
  const [enqueryData, setEnqueryData] = useState({});

  const { isOtpRequired, ...otpData } = enqueryData;

  const onSubmit = (data) => {
    console.log("fire-check");
    if (
      data?.fullName ||
      data?.emailId ||
      data?.mobileNo ||
      data?.whatsappNo ||
      data?.isSkipped
    ) {
      dispatch(
        set_temp_data({
          whatsappConsent: consent,
          ...data,
        })
      );
      //clearing registration
      const e_data = {
        ...(["ABIBL", "ACE"].includes(process.env.REACT_APP_BROKER) && {
          whatsappConsent: consent,
        }),
        ...data,
        ...(source && { source }),
        ...((!_.isEmpty(tokenData) || localStorage?.SSO_user) && {
          tokenResp: !_.isEmpty(tokenData)
            ? tokenData
            : JSON.parse(localStorage?.SSO_user),
        }),
        ...(theme_conf?.broker_config?.lead_otp &&
          !(
            process.env.REACT_APP_BROKER === "BAJAJ" &&
            process.env.REACT_APP_BASENAME === "NA"
          ) && {
            isOtpRequired: "Y",
            logo: getLogoUrl(),
          }),
      };
      dispatch(Enquiry(e_data));
      setEnqueryData(e_data);
      // console.log(data);
      // setTimeout(setbtnDisable(false), 3000);
    }
    if (
      theme_conf?.broker_config?.lead_otp &&
      !(
        process.env.REACT_APP_BROKER === "BAJAJ" &&
        process.env.REACT_APP_BASENAME === "NA"
      )
    ) {
      setShow(true);
    }
  };

  useEffect(() => {
    setbtnDisable(false);
  }, [show]);

  console.log("btnDisable", btnDisable);

  const brokerList =
    process.env.REACT_APP_BROKER === "RB" ||
    process.env.REACT_APP_BROKER === "FYNTUNE";

  const WhatsAppInput = (
    <Col sm="12" md={8} lg={8} xl={8} className="d-flex mx-auto">
      <SubmitDiv
        style={
          lessthan767
            ? { fontSize: "12px !important" }
            : { position: "relative", top: "-10px", left: "12px" }
        }
      >
        <label className="checkbox-container">
          <input
            className="bajajCheck"
            defaultChecked={false}
            name="accept"
            type="checkbox"
            value={sameNumber}
            checked={sameNumber}
            onChange={(e) => {
              setSameNumber(e.target.checked);
            }}
          />
          <span
            style={{ height: "18px!important", width: "18px!important" }}
            className="checkmarkwp checkmark"
          ></span>
        </label>
        <p
          className="whatsappNumber"
          style={
            lessthan767
              ? {
                  fontSize: "12px !important",
                  position: "relative",
                  top: "-2px",
                  zIndex: "-1",
                }
              : {}
          }
        >
          <span style={lessthan767 ? { fontSize: "12px !important" } : {}}>
            Whatsapp number same as mobile number
          </span>{" "}
        </p>
      </SubmitDiv>
    </Col>
  );

  const FinalSubmit = (
    <Col
      sm="12"
      md="12"
      lg="12"
      xl="12"
      className="d-flex justify-content-center"
    >
      <SubmitDiv>
        <label className="checkbox-container">
          <input
            className="bajajCheck"
            defaultChecked={false}
            name="accept"
            type="checkbox"
            value={consent}
            checked={consent}
            onChange={(e) => {
              setConsent(e.target.checked);
            }}
          />
          <span className="checkmark"></span>
        </label>
        <p className="privacyPolicy">
          <span>I Agree to be contacted via</span>{" "}
          <i
            className="fab fa-whatsapp text-success"
            style={{ fontSize: "14px" }}
          ></i>{" "}
          <span>Whatsapp.</span>
        </p>
      </SubmitDiv>
    </Col>
  );

  return (
    <>
      <div className="ml-4 my-4 w-100 mx-auto ElemFade">
        <Row className="text-center w-100 mx-auto d-flex justify-content-center">
          <div
            className={`mt-4 d-flex justify-content-center w-100 mx-auto ${
              !lessthan767 ? `flex-column` : ""
            }`}
          >
            <>
              {" "}
              <StyledH4 className="text-center w-100 mx-auto d-flex justify-content-center">
                {lessthan767
                  ? theme_conf?.broker_config?.mobile_lead_page_title
                    ? theme_conf?.broker_config?.mobile_lead_page_title
                        .replace(
                          /productType2/g,
                          TypeReturn(type) === "cv"
                            ? "Vehicle"
                            : `${
                                TypeCategory(TypeReturn(type))
                                  .charAt(0)
                                  .toUpperCase() +
                                TypeCategory(TypeReturn(type)).slice(1)
                              }`
                        )
                        .replace(
                          /productType/gi,
                          TypeReturn(type) === "cv"
                            ? "Vehicle"
                            : `${
                                TypeReturn(type).charAt(0).toUpperCase() +
                                TypeReturn(type).slice(1)
                              }`
                        )
                    : `Buy vehicle insurance in easy steps. Please fill the details`
                  : theme_conf?.broker_config?.lead_page_title
                  ? theme_conf?.broker_config?.lead_page_title
                      .replace(
                        /productType2/g,
                        TypeReturn(type) === "cv"
                          ? "Vehicle"
                          : `${
                              TypeCategory(TypeReturn(type))
                                .charAt(0)
                                .toUpperCase() +
                              TypeCategory(TypeReturn(type)).slice(1)
                            }`
                      )
                      .replace(
                        /productType/gi,
                        TypeReturn(type) === "cv"
                          ? "Vehicle"
                          : `${
                              TypeReturn(type).charAt(0).toUpperCase() +
                              TypeReturn(type).slice(1)
                            }`
                      )
                  : process.env.REACT_APP_BROKER === "TATA"
                  ? `Get the Right Vehicle Insurance Quotes`
                  : process.env.REACT_APP_BROKER === "BAJAJ"
                  ? `Now Buy faster ${
                      type.charAt(0).toUpperCase() + type.slice(1)
                    } Insurance online`
                  : `Get the best Vehicle Insurance quotes in`}
              </StyledH4>
              {!theme_conf?.broker_config?.lead_page_title &&
                !lessthan767 &&
                process.env.REACT_APP_BROKER !== "TATA" && (
                  <StyledH4
                    className={`text-center w-100 mx-auto d-flex justify-content-center ${
                      process.env.REACT_APP_BROKER === "UIB" &&
                      "font-weight-bold"
                    }  `}
                  >
                    {process.env.REACT_APP_BROKER === "BAJAJ"
                      ? " in India."
                      : " 2 minutes."}
                  </StyledH4>
                )}
            </>
          </div>
        </Row>
        {!leadLoad || (enquiry_id && enquiry_id?.corporate_id) || !skip ? (
          <>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Row className={`w-100 d-flex no-wrap mt-5 mx-auto`}>
                <>
                  <Col sm="12" md={8} lg={8} xl={8} className="mx-auto">
                    <div className="w-100">
                      <Textbox
                        lg
                        type="text"
                        id="fullname"
                        fieldName="Full name"
                        name="fullName"
                        placeholder=" "
                        fontWeight={"1000"}
                        onInput={(e) =>
                          (e.target.value =
                            e.target.value.length <= 1
                              ? ("" + e.target.value).toUpperCase()
                              : e.target.value)
                        }
                        register={register}
                        onChange={handleChange}
                        error={
                          errors.fullName?.message ||
                          errors.firstName?.message ||
                          errors.lastName?.message
                        }
                      />
                      {(errors.fullName ||
                        errors.firstName ||
                        errors.lastName) && (
                        <Error
                          style={{
                            marginTop: "-20px",
                            ...(process.env.REACT_APP_BROKER === "BAJAJ" && {
                              textAlign: "left",
                              marginLeft: "18px",
                            }),
                          }}
                        >
                          {errors.fullName?.message ||
                            errors.firstName?.message ||
                            errors.lastName?.message}
                        </Error>
                      )}
                    </div>
                    <input type="hidden" ref={register} name="firstName" />
                    <input type="hidden" ref={register} name="lastName" />
                  </Col>
                  <Col sm="12" md={8} lg={8} xl={8} className="mx-auto">
                    <div className="w-100">
                      <Textbox
                        lg
                        type="text"
                        id="emailId"
                        fieldName="Email"
                        fontWeight={"1000"}
                        name="emailId"
                        placeholder=" "
                        register={register}
                        onChange={handleChange}
                        error={errors?.emailId}
                      />
                      {!!errors?.emailId && (
                        <Error
                          style={{
                            marginTop: "-20px",
                            ...(process.env.REACT_APP_BROKER === "BAJAJ" && {
                              textAlign: "left",
                              marginLeft: "18px",
                            }),
                          }}
                        >
                          {errors?.emailId?.message}
                        </Error>
                      )}
                    </div>
                  </Col>
                </>
                <Col sm="12" md={8} lg={8} xl={8} className="mx-auto">
                  <div className="w-100">
                    <Textbox
                      lg
                      type="tel"
                      id="mobileNo"
                      fieldName="Mobile No."
                      fontWeight={"1000"}
                      name="mobileNo"
                      placeholder=" "
                      register={register}
                      error={errors?.mobileNo}
                      maxLength="10"
                      onKeyDown={numOnly}
                    />
                    {!!errors?.mobileNo && (
                      <Error
                        style={{
                          marginTop: "-20px",
                          ...(process.env.REACT_APP_BROKER === "BAJAJ" && {
                            textAlign: "left",
                            marginLeft: "18px",
                          }),
                        }}
                      >
                        {errors?.mobileNo?.message}
                      </Error>
                    )}
                  </div>
                </Col>
                {brokerList && !sameNumber && (
                  <Col sm="12" md={8} lg={8} xl={8} className="mx-auto">
                    <div className="w-100">
                      <Textbox
                        lg
                        type="tel"
                        id="whatsappNo"
                        fieldName="Whatsapp No."
                        fontWeight={"1000"}
                        name="whatsappNo"
                        placeholder=" "
                        register={register}
                        error={errors?.whatsappNo}
                        maxLength="10"
                        onKeyDown={numOnly}
                        readOnly={sameNumber}
                      />
                      {!!errors?.whatsappNo && (
                        <Error
                          style={{
                            marginTop: "-20px",
                          }}
                        >
                          {errors?.whatsappNo?.message}
                        </Error>
                      )}
                    </div>
                  </Col>
                )}
                {brokerList && WhatsAppInput}
                {(process.env.REACT_APP_BROKER === "ABIBL" ||
                  process.env.REACT_APP_BROKER === "ACE") &&
                  FinalSubmit}

                <Col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  className="d-flex justify-content-center mt-1"
                  style={
                    firstName && lastName && mobileNo && emailId
                      ? {
                          transition:
                            "top 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                        }
                      : {}
                  }
                >
                  <Button
                    className="proceed-button"
                    buttonStyle="outline-solid"
                    style={{ width: "160px" }}
                    disabled={
                      btnDisable ||
                      (!fullName && !emailId && !mobileNo && !whatsappNo)
                    }
                    onClick={() => {
                      if (
                        ((fullName &&
                          fullName.match(/^([[0-9A-Za-z\s.'&_+@!#,-])+$/) &&
                          fullName.length < 101 &&
                          firstName?.length <= 50 &&
                          firstName?.length > 1 &&
                          (lastName && lastName !== 0
                            ? lastName.length <= 50
                            : true)) ||
                          (!fullName &&
                            fullName !== 0 &&
                            !theme_conf?.broker_config?.fullName)) &&
                        ((emailId &&
                          emailId.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) ||
                          (!emailId &&
                            emailId !== 0 &&
                            !theme_conf?.broker_config?.lead_email)) &&
                        ((mobileNo && mobileNo.match(/^[6-9][0-9]{9}$/)) ||
                          (!mobileNo &&
                            mobileNo !== 0 &&
                            !theme_conf?.broker_config?.mobileNo)) &&
                        ((whatsappNo && whatsappNo.match(/^[6-9][0-9]{9}$/)) ||
                          (!whatsappNo && whatsappNo !== 0))
                      ) {
                        navigator &&
                          navigator?.vibrate &&
                          navigator.vibrate([100, 0, 50]);
                        process.env.REACT_APP_BROKER === "RB" ||
                        process.env.REACT_APP_BROKER === "FYNTUNE"
                          ? onSubmit({
                              firstName: firstName,
                              lastName: lastName,
                              fullName: fullName,
                              emailId: emailId,
                              mobileNo: mobileNo,
                              whatsappNo: sameNumber ? mobileNo : whatsappNo,
                            })
                          : onSubmit({
                              firstName: firstName,
                              lastName: lastName,
                              fullName: fullName,
                              emailId: emailId,
                              mobileNo: mobileNo,
                            });
                        setbtnDisable(true);
                        (!theme_conf?.broker_config?.lead_otp ||
                          (process.env.REACT_APP_BROKER === "BAJAJ" &&
                            process.env.REACT_APP_BASENAME === "NA")) &&
                          setSkip(true);
                      } else {
                        if (!fullName && !emailId && !mobileNo && !whatsappNo) {
                          swal("Please enter your details");
                        } else {
                          handleSubmit(onSubmit);
                        }
                      }
                    }}
                    hex1={
                      fullName || emailId || mobileNo || whatsappNo
                        ? process.env.REACT_APP_BROKER === "RB"
                          ? Theme?.leadPageBtn?.background1
                          : Theme?.leadPageBtn?.background || "#bdd400"
                        : "#e7e7e7"
                    }
                    hex2={
                      fullName || emailId || mobileNo || whatsappNo
                        ? process.env.REACT_APP_BROKER === "RB"
                          ? Theme?.leadPageBtn?.background2
                          : Theme?.leadPageBtn?.background || "#bdd400"
                        : "#e7e7e7"
                    }
                    shadow={
                      firstName || lastName || emailId || mobileNo || whatsappNo
                        ? false
                        : "none"
                    }
                    borderRadius={
                      Theme?.leadPageBtn?.borderRadius
                        ? Theme?.leadPageBtn?.borderRadius
                        : "20px"
                    }
                    type="submit"
                  >
                    <text
                      style={{
                        color:
                          fullName || emailId || mobileNo || whatsappNo
                            ? Theme?.leadPageBtn?.textColor
                              ? Theme?.leadPageBtn?.textColor
                              : isRenewbuy
                              ? "white"
                              : "black"
                            : " black",
                      }}
                    >
                      <span>Proceed</span>{" "}
                      <span className="eastIcon">
                        <EastIcon sx={{ fontSize: "18px" }} />
                      </span>
                    </text>
                  </Button>
                </Col>
                {!(process.env.REACT_APP_BROKER === "BAJAJ" && !token) &&
                  (!theme_conf?.broker_config?.lead_otp ||
                    (process.env.REACT_APP_BROKER === "BAJAJ" &&
                      process.env.REACT_APP_BASENAME === "NA")) && (
                    <Col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      className="d-flex justify-content-center mt-2 noOutLine"
                    >
                      <Btn
                        className={`lead_link ${
                          Theme?.leadPageBtn?.link
                            ? Theme?.leadPageBtn?.link
                            : ""
                        }`}
                        variant={"link"}
                        type="button"
                        disabled={btnDisable}
                        onClick={() => {
                          navigator &&
                            navigator?.vibrate &&
                            navigator.vibrate([100, 0, 50]);
                          onSubmit({
                            firstName: null,
                            lastName: null,
                            emailId: null,
                            mobileNo: null,
                            isSkipped: true,
                          });
                          setbtnDisable(true);
                          setSkip(true);
                        }}
                      >
                        Skip for now
                      </Btn>
                    </Col>
                  )}
                {process.env.REACT_APP_BROKER === "UIB" && (
                  <Col
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    className="d-flex justify-content-center mt-2 noOutLine"
                  >
                    <Ribbon className="ribbon" type="button">
                      Enter Your Corporate Email-ID for Additional Discount
                    </Ribbon>
                  </Col>
                )}
              </Row>
            </Form>
            <GlobalStyle />
            <>
              <Realistic />
              <div>
                <StyledContainer
                  autoClose={false}
                  hideProgressBar
                  position="top-center"
                  style={{
                    width: "100%",
                    marginTop: "-20px",
                    textAlign: "center",
                  }}
                />
              </div>
            </>
            {show && !skip && (
              <OTPPopup
                enquiry_id={enquiry_id}
                show={show}
                onHide={() => setShow(false)}
                mobileNumber={mobileNo}
                email={emailId}
                otpSuccess={() => otpSuccess()}
                otpData={otpData}
                lead_otp
              />
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

const StyledH4 = styled.h4`
  font-size: ${process.env.REACT_APP_BROKER === "BAJAJ" ||
  process.env.REACT_APP_BROKER === "SPA" ||
  process.env.REACT_APP_BROKER === "EPOCH"
    ? "34px"
    : "36px"};
  color: ${({ theme }) => theme.regularFont?.fontColor || "rgb(74, 74, 74)"};
  ${process.env.REACT_APP_BROKER === "TATA" &&
  ` background: linear-gradient(to right, #00bcd4 0%, #ae15d4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;`}

  font-family: ${({ theme }) =>
    theme.regularFont?.headerFontFamily || "sans-serif"};
  white-space: pre-wrap;
  max-width: 760px;
  @media (max-width: 767px) {
    font-size: 22px;
  }
  @media (max-width: 375px) {
    font-size: 20.5px;
  }
  @media (max-width: 360px) {
    font-size: 20px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${({ theme }) =>
    theme?.fontFamily &&
    ` .gRDOdS,.gRDOdS ~ label,.gRDOdS:not(:placeholder-shown) ~ label,.cdsZcX , .cdsZcX:not(:placeholder-shown) ~ label,.lgaHSq, .lgaHSq:not(:placeholder-shown) ~ label, .cdsZcX ~ label{
  font-family: ${theme?.fontFamily} !important;
}`}

body {
  // background: #EAEAEA !important;
}

.proceed-button:hover .eastIcon{
  padding-left:8px;
  transition:0.2s ease-in-out;
}
.lead_link, .lead_link:hover {
  color: ${({ theme }) => theme.links?.color || ""};
}
`;

const SubmitDiv = styled.div`
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkbox-container input:checked ~ .checkmark,
  .plan-card .checkbox-container input:checked ~ .checkmark {
    background-color: ${({ theme }) =>
      theme?.proposalProceedBtn?.hex1
        ? theme?.proposalProceedBtn?.hex1
        : "#268f05"};
  }
  .checkbox-container .checkmark {
    position: absolute;
    top: 2.1px !important;
    left: 0 !important;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 0;
  }
  .checkbox-container .checkmarkwp {
    position: absolute;
    top: 2.1px !important;
    left: 0 !important;
    height: 18px !important;
    width: 18px !important;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 0;
  }
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  .checkbox-container .checkmark:after {
    content: url(${process.env.REACT_APP_BASENAME !== "NA"
      ? `/${process.env.REACT_APP_BASENAME}`
      : ""}/assets/images/checkbox-select.png);
    left: 1px;
    top: -10px;
    width: 17px;
    height: 16px;
    position: absolute;
  }
  .privacyPolicy {
    padding-left: 40px;
    font-size: 13px;
    color: #545151;
    font-family: ${({ theme }) =>
      theme?.fontFamily ? theme?.fontFamily : `sans-serif`};
    text-align: justify;
    text-justify: inter-word;
  }
  .whatsappNumber {
    padding-left: 30px;
    font-size: 10px !important;
    color: #545151;
    font-family: ${({ theme }) =>
      theme?.fontFamily ? theme?.fontFamily : `sans-serif`};
    text-align: justify;
    text-justify: inter-word;
  }

  @media screen and (max-width: 993px) {
    .checkbox-container .checkmark:after {
      content: url(/assets/images/checkbox-select.png);
      left: 1px;
      top: 0px;
      width: 17px;
      height: 16px;
      position: absolute;
      color: #0000;
    }
  }
`;

const Ribbon = styled.button`
  cursor: default !important;
  background-color: ${({ theme }) =>
    theme?.leadPageBtn?.background3 || "#f2f7cc"};
  color: ${({ theme }) =>
    process.env.REACT_APP_BROKER === "UIB"
      ? theme?.leadPageBtn?.textColor
      : theme?.Registration?.otherBtn?.hex1 || "#006400"};
  @media (max-width: 767px) {
    font-size: 10.5px;
    padding: 10px 35px;
  }
`;

const Proceed = styled.div``;

const StyledContainer = styled(ToastContainer)`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    width: 100%;
    background-color: ${({ theme }) =>
      theme?.leadPageBtn?.background3 || "#f2f7cc"};
    color: ${({ theme }) =>
      process.env.REACT_APP_BROKER === "UIB"
        ? theme?.leadPageBtn?.textColor
        : theme?.Registration?.otherBtn?.hex1 || "#006400"};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;
