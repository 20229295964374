import styled from "styled-components";
import React, { useState, useEffect } from "react"
import Popup from "components/Popup/Popup"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function FilePicker({
    register,
    id,
    name,
    watch,
    file,
    setFile,
    placeholder
}) {
    console.log(file, "file")
    const [show, setShow] = useState(false)
    const [url, setUrl] = useState("")
    const onClose = () => {
        setShow(false)
    }
    useEffect(() => {
        file && setUrl(URL.createObjectURL(file))

        return () => file && URL.revokeObjectURL(URL.createObjectURL(file))
    }, [file])

    const content = (
        <div style={{ height: "100%", width: "100%", display: "flex" }}>
            <img
                style={{ margin: "auto", maxWidth: "400px", maxHeight: "400px" }}
                src={url}
            />
        </div>
    )
    return (
        <Main className="">
            <Label id={id}>
                {file ? file?.name : placeholder}
                <input
                    type="file"
                    id={id}
                    name={name}
                    onChange={(e) => setFile(e.target.files[0])}
                />
                {file &&
                    <div style={{ marginLeft: "auto" }} onClick={() => setFile()}>
                        <HighlightOffIcon />
                    </div>
                }
            </Label>
            {file && (
                <span onClick={() => setShow(true)}>Preview</span>
            )
            }
            <Popup
                top="40%"
                show={show}
                onClose={onClose}
                content={content}
                position="middle"
                height="400px"
                width="400px"
            />
        </Main>
    )
}
const Main = styled.div`
    display: flex;
    span {
        border: 1px solid #999;
        padding:0.2rem 0.25rem;
        height: 33px;
        cursor: pointer;
    }
`
const Label = styled.label`
padding:0.2rem 0.25rem;
font-size: 15px;
font-weight: normal;
display: flex;
width: 100%;
height: 33.6px;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #999;
border-radius: 0;
margin-bottom: 0px !important;
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
cursor: pointer;
overflow: hidden;
input {
     height: 0;
     width:0;
     display: none;
}

`

