import React from "react";
import { useSelector } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { useMediaPredicate } from "react-media-hook";
import { Row, Col } from "react-bootstrap";
// import FacebookIcon from "@material-ui/icons/Facebook";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import YouTubeIcon from "@material-ui/icons/YouTube";
// import LinkedInIcon from "@material-ui/icons/LinkedIn";
// import InstagramIcon from '@mui/icons-material/Instagram';
import facebook from "assets/img/facebook.svg";
import twitter from "assets/img/twitter.svg";
import instagram from "assets/img/instagram.svg";
import { reloadPage } from "utils";

const Footer = () => {
  const { theme_conf } = useSelector((state) => state.home);

  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  return (
    <>
      <FooterTag
        style={{
          backgroundColor: "#fff",
          textAlign: lessthan767 ? "left" : "center",
          display: "flex",
        }}
      >
        <div>
          <Row
            style={{
              padding: lessthan767 ? "30px 15px" : "38px 13.4% 0 13.4%",
            }}
          >
            <Col xl={4} lg={4} md={4} sm={6} xs={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <a>
                  <Logo
                    style={{}}
                    src={`${
                      process.env.REACT_APP_BASENAME !== "NA"
                        ? `/${process.env.REACT_APP_BASENAME}`
                        : ""
                    }/assets/images/logo/bajaj.png`}
                    alt="logo"
                  />
                </a>
                <Address>
                  Bajaj Capital Insurance Broking Limited Bajaj House, 97, Nehru
                  Place, New Delhi -110019.
                </Address>
                <Link href="mailto:info@bajajcapitalinsurance.com">
                  {theme_conf?.broker_config?.email
                    ? theme_conf?.broker_config?.email
                    : "info@bajajcapitalinsurance.com"}
                </Link>
                <Link href="tel:1800-212-123123">
                  {theme_conf?.broker_config?.phone
                    ? theme_conf?.broker_config?.phone
                    : "1800 - 212 - 123123"}
                </Link>
              </div>
            </Col>
            <Col
              xl={2}
              lg={2}
              md={2}
              sm={6}
              sx={6}
              style={{ marginTop: lessthan767 ? "" : "" }}
            >
              <FooterTitle>Our Services</FooterTitle>
              <div style={{}}>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage(
                      "https://partneruat.bajajcapitalinsurance.com/RmLogin.aspx#insurance"
                    )
                  }
                >
                  Term Insurance
                </p>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage(
                      "https://partneruat.bajajcapitalinsurance.com/RmLogin.aspx#insurance"
                    )
                  }
                >
                  Health Insurance
                </p>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage(
                      "https://partneruat.bajajcapitalinsurance.com/RmLogin.aspx#insurance"
                    )
                  }
                >
                  Car Insurance
                </p>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage(
                      "https://partneruat.bajajcapitalinsurance.com/RmLogin.aspx#insurance"
                    )
                  }
                >
                  Bike Insurance
                </p>
              </div>
            </Col>
            <Col xl={2} lg={2} md={2} sm={6} sx={6}>
              <FooterTitle>Quick Links</FooterTitle>
              <div style={{}}>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage(
                      "https://partneruat.bajajcapitalinsurance.com/about.aspx"
                    )
                  }
                >
                  About us
                </p>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage(
                      "https://partneruat.bajajcapitalinsurance.com/contact-us.aspx"
                    )
                  }
                >
                  Contact us
                </p>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage("https://uatpos.bajajcapitalinsurance.com/")
                  }
                >
                  Earn With Us
                </p>
                {/* <p
                className="underline-on-hover"
                onClick={() =>
                  reloadPage(
                    "https://b2b.bajajcapitalinsurance.com/RegisterPos/Index"
                  )
                }
              >
                Register
              </p> */}
              </div>
            </Col>
            <Col xl={2} lg={2} md={2} sm={6} sx={6}>
              <FooterTitle>Legal Policy</FooterTitle>
              <div style={{}}>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage(
                      "https://partneruat.bajajcapitalinsurance.com/privacy-policy.aspx"
                    )
                  }
                >
                  Privacy Policy
                </p>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage(
                      "https://partneruat.bajajcapitalinsurance.com/terms-and-conditions.aspx"
                    )
                  }
                >
                  Terms & Conditions
                </p>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage(
                      "https://partneruat.bajajcapitalinsurance.com/disclaimer.aspx"
                    )
                  }
                >
                  Disclaimer
                </p>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage(
                      "https://partneruat.bajajcapitalinsurance.com/csr-policy.aspx"
                    )
                  }
                >
                  CSR Policy
                </p>
                <p
                  className="underline-on-hover"
                  onClick={() =>
                    reloadPage(
                      "https://partneruat.bajajcapitalinsurance.com/antifraud-policy.aspx"
                    )
                  }
                >
                  Anti-Fraud Policy
                </p>
              </div>
            </Col>
            <Col xl={2} lg={2} md={2} sm={6} sx={6}>
              <FooterTitle
                style={{ textAlign: lessthan767 ? "left" : "center" }}
              >
                Follow Us
              </FooterTitle>
              {/* <div style={{ marginTop: "22px" }}>
              <p style={{ fontWeight: "bold" }}>1800-212-123123</p>
              <p>Mon — Sat(10AM — 6PM)</p>
            </div> */}
              <MediaContainer
                style={{ alignItems: lessthan767 ? "left" : "center" }}
              >
                {/* <FacebookIcon
                className="cursorChange"
                onClick={() =>
                  reloadPage("https://www.facebook.com/bajajcapitalinsurance")
                }
                style={{ fontSize: "35px", color: "#000000",padding:"4px" }}
              />
              <TwitterIcon
                className="cursorChange"
                onClick={() => reloadPage("https://twitter.com/Bajaj_insurance")}
                style={{ fontSize: "35px", color: "#000000",padding:"4px" }}
              />
              <InstagramIcon
                className="cursorChange"
                onClick={() => reloadPage("https://www.instagram.com/bajaj_capital_insurance/")}
                style={{ fontSize: "35px", color: "#000000",padding:"4px" }}
              /> */}
                <div
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  <a href="https://www.facebook.com/bajajcapitalinsurance">
                    <img src={facebook} style={{ paddingRight: "10px" }} />
                  </a>
                  <a href="https://twitter.com/Bajaj_insurance">
                    <img src={twitter} style={{ paddingRight: "10px" }} />
                  </a>
                  <a href="https://www.instagram.com/bajaj_capital_insurance/">
                    <img src={instagram} style={{ paddingRight: "10px" }} />
                  </a>
                </div>
                {/* <YouTubeIcon
                className="cursorChange"
                onClick={() =>
                  reloadPage("https://www.youtube.com/user/bajajcapital")
                }
                style={{ fontSize: "35px", color: "#ff0000" }}
              />
              <LinkedInIcon
                className="cursorChange"
                onClick={() =>
                  reloadPage(
                    "https://in.linkedin.com/company/bajaj-capital-ltd"
                  )
                }
                style={{ fontSize: "35px", color: "#0077b5" }}
              /> */}
              </MediaContainer>
            </Col>
          </Row>
          <Line
            style={{
              marginLeft: lessthan767 ? "0" : "13.4%",
              marginRight: "13.4%",
            }}
          />
          <MiddleFooter>
            <Content
              style={{
                padding: lessthan767 ? "30px 15px" : "10px 13.4% 0",
              }}
            >
              This site is owned and operated by Bajaj Capital Insurance Broking
              Limited [“BCIBL” (CIN:U67200DL2002PLC117625)], an IRDA licensed
              Composite Broker bearing{" "}
              <span>
                <a
                  target="_blank"
                  href="https://partneruat.bajajcapitalinsurance.com/externalpdf/BrokingCertificate.pdf"
                >
                  License No. 241, License Code CB 042/02,
                </a>
              </span>{" "}
              license dated 09-01-2022 valid till 08-01-2025 (originally
              licensed by IRDA on 09/01/2004 and renewed thereafter).BCIBL is a
              member of Insurance Brokers Association of India (Membership
              No.119). The Prospect’s/visitor’s particulars could be shared with
              users. The information displayed on this website is of the
              insurers with whom BCIBL has an agreement. Insurance is the
              subject matter of solicitation.
            </Content>
          </MiddleFooter>
          <BottomFooter
          // style={{
          //   padding: lessthan767 ? "5px" : "5px 81px",
          //   flexDirection: lessthan767 ? "column" : "row",
          // }}
          >
            <p style={{ textAlign: "center" }}>
              Copyright © 2020 Bajaj Capital Insurance Broking Limited
            </p>
            {/* <p style={{ fontSize: lessthan767 && "12px" }}>
            <text
              onClick={() =>
                reloadPage(
                  "https://b2b.bajajcapitalinsurance.com/BajajInsurance/Disclaimer"
                )
              }
              className="underline-on-hover"
            >
              Legal Disclaimer
            </text>{" "}
            |{" "}
            <text
              onClick={() =>
                reloadPage(
                  "https://b2b.bajajcapitalinsurance.com/BajajInsurance/PrivacyPolicy"
                )
              }
              className="underline-on-hover"
            >
              Privacy Policy
            </text>{" "}
            |{" "}
            <text
              onClick={() =>
                reloadPage(
                  "https://b2b.bajajcapitalinsurance.com/BajajInsurance/TermsAndCondition"
                )
              }
              className="underline-on-hover"
            >
              Terms and Conditions
            </text>
          </p> */}
          </BottomFooter>
          <GlobalStyle lessthan767={lessthan767} />
        </div>
      </FooterTag>
    </>
  );
};

export default Footer;

const GlobalStyle = createGlobalStyle`
${({ lessthan767 }) =>
  lessthan767
    ? `
body::after {
    content: '';
    display: block;
    height: 1613px; /* Set same as footer's height */
  }`
    : `
body::after {
    content: '';
    display: block;
    height: 520px; /* Set same as footer's height */
  }
`}`;

const Logo = styled.img`
  height: 42px;
  width: 181px;
  margin-bottom: 1.25rem;
  vertical-align: middle;
  border-style: none; ;
`;
const Content = styled.div`
  // max-width: 100%;
  color: #00000087;
  padding: 1rem 0;
  line-height: 32px;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 3rem;
  span {
    color: #1c75bc;
    cursor: pointer;
    &:hover {
      color: #ed1b24;
    }
  }
  a {
    color: inherit;
    font-size: 14px;
  }
`;

const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterTag = styled.footer`
  text-align: left !important;
  font-size: 1rem;
  font-weight: 500;
  padding: 4rem 0 0 0 !important;
  .underline-on-hover {
    // text-decoration: underline;
    cursor: pointer;
    color: inherit;
    font-size: 14px;
    margin-bottom: 0.5rem !important;
    &:hover {
      color: #ed1b24;
      text-decoration: none;
    }
  }
  .cursorChange {
    cursor: pointer;
  }
  //   text-align: left !important;
`;

const FooterTitle = styled.div`
  margin: 8px 0 10px;
  line-height: inherit;
  color: #1c75bc;
  font-size: 16px;
  line-height: 20px;
`;

const Line = styled.hr`
  height: 3px;
`;

const BottomFooter = styled.div`
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: #00000087;
    max-width: 100%;
    margin: 0.7rem 0;
    color: #00000087;
  }
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
`;

const Address = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  margin-bottom: 0.5rem;
  color: #1e2833;
  max-width: 300px;
`;

const Link = styled.a`
  color: inherit;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  &:hover {
    color: #ed1b24;
    text-decoration: none;
  }
`;
const MiddleFooter = styled.div``;
