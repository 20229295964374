import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { reloadPage } from "utils";
import swal from "sweetalert";

export const CarGetDataMmv = () => {
  const history = useHistory();
  const redirectTo = () => {
    swal({
      title: "Confirm action",
      text: "Redirect to MMV Data.",
      icon: "info",
      buttons: {
        cancel: "cancel",
        catch: {
          text: "Confirm",
          value: "confirm",
        },
      },
      dangerMode: true,
    }).then((setValue) => {
      switch (setValue) {
        case "confirm":
          reloadPage(`${process.env.REACT_APP_API_BASE_URL}/car/getdata`);
          break;
        default:
          history.replace("/");
      }
    });
  };
  useEffect(() => {
    redirectTo();
  }, []);
  return <></>;
};
