import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useMediaPredicate } from "react-media-hook";
import { Row, Col } from "react-bootstrap";
import facebook from "assets/img/facebook.svg";
import twitter from "assets/img/twitter.svg";
import instagram from "assets/img/instagram.svg";
import tick from "assets/img/tick.svg";
import Linkedin from "assets/img/linkedin.png";
import { TypeReturn } from "modules/type";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import _ from "lodash";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import Tata from "../../../utils/tata.png";
import Copyright from "../../../utils/tata-title.png";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";

const Footer = () => {
  const { theme_conf } = useSelector((state) => state.home);
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const lessthan576 = useMediaPredicate("(max-width: 576px)");
  const location = useLocation();
  const loc = location.pathname ? location.pathname.split("/") : "";
  const type = !_.isEmpty(loc) ? (loc?.length >= 2 ? loc[1] : "") : "";
  const { temp_data: temp } = useSelector((state) => state.proposal);
  const [listdisplay, setListDisplay] = useState(false);
  return (
    <>
      <FooterTag
        style={{
          backgroundColor: "#374ddb",
          textAlign: lessthan767 ? "left" : "center",
          padding: "60px 0 0px 0",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Main>
          <a
            href={`${window.location.origin}${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ``
            }/${
              Number(temp?.productSubTypeId) === 1
                ? "car"
                : Number(temp?.productSubTypeId) === 2
                ? "bike"
                : type && ["car", "bike", "cv"].includes(TypeReturn(type))
                ? type
                : temp?.journeyCategory
                ? temp?.journeyCategory.toLowerCase() === "pcv" ||
                  temp?.journeyCategory.toLowerCase() === "gcv"
                  ? "cv"
                  : temp?.journeyCategory.toLowerCase()
                : "cv"
            }/lead-page`}
          >
            <Logo
              // style={{}}
              src={Tata}
              alt="logo"
            />
          </a>
          <Line
            style={{
              marginLeft: lessthan767 ? "0" : "13.1%",
              marginTop: "-30px",
            }}
          />
          <Row
            style={{ paddingTop: "30px" }}
            onMouseLeave={() => setListDisplay(false)}
          >
            <Col
              xl={3}
              lg={3}
              md={3}
              sm={6}
              sx={6}
              style={{ marginTop: lessthan767 ? "" : "" }}
            >
              <FooterContainer onMouseEnter={() => setListDisplay(true)}>
                {/* <img
                  className="tickLogo"
                  src={tick}
                  style={{ height: "37px", width: "15px" }}
                  alt=""
                /> */}
                {/* <CheckIcon
                  sx={{ fontSize: "24px", color: "#ffffff", fontWeight: 700 }}
                /> */}
                {/* <a href="abc"> */}
                <FooterTitle style={{ cursor: "pointer" }}>
                  Products
                </FooterTitle>
                {/* </a> */}
              </FooterContainer>
              <InsideText
                style={
                  listdisplay
                    ? {}
                    : lessthan767
                    ? { display: "none" }
                    : { display: "none" }
                }
              >
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/health-insurance`}
                >
                  <LocalHospitalIcon
                    sx={{
                      fontSize: "20px",
                      color: "#ffffff",
                      fontWeight: 700,
                      marginRight: "4px",
                    }}
                  />{" "}
                  Health Insurance
                </a>
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/car-insurance`}
                >
                  <DirectionsCarIcon
                    sx={{
                      fontSize: "20px",
                      color: "#ffffff",
                      fontWeight: 700,
                      marginRight: "4px",
                    }}
                  />{" "}
                  Car Insurance
                </a>
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/bike-insurance`}
                >
                  <TwoWheelerIcon
                    sx={{
                      fontSize: "20px",
                      color: "#ffffff",
                      fontWeight: 700,
                      marginRight: "4px",
                    }}
                  />{" "}
                  Bike Insurance
                </a>
              </InsideText>
              <FooterContainer>
                {/* <img
                  src={tick}
                  className="tickLogo"
                  style={{ height: "37px", width: "15px" }}
                  alt=""
                /> */}
                {/* <CheckIcon sx={{ fontSize: "24px", color: "#ffffff" }} /> */}
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat`
                  }dashboard.lifekaplan.com/customer/login`}
                >
                  <FooterTitle>Claims</FooterTitle>
                </a>
              </FooterContainer>
              <InsideText
                style={
                  listdisplay
                    ? { display: "none" }
                    : lessthan767
                    ? { display: "none" }
                    : { visibility: "hidden" }
                }
              >
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/health-insurance`}
                >
                  <RemoveIcon
                    sx={{ fontSize: "24px", color: "#ffffff", fontWeight: 700 }}
                  />{" "}
                  Health Insurance
                </a>
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/car-insurance`}
                >
                  <RemoveIcon
                    sx={{ fontSize: "24px", color: "#ffffff", fontWeight: 700 }}
                  />{" "}
                  Car Insurance
                </a>
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/bike-insurance`}
                >
                  <RemoveIcon
                    sx={{ fontSize: "24px", color: "#ffffff", fontWeight: 700 }}
                  />{" "}
                  Bike Insurance
                </a>
              </InsideText>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} sx={6}>
              <FooterContainer>
                {/* <img
                  src={tick}
                  className="tickLogo"
                  style={{ height: "37px", width: "15px" }}
                  alt=""
                /> */}
                {/* <CheckIcon sx={{ fontSize: "24px", color: "#ffffff" }} /> */}
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/need-help`}
                >
                  <FooterTitle>Need Help?</FooterTitle>
                </a>
              </FooterContainer>
              {/* <FooterContainer>
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/health-insurance#`}
                >
                  <FooterTitle>Insurance made simple</FooterTitle>
                </a>
              </FooterContainer> */}
              <FooterContainer>
                {/* <img
                  src={tick}
                  className="tickLogo"
                  style={{ height: "37px", width: "15px" }}
                  alt=""
                /> */}
                {/* <CheckIcon sx={{ fontSize: "24px", color: "#ffffff" }} /> */}
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/#testi`}
                >
                  <FooterTitle>Our Happy Customers</FooterTitle>
                </a>
              </FooterContainer>
              <FooterContainer>
                {/* <img
                  src={tick}
                  className="tickLogo"
                  style={{ height: "37px", width: "15px" }}
                  alt=""
                /> */}
                {/* <CheckIcon sx={{ fontSize: "24px", color: "#ffffff" }} /> */}
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/#partners`}
                >
                  <FooterTitle>Insurance Partners</FooterTitle>
                </a>
              </FooterContainer>
            </Col>
            <Col
              xl={3}
              lg={3}
              md={3}
              sm={6}
              sx={6}
              style={{
                borderRight: !lessthan767 ? "1px  solid white" : "",
                borderBottom: lessthan576 ? "1px solid white" : "",
              }}
            >
              <FooterContainer>
                {/* <img
                  src={tick}
                  className="tickLogo"
                  style={{ height: "37px", width: "15px" }}
                  alt=""
                /> */}
                {/* <CheckIcon sx={{ fontSize: "24px", color: "#ffffff" }} /> */}
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat`
                  }dashboard.lifekaplan.com/customer/login`}
                >
                  <FooterTitle>Log in</FooterTitle>
                </a>
              </FooterContainer>
              <FooterContainer>
                {/* <img
                  src={tick}
                  className="tickLogo"
                  style={{ height: "37px", width: "15px" }}
                  alt=""
                /> */}
                {/* <CheckIcon sx={{ fontSize: "24px", color: "#ffffff" }} /> */}
                <a href="https://www.tatamotorsinsurancebrokers.com/our-story">
                  <FooterTitle>About Us</FooterTitle>
                </a>
              </FooterContainer>
              <FooterContainer>
                {/* <img
                  src={tick}
                  className="tickLogo"
                  style={{ height: "37px", width: "15px" }}
                  alt=""
                /> */}
                {/* <CheckIcon sx={{ fontSize: "24px", color: "#ffffff" }} /> */}
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/contact-us`}
                >
                  <FooterTitle>Contact Us</FooterTitle>
                </a>
              </FooterContainer>
              <FooterContainer>
                {/* <img
                  src={tick}
                  className="tickLogo"
                  style={{ height: "37px", width: "15px" }}
                  alt=""
                /> */}
                {/* <CheckIcon sx={{ fontSize: "24px", color: "#ffffff" }} /> */}
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/grievance-redressal`}
                >
                  <FooterTitle>Grievance Redressal</FooterTitle>
                </a>
              </FooterContainer>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} sx={6}>
              <MediaContainer>
                <div
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "relative",
                    marginTop: lessthan767 ? "30px" : "",
                  }}
                >
                  {/* <a href="https://twitter.com/Bajaj_insurance">
                    <img className="socialIcon" src={twitter} alt="" />
                  </a>
                  <a href="https://www.instagram.com/bajaj_capital_insurance/">
                    <img className="socialIcon" src={instagram} alt="" />
                  </a> */}
                  Follow us on:{" "}
                  <a
                    href={`https://${
                      process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                    }lifekaplan.com/health-insurance#`}
                  >
                    <img
                      className="socialIcon"
                      src={Linkedin}
                      style={{ padding: "8px", marginLeft: "6px" }}
                      alt=""
                    />
                  </a>
                </div>
                <div style={{ marginTop: "15px", color: "#fff" }}>
                  <ContactText style={{ marginBottom: "1rem" }}>
                    Contact us on
                  </ContactText>
                  <ContactText>
                    <CallIcon sx={{ fontSize: "18px" }} />
                    {theme_conf?.broker_config?.phone
                      ? theme_conf?.broker_config?.phone
                      : "18002090060"}
                  </ContactText>
                  <ContactText>
                    <MailOutlineIcon sx={{ fontSize: "18px" }} />
                    {theme_conf?.broker_config?.email
                      ? theme_conf?.broker_config?.email
                      : "support@tmibasl.com"}
                  </ContactText>
                </div>
              </MediaContainer>
            </Col>
          </Row>
        </Main>
        <MiddleFooter>
          <Content>
            <CopyrightText src={Copyright} alt="" />
            <TollText>
              Composite Broker License No.{" "}
              {theme_conf?.broker_config?.irdanumber
                ? theme_conf?.broker_config?.irdanumber
                : "375"}{" "}
              I Validity 13/05/2020 to 12/05/2023 I CIN:{" "}
              {theme_conf?.broker_config?.cinnumber
                ? theme_conf?.broker_config?.cinnumber
                : "U50300MH1997PLC149349"}{" "}
              | IBAI Membership No. 35375 <br />
              Corp Office: 1st Floor AFL House, Lok Bharti complex, Marol
              Maroshi Road, Andheri (East), Mumbai - 400 059. Maharashtra.
              India. <br />
              Registered Office: Nanavati Mahalaya, 3rd floor, Tamarind Lane,
              Homi Mody Street, Fort, Mumbai - 400 001. Maharashtra. India.{" "}
              <br />A sister Company of TATA AIA Life Insurance Company Limited
              and TATA AIG General Insurance Company Limited |{" "}
              <span>
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/terms-conditions`}
                >
                  Terms & Conditions |
                </a>
              </span>{" "}
              <span>
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/code-of-conduct`}
                >
                  Broker Code of Conduct |
                </a>
              </span>{" "}
              <span>
                <a
                  href={`https://${
                    process.env.REACT_APP_PROD === "YES" ? `` : `uat.`
                  }lifekaplan.com/privacy-policy`}
                >
                  Privacy Policy |
                </a>
              </span>{" "}
              <span>
                <a href="https://www.irdai.gov.in/">IRDAI |</a>
              </span>{" "}
              <span>
                <a href="http://ibai.org/">IBAI</a>
              </span>{" "}
            </TollText>
            {/* <TollText>
              Corp Office: 1st Floor AFL House, Lok Bharti complex, Marol
              Maroshi Road, Andheri (East), Mumbai - 400 059. Maharashtra.
              India.
            </TollText> */}
            {/* <TollText>
              Registered Office: Nanavati Mahalaya, 3rd floor, Tamarind Lane,
              Homi Mody Street, Fort, Mumbai - 400 001. Maharashtra. India.
            </TollText> */}
            {/* <TollText>
              A sister Company of TATA AIA Life Insurance Company Limited and
              TATA AIG General Insurance Company Limited |{" "}
              <span>
                <a href="https://uat.lifekaplan.com/terms-conditions">
                  Terms & Conditions |
                </a>
              </span>{" "}
              <span>
                <a href="https://uat.lifekaplan.com/code-of-conduct">
                  Broker Code of Conduct |
                </a>
              </span>{" "}
              <span>
                <a href="https://uat.lifekaplan.com/privacy-policy">
                  Privacy Policy |
                </a>
              </span>{" "}
              <span>
                <a href="https://www.irdai.gov.in/">IRDAI |</a>
              </span>{" "}
              <span>
                <a href="http://ibai.org/">IBAI</a>
              </span>{" "}
            </TollText> */}
          </Content>
        </MiddleFooter>
        <GlobalStyle lessthan767={lessthan767} />
      </FooterTag>
    </>
  );
};

export default Footer;

const GlobalStyle = createGlobalStyle`
${({ lessthan767 }) =>
  lessthan767
    ? `
body::after {
    content: '';
    display: block;
    height: 1613px; /* Set same as footer's height */
  }`
    : `
body::after {
    content: '';
    display: block;
    height: 520px; /* Set same as footer's height */
  }
`}
.tickLogo{
  filter:  brightness(0) invert(1);
}
.socialIcon{
  width: 30px;
  background: white;
  height: 30px;
  border-radius: 5px;
  padding: 5px;
  margin-right: 15px;
}

`;

const Logo = styled.img`
  height: 60px;
  width: 171px;
  object-fit: contain;
  margin-bottom: 1.25rem;
  vertical-align: middle;
  border-style: none;
  // filter: brightness(0) invert(1);
`;
const Content = styled.div`
  // max-width: 100%;
  color: #fff;
  /* padding-top: 1rem !important;
  padding-bottom: 1rem !important; */
  padding: 16px 50px;
  // line-height: 32px;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  a {
    color: inherit;
    font-size: 14px;
  }
`;

const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterTag = styled.footer`
  text-align: left !important;
  font-size: 1rem;
  font-weight: 500;
`;

const FooterContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
  a {
    text-decoration: none;
    &:hover {
      color: "";
      text-decoration: none;
    }
  }
`;

const ContactText = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  color: #fff;
`;

const FooterTitle = styled.h4`
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
  font-family: "Roboto", sans-serif;
`;

const Line = styled.hr`
  background: #fff;
`;

const MiddleFooter = styled.div`
  background-color: rgb(30, 42, 168);
  @media only screen and (max-width: 768px) {
    padding: 1rem 2rem 3rem 2rem !important;
  }
`;

const TollText = styled.p`
  margin-bottom: 0px !important;
  line-height: 20px;
  span {
    a {
      cursor: pointer;
      font-size: 12px;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const InsideText = styled.div`
  margin: 0px 0px 10px 20px;
  font-size: 14px;
  font-family: ${({ theme }) =>
    theme?.fontFamily ? theme?.fontFamily : `"Roboto", sans-serif`};
  display: flex;
  flex-direction: column;
  a {
    color: #fff;
    margin: 5px;
    text-decoration: none;
  }
`;

const Main = styled.div`
  padding: 1rem 4rem 3rem 4rem !important;
  @media only screen and (max-width: 768px) {
    padding: 1rem 2rem 3rem 2rem !important;
  }
`;

const CopyrightText = styled.img`
  width: 420px;
  margin-bottom: 15px;
  margin-top: 8px;
`;
