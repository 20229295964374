import React, { useState, useEffect } from "react";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import styled, { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import "./toaster.scss";
import { Bounce } from "react-toastify";
toast.configure();

// toast component for edit details toaster

function ToasterPolicyChange({
	callToaster,
	setCall,
	content,
	buttonText,
	setEdit,
	type,
	Theme,
	setToasterShown,
}) {
	const notify = () => {
		toast(
			<ToastMessageContainer Theme={Theme}>
				{/* <ContactImg
					src={"/assets/images/pointingup.png"}
					alt="pointer"
				></ContactImg> */}
				<ToastMessage>{content} </ToastMessage>{" "}
				<div style={{ display: "flex", justifyContent: "flex-end" }}></div>{" "}
			</ToastMessageContainer>,
			{
				transition: Bounce,
				className: "toasterStyle",
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,

				style: {
					position: "relative",
					top: Theme?.QuoteBorderAndFont?.toasterTop
						? Theme?.QuoteBorderAndFont?.toasterTop
						: "125px",
					right: "70px",
					width: "300px",
				},
			}
		); // Default
	};
	useEffect(() => {
		if (callToaster) {
			notify();
			setCall(false);
			setToasterShown(false);
		}
	}, [callToaster]);
	return (
		<div>
			<GlobalStyle Theme={Theme} />
		</div>
	);
}

export default ToasterPolicyChange;

const GlobalStyle = createGlobalStyle`
body {
	.Toastify__progress-bar-theme--light {
		background: ${({ Theme }) =>
			`${
				Theme?.QuoteCard?.color
					? `${Theme?.QuoteCard?.color} !important`
					: "#bdd400 !important"
			}`};
	}
}
`;

const ToastMessageContainer = styled.div`
	width: 100%;
	font-family: ${({ Theme }) =>
		Theme.regularFont?.fontFamily || " Inter-Medium"};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.toasterStyle {
		.Toastify__progress-bar-theme--light {
			background: ${({ Theme }) =>
				`${
					Theme.QuoteCard?.color
						? `${Theme.QuoteCard?.color} !important`
						: "#bdd400 !important"
				}`};
		}
	}
`;

const ToastButton = styled.div`
	font-family: ${({ Theme }) =>
		Theme.regularFont?.fontFamily || " Inter-Medium"};
	background-color: ${({ Theme }) => Theme.QuotePopups?.color || "#bdd400"};
	border: ${({ Theme }) => Theme.QuotePopups?.border || "1px solid #bdd400"};
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ Theme }) => Theme.QuoteBorderAndFont?.fontColor || "#000"};
	flex-direction: column;
	padding: 5px 10px;
	margin-top: 5px;
	border-radius: ${({ Theme }) =>
		Theme.QuoteBorderAndFont?.borderRadius || "30px"};
`;

const ToastMessage = styled.div`
	font-family: ${({ Theme }) =>
		Theme?.regularFont?.fontFamily || " Inter-Medium"};
	color: black;
`;
