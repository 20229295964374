import React, { useEffect, useState } from "react";
import { Form, Row, Col, ModalBody } from "react-bootstrap";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { CompactCard, Button, ErrorMsg } from "components";
import { SubmitData } from "./generate.slice";
import { useDispatch, useSelector } from "react-redux";
import { downloadFile } from "utils";
import { Loader } from "components";
import { Modal } from "react-bootstrap";
import { clear } from "./generate.slice";
import swal from "sweetalert";
import { useMediaPredicate } from "react-media-hook";

const yupValidate = yup.object({
  selection: yup
    .string()
    .required("Enquiry Id is required")
    .matches(/^[^@]+$/, "Enquiry Id is required"),
});

const GeneratePdf = () => {
  const { handleSubmit, register, errors, watch, setValue } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { submit, error, loading } = useSelector((state) => state.generate);

  const dispatch = useDispatch();

  const selection = watch("selection");

  // useEffect(() => {
  //     dispatch(clear())
  //     setTimeout(() => {
  //         if (selection) {
  //             console.log(selection)
  //             dispatch(SubmitData({ enquiryId: selection }))
  //         }
  //     }, 1000)
  // }, [selection])

  const submitHandler = (data) => {
    console.log(data);
    dispatch(clear());
    if (selection) {
      console.log(selection);
      dispatch(
        SubmitData({
          enquiryId: data.selection,
          url: `${process.env.REACT_APP_API_BASE_URL}/generatePdf`,
        })
      );
    }
  };

  const handleClick = () => {
    if (submit?.pdf_link) {
      downloadFile(submit?.pdf_link, false, true);
      handleReset();
    }
  };

  const handleReset = () => {
    dispatch(clear());
    setValue("selection", "");
  };

  useEffect(() => {
    if (submit?.pdf_link) {
      swal({
        title: "Info",
        text: `Your Policy Number is ${submit?.policy_number}`,
        icon: "success",
        timer: 2000,
      });
    } else if (!loading && error) {
      swal({
        title: "Error",
        text: error,
        icon: "error",
        timer: 2000,
      });
    }
  }, [submit, error, dispatch]);

  // const PolicyPopup = () => {

  //     const [show, setShow] = useState(false)

  //     return (
  //         <TopDiv>
  //         <DownloadButton disabled={!submit?.pdf_link} onClick={() => setShow(true)}>Proceed</DownloadButton>
  //         <Modal show={show} centered onHide={() => setShow(false)}>
  //             <Modal.Body style={{ textAlign: 'center', padding: '40px 10px', borderRadius: '20px' }}>
  //                 <p>Your Policy Number is <text style={{ color: 'green' }}>{submit?.policy_number}</text></p>
  //                 <DownloadButton onClick={handleClick}>Download PDF</DownloadButton>
  //             </Modal.Body>
  //         </Modal>
  //         </TopDiv>
  //     )
  // }

  const BaseUrls = [
    {
      name: "UNILIGHT",
      url: "https://api-unilight-uat.fynity.in/api/generatePdf",
    },
    {
      name: "UNILIGHT-PROD",
      url: "https://apipcv.policyongo.com/api/generatePdf",
    },
    {
      name: "UNILIGHT-PREPROD",
      url: "https://api-unilight-preprod.fynity.in/api/generatePdf",
    },
    { name: "OLA", url: "https://api-ola-uat.fynity.in/api/generatePdf" },
    {
      name: "OLA-PREPROD",
      url: "https://api-ola-preprod.fynity.in/api/generatePdf",
    },
    { name: "OLA-PROD", url: "https://olaapi.fynity.in/api/generatePdf" },
    { name: "FYNTUNE", url: "https://apimotor.fynity.in/api/generatePdf" },
    {
      name: "COMPARE-POLICY",
      url: "https://apimotoruat.comparepolicy.com/api/generatePdf",
    },
    {
      name: "COMPARE-POLICY-PROD",
      url: "https://apimotor.comparepolicy.com/api/generatePdf",
    },
    {
      name: "POLIC-ON-GO",
      url: "https://apipcv.policyongo.com/api/generatePdf",
    },
    { name: "ABIBL-DEV", url: "https://apimotor.fynity.in/api/generatePdf" },
    {
      name: "ABIBL-BUILD",
      url: "https://api-carbike.fynity.in/api/generatePdf",
    },
    {
      name: "ABIBL-CAR-BIKE",
      url: "https://apiabibl-carbike.fynity.in/api/generatePdf",
    },
    {
      name: "ABIBL-PREPROD",
      url: "https://apiabibl-preprod-carbike.fynity.in/api/generatePdf",
    },
    {
      name: "ACE",
      url: "https://uatmotorapis.aceinsurance.com/api/generatePdf",
    },
    {
      name: "ACE-PROD",
      url: "https://motorapis.aceinsurance.com/api/generatePdf",
    },
    {
      name: "PINC",
      url: "https://uat-apimotor.pincinsurance.com/api/generatePdf",
    },
    {
      name: "PINC-PROD",
      url: "https://apimotor.pincinsurance.com/api/generatePdf",
    },
    {
      name: "RB",
      url: "https://apiuatmotor.rbstaging.in/api/generatePdf",
    },
    {
      name: "RB-PREPROD",
      url: "https://apipreprodmotor.rbstaging.in/api/generatePdf",
    },
    {
      name: "RB-PROD",
      url: "https://apimotor.renewbuy.com/api/generatePdf",
    },
    {
      name: "SRIYAH",
      url: "https://apicarbike-sriyah-uat.fynity.in/api/generatePdf",
    },
    {
      name: "SRIYAH-PROD",
      url: "https://apimotor.nammacover.com/api/generatePdf",
    },
    {
      name: "GRAM-COVER",
      url: "https://apigramcover-carbike.fynity.in/api/generatePdf",
    },
    {
      name: "GRAM-COVER-PREPROD",
      url: "https://apigramcover-carbike-preprod.fynity.in/api/generatePdf",
    },
    {
      name: "GRAM-COVER-PROD",
      url: "https://apicarbike.gramcover.com/api/generatePdf",
    },
    {
      name: "SPA",
      url: "https://apiuatmotor.insuringall.com/api/generatePdf",
    },
    {
      name: "SPA-PREPROD",
      url: "https://apipreprodmotor.insuringall.com/api/generatePdf",
    },
    {
      name: "SPA-PROD",
      url: "https://apimotor.insuringall.com/api/generatePdf",
    },
    {
      name: "EPOCH",
      url: "https://uatapimotor.policylo.com/api/generatePdf",
    },
    {
      name: "EPOCH-PROD",
      url: "https://apimotor.policylo.com/api/generatePdf",
    },
    {
      name: "BIMA",
      url: "https://uatapimotor.bimaplanner.com/api/generatePdf",
    },
    {
      name: "BIMA-PROD",
      url: "https://apimotor.bimaplanner.com/api/generatePdf",
    },
    {
      name: "BAJAJ",
      url: "https://uatcar.bajajcapitalinsurance.com/api",
    },
    {
      name: "BAJAJ-PREPROD",
      url: "https://stageapimotor.bajajcapitalinsurance.com/api/generatePdf",
    },
    {
      name: "BAJAJ-PROD",
      url: "https://apimotor.bajajcapitalinsurance.com/api/generatePdf",
    },
    {
      name: "UIB",
      url: "https://uatapicar.uibinsure.com/api/generatePdf",
    },
    {
      name: "UIB-PROD",
      url: "https://apimotor.uibinsure.com/api/generatePdf",
    },
    {
      name: "SRIDHAR",
      url: "https://uatapimotor.sibinsure.com/api/generatePdf",
    },
    {
      name: "SHREE",
      url: "https://uatapimotor.shreeinsure.com/api/generatePdf",
    },
    {
      name: "ANALAH",
      url: "https://uatapimotor.analahinsurance.com/api/generatePdf",
    },
    {
      name: "POLICYERA",
      url: "https://uatapimotor.policyera.com/api/generatePdf",
    },
    {
      name: "POLICYERA-PROD",
      url: " https://apimotor.policyera.com/api/generatePdf",
    },
    {
      name: "TATA-UAT",
      url: "https://apiuatmotor.lifekaplan.com/api/generatePdf",
    },
    {
      name: "TATA",
      url: "https://apimotor.lifekaplan.com/api/generatePdf",
    },
  ];

  const lessthan767 = useMediaPredicate("(max-width: 767px)");

  return (
    <div>
      <div>
        <div
          className="row"
          style={{ marginTop: "100px", padding: "0px 25px" }}
        >
          <div
            className="col-md-6 mx-auto"
            style={{
              boxShadow: "1px 4px 26px 7px #dcdcdc",
              padding: "20px 30px",
              borderRadius: "8px",
            }}
          >
            <h4
              className="text-center"
              style={{ fontSize: lessthan767 && "1rem" }}
            >
              Policy Number Generator
            </h4>
            <form onSubmit={handleSubmit(submitHandler)}>
              {/* { process.env.REACT_APP_BROKER === "FYNTUNE" && (
                                <>
                                <FormGroupTag>Base Url</FormGroupTag>
                                <Form.Control
                                as="select"
                                size="md"
                                ref={register}
                                name="baseUrl"
                                style={{ cursor: "pointer" }}
                                > */}
              {/* <option selected={true} value={"@"}>
                                    Select
                                </option> */}
              {/* { BaseUrls.map(item => (
                                    <option value={item.url} selected={item.name === process.env.REACT_APP_BROKER} style={{ cursor: 'pointer' }}>
                                        { item.name }
                                    </option>
                                )) }
                                </Form.Control>
                                </>
                            ) }     */}
              <FormGroupTag>Enquiry Id</FormGroupTag>
              <Form.Control
                ref={register}
                errors={errors.selection}
                name="selection"
                type="Number"
                // maxLength="50"
                // minlength="2"
                placeholder="Enter Enquiry Id"
                size="md"
                onChange={() => dispatch(clear())}
              />
              {!!errors.selection && (
                <ErrorMsg fontSize={"12px"}>
                  {errors.selection.message}
                </ErrorMsg>
              )}
              {error && <ErrorMsg fontSize={"12px"}>{error}</ErrorMsg>}
              <FormGroupTag>Policy Number</FormGroupTag>
              <Form.Control
                // ref={register}
                // errors={errors.selection}
                // name="selection"
                type="text"
                readOnly={true}
                // maxLength="50"
                // minlength="2"
                placeholder="Policy Number"
                size="md"
                defaultValue={submit?.policy_number}
              />
              {loading && <Loader />}
              <div className="mt-3" style={{ textAlign: "right" }}>
                <DownloadButton
                  className="reset_btn"
                  // submit={submit}
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  // disabled={!submit?.pdf_link}
                  onClick={handleReset}
                >
                  Reset
                </DownloadButton>
                {submit?.pdf_link ? (
                  <DownloadButton
                    submit={submit}
                    style={{
                      cursor: submit?.pdf_link ? "pointer" : "not-allowed",
                    }}
                    disabled={!submit?.pdf_link}
                    onClick={handleClick}
                  >
                    Download
                  </DownloadButton>
                ) : (
                  <DownloadButton
                    type="submit"
                    className="submit_button"
                    submit={submit}
                    style={{ cursor: selection ? "pointer" : "not-allowed" }}
                    disabled={!selection}
                    // onClick={submitHandler}
                  >
                    Submit
                  </DownloadButton>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratePdf;

const FormGroupTag = styled(Form.Label)`
  font-size: 12px;
  font-weight: normal;
`;

const DownloadButton = styled.button`
  padding: 5px 20px;
  border-radius: 8px;
  background: ${({ submit }) => (submit?.pdf_link ? "rgb(189, 212, 0)" : "")};
  //    border: 1px solid green;
`;

const TopDiv = styled.div``;
