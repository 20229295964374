import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useMediaPredicate } from "react-media-hook";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getIRDAI, cinNO, BrokerCategory, BrokerName } from "components";
export const Footer = () => {
  const todayDate = new Date();
  const presentYear = todayDate.getFullYear();
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const { theme_conf } = useSelector((state) => state.home);

  const FooterFn = () => {
    if (process.env.REACT_APP_BROKER === "UNILIGHT") {
      return (
        <Row>
          <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
            {/* <div className="footerInline"><span>UNILIGHT </span>Insurance Brokers Limited</div> */}
            <div className={lessthan767 ? "footerInline" : "footerInline1"}>
              <span>IRDA Licence No. </span>
              {theme_conf?.broker_config?.irdanumber || getIRDAI()}
            </div>
            <div className={lessthan767 ? "footerInline" : "footerInline1"}>
              <span>Code No. </span>DB 314/05
            </div>
            <div className={lessthan767 ? "footerInline" : "footerInline2"}>
              <span>Insurance is a subject matter of solicitation.</span>
            </div>
          </Col>
          <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
            <text style={{ fontWeight: "700" }}>
              {" "}
              Copyright &copy; {presentYear}{" "}
            </text>{" "}
            <span></span>
            {BrokerName()}
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
            <div className="footerInline">
              <span>Category: </span>
              {BrokerCategory()}
            </div>
            <div className="footerInline">
              <span>CIN No. </span>
              {theme_conf?.broker_config?.cinnumber || cinNO()}
            </div>
            <div className="footerInline">
              <span>IRDAI Registration No. </span>
              {theme_conf?.broker_config?.irdanumber || getIRDAI()}
            </div>
          </Col>
          <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
            <text style={{ fontWeight: "700" }}>
              {" "}
              Copyright &copy; {presentYear}{" "}
            </text>{" "}
            <span></span>
            {BrokerName()}
          </Col>
        </Row>
      );

      // case "CP":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Web Aggregator
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U74140DL2015PTC276540
      //         </div>
      //         <div className="footerInline">
      //           <span>License Code No.</span>IRDAI/WBA23/15
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}
      //         </text>{" "}
      //         <span></span>
      //         Comparepolicy.com
      //       </Col>
      //     </Row>
      //   );
      // case "FYNTUNE":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Composite Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U67200MH2003PTC141621
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>CA0682
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}{" "}
      //         </text>{" "}
      //         <span></span>
      //         Fyntune Solutions Pvt. Ltd.
      //       </Col>
      //     </Row>
      //   );
      // case "ABIBL":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Composite Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U67200MH2003PTC141621
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>CA0682
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}{" "}
      //         </text>{" "}
      //         <span></span>
      //         Aditya Birla Pvt. Ltd.
      //       </Col>
      //     </Row>
      //   );
      // case "GRAM":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Composite Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U66000DL2016PTC292037
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>CB 691/17
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}{" "}
      //         </text>{" "}
      //         <span></span>
      //         GramCover Insurance Brokers Pvt. Ltd.
      //       </Col>
      //     </Row>
      //   );
      // case "ACE":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Composite Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U74999DL2001PTC110729
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>CB/246
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}{" "}
      //         </text>{" "}
      //         <span></span>
      //         ACE Insurance Broker Pvt. Ltd.
      //       </Col>
      //     </Row>
      //   );
      // case "SRIYAH":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Direct Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U66010KA2003PTC031462
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>203
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}{" "}
      //         </text>{" "}
      //         <span></span>
      //         Sriyah Insurance Brokers Pvt. Ltd.
      //       </Col>
      //     </Row>
      //   );
      // case "PINC":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Composite Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U67200MH2002PTC137896
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>118
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}{" "}
      //         </text>{" "}
      //         <span></span>
      //         Pioneer Insurance & Reinsurance Brokers Pvt. Ltd.
      //       </Col>
      //     </Row>
      //   );
      // case "EPOCH":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Direct (Life & Non-Life)
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U74900DL2013PTC251990
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>555
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}{" "}
      //         </text>
      //         <span></span>
      //         Epoch Insurance Broker Pvt. Ltd.
      //       </Col>
      //     </Row>
      //   );
      // case "RB":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Direct Broker (Life & General)
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U66030DL2013PTC249265
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>DB 571/14
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}{" "}
      //         </text>{" "}
      //         <span></span>
      //         D2C INSURANCE BROKING PVT. LTD.
      //       </Col>
      //     </Row>
      //   );
      // case "SPA":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Direct Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U67120MH1995PLC088462
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>DB053/03
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}{" "}
      //         </text>
      //         <span></span>
      //         SPA Insurance Broking Services Ltd.
      //       </Col>
      //     </Row>
      //   );
      // case "BIMA":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Direct Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U67120MH1995PLC088462
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>DB/665
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}
      //         </text>{" "}
      //         <span></span>
      //         SS Insurance Broking Services Ltd.
      //       </Col>
      //     </Row>
      //   );
      // case "ANALAH":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Direct Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U66010MH2020PTC337611
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>DB842/20
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}
      //         </text>{" "}
      //         <span></span>
      //         Analah Insurance Broking Private Limited.
      //       </Col>
      //     </Row>
      //   );
      // case "BAJAJ":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Direct Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U67200DL2002PLC117625
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>CB 042/02
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy; {presentYear}
      //         </text>{" "}
      //         <span></span>
      //         Bajaj Capital Insurance Broking Limited.
      //       </Col>
      //     </Row>
      //   );
      // case "UIB":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Composite Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U66030MH2009PTC195794
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>410
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy;
      //           {presentYear}
      //         </text>{" "}
      //         <span></span>
      //         UIB Insurance Brokers (India) Pvt. Ltd.
      //       </Col>
      //     </Row>
      //   );
      // case "SRIDHAR":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Direct Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U67120CH2002PTC025491
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>212
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy;
      //           {presentYear}
      //         </text>{" "}
      //         <span></span>
      //         Sridhar Insurance Brokers (P) Ltd.
      //       </Col>
      //     </Row>
      //   );
      // case "SHREE":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Composite Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U66000MH2014PTC257770
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>CA0625
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy;
      //           {presentYear}
      //         </text>{" "}
      //         <span></span>
      //         LAKSHMISHREE CAPITAL SERVICES PRIVATE LIMITED.
      //       </Col>
      //     </Row>
      //   );
      // case "POLICYERA":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Direct Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>AAX-7485
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>DB 897/2021
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy;
      //           {presentYear}
      //         </text>{" "}
      //         <span></span>
      //         Policy Era Insurance Broking LLP.
      //       </Col>
      //     </Row>
      //   );
      // case "TATA":
      //   return (
      //     <Row>
      //       <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //         <div className="footerInline">
      //           <span>Category: </span>Composite Broker
      //         </div>
      //         <div className="footerInline">
      //           <span>CIN No. </span>U50300MH1997PLC149349
      //         </div>
      //         <div className="footerInline">
      //           <span>IRDAI Registration No. </span>375
      //         </div>
      //       </Col>
      //       <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //         <text style={{ fontWeight: "700" }}>
      //           {" "}
      //           Copyright &copy;
      //           {presentYear}
      //         </text>{" "}
      //         <span></span>
      //         Tata Motors Insurance Broking And Advisory Services Limited.
      //       </Col>
      //     </Row>
      //   );
      // default:
      // return (
      //   <Row>
      //     <Col xl={8} lg={8} md={12} sm={12} className="footerBold">
      //       <div className="footerInline">
      //         <span>Category: </span>Composite Broker
      //       </div>
      //       <div className="footerInline">
      //         <span>CIN No. </span>U67200MH2003PTC141621
      //       </div>
      //       <div className="footerInline">
      //         <span>IRDAI Registration No.</span>CA0682
      //       </div>
      //     </Col>
      //     <Col xl={4} lg={4} md={12} sm={12} className="footerCopy">
      //       <text style={{ fontWeight: "700" }}>
      //         {" "}
      //         Copyright &copy; {presentYear}
      //       </text>{" "}
      //       <span></span>
      //       Ola Financial Services Pvt. Ltd.
      //     </Col>
      //   </Row>
      // );
    }
  };

  return (
    <>
      <footer className="footer_style">{FooterFn()}</footer>
      <GlobalStyle />
    </>
  );
};

export default Footer;

const GlobalStyle = createGlobalStyle`
  .footer_style{
    background: ${({ theme }) => theme.footer?.background}!important;
  }

`;

export const Layout = styled.div`
  margin-bottom: ${(props) =>
    console.log(props.marginBottom) ? "0px !important" : "100px"};
`;
