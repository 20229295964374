import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FormGroupTag } from "modules/proposal/style.js";
import { CompactCard, Button, ErrorMsg } from "components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import {
  GetIc,
  clear,
  GetFields,
  fields as clearFields,
  icList as clearIcs,
} from "modules/proposal/proposal.slice";
import _ from "lodash";
import { FieldCard } from "./field-card";

/*----------------Validation Schema---------------------*/
const yupValidate = yup.object({
  ...(process.env.REACT_APP_BROKER === "FYNTUNE" && {
    broker: yup.string().required("Broker is required"),
  }),
  company_alias: yup
    .string()
    .required("IC is Required")
    .matches(/[^@]/, "IC is Required"),
  section: yup
    .string()
    .required("section is Required")
    .matches(/[^@]/, "section is Required"),
  owner_type: yup
    .string()
    .required("Owner Type is Required")
    .matches(/[^@]/, "Owner Type is Required"),
});
/*----------x------Validation Schema----------x-----------*/

export const FieldConfig = () => {
  const { handleSubmit, register, errors, watch } = useForm({
    //   defaultValues: {},
    resolver: yupResolver(yupValidate),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const dispatch = useDispatch();
  const { error, icList, success, fields, ckycFields } = useSelector(
    (state) => state.proposal
  );
  console.log("watchpoi3", ckycFields);
  //Broker
  const SelectedBroker = watch("broker");

  //load all company_alias
  useEffect(() => {
    //clearing previous data
    dispatch(clearIcs([]));
    dispatch(GetIc(SelectedBroker));
  }, [SelectedBroker]);

  //error
  useEffect(() => {
    if (error) {
      swal("Error", error, "error");
    }
    return () => {
      dispatch(clear());
    };
  }, [error]);

  //success
  useEffect(() => {
    if (success) {
      swal("Success", "Fields updated", "success");
    }
    return () => {
      dispatch(clear());
    };
  }, [success]);

  //onChange - clearing fields
  const IC = watch("company_alias");
  const section = watch("section");
  const owner_type = watch("owner_type");
  useEffect(() => {
    dispatch(clearFields(null));
  }, [owner_type, section, IC, SelectedBroker]);

  const onSubmit = (data) => {
    dispatch(GetFields(data, SelectedBroker));
  };

  const Brokers = [
    { name: "Fyntune", url: "https://apimotor.fynity.in/api" },
    {
      name: "ABIBL UAT",
      url: "https://motor_api_uat.adityabirlainsurancebrokers.com/api",
    },
    {
      name: "ABIBL Production",
      url: "https://motor_api.adityabirlainsurancebrokers.com/api",
    },
    {
      name: "BIMA-Planner UAT",
      url: "https://uatapimotor.bimaplanner.com/api",
    },
    {
      name: "BIMA-Planner Production",
      url: "https://apimotor.bimaplanner.com/api",
    },
    { name: "TMIBASL UAT", url: "https://apiuatmotor.lifekaplan.com/api" },
    {
      name: "TMIBASL Pre-Prod",
      url: "https://preprodapimotor.lifekaplan.com/api",
    },
    { name: "TMIBASL Production", url: "https://apimotor.lifekaplan.com/api" },
    { name: "UIB UAT", url: "https://uatapicar.uibinsure.com/api" },
    { name: "UIB Production", url: "https://apimotor.uibinsure.com/api" },
    { name: "ANALAH UAT", url: "https://uatapimotor.analahinsurance.com/api" },
    { name: "ANALAH Prod", url: "https://apimotor.analahinsurance.com/api" },
    { name: "Policy-Era UAT", url: "https://uatapimotor.policyera.com/api" },
    {
      name: "Policy-Era Production",
      url: "https://apimotor.policyera.com/api",
    },
    { name: "Sridhar UAT", url: "https://uatapimotor.sibinsure.com/api" },
    { name: "Sridhar Production", url: "https://apimotor.sibinsure.com/api" },
    { name: "Shree UAT", url: "https://uatapimotor.shreeinsure.com/api" },
    { name: "Shree Production", url: "https://apimotor.shreeinsure.com/api" },
    {
      name: "Bajaj UAT",
      url: "https://uatapimotor.bajajcapitalinsurance.com/api",
    },
    {
      name: "Bajaj Pre-Prod",
      url: "https://stageapimotor.bajajcapitalinsurance.com/api",
    },
    {
      name: "Bajaj Production",
      url: "https://apimotor.bajajcapitalinsurance.com/api",
    },
    { name: "EPOCH UAT", url: "https://uatapimotor.policylo.com/api" },
    { name: "EPOCH Production", url: "https://apimotor.policylo.com/api" },
    { name: "SPA UAT", url: "https://apiuatmotor.insuringall.com/api" },
    {
      name: "SPA Pre-Prod",
      url: "https://apipreprodmotor.insuringall.com/api",
    },
    { name: "SPA Production", url: "https://apimotor.insuringall.com/api" },
    { name: "RB UAT", url: "https://apiuatmotor.rbstaging.in/api" },
    { name: "RB Pre-Prod", url: "https://apipreprodmotor.rbstaging.in/api" },
    { name: "RB Production", url: "https://apimotor.renewbuy.com/api" },
    { name: "PINC UAT", url: "https://uat-apimotor.pincinsurance.com/api" },
    { name: "PINC Production", url: "https://apimotor.pincinsurance.com/api" },
    { name: "ACE UAT", url: "https://apicar-aceinsurance.fynity.in/api" },
    { name: "ACE Production", url: "https://motorapis.aceinsurance.com/api" },
    {
      name: "Nammacover UAT",
      url: "https://apicarbike-sriyah-uat.fynity.in/api",
    },
    {
      name: "Nammacover Production",
      url: "https://apimotor.nammacover.com/api",
    },
    {
      name: "Gramcover UAT",
      url: "https://apigramcover-carbike.fynity.in/api",
    },
    {
      name: "Gramcover Pre-Prod",
      url: "https://apigramcover-carbike-preprod.fynity.in/api",
    },
    {
      name: "Gramcover Production",
      url: "https://apicarbike.gramcover.com/api",
    },
    { name: "OLA UAT", url: "https://api-ola-uat.fynity.in/api" },
    { name: "OLA Pre-Prod", url: "https://api-ola-preprod.fynity.in/api" },
    { name: "OLA Production", url: "https://olaapi.fynity.in/api" },
    { name: "UNILIGHT UAT", url: "https://api-unilight-uat.fynity.in/api" },
    {
      name: "UNILIGHT Pre-Prod",
      url: "https://api-unilight-preprod.fynity.in/api",
    },
    { name: "UNILIGHT Production", url: "https://apipcv.policyongo.com/api" },
  ];

  return (
    <>
      <CompactCard
        title={`${
          process.env.REACT_APP_BROKER === "FYNTUNE" ? "Master " : ""
        }Proposal Field Configurator`}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row style={{ marginTop: "-35px" }}>
            {process.env.REACT_APP_BROKER === "FYNTUNE" && (
              <Col xs="12" sm="12" md="6" lg="4" xl="4">
                <div className="py-2 fname">
                  <FormGroupTag>Broker</FormGroupTag>
                  <Form.Control
                    autoComplete="none"
                    as="select"
                    size="sm"
                    ref={register}
                    name={`broker`}
                    style={{ cursor: "pointer" }}
                  >
                    {Brokers.map(({ url, name }) => (
                      <option
                        value={url}
                        selected={process.env.REACT_APP_BROKER === url}
                      >
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                  {!!errors?.broker && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.broker?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
            )}
            <Col xs="12" sm="12" md="6" lg="4" xl="4">
              <div className="py-2 fname">
                <FormGroupTag>Insurance Company</FormGroupTag>
                <Form.Control
                  autoComplete="none"
                  as="select"
                  size="sm"
                  ref={register}
                  name={`company_alias`}
                  style={{ cursor: "pointer" }}
                >
                  <option value={"@"}>Select</option>
                  <option value={"all"}>All</option>
                  {icList.map((el, index) => (
                    <option value={el}>
                      {_.capitalize(el.replace(/_/gi, " "))}
                    </option>
                  ))}
                </Form.Control>
                {!!errors?.company_alias && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.company_alias?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="4">
              <div className="py-2 fname">
                <FormGroupTag>Section</FormGroupTag>
                <Form.Control
                  autoComplete="none"
                  as="select"
                  size="sm"
                  ref={register}
                  name={`section`}
                  style={{ cursor: "pointer" }}
                >
                  <option value={"all"}>All</option>
                  <option value={"car"}>Car</option>
                  <option value={"bike"}>Bike</option>
                  <option value={"cv"}>Cv</option>
                </Form.Control>
                {!!errors?.section && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.section?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="4" xl="4">
              <div className="py-2 fname">
                <FormGroupTag>Owner Type</FormGroupTag>
                <Form.Control
                  autoComplete="none"
                  as="select"
                  size="sm"
                  ref={register}
                  name={`owner_type`}
                  style={{ cursor: "pointer" }}
                >
                  <option value={"I"}>Individual</option>
                  <option value={"C"}>Company</option>
                </Form.Control>
                {!!errors?.owner_type && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.owner_type?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            <Col
              sm={12}
              lg={12}
              md={12}
              xl={12}
              className="d-flex justify-content-end mt-3 mx-auto"
            >
              <Button
                type="submit"
                buttonStyle="outline-solid"
                className=""
                hex1={"#4ca729"}
                hex2={"#4ca729"}
                borderRadius="5px"
                color="white"
              >
                <text
                  style={{
                    fontSize: "15px",
                    padding: "-20px",
                    margin: "-20px -5px -20px -5px",
                    fontWeight: "400",
                  }}
                >
                  Get Fields
                </text>
              </Button>
            </Col>
          </Row>
        </Form>
      </CompactCard>
      {fields && (
        <FieldCard
          ic={IC}
          section={section}
          owner_type={owner_type}
          fields={fields}
          ckycFields={ckycFields}
          Broker={SelectedBroker}
        />
      )}
    </>
  );
};
