import React, { useState, useEffect, useMemo } from "react";
import styled, { createGlobalStyle } from "styled-components";
import FilePicker from "./filePicker";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Checkbox from "components/inputs/checkbox/checkbox";
import { radioClasses } from "@mui/material";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import swal from "sweetalert";
import {
  CompactCard,
  ContactFn,
  brokerEmailFunction,
  getIRDAI,
  cinNO,
  DefaultGSTCondition,
  DefaultCPACondition,
} from "components";

import { ThemeConfPost, ThemeConf } from "modules/Home/home.slice";

export const OnboardingConfig = () => {
  const [ckycMandate, setCkycMandate] = useState(false);
  const dispatch = useDispatch();
  const { temp_data, theme_conf } = useSelector((state) => state.home);

  //   const [brokerValue, setBrokerValue] = useState();
  const yupValidate = yup.object({
    ...(process.env.REACT_APP_BROKER === "FYNTUNE" && {
      broker: yup.string(),
    }),
    // logo: yup.string().required("Logo is Required"),
    irdanumber: yup.string().required("IRDA number is Required"),
    // redirection: yup.string().required("Redirection Link is Required"),
    ncbconfig: yup.string().required("NCB Config is Required"),
    gst: yup.string().required("This field is Required"),
    gst_style: yup.string().required("This field is Required"),
    cpa: yup.string().required("This field is Required"),
    lead_page_title: yup.string().nullable(),
    mobile_lead_page_title: yup.string().nullable(),
    time_out: yup
      .string()
      .nullable()
      .transform((v, o) => (o === "" ? null : v))
      .matches(/^([\W\d]{1,2})$/, "Enter only 2 digit number"),
    phone: yup
      .string()
      .nullable()
      .transform((v, o) => (o === "" ? null : v))
      .trim()
      .matches(
        /^(([\+ || \d]{1,3})?[-||\s ]?)?([\d]{5}[-||\s ]?[\d]{5})$/,
        "Invalid mobile number"
      )
      .min(10, "Mobile No. should be 10 digits")
      .max(15, "Mobile No. should be less than 15 digits"),
    email: yup
      .string()
      .email("Please enter valid email id")
      .min(2, "Please enter valid email id")
      .max(50, "Must be less than 50 chars")
      .required("Email id is required")
      .trim(),
    cinnumber: yup.string().required("CIN number is Required"),
    gst_color: yup.string().nullable(),
    gst_color_no: yup.string().nullable(),
    gst_text_color: yup.string().nullable(),
    // ...(ckycMandate && {
    mandate_h: yup.string().nullable(),
    mandate_p1: yup.string().nullable(),
    mandate_p2: yup.string().nullable(),
    // }),
  });

  const { handleSubmit, register, watch, setValue, errors, reset } = useForm({
    defaultValues: theme_conf?.broker_config ? theme_conf?.broker_config : {},
    resolver: yupResolver(yupValidate),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const Brokerss = [
    {
      name: "Fyntune",
      url: "https://apimotor.fynity.in/api",
      broker: "FYNTUNE",
    },
    {
      name: "ABIBL UAT",
      url: "https://motor_api_uat.adityabirlainsurancebrokers.com/api",
      broker: "ABIBL",
    },
    {
      name: "ABIBL Production",
      url: "https://motor_api.adityabirlainsurancebrokers.com/api",
      broker: "ABIBL",
    },
    {
      name: "BIMA-Planner UAT",
      url: "https://uatapimotor.bimaplanner.com/api",
      broker: "BIMA",
    },
    {
      name: "BIMA-Planner Production",
      url: "https://apimotor.bimaplanner.com/api",
      broker: "BIMA",
    },
    {
      name: "TMIBASL UAT",
      url: "https://apiuatmotor.lifekaplan.com/api",
      broker: "TATA",
    },
    {
      name: "TMIBASL Pre-Prod",
      url: "https://preprodapimotor.lifekaplan.com/api",
    },
    {
      name: "TMIBASL Production",
      url: "https://apimotor.lifekaplan.com/api",
      broker: "TATA",
    },
    {
      name: "UIB UAT",
      url: "https://uatapicar.uibinsure.com/api",
      broker: "UIB",
    },
    {
      name: "UIB Production",
      url: "https://apimotor.uibinsure.com/api",
      broker: "UIB",
    },
    {
      name: "ANALAH UAT",
      url: "https://uatapimotor.analahinsurance.com/api",
      broker: "ANALAH",
    },
    {
      name: "Policy-Era UAT",
      url: "https://uatapimotor.policyera.com/api",
      broker: "POLICYERA",
    },
    {
      name: "Policy-Era Production",
      url: "https://apimotor.policyera.com/api",
      broker: "POLICYERA",
    },
    {
      name: "Sridhar UAT",
      url: "https://uatapimotor.sibinsure.com/api",
      broker: "SRIDHAR",
    },
    {
      name: "Sridhar Production",
      url: "https://apimotor.sibinsure.com/api",
      broker: "SRIDHAR",
    },
    {
      name: "Shree UAT",
      url: "https://uatapimotor.shreeinsure.com/api",
      broker: "SHREE",
    },
    {
      name: "Shree Production",
      url: "https://apimotor.shreeinsure.com/api",
      broker: "SHREE",
    },
    {
      name: "Bajaj UAT",
      url: "https://uatapimotor.bajajcapitalinsurance.com/api",
      broker: "BAJAJ",
    },
    {
      name: "Bajaj Pre-Prod",
      url: "https://stageapimotor.bajajcapitalinsurance.com/api",
      broker: "BAJAJ",
    },
    {
      name: "Bajaj Production",
      url: "https://apimotor.bajajcapitalinsurance.com/api",
      broker: "BAJAJ",
    },
    {
      name: "EPOCH UAT",
      url: "https://uatapimotor.policylo.com/api",
      broker: "EPOCH",
    },
    {
      name: "EPOCH Production",
      url: "https://apimotor.policylo.com/api",
      broker: "EPOCH",
    },
    {
      name: "SPA UAT",
      url: "https://apiuatmotor.insuringall.com/api",
      broker: "SPA",
    },
    {
      name: "SPA Pre-Prod",
      url: "https://apipreprodmotor.insuringall.com/api",
      broker: "SPA",
    },
    {
      name: "SPA Production",
      url: "https://apimotor.insuringall.com/api",
      broker: "SPA",
    },
    {
      name: "RB UAT",
      url: "https://apiuatmotor.rbstaging.in/api",
      broker: "RB",
    },
    {
      name: "RB Pre-Prod",
      url: "https://apipreprodmotor.rbstaging.in/api",
      broker: "RB",
    },
    {
      name: "RB Production",
      url: "https://apimotor.renewbuy.com/api",
      broker: "RB",
    },
    {
      name: "PINC UAT",
      url: "https://uat-apimotor.pincinsurance.com/api",
      broker: "PINC",
    },
    {
      name: "PINC Production",
      url: "https://apimotor.pincinsurance.com/api",
      broker: "PINC",
    },
    {
      name: "ACE UAT",
      url: "https://apicar-aceinsurance.fynity.in/api",
      broker: "ACE",
    },
    {
      name: "ACE Production",
      url: "https://motorapis.aceinsurance.com/api",
      broker: "ACE",
    },
    {
      name: "Nammacover UAT",
      url: "https://apicarbike-sriyah-uat.fynity.in/api",
      broker: "SRIYAH",
    },
    {
      name: "Nammacover Production",
      url: "https://apimotor.nammacover.com/api",
      broker: "SRIYAH",
    },
    {
      name: "Gramcover UAT",
      url: "https://apigramcover-carbike.fynity.in/api",
      broker: "GRAM",
    },
    {
      name: "Gramcover Pre-Prod",
      url: "https://apigramcover-carbike-preprod.fynity.in/api",
      broker: "GRAM",
    },
    {
      name: "Gramcover Production",
      url: "https://apicarbike.gramcover.com/api",
      broker: "GRAM",
    },
    {
      name: "OLA UAT",
      url: "https://api-ola-uat.fynity.in/api",
      broker: "OLA",
    },
    {
      name: "OLA Pre-Prod",
      url: "https://api-ola-preprod.fynity.in/api",
      broker: "OLA",
    },
    {
      name: "OLA Production",
      url: "https://olaapi.fynity.in/api",
      broker: "OLA",
    },
    {
      name: "UNILIGHT UAT",
      url: "https://api-unilight-uat.fynity.in/api",
      broker: "UNILIGHT",
    },
    {
      name: "UNILIGHT Pre-Prod",
      url: "https://api-unilight-preprod.fynity.in/api",
      broker: "UNILIGHT",
    },
    {
      name: "UNILIGHT Production",
      url: "https://apipcv.policyongo.com/api",
      broker: "UNILIGHT",
    },
  ];
  const madateValue = watch("ckyc_mandate") ? watch("ckyc_mandate") : false;
  useEffect(() => {
    setCkycMandate(madateValue);
  }, [madateValue]);

  const brokerValue = watch("broker") ? watch("broker") : false;
  const standardBN = brokerValue
    ? _.compact(
        Brokerss?.map((item) =>
          item.name === brokerValue ? item.broker : false
        )
      )[0]
    : "";

  const handleReset = () => {
    const baseUrl = _.compact(
      Brokerss?.map((item) => (item.name === brokerValue ? item.url : false))
    )[0];
    dispatch(ThemeConfPost({ broker_config: JSON.stringify({}) }, baseUrl));
  };
  const handleBroker = () => {
    const baseUrl = _.compact(
      Brokerss?.map((item) => (item.name === brokerValue ? item.url : false))
    )[0];
    dispatch(ThemeConf(baseUrl));
    swal(`Fetching  ${brokerValue} Detailes !...`);
  };

  useEffect(() => {
    if (
      brokerValue &&
      theme_conf?.broker_config?.broker &&
      brokerValue === theme_conf?.broker_config?.broker
    ) {
      setValue("broker", theme_conf?.broker_config?.broker);
      setValue("irdanumber", theme_conf?.broker_config?.irdanumber);
      setValue("cinnumber", theme_conf?.broker_config?.cinnumber);
      setValue("email", theme_conf?.broker_config?.email);
      setValue("phone", theme_conf?.broker_config?.phone);
      setValue("gst", theme_conf?.broker_config?.gst);
      setValue("lead_otp", theme_conf?.broker_config?.lead_otp);
      setValue(
        "gst_style",
        theme_conf?.broker_config?.gst_style === "fromTheme"
          ? "fromTheme"
          : "notFromTheme"
      );
      setValue("cpa", theme_conf?.broker_config?.cpa);
      setValue("ncbconfig", theme_conf?.broker_config?.ncbconfig);
      setValue("lead_page_title", theme_conf?.broker_config?.lead_page_title);
      setValue(
        "mobile_lead_page_title",
        theme_conf?.broker_config?.mobile_lead_page_title
      );
      setValue("lead_otp", theme_conf?.broker_config?.lead_otp);
      setValue("time_out", theme_conf?.broker_config?.time_out);
      setValue("gst_color", theme_conf?.broker_config?.gst_color);
      setValue("gst_color_no", theme_conf?.broker_config?.gst_color_no);
      setValue("gst_text_color", theme_conf?.broker_config?.gst_text_color);
      setValue("renewal", theme_conf?.broker_config?.renewal);
      setValue("ckyc_mandate", theme_conf?.broker_config?.ckyc_mandate);
      if (theme_conf?.broker_config?.ckyc_mandate) {
        setValue("mandate_h", theme_conf?.broker_config?.mandate_h);
        setValue("mandate_p1", theme_conf?.broker_config?.mandate_p1);
        setValue("mandate_p2", theme_conf?.broker_config?.mandate_p2);
      }
      setValue("p_declaration", theme_conf?.broker_config?.p_declaration);
      setValue("fullName", theme_conf?.broker_config?.fullName);
      setValue("mobileNo", theme_conf?.broker_config?.mobileNo);
      setValue("lead_email", theme_conf?.broker_config?.lead_email);
      setValue("journey_block", theme_conf?.broker_config?.journey_block);
    } else if (
      brokerValue &&
      brokerValue !== "@" &&
      (brokerValue !== theme_conf?.broker_config?.broker ||
        !theme_conf?.broker_config?.broker)
    ) {
      setValue("irdanumber", getIRDAI(standardBN));
      setValue("cinnumber", cinNO(standardBN));
      setValue("email", brokerEmailFunction(standardBN));
      setValue("phone", ContactFn(standardBN));
      setValue("gst", DefaultGSTCondition(standardBN));
      setValue("gst_style", "fromTheme");
      setValue("cpa", DefaultCPACondition(standardBN, !temp_data?.odOnly));
      setValue("ncbconfig", "No");
      setValue(
        "lead_page_title",
        "Get the best Vehicle Insurance quotes in 2 minutes."
      );
      setValue(
        "mobile_lead_page_title",
        "Buy vehicle insurance in easy steps. Please fill the details"
      );
      setValue("lead_otp", false);
      setValue("time_out", "");
      setValue("gst_color", "#f815a0");
      setValue("gst_color_no", "#f815a0");
      setValue("gst_text_color", "#ffff");
      setValue("ckyc_mandate", false);
      setValue("fullName", false);
      setValue("mobileNo", false);
      setValue("lead_email", false);
      setValue("renewal", "No");
      setValue("p_declaration", "");
      setValue("journey_block", false);
    } else {
      if (!brokerValue) {
        reset({});
      }
    }
  }, [brokerValue, theme_conf?.broker_config]);

  useEffect(() => {
    if (
      madateValue &&
      brokerValue &&
      (brokerValue !== theme_conf?.broker_config?.broker ||
        !theme_conf?.broker_config?.broker)
    ) {
      setValue(
        "mandate_h",
        "As per IRDA KYC is mandatory for all policies from"
      );
      setValue(
        "mandate_p1",
        `Customer name and DOB should match with
    the document used for CKYC like Pan card, Aadhar, etc`
      );
      setValue(
        "mandate_p2",
        `All documents should be clear and have
    same Customer name and DOB which are uploaded on insurance portal
    for CKYC`
      );
    }
  }, [madateValue, theme_conf?.broker_config]);

  console.log("madate : ", watch("ckyc_mandate"));

  const handleSub = (data) => {
    const baseUrl = _.compact(
      Brokerss?.map((item) => (item.name === brokerValue ? item.url : false))
    )[0];
    dispatch(ThemeConfPost({ broker_config: JSON.stringify(data) }, baseUrl));
    if (data) {
      swal("submited successfully !..");
    }
  };

  return (
    <Form style={{ padding: "0px 20px" }} onSubmit={handleSubmit(handleSub)}>
      <CompactCard
        title={
          brokerValue &&
          brokerValue !== "@" &&
          (brokerValue !== theme_conf?.broker_config?.broker ||
            !theme_conf?.broker_config?.broker)
            ? "Offline Portal Details"
            : "Onboarding Config"
        }
      >
        <Row>
          {
            <>
              {" "}
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                <div className="py-2 fname">
                  <label>Broker Name</label>
                  <Form.Control
                    as="select"
                    autoComplete="none"
                    size="sm"
                    ref={register}
                    name={`broker`}
                  >
                    <option defaultValue value="">
                      select Broker
                    </option>
                    {Brokerss.map(({ name }) => (
                      <option
                        // key={url}
                        value={name}
                        selected={theme_conf?.broker_config?.broker === name}
                      >
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors?.broker?.message && (
                    <Error>{errors?.broker?.message}</Error>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                <div className="buttonWrap">
                  <ButtonInput
                    type="button"
                    // disabled={process.env.REACT_APP_BROKER !== "FYNTUNE"}
                    onClick={() => {
                      // !_.isEmpty(theme_conf?.broker_config) &&
                      brokerValue && handleReset();
                    }}
                  >
                    Reset
                  </ButtonInput>
                  <ButtonInput
                    type="button"
                    // disabled={process.env.REACT_APP_BROKER !== "FYNTUNE"}
                    onClick={() => brokerValue && handleBroker()}
                  >
                    Fetch
                  </ButtonInput>
                </div>
              </Col>
            </>
          }
        </Row>
        <StyedH3>Footer Details</StyedH3>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>IRDA No.</label>
              <Form.Control
                autoComplete="none"
                type="text"
                size="sm"
                ref={register}
                name={`irdanumber`}
              />
              {errors?.irdanumber?.message && (
                <Error>{errors?.irdanumber?.message}</Error>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>CIN No.</label>
              <Form.Control
                autoComplete="none"
                type="text"
                size="sm"
                ref={register}
                name={`cinnumber`}
              />
              {errors?.cinnumber?.message && (
                <Error>{errors?.cinnumber?.message}</Error>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>Email</label>
              <Form.Control
                autoComplete="none"
                type="text"
                size="sm"
                ref={register}
                name={`email`}
              />
              {errors?.email?.message && (
                <Error>{errors?.email?.message}</Error>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>Phone</label>
              <Form.Control
                autoComplete="none"
                type="tel"
                size="sm"
                ref={register}
                name={`phone`}
              />
              {errors?.phone?.message && (
                <Error>{errors?.phone?.message}</Error>
              )}
            </div>
          </Col>
        </Row>
        <StyedH3>Lead Page</StyedH3>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>Lead Page Title Text</label>
              <Form.Control
                autoComplete="none"
                type="text"
                size="sm"
                ref={register}
                name={`lead_page_title`}
                placeholder="enter Title Text"
              />
              {errors?.lead_page_title?.message && (
                <Error>{errors?.lead_page_title?.message}</Error>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>Mobile Lead Page Title Text</label>
              <Form.Control
                autoComplete="none"
                type="text"
                size="sm"
                ref={register}
                name={`mobile_lead_page_title`}
                placeholder="enter Title Text"
              />
              {errors?.mobile_lead_page_title?.message && (
                <Error>{errors?.mobile_lead_page_title?.message}</Error>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>Want to show Renewal option?</label>
              <Radio style={{ display: "flex" }}>
                <>
                  <input
                    type="radio"
                    name="renewal"
                    id={`renewal-Yes`}
                    ref={register}
                    value={"Yes"}
                    // checked
                  />
                  <label htmlFor={`renewal-Yes`}>Yes</label>
                  <input
                    type="radio"
                    name="renewal"
                    id={`renewal-No`}
                    ref={register}
                    value="No"
                  />
                  <label htmlFor={`renewal-No`}>No</label>
                </>
              </Radio>
              {errors?.renewal?.message && (
                <Error>{errors?.renewal?.message}</Error>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4}>
            <div className="py-2 fname ">
              <label style={{ visibility: "hidden", padding: "0px" }}>
                nothing
              </label>
              <Checkbox
                id={"no_back"}
                register={register}
                fieldName={"Disable lead-page in B2B journey"}
                index={""}
                name={"noBack"}
                tooltipData={""}
                color={"rgb(67 56 202)"}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4}>
            <div className="py-2 fname ">
              <label style={{ visibility: "hidden", padding: "0px" }}>
                nothing
              </label>
              <Checkbox
                id={"fullName"}
                register={register}
                fieldName={"Mandatory lead name."}
                index={""}
                name={"fullName"}
                tooltipData={""}
                color={"rgb(67 56 202)"}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4}>
            <div className="py-2 fname ">
              <label style={{ visibility: "hidden", padding: "0px" }}>
                nothing
              </label>
              <Checkbox
                id={"mobileNo"}
                register={register}
                fieldName={"Mandatory lead contact number."}
                index={""}
                name={"mobileNo"}
                tooltipData={""}
                color={"rgb(67 56 202)"}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4}>
            <div className="py-2 fname ">
              <label style={{ visibility: "hidden", padding: "0px" }}>
                nothing
              </label>
              <Checkbox
                id={"lead_email"}
                register={register}
                fieldName={"Mandatory lead email."}
                index={""}
                name={"lead_email"}
                tooltipData={""}
                color={"rgb(67 56 202)"}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={3}>
            <div className="py-2 fname ">
              <label style={{ visibility: "hidden", padding: "0px" }}>
                nothing
              </label>
              <Checkbox
                id={"lead_otp"}
                register={register}
                fieldName={"Enable Lead Page Otp"}
                index={""}
                name={"lead_otp"}
                tooltipData={""}
                color={"rgb(67 56 202)"}
              />
            </div>
          </Col>
        </Row>
        <StyedH3>Quotes Page</StyedH3>
        <Row>
          {/* <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>redirection link</label>
              <Form.Control
                autoComplete="none"
                type="text"
                size="sm"
                ref={register}
                name={`redirection`}
              />
              {errors?.redirection?.message && (
                <Error>{errors?.redirection?.message}</Error>
              )}
            </div>
          </Col>  */}
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>Inactivity timeout</label>
              <Form.Control
                autoComplete="none"
                type="text"
                size="sm"
                ref={register}
                name={`time_out`}
                placeholder="Enter Time in Minutes"
              />
              {errors?.time_out?.message && (
                <Error>{errors?.time_out?.message}</Error>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>GST default selected</label>
              <Radio style={{ display: "flex" }}>
                <>
                  <input
                    type="radio"
                    name="gst"
                    id={`gst-Yes`}
                    ref={register}
                    value={"Yes"}
                    // checked
                  />
                  <label htmlFor={`gst-Yes`}>Yes</label>
                  <input
                    type="radio"
                    name="gst"
                    id={`gst-No`}
                    ref={register}
                    value="No"
                  />
                  <label htmlFor={`gst-No`}>No</label>
                </>
              </Radio>
              {errors?.gst?.message && <Error>{errors?.gst?.message}</Error>}
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>PYP insurer prompt on load</label>
              <Radio style={{ display: "flex" }}>
                <>
                  <input
                    type="radio"
                    name="ncbconfig"
                    id={`ncbconfig-Yes`}
                    ref={register}
                    value={"Yes"}
                    // checked
                  />
                  <label htmlFor={`ncbconfig-Yes`}>Yes</label>
                  <input
                    type="radio"
                    name="ncbconfig"
                    id={`ncbconfig-No`}
                    ref={register}
                    value="No"
                  />
                  <label htmlFor={`ncbconfig-No`}>No</label>
                </>
              </Radio>
              {errors?.ncbconfig?.message && (
                <Error>{errors?.ncbconfig?.message}</Error>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <label>CPA default selected</label>
              <Radio style={{ display: "flex" }}>
                <>
                  <input
                    type="radio"
                    name="cpa"
                    id={`cpa-Yes`}
                    ref={register}
                    value={"Yes"}
                    // checked
                  />
                  <label htmlFor={`cpa-Yes`}>Yes</label>
                  <input
                    type="radio"
                    name="cpa"
                    id={`cpa-No`}
                    ref={register}
                    value="No"
                  />
                  <label htmlFor={`cpa-No`}>No</label>
                </>
              </Radio>
              {errors?.cpa?.message && (
                <Error fontSize={"12px"}>{errors?.cpa?.message}</Error>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={watch("gst_style") === "notFromTheme" ? 12 : 6}
            xl={watch("gst_style") === "notFromTheme" ? 12 : 4}
            className=""
          >
            <div className="py-2">
              <label>GST Toggle Style </label>
              <Radio style={{ display: "flex" }}>
                <>
                  <input
                    type="radio"
                    name="gst_style"
                    id={`gstStyleYes`}
                    ref={register}
                    value={"fromTheme"}
                    // checked
                  />
                  <label htmlFor={`gstStyleYes`}>From Theme</label>
                  <input
                    type="radio"
                    name="gst_style"
                    id={`gstStyleNo`}
                    ref={register}
                    value="notFromTheme"
                  />
                  <label htmlFor={`gstStyleNo`}>Select Other</label>
                </>
              </Radio>
              {errors?.gst_style?.message && (
                <Error fontSize={"12px"}>{errors?.gst_style?.message}</Error>
              )}
            </div>
          </Col>
          {watch("gst_style") === "notFromTheme" ? (
            <>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <label>GST Text Color</label>
                  <Form.Control
                    style={{ padding: "0px" }}
                    type="color"
                    name="gst_text_color"
                    id={`gst-color`}
                    size="sm"
                    ref={register}
                    placeholder="enter color in hexa-decimal"
                    // onInput={(e) => (e.target.value = `#${e.target.value}`)}
                  />

                  {errors?.gst_text_color?.message && (
                    <Error>{errors?.gst_text_color?.message}</Error>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <label>GST Color (YES)</label>
                  <Form.Control
                    style={{ padding: "0px" }}
                    type="color"
                    name="gst_color"
                    id={`gst-color`}
                    size="sm"
                    ref={register}
                    placeholder="enter color in hexa-decimal"
                    // onInput={(e) => (e.target.value = `#${e.target.value}`)}
                  />

                  {errors?.gst_color?.message && (
                    <Error>{errors?.gst_color?.message}</Error>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <label>GST Color (NO)</label>
                  <Form.Control
                    style={{ padding: "0px" }}
                    type="color"
                    name="gst_color_no"
                    id={`gst-color-no`}
                    size="sm"
                    ref={register}
                    placeholder="enter color in hexa-decimal"
                    // onInput={(e) => (e.target.value = `#${e.target.value}`)}
                  />

                  {errors?.gst_color_no?.message && (
                    <Error>{errors?.gst_color_no?.message}</Error>
                  )}
                </div>
              </Col>
            </>
          ) : (
            <noscript />
          )}
        </Row>
        <StyedH3>Proposal Page</StyedH3>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
            <div className="py-2">
              <label>Proposal Page Declaration Text</label>
              <Form.Control
                autoComplete="none"
                type="text"
                size="sm"
                ref={register}
                as="textarea"
                name={`p_declaration`}
                placeholder="Proposal Declaration Text"
              />
              {errors?.p_declaration?.message && (
                <Error>{errors?.p_declaration?.message}</Error>
              )}
            </div>
          </Col>
        </Row>
        <StyedH3>Popups & Others</StyedH3>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={watch("ckyc_mandate") ? 12 : 6}
            xl={watch("ckyc_mandate") ? 12 : 4}
          >
            <div className="py-2 fname ">
              <Checkbox
                id={"ckyc_mandate"}
                register={register}
                fieldName={"CKYC Mandate Popup?"}
                index={""}
                name={"ckyc_mandate"}
                tooltipData={""}
                color={"rgb(67 56 202)"}
              />
            </div>
          </Col>
          {watch("ckyc_mandate") ? (
            <>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <label>Mandate Popup Heading</label>
                  <Form.Control
                    autoComplete="none"
                    type="text"
                    size="sm"
                    ref={register}
                    name={`mandate_h`}
                    onInput={(e) =>
                      (e.target.value = _.capitalize(e.target.value))
                    }
                    placeholder="Enter Title Text"
                  />
                  {errors?.mandate_h?.message && (
                    <Error>{errors?.mandate_h?.message}</Error>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <label>Mandate Popup First Line</label>
                  <Form.Control
                    autoComplete="none"
                    type="text"
                    size="sm"
                    ref={register}
                    name={`mandate_p1`}
                    onInput={(e) =>
                      (e.target.value = _.capitalize(e.target.value))
                    }
                    placeholder="Enter First Line"
                  />
                  {errors?.mandate_p1?.message && (
                    <Error>{errors?.mandate_p1?.message}</Error>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <label>Mandate popup Second Line</label>
                  <Form.Control
                    autoComplete="none"
                    type="text"
                    size="sm"
                    ref={register}
                    name={`mandate_p2`}
                    onInput={(e) =>
                      (e.target.value = _.capitalize(e.target.value))
                    }
                    placeholder="Enter Second Line"
                  />
                  {errors?.mandate_p2?.message && (
                    <Error>{errors?.mandate_p2?.message}</Error>
                  )}
                </div>
              </Col>
            </>
          ) : (
            <script />
          )}
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={4}>
            <div className="py-2 fname">
              <Checkbox
                id={"fastlane_error"}
                register={register}
                fieldName={"Customise vahaan error"}
                index={""}
                name={"fastlane_error"}
                tooltipData={""}
                color={"rgb(67 56 202)"}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4}>
            <div className="py-2 fname">
              <Checkbox
                id={"journey_block"}
                register={register}
                fieldName={"Block journey if no record found."}
                index={""}
                name={"journey_block"}
                tooltipData={""}
                color={"rgb(67 56 202)"}
              />
            </div>
          </Col>
          {watch("fastlane_error") && (
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
              <div className="py-2">
                <label>Vahaan Error Message</label>
                <Form.Control
                  autoComplete="none"
                  type="text"
                  size="sm"
                  ref={register}
                  as="textarea"
                  name={`fastlane_error_message`}
                  placeholder="Vahaan Error Message"
                />
                {errors?.p_declaration?.message && (
                  <Error>{errors?.p_declaration?.message}</Error>
                )}
              </div>
            </Col>
          )}
        </Row>
        <div className="buttonWrap">
          {
            <ButtonInput
              type={"submit"}
              // disabled={process.env.REACT_APP_BROKER !== "FYNTUNE"}
              // onClick={() => }
            >
              Submit
            </ButtonInput>
          }
        </div>
      </CompactCard>
      <GlobalStyle />
    </Form>
  );
};

export const GlobalStyle = createGlobalStyle`
 .buttonWrap{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width:767px) {
    justify-content: center;
  }
 }
 /* input::-webkit-color-swatch-wrapper {
	padding: 0;
    margin:0;
    background:none;
}
input::-webkit-color-swatch {
	border: none;
} */
.row{
  margin-left: 0px;
  margin-right: 0px;
}
`;
export const Main = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 16px;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-weight: 300;
  }
`;
export const Input = styled.input`
  padding: 4px 6px;
`;
export const Error = styled.span`
  display: block;
  margin-top: 4px;
  line-height: 17px;
  font-size: 14px;
  color: #d43d3d;
`;
export const ButtonInput = styled.button`
  margin: 20px;
  padding: 8px 20px;
  color: white;
  border-radius: 5px;
  background: ${({ errors }) => (errors ? "black" : "rgb(67 56 202)")};
`;

export const Radio = styled.div`
  input[type="radio"] {
    appearance: none;
    display: none;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 30px;
    text-align: center;
    overflow: hidden;
    transition: linear 0.3s;
    cursor: pointer;
    border: 1px solid #999;
    margin-right: 10px;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      margin-right: 0px;
    }
  }

  input[type="radio"]:checked + label {
    background-color: rgb(67 56 202);
    color: white;
    transition: 0.3s;
  }
  input[type="radio"] + label {
    @media screen and (max-width: 767px) {
      width: 50%;
    }
  }
`;
const StyedH3 = styled.h3`
  font-size: 25px;
  font-weight: 700;
  background: rgba(67, 56, 202, 50%);
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffff;
  margin-top: 20px;
`;
