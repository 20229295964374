import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import * as yup from "yup";
import { currencyFormater, Encrypt } from "utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { Textbox, TabWrapper, Tab } from "components";
import Popup from "./Popup";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Col, Form, Row, Button as Btn, Spinner } from "react-bootstrap";
import "./sendQuote.scss";
import {
  ShareQuote,
  Prefill,
  TriggerWhatsapp,
} from "../../modules/Home/home.slice";
import swal from "sweetalert";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import MessageIcon from "@material-ui/icons/Message";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import _ from "lodash";
import {
  EmailPdf,
  setEmailPdf,
  Whatsapp,
  EmailComparePdf,
  setEmailComparePdf,
} from "modules/quotesPage/quote.slice";
import { useMediaPredicate } from "react-media-hook";
import Drawer from "@mui/material/Drawer";
import { useForm } from "react-hook-form";
import { TypeReturn } from "modules/type";
import { MultiEmail } from "./MultiEmail";

const yupValidate = yup.object({
  mobileNo: yup
    .string()
    .matches(/^[6-9]\d{9}$/, "Invalid mobile number")
    .nullable()
    .transform((v, o) => (o === "" ? null : v))
    .min(10, "Mobile No. should be 10 digits")
    .max(10, "Mobile No. should be 10 digits"),
});

const getLogoUrl = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/ola.png`;
    case "UNILIGHT":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/unilight.png`;
    case "CP":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/cp.png`;
    case "FYNTUNE":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/fyntune.png`;
    case "POLICYERA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/policy-era.png`;
    case "ABIBL":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/abiblPdf.jpeg`;
    case "GRAM":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/gc.png`;
    case "ACE":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/ace.png`;
    case "SRIYAH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/sriyah.png`;
    case "PINC":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/pinc.png`;
    case "EPOCH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/epochlogo.png`;
    case "RB":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/rb.png`;
    case "SPA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/insuringall.jpeg`;
    case "BIMA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/bima.png`;
    case "ANALAH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/analah.jpeg`;
    case "BAJAJ":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/bajaj.png`;
    case "UIB":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/uib.png`;
    case "SRIDHAR":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/sridhar.png`;
    case "SHREE":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/shree.png`;
    case "TATA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/tata.gif`;
    default:
      break;
  }
};
export const SendQuotes = ({
  show,
  onClose,
  sendPdf,
  setSendPdf,
  comparePdfData,
  type,
  shareQuotesFromToaster,
  setShareQuotesFromToaster,
  premiumBreakuppdf,
}) => {
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const [msg, setMsg] = useState(false);
  const [whatsappSucess, setWhatsappSucess] = useState(false);
  const [sendAllChannels, setSendAllChannels] = useState(false);
  const [smsSuccess, setSmsSuccess] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const enquiry_id = query.get("enquiry_id");
  const loc = location.pathname ? location.pathname.split("/") : "";

  const { policy } = useSelector((state) => state.payment);
  const { temp_data } = useSelector((state) => state.proposal);
  const { temp_data: userDataHome, gstStatus } = useSelector(
    (state) => state.home
  );
  const {
    finalPremiumlist1,
    shortTerm,
    selectedTab,
    emailPdf,
    customLoad,
    emailComparePdf,
  } = useSelector((state) => state.quotes);

  let lessthan576 = useMediaPredicate("(max-width: 576px)");
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const lessthan600 = useMediaPredicate("(max-height: 600px)");
  const [step, setStep] = useState(2);

  const { handleSubmit, register, errors, watch, setValue } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onBlur",
  });

  const hideWhatsAppCondition =
    process.env.REACT_APP_BROKER !== "TATA" &&
    process.env.REACT_APP_BROKER !== "SRIYAH";

  const [disEmail, setDisEmail] = useState(true);

  const MobileNo = watch("mobileNo");
  const unformattedEmails = watch("multiEmails");
  const EmailsId = unformattedEmails && unformattedEmails.split(",");
  const [selectedItems, setSelectedItems] = useState([]);

  //Prefill Api
  useEffect(() => {
    if (enquiry_id && !_.isEmpty(loc) && loc[2] === "proposal-page")
      dispatch(Prefill({ enquiryId: enquiry_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  const getType = (name, journeyStage) => {
    switch (name) {
      case "quotes":
        return "shareQuotes";
      case "proposal-page":
        return temp_data?.journeyStage?.stage === "Proposal Accepted" ||
          temp_data?.journeyStage?.stage === "Payment Initiated" ||
          temp_data?.journeyStage?.stage === "Inspection Accept" ||
          temp_data?.journeyStage?.stage.toLowerCase() === "payment failed"
          ? "proposalCreated"
          : "shareProposal";
      case "payment-confirmation":
        return "sharePayment";
      case "payment-success":
        return "successPayment";
      case "compare-quote":
        return "comparepdf";
      default:
        return "";
    }
  };

  const [isActive, setActive] = useState("custom");

  const onSubmit = (data, typeCheck, sendAll) => {
    let quoteList = !_.isEmpty(selectedItems)
      ? selectedItems?.map(
          ({
            idv,
            name,
            logo,
            finalPremium,
            finalPremiumNoGst,
            gst,
            productName,
            policyId,
            policyType,
          }) => ({
            name: name,
            idv: idv,
            logo: logo,
            finalPremium: finalPremium,
            finalPremiumNoGst: finalPremiumNoGst,
            premium: finalPremiumNoGst,
            premiumWithGst: finalPremium,
            gst: gst,
            action: `${window.location.href}&productId=${Encrypt(policyId)}${
              selectedTab === "tab2"
                ? `&selectedType=${Encrypt(selectedTab)}`
                : ""
            }${
              shortTerm && selectedTab !== "tab2"
                ? `&selectedTerm=${Encrypt(shortTerm)}`
                : ""
            }`,
            productName: productName,
            policyType: policyType,
          })
        )
      : !_.isEmpty(finalPremiumlist1)
      ? finalPremiumlist1.map((x) => ({
          ...x,
          premium: x?.finalPremiumNoGst,
          premiumWithGst: x?.finalPremium * 1 ? Math.round(x?.finalPremium) : 0,
          action: `${window.location.href}&productId=${Encrypt(x?.policyId)}${
            selectedTab === "tab2"
              ? `&selectedType=${Encrypt(selectedTab)}`
              : ""
          }${
            shortTerm && selectedTab !== "tab2"
              ? `&selectedTerm=${Encrypt(shortTerm)}`
              : ""
          }`,
          productName: x?.productName,
          policyType: x?.policyType,
        }))
      : !_.isEmpty(finalPremiumlist1)
      ? finalPremiumlist1.map((x) => ({
          ...x,
          premium: x?.finalPremiumNoGst,
          premiumWithGst: x?.finalPremium * 1 ? Math.round(x?.finalPremium) : 0,
          action: `${window.location.href}&productId=${Encrypt(x?.policyId)}${
            selectedTab === "tab2"
              ? `&selectedType=${Encrypt(selectedTab)}`
              : ""
          }${
            shortTerm && selectedTab !== "tab2"
              ? `&selectedTerm=${Encrypt(shortTerm)}`
              : ""
          }`,
          policyType: x?.policyType,
        }))
      : [];
    if (!sendPdf && loc[1] !== "payment-success") {
      if (typeCheck !== 2) {
        var sendData = {
          userType: token ? "pos" : "employee",
          firstName:
            userDataHome?.firstName ||
            temp_data?.firstName ||
            userDataHome?.userProposal?.additonalData?.owner?.firstName ||
            "Customer",
          lastName:
            userDataHome?.lastName ||
            temp_data?.lastName ||
            userDataHome?.userProposal?.additonalData?.owner?.lastName ||
            "",
          emailId: EmailsId && !_.isEmpty(EmailsId) ? EmailsId : null,
          mobileNo: MobileNo,
          logo: getLogoUrl(),
          notificationType: sendAll ? "all" : typeCheck === 1 ? "sms" : "email",
          enquiryId: enquiry_id,
          to: `91${MobileNo}`,
          url: window.location.href,
          link:
            temp_data?.journeyStage?.stage === "Proposal Accepted" ||
            temp_data?.journeyStage?.stage === "Payment Initiated"
              ? `${window.location.href}&dropout=${Encrypt(true)}&icr=${Encrypt(
                  true
                )}`
              : window.location.href,
          domain: `http://${window.location.hostname}`,
          type: getType(loc[2]),
          ...(loc[2] === "quotes" && { quotes: quoteList }),
          ...(loc[2] === "quotes" && {
            productName: quoteList[0]?.productName,
          }),
          ...(loc[2] === "payment-confirmation" && {
            productName: temp_data?.selectedQuote?.productName,
          }),
          ...(loc[2] === "proposal-page" && {
            productName: temp_data?.selectedQuote?.productName,
          }),
          gstSelected: gstStatus ? "Y" : "N",
        };
        sendAll && setSendAllChannels(true);
        typeCheck === 1 && setSmsSuccess(true);
        dispatch(ShareQuote(sendData));
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      } else {
        if (MobileNo) {
          if (MobileNo?.length < 10) {
            swal("Info", "Please enter Valid mobile number", "info");
            setValue("mobile", "");
          } else {
            if (
              process.env.REACT_APP_BROKER === "FYNTUNE" ||
              process.env.REACT_APP_BROKER === "GRAM" ||
              process.env.REACT_APP_BROKER === "ABIBL" ||
              process.env.REACT_APP_BROKER === "RB" ||
              process.env.REACT_APP_BROKER === "POLICYERA"
            ) {
              // abibl whatsapp pc opening in new window
              var content = `Hi I have shared you some quotes please check it out \n \n   ${
                temp_data?.journeyStage?.stage === "Proposal Accepted" ||
                temp_data?.journeyStage?.stage === "Payment Initiated"
                  ? `${window.location.href}&dropout=${Encrypt(
                      true
                    )}&icr=${Encrypt(true)}`
                  : window.location.href
              }`;
              if (loc[2] === "proposal-page") {
                content = `Hi I have shared you a proposal form  please check it out \n \n   ${
                  temp_data?.journeyStage?.stage === "Proposal Accepted" ||
                  temp_data?.journeyStage?.stage === "Payment Initiated"
                    ? `${window.location.href}&dropout=${Encrypt(
                        true
                      )}&icr=${Encrypt(true)}`
                    : window.location.href
                }`;
              }
              window.open(
                `https://api.whatsapp.com/send?phone=${`91${MobileNo}`}&text=${encodeURI(
                  content
                )}#`
              );
            } else {
              var data = {
                userType: token ? "pos" : "employee",
                firstName:
                  userDataHome?.firstName ||
                  temp_data?.firstName ||
                  userDataHome?.userProposal?.additonalData?.owner?.firstName ||
                  "Customer",
                lastName:
                  userDataHome?.lastName ||
                  temp_data?.lastName ||
                  userDataHome?.userProposal?.additonalData?.owner?.lastName ||
                  " ",
                to: `91${MobileNo}`,
                type: shareQuotesFromToaster
                  ? "driverDetails"
                  : getType(
                      loc[2],
                      loc[2] === "proposal-page"
                        ? temp_data?.journeyStage?.stage
                        : false
                    ),
                enquiryId: enquiry_id,
                url: window.location.href,

                link:
                  temp_data?.journeyStage?.stage === "Proposal Accepted" ||
                  temp_data?.journeyStage?.stage === "Payment Initiated"
                    ? `${window.location.href}&dropout=${Encrypt(
                        true
                      )}&icr=${Encrypt(true)}`
                    : window.location.href,

                ...(loc[2] === "proposal-page" && {
                  proposalData: temp_data?.userProposal,
                }),

                ...(loc[2] === "quotes" && { quotes: quoteList }),
                gstSelected: gstStatus ? "Y" : "N",
              };
              dispatch(Whatsapp(data));
              setWhatsappSucess(true);
              setMsg(true);
              if (setShareQuotesFromToaster) {
                setShareQuotesFromToaster(false);
              }
            }
          }
        } else {
          swal("Info", "Please enter your mobile number", "info");
        }
      }
    } else if (loc[2] === "compare-quote") {
      if (EmailsId && typeCheck !== 2) {
        dispatch(
          EmailComparePdf({
            userType: token ? "pos" : "employee",
            name:
              (userDataHome?.firstName || temp_data?.firstName || "Customer") +
              " " +
              (userDataHome?.lastName || temp_data?.lastName || " "),
            data: JSON.stringify(comparePdfData),
            email: EmailsId,
            emailId: EmailsId,
            logo: getLogoUrl(),
            enquiryId: enquiry_id,
            subject: "Compare PDF",
            link: window.location.href,
            vehicle_model: userDataHome?.modelName,
            rto: userDataHome?.rtoNumber,
            regNumber: userDataHome?.regNo,
            previos_policy_expiry_date: userDataHome?.expiry || "NEW",
            fuel_type: userDataHome?.fuel,
            productName: comparePdfData?.policy_type,
            reg_date: userDataHome?.regDate,
            ...(typeCheck === 3 && {
              url: window.location.href,
              mobileNo: MobileNo,
              to: `91${MobileNo}`,
              type: "comparepdf",
              notificationType: "all",
            }),
            gstSelected: gstStatus ? "Y" : "N",
          })
        );
        sendAll && setSendAllChannels(true);
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      } else if (MobileNo) {
        dispatch(
          Whatsapp({
            userType: token ? "pos" : "employee",
            firstName:
              userDataHome?.firstName ||
              temp_data?.firstName ||
              userDataHome?.userProposal?.additonalData?.owner?.firstName ||
              "Customer",
            lastName:
              userDataHome?.lastName ||
              temp_data?.lastName ||
              userDataHome?.userProposal?.additonalData?.owner?.lastName ||
              " ",
            to: `91${MobileNo}`,
            enquiryId: enquiry_id,
            url: window.location.href,
            data: JSON.stringify(comparePdfData),
            type: getType(loc[2]),
            link:
              temp_data?.journeyStage?.stage === "Proposal Accepted" ||
              temp_data?.journeyStage?.stage === "Payment Initiated"
                ? `${window.location.href}&dropout=${Encrypt(
                    true
                  )}&icr=${Encrypt(true)}`
                : window.location.href,
            gstSelected: gstStatus ? "Y" : "N",
          })
        );
        sendAll && setSendAllChannels(true);
        setWhatsappSucess(true);
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      }
    } else if (loc[1] === "payment-success") {
      if (
        typeCheck !== 2 &&
        enquiry_id &&
        temp_data?.selectedQuote?.productName &&
        policy?.policyNumber
      ) {
        dispatch(
          ShareQuote({
            enquiryId: enquiry_id,
            notificationType: sendAll
              ? "all"
              : typeCheck === 1
              ? "sms"
              : "email",
            domain: `http://${window.location.hostname}`,
            type: "paymentSuccess",
            email: EmailsId,
            emailId: EmailsId,
            firstName:
              userDataHome?.firstName ||
              temp_data?.firstName ||
              userDataHome?.userProposal?.additonalData?.owner?.firstName ||
              "Customer",
            lastName:
              userDataHome?.lastName ||
              temp_data?.lastName ||
              userDataHome?.userProposal?.additonalData?.owner?.lastName ||
              " ",
            action: window.location.href,
            link: window.location.href,
            productName: temp_data?.selectedQuote?.productName,
            policyNumber: policy?.policyNumber,
            logo: getLogoUrl(),
            downloadUrl: policy?.pdfUrl ? policy?.pdfUrl : window.location.href,
            mobileNo: MobileNo,
            to: `91${MobileNo}`,
            gstSelected: gstStatus ? "Y" : "N",
          })
        );
        sendAll && setSendAllChannels(true);
        typeCheck === 1 && setSmsSuccess(true);
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      } else if (MobileNo && typeCheck === 2) {
        dispatch(
          TriggerWhatsapp({
            enquiryId: enquiry_id,
            domain: `http://${window.location.hostname}`,
            type: "paymentSuccess",
            notificationType: "whatsapp",
            firstName:
              userDataHome?.firstName ||
              temp_data?.firstName ||
              userDataHome?.userProposal?.additonalData?.owner?.firstName ||
              "Customer",
            lastName:
              userDataHome?.lastName ||
              temp_data?.lastName ||
              userDataHome?.userProposal?.additonalData?.owner?.lastName ||
              " ",
            mobileNo: MobileNo,
            to: `91${MobileNo}`,
            url: window.location.href,
            action: window.location.href,
            link: window.location.href,
            logo: getLogoUrl(),
            downloadUrl: policy?.pdfUrl ? policy?.pdfUrl : window.location.href,
            gstSelected: gstStatus ? "Y" : "N",
          })
        );
        setWhatsappSucess(true);
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      }
    } else {
      if (MobileNo && typeCheck === 2) {
        dispatch(
          Whatsapp({
            userType: token ? "pos" : "employee",
            firstName:
              userDataHome?.firstName ||
              temp_data?.firstName ||
              userDataHome?.userProposal?.additonalData?.owner?.firstName ||
              "Customer",
            lastName:
              userDataHome?.lastName ||
              temp_data?.lastName ||
              userDataHome?.userProposal?.additonalData?.owner?.lastName ||
              " ",
            to: `91${MobileNo}`,
            type: premiumBreakuppdf,
            enquiryId: enquiry_id,
            url: window.location.href,
            data: sendPdf,
            link:
              temp_data?.journeyStage?.stage === "Proposal Accepted" ||
              temp_data?.journeyStage?.stage === "Payment Initiated"
                ? `${window.location.href}&dropout=${Encrypt(
                    true
                  )}&icr=${Encrypt(true)}`
                : window.location.href,
            gstSelected: gstStatus ? "Y" : "N",
          })
        );
        sendAll && setSendAllChannels(true);
        setWhatsappSucess(true);
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      } else if (EmailsId) {
        dispatch(
          EmailPdf({
            userType: token ? "pos" : "employee",
            name:
              (userDataHome?.firstName || temp_data?.firstName || "Customer") +
              " " +
              (userDataHome?.lastName || temp_data?.lastName || " "),
            data: sendPdf,
            email: EmailsId,
            emailId: EmailsId,
            logo: getLogoUrl(),
            enquiryId: enquiry_id,
            subject: "Premium Breakup",
            link:
              temp_data?.journeyStage?.stage === "Proposal Accepted" ||
              temp_data?.journeyStage?.stage === "Payment Initiated"
                ? `${window.location.href}&dropout=${Encrypt(
                    true
                  )}&icr=${Encrypt(true)}`
                : window.location.href,
            vehicle_model: userDataHome?.modelName,
            rto: userDataHome?.rtoNumber,
            regNumber: userDataHome?.regNo,
            previos_policy_expiry_date: userDataHome?.expiry || "NEW",
            fuel_type: userDataHome?.fuel,
            ...(loc[2] === "quotes" && { quotes: quoteList }),
            productName: finalPremiumlist1[0]?.productName,
            reg_date: userDataHome?.regDate,
            ...(typeCheck === 3 &&
              sendPdf && {
                to: `91${MobileNo}`,
                type: "premiumBreakuppdf",
                notificationType: "all",
                url: window.location.href,
              }),
            gstSelected: gstStatus ? "Y" : "N",
          })
        );
        sendAll && setSendAllChannels(true);
        setMsg(true);
        setTimeout(() => {
          onClose(false);
          setMsg(false);
        }, 2500);
      }
    }
  };

  //OnEmail Pdf success /Error
  useEffect(() => {
    return () => {
      dispatch(setEmailPdf("emailPdf"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailPdf]);

  // onMailComparePdf
  useEffect(() => {
    return () => {
      dispatch(setEmailComparePdf("emailPdf"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailComparePdf]);

  //---drawer for mobile
  const [drawer, setDrawer] = useState(false);
  useEffect(() => {
    if (lessthan767 && show) {
      setTimeout(() => {
        setDrawer(true);
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  //options for multiselect.
  const options = !_.isEmpty(finalPremiumlist1)
    ? _.map(finalPremiumlist1).map((x) => ({
        name: `${x?.name}`,
        id: x?.name,
        label: `${x?.name}, Premium: ${
          x?.finalPremium * 1 ? Math.round(x?.finalPremium) : 0
        }`,
        value: x?.name,
        idv: x?.idv,
        logo: x?.logo,
        finalPremiumNoGst: x?.finalPremiumNoGst,
        finalPremium: x?.finalPremium,
        gst: x?.gst,
        action: window.location.href,
        productName: x?.productName,
        policyId: x?.policyId,
        policyType: x?.policyType,
      }))
    : [];

  useEffect(() => {
    if (selectedItems?.length > 0) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  }, [selectedItems]);

  const options2 = !_.isEmpty(finalPremiumlist1) ? finalPremiumlist1 : [];

  useEffect(() => {
    (options.length && selectedItems.length) !== 0 &&
    options.length === selectedItems.length
      ? setSelectAll(true)
      : setSelectAll(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  //prefill
  useEffect(() => {
    (userDataHome?.mobileNo ||
      userDataHome?.userProposal?.additonalData?.owner?.mobileNumber ||
      temp_data?.userProposal?.additonalData?.owner?.mobileNumber) &&
      setValue(
        "mobileNo",
        userDataHome?.mobileNo
          ? userDataHome?.mobileNo
          : userDataHome?.userProposal?.additonalData?.owner?.mobileNumber
          ? userDataHome?.userProposal?.additonalData?.owner?.mobileNumber
          : temp_data?.userProposal?.additonalData?.owner?.mobileNumber
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataHome, isActive]);

  const content2 = (
    <MessageContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#4fcc6e"
        width="48px"
        height="48px"
      >
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
      </svg>
      <FlexDiv>
        <LaxmiWrapper>
          <Laxmi
            src={
              TypeReturn(type) !== "bike"
                ? `${
                    process.env.REACT_APP_BASENAME !== "NA"
                      ? `/${process.env.REACT_APP_BASENAME}`
                      : ""
                  }/assets/images/new-car.svg`
                : `${
                    process.env.REACT_APP_BASENAME !== "NA"
                      ? `/${process.env.REACT_APP_BASENAME}`
                      : ""
                  }/assets/images/vehicle/bike3.png`
            }
            alt="Laxmi"
          />
        </LaxmiWrapper>
        <Wrapper>
          <Text>
            Thank you! Your{" "}
            {loc[1] === "payment-success"
              ? "policy has been sent"
              : loc[2] === "proposal-page"
              ? "proposal has been sent"
              : loc[1] === "payment-success"
              ? "policy has been sent"
              : loc[2] === "payment-confirmation"
              ? "payment link has been sent"
              : !sendPdf
              ? "quote(s) have been sent"
              : "pdf has been sent"}
            {whatsappSucess
              ? process.env.REACT_APP_BROKER === "BAJAJ"
                ? " to your WhatsApp successfully."
                : " through Whatsapp successfully."
              : smsSuccess
              ? " through SMS successfully"
              : sendAllChannels
              ? process.env.REACT_APP_BROKER === "BAJAJ"
                ? ` to your Email(s)${
                    loc[2] === "compare-quote" || sendPdf
                      ? " and WhatsApp"
                      : ", WhatsApp and SMS"
                  } successfully.`
                : " through all channels successfully."
              : ` on given Email${
                  EmailsId && !_.isEmpty(EmailsId) && EmailsId?.length === 1
                    ? " successfully."
                    : "(s) successfully."
                }`}
          </Text>
        </Wrapper>
      </FlexDiv>
    </MessageContainer>
  );

  const checkItem = (item) => {
    let allItems = [...selectedItems, item];
    setSelectedItems(allItems);
  };

  const removeItem = (item) => {
    let allItems = selectedItems.filter((x) => x.policyId !== item.policyId);
    setSelectedItems(allItems);
  };

  const selectAllItems = () => {
    let allItems = options2;
    setSelectedItems(allItems);
    setSelectAll(true);
  };

  const removeAllItems = () => {
    let allItems = [];
    setSelectedItems(allItems);
    setSelectAll(false);
  };

  const handleShare = () => {
    if (selectedItems?.length > 0) {
      setActive("shareOption");
    }
  };

  const content = (
    <>
      <MainWrapper>
        <div className="sendQuotes">
          <Wrapper>
            <div className="stepwizard" style={{ display: "none" }}>
              <div className="stepwizard-row setup-panel">
                <div className="stepwizard-step">
                  <a
                    type="button"
                    className={`btn btn-circle btn-default ${
                      step === 1 ? "btn-primary" : ""
                    }`}
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    1
                  </a>
                  <p>Select the plans</p>
                </div>
                <div className="stepwizard-step">
                  <a
                    type="button"
                    className={`btn btn-circle btn-default ${
                      step === 2 ? "btn-primary" : ""
                    }`}
                    //	onClick={() => setStep(2)}
                  >
                    2
                  </a>
                  <p>Share with</p>
                </div>
              </div>
            </div>
            <div
              className="contact__imgbox"
              style={{ display: lessthan600 && step === 1 && "none" }}
            >
              <ContactImg
                src={
                  TypeReturn(type) !== "bike"
                    ? `${
                        process.env.REACT_APP_BASENAME !== "NA"
                          ? `/${process.env.REACT_APP_BASENAME}`
                          : ""
                      }/assets/images/new-car.jpg`
                    : `${
                        process.env.REACT_APP_BASENAME !== "NA"
                          ? `/${process.env.REACT_APP_BASENAME}`
                          : ""
                      }/assets/images/vehicle/bike2.png`
                }
                alt="Lakshmi"
              ></ContactImg>
            </div>
            <ContactText
              style={{ display: lessthan600 && step === 1 && "none" }}
            >
              {!sendPdf && shareQuotesFromToaster !== true ? (
                <p
                  style={{
                    fontSize: lessthan576 ? "0.9rem" : "",
                  }}
                >
                  Hi, please choose the way you wish to share the{" "}
                  {loc[2] === "proposal-page"
                    ? "proposal"
                    : loc[2] === "payment-confirmation"
                    ? "payment"
                    : loc[1] === "payment-success"
                    ? "policy"
                    : "quotes"}
                </p>
              ) : shareQuotesFromToaster ? (
                <p>Hi, Please share mobile number of customer. </p>
              ) : (
                <p>
                  Please enter your Email Ids{" "}
                  {(process.env.REACT_APP_BROKER === "ACE" ||
                    process.env.REACT_APP_BROKER === "RB" ||
                    process.env.REACT_APP_BROKER === "BAJAJ") &&
                    "or Mobile No."}{" "}
                  to share PDF.
                </p>
              )}
            </ContactText>
            {loc[2] === "quotes" && !shareQuotesFromToaster && !sendPdf && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TabWrapper width="290px" className="tabWrappers">
                  <Tab
                    isActive={Boolean(isActive === "custom")}
                    onClick={() => setActive("custom")}
                    className="shareTab"
                    shareTab="shareTab"
                  >
                    Select Insurer
                  </Tab>
                  <Tab
                    isActive={Boolean(isActive === "shareOption")}
                    onClick={handleShare}
                    className="shareTab"
                    shareTab="shareTab"
                    disable={disabledBtn}
                  >
                    Basic Details
                  </Tab>
                </TabWrapper>
                <div
                  style={{
                    display: isActive === "shareOption" ? "none" : "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  Select all
                  {selectAll ? (
                    <ShareCheckBox
                      onClick={removeAllItems}
                      className="fa fa-check"
                      style={{
                        marginLeft: "5px",
                      }}
                    ></ShareCheckBox>
                  ) : (
                    <div
                      onClick={selectAllItems}
                      style={{
                        height: "15px",
                        width: "15px",
                        border: "1px solid",
                        cursor: "pointer",
                        marginLeft: "5px",
                      }}
                    ></div>
                  )}
                  <div></div>
                </div>
              </div>
            )}
            {isActive === "custom" &&
            loc[2] === "quotes" &&
            !shareQuotesFromToaster &&
            !sendPdf ? (
              <>
                <table style={{ width: "100%" }}>
                  {options &&
                    options.map((item, index) => (
                      <tr style={{ marginBottom: "15px" }}>
                        <td>
                          <img
                            src={item.logo}
                            alt="Logo"
                            style={{
                              width: "50px",
                              margin: lessthan767
                                ? "0px 15px 0px 0px"
                                : "0px 15px",
                            }}
                          />
                        </td>
                        <td style={{ lineHeight: "1px", width: "270px" }}>
                          <p style={{ fontSize: "12px" }}>
                            {item?.name.length > 35
                              ? item?.name?.slice(0, 35) + "..."
                              : item?.name}
                          </p>
                          <small style={{ fontSize: "10px", color: "gray" }}>
                            Policy Type: {item.policyType}
                          </small>
                        </td>
                        <td style={{ display: lessthan767 ? "none" : "" }}>
                          <button
                            style={{
                              fontSize: "12px",
                              border: "none",
                              borderRadius: "10px",
                              padding: "7px 14px",
                              width: "145px",
                              marginRight: "10px",
                            }}
                          >
                            Premium: ₹ {currencyFormater(item.finalPremium)}
                          </button>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {selectedItems
                              .map((x) => x.policyId)
                              .includes(item?.policyId) ? (
                              <ShareCheckBox
                                onClick={() => removeItem(item)}
                                className="fa fa-check"
                                style={{
                                  marginLeft: lessthan767 ? "-28px" : "5px",
                                }}
                              ></ShareCheckBox>
                            ) : (
                              <div
                                onClick={() => checkItem(item)}
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  border: "1px solid",
                                  cursor: "pointer",
                                  marginLeft: lessthan767 ? "-28px" : "5px",
                                }}
                              ></div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </table>
              </>
            ) : (
              <Form
                style={{
                  height: loc[2] === "quotes" && !sendPdf && "200px",
                  transform:
                    loc[2] === "quotes" && !sendPdf && "translateY(30px)",
                }}
              >
                <Row>
                  {((!sendPdf && process.env.REACT_APP_BROKER !== "CP") ||
                    (sendPdf &&
                      (process.env.REACT_APP_BROKER === "ACE" ||
                        process.env.REACT_APP_BROKER === "BAJAJ")) ||
                    (loc[2] === "compare-quote" &&
                      (process.env.REACT_APP_BROKER === "RB" ||
                        process.env.REACT_APP_BROKER === "SPA"))) && (
                    <>
                      <Col
                        sm="10"
                        md="10"
                        lg="10"
                        xl="10"
                        xs={lessthan576 && !sendPdf ? 8 : 10}
                      >
                        <div className="w-100">
                          <Textbox
                            nonCircular
                            lg
                            type="tel"
                            id="mobileNo"
                            fieldName="Mobile No."
                            name="mobileNo"
                            placeholder=" "
                            register={register}
                            error={
                              !!errors?.mobileNo &&
                              watch("mobileNo") &&
                              watch("mobileNo")?.length === 10 &&
                              errors?.mobileNo?.message
                            }
                            maxLength="10"
                            fontWeight="bold"
                            onInput={(e) =>
                              (e.target.value = e.target.value.replace(
                                /[^0-9-/]/g,
                                ""
                              ))
                            }
                            isEmail
                          />
                        </div>
                      </Col>

                      {lessthan576 ? (
                        <>
                          {hideWhatsAppCondition ? (
                            <Col xs={2} style={{ paddingLeft: "2px" }}>
                              <Btn
                                type="button"
                                variant="success"
                                disabled={
                                  !MobileNo ||
                                  MobileNo?.length < 10 ||
                                  errors?.mobileNo
                                }
                                onClick={() => onSubmit(MobileNo, 2)}
                                style={{
                                  position: "relative",
                                  right: "0px",
                                  height: "50px",
                                  width: "50px",
                                  cursor:
                                    (!MobileNo ||
                                      MobileNo?.length < 10 ||
                                      errors?.mobileNo) &&
                                    "not-allowed",
                                  ...(process.env.REACT_APP_BROKER === "RB" && {
                                    background: "#F27F21",
                                    borderColor: "#F27F21",
                                  }),
                                }}
                              >
                                <WhatsAppIcon style={{ color: "#000" }} />
                              </Btn>
                            </Col>
                          ) : (
                            <noscript />
                          )}
                          {shareQuotesFromToaster !== true &&
                          !sendPdf &&
                          (process.env.REACT_APP_BROKER !== "OLA" ||
                            loc[2] === "compare-quote") ? (
                            <Col xs={2}>
                              <Btn
                                type="submit"
                                variant="warning"
                                disabled={
                                  !MobileNo ||
                                  MobileNo?.length < 10 ||
                                  errors?.mobileNo
                                }
                                onClick={() => onSubmit(MobileNo, 1)}
                                style={{
                                  position: "relative",
                                  right: hideWhatsAppCondition ? "0px" : "15px",
                                  height: "50px",
                                  width: "50px",
                                  cursor:
                                    (!MobileNo ||
                                      MobileNo?.length < 10 ||
                                      errors?.mobileNo) &&
                                    "not-allowed",
                                  ...(process.env.REACT_APP_BROKER === "RB" && {
                                    background: "#F27F21",
                                    borderColor: "#F27F21",
                                  }),
                                }}
                              >
                                <MessageIcon style={{ color: "#000" }} />
                              </Btn>
                            </Col>
                          ) : (
                            <noscript />
                          )}
                        </>
                      ) : (
                        <>
                          {hideWhatsAppCondition ? (
                            <Col sm="1" md="1" lg="1" xl="1" xs={2}>
                              <Btn
                                type="button"
                                variant="success"
                                disabled={
                                  !MobileNo ||
                                  MobileNo?.length < 10 ||
                                  errors?.mobileNo
                                }
                                onClick={() => onSubmit(MobileNo, 2)}
                                style={{
                                  position: "relative",
                                  right: "15px",
                                  height: "50px",
                                  width: "50px",
                                  cursor:
                                    (!MobileNo ||
                                      MobileNo?.length < 10 ||
                                      errors?.mobileNo) &&
                                    "not-allowed",
                                  ...(process.env.REACT_APP_BROKER === "RB" && {
                                    background: "#F27F21",
                                    borderColor: "#F27F21",
                                  }),
                                }}
                              >
                                <WhatsAppIcon style={{ color: "#000" }} />
                              </Btn>
                            </Col>
                          ) : (
                            <noscript />
                          )}
                          {shareQuotesFromToaster !== true &&
                          !sendPdf &&
                          (process.env.REACT_APP_BROKER !== "OLA" ||
                            loc[2] === "compare-quote") ? (
                            <Col sm="1" md="1" lg="1" xl="1" xs={2}>
                              <Btn
                                type="submit"
                                variant="warning"
                                disabled={
                                  !MobileNo ||
                                  MobileNo?.length < 10 ||
                                  errors?.mobileNo
                                }
                                onClick={() => onSubmit(MobileNo, 1)}
                                style={{
                                  position: "relative",
                                  right: hideWhatsAppCondition ? "0px" : "15px",
                                  height: "50px",
                                  width: "50px",
                                  cursor:
                                    (!MobileNo ||
                                      MobileNo?.length < 10 ||
                                      errors?.mobileNo) &&
                                    "not-allowed",
                                  ...(process.env.REACT_APP_BROKER === "RB" && {
                                    background: "#F27F21",
                                    borderColor: "#F27F21",
                                  }),
                                }}
                              >
                                <MessageIcon style={{ color: "#000" }} />
                              </Btn>
                            </Col>
                          ) : (
                            <noscript />
                          )}
                        </>
                      )}
                    </>
                  )}
                  <Col sm="1" md="1" lg="1" xl="1" xs={2}>
                    {loc[2] === "payment-confirmation" && (
                      <Btn
                        type="submit"
                        variant="warning"
                        disabled={
                          !MobileNo || MobileNo?.length < 10 || errors?.mobileNo
                        }
                        onClick={() => onSubmit(MobileNo, 1)}
                        style={{
                          height: "50px",
                          width: "50px",
                          cursor:
                            (!MobileNo ||
                              MobileNo?.length < 10 ||
                              errors?.mobileNo) &&
                            "not-allowed",
                          ...(process.env.REACT_APP_BROKER === "RB" && {
                            background: "#F27F21",
                            borderColor: "#F27F21",
                          }),
                        }}
                      >
                        <MessageIcon style={{ color: "#000" }} />
                      </Btn>
                    )}
                  </Col>
                </Row>
                {shareQuotesFromToaster !== true &&
                  (process.env.REACT_APP_BROKER !== "OLA" ||
                    loc[2] === "compare-quote") && (
                    <Row>
                      <Col
                        sm="10"
                        md="10"
                        lg="10"
                        xl="10"
                        xs={lessthan576 && !sendPdf ? 8 : 10}
                        style={{}}
                      >
                        <div className="w-100">
                          <MultiEmail
                            register={register}
                            setValue={setValue}
                            setDisEmail={setDisEmail}
                            prefill={
                              userDataHome?.emailId ||
                              userDataHome?.userProposal?.additonalData?.owner
                                ?.email ||
                              temp_data?.userProposal?.additonalData?.owner
                                ?.email
                            }
                          />
                        </div>
                      </Col>
                      <Col
                        sm="1"
                        md="1"
                        lg="1"
                        xl="1"
                        xs={lessthan576 ? 2 : 2}
                        style={{ paddingLeft: lessthan576 ? "0" : "" }}
                      >
                        <Btn
                          type="submit"
                          variant="primary"
                          disabled={disEmail}
                          onClick={handleSubmit(onSubmit)}
                          style={{
                            height: "50px",
                            width: "50px",
                            position: "relative",
                            right: lessthan576 ? "0" : "15px",
                            cursor: disEmail && "not-allowed",
                            ...(process.env.REACT_APP_BROKER === "RB" && {
                              background: "#F27F21",
                              borderColor: "#F27F21",
                            }),
                          }}
                        >
                          {!customLoad ? (
                            <MailOutlineOutlinedIcon
                              style={{ color: "#000" }}
                            />
                          ) : (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                          )}
                        </Btn>
                      </Col>
                      {(!sendPdf || process.env.REACT_APP_BROKER === "BAJAJ") &&
                      process.env.REACT_APP_BROKER !== "CP" ? (
                        <Col
                          sm="1"
                          md="1"
                          lg="1"
                          xl="1"
                          xs={lessthan576 ? 2 : 2}
                          style={{ paddingLeft: lessthan576 ? "0" : "" }}
                        >
                          <Btn
                            type="submit"
                            variant="secondary"
                            disabled={
                              disEmail ||
                              !MobileNo ||
                              errors?.mobileNo ||
                              MobileNo?.length < 10
                            }
                            onClick={() => {
                              onSubmit(MobileNo, 3, true);
                            }}
                            style={{
                              height: "50px",
                              width: "50px",
                              left: lessthan576 ? "15px" : "0",
                              position: "relative",
                              cursor:
                                (disEmail ||
                                  !MobileNo ||
                                  MobileNo?.length < 10 ||
                                  errors?.mobileNo) &&
                                "not-allowed",
                              ...(process.env.REACT_APP_BROKER === "RB" && {
                                background: "#F27F21",
                                borderColor: "#F27F21",
                                color: "#000",
                                fontWeight: "bold",
                              }),
                            }}
                          >
                            {!customLoad && "ALL"}
                          </Btn>
                        </Col>
                      ) : (
                        <noscript />
                      )}
                    </Row>
                  )}
              </Form>
            )}
          </Wrapper>
        </div>
      </MainWrapper>
      <Content2>
        <p>
          <span>*</span>
          {shareQuotesFromToaster
            ? "Customer will receive message on shared WhatsApp number."
            : "Please note that the premium may vary in future."}
        </p>
      </Content2>
    </>
  );

  return !lessthan767 ? (
    <div>
      <Popup
        height={msg ? "240px" : "auto"}
        width="640px"
        show={show}
        onClose={onClose}
        content={msg ? content2 : content}
        position="middle"
        zIndexPopup={true}
        outside={sendPdf ? true : false}
        hiddenClose={customLoad ? true : false}
      ></Popup>
    </div>
  ) : (
    <>
      <React.Fragment key={"bottom"} style={{ borderRadius: "5% 5% 0% 0%" }}>
        <Drawer
          anchor={"bottom"}
          open={drawer}
          style={{ zIndex: "1000000000" }}
          onClose={() => {
            setDrawer(false);
            onClose(false);
            setSendPdf(false);
          }}
          onOpen={() => {
            setDrawer(true);
          }}
          ModalProps={{
            keepMounted: true,
            disableEscapeKeyDown: true,
          }}
          onBackdropClick={false}
        >
          <MobileDrawerBody>
            <CloseButton
              onClick={() => {
                setDrawer(false);
                onClose(false);
                setSendPdf(false);
              }}
            >
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ height: " 25px" }}
              >
                <path
                  fill={"#000"}
                  d="M12,2c-5.53,0 -10,4.47 -10,10c0,5.53 4.47,10 10,10c5.53,0 10,-4.47 10,-10c0,-5.53 -4.47,-10 -10,-10Zm5,13.59l-1.41,1.41l-3.59,-3.59l-3.59,3.59l-1.41,-1.41l3.59,-3.59l-3.59,-3.59l1.41,-1.41l3.59,3.59l3.59,-3.59l1.41,1.41l-3.59,3.59l3.59,3.59Z"
                ></path>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
            </CloseButton>

            {msg ? content2 : content}
          </MobileDrawerBody>
        </Drawer>
      </React.Fragment>

      <GlobalStyle disabledBackdrop={false} />
    </>
  );
};

export default SendQuotes;

const GlobalStyle = createGlobalStyle`
body {
	.MuiDrawer-paperAnchorBottom {
		border-radius: 3% 3% 0px 0px;
		z-index: 999999999 !important;
	}
	.css-1u2w381-MuiModal-root-MuiDrawer-root {
    z-index: 1000000 !important;
  }
	.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    pointer-events: ${({ disabledBackdrop }) =>
      disabledBackdrop ? "none !important" : ""};
  }
}
`;

const LaxmiWrapper = styled.div`
  float: left;
  margin-right: 28px;
`;

const Laxmi = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 20px;
  box-shadow: 0px 4px 13px rgba(41, 41, 41, 0.35);
  border: ${({ theme }) => theme.Header?.borderLogo || "2.5px solid #bdd400"};
  background: ${({ theme }) => theme.links?.color || "#bdd400"};
`;

const MainWrapper = styled.div`
  margin: 40px 0 20px;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .tabWrappers {
    position: unset;
    margin-left: -12px;
    margin-bottom: 12px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: max-content;
  }
  .shareTab {
    border: none !important;
    border-radius: 20px;
  }

  .tableConatiner {
    max-height: 600px;
    overflow: auto;
    margin-top: 10px;
    padding: 10px;
    & td {
      border: none;
    }
    & th {
      border: none;
    }
    & tr {
      border-bottom: 0.3px solid #bdd400;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  & > div {
    margin-top: 10px;
    position: relative;
  }
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  font-family: ${({ theme }) =>
    theme?.fontFamily
      ? theme?.fontFamily
      : `"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif`};
  & strong {
    color: #000000;
    font-weight: 700;
  }
`;

const Content2 = styled.div`
  & p {
    color: #000000;
    font-family: ${({ theme }) =>
      theme?.fontFamily ? theme?.fontFamily : `"basier_squaremedium"`};
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
  height: 20%;
  padding: 23px 0;
  background-color: #f1f2f6;
  text-align: center;
`;

const MessageContainer = styled.div`
  padding: 10px;
  & svg {
    margin: 0 auto;
    width: 100%;
  }
`;

const FlexDiv = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 60px;
  @media only screen and (max-width: 600) {
    margin: 0px 0px;
  }
`;

const ContactImg = styled.img`
  float: left;
  margin-right: 10px;
  height: 70px;
`;

const ContactText = styled.div`
  padding: 1rem;
  font-weight: 400;
  font-family: ${({ theme }) =>
    theme?.fontFamily ? theme?.fontFamily : `"basier_squareregular"`};
  font-size: 16px;
  margin-bottom: 20px;
  color: #111;
`;

const MobileDrawerBody = styled.div`
  width: 100%;
  border-radius: 3px 3px 0px 0px;
`;
const CloseButton = styled.div`
  display: ${({ hiddenClose }) => (hiddenClose ? "none" : "block")};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1111;
  &:hover {
    text-decoration: none;
    color: #363636;
  }
`;

const ShareCheckBox = styled.i`
  background-color: ${({ theme }) => theme.CheckBox?.color || "#bdd400"};
  border: ${({ theme }) => theme.CheckBox?.border || "1px solid #bdd400"};
  width: 15px;
  height: 15px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
