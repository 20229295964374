import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Spinner, Modal } from "react-bootstrap";
import "./otp.css";
import { Button } from "components";
import { useMediaPredicate } from "react-media-hook";
import styled from "styled-components";
import { clear, VerifyOTP, VerifyCkycnum } from "./proposal.slice";
import swal from "sweetalert";
import _ from "lodash";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { Enquiry } from "modules/Home/home.slice";
const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const OTPPopup = (props) => {
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const lessthan380 = useMediaPredicate("(max-width: 380px)");
  const lessthan320 = useMediaPredicate("(max-width: 320px)");
  const lessthan415 = useMediaPredicate("(max-width: 415px)");
  const dispatch = useDispatch();
  const { onHide, otpSuccess, lead_otp, otpData } = props;
  const { verifyOtp, otpError, verifyCkycnum } = useSelector(
    (state) => state.proposal
  );
  const { enquiry_id, loading: r_loading } = useSelector((state) => state.home);

  console.log("fsdfsadf", r_loading);

  const [resend, setResend] = useState(false);
  const [counter, setCounter] = useState(false);
  const [loading, setLoading] = useState(false);

  const otp1 = useRef();
  const otp2 = useRef();
  const otp3 = useRef();
  const otp4 = useRef();
  const otp5 = useRef();
  const otp6 = useRef();

  const numOnly = (event) => {
    let key = event.keyCode || event.which;
    if (
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105) ||
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 16 ||
      key === 17 ||
      key === 20 ||
      key === 35 ||
      key === 35 ||
      key === 37 ||
      key === 39
      // key === 144
    ) {
      if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
        if (event.target.name === "otp1") {
          otp1.current.value = event.key;
        }
        if (event.target.name === "otp2") {
          otp2.current.value = event.key;
        }
        if (event.target.name === "otp3") {
          otp3.current.value = event.key;
        }
        if (event.target.name === "otp4") {
          otp4.current.value = event.key;
        }
        if (event.target.name === "otp5") {
          otp5.current.value = event.key;
        }
        if (event.target.name === "otp6") {
          otp6.current.value = event.key;
        }
      }
    } else {
      event.preventDefault();
    }
  };

  const nextKey = (e) => {
    if (e.target.value) {
      if (e.target.name === "otp1") {
        otp2?.current?.focus && otp2.current.focus();
      }
      if (e.target.name === "otp2") {
        otp3?.current?.focus && otp3.current.focus();
      }
      if (e.target.name === "otp3") {
        otp4?.current?.focus && otp4.current.focus();
      }
      if (e.target.name === "otp4") {
        otp5?.current?.focus && otp5.current.focus();
      }
      if (e.target.name === "otp5") {
        otp6?.current?.focus && otp6.current.focus();
      }
    }
  };

  const {
    fullName,
    firstName,
    lastName,
    emailId,
    mobileNo,
    whatsappNo,
    ...other
  } = otpData || {};

  //verify OTP
  const otpEnter = () => {
    if (
      !props?.ckyc &&
      lead_otp &&
      otp1.current.value &&
      otp2.current.value &&
      otp3.current.value &&
      otp4.current.value
    ) {
      dispatch(
        Enquiry(
          {
            otp:
              otp1.current.value +
              otp2.current.value +
              otp3.current.value +
              otp4.current.value,
            firstName,
            lastName,
            fullName,
            emailId,
            mobileNo,
            whatsappNo,
            other,
          },
          true
        )
      );
    } else if (
      !props?.ckyc &&
      otp1.current.value &&
      otp2.current.value &&
      otp3.current.value &&
      otp4.current.value
    ) {
      setLoading(true);
      dispatch(
        VerifyOTP({
          enquiryId: props?.enquiry_id,
          otp:
            otp1.current.value +
            otp2.current.value +
            otp3.current.value +
            otp4.current.value,
        })
      );
    } else if (
      !!props?.ckyc &&
      props?.otp_id &&
      otp1.current.value &&
      otp2.current.value &&
      otp3.current.value &&
      otp4.current.value &&
      otp5.current.value &&
      otp6.current.value
    ) {
      setLoading(true);
      dispatch(
        VerifyCkycnum(
          {
            companyAlias: props?.companyAlias,
            mode: "otp",
            otpId: props?.otp_id,
            enquiryId: props?.enquiry_id,
            otp: Number(
              otp1.current.value +
                otp2.current.value +
                otp3.current.value +
                otp4.current.value +
                otp5.current.value +
                otp6.current.value
            ),
          },
          setLoading
        )
      );
    }
  };

  const pasteHandler = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (enquiry_id?.enquiryId) {
      otpSuccess();
    }
  }, [enquiry_id?.enquiryId]);

  //on Error
  useEffect(() => {
    if (otpError) {
      swal(
        "Error",
        props?.enquiry_id
          ? `${`Trace ID:- ${props?.enquiry_id}.\n Error Message:- ${otpError}`}`
          : otpError,
        "error"
      );
    }

    if (verifyOtp) {
      // swal("Success", 'Validation successful', "success").then(() => {

      // });
      otpSuccess();
      onHide();
      // history.push(`/policy-renewal?enquiry_id=${enquiry_id}`)
    } else if (props?.ckyc && props?.companyAlias === "tata_aig") {
      if (verifyCkycnum?.verification_status) {
        otpSuccess();
        onHide();
      } else if (verifyCkycnum && !verifyCkycnum?.verification_status) {
        swal("Error", verifyCkycnum?.message || "verification failed", "error");
        onHide();
      }
    }

    return () => {
      dispatch(clear("verifyOtp"));
      dispatch(clear("verifyCkycnum"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpError, verifyOtp, verifyCkycnum]);
  //'resend
  // useEffect(() => {
  //   setTimeout(setCounter(true), 5000);
  //   console.log('fire', counter)
  // }, []);

  // const pasteHandler=(e)=>{
  //   const pastedData = e.clipboardData
  //   .getData('text/plain')
  //   .trim()
  //   .split('');
  //   if(pastedData&&pastedData?.length>3){
  //     pastedData.slice(0,4)
  //     otp1.current.value=pastedData[0]
  //     otp2.current.value=pastedData[1]
  //     otp3.current.value=pastedData[2]
  //     otp4.current.value=pastedData[3]
  //     otp4.current.focus();
  //   }
  // }

  const otpResend = () => {
    setResend(true);
    // dispatch(
    //   resendOTP({
    //     contact_no: parseInt(`91${company_data.contact_no}`),
    //   })
    // );
    setTimeout(() => {
      setResend(false);
    }, 25000);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="my-modal"
    >
      <Modal.Body
        style={{
          padding: "85px 15px 50px 15px",
          background: "white",
          borderRadius: "5px",
        }}
      >
        <CloseButton onClick={props.onHide}>×</CloseButton>
        <Row>
          <ModalLeftContentDiv md={12} lg={5} xl={5} sm={12}>
            <img
              src={`${
                process.env.REACT_APP_BASENAME !== "NA"
                  ? `/${process.env.REACT_APP_BASENAME}`
                  : ""
              }/assets/images/RFQ/security-otp-one-time-password-smartphone-shield_9904-104.png`}
              alt="otp"
            ></img>
          </ModalLeftContentDiv>
          <Col md={12} lg={7} xl={7} sm={12}>
            <div>
              <ModalRightContentDiv style={{ marginBottom: "25px" }}>
                <Heading>Please enter the OTP</Heading>
              </ModalRightContentDiv>
              <ModalRightContentDiv>
                <Paragraph>
                  {props?.ckyc ? (
                    "OTP has been sent to your registered mobile number."
                  ) : props?.lead_otp ? (
                    <>
                      OTP has been sent to <b>{`${props?.mobileNumber}`}</b>
                    </>
                  ) : (
                    <>
                      OTP has been sent to <b>{`${props?.mobileNumber}`}</b> and{" "}
                      <b>{`${props?.email}`}</b>
                      &nbsp;&nbsp;
                    </>
                  )}
                  {/* <i
                    className="fa fa-pencil"
                    aria-hidden="true"
                    onClick={props.onHide}
                    style={{ cursor: "pointer" }}
                  ></i> */}
                </Paragraph>
              </ModalRightContentDiv>
              <ModalRightContentDiv
                style={{ marginLeft: lessthan320 && "15px" }}
                lessthan767={lessthan767}
                ckyc={props?.ckyc}
              >
                <input
                  // autoComplete="none"
                  name="otp1"
                  ref={otp1}
                  maxLength="1"
                  onKeyUp={nextKey}
                  type="tel"
                  onKeyDown={numOnly}
                  onPaste={pasteHandler}
                />
                <input
                  // autoComplete="none"
                  name="otp2"
                  ref={otp2}
                  maxLength="1"
                  onKeyUp={nextKey}
                  type="tel"
                  onKeyDown={numOnly}
                  onPaste={pasteHandler}
                />
                <input
                  // autoComplete="none"
                  name="otp3"
                  ref={otp3}
                  maxLength="1"
                  onKeyUp={nextKey}
                  type="tel"
                  onKeyDown={numOnly}
                  onPaste={pasteHandler}
                />
                <input
                  // autoComplete="none"
                  name="otp4"
                  ref={otp4}
                  maxLength="1"
                  onKeyUp={nextKey}
                  type="tel"
                  onKeyDown={numOnly}
                  onPaste={pasteHandler}
                />
                {props?.ckyc && (
                  <>
                    <input
                      // autoComplete="none"
                      name="otp5"
                      ref={otp5}
                      maxLength="1"
                      onKeyUp={nextKey}
                      type="tel"
                      onKeyDown={numOnly}
                      onPaste={pasteHandler}
                    />
                    <input
                      // autoComplete="none"
                      name="otp6"
                      ref={otp6}
                      maxLength="1"
                      onKeyUp={nextKey}
                      type="tel"
                      onKeyDown={numOnly}
                      onPaste={pasteHandler}
                    />
                  </>
                )}
              </ModalRightContentDiv>
              <ModalRightContentDiv>
                {" "}
                <Button
                  onClick={otpEnter}
                  disabled={loading}
                  type="submit"
                  buttonStyle="outline-solid"
                  hex1={
                    Theme?.proposalProceedBtn?.hex1
                      ? Theme?.proposalProceedBtn?.hex1
                      : "#4ca729"
                  }
                  hex2={
                    Theme?.proposalProceedBtn?.hex2
                      ? Theme?.proposalProceedBtn?.hex2
                      : "#4ca729"
                  }
                  borderRadius="5px"
                  color="white"
                >
                  <text
                    style={{
                      fontSize: "15px",
                      padding: "-20px",
                      margin: "-20px -5px -20px -5px",
                      fontWeight: "400",
                    }}
                  >
                    {loading || r_loading ? "Please Wait..." : "Submit"}
                  </text>
                </Button>
              </ModalRightContentDiv>
              {/* <ModalRightContentDiv>
                <div
                  onClick={() => (!resend ? otpResend() : {})}
                  style={{ cursor: "pointer" }}
                >
                  Didn't receive your OTP?{" "}
                  <text className={resend ? "text-secondary" : "text-primary"}>
                    Resend OTP
                  </text>
                </div>
              </ModalRightContentDiv>
              <ModalRightContentDiv>
                <div
                  style={resend ? {} : { display: "none" }}
                  className={`${resend ? "resendShow" : ""}`}
                >
                  OTP has been sent again
                </div>
              </ModalRightContentDiv> */}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  width: 35px;
  height: 35px;
  // border-radius: 50%;
  color: #232222;
  text-shadow: none;
  opacity: 1;
  z-index: 1;
  border: none !important;
  font-size: 1.7rem;
  font-weight: 700;
  background: white;
  &:focus {
    outline: none;
  }
`;
const ModalRightContentDiv = styled.div`
  margin-bottom: 25px;
  @media (max-width: 991px) {
    input,
    textarea {
      transform: translateZ(0px) !important;
    }
    display: flex;
    justify-content: center;
    & .OTPDiv {
      margin-left: 0px !important;
    }
  }
  & h2 {
    font-weight: bold;
  }
  & p {
    font-size: 15px;
    color: #4e4d4d;
    line-height: 1.8rem;
    word-spacing: 1px;
    // text-align: center;
  }
  & input {
    height: ${(props) =>
      props?.lessthan767
        ? props?.ckyc
          ? "35px"
          : "50px"
        : props?.ckyc
        ? "50px"
        : "60px"};
    width: ${(props) =>
      props?.lessthan767
        ? props?.ckyc
          ? "35px"
          : "50px"
        : props?.ckyc
        ? "50px"
        : "60px"};
    margin-right: 25px;
    font-weight: bold;
    font-size: 20px;
    // border: none;
    text-align: center;
    box-shadow: rgb(35 34 34 / 25%) 0px 2px 6px 1px,
      rgb(0 0 0 / 10%) 1px 1px 0px 0px !important;
    @media (max-width: 991px) {
      margin-right: 10px;
    }
  }
  & .OTPDiv {
    margin-left: 55px;
    font-size: 15px;
    cursor: pointer;
  }
`;

const Heading = styled.h2`
  @media (max-width: 768px) {
    font-size: 25px;
    margin: 25px 0 0;
  }
  @media (max-width: 324px) {
    font-size: 22px;
    margin: 25px 0 0;
  }
`;

const Paragraph = styled.p`
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const ModalLeftContentDiv = styled(Col)`
  padding-left: 0px;
  margin-top: -30px;
  & img {
    width: 280px;
  }
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 768px) {
    & img {
      width: 180px;
      height: 180px;
      object-fit: cover;
    }
  }
  @media (max-width: 320px) {
    & img {
      width: 130px;
      height: 120px;
      object-fit: cover;
    }
  }
`;

export default OTPPopup;
