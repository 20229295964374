import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "components";
import styled, { createGlobalStyle } from "styled-components";
import { FeedBack as FeedBackAction } from "./feedback.slice";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Textbox } from "components";
import "./feedback.scss";
import swal from "sweetalert";
import { reloadPage } from "utils";

function RatingStar({ name, value, ...props }) {
  return (
    <>
      <input
        type="radio"
        name={name}
        value={value}
        id={`${name}-${value}`}
        {...props}
      />
      <label htmlFor={`${name}-${value}`}>★</label>
    </>
  );
}

function Rating({
  title,
  worstLabel,
  bestLabel,
  errorText = "Please select",
  isError = false,
  name,
  ...props
}) {
  return (
    <div className="ratingCont">
      <div className="ratingTitle">{title}</div>
      <div className="rating-box">
        <div className="rating-container">
          {[5, 4, 3, 2, 1].map((val) => (
            <RatingStar key={val} name={name} value={val} {...props} />
          ))}
        </div>
      </div>
      <div className="rateTxt">
        {worstLabel} <span>{bestLabel}</span>
      </div>
      {isError && <div className="ratingErrorMsg">{errorText}</div>}
    </div>
  );
}

export const Feedback = () => {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    // resolver: yupResolver(mobileValidation),
    // mode: "onBlur",
    // reValidateMode: "onBlur",
  });
  const [overallExp, setOverallExp] = useState(null);
  const [easyBuy, setEasyBuy] = useState(null);
  const [recommendUs, setRecommendUs] = useState(null);
  const [custsupExp, setCustsupExp] = useState(null);
  const { success, error } = useSelector((state) => state.feedback);
  const [isError, setIsError] = useState(false);

  const { temp_data } = useSelector((state) => state.home);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const dispatch = useDispatch();
  const history = useHistory();

  const UrlFn = () => {
    switch (process.env?.REACT_APP_BROKER) {
      case "OLA":
        return "https://ola-dashboard.fynity.in/";
      case "UNILIGHT":
        return "https://www.policyongo.com/pos/login";
      case "CP":
        return "https://www.comparepolicy.com/";
      case "FYNTUNE":
        return "";
      case "ABIBL":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-preprod-carbike.fynity.in/api"
        ) {
          return "http://preprod-dasbhoard-abibl.fynity.in/";
        } else if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apiabibl-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-abibl.fynity.in/";
        } else {
          return "http://abibl-prod-dashboard.fynity.in/";
        }
      case "GRAM":
        if (
          process.env?.REACT_APP_API_BASE_URL ===
          "https://apigramcover-carbike.fynity.in/api"
        ) {
          return "http://uat-dasbhoard-gramcover.fynity.in/";
        } else {
          return "https://dashboard.gramcover.com/";
        }
      case "ACE":
        return "https://crm.aceinsurance.com:5555/";
      case "SRIYAH":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://nammacover.com/"
          : "https://uat.nammacover.com/";
      case "PINC":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://live.pincnews.co.in/"
          : "https://dev.pincnews.co.in/";
      case "EPOCH":
        return `http://online.epochbrokers.com:8092/`;
      case "RB":
        return ``;
      case "SPA":
        return `https://uatdashboard.insuringall.com/`;
      case "BIMA":
        return ``;
      case "BAJAJ":
        return `https://www.bajajcapitalinsurance.com/`;
      case "UIB":
        return ``;
      case "SRIDHAR":
        return `https://uatdashboard.sibinsure.com/`;
      case "SHREE":
        return `https://shreeinsure.com/`;
      case "POLICYERA":
        return process.env.REACT_APP_PROD === "YES"
          ? `https://dashboard.policyera.com/`
          : `https://uatdashboard.policyera.com/`;
      case "TATA":
        return process.env.REACT_APP_PROD === "YES"
          ? "https://lifekaplan.com/"
          : "https://uat.lifekaplan.com/";
      default:
        break;
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    var feedbackData = {
      userProductJourneyId: enquiry_id,
      overallExperience: overallExp,
      easyToBuy: easyBuy,
      recommendUs: recommendUs,
      customerSupportExperience: custsupExp,
      comments: data?.otherFeedBack,
    };
    console.log(feedbackData);
    dispatch(FeedBackAction(feedbackData));
    if (error) {
      swal(
        "Error",
        `${`Trace ID:- ${enquiry_id}.\n Error Message:- ${error}`}`,
        "warning"
      );
    } else if (success) {
      reloadPage(UrlFn());
    }
  };

  useEffect(() => {
    if (success) {
      reloadPage(UrlFn());
    }
  }, [success]);

  return (
    <Row className="text-center w-100 mx-auto">
      <div className="mx-auto conatinerFeedback">
        <div className="mt-4 d-flex justify-content-center w-100">
          <img
            src={`${
              process.env.REACT_APP_BASENAME !== "NA"
                ? `/${process.env.REACT_APP_BASENAME}`
                : ""
            }/assets/images/success.png`}
            alt="errImg"
            height="70"
            width="70"
            className=""
          />
        </div>
        <div className="mt-4 d-flex flex-column justify-content-center w-100">
          <h4 className="text-center w-100 text-success font-weight-bold">
            Please provide your valuble feedback!
          </h4>
        </div>
        <div className="customWrap feedbackmainBtn">
          {/* <FeedbackmainBtn
						type="button"
						className="proceedNextTop"
						onClick={() => history.push(`/quotes?enquiry_id=${enquiry_id}`)}
					>
						Buy Another Policy
					</FeedbackmainBtn> */}
          <FeedbackmainBtn
            type="button"
            className="proceedNextTop"
            onClick={() => reloadPage(UrlFn())}
          >
            Back to Home Page
          </FeedbackmainBtn>
        </div>
        <div className="ratingFormWrapper">
          <form
            className="feedRatingWrap feedRatingForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Rating
              title="Overall Experience"
              bestLabel="Excellent"
              worstLabel="Poor"
              name="overallExp"
              onChange={(evt) => setOverallExp(evt.target.value)}
              isError={isError && !overallExp}
            />
            <Rating
              title="Easy to Buy"
              bestLabel="Yes"
              worstLabel="No"
              name="easyBuy"
              onChange={(evt) => setEasyBuy(evt.target.value)}
              isError={isError && !easyBuy}
            />
            <Rating
              title="Recommend Us"
              bestLabel="Yes"
              worstLabel="No"
              name="recommendUs"
              onChange={(evt) => setRecommendUs(evt.target.value)}
              isError={isError && !recommendUs}
            />
            <Rating
              title="Customer Support Experience"
              bestLabel="Excellent"
              worstLabel="Poor"
              name="custsupExp"
              onChange={(evt) => setCustsupExp(evt.target.value)}
              isError={isError && !custsupExp}
            />

            <div className="rateSubmitWrp">
              <TextBoxContainer>
                <Row>
                  {" "}
                  <Col sm="12" md="12" lg="12" xl="12">
                    <div className="w-100">
                      <Textbox
                        lg
                        type="text"
                        id="otherFeedBack"
                        fieldName="Please mention if any other comments"
                        name="otherFeedBack"
                        placeholder=" "
                        register={register}
                        //onChange={handleChange}
                        error={errors?.otherFeedBack}
                      />
                    </div>
                  </Col>
                </Row>
              </TextBoxContainer>
              <ProceedNextFeedBack
                type="submit"
                className="proceedNextFeedBack"
              >
                Submit
              </ProceedNextFeedBack>
            </div>
          </form>
        </div>
      </div>
      <GlobalStyle />
    </Row>
  );
};

const GlobalStyle = createGlobalStyle`


${({ theme }) =>
  theme?.fontFamily ? theme?.fontFamily : `font-family:roboto !important`}

`;

const FeedbackmainBtn = styled.button`
  color: #bdd400;
  background-color: #000;
`;
const ProceedNextFeedBack = styled.button`
  background-color: #bdd400;
  color: #000;
`;

const TextBoxContainer = styled.div`
  position: relative;
`;
