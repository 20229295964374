import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import tooltip from "../../../../assets/img/tooltip.svg";
import CustomTooltip from "../../../../components/tooltip/CustomTooltip";
import Popup from "../../../../components/Popup/Popup";
import "../../quotesPopup/idvPopup/idvPopup.scss";
import { set_temp_data } from "modules/Home/home.slice";
import { useMediaPredicate } from "react-media-hook";
import Drawer from "@mui/material/Drawer";
import _ from "lodash";
import { CancelAll } from "modules/quotesPage/quote.slice";
import moment from "moment";
import { differenceInDays } from "date-fns";
import { toDate } from "utils";

const NCBPopup = ({ show, onClose, ncb, setNcb }) => {
  const lessthan963 = useMediaPredicate("(max-width: 963px)");
  const lessthan450 = useMediaPredicate("(max-width: 450px)");
  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const dispatch = useDispatch();
  const { handleSubmit, register, watch, control, errors, setValue } = useForm({
    // resolver: yupResolver(),
  });
  const { ncbList, tempData } = useSelector((state) => state.quoteFilter);
  const { temp_data } = useSelector((state) => state.home);
  const [noClaim, setNoClaim] = useState(false);
  const expPolicy = watch("claimMade");
  const ncbValue = watch("existinNcb");
  const OwnerShip = watch("ownerShip");
  const [ncbState, setNcbState] = useState(ncbValue || temp_data?.ncb);
  console.log(ncbState, "ncbState", ncbValue);
  console.log(OwnerShip, "ncbState", expPolicy);
  const myOrderedNcbList = _.sortBy(ncbList, (o) => o.discountRate);

  let date = new Date();
  date.setDate(date.getDate() - 91);
  console.log(moment(date).format("DD-MM-YYYY"), "expirydate");
  const ownershipDate = moment(date).format("DD-MM-YYYY");

  const ncbvoid =
    differenceInDays(
      toDate(moment().format("DD-MM-YYYY")),
      toDate(temp_data?.expiry)
    ) <= 90;

  ///-----------setting existing ncbs from temp data of home slice-------------------------

  useEffect(() => {
    if (temp_data?.ncb) {
      setValue("existinNcb", temp_data?.ncb);
      setNcbState(temp_data?.ncb);
    }
  }, [temp_data?.ncb]);

  useEffect(() => {
    if (ncbValue) {
      setNcbState(ncbValue);
    }
  }, [ncbValue]);

  //-----------------handling on submit of ncbs popup----------------------

  const onSubmit = (data) => {
    dispatch(CancelAll(true)); // cancel all apis loading (quotes apis)
    dispatch(
      set_temp_data({
        ncb:
          expPolicy === "yes" || OwnerShip === "yes" || !ncbvoid
            ? ncbState
            : ncbState
            ? ncbState
            : "0%",
        expPolicy: expPolicy,
        newNcb:
          expPolicy === "yes" || OwnerShip === "yes" || !ncbvoid
            ? "0%"
            : ncbState
            ? ncbState === "50%"
              ? "50%"
              : temp_data?.prevShortTerm * 1
              ? ncbState
              : getNewNcb(ncbState)
            : "0%",
        noClaimMade: noClaim || expPolicy === "no" ? true : false,
        carOwnership: OwnerShip === "yes" ? true : false,
        isNcbVerified: "Y", // this removed the ncb assumptions
        breakIn: temp_data?.breakIn,
        expiry: temp_data?.expiry,
      })
    );

    if (expPolicy === "yes") {
      setNcb(ncbState);
    } else {
      if (ncbValue) {
        setNcb(ncbValue);
      }
    }
    dispatch(CancelAll(false)); // resetting cancel all apis loading so quotes will restart (quotes apis)
    onClose(false);
  };

  ///-----------------handling claim conditions----------------------

  useEffect(() => {
    if (expPolicy === "no" || temp_data?.noClaimMade === true) {
      setNoClaim(true);
    } else {
      setNoClaim(false);
    }
    if (expPolicy === "yes") {
      setNoClaim(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expPolicy, temp_data?.noClaimMade]);

  const getNewNcb = (ncb) => {
    switch (ncb) {
      case "0%":
        return "20%";
      case "20%":
        return "25%";
      case "25%":
        return "35%";
      case "35%":
        return "45%";
      case "45%":
        return "50%";
      case "50%":
        return "50%";
      default:
        return "20%";
    }
  };

  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if (lessthan767 && show) {
      setTimeout(() => {
        setDrawer(true);
      }, 50);
    }
  }, [show]);
  console.log("ncbvoidncbvoid", ncbvoid);
  const content = (
    <>
      <Conatiner>
        <Row>
          <PaymentTermTitle>
            No Claim Bonus (NCB) Value
            <span
              class="cardTooltipSvg"
              data-toggle="popover"
              title=""
              data-content="Insured Value (IDV) Text"
              data-original-title="Insured Value (IDV)"
            >
              <CustomTooltip
                rider="true"
                id="RiderInbuilt__Tooltip"
                place={"bottom"}
                customClassName="mt-3 riderPageTooltip "
                allowClick
              >
                <img
                  data-tip="<h3 >No claim Bonus</h3> <div>No Claim Bonus (NCB) is a reward given by an insurer to an insured person for not raising any claim requests during a policy year.</div>"
                  data-html={true}
                  data-for="RiderInbuilt__Tooltip"
                  src={tooltip}
                  alt="tooltip"
                  className="toolTipRiderChild"
                />
              </CustomTooltip>
            </span>
          </PaymentTermTitle>
          <PopupSubTitle>
            No Claim Bonus or NCB is a reward given by an insurance company to
            an insured for not raising any claim requests during a policy year.
            The NCB discount is applicable on the premium amount.
          </PopupSubTitle>

          <PopupSubHead>
            {" "}
            Did vehicle's ownership change in the last 12 months?
          </PopupSubHead>
          <div class="vehRadioWrap">
            <input
              type="radio"
              id="ownerShipYes"
              name="ownerShip"
              value="yes"
              ref={register}
              defaultChecked={temp_data?.carOwnership === true}
            />
            <label for="ownerShipYes" style={{ cursor: "pointer" }}>
              Yes
            </label>
            <input
              type="radio"
              id="ownerShipNo"
              name="ownerShip"
              value="no"
              ref={register}
              defaultChecked={temp_data?.carOwnership === false}
            />
            <label for="ownerShipNo" style={{ cursor: "pointer" }}>
              No
            </label>
          </div>
          {/* {OwnerShip !== "yes" && ( */}
          <>
            {" "}
            <PopupSubHead>
              Any claims made in your existing policy?
            </PopupSubHead>
            <div class="vehRadioWrap">
              <input
                type="radio"
                id="claimMadeYes"
                name="claimMade"
                value="yes"
                ref={register}
                defaultChecked={
                  temp_data?.expPolicy === false ||
                  temp_data?.noClaimMade === false
                }
              />
              <label for="claimMadeYes" style={{ cursor: "pointer" }}>
                Yes
              </label>
              <input
                type="radio"
                id="ownerNo"
                name="claimMade"
                value="no"
                ref={register}
                defaultChecked={
                  temp_data?.expPolicy === true ||
                  temp_data?.noClaimMade === true
                }
              />
              <label for="ownerNo" style={{ cursor: "pointer" }}>
                No
              </label>
            </div>
          </>
          {(temp_data?.expiry && OwnerShip !== "yes" && ncbvoid && expPolicy !== "yes") ? (
            <>
              <div
                class="popupSubHead ncsSubHeadNo"
                style={{
                  display: "block",
                  marginBottom: "12px",
                  visibility:
                    temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y"
                      ? "hidden"
                      : "visible",
                }}
              >
                {temp_data?.newCar
                  ? "Select your Transfered No Claim Bonus (NCB)"
                  : "Please select your existing NCB"}
              </div>
              <div
                class="vehRadioWrap ncsPercentCheck"
                style={{
                  display: lessthan767 ? "flex" : "block",
                  marginBottom: "12px",
                  justifyContent: "space-between",
                }}
              >
                {temp_data?.corporateVehiclesQuoteRequest?.isRenewal !== "Y" &&
                  myOrderedNcbList.map((item, index) => (
                    <>
                      <input
                        type="radio"
                        id={item?.ncbId}
                        name="existinNcb"
                        value={`${item?.discountRate}%`}
                        ref={register}
                        defaultChecked={
                          temp_data?.ncb === `${item?.discountRate}%`
                        }
                      />
                      <label for={item?.ncbId} style={{ cursor: "pointer" }}>
                        {item?.discountRate}%
                      </label>
                    </>
                  ))}
              </div>

              <EligText>
                <div>
                  Your new NCB is set to{" "}
                  {(ncbValue || temp_data?.ncb) && temp_data?.prevShortTerm * 1
                    ? ncbValue || temp_data?.ncb
                    : getNewNcb(ncbValue || temp_data?.ncb)}
                </div>
              </EligText>
            </>
          ) : expPolicy === "yes" && OwnerShip === "yes" && !ncbvoid ? (
            <EligText>
              Since you have made claim in your existing policy & changed
              ownership, your NCB will be reset to 0%
            </EligText>
          ) : expPolicy === "yes" && OwnerShip === "yes" ? (
            <EligText>
              Since you have made claim in your existing policy & changed
              ownership, your NCB will be reset to 0%
            </EligText>
          ) : OwnerShip === "yes" ? (
            <EligText>
              Since you have changed ownership, your NCB will be reset to 0%
            </EligText>
          ) : !ncbvoid ? (
            <EligText>
              Since your policy has expired over 90 days ago, your NCB will be
              reset to 0%
            </EligText>
          ) : (
            <EligText>
              Since you have made claim in your existing policy, your NCB will
              be reset to 0%
            </EligText>
          )}
          <PaymentTermRadioWrap>
            <ApplyButton
              onClick={() => [
                navigator &&
                  navigator?.vibrate &&
                  navigator.vibrate([100, 0, 50]),
                onSubmit(),
              ]}
            >
              APPLY
            </ApplyButton>
          </PaymentTermRadioWrap>
        </Row>
      </Conatiner>
    </>
  );
  return !lessthan767 ? (
    <Popup
      height={lessthan450 ? "100%" : "auto"}
      width={lessthan450 ? "100%" : "400px"}
      show={show}
      onClose={onClose}
      content={content}
      position="middle"
      top="top"
      left={lessthan963 ? "50%" : "80%"}
      //	noBlur="true"
    />
  ) : (
    <>
      <React.Fragment key={"bottom"} style={{ borderRadius: "5% 5% 0% 0%" }}>
        <Drawer
          anchor={"bottom"}
          open={drawer}
          onClose={() => {
            setDrawer(false);
            onClose(false);
          }}
          onOpen={() => setDrawer(true)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <MobileDrawerBody>
            <CloseButton
              onClick={() => {
                setDrawer(false);
                onClose(false);
              }}
            >
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ height: " 25px" }}
              >
                <path
                  fill={"#000"}
                  d="M12,2c-5.53,0 -10,4.47 -10,10c0,5.53 4.47,10 10,10c5.53,0 10,-4.47 10,-10c0,-5.53 -4.47,-10 -10,-10Zm5,13.59l-1.41,1.41l-3.59,-3.59l-3.59,3.59l-1.41,-1.41l3.59,-3.59l-3.59,-3.59l1.41,-1.41l3.59,3.59l3.59,-3.59l1.41,1.41l-3.59,3.59l3.59,3.59Z"
                ></path>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
            </CloseButton>
            {content}
          </MobileDrawerBody>
        </Drawer>
      </React.Fragment>

      <GlobalStyle />
    </>
  );
};

// PropTypes
NCBPopup.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

// DefaultTypes
NCBPopup.defaultProps = {
  show: false,
  onClose: () => {},
};

const GlobalStyle = createGlobalStyle`
body {
	.MuiDrawer-paperAnchorBottom {
		border-radius: 3% 3% 0px 0px;
		z-index: 99999 !important;
	}
	.css-1u2w381-MuiModal-root-MuiDrawer-root {
    z-index: 100000 !important;
  }
}
`;

const Conatiner = styled.div`
  padding: 30px;
  .vehRadioWrap input:checked + label {
    // font-family: "Inter-SemiBold";
    background-color: ${({ theme }) =>
      theme.QuoteBorderAndFont?.checkBoxAndToggleBackground || "#000"};
    color: #fff;
    box-shadow: none;
    border: none;
  }
`;

const PaymentTermTitle = styled.div`
  float: left;
  width: 100%;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 16px;
  line-height: 20px;
  color: #333;
  padding-bottom: 10px;
  border-bottom: solid 1px #e3e4e8;
`;

const PopupSubTitle = styled.div`
  float: left;
  width: 100%;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  font-size: 14px;
  line-height: 20px;
  color: #333;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const PopupSubHead = styled.div`
  float: left;
  width: 100%;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 14px;
  line-height: 17px;
  color: #333;
  margin-bottom: 12px;
`;

const ApplyButton = styled.button`
  width: 117px;
  height: 32px;
  background-color: ${({ theme }) => theme.QuotePopups?.color || "#bdd400"};
  border: ${({ theme }) => theme.QuotePopups?.border || "1px solid #bdd400"};
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme?.FilterConatiner?.clearAllTextColor || " #000"};
  /* text-transform: uppercase; */
  margin: 0;
  float: right;
  border-radius: ${({ theme }) =>
    theme.QuoteBorderAndFont?.borderRadius || "50px"};
  &:hover {
    ${process.env.REACT_APP_BROKER === "TATA" &&
    `
    background: transparent;
    color: #0099f2;
  `}
  }
`;

const EligText = styled.div`
  float: left;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  width: 100%;
  border: dashed 1px #000;
  padding: 11px 13px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  margin-bottom: 24px;
  div {
    font-family: ${({ theme }) =>
      theme.QuoteBorderAndFont?.fontFamilyBold || "Inter-SemiBold"};
    font-size: 14px;
    line-height: 17px;
    margin-top: 0px;
  }
`;

const PaymentTermRadioWrap = styled.div`
  float: left;
  width: 100%;
`;

const MobileDrawerBody = styled.div`
  width: 100%;
  border-radius: 3px 3px 0px 0px;
`;
const CloseButton = styled.div`
  display: ${({ hiddenClose }) => (hiddenClose ? "none" : "block")};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1111;
  &:hover {
    text-decoration: none;
    color: #363636;
  }
`;

export default NCBPopup;
