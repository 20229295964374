import _ from "lodash";

export const bike = _.compact([
  process.env?.REACT_APP_BROKER === "ACE" ||
  process.env?.REACT_APP_BROKER === "OLA"
    ? "godigit"
    : false,
  process.env?.REACT_APP_BROKER === "OLA" ? "acko" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "reliance" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "iffco_tokio" : false,
]);
export const bikeTp = _.compact([
  process.env?.REACT_APP_BROKER === "ACE" ||
  process.env?.REACT_APP_BROKER === "OLA"
    ? "godigit"
    : false,
  process.env?.REACT_APP_BROKER === "OLA" ? "acko" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "reliance" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "iffco_tokio" : false,
]);
export const car = _.compact([
  process.env?.REACT_APP_BROKER === "ACE" ||
  process.env?.REACT_APP_BROKER === "OLA"
    ? "godigit"
    : false,
  process.env?.REACT_APP_BROKER === "OLA" ? "acko" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "tata_aig" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "reliance" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "iffco_tokio" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "reliance" : false,
]);
export const carTp = _.compact([
  process.env?.REACT_APP_BROKER === "ACE" ||
  process.env?.REACT_APP_BROKER === "OLA"
    ? "godigit"
    : false,
  process.env?.REACT_APP_BROKER === "OLA" ? "acko" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "tata_aig" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "reliance" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "iffco_tokio" : false,
]);
export const cv12 = _.compact([
  process.env?.REACT_APP_BROKER === "ACE" ||
  process.env?.REACT_APP_BROKER === "OLA"
    ? "godigit"
    : false,
  process.env?.REACT_APP_BROKER === "OLA" ? "acko" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "tata_aig" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "reliance" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "iffco_tokio" : false,
]);
export const cvTp = _.compact([
  process.env?.REACT_APP_BROKER === "ACE" ||
  process.env?.REACT_APP_BROKER === "OLA"
    ? "godigit"
    : false,
  process.env?.REACT_APP_BROKER === "OLA" ? "acko" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "tata_aig" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "reliance" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "iffco_tokio" : false,
]);
export const cv6 = _.compact([
  process.env?.REACT_APP_BROKER === "ACE" ||
  process.env?.REACT_APP_BROKER === "OLA"
    ? "godigit"
    : false,
  process.env?.REACT_APP_BROKER === "OLA" ? "acko" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "iffco_tokio" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "tata_aig" : false,
]);
export const cv3 = _.compact([
  process.env?.REACT_APP_BROKER === "ACE" ||
  process.env?.REACT_APP_BROKER === "OLA"
    ? "godigit"
    : false,
  process.env?.REACT_APP_BROKER === "OLA" ? "acko" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "iffco_tokio" : false,
  process.env?.REACT_APP_BROKER === "ACE" ? "tata_aig" : false,
]);
