import React, { useEffect, useState } from "react";
import { Row, Col, Spinner, Modal } from "react-bootstrap";
import _ from "lodash";
import { Button } from "components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { clear, Prefill, VerifyGodigitKyc } from "./proposal.slice";
import swal from "sweetalert";
import { ShareQuote, clear as clr } from "../../modules/Home/home.slice";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { useMediaPredicate } from "react-media-hook";
import { currencyFormater } from "utils";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const PaymentModal = (props) => {
  const { verifyCkycnum, ckycError } = useSelector((state) => state.proposal);
  const [submitData, setSubmitData] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = props?.enquiry_id;
  const token = props?.token;
  const type = props?.type;
  const lessthan768 = useMediaPredicate("(max-width: 768px)");
  const pre_payment_ckyc =
    process.env.REACT_APP_BROKER === "RB" ||
    process.env.REACT_APP_BROKER === "GRAM" ||
    process.env.REACT_APP_BROKER === "BAJAJ" ||
    process.env.REACT_APP_BROKER === "TATA" ||
    process.env.REACT_APP_BROKER === "ABIBL" ||
    process.env.REACT_APP_BROKER === "EPOCH" ||
    process.env.REACT_APP_BROKER === "POLICYERA" ||
    process.env.REACT_APP_BROKER === "SRIDHAR" ||
    process.env.REACT_APP_BROKER === "UIB" ||
    process.env.REACT_APP_BROKER === "SPA" ||
    process.env.REACT_APP_BROKER === "PINC" ||
    process.env.REACT_APP_BROKER === "ANALAH" ||
    process.env.REACT_APP_BROKER === "SRIYAH" ||
    process.env.REACT_APP_BROKER === "OLA" ||
    process.env.REACT_APP_BROKER === "ACE";

  const { temp_data, error_other, prefillLoad } = useSelector(
    (state) => state.proposal
  );
  const { share } = useSelector((state) => state.home);

  const CardData = !_.isEmpty(temp_data)
    ? temp_data?.userProposal?.additonalData
      ? temp_data?.userProposal?.additonalData
      : {}
    : {};

  useEffect(() => {
    props?.submit && setSubmitData(props?.submit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.submit]);

  useEffect(() => {
    if (props?.rsKycStatus?.kyc_status) {
      setSubmitData(props?.rsKycStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.rsKycStatus]);

  //load prefill data
  useEffect(() => {
    if (enquiry_id && props.show) {
      dispatch(Prefill({ enquiryId: enquiry_id }, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id, props.show]);

  // verify ckyc for godigit in RB
  const verifyCkyc = () => {
    setLoading(true);
    dispatch(
      VerifyGodigitKyc(
        {
          UserProductJourneyId: props.enquiry_id,
        },
        setLoading
      )
    );
  };

  useEffect(() => {
    if (
      verifyCkycnum &&
      pre_payment_ckyc &&
      props?.companyAlias === "godigit"
    ) {
      setSubmitData(verifyCkycnum);
      dispatch(clear("verifyCkycnum"));
    }
  }, [verifyCkycnum]);

  //onError
  useEffect(() => {
    if (error_other) {
      swal(
        "Error",
        props?.enquiry_id
          ? `${`Trace ID:- ${props?.enquiry_id}.\n Error Message:- ${error_other}`}`
          : error_other,
        "error"
      );
    }
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error_other]);

  return (
    <Modal
      {...props}
      size={temp_data?.userProposal?.isFinsallAvailable === "Y" ? "lg" : "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={"static"}
      keyboard={false}
    >
      {!submitData?.kyc_status &&
      temp_data?.userProposal?.isCkycVerified !== "Y" &&
      props?.ckycPresent &&
      ((pre_payment_ckyc && props?.companyAlias === "godigit") ||
        props?.companyAlias === "royal_sundaram" ||
        props?.companyAlias === "kotak" ||
        props?.companyAlias === "raheja" ||
        props?.companyAlias === "new_india") ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              CKYC Verification Pending
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading ? (
              <p style={{ textAlign: "center" }}>Please Wait...</p>
            ) : (
              <>
                <p style={{ textAlign: "center" }}>
                  It seems your CKYC verification is not complete.
                </p>
                {submitData?.kyc_message && (
                  <p style={{ textAlign: "center" }}>
                    {submitData?.kyc_message}
                  </p>
                )}
                <p style={{ textAlign: "center" }}>
                  {!!submitData?.is_kyc_url_present ? (
                    <>
                      <a
                        style={{
                          textDecoration: "underline",
                        }}
                        target={
                          props?.companyAlias === "godigit" ||
                          props?.companyAlias === "raheja" ||
                          props?.companyAlias === "new_india" 
                            ? "_blank"
                            : "_self"
                        }
                        href={submitData?.kyc_url || ""}
                      >
                        Click Here
                      </a>{" "}
                      to verify
                    </>
                  ) : (
                    "No url provided by insurance company for CKYC verification.Please contact partner."
                  )}
                </p>
                {pre_payment_ckyc && props?.companyAlias === "godigit" && (
                  <p style={{ textAlign: "center" }}>
                    If Already verified then{" "}
                    <span
                      onClick={verifyCkyc}
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    >
                      Proceed
                    </span>
                  </p>
                )}
              </>
            )}
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation Required
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {!_.isEmpty(temp_data?.userProposal) && !prefillLoad ? (
              <div>
                <p>{`Your new policy will start from ${
                  temp_data?.userProposal?.policyStartDate
                    ? `${
                        temp_data?.userProposal?.policyStartDate?.split("-")[0]
                      }/${
                        temp_data?.userProposal?.policyStartDate?.split("-")[1]
                      }/${
                        temp_data?.userProposal?.policyStartDate?.split("-")[2]
                      }`
                    : "N/A"
                }.`}</p>
                <p>{`The total premium payable is ₹ ${currencyFormater(
                  Number(temp_data?.userProposal?.finalPayableAmount) || 0
                )}.`}</p>
                <p>Do you wish to proceed?</p>
              </div>
            ) : (
              <p>
                <Spinner animation="border" size="sm" /> Please wait.
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              buttonStyle="outline-solid"
              onClick={() => {
                return [
                  document?.getElementById("shareQuotes1") &&
                    document?.getElementById("shareQuotes1").click(),
                  props?.onHide(),
                ];
              }}
              hex1={
                Theme?.paymentConfirmation?.Button?.hex1
                  ? Theme?.paymentConfirmation?.Button?.hex1
                  : "#4ca729"
              }
              hex2={
                Theme?.paymentConfirmation?.Button?.hex2
                  ? Theme?.paymentConfirmation?.Button?.hex2
                  : "#4ca729"
              }
              borderRadius="5px"
              color={
                Theme?.PaymentConfirmation?.buttonTextColor
                  ? Theme?.PaymentConfirmation?.buttonTextColor
                  : "white"
              }
              style={{ ...(lessthan768 && { width: "100%" }) }}
              shadow={"none"}
            >
              <text
                style={{
                  fontSize: "15px",
                  padding: "-20px",
                  margin: "-20px -5px -20px -5px",
                  fontWeight: "400",
                }}
              >
                {lessthan768 ? "Share Payment Link" : ""}
                <i
                  className={`fa fa-share-alt ${lessthan768 ? "ml-2" : ""}`}
                ></i>
              </text>
            </Button>
            {temp_data?.userProposal?.isFinsallAvailable === "Y" && (
              <Button
                type="submit"
                buttonStyle="outline-solid"
                onClick={() => props?.payment("finsal")}
                hex1={
                  Theme?.paymentConfirmation?.Button?.hex1
                    ? Theme?.paymentConfirmation?.Button?.hex1
                    : "#4ca729"
                }
                hex2={
                  Theme?.paymentConfirmation?.Button?.hex2
                    ? Theme?.paymentConfirmation?.Button?.hex2
                    : "#4ca729"
                }
                borderRadius="5px"
                color={
                  Theme?.PaymentConfirmation?.buttonTextColor
                    ? Theme?.PaymentConfirmation?.buttonTextColor
                    : "white"
                }
                style={{ ...(lessthan768 && { width: "100%" }) }}
              >
                <text
                  style={{
                    fontSize: "15px",
                    padding: "-20px",
                    margin: "-20px -5px -20px -5px",
                    fontWeight: "400",
                  }}
                >
                  {"Pay with Finsall"}
                  {/* <i className="fa fa-arrow-circle-right ml-2"></i> */}
                </text>
              </Button>
            )}
            <Button
              type="submit"
              buttonStyle="outline-solid"
              onClick={() => props?.payment()}
              hex1={
                Theme?.paymentConfirmation?.Button?.hex1
                  ? Theme?.paymentConfirmation?.Button?.hex1
                  : "#4ca729"
              }
              hex2={
                Theme?.paymentConfirmation?.Button?.hex2
                  ? Theme?.paymentConfirmation?.Button?.hex2
                  : "#4ca729"
              }
              borderRadius="5px"
              color={
                Theme?.PaymentConfirmation?.buttonTextColor
                  ? Theme?.PaymentConfirmation?.buttonTextColor
                  : "white"
              }
              style={{ ...(lessthan768 && { width: "100%" }) }}
              shadow={"none"}
            >
              <text
                style={{
                  fontSize: "15px",
                  padding: "-20px",
                  margin: "-20px -5px -20px -5px",
                  fontWeight: "400",
                }}
              >
                {"Proceed to payment"}
                <i className="fa fa-arrow-circle-right ml-2"></i>
              </text>
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default PaymentModal;
