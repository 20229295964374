/* eslint-disable */
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Button, ErrorMsg } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Row, Col, Form, ToggleButton, Button as Btn } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { FormGroupTag, ButtonGroupTag, H4Tag2, ColDiv } from "../style.js";
import DateInput from "../DateInput";
import _, { forEach } from "lodash";
import { numOnly, toDate as DateUtil, downloadFile } from "utils";
import { subYears, toDate } from "date-fns";
import {
  getGender,
  Pincode,
  clear,
  getOccupation,
  VerifyCkycnum,
  Save,
  gender as clearGender,
  GetOrgFields,
} from "../proposal.slice";
import CkycInfo from "../ckyc-info";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { ShareQuote } from "modules/Home/home.slice";
import {
  Identities,
  identitiesCompany,
  ckycResponse,
  otherResponse,
} from "./data";
import Checkbox from "components/inputs/checkbox/checkbox";
import FilePicker from "components/filePicker/filePicker";
import swal from "sweetalert";
import OTPPopup from "../otp";
import { useIdleTimer } from "react-idle-timer";
import Popup from "components/Popup/Popup";
import SummaryProposal from "../summary/summary-proposal.js";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme1 = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

/*---------------date config----------------*/
const AdultCheck = subYears(new Date(Date.now() - 86400000), 18);
/*-----x---------date config-----x----------*/

const OwnerCard = ({
  onSubmitOwner,
  owner,
  CardData,
  conditionChk,
  Theme,
  lessthan768,
  lessthan376,
  enquiry_id,
  fields,
  type,
  token,
  setOwner,
  tempOwner,
  poi_file,
  setpoi_file,
  poa_file,
  setpoa_file,
  form60,
  setForm60,
  photo,
  setPhoto,
  loading,
  setResubmit,
  uploadFile,
  setuploadFile,
  resubmit,
  verifiedData,
  setVerifiedData,
  fileUploadError,
}) => {
  const {
    gender,
    pincode: pin,
    occupation,
    temp_data,
    verifyCkycnum,
    ckycFields,
    ckycError,
    orgFields,
  } = useSelector((state) => state.proposal);
  const dispatch = useDispatch();
  const { validationConfig } = useSelector((state) => state.home);

  /*--------------ckyc states-----------------*/
  const [identity, setIdentity] = useState();
  const [poi_identity, setpoi_identity] = useState();
  const [poa_identity, setpoa_identity] = useState();
  const [ckycTypeValue, setCkycTypeValue] = useState();
  const [ckycValue, setckycValue] = useState();
  const [poi, setPoi] = useState(false);
  const [poa, setPoa] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show1, setShow1] = useState(false);
  const [poi_disabled, setPoi_disabled] = useState(false);
  const [poa_disabled, setPoa_disabled] = useState(false);
  const [otp_id, setOtp_id] = useState();
  const [isRedirected, setIsRedirected] = useState(false);
  const [relationType, setRelationType] = useState(false);
  const [isCkycDetailsRejected, setisCkycDetailsRejected] = useState(false);
  const [customerDetails, setCustomerDetails] = useState();
  /*------x-------ckyc states---------x--------*/

  /*----------------Validation Schema---------------------*/
  const yupValidate = yup.object(
    temp_data?.selectedQuote?.isRenewal === "Y"
      ? {
          addressLine1: yup.string().required("Address1 is Required").trim(),
          addressLine2: yup.string().trim(),
          ...((process.env.REACT_APP_BROKER !== "OLA" ||
            fields.includes("email")) && {
            email: yup
              .string()
              .email("Please enter valid email id")
              .min(2, "Please enter valid email id")
              .max(50, "Must be less than 50 chars")
              .required("Email id is required")
              .trim(),
          }),
          mobileNumber: yup
            .string()
            .required("Mobile No. is required")
            .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
            .min(10, "Mobile No. should be 10 digits")
            .max(10, "Mobile No. should be 10 digits"),
          ...(Number(temp_data?.ownerTypeId) === 1
            ? {
                fullName: yup
                  .string()
                  .required("Name is required")
                  .matches(/^([A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .min(1, "Minimum 1 chars required")
                  .trim(),
                firstName: yup
                  .string()
                  .required("First Name is required")
                  .matches(/^([[A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .min(1, "Minimum 1 chars required")
                  .max(50, "First Name can be upto 50 chars")
                  .trim(),
                lastName: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .max(50, "Last Name can be upto 50 chars")
                  .matches(/^([A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .trim(),
              }
            : {
                firstName: yup
                  .string()
                  .required("Name is required")
                  .matches(
                    /^([[0-9A-Za-z\s.'&_+@!#,-])+$/,
                    "Entered value is invalid"
                  )
                  .min(1, "Minimum 1 char required")
                  .max(50, "First Name can be upto 50 chars")
                  .trim(),
                lastName: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  // .required("Name is required")
                  .min(1, "Minimum 1 char required")
                  .max(50, "Last Name can be upto 50 chars")
                  .matches(/^([A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .trim(),
              }),
          ...(fields.includes("dob") &&
            Number(temp_data?.ownerTypeId) === 1 && {
              dob: yup.string().required("DOB is required"),
            }),
          ...(Number(temp_data?.ownerTypeId) === 2 && {
            cinNumber: yup
              .string()
              // .required("DOB is required")
              .matches(
                /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
                "Invalid CIN Number"
              ),
          }),
          ...(fields.includes("fatherName") &&
            !fields.includes("relationType") &&
            ckycValue === "NO" && {
              fatherName: yup
                .string()
                .nullable()
                .transform((v, o) => (o === "" ? null : v))
                .trim()
                .required("Father's Name is required"),
            }),
          ...(fields.includes("ckyc") && ckycValue === "YES"
            ? {
                ckycNumber: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .trim()
                  .required("CKYC Number is required"),
              }
            : {}),
          ...(fields.includes("ckyc") &&
          (fields.includes("poi") || poi) &&
          ckycValue === "NO" &&
          uploadFile
            ? poi_identity === "drivingLicense"
              ? {
                  poi_drivingLicense: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .trim()
                    .required("Driving License is required"),
                }
              : poi_identity === "panNumber"
              ? {
                  poi_panNumber: yup
                    .string()
                    .required("PAN is required")
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(
                      /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                      "PAN number invalid"
                    )
                    .trim(),
                }
              : poi_identity === "gstNumber"
              ? {
                  poi_gstNumber: yup
                    .string()
                    .required("GST is required")
                    .matches(
                      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                      "GST number invalid"
                    )
                    .trim(),
                }
              : poi_identity === "voterId"
              ? {
                  poi_voterId: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^([a-zA-Z]){3}([0-9]){7}?$/g, "Voter ID Invalid")
                    .trim()
                    .required("Voter ID is required"),
                }
              : poi_identity === "passportNumber"
              ? {
                  poi_passportNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(
                      /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/gi,
                      "Passport Number Invalid"
                    )
                    .trim()
                    .required("Passport Number is required"),
                }
              : poi_identity === "aadharNumber"
              ? {
                  poi_aadharNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid")
                    .trim()
                    .required("Adhaar Number is required"),
                }
              : {}
            : {}),
          ...(fields.includes("ckyc") &&
          (fields.includes("poa") || poa) &&
          ckycValue === "NO"
            ? poa_identity === "drivingLicense" && uploadFile
              ? {
                  poa_drivingLicense: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .trim()
                    .required("Driving License is required"),
                }
              : poa_identity === "panNumber"
              ? {
                  poa_panNumber: yup
                    .string()
                    .required("PAN is required")
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(
                      /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                      "PAN number invalid"
                    )
                    .trim(),
                }
              : poa_identity === "gstNumber"
              ? {
                  poa_gstNumber: yup
                    .string()
                    .required("GST is required")
                    .matches(
                      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                      "GST number invalid"
                    )
                    .trim(),
                }
              : poa_identity === "voterId"
              ? {
                  poa_voterId: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^([a-zA-Z]){3}([0-9]){7}?$/g, "Voter ID Invalid")
                    .trim()
                    .required("Voter ID is required"),
                }
              : poa_identity === "passportNumber"
              ? {
                  poa_passportNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(
                      /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/gi,
                      "Passport Number Invalid"
                    )
                    .trim()
                    .required("Passport Number is required"),
                }
              : poa_identity === "aadharNumber"
              ? {
                  poa_aadharNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid")
                    .trim()
                    .required("Adhaar Number is required"),
                }
              : {}
            : {}),
          ...(fields.includes("ckyc")
            ? identity === "drivingLicense" && ckycValue === "NO" && !uploadFile
              ? {
                  drivingLicense: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .trim()
                    .required("Driving License is required"),
                }
              : identity === "voterId" && ckycValue === "NO"
              ? {
                  voterId: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^([a-zA-Z]){3}([0-9]){7}?$/g, "Voter ID Invalid")
                    .trim()
                    .required("Voter ID is required"),
                }
              : identity === "passportNumber" && ckycValue === "NO"
              ? {
                  passportNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(
                      /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/gi,
                      "Passport Number Invalid"
                    )
                    .trim()
                    .required("Passport Number is required"),
                }
              : identity === "aadharNumber" && ckycValue === "NO"
              ? {
                  aadharNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid")
                    .trim()
                    .required("Adhaar Number is required"),
                }
              : {}
            : {}),
          ...(!fields.includes("panNumber")
            ? {}
            : poi_identity !== "panNumber" &&
              (temp_data?.selectedQuote?.companyAlias === "magma" ||
                temp_data?.selectedQuote?.companyAlias === "bajaj_allianz" ||
                temp_data?.selectedQuote?.companyAlias === "tata_aig" ||
                (ckycValue === "NO" &&
                  fields.includes("ckyc") &&
                  identity === "panNumber") ||
                (temp_data?.selectedQuote?.companyAlias === "icici_lombard" &&
                  temp_data?.selectedQuote?.totalPayableAmountWithAddon >
                    100000) ||
                temp_data?.selectedQuote?.companyAlias === "edelweiss" ||
                temp_data?.selectedQuote?.companyAlias === "reliance" ||
                temp_data?.selectedQuote?.companyAlias === "royal_sundaram" ||
                temp_data?.selectedQuote?.companyAlias === "united_india" ||
                temp_data?.selectedQuote?.companyAlias === "oriental")
            ? {
                panNumber: yup
                  .string()
                  .required("PAN is required")
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .matches(
                    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                    "PAN number invalid"
                  )
                  .trim(),
              }
            : {
                panNumber: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .matches(
                    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                    "PAN number invalid"
                  )
                  .trim(),
              }),

          ...(!fields.includes("gstNumber")
            ? {}
            : fields.includes("ckyc") &&
              identity === "gstNumber" &&
              ckycValue === "NO"
            ? {
                gstNumber: yup
                  .string()
                  .required("GST is required")
                  .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    "GST number invalid"
                  )
                  .trim(),
              }
            : {
                gstNumber: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    "GST number invalid"
                  )
                  .trim(),
              }),
          pincode: yup
            .string()
            .required("Pincode is Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Must be 6 digits")
            .max(6, "Must be 6 digits")
            .trim(),
          city: yup
            .string()
            .required("Required")
            .matches(/[1-9A-Za-z]/, "City Required"),
          state: yup
            .string()
            .required("Required")
            .matches(/[1-9A-Za-z]/, "State Required"),
        }
      : {
          pincode: yup
            .string()
            .required("Pincode is Required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, "Must be 6 digits")
            .max(6, "Must be 6 digits")
            .trim(),
          addressLine1: yup.string().required("Address1 is Required").trim(),
          addressLine2: yup.string().trim(),
          city: yup
            .string()
            .required("Required")
            .matches(/[1-9A-Za-z]/, "City Required"),
          state: yup
            .string()
            .required("Required")
            .matches(/[1-9A-Za-z]/, "State Required"),
          cityId: yup.string().required("Required"),
          stateId: yup.string().required("Required"),
          ...(!fields.includes("gstNumber")
            ? {}
            : identity && identity === "gstNumber" && ckycValue === "NO"
            ? {
                gstNumber: yup
                  .string()
                  .required("GST is required")
                  .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    "GST number invalid"
                  )
                  .trim(),
              }
            : {
                gstNumber: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    "GST number invalid"
                  )
                  .trim(),
              }),
          ...(Number(temp_data?.ownerTypeId) === 2 && {
            cinNumber: yup
              .string()
              .matches(
                /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
                "Invalid CIN Number"
              ),
          }),
          ...(fields.includes("fatherName") &&
            !fields.includes("relationType") &&
            ckycValue === "NO" && {
              fatherName: yup
                .string()
                .nullable()
                .transform((v, o) => (o === "" ? null : v))
                .trim()
                .required("Father's Name is required"),
            }),

          ...(fields.includes("ckyc") && ckycValue === "YES"
            ? {
                ckycNumber: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .trim()
                  .required("CKYC Number is required"),
              }
            : {}),
          ...(fields.includes("ckyc") && ckycValue === "NO" && !uploadFile
            ? identity === "drivingLicense"
              ? {
                  drivingLicense: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .trim()
                    .required("Driving License is required"),
                }
              : identity === "voterId" && ckycValue === "NO"
              ? {
                  voterId: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^([a-zA-Z]){3}([0-9]){7}?$/g, "Voter ID Invalid")
                    .trim()
                    .required("Voter ID is required"),
                }
              : identity === "passportNumber" && ckycValue === "NO"
              ? {
                  passportNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(
                      /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/gi,
                      "Passport Number Invalid"
                    )
                    .trim()
                    .required("Passport Number is required"),
                }
              : identity === "aadharNumber" && ckycValue === "NO"
              ? {
                  aadharNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid")
                    .trim()
                    .required("Adhaar Number is required"),
                }
              : {}
            : {}),
          ...(fields.includes("ckyc") &&
          (fields.includes("poi") || poi) &&
          ckycValue === "NO" &&
          uploadFile
            ? poi_identity === "drivingLicense"
              ? {
                  poi_drivingLicense: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    // .matches(
                    //   /^(([A-Z]{2}[0-9]{2})|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
                    //   "Driving License Invalid"
                    // )
                    .trim()
                    .required("Driving License is required"),
                }
              : poi_identity === "panNumber"
              ? {
                  poi_panNumber: yup
                    .string()
                    .required("PAN is required")
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(
                      /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                      "PAN number invalid"
                    )
                    .trim(),
                }
              : poi_identity === "gstNumber"
              ? {
                  poi_gstNumber: yup
                    .string()
                    .required("GST is required")
                    .matches(
                      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                      "GST number invalid"
                    )
                    .trim(),
                }
              : poi_identity === "voterId"
              ? {
                  poi_voterId: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^([a-zA-Z]){3}([0-9]){7}?$/g, "Voter ID Invalid")
                    .trim()
                    .required("Voter ID is required"),
                }
              : poi_identity === "passportNumber"
              ? {
                  poi_passportNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(
                      /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/gi,
                      "Passport Number Invalid"
                    )
                    .trim()
                    .required("Passport Number is required"),
                }
              : poi_identity === "aadharNumber"
              ? {
                  poi_aadharNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid")
                    .trim()
                    .required("Adhaar Number is required"),
                }
              : {}
            : {}),
          ...(fields.includes("ckyc") &&
          (fields.includes("poa") || poa) &&
          ckycValue === "NO" &&
          uploadFile
            ? poa_identity === "drivingLicense"
              ? {
                  poa_drivingLicense: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    // .matches(
                    //   /^(([A-Z]{2}[0-9]{2})|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
                    //   "Driving License Invalid"
                    // )
                    .trim()
                    .required("Driving License is required"),
                }
              : poa_identity === "panNumber"
              ? {
                  poa_panNumber: yup
                    .string()
                    .required("PAN is required")
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(
                      /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                      "PAN number invalid"
                    )
                    .trim(),
                }
              : poa_identity === "gstNumber"
              ? {
                  poa_gstNumber: yup
                    .string()
                    .required("GST is required")
                    .matches(
                      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                      "GST number invalid"
                    )
                    .trim(),
                }
              : poa_identity === "voterId"
              ? {
                  poa_voterId: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^([a-zA-Z]){3}([0-9]){7}?$/g, "Voter ID Invalid")
                    .trim()
                    .required("Voter ID is required"),
                }
              : poa_identity === "passportNumber"
              ? {
                  poa_passportNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(
                      /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/gi,
                      "Passport Number Invalid"
                    )
                    .trim()
                    .required("Passport Number is required"),
                }
              : poa_identity === "aadharNumber"
              ? {
                  poa_aadharNumber: yup
                    .string()
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v))
                    .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid")
                    .trim()
                    .required("Adhaar Number is required"),
                }
              : {}
            : {}),

          ...(!fields.includes("panNumber")
            ? {}
            : poi_identity !== "panNumber" &&
              ((ckycValue === "NO" &&
                fields.includes("ckyc") &&
                identity === "panNumber" &&
                !uploadFile) ||
                (temp_data?.selectedQuote?.companyAlias === "icici_lombard" &&
                  temp_data?.selectedQuote?.totalPayableAmountWithAddon >
                    100000) ||
                temp_data?.selectedQuote?.companyAlias === "edelweiss" ||
                temp_data?.selectedQuote?.companyAlias === "reliance" ||
                temp_data?.selectedQuote?.companyAlias === "royal_sundaram" ||
                temp_data?.selectedQuote?.companyAlias === "united_india" ||
                temp_data?.selectedQuote?.companyAlias === "magma" ||
                temp_data?.selectedQuote?.companyAlias === "tata_aig" ||
                temp_data?.selectedQuote?.companyAlias === "bajaj_allianz" ||
                temp_data?.selectedQuote?.companyAlias === "oriental")
            ? {
                panNumber: yup
                  .string()
                  .required("PAN is required")
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .matches(
                    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                    "PAN number invalid"
                  )
                  .trim(),
              }
            : {
                panNumber: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .matches(
                    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                    "PAN number invalid"
                  )
                  .trim(),
              }),
          ...((process.env.REACT_APP_BROKER !== "OLA" ||
            fields.includes("email")) && {
            email: yup
              .string()
              .email("Please enter valid email id")
              .min(2, "Please enter valid email id")
              .max(50, "Must be less than 50 chars")
              .required("Email id is required")
              .trim(),
          }),
          mobileNumber: yup
            .string()
            .required("Mobile No. is required")
            .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
            .min(10, "Mobile No. should be 10 digits")
            .max(10, "Mobile No. should be 10 digits"),
          ...(fields.includes("dob") &&
            Number(temp_data?.ownerTypeId) === 1 && {
              dob: yup.string().required("DOB is required"),
            }),
          ...(fields.includes("gender") &&
            Number(temp_data?.ownerTypeId) === 1 && {
              gender: yup
                .string()
                .required("Gender is required")
                .matches(
                  /^([A-Za-z\s])+$/,
                  "Salutation and gender doesn't match"
                ),
            }),
          ...(Number(temp_data?.ownerTypeId) === 1
            ? {
                fullName: yup
                  .string()
                  .required("Name is required")
                  .matches(/^([A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .min(2, "Minimum 2 chars required")
                  .trim(),
                firstName: yup
                  .string()
                  .required("First Name is required")
                  .matches(/^([[A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .min(1, "Minimum 2 chars required")
                  .max(50, "Must be less than 50 chars")
                  .trim(),
                lastName: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .max(50, "Must be less than 50 chars")
                  .matches(/^([A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .trim(),
              }
            : {
                firstName: yup
                  .string()
                  .required("Name is required")
                  .matches(
                    /^([[0-9A-Za-z\s.'&_+@!#,-])+$/,
                    "Must be only AlphaNumeric"
                  )
                  .min(1, "Minimum 1 char required")
                  .max(50, "Must be less than 50 chars")
                  .trim(),
                lastName: yup
                  .string()
                  .nullable()
                  .transform((v, o) => (o === "" ? null : v))
                  .min(1, "Minimum 1 char required")
                  .max(50, "Must be less than 50 chars")
                  .matches(/^([A-Za-z\s.'])+$/, "Must contain only alphabets")
                  .trim(),
              }),
          ...(fields.includes("maritalStatus") &&
            Number(temp_data?.ownerTypeId) === 1 && {
              maritalStatus: yup
                .string()
                .required("Marital Status is required"),
            }),
          ...(fields.includes("occupation") &&
            Number(temp_data?.ownerTypeId) === 1 && {
              occupation: yup
                .string()
                .required("Occupation is required")
                .matches(/[^@]/, "Occupation is required"),
            }),
        }
  );
  /*----------x------Validation Schema----------x-----------*/

  const { handleSubmit, register, errors, control, reset, setValue, watch } =
    useForm({
      defaultValues: !_.isEmpty(owner)
        ? owner
        : !_.isEmpty(CardData?.owner)
        ? CardData?.owner
        : {},
      resolver: yupResolver(yupValidate),
      mode: "onBlur",
      reValidateMode: "onBlur",
    });

  const companyAlias = !_.isEmpty(temp_data?.selectedQuote)
    ? temp_data?.selectedQuote?.companyAlias
    : "";

  const DOB = watch("dob");
  const emailId = watch("email");
  const allFieldsReadOnly =
    temp_data?.selectedQuote?.isRenewal === "Y" ||
    temp_data?.corporateVehiclesQuoteRequest?.rolloverRenewal === "Y";

  //clear existing data
  useEffect(() => {
    dispatch(clearGender([]));
  }, []);

  //prefill Api
  useEffect(() => {
    if (_.isEmpty(owner) && !_.isEmpty(CardData?.owner)) {
      reset(CardData?.owner);
      CardData?.owner?.maritalStatus &&
        setRadioValue2(CardData?.owner?.maritalStatus);
      setRadioValue(CardData?.owner?.gender);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CardData.owner]);

  //Personal Details
  useEffect(() => {
    if (_.isEmpty(owner) && _.isEmpty(CardData?.owner)) {
      if (Number(temp_data?.ownerTypeId) === 1) {
        setValue(
          "firstName",
          Number(temp_data?.ownerTypeId) === 1 ? temp_data?.firstName : ""
        );

        temp_data?.firstName &&
          setValue(
            "fullName",
            `${temp_data?.firstName}${
              temp_data?.lastName ? ` ${temp_data?.lastName}` : ""
            }`
          );
      }
      setValue(
        "lastName",
        Number(temp_data?.ownerTypeId) === 1
          ? temp_data?.lastName
          : temp_data?.firstName && temp_data?.lastName
          ? `${temp_data?.firstName} ${temp_data?.lastName}`
          : ""
      );
      setValue("email", temp_data?.emailId);
      setValue("mobileNumber", temp_data?.mobileNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data]);

  //Get Organisation type
  useEffect(() => {
    if (
      temp_data?.ownerTypeId === 2 &&
      fields?.includes("organizationType") &&
      temp_data?.selectedQuote?.companyAlias
    )
      dispatch(
        GetOrgFields({ company_alias: temp_data?.selectedQuote?.companyAlias })
      );
  }, [temp_data?.ownerTypeId, fields]);

  //occupation
  const [isOccupationCalled, setOccupationCalled] = useState(false);
  useEffect(() => {
    if (
      companyAlias &&
      Number(temp_data?.ownerTypeId) === 1 &&
      !isOccupationCalled
    ) {
      dispatch(
        getOccupation({ companyAlias: companyAlias, enquiryId: enquiry_id })
      );
      setOccupationCalled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAlias, temp_data?.ownerTypeId]);

  const Occupations = !_.isEmpty(occupation)
    ? occupation?.map(({ name, id }) => {
        return { name, id };
      })
    : [];

  //setting hidden i/p
  const OccupationType =
    watch("occupation") || owner?.occupation || CardData?.owner?.occupation;

  const OccupationName = Occupations.filter(
    ({ id }) => id === OccupationType
  )[0]?.name;

  const handleOnIdle = () => {
    companyAlias === "edelweiss" &&
      isRedirected &&
      swal("Info", "Page update is required", "info").then(() => {
        window.location.reload();
      });
  };

  //timer for edelweiss
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 2 * 1000 * 60,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  /*--------- CKYC---------*/
  //sbi popup for customer detail cinfornation
  const setValueCustomerDetails = () => {
    Object.keys(tempOwner)?.forEach((each) => {
      setValue(each, tempOwner[each]);
    });
    //verified data consists keys that should not be editable on ckyc verification
    customerDetails && setVerifiedData(Object.keys(customerDetails));
    customerDetails &&
      Object.keys(customerDetails)?.forEach((each) => {
        customerDetails[each] && setValue(each, customerDetails[each]);
      });
    //Gender Index check
    customerDetails?.gender &&
      !_.isEmpty(
        _.compact(
          gender?.map((item) =>
            item?.code === customerDetails?.gender ? item : ""
          )
        )
      ) &&
      setRadioValue(
        _.compact(
          gender?.map((item) =>
            item?.code === customerDetails?.gender ? item : ""
          )
        )[0]?.code
      );
    if (
      customerDetails &&
      (Object.keys(customerDetails)?.includes("addressLine1") ||
        Object.keys(customerDetails)?.includes("addressLine2") ||
        Object.keys(customerDetails)?.includes("addressLine3"))
    ) {
      setValue(
        "address",
        `${customerDetails?.addressLine1 ? customerDetails?.addressLine1 : ""}${
          customerDetails?.addressLine2
            ? ` ${customerDetails?.addressLine2}`
            : ""
        }${
          customerDetails?.addressLine3
            ? ` ${customerDetails?.addressLine3}`
            : ""
        }`
      );
      setValue("isCkycDetailsRejected", "N");

      setVerifiedData([...Object.keys(customerDetails), "address"]);
    }
    swal(
      "Success",
      "CKYC verified. Please proceed to continue",
      "success"
    ).then(() => {
      dispatch(clear("verifyCkycnum"));
    });
    setResubmit(true);
  };

  const confirmHandler = () => {
    setisCkycDetailsRejected(false);
    setValueCustomerDetails();
    setShow(false);
  };

  const rejectHandler = () => {
    setShow(false);
    setisCkycDetailsRejected(true);
    setResubmit(true);
  };

  const onClose = () => {
    setShow(false);
  };

  const content = (
    <>
      <div style={{ padding: lessthan768 ? "25px 5px 5px 5px" : "25px 30px" }}>
        <p
          className="mt-1"
          style={{
            fontSize: "16px",
            fontWeight: "600",
            margin: "10px 0 0 20px",
          }}
        >
          Action Required
        </p>
        <div className="p-2 m-1">
          <div>
            <SummaryProposal
              popup={true}
              data={customerDetails}
              lessthan768={lessthan768}
              fields={fields}
              type="header"
            />
          </div>
          <div
            className="w-100 d-flex justify-content-end mt-3"
            style={{ paddingRight: lessthan768 ? "15px" : "" }}
          >
            <Btn
              className="mx-2"
              size="sm"
              variant="success"
              onClick={confirmHandler}
            >
              Use this data
            </Btn>
            <Btn size="sm" variant="danger" onClick={rejectHandler}>
              Discard CKYC
            </Btn>
          </div>
        </div>
      </div>
    </>
  );
  //ckyc declarations
  const ownerIdentity = watch("identity");
  const poiIdentity = watch("poi_identity");
  const poaIdentity = watch("poa_identity");
  const relationTypeIp = watch("relationType");

  useEffect(() => {
    setIdentity(ownerIdentity);
    setpoi_identity(poiIdentity);
    setpoa_identity(poaIdentity);
    identity && setValue(identity, "");
    poi_identity && setValue(poi_identity, "");
    poa_identity && setValue(poa_identity, "");
  }, [ownerIdentity, poiIdentity, poaIdentity]);

  useEffect(() => {
    if (relationTypeIp) {
      setRelationType(relationTypeIp);
    }
  }, [relationTypeIp]);

  var poa_number = watch(`poa_${poa_identity}`);
  var poi_number = watch(`poi_${poi_identity}`);
  //if poa and poi are same
  useEffect(() => {
    if (
      poiIdentity === poaIdentity &&
      ((selectedpoiIdentity &&
        poi_number &&
        poi_number?.length === selectedpoiIdentity?.length) ||
        (selectedpoaIdentity &&
          poa_number &&
          poa_number?.length === selectedpoaIdentity?.length))
    ) {
      if (poi_number?.length === selectedpoiIdentity?.length) {
        setPoa_disabled(true);
        setPoi_disabled(false);
        setValue(`poa_${poa_identity}`, poi_number);
      } else if (poa_number?.length === selectedpoaIdentity?.length) {
        setPoa_disabled(false);
        setPoi_disabled(true);
        setValue(`poi_${poi_identity}`, poa_number);
      } else {
        setPoa_disabled(false);
        setPoi_disabled(false);
        setValue(`poi_${poi_identity}`, "");
        setValue(`poa_${poa_identity}`, "");
      }
    } else {
      setPoa_disabled(false);
      setPoi_disabled(false);
    }
  }, [poiIdentity, poaIdentity, poi_number, poa_number]);

  //fields to be disabled after ckyc verification completion
  const fieldsNonEditable =
    (temp_data?.userProposal?.isCkycVerified === "Y" || resubmit) &&
    (companyAlias === "hdfc_ergo" ||
      companyAlias === "royal_sundaram" ||
      companyAlias === "edelweiss" ||
      companyAlias === "reliance" ||
      companyAlias === "icici_lombard");
  //fields not to be disabled for some ics
  const fieldEditable = true;
  // if we are getting ckyc verified then from userproposal thenstomer_details
  const prefillAndResubmit =
    companyAlias === "liberty_videocon" || companyAlias === "edelweiss";

  useEffect(() => {
    if (temp_data?.userProposal?.isCkycVerified === "Y" && prefillAndResubmit) {
      const icverifiedData =
        typeof temp_data?.userProposal?.ckycMetaData === "string"
          ? JSON.parse(temp_data?.userProposal?.ckycMetaData)
          : temp_data?.userProposal?.ckycMetaData;
      if (icverifiedData?.customer_details) {
        setVerifiedData(Object.keys(icverifiedData?.customer_details));
        Object.keys(icverifiedData?.customer_details)?.forEach((each) => {
          icverifiedData?.customer_details[each] &&
            setValue(each, icverifiedData?.customer_details[each]);
        });
        companyAlias === "edelweiss" && setResubmit(true);
      } else {
        companyAlias === "edelweiss" && setResubmit(true);
      }
    }
  }, [temp_data]);

  const ckycPresent = watch("ckycPresent");
  const ckycNumber = watch("ckycNumber");

  const selectedIdentity = ownerIdentity
    ? Number(temp_data?.ownerTypeId) === 1
      ? Identities(companyAlias, uploadFile).find(
          (each) => each.id === identity
        )
      : identitiesCompany(companyAlias, uploadFile)?.find(
          (each) => each.id === identity
        )
    : "";
  const selectedpoiIdentity =
    poiIdentity && temp_data
      ? Number(temp_data?.ownerTypeId) === 1
        ? Identities(companyAlias, uploadFile).find(
            (each) => each.id === poi_identity
          )
        : identitiesCompany(companyAlias, uploadFile)?.find(
            (each) => each.id === poi_identity
          )
      : "";
  const selectedpoaIdentity =
    poaIdentity && temp_data
      ? Number(temp_data?.ownerTypeId) === 1
        ? Identities(companyAlias, uploadFile).find(
            (each) => each.id === poa_identity
          )
        : identitiesCompany(companyAlias, uploadFile)?.find(
            (each) => each.id === poa_identity
          )
      : "";
  const verificationNumber = !!identity && watch(identity);

  useEffect(() => {
    setCkycTypeValue(verificationNumber);
  }, [verificationNumber]);

  const ckycTypes =
    Number(temp_data?.ownerTypeId) === 1
      ? Identities(companyAlias, uploadFile)
      : identitiesCompany(companyAlias, uploadFile);

  const handler = (HyperKycResult) => {
    console.log("HyperKycResult", HyperKycResult);
    HyperKycResult.status === "error"
      ? swal(
          "Error",
          HyperKycResult?.errorMessage || "Something went wrong",
          "error"
        )
      : HyperKycResult.status === "auto_approved" ||
        HyperKycResult.status === "auto_declined" ||
        HyperKycResult.status === "needs_review"
      ? dispatch(
          VerifyCkycnum({
            HyperKycResult,
            companyAlias,
            mode: "ckyc",
            enquiryId: enquiry_id,
          })
        )
      : "";
  };

  //united india || Oriental ckyc
  const HyperVergeFn = (accessToken) => {
    const workflowId =
      companyAlias === "united_india" ? "kyc_flow" : "portal_kyc_flow";
    if (companyAlias === "united_india" || companyAlias === "oriental") {
      const transactionId = `${enquiry_id}`;
      const hyperKycConfig = new window.HyperKycConfig(
        accessToken,
        workflowId,
        transactionId
      );
      window.HyperKYCModule.launch(hyperKycConfig, handler);
    }
  };

  //CKYC session in new tab
  const newTab =
    companyAlias === "universal_sompo" ||
    companyAlias === "edelweiss" ||
    companyAlias === "godigit";
  //form data is required for liberty
  const isForm =
    companyAlias === "liberty_videocon" || companyAlias === "future_generali";
  //Success/error handling after ckyc verification status
  useEffect(() => {
    //Error after ckyc number verification failure
    if (
      verifyCkycnum &&
      !verifyCkycnum?.verification_status &&
      (companyAlias === "united_india" || companyAlias === "oriental")
    ) {
      if (verifyCkycnum?.accessToken) {
        HyperVergeFn(verifyCkycnum?.accessToken);
      } else {
        swal(
          "Error",
          verifyCkycnum?.message || "Something went wrong",
          "error",
          { closeOnClickOutside: false }
        );
      }
    }
    //Error after ckyc number verification failure
    else if (
      verifyCkycnum &&
      !verifyCkycnum?.verification_status &&
      ckycValue === "YES"
    ) {
      verifyCkycnum?.redirection_url && companyAlias === "cholla_mandalam"
        ? swal(
            "Error",
            "Ckyc number verification failed. Redirecting to our portal.",
            "error",
            { closeOnClickOutside: false }
          ).then(() => {
            setIsRedirected(true);
            const metaData = verifyCkycnum?.meta_data;
            downloadFile(
              verifyCkycnum?.redirection_url,
              false,
              newTab,
              isForm,
              metaData
            );
          })
        : swal({
            title: "Confirm Action",
            text: verifyCkycnum?.message
              ? `${verifyCkycnum?.message} Try other options or try again.`
              : "Ckyc number verification failed. Please try with PAN number",
            icon: "info",
            buttons: {
              cancel: "Go Back",
              catch: {
                text: "Try other method",
                value: "confirm",
              },
            },
            dangerMode: true,
            closeOnClickOutside: false,
          }).then((caseValue) => {
            switch (caseValue) {
              case "confirm":
                setckycValue("NO");
                setValue("ckycNumber", "");
                setValue(identity, "");
                break;
              default:
            }
          });
      //Error after step1 failure.
      //If redirection URL is present then user is redirected to vendor's ckyc portal
    } else if (
      verifyCkycnum &&
      !verifyCkycnum?.verification_status &&
      ckycValue === "NO" &&
      verifyCkycnum?.redirection_url
    ) {
      swal({
        title: "Confirm Action",
        text: verifyCkycnum?.message
          ? `${verifyCkycnum?.message}. Redirecting to our portal.`
          : "Ckyc number verification failed. Redirecting to our portal.",
        icon: "info",
        buttons: {
          cancel: "Go Back",
          catch: {
            text: "Redirect for verification",
            value: "confirm",
          },
        },
        dangerMode: true,
        closeOnClickOutside: false,
      }).then((caseValue) => {
        switch (caseValue) {
          case "confirm":
            setIsRedirected(true);
            const metaData = verifyCkycnum?.meta_data;
            downloadFile(
              verifyCkycnum?.redirection_url,
              false,
              newTab,
              isForm,
              metaData
            );
            break;
          default:
        }
      });
      //On getting otp
    } else if (
      verifyCkycnum &&
      !verifyCkycnum?.verification_status &&
      ckycValue === "NO" &&
      !verifyCkycnum?.redirection_url &&
      !!verifyCkycnum?.otp_id
    ) {
      swal("Success", "OTP sent successfully", "success").then(() => {
        setOtp_id(verifyCkycnum?.otp_id);
        setShow1(true);
      });
      //Step 2 failure
    }
    // else if (
    //   verifyCkycnum &&
    //   !verifyCkycnum?.verification_status &&
    //   ckycValue === "NO" &&
    //   companyAlias === "sbi"
    // ) {
    //   process.env.REACT_APP_PROD !== "YES"
    //     ? setShow2(true)
    //     : setOwner(tempOwner);
    // }
    else if (
      verifyCkycnum &&
      !verifyCkycnum?.verification_status &&
      ckycValue === "NO" &&
      fields?.includes("fileupload") &&
      !uploadFile
    ) {
      show1 && setShow1(false);
      otp_id && setOtp_id();
      swal({
        title: "Confirm Action",
        text: verifyCkycnum?.message
          ? `${verifyCkycnum?.message} Try other options or try again.`
          : "Ckyc number verification failed. Please upload the required files for verification.",
        icon: "info",
        buttons: {
          cancel: "Go Back",
          catch: {
            text: "Try other method",
            value: "confirm",
          },
        },
        dangerMode: true,
        closeOnClickOutside: false,
      }).then((caseValue) => {
        switch (caseValue) {
          case "confirm":
            setuploadFile(true);
            break;
          default:
        }
      });
      //Step 3 failure
    } else if (
      verifyCkycnum &&
      !verifyCkycnum?.verification_status &&
      ckycValue === "NO" &&
      fields?.includes("fileupload") &&
      uploadFile
    ) {
      swal("Error", "Ckyc number verification failed.", "error", {
        closeOnClickOutside: false,
      });
    } else if (
      verifyCkycnum &&
      !verifyCkycnum?.verification_status &&
      !verifyCkycnum?.ckyc_id &&
      !verifyCkycnum?.otp_id &&
      !!verifyCkycnum?.message
    ) {
      swal("Error", verifyCkycnum?.message, "error", {
        closeOnClickOutside: false,
      });
    } else if (
      verifyCkycnum &&
      !!verifyCkycnum?.verification_status &&
      !!verifyCkycnum?.customer_details
    ) {
      //if ckyc is verified and ic is sending back data then that data should be prefilled and those fields should be blocked from editing
      if (companyAlias === "sbi") {
        setCustomerDetails(verifyCkycnum?.customer_details);
        setShow(true);
      } else {
        show1 && setShow1(false);
        otp_id && setOtp_id();
        Object.keys(tempOwner)?.forEach((each) => {
          setValue(each, tempOwner[each]);
        });
        //verified data consists keys that should not be editable on ckyc verification
        setVerifiedData(Object.keys(verifyCkycnum?.customer_details));

        Object.keys(verifyCkycnum?.customer_details)?.forEach((each) => {
          verifyCkycnum?.customer_details[each] &&
            setValue(each, verifyCkycnum?.customer_details[each]);
        });
        if (verifyCkycnum?.meta_data?.ckyc_status === "CKYCInProgress") {
          swal(
            "Documents uploaded Successfully",
            "Insurance company is reviewing your documents and will update you the status.",
            "info"
          ).then(() => {
            dispatch(clear("verifyCkycnum"));
          });
        } else {
          swal(
            "Success",
            "CKYC verified. Your Details may have been updated using your ckyc data. Please review and continue",
            "success"
          ).then(() => {
            dispatch(clear("verifyCkycnum"));
          });
        }
        setResubmit(true);
      }
    } else if (verifyCkycnum && !!verifyCkycnum?.verification_status) {
      show1 && setShow1(false);
      otp_id && setOtp_id();
      setOwner(tempOwner);
      dispatch(clear("verifyCkycnum"));
    }
  }, [verifyCkycnum]);

  useEffect(() => {
    ckycError &&
      swal("Error", ckycError, "error", {
        closeOnClickOutside: false,
      }).then(() => {
        dispatch(clear("ckycError"));
        setValue("ckycNumber", "");
        setValue(identity, "");
      });
  }, [ckycError]);

  //ckyc value reset
  useEffect(() => {
    if (ckycValue === "YES" && !_.isEmpty(fields)) {
      setuploadFile(false);
      setpoa_file();
      setpoi_file();
      setForm60();
      setPoa(false);
      setPoi(false);
    } else if (
      ckycValue === "NO" &&
      (companyAlias === "bajaj_allianz" ||
        companyAlias === "tata_aig" ||
        companyAlias === "shriram") &&
      (fields?.includes("poa") ||
        fields?.includes("poi") ||
        fields?.includes("fileupload")) &&
      !_.isEmpty(fields)
    ) {
      setuploadFile(true);
      fields?.includes("poa") && setPoa(true);
      fields?.includes("poi") && setPoi(true);
    }
  }, [ckycValue, fields]);

  //Setting CKYC Number as default on initial load of component and prefill if present
  const ckycValuePresent =
    watch("isckycPresent") || CardData?.owner?.isckycPresent;
  useEffect(() => {
    companyAlias === "bajaj_allianz" ||
    companyAlias === "tata_aig" ||
    companyAlias === "united_india" ||
    companyAlias === "raheja" ||
    companyAlias === "oriental"
      ? setckycValue("NO")
      : ckycValuePresent
      ? setckycValue(ckycValuePresent)
      : setckycValue("YES");
  }, [CardData?.owner]);

  /*----x---- CKYC----x----*/

  /*----------------gender config-----------------------*/
  const [isGenderCalled, setGenderCalled] = useState(false);
  useEffect(() => {
    if (
      companyAlias &&
      Number(temp_data?.ownerTypeId) === 1 &&
      !isGenderCalled
    ) {
      dispatch(
        getGender({ companyAlias: companyAlias, enquiryId: enquiry_id })
      );
      setGenderCalled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAlias, temp_data?.ownerTypeId]);

  const radios = !_.isEmpty(gender)
    ? gender?.map(({ code, name }, index) => {
        return { name, val: String(index), value: code, code };
      })
    : [];

  const [radioValue, setRadioValue] = useState(watch("gender"));

  /*--------- marital status---------*/
  const maritalStatus = [
    { name: "Single", value: "1" },
    { name: "Married", value: "2" },
  ];

  const [radioValue2, setRadioValue2] = useState(
    watch("maritalStatus") ||
      (process.env.REACT_APP_BROKER === "OLA" ? "Single" : "Married")
  );

  /*----x---- marital status----x----*/
  const PinCode = watch("pincode");
  //get state-city
  useEffect(() => {
    if (PinCode?.length === 6 && companyAlias) {
      dispatch(
        Pincode({
          companyAlias: companyAlias,
          pincode: PinCode,
          enquiryId: enquiry_id,
        })
      );
    } else {
      dispatch(clear("pincode"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PinCode]);

  useEffect(() => {
    if (!_.isEmpty(pin)) {
      setValue("state", pin?.state?.state_name);
      setValue("stateId", pin?.state?.state_id);
    } else {
      setValue("state", "");
      setValue("stateId", "");
      setValue("city", "");
      setValue("cityId", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  // auto selecting if only one option is present.
  const city =
    watch("city") ||
    owner?.city ||
    CardData?.owner?.city ||
    (!_.isEmpty(pin?.city) &&
      pin?.city?.length === 1 &&
      pin?.city[0]?.city_name);

  useEffect(() => {
    if (city) {
      const city_id = pin?.city?.filter(({ city_name }) => city_name === city);
      !_.isEmpty(city_id)
        ? setValue("cityId", city_id[0]?.city_id)
        : setValue("cityId", owner?.cityId || CardData?.owner?.cityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, pin]);

  //pre-selection of toggle inputs (gender -male, marital status - married)
  const GenderIP = watch("gender");
  const MaritalIP = watch("maritalStatus");
  //temporary gender fix
  const genderCodeCheck = !_.isEmpty(
    _.compact(
      gender?.map((item) =>
        item?.code === temp_data?.userProposal?.gender ? item : ""
      )
    )
  );

  useEffect(() => {
    setTimeout(() => {
      if (
        _.isEmpty(owner) &&
        _.isEmpty(CardData?.owner) &&
        !GenderIP &&
        !temp_data?.userProposal?.gender &&
        !temp_data?.userProposal?.genderName &&
        !_.isEmpty(temp_data)
      ) {
        !MaritalIP &&
          !temp_data?.userProposal?.maritalStatus &&
          (process.env.REACT_APP_BROKER === "OLA"
            ? setRadioValue2("Single")
            : setRadioValue2("Married"));
        Number(temp_data?.ownerTypeId) &&
          !_.isEmpty(radios) &&
          !GenderIP &&
          setRadioValue(
            !_.isEmpty(gender)
              ? !_.isEmpty(
                  gender?.map(
                    ({ name }) =>
                      name.toLowerCase() === "male" ||
                      name.toLowerCase() === "m"
                  )
                )
                ? gender?.filter(
                    ({ name }) =>
                      name.toLowerCase() === "male" ||
                      name.toLowerCase() === "m"
                  )[0]?.code
                : ""
              : ""
          );
        let genderHidden = !_.isEmpty(gender)
          ? !_.isEmpty(
              gender?.map(
                ({ name }) =>
                  name.toLowerCase() === "male" || name.toLowerCase() === "m"
              )
            )
            ? gender?.filter(
                ({ name }) =>
                  name.toLowerCase() === "male" || name.toLowerCase() === "m"
              )[0]?.name
            : ""
          : "";
        genderHidden && setValue("genderName", genderHidden);
      }
      if (
        (_.isEmpty(owner) &&
          _.isEmpty(CardData?.owner) &&
          !GenderIP &&
          temp_data?.userProposal?.genderName) ||
        (!_.isEmpty(gender) && CardData?.owner?.gender && !genderCodeCheck)
      ) {
        //Gender Index check
        let genderCode =
          !_.isEmpty(
            _.compact(
              gender?.filter(
                (item) =>
                  item?.name.toLowerCase() ===
                  temp_data?.userProposal?.genderName.toLowerCase()
              )
            )
          ) &&
          setRadioValue(
            _.compact(
              gender?.filter(
                (item) =>
                  item?.name.toLowerCase() ===
                  temp_data?.userProposal?.genderName.toLowerCase()
              )
            )[0]?.code
          );
      }
      if (
        _.isEmpty(owner) &&
        _.isEmpty(CardData?.owner) &&
        !!MaritalIP &&
        temp_data?.userProposal?.maritalStatus
      ) {
        setRadioValue2(temp_data?.userProposal?.maritalStatus);
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CardData?.owner, radios, owner]);

  //ongrid prefill
  useEffect(() => {
    if (_.isEmpty(CardData?.owner) && _.isEmpty(owner)) {
      !_.isEmpty(temp_data) &&
        setTimeout(() => {
          if (
            temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
              "adrila" &&
            temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType === "C"
          ) {
            (temp_data?.userProposal?.firstName ||
              temp_data?.userProposal?.lastName) &&
              setValue(
                "firstName",
                `${
                  temp_data?.userProposal?.firstName
                    ? temp_data?.userProposal?.firstName
                    : ""
                }${
                  temp_data?.userProposal?.lastName
                    ? temp_data?.userProposal?.lastName
                    : ""
                }`
              );
          } else {
            temp_data?.userProposal?.firstName &&
              setValue("firstName", temp_data?.userProposal?.firstName);
            temp_data?.userProposal?.lastName &&
              setValue("lastName", temp_data?.userProposal?.lastName);
          }
          ((temp_data?.userProposal?.firstName &&
            temp_data?.userProposal?.lastName) ||
            temp_data?.userProposal?.fullName) &&
            setValue(
              "fullName",
              `${
                temp_data?.userProposal?.fullName
                  ? temp_data?.userProposal?.fullName
                  : `${temp_data?.userProposal?.firstName} ${temp_data?.userProposal?.lastName}`
              }`
            );
          (temp_data?.userProposal?.addressLine1 ||
            temp_data?.userProposal?.addressLine2 ||
            temp_data?.userProposal?.addressLine3) &&
            setValue(
              "address",
              `${
                temp_data?.userProposal?.addressLine1
                  ? temp_data?.userProposal?.addressLine1
                  : ""
              }${
                temp_data?.userProposal?.addressLine2
                  ? ` ${temp_data?.userProposal?.addressLine2}`
                  : ""
              }${
                temp_data?.userProposal?.addressLine3
                  ? ` ${temp_data?.userProposal?.addressLine3}`
                  : ""
              }`
            );
          temp_data?.userProposal?.pincode &&
            setValue("pincode", temp_data?.userProposal?.pincode);
          temp_data?.userProposal?.dob &&
            setValue("dob", temp_data?.userProposal?.dob);
          temp_data?.userProposal?.genderName &&
            setValue("genderName", temp_data?.userProposal?.genderName);
          temp_data?.userProposal?.gender &&
            setValue("gender", temp_data?.userProposal?.gender);
          temp_data?.userProposal?.gstNumber &&
            setValue("gstNumber", temp_data?.userProposal?.gstNumber);
          temp_data?.userProposal?.panNumber &&
            setValue("panNumber", temp_data?.userProposal?.panNumber);
          temp_data?.userProposal?.occupation &&
            setValue("occupation", temp_data?.userProposal?.occupation);
          temp_data?.userProposal?.occupationName &&
            setValue("occupationName", temp_data?.userProposal?.occupationName);
        }, 500);
    }
  }, [temp_data?.userProposal]);

  const prevOwnerType = watch("prevOwnerType");

  useEffect(() => {
    if (
      !_.isEmpty(CardData?.owner) &&
      !_.isEmpty(temp_data) &&
      prevOwnerType &&
      temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType !==
        prevOwnerType
    ) {
      setTimeout(() => {
        //resetting fName and lName when prevOwnerType is diff from quote Page
        setValue("firstName", "");
        setValue("lastName", "");
        //Change the prevOwnerType
        setValue(
          "prevOwnerType",
          temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType
        );
      }, 0);
    }
  }, [temp_data, CardData, prevOwnerType]);

  //ACE lead SMS
  const mobileNoLead = watch("mobileNumber");

  const [memonisedNo, setMemonisedNo] = useState(false);
  useEffect(() => {
    if (
      process.env.REACT_APP_BROKER === "ACE" &&
      mobileNoLead &&
      mobileNoLead.length === 10 &&
      memonisedNo * 1 !== mobileNoLead * 1 &&
      CardData?.owner?.mobileNumber * 1 !== mobileNoLead * 1 &&
      owner?.mobileNumber * 1 !== mobileNoLead * 1
    ) {
      setMemonisedNo(mobileNoLead);
      dispatch(
        ShareQuote({
          enquiryId: enquiry_id,
          notificationType: "sms",
          domain: `http://${window.location.hostname}`,
          type: "Aceleadsms",
          mobileNo: mobileNoLead,
          productName: temp_data?.selectedQuote?.productName,
        })
      );
    }
  }, [mobileNoLead]);

  //splitting fullname
  const FullName = watch("fullName");
  useEffect(() => {
    if (FullName) {
      let FullnameCheck = FullName.split(" ");
      if (!_.isEmpty(FullnameCheck) && FullnameCheck?.length === 1) {
        let fname = FullnameCheck[0];
        setValue("firstName", fname);
      }
      if (!_.isEmpty(FullnameCheck) && FullnameCheck?.length > 1) {
        let FullnameLen = FullnameCheck?.length;
        let fname = FullnameCheck.slice(0, -1).join(" ");
        let lname = FullnameCheck.slice(-1)[0];
        setValue("firstName", fname);
        setValue("lastName", lname);
      } else {
        setValue("lastName", "");
      }
    } else {
      if (
        !_.isEmpty(temp_data?.userProposal) &&
        temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType !== "C"
      ) {
        setValue("firstName", "");
        setValue("lastName", "");
      }
    }
  }, [FullName]);

  const address = watch("address");

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmitOwner)} autoComplete="none">
        <Row
          style={{
            margin: lessthan768
              ? "-60px -30px 20px -30px"
              : "-60px -20px 20px -30px",
          }}
          className="p-2"
        >
          {Number(temp_data?.ownerTypeId) === 1 ? (
            <>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>{"Full Name"}</FormGroupTag>
                  <Form.Control
                    autoComplete="none"
                    type="text"
                    placeholder={"Enter Full Name"}
                    name="fullName"
                    maxLength="100"
                    minlength="1"
                    ref={register}
                    readOnly={
                      allFieldsReadOnly ||
                      (resubmit && verifiedData?.includes("fullName")) ||
                      (watch("fullName") && fieldsNonEditable)
                    }
                    onBlur={(e) => (e.target.value = e.target.value.trim())}
                    onInput={(e) =>
                      (e.target.value =
                        e.target.value.length <= 1
                          ? ("" + e.target.value)
                              .toUpperCase()
                              .replace(/\s\s/gi, " ")
                          : e.target.value
                              .replace("..", ".")
                              .replace(/\s\s/gi, " "))
                    }
                    errors={
                      errors?.fullName || errors?.firstName || errors?.lastName
                    }
                    isInvalid={
                      errors?.fullName || errors?.firstName || errors?.lastName
                    }
                    size="sm"
                  />
                  {errors?.fullName || errors?.firstName || errors?.lastName ? (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.fullName?.message ||
                        errors?.firstName?.message ||
                        errors?.lastName?.message}
                    </ErrorMsg>
                  ) : (
                    <Form.Text className="text-muted">
                      <text style={{ color: "#bdbdbd" }}>
                        (Full Name as mentioned in RC copy)
                      </text>
                    </Form.Text>
                  )}
                </div>
                <input type="hidden" ref={register} name="firstName" />
                <input type="hidden" ref={register} name="lastName" />
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>
                    {Number(temp_data?.ownerTypeId) === 1
                      ? "First Name"
                      : "Company Name"}
                  </FormGroupTag>
                  <Form.Control
                    autoComplete="none"
                    type="text"
                    allFieldsReadOnly={allFieldsReadOnly}
                    placeholder={
                      Number(temp_data?.ownerTypeId) === 1
                        ? "Enter First Name"
                        : "Enter Company Name"
                    }
                    name="firstName"
                    maxLength="50"
                    minlength="2"
                    ref={register}
                    onInput={(e) =>
                      (e.target.value =
                        e.target.value.length <= 1
                          ? ("" + e.target.value).toUpperCase()
                          : e.target.value)
                    }
                    errors={errors?.firstName}
                    isInvalid={errors?.firstName}
                    size="sm"
                  />
                  {!!errors?.firstName && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.firstName?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
              {fields.includes("representativeName") && (
                <Col xs={12} sm={12} md={12} lg={6} xl={4} className="w-100">
                  <div className="py-2 w-100">
                    <FormGroupTag>
                      {Number(temp_data?.ownerTypeId) === 1
                        ? "Last Name"
                        : "Representative Name"}
                    </FormGroupTag>
                    <div className="d-flex w-100 fname">
                      <div
                        style={{ maxWidth: "100%", width: "100%" }}
                        className="fname1"
                      >
                        <Form.Control
                          ref={register}
                          errors={errors.lastName}
                          isInvalid={errors.lastName}
                          autoComplete="none"
                          name="lastName"
                          allFieldsReadOnly={allFieldsReadOnly}
                          type="text"
                          onInput={(e) =>
                            (e.target.value =
                              e.target.value.length <= 1
                                ? ("" + e.target.value).toUpperCase()
                                : e.target.value)
                          }
                          maxLength="50"
                          // minlength="2"
                          placeholder={
                            Number(temp_data?.ownerTypeId) === 1
                              ? "Enter Last Name"
                              : "Enter Name"
                          }
                          size="sm"
                        />
                        {!!errors?.lastName && (
                          <ErrorMsg fontSize={"12px"}>
                            {errors?.lastName?.message}
                          </ErrorMsg>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              )}
            </>
          )}
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <FormGroupTag>Mobile Number</FormGroupTag>
              <Form.Control
                name="mobileNumber"
                ref={register}
                type="tel"
                autoComplete="none"
                placeholder="Enter Mobile Number"
                errors={errors?.mobileNumber}
                isInvalid={errors?.mobileNumber}
                size="sm"
                onKeyDown={numOnly}
                readOnly={false}
                maxLength="10"
              />
              {!!errors?.mobileNumber && (
                <ErrorMsg fontSize={"12px"}>
                  {errors?.mobileNumber?.message}
                </ErrorMsg>
              )}
            </div>
          </Col>
          {(process.env.REACT_APP_BROKER !== "OLA" ||
            fields.includes("email")) && (
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>Email ID</FormGroupTag>
                <Form.Control
                  type="email"
                  autoComplete="none"
                  placeholder="Enter Email Id"
                  size="sm"
                  name="email"
                  maxLength="50"
                  readOnly={
                    false
                    // (resubmit && verifiedData?.includes("email")) ||
                    // (watch("email") && fieldsNonEditable)
                  }
                  ref={register}
                  errors={errors?.email}
                  isInvalid={errors?.email}
                />
                {!!errors?.email && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.email?.message}
                  </ErrorMsg>
                )}
              </div>
              <input type="hidden" ref={register} name="officeEmail" />
            </Col>
          )}
          {fields.includes("dob") && Number(temp_data?.ownerTypeId) === 1 && (
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <StyledDatePicker>
                <div className="py-2 dateTimeOne">
                  <FormGroupTag>Date of Birth</FormGroupTag>
                  <Controller
                    control={control}
                    name="dob"
                    render={({ onChange, onBlur, value, name }) => (
                      <DateInput
                        minDate={false}
                        maxDate={AdultCheck}
                        value={value}
                        name={name}
                        onChange={onChange}
                        readOnly={
                          (resubmit && verifiedData?.includes("dob")) ||
                          (watch("dob") && fieldsNonEditable)
                        }
                        ref={register}
                        selected={
                          DOB || owner?.dob || CardData?.owner?.dob
                            ? DateUtil(
                                DOB || owner?.dob || CardData?.owner?.dob
                              )
                            : false
                        }
                        dob={true}
                      />
                    )}
                  />
                  {!!errors?.dob && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.dob?.message}
                    </ErrorMsg>
                  )}
                </div>
              </StyledDatePicker>
            </Col>
          )}
          {fields.includes("fatherName") &&
            !fields.includes("relationType") &&
            ckycValue === "NO" && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>Father's Name</FormGroupTag>
                  <Form.Control
                    type="text"
                    autoComplete="none"
                    placeholder="Enter Father's Name"
                    size="sm"
                    name="fatherName"
                    maxLength="50"
                    readOnly={
                      (resubmit && verifiedData?.includes("fatherName")) ||
                      (watch("fatherName") && fieldsNonEditable)
                    }
                    ref={register}
                    errors={errors?.fatherName}
                    isInvalid={errors?.fatherName}
                  />
                  {!!errors?.fatherName && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.fatherName?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
            )}
          {fields.includes("relationType") &&
            ckycValue === "NO" &&
            uploadFile && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2 fname">
                  <FormGroupTag>Relation Type</FormGroupTag>
                  <Form.Control
                    as="select"
                    // readOnly={allFieldsReadOnly}
                    size="sm"
                    ref={register}
                    name={`relationType`}
                    errors={errors?.relationType}
                    isInvalid={errors?.relationType}
                    style={{ cursor: "pointer" }}
                  >
                    {[
                      { type: "Father", value: "fatherName" },
                      { type: "Mother", value: "motherName" },
                      { type: "Spouse", value: "spouseName" },
                    ].map(({ value, type }, index) => (
                      <option value={value}>{type}</option>
                    ))}
                  </Form.Control>
                  {!!errors?.city && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.city?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
            )}
          {fields.includes("relationType") &&
            watch("relationType") &&
            uploadFile &&
            ckycValue === "NO" && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>
                    {_.startCase(watch("relationType"))}
                  </FormGroupTag>
                  <Form.Control
                    type="text"
                    autoComplete="none"
                    placeholder={`Enter ${_.startCase(watch("relationType"))}`}
                    size="sm"
                    required
                    name={watch("relationType")}
                    maxLength="50"
                    readOnly={
                      (resubmit &&
                        verifiedData?.includes(watch("relationType"))) ||
                      (watch("relationType") && fieldsNonEditable)
                    }
                    ref={register}
                    errors={errors?.[`${watch("relationType")}`]}
                    isInvalid={errors?.[`${watch("relationType")}`]}
                  />
                  {!!errors?.[`${watch("relationType")}`] && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.[`${watch("relationType")}`]?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
            )}
          {!_.isEmpty(orgFields) ? (
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2 fname">
                <FormGroupTag>Organization Type</FormGroupTag>
                <Form.Control
                  as="select"
                  // readOnly={allFieldsReadOnly}
                  size="sm"
                  ref={register}
                  name={`organizationType`}
                  errors={errors?.organizationType}
                  isInvalid={errors?.organizationType}
                  style={{ cursor: "pointer" }}
                >
                  {orgFields.map(({ value, type }, index) => (
                    <option value={value}>{value}</option>
                  ))}
                </Form.Control>
                {!!errors?.organizationType && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.organizationType?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
          ) : (
            <noscript />
          )}
          {fields.includes("ckyc") && Number(temp_data?.ownerTypeId) === 2 && (
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <StyledDatePicker>
                <div className="py-2 dateTimeOne">
                  <FormGroupTag>Date of Incorporation</FormGroupTag>
                  <Controller
                    control={control}
                    name="dob"
                    render={({ onChange, onBlur, value, name }) => (
                      <DateInput
                        minDate={false}
                        maxDate={new Date()}
                        value={value}
                        name={name}
                        onChange={onChange}
                        readOnly={
                          (resubmit && verifiedData?.includes("dob")) ||
                          (watch("dob") && fieldsNonEditable)
                        }
                        ref={register}
                        selected={
                          DOB || owner?.dob || CardData?.owner?.dob
                            ? DateUtil(
                                DOB || owner?.dob || CardData?.owner?.dob
                              )
                            : false
                        }
                        incorporation={true}
                      />
                    )}
                  />
                  {!!errors?.dob && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.dob?.message}
                    </ErrorMsg>
                  )}
                </div>
              </StyledDatePicker>
            </Col>
          )}
          {fields.includes("gender") &&
            Number(temp_data?.ownerTypeId) === 1 && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <FormGroupTag style={{ paddingTop: "10px" }}>
                  Gender
                </FormGroupTag>
                <div className="" style={{ width: "100%", paddingTop: "2px" }}>
                  <ButtonGroupTag toggle style={{ width: "100%" }}>
                    {radios.map((radio, idx) => (
                      <ToggleButton
                        style={{
                          minWidth: "fill-available",
                          width: "fill-available",
                          minHeight: "32px",
                        }}
                        key={idx}
                        className={
                          radio.val === "0"
                            ? `mb-2 mr-4 index-key${idx}`
                            : `mb-2 index-key${idx}`
                        }
                        type="radio"
                        variant="secondary"
                        readOnly={
                          (allFieldsReadOnly && CardData?.owner?.genderName) ||
                          (resubmit && verifiedData?.includes("genderName")) ||
                          (CardData?.owner?.genderName && fieldsNonEditable)
                        }
                        ref={register}
                        size="sm"
                        tabIndex={"0"}
                        id={`index-key${idx}`}
                        onKeyDown={(e) => {
                          if (e.keyCode === 32 && !allFieldsReadOnly) {
                            e.preventDefault();
                            document.getElementById(`index-key${idx}`) &&
                              document
                                .getElementById(`index-key${idx}`)
                                .click();
                          }
                        }}
                        name="gender1"
                        value={radio.code}
                        checked={radioValue === radio.code}
                        onInput={() => setValue("genderName", radio.name)}
                        onChange={(e) => {
                          (!allFieldsReadOnly ||
                            !CardData?.owner?.genderName) &&
                            !(
                              CardData?.owner?.genderName && fieldsNonEditable
                            ) &&
                            setRadioValue(e.target.value);
                        }}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroupTag>
                </div>
                <input
                  type="hidden"
                  name="gender"
                  value={radioValue}
                  ref={register}
                />
                <input
                  type="hidden"
                  name="genderName"
                  // value={genderName}
                  ref={register}
                />
                {!!errors?.gender && (
                  <ErrorMsg fontSize={"12px"} style={{ marginTop: "-3px" }}>
                    {errors?.gender?.message}
                  </ErrorMsg>
                )}
              </Col>
            )}
          {fields && fields?.includes("ckyc") && (
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <FormGroupTag style={{ paddingTop: "10px" }}>
                Do you have CKYC Number?
              </FormGroupTag>
              <div className="" style={{ width: "100%", paddingTop: "2px" }}>
                <ButtonGroupTag toggle style={{ width: "100%" }}>
                  {["YES", "NO"].map((radio, idx) => (
                    <ToggleButton
                      style={{
                        width: "100%",
                        minHeight: "32px",
                      }}
                      key={idx}
                      className={`${idx === 0 ? "mr-4" : "mr-0"} "mb-2"`}
                      type="radio"
                      variant="secondary"
                      ref={register}
                      size="sm"
                      tabIndex={"0"}
                      name="ckycPresent"
                      value={radio}
                      checked={ckycValue === radio}
                      onInput={() => setValue("ckycValue", radio)}
                      onChange={(e) => {
                        !resubmit &&
                          companyAlias !== "tata_aig" &&
                          !(
                            CardData?.owner?.isckycPresent && fieldsNonEditable
                          ) &&
                          setckycValue(e.target.value);
                      }}
                    >
                      {_.capitalize(radio)}
                    </ToggleButton>
                  ))}
                </ButtonGroupTag>
              </div>
              <input
                type="hidden"
                name="isckycPresent"
                value={ckycValue}
                ref={register}
              />
              {
                <input
                  type="hidden"
                  name="isCkycDetailsRejected"
                  value={isCkycDetailsRejected ? "Y" : "N"}
                  ref={register}
                />
              }
              {!!errors?.ckycPresent && (
                <ErrorMsg fontSize={"12px"} style={{ marginTop: "-3px" }}>
                  {errors?.ckycPresent?.message}
                </ErrorMsg>
              )}
            </Col>
          )}
          {fields.includes("ckyc") && ckycValue === "NO" && !uploadFile && (
            <Col xs={12} sm={12} md={12} lg={6} xl={4} style={{ display: "" }}>
              <div className="py-2 fname">
                <FormGroupTag>CKYC Type</FormGroupTag>
                <Form.Control
                  as="select"
                  autoComplete="none"
                  size="sm"
                  ref={register}
                  name="identity"
                  readOnly={resubmit}
                  className="title_list"
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  {resubmit || (watch("identity") && fieldsNonEditable) ? (
                    <option style={{ cursor: "pointer" }} value={identity}>
                      {selectedIdentity?.name}
                    </option>
                  ) : !_.isEmpty(ckycFields?.ckyc_type) ? (
                    ckycFields?.ckyc_type.map(
                      ({ label, value, priority }, index) => (
                        <option style={{ cursor: "pointer" }} value={value}>
                          {label}
                        </option>
                      )
                    )
                  ) : Number(temp_data?.ownerTypeId) === 1 ? (
                    Identities(companyAlias, uploadFile).map(
                      ({ name, id, priority }, index) => (
                        <option style={{ cursor: "pointer" }} value={id}>
                          {name}
                        </option>
                      )
                    )
                  ) : (
                    identitiesCompany(companyAlias, uploadFile)?.map(
                      ({ name, id, priority }, index) => (
                        <option style={{ cursor: "pointer" }} value={id}>
                          {name}
                        </option>
                      )
                    )
                  )}
                </Form.Control>
              </div>
              {!!errors?.identity && (
                <ErrorMsg fontSize={"12px"} style={{ marginTop: "-3px" }}>
                  {errors?.identity?.message}
                </ErrorMsg>
              )}
            </Col>
          )}
          {fields.includes("ckyc") &&
            ckycValue === "NO" &&
            uploadFile &&
            (fields.includes("poi") || poi) && (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={4}
                style={{ display: "" }}
              >
                <div className="py-2 fname">
                  <FormGroupTag>Proof of Identity</FormGroupTag>
                  <Form.Control
                    as="select"
                    autoComplete="none"
                    size="sm"
                    ref={register}
                    name="poi_identity"
                    // readOnly={allFieldsReadOnly}
                    className="title_list"
                    style={{ cursor: "pointer" }}
                  >
                    {!_.isEmpty(ckycFields?.poilist)
                      ? ckycFields?.poilist.map(
                          ({ label, value, priority }, index) => (
                            <option
                              style={{ cursor: "pointer" }}
                              // selected={"@"}
                              value={value}
                            >
                              {label}
                            </option>
                          )
                        )
                      : Number(temp_data?.ownerTypeId) === 1
                      ? Identities(companyAlias, uploadFile, true, false)?.map(
                          ({ name, id, priority }, index) => (
                            <option
                              style={{ cursor: "pointer" }}
                              // selected={"@"}
                              value={id}
                            >
                              {name}
                            </option>
                          )
                        )
                      : identitiesCompany(
                          companyAlias,
                          uploadFile,
                          true,
                          false
                        )?.map(({ name, id, priority }, index) => (
                          <option
                            style={{ cursor: "pointer" }}
                            // selected={"@"}
                            value={id}
                          >
                            {name}
                          </option>
                        ))}
                  </Form.Control>
                </div>
                {!!errors?.poi_identity && (
                  <ErrorMsg fontSize={"12px"} style={{ marginTop: "-3px" }}>
                    {errors?.poi_identity?.message}
                  </ErrorMsg>
                )}
              </Col>
            )}
          {fields.includes("ckyc") &&
            uploadFile &&
            (fields.includes("poi") || poi) &&
            ckycTypes.map((each) => {
              if (
                ckycValue === "NO" &&
                each.id === poi_identity &&
                poi_identity !== "doi" &&
                poi_identity !== "form60"
              ) {
                return (
                  <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                    <div className="py-2">
                      <FormGroupTag>{selectedpoiIdentity?.name}</FormGroupTag>
                      <Form.Control
                        type="text"
                        autoComplete="none"
                        placeholder={`Enter ${selectedpoiIdentity?.name}`}
                        size="sm"
                        ref={register}
                        name={`poi_${poi_identity}`}
                        readOnly={poi_disabled}
                        maxLength={selectedpoiIdentity?.length}
                        onInput={(e) =>
                          (e.target.value = e.target.value
                            .replace(/[^A-Za-z0-9]/gi, "")
                            .toUpperCase())
                        }
                      />
                      {errors[`poi_${poi_identity}`] ? (
                        <ErrorMsg fontSize={"12px"}>
                          {errors[`poi_${poi_identity}`]?.message}
                        </ErrorMsg>
                      ) : (
                        <Form.Text className="text-muted">
                          {/* <text style={{ color: "#bdbdbd" }}>e.g 18AABCU9603R1ZM</text> */}
                        </Form.Text>
                      )}
                    </div>
                  </Col>
                );
              }
            })}
          {fields.includes("ckyc") &&
            uploadFile &&
            (fields.includes("poi") || poi) &&
            companyAlias !== "bajaj_allianz" &&
            companyAlias !== "tata_aig" && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>Upload File</FormGroupTag>
                  <FilePicker
                    file={poi_file}
                    setFile={setpoi_file}
                    watch={watch}
                    register={register}
                    name={selectedpoiIdentity?.fileKey}
                    id={selectedpoiIdentity?.fileKey}
                    placeholder={selectedpoiIdentity?.placeholder}
                  />
                  {!poi_file && fileUploadError && (
                    <ErrorMsg fontSize={"12px"}>
                      Please Upload document
                    </ErrorMsg>
                  )}
                </div>
              </Col>
            )}
          {fields.includes("ckyc") &&
            ckycValue === "NO" &&
            uploadFile &&
            (fields.includes("poa") || poa) && (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={4}
                style={{ display: "" }}
              >
                <div className="py-2 fname">
                  <FormGroupTag>Proof of Address</FormGroupTag>
                  <Form.Control
                    as="select"
                    autoComplete="none"
                    size="sm"
                    ref={register}
                    name="poa_identity"
                    // readOnly={allFieldsReadOnly}
                    className="title_list"
                    style={{ cursor: "pointer" }}
                  >
                    {!_.isEmpty(ckycFields?.poalist)
                      ? ckycFields?.poalist.map(
                          ({ label, value, priority }, index) => (
                            <option
                              style={{ cursor: "pointer" }}
                              // selected={"@"}
                              value={value}
                            >
                              {label}
                            </option>
                          )
                        )
                      : Number(temp_data?.ownerTypeId) === 1
                      ? Identities(companyAlias, uploadFile, false, true).map(
                          ({ name, id, priority }, index) => (
                            <option
                              style={{ cursor: "pointer" }}
                              // selected={"@"}
                              value={id}
                            >
                              {name}
                            </option>
                          )
                        )
                      : identitiesCompany(
                          companyAlias,
                          uploadFile,
                          false,
                          true
                        )?.map(({ name, id, priority }, index) => (
                          <option
                            style={{ cursor: "pointer" }}
                            // selected={"@"}
                            value={id}
                          >
                            {name}
                          </option>
                        ))}
                  </Form.Control>
                </div>
                {!!errors?.poa_identity && (
                  <ErrorMsg fontSize={"12px"} style={{ marginTop: "-3px" }}>
                    {errors?.poa_identity?.message}
                  </ErrorMsg>
                )}
              </Col>
            )}
          {fields.includes("ckyc") &&
            uploadFile &&
            (fields.includes("poa") || poa) &&
            ckycTypes.map((each) => {
              if (
                ckycValue === "NO" &&
                each.id === poa_identity &&
                poa_identity !== "doi" &&
                poa_identity !== "form60"
              ) {
                return (
                  <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                    <div className="py-2">
                      <FormGroupTag>{selectedpoaIdentity?.name}</FormGroupTag>
                      <Form.Control
                        type="text"
                        autoComplete="none"
                        placeholder={`Enter ${selectedpoaIdentity?.name}`}
                        size="sm"
                        ref={register}
                        name={`poa_${poa_identity}`}
                        readOnly={poa_disabled}
                        maxLength={selectedpoaIdentity?.length}
                        onInput={(e) =>
                          (e.target.value = e.target.value
                            .replace(/[^A-Za-z0-9]/gi, "")
                            .toUpperCase())
                        }
                      />
                      {errors[`poa_${poa_identity}`] ? (
                        <ErrorMsg fontSize={"12px"}>
                          {errors[`poa_${poa_identity}`]?.message}
                        </ErrorMsg>
                      ) : (
                        <Form.Text className="text-muted">
                          {/* <text style={{ color: "#bdbdbd" }}>e.g 18AABCU9603R1ZM</text> */}
                        </Form.Text>
                      )}
                    </div>
                  </Col>
                );
              }
            })}
          {fields.includes("ckyc") &&
            uploadFile &&
            (fields.includes("poa") || poa) && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>Upload File</FormGroupTag>
                  <FilePicker
                    file={poa_file}
                    setFile={setpoa_file}
                    watch={watch}
                    register={register}
                    name={selectedpoaIdentity?.fileKey}
                    id={selectedpoaIdentity?.fileKey}
                    placeholder={selectedpoaIdentity?.placeholder}
                  />
                  {!poa_file && fileUploadError && (
                    <ErrorMsg fontSize={"12px"}>
                      Please Upload document
                    </ErrorMsg>
                  )}
                </div>
              </Col>
            )}
          {fields.includes("ckyc") &&
            ckycValue === "NO" &&
            identity === "form60" && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>Upload Form 60</FormGroupTag>
                  <FilePicker
                    file={form60}
                    setFile={setForm60}
                    watch={watch}
                    register={register}
                    name={`form60`}
                    id={`form60`}
                    placeholder={selectedIdentity?.placeholder}
                  />
                </div>
              </Col>
            )}
          {/*Tag to keep track of the previous owner type*/}
          <input
            type="hidden"
            name={"prevOwnerType"}
            value={temp_data?.corporateVehiclesQuoteRequest?.vehicleOwnerType}
            ref={register}
          />
          {fields.includes("ckyc") && ckycValue === "YES" && (
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>CKYC Number</FormGroupTag>
                <Form.Control
                  type="text"
                  autoComplete="none"
                  placeholder={`Enter CKYC Number`}
                  size="sm"
                  ref={register}
                  name={"ckycNumber"}
                  maxLength={14}
                  readOnly={
                    resubmit || (watch("ckycNumber") && fieldsNonEditable)
                  }
                  onInput={(e) =>
                    (e.target.value = ("" + e.target.value)
                      .replace(/[^A-Za-z0-9]/gi, "")
                      .toUpperCase())
                  }
                />
                {errors?.ckycNumber ? (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.ckycNumber?.message}
                  </ErrorMsg>
                ) : (
                  <Form.Text className="text-muted">
                    {/* <text style={{ color: "#bdbdbd" }}>e.g 18AABCU9603R1ZM</text> */}
                  </Form.Text>
                )}
              </div>
            </Col>
          )}
          {fields?.includes("ckyc") &&
            fields.includes("photo") &&
            ((!(companyAlias === "shriram" && ckycValue === "YES") &&
              companyAlias !== "iffco_tokio") ||
              (companyAlias === "iffco_tokio" &&
                uploadFile &&
                ckycValue === "NO")) && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>Upload profile photo</FormGroupTag>
                  <FilePicker
                    file={photo}
                    setFile={setPhoto}
                    watch={watch}
                    register={register}
                    name={"photo"}
                    id={"photo"}
                    placeholder={"Upload your photo here"}
                  />
                  {!photo && fileUploadError && (
                    <ErrorMsg fontSize={"12px"}>Please Upload Photo</ErrorMsg>
                  )}
                </div>
              </Col>
            )}
          {fields.includes("ckyc") &&
            !uploadFile &&
            ckycTypes.map((each) => {
              if (
                ckycValue === "NO" &&
                each.id === identity &&
                identity !== "doi" &&
                identity !== "panNumber" &&
                identity !== "gstNumber" &&
                identity !== "form60"
              ) {
                return (
                  <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                    <div className="py-2">
                      <FormGroupTag>{selectedIdentity?.name}</FormGroupTag>
                      <Form.Control
                        type="text"
                        autoComplete="none"
                        placeholder={`Enter ${selectedIdentity?.name}`}
                        size="sm"
                        ref={register}
                        name={identity}
                        readOnly={
                          resubmit || (watch(identity) && fieldsNonEditable)
                        }
                        maxLength={selectedIdentity?.length}
                        onInput={(e) =>
                          (e.target.value = e.target.value
                            .replace(/[^A-Za-z0-9]/gi, "")
                            .toUpperCase())
                        }
                      />
                      {errors[identity] ? (
                        <ErrorMsg fontSize={"12px"}>
                          {errors[identity]?.message}
                        </ErrorMsg>
                      ) : (
                        <Form.Text className="text-muted">
                          {/* <text style={{ color: "#bdbdbd" }}>e.g 18AABCU9603R1ZM</text> */}
                        </Form.Text>
                      )}
                    </div>
                  </Col>
                );
              }
            })}
          {identity && identity === "doi" && ckycValue === "NO" && (
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <StyledDatePicker>
                <div className="py-2 dateTimeOne">
                  <FormGroupTag>Date of Incorporation</FormGroupTag>
                  <Controller
                    control={control}
                    name="doi"
                    render={({ onChange, onBlur, value, name }) => (
                      <DateInput
                        minDate={false}
                        maxDate={new Date()}
                        value={value}
                        name={name}
                        onChange={onChange}
                        // readOnly={allFieldsReadOnly}
                        ref={register}
                        // selected={
                        //   DOB || owner?.dob || CardData?.owner?.dob
                        //     ? DateUtil(DOB || owner?.dob || CardData?.owner?.dob)
                        //     : false
                        // }
                        incorporation={true}
                      />
                    )}
                  />
                  {!!errors?.doi && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.doi?.message}
                    </ErrorMsg>
                  )}
                </div>
              </StyledDatePicker>
            </Col>
          )}
          {fields.includes("panNumber") &&
            poi_identity !== "panNumber" &&
            poa_identity !== "panNumber" && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>
                    {(ckycValue === "NO" &&
                      fields.includes("ckyc") &&
                      identity &&
                      identity === "panNumber") ||
                    (temp_data?.selectedQuote?.companyAlias ===
                      "icici_lombard" &&
                      temp_data?.selectedQuote?.totalPayableAmountWithAddon >
                        100000) ||
                    temp_data?.selectedQuote?.companyAlias === "edelweiss" ||
                    temp_data?.selectedQuote?.companyAlias === "reliance" ||
                    temp_data?.selectedQuote?.companyAlias ===
                      "royal_sundaram" ||
                    temp_data?.selectedQuote?.companyAlias === "united_india" ||
                    companyAlias === "oriental" ||
                    temp_data?.selectedQuote?.companyAlias === "magma" ||
                    temp_data?.selectedQuote?.companyAlias ===
                      "bajaj_allianz" ||
                    temp_data?.selectedQuote?.companyAlias === "tata_aig"
                      ? "PAN No"
                      : "PAN No (Optional)"}
                  </FormGroupTag>
                  <Form.Control
                    type="text"
                    autoComplete="none"
                    placeholder="Enter PAN No"
                    size="sm"
                    ref={register}
                    name="panNumber"
                    readOnly={
                      resubmit || (watch("panNumber") && fieldsNonEditable)
                    }
                    maxLength="10"
                    onInput={(e) =>
                      (e.target.value = ("" + e.target.value)
                        .replace(/[^A-Za-z0-9]/gi, "")
                        .toUpperCase())
                    }
                    isInvalid={errors?.panNumber}
                  />
                  {errors?.panNumber ? (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.panNumber?.message}
                    </ErrorMsg>
                  ) : (
                    <Form.Text className="text-muted">
                      <text style={{ color: "#bdbdbd" }}>e.g AAAPL1234C</text>
                    </Form.Text>
                  )}
                </div>
              </Col>
            )}
          {fields.includes("gstNumber") &&
            (!poa_identity || poa_identity !== "gstNumber") &&
            (!poi_identity || poi_identity !== "gstNumber") && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>{`GSTIN${
                    (temp_data?.selectedQuote?.companyAlias !== "sbi" &&
                      temp_data?.selectedQuote?.companyAlias ===
                        "liberty_videocon" &&
                      (type === "car" || type === "bike") &&
                      temp_data?.ownerTypeId === 2 &&
                      !token) ||
                    (identity === "gstNumber" && fields.includes("ckyc"))
                      ? ""
                      : " (Optional)"
                  }`}</FormGroupTag>
                  <Form.Control
                    type="text"
                    autoComplete="none"
                    placeholder="Enter GSTIN"
                    size="sm"
                    ref={register}
                    name="gstNumber"
                    readOnly={
                      (resubmit && verifiedData?.includes("gstNumber")) ||
                      (watch("gstNumber") && fieldsNonEditable)
                    }
                    maxLength={"15"}
                    onInput={(e) =>
                      (e.target.value = ("" + e.target.value)
                        .replace(/[^A-Za-z0-9]/gi, "")
                        .toUpperCase())
                    }
                    isInvalid={errors?.gstNumber}
                  />
                  {errors?.gstNumber ? (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.gstNumber?.message}
                    </ErrorMsg>
                  ) : (
                    <Form.Text className="text-muted">
                      <text style={{ color: "#bdbdbd" }}>
                        e.g 18AABCU9603R1ZM
                      </text>
                    </Form.Text>
                  )}
                </div>
              </Col>
            )}
          {fields.includes("occupation") &&
            Number(temp_data?.ownerTypeId) === 1 && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2 fname">
                  <FormGroupTag>Occupation Type</FormGroupTag>
                  <Form.Control
                    as="select"
                    autoComplete="none"
                    size="sm"
                    ref={register}
                    name="occupation"
                    readOnly={allFieldsReadOnly}
                    className="title_list"
                    style={{ cursor: "pointer" }}
                    isInvalid={errors?.occupation}
                  >
                    (
                    <option
                      selected={
                        process.env.REACT_APP_BROKER === "ACE" ? false : true
                      }
                      value={"@"}
                    >
                      Select
                    </option>
                    )
                    {Occupations.map(({ name, id, priority }, index) => (
                      <option
                        style={{ cursor: "pointer" }}
                        selected={
                          CardData?.owner?.occupation === id ||
                          (process.env.REACT_APP_BROKER === "OLA" &&
                            name === "Business / Professional Services") ||
                          (process.env.REACT_APP_BROKER === "ACE" &&
                            (priority * 1 === 1 ||
                              name === "others" ||
                              name === "Others" ||
                              name === "Other" ||
                              name === "other"))
                        }
                        value={id}
                      >
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                </div>
                {watch("occupation") && (
                  <input
                    type="hidden"
                    ref={register}
                    name="occupationName"
                    value={OccupationName}
                  />
                )}
                {!!errors?.occupation && (
                  <ErrorMsg fontSize={"12px"} style={{ marginTop: "-3px" }}>
                    {errors?.occupation?.message}
                  </ErrorMsg>
                )}
              </Col>
            )}
          {fields.includes("maritalStatus") &&
            Number(temp_data?.ownerTypeId) === 1 && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <FormGroupTag style={{ paddingTop: "10px" }}>
                  Marital Status
                </FormGroupTag>
                <div className="" style={{ width: "100%", paddingTop: "2px" }}>
                  <ButtonGroupTag toggle style={{ width: "100%" }}>
                    {maritalStatus.map((item, idx) => (
                      <ToggleButton
                        style={{
                          minWidth: "fill-available",
                          width: "fill-available",
                          minHeight: "32px",
                        }}
                        key={idx}
                        className={item.value === "1" ? "mb-2 mr-4" : "mb-2"}
                        type="radio"
                        variant="secondary"
                        ref={register}
                        readOnly={allFieldsReadOnly}
                        size="sm"
                        name="maritalS"
                        tabIndex={"0"}
                        id={`index-key2${idx}`}
                        onKeyDown={(e) => {
                          if (e.keyCode === 32 && !allFieldsReadOnly) {
                            e.preventDefault();
                            document.getElementById(`index-key2${idx}`) &&
                              document
                                .getElementById(`index-key2${idx}`)
                                .click();
                          }
                        }}
                        value={item.name}
                        checked={radioValue2 === item.name}
                        onChange={(e) => {
                          !allFieldsReadOnly && setRadioValue2(e.target.value);
                        }}
                      >
                        {item.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroupTag>
                </div>
                <input
                  type="hidden"
                  name="maritalStatus"
                  value={radioValue2}
                  ref={register}
                />
                {!!errors?.maritalStatus && (
                  <ErrorMsg fontSize={"12px"} style={{ marginTop: "-3px" }}>
                    {errors?.maritalStatus.message}
                  </ErrorMsg>
                )}
              </Col>
            )}
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className=" mt-1"
            style={{ marginBottom: "-10px" }}
          >
            <p
              style={{
                color: Theme?.proposalHeader?.color
                  ? Theme?.proposalHeader?.color
                  : "#1a5105",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              Communication Address
            </p>
          </Col>
          {true && (
            <>
              {" "}
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                <div className="py-2">
                  <FormGroupTag>Address</FormGroupTag>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    autoComplete="none"
                    // placeholder="e.g C/58 Jeet Nagar, Versova Bowla"
                    name="address"
                    maxLength={`${
                      temp_data?.selectedQuote?.companyAlias === "reliance"
                        ? 200
                        : 120
                    }`}
                    readOnly={
                      // allFieldsReadOnly ||
                      resubmit &&
                      verifiedData?.includes("address") &&
                      !fieldEditable
                    }
                    minlength="2"
                    ref={register}
                    onInput={(e) =>
                      (e.target.value =
                        e.target.value.length <= 1
                          ? ("" + e.target.value)
                              .toUpperCase()
                              .replace(
                                /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
                                ""
                              )
                          : e.target.value)
                    }
                    errors={errors?.addressLine1}
                    isInvalid={errors?.addressLine1}
                    size="sm"
                  />
                  {errors?.addressLine1 ||
                  errors?.addressLine2 ||
                  errors?.addressLine3 ||
                  errors?.address ? (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.addressLine1?.message ||
                        errors?.addressLine2?.message ||
                        errors?.addressLine3?.message ||
                        errors?.address?.message}
                    </ErrorMsg>
                  ) : (
                    <Form.Text className="text-muted">
                      <text style={{ color: "#bdbdbd" }}>
                        {`(${(watch("address") || "").length}/${
                          temp_data?.selectedQuote?.companyAlias === "reliance"
                            ? 200
                            : 120
                        })`}
                      </text>
                    </Form.Text>
                  )}
                </div>
              </Col>
              <input
                type="hidden"
                ref={register}
                name="addressLine1"
                value={watch("address")}
              />
            </>
          )}
          {false && (
            <>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>Address Line 1</FormGroupTag>
                  <Form.Control
                    type="text"
                    autoComplete="none"
                    placeholder="Enter Address Line 1"
                    name="addressLine1"
                    readOnly={allFieldsReadOnly}
                    maxLength="50"
                    minlength="2"
                    ref={register}
                    onInput={(e) =>
                      (e.target.value =
                        e.target.value.length <= 1
                          ? ("" + e.target.value)
                              .toUpperCase()
                              .replace(
                                /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
                                ""
                              )
                          : e.target.value)
                    }
                    errors={errors.addressLine1}
                    isInvalid={errors.addressLine1}
                    size="sm"
                  />
                  {!!errors?.addressLine1 && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.addressLine1?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>Address Line 2</FormGroupTag>
                  <Form.Control
                    type="text"
                    autoComplete="none"
                    placeholder="Enter Address Line 2"
                    name="addressLine2"
                    readOnly={allFieldsReadOnly}
                    maxLength="50"
                    minlength="2"
                    ref={register}
                    onInput={(e) =>
                      (e.target.value =
                        e.target.value.length <= 1
                          ? ("" + e.target.value)
                              .toUpperCase()
                              .replace(
                                /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
                                ""
                              )
                          : e.target.value)
                    }
                    errors={errors.addressLine2}
                    isInvalid={errors.addressLine2}
                    size="sm"
                  />
                  {!!errors?.addressLine2 && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.addressLine2?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>Address Line 3 (optional)</FormGroupTag>
                  <Form.Control
                    type="text"
                    autoComplete="none"
                    placeholder="Enter Address Line 3"
                    name="addressLine3"
                    readOnly={allFieldsReadOnly}
                    maxLength="50"
                    minlength="2"
                    ref={register}
                    onInput={(e) =>
                      (e.target.value =
                        e.target.value.length <= 1
                          ? ("" + e.target.value)
                              .toUpperCase()
                              .replace(
                                /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
                                ""
                              )
                          : e.target.value)
                    }
                    errors={errors.addressLine3}
                    isInvalid={errors.addressLine3}
                    size="sm"
                  />
                  {!!errors?.addressLine3 && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.addressLine3?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
            </>
          )}

          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <FormGroupTag>Pincode</FormGroupTag>
              <Form.Control
                name="pincode"
                autoComplete="none"
                ref={register}
                readOnly={
                  resubmit &&
                  verifiedData?.includes("pincode") &&
                  !fieldEditable
                }
                type="tel"
                placeholder="Enter Pincode"
                errors={errors?.pincode}
                isInvalid={errors?.pincode}
                size="sm"
                onKeyDown={numOnly}
                maxLength="6"
              />
              {!!errors?.pincode && (
                <ErrorMsg fontSize={"12px"}>
                  {errors?.pincode?.message}
                </ErrorMsg>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <FormGroupTag>State</FormGroupTag>
              <Form.Control
                name="state"
                ref={register}
                type="text"
                placeholder="Select State"
                style={{ cursor: "not-allowed" }}
                errors={errors?.state}
                isInvalid={errors?.state}
                size="sm"
                readOnly
              />
              {!!errors?.state && (
                <ErrorMsg fontSize={"12px"}>{errors?.state?.message}</ErrorMsg>
              )}
            </div>
            <input
              name="stateId"
              ref={register}
              type="hidden"
              value={pin?.state?.state_id}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2 fname">
              <FormGroupTag>City</FormGroupTag>
              <Form.Control
                as="select"
                // readOnly={allFieldsReadOnly}
                size="sm"
                ref={register}
                name={`city`}
                errors={errors?.city}
                isInvalid={errors?.city}
                style={{ cursor: "pointer" }}
                // value={owner?.city || CardData?.owner?.city}
              >
                <option selected={true} value={"@"}>
                  Select
                </option>

                {pin?.city?.map(({ city_name, city_id }, index) => (
                  <option
                    selected={
                      CardData?.owner?.city?.trim() === city_name?.trim() ||
                      (pin?.city?.length === 1 &&
                        !CardData?.owner?.city?.trim()) ||
                      (_.isEmpty(CardData?.owner) &&
                        _.isEmpty(owner) &&
                        temp_data?.userProposal?.city &&
                        temp_data?.userProposal?.city.trim() ===
                          city_name?.trim())
                    }
                    value={city_name}
                  >
                    {city_name}
                  </option>
                ))}
              </Form.Control>
              {!!errors?.city && (
                <ErrorMsg fontSize={"12px"}>{errors?.city?.message}</ErrorMsg>
              )}
            </div>
            <input name="cityId" ref={register} type="hidden" />
          </Col>
          {/*---This hidden input is for popup notification---*/}
          <input type="hidden" ref={register} name="popupPreview" value={"Y"} />
          {/*-x-This hidden input is for popup notification-x-*/}
          <Col
            sm={12}
            lg={12}
            md={12}
            xl={12}
            className="d-flex justify-content-center mt-5 mx-auto"
          >
            <Button
              type="submit"
              buttonStyle="outline-solid"
              className=""
              shadow={"none"}
              disabled={loading}
              hex1={
                Theme?.proposalProceedBtn?.hex1
                  ? Theme?.proposalProceedBtn?.hex1
                  : "#4ca729"
              }
              hex2={
                Theme?.proposalProceedBtn?.hex2
                  ? Theme?.proposalProceedBtn?.hex2
                  : "#4ca729"
              }
              borderRadius="5px"
              color="white"
              onClick={() =>
                navigator &&
                navigator?.vibrate &&
                navigator.vibrate([100, 0, 50])
              }
            >
              <text
                style={{
                  fontSize: "15px",
                  padding: "-20px",
                  margin: "-20px -5px -20px -5px",
                  fontWeight: "400",
                }}
              >
                {loading
                  ? "Please Wait..."
                  : Number(temp_data?.ownerTypeId) === 1 && conditionChk
                  ? `Proceed to Nominee${!lessthan376 ? " Details" : ""}`
                  : `Proceed to Vehicle${!lessthan376 ? " Details" : ""}`}
              </text>
            </Button>
          </Col>
        </Row>
      </Form>

      <CkycInfo
        show={show2}
        onHide={() => {
          setShow2(false);
          setOwner(tempOwner);
        }}
      />
      <OTPPopup
        enquiry_id={enquiry_id}
        show={show1}
        onHide={() => setShow1(false)}
        mobileNumber={mobileNoLead}
        otpSuccess={() => otpSuccess()}
        email={emailId}
        ckyc={companyAlias === "magma"}
        otp_id={otp_id}
        companyAlias={companyAlias}
        stage={"owner card"}
      />
      <Popup
        top="40%"
        show={show}
        content={content}
        onClose={onClose}
        position="middle"
        height="auto"
        width="600px"
      />
    </>
  );
};

const StyledDatePicker = styled.div`
  .dateTimeOne .date-header {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
  .dateTimeOne .react-datepicker__day:hover {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
`;

export default OwnerCard;
