import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import demoLogo from "../../../assets/img/logo02.png";
import { useHistory } from "react-router-dom";
import { Row, Col, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import _, { isEmpty } from "lodash";
import { setTempData } from "../filterConatiner/quoteFilter.slice";
import { currencyFormater, Decrypt, toDate } from "utils";
import Skeleton from "react-loading-skeleton";
import { differenceInDays, differenceInYears } from "date-fns";
import moment from "moment";
import {
  setSelectedQuote,
  SaveQuotesData,
  SaveAddonsData,
  clear,
  setQuotesList,
  setFinalPremiumList,
  setFinalPremiumList1,
  clearFinalPremiumList,
  saveSelectedQuoteResponse,
  CancelAll,
  setLoadingFromPDf,
  setQuoteBundle,
  setzdAvailablity,
  setShowingZdlp,
} from "../quote.slice";
import { parseInt } from "lodash";
import { useMediaPredicate } from "react-media-hook";
import { getAddonName } from "../quoteUtil";
import swal from "sweetalert";
import tooltip from "../../../assets/img/tooltip.svg";
import { CustomTooltip } from "components";
import { setRedirectionFlag } from "modules/Home/home.slice";
import { TypeReturn } from "modules/type";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Tab } from "components";

export const QuoteCard = ({
  quote,
  register,
  index,
  compare,
  lessthan767,
  length,
  watch,
  onCompare,
  type,
  typeId,
  setPrevPopup,
  prevPopup,
  setSelectedId,
  popupCard,
  multiPopupCard,
  setSelectedCompanyName,
  setSelectedCompanyAlias,
  gstToggle,
  maxAddonsMotor,
  setSelectedIcId,
  setKnowMoreObject,
  setKnowMore,
  knowMore,
  setSelectedKnowMore,
  quoteComprehesiveGrouped,
  knowMoreCompAlias,
  allQuoteloading,
  sendQuotes,
  setApplicableAddonsLits,
  setPrevPopupTp,
  setQuoteData,
  isMobileIOS,
  journey_type,
  zdlp,
  setZdlp,
  mobileComp,
  setMobileComp,
  claimList,
  zdlp_gdd,
  setZdlp_gdd,
  claimList_gdd,
  diffDays,
  date,
  NoOfDays,
  CompareData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { temp_data, isRedirectionDone } = useSelector((state) => state.home);
  const { prevInsList, tempData } = useSelector((state) => state.quoteFilter);
  const {
    addOnsAndOthers,
    saveQuoteResponse,
    updateQuoteLoader,
    loadingFromPdf,
    quoteBundle,
    zdAvailablity,
    masterLogos,
  } = useSelector((state) => state.quotes);
  console.log("zdAvailablityzdAvailablity", zdAvailablity);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  let selectedProductEncrypted = query.get("productId")
    ? query.get("productId")
    : null;
  let selectedProduct = selectedProductEncrypted
    ? selectedProductEncrypted * 1
      ? selectedProductEncrypted
      : Decrypt(selectedProductEncrypted)
    : null;
  let selectedTypeEncrypted = lessthan767
    ? ""
    : query.get("selectedType")
    ? query.get("selectedType")
    : null;
  let selectedType = selectedTypeEncrypted
    ? Decrypt(selectedTypeEncrypted)
    : null;
  let selectedTermEncrypted = lessthan767
    ? ""
    : query.get("selectedTerm")
    ? query.get("selectedTerm")
    : null;
  let selectedTerm = selectedTermEncrypted
    ? Decrypt(selectedTermEncrypted)
    : null;
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const [mouseHover, setMouseHover] = useState(false);
  const [mouseHoverBenefits, setMouseHoverBenefits] = useState(false);
  const [difference, setDifference] = useState(false);
  const [sort, setSort] = useState(0);
  const between9to12 = useMediaPredicate(
    "(min-width:993px) and (max-width:1250px)"
  );
  const between13to14 = useMediaPredicate(
    "(min-width:1350px) and (max-width:1450px)"
  );
  //-----------Product selection through url when redirected from pdf----------------

  const address =
    !_.isEmpty(masterLogos) &&
    masterLogos.filter((ic) => ic.companyAlias === "royal_sundaram");

  const displayAddress = !_.isEmpty(address)
    ? address[0]?.Address
      ? address[0]?.Address
      : false
    : false;

  useEffect(() => {
    if (selectedProduct && !loadingFromPdf) {
      // generating clicks
      // tab
      if (date && diffDays < NoOfDays()) {
        !lessthan767 &&
          selectedType === "tab2" &&
          setTimeout(() => {
            document.getElementById(`tab2`) &&
              document.getElementById(`tab2`).click();
          }, 500);
        //term selection.
        // 3 months
        !lessthan767 &&
          Number(selectedTerm) &&
          Number(selectedTerm) === 3 &&
          setTimeout(() => {
            document.getElementById(`Short Term Policy (3 months)`) &&
              document.getElementById(`Short Term Policy (3 months)`).click();
          }, 1000);
        //6 months
        !lessthan767 &&
          Number(selectedTerm) &&
          Number(selectedTerm) === 6 &&
          setTimeout(() => {
            document.getElementById(`Short Term Policy (6 months)`) &&
              document.getElementById(`Short Term Policy (6 months)`).click();
          }, 1000);
        //buy now button
        setTimeout(() => {
          document.getElementById(`buy-${selectedProduct}`) &&
            document.getElementById(`buy-${selectedProduct}`).click();
        }, 2000);

        //removing the query param "producId" to prevent the element click twice if the page is reloaded with the same url
        if (document.getElementById(`buy-${selectedProduct}`)) {
          var queryUrl = window.location.search.substring(1);
          // is there anything there ?
          if (queryUrl.length) {
            // are the new history methods available ?
            if (
              window.history != undefined &&
              window.history.replaceState != undefined
            ) {
              // if pushstate exists, ad d a new state to the history, this changes the url without reloading the page
              const newurl =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname +
                `?enquiry_id=${enquiry_id}${token ? `&token=${token}` : ``}${
                  journey_type ? `&journey_type=${journey_type}` : ``
                }${typeId ? `&typeid=${typeId}` : ``}`;
              window.history.replaceState({ path: newurl }, "", newurl);
              // query.delete("productId")
              // window.location.search = query.toString();
            }
          }
        }
      }
    }
  }, [selectedProduct]);

  //-----------------sortByDefault----------------------
  useEffect(() => {
    setSort(tempData?.sortBy);
  }, [tempData?.sortBy]);

  //geetingAddonValue

  const GetAddonValue = (addonName, addonDiscountPercentage) => {
    let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
    let additional = Object.keys(quote?.addOnsData?.additional);
    let selectedAddons = addOnsAndOthers?.selectedAddons;
    if (inbuilt?.includes(addonName)) {
      return (
        <span style={{ ...(lessthan767 && { fontSize: "9px" }) }}>
          {Number(quote?.addOnsData?.inBuilt[addonName]) !== 0 ? (
            `₹ ${currencyFormater(
              parseInt(
                quote?.company_alias === "royal_sundaram"
                  ? (addonName === "ncbProtection" ||
                    addonName === "engineProtector" ||
                    addonName === "tyreSecure"
                      ? quote?.addOnsData?.inBuilt[addonName] -
                          (quote?.addOnsData?.inBuilt[addonName] *
                            addonDiscountPercentage) /
                            100 <
                        200
                        ? 200
                        : quote?.addOnsData?.inBuilt[addonName] -
                          (quote?.addOnsData?.inBuilt[addonName] *
                            addonDiscountPercentage) /
                            100
                      : addonName === "roadSideAssistance"
                      ? quote?.addOnsData?.inBuilt[addonName]
                      : quote?.addOnsData?.inBuilt[addonName] -
                          (quote?.addOnsData?.inBuilt[addonName] *
                            addonDiscountPercentage) /
                            100 <
                        100
                      ? 100
                      : quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100) * 1.18
                  : quote?.addOnsData?.inBuilt[addonName] * 1.18
              )
            )}`
          ) : (
            <>
              {addonName === "roadSideAssistance" &&
              quote?.company_alias === "reliance" ? (
                <>-</>
              ) : (
                <>
                  {lessthan767 ? (
                    <>
                      {" "}
                      <i className="fa fa-check" style={{ color: "green" }}></i>
                    </>
                  ) : (
                    <>
                      <Badge
                        variant="primary"
                        style={{ position: "relative", bottom: "2px" }}
                      >
                        Included
                      </Badge>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </span>
      );

      //	return `Inbuilt ₹ ${quote?.addOnsData?.inBuilt[addonName]}`;
    } else if (
      additional?.includes(addonName) &&
      selectedAddons?.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) !== 0 &&
      typeof quote?.addOnsData?.additional[addonName] === "number"
    ) {
      return `₹ ${currencyFormater(
        parseInt(
          quote?.company_alias === "royal_sundaram"
            ? (addonName === "ncbProtection" ||
              addonName === "engineProtector" ||
              addonName === "tyreSecure"
                ? quote?.addOnsData?.additional[addonName] -
                    (quote?.addOnsData?.additional[addonName] *
                      addonDiscountPercentage) /
                      100 <
                  200
                  ? 200
                  : quote?.addOnsData?.additional[addonName] -
                    (quote?.addOnsData?.additional[addonName] *
                      addonDiscountPercentage) /
                      100
                : addonName === "roadSideAssistance"
                ? quote?.addOnsData?.additional[addonName]
                : quote?.addOnsData?.additional[addonName] -
                    (quote?.addOnsData?.additional[addonName] *
                      addonDiscountPercentage) /
                      100 <
                  100
                ? 100
                : quote?.addOnsData?.additional[addonName] -
                  (quote?.addOnsData?.additional[addonName] *
                    addonDiscountPercentage) /
                    100) * 1.18
            : quote?.addOnsData?.additional[addonName] * 1.18
        )
      )}`;
    } else if (
      additional?.includes(addonName) &&
      //	selectedAddons.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) === 0
    ) {
      return "N/A";
    } else if (
      !additional?.includes(addonName) &&
      selectedAddons?.includes(addonName)
    ) {
      return "N/A";
    } else if (
      !(additional?.includes(addonName) || inbuilt?.includes(addonName))
    ) {
      return "N/A";
    } else if (Number(quote?.addOnsData?.additional[addonName]) === 0) {
      return "N/A";
    } else if (
      additional?.includes(addonName) &&
      !selectedAddons?.includes(addonName)
    ) {
      return "N/S";
    } else {
      return "N/A";
    }
  };

  //-----------------getting addon value without  gst---------------------

  const GetAddonValueNoGst = (addonName, addonDiscountPercentage) => {
    let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
    let additional = Object.keys(quote?.addOnsData?.additional);
    let selectedAddons = addOnsAndOthers?.selectedAddons;

    if (inbuilt?.includes(addonName)) {
      return (
        <span style={{ ...(lessthan767 && { fontSize: "9px" }) }}>
          {/* <Badge
						variant="primary"
						style={{ position: "relative", bottom: "2px" }}
					>
						Included
					</Badge>{" "} */}
          {Number(quote?.addOnsData?.inBuilt[addonName]) !== 0 ? (
            `₹ ${currencyFormater(
              parseInt(
                quote?.company_alias === "royal_sundaram"
                  ? addonName === "ncbProtection" ||
                    addonName === "engineProtector" ||
                    addonName === "tyreSecure"
                    ? quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100 <
                      200
                      ? 200
                      : quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100
                    : addonName === "roadSideAssistance"
                    ? quote?.addOnsData?.inBuilt[addonName]
                    : quote?.addOnsData?.inBuilt[addonName] -
                        (quote?.addOnsData?.inBuilt[addonName] *
                          addonDiscountPercentage) /
                          100 <
                      100
                    ? 100
                    : quote?.addOnsData?.inBuilt[addonName] -
                      (quote?.addOnsData?.inBuilt[addonName] *
                        addonDiscountPercentage) /
                        100
                  : quote?.addOnsData?.inBuilt[addonName]
              )
            )}`
          ) : (
            <>
              {addonName === "roadSideAssistance" &&
              quote?.company_alias === "reliance" ? (
                <>-</>
              ) : lessthan767 ? (
                <>
                  {" "}
                  <i className="fa fa-check" style={{ color: "green" }}></i>
                </>
              ) : (
                <>
                  <Badge
                    variant="primary"
                    style={{ position: "relative", bottom: "2px" }}
                  >
                    Included
                  </Badge>
                </>
              )}
            </>
          )}
        </span>
      );

      //	return `Inbuilt ₹ ${quote?.addOnsData?.inBuilt[addonName]}`;
    } else if (
      additional?.includes(addonName) &&
      selectedAddons?.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) !== 0 &&
      typeof quote?.addOnsData?.additional[addonName] === "number"
    ) {
      return `₹ ${currencyFormater(
        parseInt(
          quote?.company_alias === "royal_sundaram"
            ? addonName === "ncbProtection" ||
              addonName === "engineProtector" ||
              addonName === "tyreSecure"
              ? quote?.addOnsData?.additional[addonName] -
                  (quote?.addOnsData?.additional[addonName] *
                    addonDiscountPercentage) /
                    100 <
                200
                ? 200
                : quote?.addOnsData?.additional[addonName] -
                  (quote?.addOnsData?.additional[addonName] *
                    addonDiscountPercentage) /
                    100
              : addonName === "roadSideAssistance"
              ? quote?.addOnsData?.additional[addonName]
              : quote?.addOnsData?.additional[addonName] -
                  (quote?.addOnsData?.additional[addonName] *
                    addonDiscountPercentage) /
                    100 <
                100
              ? 100
              : quote?.addOnsData?.additional[addonName] -
                (quote?.addOnsData?.additional[addonName] *
                  addonDiscountPercentage) /
                  100
            : quote?.addOnsData?.additional[addonName]
        )
      )}`;
    } else if (
      additional?.includes(addonName) &&
      //	selectedAddons.includes(addonName) &&
      Number(quote?.addOnsData?.additional[addonName]) === 0
    ) {
      return "N/A";
    } else if (
      !additional?.includes(addonName) &&
      selectedAddons?.includes(addonName)
    ) {
      return "N/A";
    } else if (
      !(additional?.includes(addonName) || inbuilt?.includes(addonName))
    ) {
      return "N/A";
    } else if (Number(quote?.addOnsData?.additional[addonName]) === 0) {
      return "N/A";
    } else if (
      additional?.includes(addonName) &&
      !selectedAddons?.includes(addonName)
    ) {
      return "N/S";
    } else {
      return "N/A";
    }
  };

  //---------------------applicable addons-------------------------
  const [applicableAddons, setApplicableAddons] = useState(null);
  useEffect(() => {
    if (temp_data?.tab !== "tab2") {
      let additional = Object.keys(quote?.addOnsData?.additional);
      let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
      let selectedAddons = addOnsAndOthers?.selectedAddons || [];
      let additionalList = quote?.addOnsData?.additional;
      let inbuiltList = quote?.addOnsData?.inBuilt;
      var addonsSelectedList = [];
      if (!_.isEmpty(selectedAddons) || !_.isEmpty(inbuilt)) {
        selectedAddons.forEach((el) => {
          if (additional?.includes(el) && Number(additionalList[el])) {
            var newList = {
              name: getAddonName(el),
              premium: Number(additionalList[el]),
            };
            addonsSelectedList.push(newList);
          }
        });

        inbuilt.forEach((el) => {
          var newList = {
            name: getAddonName(el),
            premium: Number(inbuiltList[el]),
          };
          addonsSelectedList.push(newList);
        });

        setApplicableAddons(addonsSelectedList);
      } else {
        setApplicableAddons([]);
      }
    }
  }, [addOnsAndOthers?.selectedAddons, quote, temp_data?.tab]);
  //----------------addonTotalCaluclatuion-----------------------

  const [totalAddon, setTotalAddon] = useState(0);
  const [totalOthersAddon, setTotalOtherAddons] = useState(0);
  const [addonDiscountPercentage, setAddonDiscountPercentage] = useState(0);
  // quote?.company_alias === "royal_sundaram" &&
  //   console.log("addonDiscountPercentage", addonDiscountPercentage);
  useEffect(() => {
    let additional = Object.keys(quote?.addOnsData?.additional);

    let additionalList = quote?.addOnsData?.additional;

    let selectedAddons = addOnsAndOthers?.selectedAddons || [];

    let totalAdditional = 0;

    selectedAddons.forEach((el) => {
      if (
        !_.isEmpty(additional) &&
        additional?.includes(el) &&
        typeof additionalList[el] === "number"
      ) {
        totalAdditional =
          totalAdditional +
          (quote?.company_alias === "royal_sundaram"
            ? el === "ncbProtection" ||
              el === "engineProtector" ||
              el === "tyreSecure"
              ? Number(additionalList[el]) -
                  (Number(additionalList[el]) * addonDiscountPercentage) / 100 <
                  200 && Number(additionalList[el]) > 0
                ? 200
                : Number(additionalList[el]) -
                  (Number(additionalList[el]) * addonDiscountPercentage) / 100
              : el === "roadSideAssistance"
              ? Number(additionalList[el])
              : Number(additionalList[el]) -
                  (Number(additionalList[el]) * addonDiscountPercentage) / 100 <
                  100 && Number(additionalList[el]) > 0
              ? 100
              : Number(additionalList[el]) -
                (Number(additionalList[el]) * addonDiscountPercentage) / 100
            : Number(additionalList[el]));
      }
    });

    let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
    let allAddons = [
      "zeroDepreciation",
      "roadSideAssistance",
      "imt23",
      "keyReplace",
      "engineProtector",
      "ncbProtection",
      "consumables",
      "tyreSecure",
      "returnToInvoice",
      "lopb",
      "emergencyMedicalExpenses",
    ];
    let inbuiltList = quote?.addOnsData?.inBuilt;
    let totalInbuilt = 0;
    allAddons.forEach((el) => {
      if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
        totalInbuilt =
          totalInbuilt +
          (quote?.company_alias === "royal_sundaram"
            ? el === "ncbProtection" ||
              el === "engineProtector" ||
              el === "tyreSecure"
              ? Number(inbuiltList[el]) -
                  (Number(inbuiltList[el]) * addonDiscountPercentage) / 100 <
                  200 && Number(inbuiltList[el]) > 0
                ? 200
                : Number(inbuiltList[el]) -
                  (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
              : el === "roadSideAssistance"
              ? Number(inbuiltList[el])
              : Number(inbuiltList[el]) -
                  (Number(inbuiltList[el]) * addonDiscountPercentage) / 100 <
                  100 && Number(inbuiltList[el]) > 0
              ? 100
              : Number(inbuiltList[el]) -
                (Number(inbuiltList[el]) * addonDiscountPercentage) / 100
            : Number(inbuiltList[el]));
      }
    });

    let others =
      (quote?.addOnsData?.other && Object.keys(quote?.addOnsData?.other)) || [];

    let othersList = quote?.addOnsData?.other;

    let totalOther = 0;
    others.forEach((el) => {
      if (typeof othersList[el] === "number") {
        totalOther = totalOther + Number(othersList[el]);
      }
    });

    setTotalOtherAddons(totalOther);

    setTotalAddon(
      Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther)
    );
    // quote.company_alias === "royal_sundaram" &&
    //   console.log("totalAddonq", {
    //     totalAddon,
    //     totalAdditional,
    //     totalInbuilt,
    //     totalOther,
    //     additionalList,
    //     selectedAddons,
    //   });
  }, [addOnsAndOthers?.selectedAddons, sort, quote, addonDiscountPercentage]);

  //-----------------------liabilityCalculationCpaBased--------------------------

  useEffect(() => {
    let selectedCpa = addOnsAndOthers?.selectedCpa;

    let cpa = 0;

    if (selectedCpa?.includes("Compulsory Personal Accident")) {
      if (!_.isEmpty(addOnsAndOthers?.isTenure)) {
        cpa = quote?.multiYearCpa ? quote?.multiYearCpa : 0;
      } else {
        cpa = quote?.compulsoryPaOwnDriver;
      }
    } else {
      cpa = 0;
    }

    setTotalPremiumB(
      Number(quote?.finalTpPremium) +
        Number(cpa) +
        //adding un-named passenger cover in multi year cpa sbi.
        (quote?.companyAlias === "sbi" &&
        selectedCpa?.includes("Compulsory Personal Accident") &&
        !_.isEmpty(addOnsAndOthers?.isTenure) &&
        quote?.coverUnnamedPassengerValue * 1
          ? quote?.coverUnnamedPassengerValue *
            (TypeReturn(type) === "bike" ? 4 : 2)
          : 0) +
        //adding additional paid driver cover in multi year cpa sbi.
        (quote?.companyAlias === "sbi" &&
        selectedCpa?.includes("Compulsory Personal Accident") &&
        !_.isEmpty(addOnsAndOthers?.isTenure) &&
        quote?.motorAdditionalPaidDriver * 1
          ? quote?.motorAdditionalPaidDriver *
            (TypeReturn(type) === "bike" ? 4 : 2)
          : 0)
    );
  }, [addOnsAndOthers?.selectedCpa, sort, quote, addOnsAndOthers?.isTenure]);

  //--------------------------------revised ncb and other discount calculation--------------------------- ic based conditions

  const [revisedNcb, setRevisedNcb] = useState(quote?.deductionOfNcb);
  const [addonDiscount, setAddonDiscount] = useState(0);
  const [otherDiscounts, setOtherDiscounts] = useState(
    quote?.icVehicleDiscount || 0
  );

  //premium calc states
  const [totalPremiumA, setTotalPremiumA] = useState(quote?.finalOdPremium);
  const [totalPremiumB, setTotalPremiumB] = useState(quote?.finalTpPremium);
  const [totalPremiumC, setTotalPremiumC] = useState(quote?.finalTotalDiscount);

  const [totalPremium, setTotalPremium] = useState(quote?.finalNetPremium);
  const [gst, setTotalPremiumGst] = useState(false);
  const [finalPremium, setFinalPremium] = useState(false);
  //Clearing quotes other discounts icwise.
  useEffect(() => {
    if (quote?.icVehicleDiscount * 1 || quote?.icVehicleDiscount * 1 === 0) {
      setOtherDiscounts(quote?.icVehicleDiscount);
    }
  }, [quote]);

  useEffect(() => {
    let additional = Object.keys(quote?.addOnsData?.additional);
    let additionalList = quote?.addOnsData?.additional;
    let selectedAddons = addOnsAndOthers?.selectedAddons || [];
    let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
    let inbuiltList = quote?.addOnsData?.inBuilt;
    let addedNcb = 0;

    //handling hdfc accesories + zddon logic
    if (quote?.company_alias === "hdfc_ergo") {
      let additionalAccsPremium = 0;
      //zd
      if (selectedAddons.includes("zeroDepreciation")) {
        /*--electrical--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.electrical?.elecZDPremium * 1
            ? quote?.accessoriesAddons?.electrical?.elecZDPremium * 1
            : 0);
        /*--Non electrical--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.nonElectrical?.nonElecZDPremium * 1
            ? quote?.accessoriesAddons?.nonElectrical?.nonElecZDPremium * 1
            : 0);
        /*--lpg cng kit--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.lpgCngKit?.bifuelZDPremium * 1
            ? quote?.accessoriesAddons?.lpgCngKit?.bifuelZDPremium * 1
            : 0);
      }
      //engine protector
      if (selectedAddons.includes("engineProtector")) {
        /*--electrical--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.electrical?.elecENGPremium * 1
            ? quote?.accessoriesAddons?.electrical?.elecENGPremium * 1
            : 0);
        /*--Non electrical--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.nonElectrical?.nonElecENGPremium * 1
            ? quote?.accessoriesAddons?.nonElectrical?.nonElecENGPremium * 1
            : 0);
        /*--lpg cng kit--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.lpgCngKit?.bifuelENGPremium * 1
            ? quote?.accessoriesAddons?.lpgCngKit?.bifuelENGPremium * 1
            : 0);
      }
      //rti
      if (selectedAddons.includes("returnToInvoice")) {
        /*--electrical--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.electrical?.elecRTIPremium * 1
            ? quote?.accessoriesAddons?.electrical?.elecRTIPremium * 1
            : 0);
        /*--Non electrical--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.nonElectrical?.nonElecRTIPremium * 1
            ? quote?.accessoriesAddons?.nonElectrical?.nonElecRTIPremium * 1
            : 0);
        /*--lpg cng kit--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.lpgCngKit?.bifuelRTIPremium * 1
            ? quote?.accessoriesAddons?.lpgCngKit?.bifuelRTIPremium * 1
            : 0);
      }
      //ncbProtection
      if (selectedAddons.includes("ncbProtection")) {
        /*--electrical--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.electrical?.elecNCBPremium * 1
            ? quote?.accessoriesAddons?.electrical?.elecNCBPremium * 1
            : 0);
        /*--Non electrical--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.nonElectrical?.nonElecNCBPremium * 1
            ? quote?.accessoriesAddons?.nonElectrical?.nonElecNCBPremium * 1
            : 0);
        /*--lpg cng kit--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.lpgCngKit?.bifuelNCBPremium * 1
            ? quote?.accessoriesAddons?.lpgCngKit?.bifuelNCBPremium * 1
            : 0);
      }
      //Consumables
      if (selectedAddons.includes("consumables")) {
        /*--electrical--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.electrical?.elecCOCPremium * 1
            ? quote?.accessoriesAddons?.electrical?.elecCOCPremium * 1
            : 0);
        /*--Non electrical--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.nonElectrical?.nonElecCOCPremium * 1
            ? quote?.accessoriesAddons?.nonElectrical?.nonElecCOCPremium * 1
            : 0);
        /*--lpg cng kit--*/
        additionalAccsPremium =
          additionalAccsPremium * 1 +
          (quote?.accessoriesAddons?.lpgCngKit?.bifuelCOCPremium * 1
            ? quote?.accessoriesAddons?.lpgCngKit?.bifuelCOCPremium * 1
            : 0);
      }
      setTotalPremiumA(
        quote?.finalOdPremium * 1 + (additionalAccsPremium * 1 || 0)
      );
    } else {
      // for resetting the Premium A state for other IC quotes
      setTotalPremiumA(quote?.finalOdPremium);
    }

    if (quote?.company_alias === "oriental" && TypeReturn(type) === "cv") {
      // For Oriental CV, you need to use following formula:NCB premium = (Total OD premium + Addons - discounts(anti theft)) * applicable NCB
      let extraOtherDiscounts = 0;
      let discountPercentageOriental = 0.7;

      // if (quote?.mmvDetail?.manfName === "MARUTI") {
      //   discountPercentageOriental = 0.5;

      //   let c = temp_data?.regDate;
      //   let d = moment().format("DD-MM-YYYY");
      //   let vehicleAge = differenceInYears(toDate(d), toDate(c));
      //   if (vehicleAge >= 11) {
      //     discountPercentageOriental = 0.7;
      //   } else if (vehicleAge >= 6 && vehicleAge < 11) {
      //     discountPercentageOriental = 0.75;
      //   } else if (vehicleAge <= 5) {
      //     discountPercentageOriental = 0.8;
      //   }
      // } else {
      //   discountPercentageOriental = 0.5;
      // }
      //for ncb zd is included.
      extraOtherDiscounts = totalAddon * discountPercentageOriental;
      //for extradiscounts we don't need ZeroDep hence recalc total (addon * discount %) without zd ------
      //additional & selected
      let totalAdditional = 0;
      selectedAddons.forEach((el) => {
        if (
          additional?.includes(el === "zeroDepreciation" ? "nomatch" : el) &&
          typeof additionalList[el] === "number"
        ) {
          totalAdditional = totalAdditional + Number(additionalList[el]);
        }
      });
      //Inbuilt
      let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
      let allAddons = [
        "roadSideAssistance",
        "imt23",
        "keyReplace",
        "engineProtector",
        "ncbProtection",
        "consumables",
        "tyreSecure",
        "returnToInvoice",
        "lopb",
        "emergencyMedicalExpenses",
      ];

      let inbuiltList = quote?.addOnsData?.inBuilt;
      let totalInbuilt = 0;
      allAddons.forEach((el) => {
        if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
          totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
        }
      });
      //other
      let others =
        (quote?.addOnsData?.other && Object.keys(quote?.addOnsData?.other)) ||
        [];
      let othersList = quote?.addOnsData?.other;
      let totalOther = 0;
      others.forEach((el) => {
        if (typeof othersList[el] === "number") {
          totalOther = totalOther + Number(othersList[el]);
        }
      });

      let extraOtherDiscounts2 =
        (Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther)) *
        discountPercentageOriental;
      //----------------------------------------------------------------------------------------

      // NCB has ZD in calc for oriental hence using extradiscounts calc using zd
      addedNcb =
        ((totalAddon - extraOtherDiscounts2) * Number(quote?.ncbDiscount)) /
        100;

      setRevisedNcb(Number(quote?.deductionOfNcb) + Number(addedNcb));
      setOtherDiscounts(
        (quote?.icVehicleDiscount || 0) + Number(extraOtherDiscounts2)
      );
      setTotalPremiumC(
        Number(quote?.finalTotalDiscount) +
          Number(addedNcb) +
          Number(extraOtherDiscounts2)
      );
    } else if (
      (((selectedAddons?.includes("imt23") &&
        additional?.includes("imt23") &&
        typeof additionalList["imt23"] === "number") ||
        inbuilt?.includes("imt23")) &&
        quote?.company_alias === "hdfc_ergo") ||
      quote?.company_alias === "godigit" ||
      quote?.company_alias === "shriram" ||
      quote?.company_alias === "reliance" ||
      quote?.company_alias === "sbi"
    ) {
      if (
        selectedAddons?.includes("imt23") &&
        additional?.includes("imt23") &&
        typeof additionalList["imt23"] === "number"
      ) {
        addedNcb =
          (Number(additionalList["imt23"]) * Number(quote?.ncbDiscount)) / 100;
        setOtherDiscounts(quote?.icVehicleDiscount || 0);
      } else if (
        inbuilt?.includes("imt23") &&
        typeof inbuiltList["imt23"] === "number"
      ) {
        //normal calculaations go digit calculations
        addedNcb =
          (Number(inbuiltList["imt23"]) * Number(quote?.ncbDiscount)) / 100;
        setOtherDiscounts(quote?.icVehicleDiscount || 0);
      }
      setRevisedNcb(Number(quote?.deductionOfNcb) + Number(addedNcb));
      setTotalPremiumC(Number(quote?.finalTotalDiscount) + Number(addedNcb));
    } else if (
      ((selectedAddons?.includes("imt23") &&
        additional?.includes("imt23") &&
        typeof additionalList["imt23"] === "number") ||
        inbuilt?.includes("imt23")) &&
      quote?.company_alias === "icici_lombard" &&
      temp_data?.journeyCategory === "GCV"
    ) {
      setRevisedNcb(
        ((totalPremiumA +
          (selectedAddons?.includes("imt23") &&
          additional?.includes("imt23") &&
          additionalList["imt23"] * 1
            ? additionalList["imt23"] * 1
            : inbuiltList["imt23"] * 1)) *
          Number(quote?.ncbDiscount)) /
          100
      );
      let othrDiscount =
        quote?.icVehicleDiscount * 1 ? quote?.icVehicleDiscount * 1 : 0;
      setTotalPremiumC(
        ((totalPremiumA +
          (selectedAddons?.includes("imt23") &&
          additional?.includes("imt23") &&
          additionalList["imt23"] * 1
            ? additionalList["imt23"] * 1
            : inbuiltList["imt23"] * 1)) *
          Number(quote?.ncbDiscount)) /
          100 +
          othrDiscount +
          (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0)
      );
      setOtherDiscounts(othrDiscount);
    } else if (TypeReturn(type) === "cv" && quote?.company_alias === "magma") {
      if (
        ((selectedAddons?.includes("imt23") &&
          additional?.includes("imt23") &&
          typeof additionalList["imt23"] === "number") ||
          inbuilt?.includes("imt23")) &&
        quote?.company_alias === "magma"
      ) {
        if (quote?.imt23Discount * 1) {
          let othrDiscount =
            (quote?.icVehicleDiscount * 1 ? quote?.icVehicleDiscount * 1 : 0) +
            quote?.imt23Discount * 1;
          setRevisedNcb(
            ((totalPremiumA +
              (selectedAddons?.includes("imt23") &&
              additional?.includes("imt23") &&
              additionalList["imt23"] * 1
                ? additionalList["imt23"] * 1
                : inbuiltList["imt23"] * 1) -
              othrDiscount) *
              Number(quote?.ncbDiscount)) /
              100
          );
          setTotalPremiumC(
            ((totalPremiumA +
              (selectedAddons?.includes("imt23") &&
              additional?.includes("imt23") &&
              additionalList["imt23"] * 1
                ? additionalList["imt23"] * 1
                : inbuiltList["imt23"] * 1) -
              othrDiscount) *
              Number(quote?.ncbDiscount)) /
              100 +
              othrDiscount +
              (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0)
          );
          setOtherDiscounts(othrDiscount);
        } else {
          let othrDiscount =
            quote?.icVehicleDiscount * 1 ? quote?.icVehicleDiscount * 1 : 0;
          setRevisedNcb(
            ((totalPremiumA +
              (selectedAddons?.includes("imt23") &&
              additional?.includes("imt23") &&
              additionalList["imt23"] * 1
                ? additionalList["imt23"] * 1
                : inbuiltList["imt23"] * 1) -
              othrDiscount) *
              Number(quote?.ncbDiscount)) /
              100
          );
          setTotalPremiumC(
            ((totalPremiumA +
              (selectedAddons?.includes("imt23") &&
              additional?.includes("imt23") &&
              additionalList["imt23"] * 1
                ? additionalList["imt23"] * 1
                : inbuiltList["imt23"] * 1) -
              othrDiscount) *
              Number(quote?.ncbDiscount)) /
              100 +
              othrDiscount +
              (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0)
          );
          setOtherDiscounts(othrDiscount);
        }
      } else {
        let othrDiscount =
          quote?.icVehicleDiscount * 1 ? quote?.icVehicleDiscount * 1 : 0;
        setRevisedNcb(
          ((totalPremiumA - othrDiscount) * Number(quote?.ncbDiscount)) / 100
        );
        setTotalPremiumC(
          ((totalPremiumA - othrDiscount) * Number(quote?.ncbDiscount)) / 100 +
            othrDiscount +
            (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0)
        );
        setOtherDiscounts(othrDiscount);
      }
    } else if (
      ((selectedAddons?.includes("imt23") &&
        additional?.includes("imt23") &&
        typeof additionalList["imt23"] === "number") ||
        inbuilt?.includes("imt23")) &&
      quote?.company_alias === "bajaj_allianz" &&
      temp_data?.journeyCategory === "GCV" &&
      quote?.isCvJsonKit
    ) {
      if (
        (selectedAddons?.includes("imt23") &&
          additional?.includes("imt23") &&
          typeof additionalList["imt23"] === "number") ||
        (inbuilt?.includes("imt23") && typeof inbuiltList["imt23"] === "number")
      ) {
        setRevisedNcb(
          ((totalPremiumA +
            (selectedAddons?.includes("imt23") &&
            additional?.includes("imt23") &&
            additionalList["imt23"] * 1
              ? additionalList["imt23"] * 1
              : inbuiltList["imt23"] * 1)) *
            Number(quote?.ncbDiscount)) /
            100
        );
        //Ic vehicle discount  + 15% IC vehicle discount if imt-23 is selected
        let othrDiscount =
          (quote?.icVehicleDiscount * 1 ? quote?.icVehicleDiscount * 1 : 0) *
          1.15;
        setTotalPremiumC(
          ((totalPremiumA +
            (selectedAddons?.includes("imt23") &&
            additional?.includes("imt23") &&
            additionalList["imt23"] * 1
              ? additionalList["imt23"] * 1
              : inbuiltList["imt23"] * 1)) *
            Number(quote?.ncbDiscount)) /
            100 +
            othrDiscount +
            (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0)
        );
        setOtherDiscounts(othrDiscount);

        console.log(
          "sdfsdfsdfsdfsdf",
          ((totalPremiumA +
            (additional?.includes("imt23") && additionalList["imt23"] * 1
              ? additionalList["imt23"] * 1
              : inbuiltList["imt23"] * 1)) *
            Number(quote?.ncbDiscount)) /
            100 +
            othrDiscount
        );
      }
    } else if (
      ((selectedAddons?.includes("imt23") &&
        additional?.includes("imt23") &&
        typeof additionalList["imt23"] === "number") ||
        inbuilt?.includes("imt23")) &&
      quote?.company_alias === "universal_sompo" &&
      temp_data?.journeyCategory === "GCV" &&
      quote?.isCvJsonKit
    ) {
      if (
        (selectedAddons?.includes("imt23") &&
          additional?.includes("imt23") &&
          typeof additionalList["imt23"] === "number") ||
        inbuilt?.includes("imt23")
      ) {
        setRevisedNcb(Number(quote?.deductionOfNcb) * 1.15);
        //Ic vehicle discount  + 15% IC vehicle discount if imt-23 is selected
        let othrDiscount =
          (quote?.icVehicleDiscount * 1 ? quote?.icVehicleDiscount * 1 : 0) *
          1.15;
        setTotalPremiumC(
          Number(quote?.deductionOfNcb) * 1.15 +
            othrDiscount +
            (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0)
        );
        setOtherDiscounts(othrDiscount);
      }
    } else if (
      quote?.company_alias === "royal_sundaram" &&
      TypeReturn(type) === "car" &&
      addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")
    ) {
      const g1 = [
        "zeroDepreciation",
        "returnToInvoice",
        "ncbProtection",
        "engineProtector",
      ]; // 10 % on final addons
      const g2 = [
        "zeroDepreciation",
        "returnToInvoice",
        "ncbProtection",
        "lopb",
        "engineProtector",
      ]; //15% on finaladdons
      const g3 = [
        "zeroDepreciation",
        "returnToInvoice",
        "ncbProtection",
        "lopb",
        "tyreSecure",
        "keyReplace",
        "engineProtector",
      ]; // 20 % on final addons
      let addonsSelectedKeys = addOnsAndOthers?.selectedAddons;
      let addonsSelected = _.compact(
        addonsSelectedKeys.map((v) =>
          Object.keys(quote?.addOnsData?.inBuilt).includes(v) ||
          quote?.addOnsData?.additional[v] * 1
            ? v
            : false
        )
      );
      console.log("addonsSelectedaddonsSelectedaddonsSelected", addonsSelected);
      if (_.intersection(g3, addonsSelected)?.length >= 4) {
        setAddonDiscount(parseInt(0.2 * totalAddon));
        setAddonDiscountPercentage(20);
        setRevisedNcb(Number(quote?.deductionOfNcb));
        setTotalPremiumC(Number(quote?.finalTotalDiscount));
      } else if (_.intersection(g2, addonsSelected)?.length === 3) {
        setAddonDiscount(parseInt(0.15 * totalAddon));
        setAddonDiscountPercentage(15);
        setRevisedNcb(Number(quote?.deductionOfNcb));
        setTotalPremiumC(Number(quote?.finalTotalDiscount));
      } else if (_.intersection(g1, addonsSelected)?.length === 2) {
        setAddonDiscount(parseInt(0.1 * totalAddon));
        setAddonDiscountPercentage(10);
        setRevisedNcb(Number(quote?.deductionOfNcb));
        setTotalPremiumC(Number(quote?.finalTotalDiscount));
      } else {
        setAddonDiscount(0);
        setAddonDiscountPercentage(0);
        setRevisedNcb(Number(quote?.deductionOfNcb));
        setTotalPremiumC(Number(quote?.finalTotalDiscount));
      }
      setOtherDiscounts(quote?.icVehicleDiscount || 0);
    } else if (
      quote?.company_alias === "royal_sundaram" &&
      TypeReturn(type) === "car" &&
      !addOnsAndOthers?.selectedAddons?.includes("zeroDepreciation")
    ) {
      setAddonDiscount(0);
      setAddonDiscountPercentage(0);
      setRevisedNcb(Number(quote?.deductionOfNcb));
      setTotalPremiumC(Number(quote?.finalTotalDiscount));

      setOtherDiscounts(quote?.icVehicleDiscount || 0);
    }
    // else if (
    //   quote?.company_alias === "hdfc_ergo" &&
    //   temp_data?.journeyCategory !== "GCV"
    // ) {
    //   setRevisedNcb(
    //     Number((totalPremiumA * 1 * Number(quote?.ncbDiscount)) / 100)
    //   );
    //   setTotalPremiumC(
    //     Number(quote?.finalTotalDiscount) +
    //       Number((totalPremiumA * 1 * Number(quote?.ncbDiscount)) / 100) -
    //       Number(quote?.deductionOfNcb)
    //   );
    //   setOtherDiscounts(quote?.icVehicleDiscount || 0);
    // }
    else if (
      quote?.company_alias === "oriental" &&
      TypeReturn(type) === "car"
    ) {
      // re-eval required addons with others
      let totalAdditional = 0;
      selectedAddons.forEach((el) => {
        if (
          additional?.includes(
            ![
              "zeroDepreciation",
              "engineProtector",
              "returnToInvoice",
              "lopb",
            ].includes(el)
              ? "nomatch"
              : el
          ) &&
          typeof additionalList[el] === "number"
        ) {
          totalAdditional = totalAdditional + Number(additionalList[el]);
        }
      });
      //Inbuilt
      let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
      let allAddons = [
        "zeroDepreciation",
        "engineProtector",
        "returnToInvoice",
        "lopb",
      ];

      let inbuiltList = quote?.addOnsData?.inBuilt;
      let totalInbuilt = 0;
      allAddons.forEach((el) => {
        if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
          totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
        }
      });
      //other
      let others =
        (quote?.addOnsData?.other && Object.keys(quote?.addOnsData?.other)) ||
        [];
      let othersList = quote?.addOnsData?.other;
      let totalOther = 0;
      others.forEach((el) => {
        if (typeof othersList[el] === "number") {
          totalOther = totalOther + Number(othersList[el]);
        }
      });

      let NcbTotalAddon =
        Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);

      setRevisedNcb(
        ((totalPremiumA * 1 +
          NcbTotalAddon * 1 -
          (Number(quote?.finalTotalDiscount) -
            Number(quote?.deductionOfNcb) -
            (Number(quote.tppdDiscount) ? Number(quote.tppdDiscount) : 0))) *
          Number(quote?.ncbDiscount)) /
          100
      );
      setTotalPremiumC(
        Number(quote?.finalTotalDiscount) -
          //deducting the ncb sent by backend
          Number(quote?.deductionOfNcb) +
          //calculating ncb and adding it to total discount
          ((totalPremiumA * 1 +
            NcbTotalAddon * 1 -
            (Number(quote?.finalTotalDiscount) -
              Number(quote?.deductionOfNcb) -
              (Number(quote.tppdDiscount) ? Number(quote.tppdDiscount) : 0))) *
            Number(quote?.ncbDiscount)) /
            100
      );
      setOtherDiscounts(quote?.icVehicleDiscount || 0);
    } else if (
      quote?.company_alias === "united_india" &&
      TypeReturn(type) === "car"
    ) {
      // re-eval required addons with others
      let totalAdditional = 0;
      selectedAddons.forEach((el) => {
        if (
          additional?.includes(
            !["zeroDepreciation", "returnToInvoice", "lopb"].includes(el)
              ? "nomatch"
              : el
          ) &&
          typeof additionalList[el] === "number"
        ) {
          totalAdditional = totalAdditional + Number(additionalList[el]);
        }
      });
      //Inbuilt
      let inbuilt = Object.keys(quote?.addOnsData?.inBuilt);
      let allAddons = ["zeroDepreciation", "returnToInvoice", "lopb"];

      let inbuiltList = quote?.addOnsData?.inBuilt;
      let totalInbuilt = 0;
      allAddons.forEach((el) => {
        if (inbuilt?.includes(el) && typeof inbuiltList[el] === "number") {
          totalInbuilt = totalInbuilt + Number(inbuiltList[el]);
        }
      });
      //other
      let others =
        (quote?.addOnsData?.other && Object.keys(quote?.addOnsData?.other)) ||
        [];
      let othersList = quote?.addOnsData?.other;
      let totalOther = 0;
      others.forEach((el) => {
        if (typeof othersList[el] === "number") {
          totalOther = totalOther + Number(othersList[el]);
        }
      });

      let NcbTotalAddon =
        Number(totalAdditional) + Number(totalInbuilt) + Number(totalOther);

      setRevisedNcb(
        ((totalPremiumA * 1 +
          NcbTotalAddon * 1 -
          (Number(quote?.finalTotalDiscount) -
            Number(quote?.deductionOfNcb) -
            (Number(quote.tppdDiscount) ? Number(quote.tppdDiscount) : 0))) *
          Number(quote?.ncbDiscount)) /
          100
      );
      setTotalPremiumC(
        Number(quote?.finalTotalDiscount) -
          //deducting the ncb sent by backend
          Number(quote?.deductionOfNcb) +
          //calculating ncb and adding it to total discount
          ((totalPremiumA * 1 +
            NcbTotalAddon * 1 -
            (Number(quote?.finalTotalDiscount) -
              Number(quote?.deductionOfNcb) -
              (Number(quote.tppdDiscount) ? Number(quote.tppdDiscount) : 0))) *
            Number(quote?.ncbDiscount)) /
            100
      );
      setOtherDiscounts(quote?.icVehicleDiscount || 0);
    } else {
      console.log("oops", quote?.companyAlias);
      setRevisedNcb(Number(quote?.deductionOfNcb));
      setTotalPremiumC(Number(quote?.finalTotalDiscount));
      setOtherDiscounts(quote?.icVehicleDiscount || 0);
    }
  }, [
    addOnsAndOthers?.deductionOfNcb,
    sort,
    addOnsAndOthers?.selectedAddons,
    quote?.finalTotalDiscount,
    quote,
    totalAddon,
    quote?.finalOdPremium,
    totalPremiumA,
  ]);

  //--------------------- total premium calculation----------------------

  //hdfc ergo Ncb Calc

  //   useEffect(() => {
  // if(quote?.company_alias ==)
  //   } ,[totalPremiumA])

  //	premium calculation sriram bike < 100 loading case

  const [uwLoading, setUwLoading] = useState(0);

  useEffect(() => {
    // if (_.isEmpty(applicableAddons)) {
    if (
      quote?.companyAlias === "shriram" &&
      TypeReturn(type) === "bike" &&
      (quote?.policyType === "Comprehensive" ||
        quote?.policyType === "Own Damage") &&
      totalPremiumA +
        totalAddon -
        totalPremiumC +
        (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0) <
        50
    ) {
      setUwLoading(
        50 -
          (totalPremiumA +
            totalAddon -
            totalPremiumC +
            (quote?.tppdDiscount * 1 ? quote?.tppdDiscount * 1 : 0))
      );
    } else {
      setUwLoading(0);
    }
    // }
    // else {
    //   setUwLoading(0);
    // }
  }, [totalPremiumA, totalPremiumC, quote, applicableAddons, totalAddon]);

  //extra loading for IC bike

  const [extraLoading, setExtraLoading] = useState(0);

  useEffect(() => {
    if (
      // quote?.company_alias === "bajaj_allianz" ||
      // quote?.company_alias === "sbi" ||
      // quote?.company_alias === "icici_lombard" ||
      // quote?.company_alias === "oriental" ||
      // quote?.company_alias === "reliance" ||
      true
    ) {
      if (
        Number(quote?.totalLoadingAmount) > 0 ||
        Number(quote?.underwritingLoadingAmount)
      ) {
        if (
          Number(quote?.totalLoadingAmount) > 0 &&
          Number(quote?.underwritingLoadingAmount)
        ) {
          setExtraLoading(
            Number(quote?.totalLoadingAmount) +
              Number(quote?.underwritingLoadingAmount)
          );
        }
        if (
          Number(quote?.totalLoadingAmount) > 0 &&
          !Number(quote?.underwritingLoadingAmount)
        ) {
          setExtraLoading(Number(quote?.totalLoadingAmount));
        }
        if (
          !Number(quote?.totalLoadingAmount) > 0 &&
          Number(quote?.underwritingLoadingAmount)
        ) {
          setExtraLoading(Number(quote?.underwritingLoadingAmount));
        }
      } else {
        setExtraLoading(0);
      }
    } else {
      setExtraLoading(0);
    }
  }, [quote?.totalLoadingAmount, quote?.underwritingLoadingAmount]);

  //toatlprem
  // quote.company_alias === "royal_sundaram" &&
  //   console.log("quoteq", {
  //     totalPremium,
  //     gst,
  //     totalAddon,
  //     totalPremiumA,
  //     totalPremiumB,
  //     totalPremiumC,
  //     uwLoading,
  //     extraLoading,
  //   });

  useEffect(() => {
    if (totalPremium && quote?.noCalculation !== "Y") {
      setTotalPremium(
        Number(totalPremiumA) +
          Number(totalPremiumB) +
          Number(totalAddon) -
          Number(totalPremiumC) +
          Number(uwLoading) +
          Number(extraLoading)
      );

      if (totalPremium) {
        if (temp_data?.journeyCategory === "GCV") {
          if (quote?.company_alias === "oriental") {
            //In Oriental , TPPD discount is not added to third party liability for GST calc
            setTotalPremiumGst(
              parseInt(
                ((totalPremium * 1 - quote?.tppdPremiumAmount) * 18) / 100
              ) +
                (quote?.tppdPremiumAmount * 12) / 100
            );
          } else if (quote?.company_alias === "sbi") {
            //In sbi , Basic tp - 12%, rest 18%
            setTotalPremiumGst(
              parseInt(
                ((totalPremium * 1 - quote?.tppdPremiumAmount) * 18) / 100
              ) +
                (quote?.tppdPremiumAmount * 12) / 100
            );
          } else if (quote?.company_alias === "godigit") {
            //   // GST calc for other IC's in GCV
            setTotalPremiumGst(
              parseInt(
                //basic tp
                ((quote?.tppdPremiumAmount -
                  //tppd discount
                  (Number(quote?.tppdDiscount)
                    ? Number(quote?.tppdDiscount)
                    : 0) +
                  //cng/lpg
                  (quote?.cngLpgTp * 1 ? quote?.cngLpgTp * 1 : 0)) *
                  12) /
                  100 +
                  //rest of the liability values
                  ((totalPremiumB -
                    quote?.tppdPremiumAmount +
                    //total od + addons - ncb
                    totalPremiumA +
                    totalAddon -
                    (totalPremiumC -
                      (Number(quote?.tppdDiscount)
                        ? Number(quote?.tppdDiscount)
                        : 0)) -
                    (quote?.cngLpgTp * 1 ? quote?.cngLpgTp * 1 : 0)) *
                    18) /
                    100
              )
            );
          } else if (quote?.company_alias === "universal_sompo") {
            // GST calc for other IC's in GCV
            setTotalPremiumGst(
              parseInt(
                ((totalPremium -
                  quote?.tppdPremiumAmount +
                  (Number(quote?.tppdDiscount)
                    ? Number(quote?.tppdDiscount)
                    : 0)) *
                  18) /
                  100 +
                  (quote?.tppdPremiumAmount * 0.12 -
                    (Number(quote?.tppdDiscount)
                      ? Number(quote?.tppdDiscount)
                      : 0) *
                      0.18)
              )
            );
          } else {
            // GST calc for other IC's in GCV
            setTotalPremiumGst(
              parseInt(
                ((totalPremium -
                  quote?.tppdPremiumAmount +
                  (Number(quote?.tppdDiscount)
                    ? Number(quote?.tppdDiscount)
                    : 0)) *
                  18) /
                  100 +
                  ((quote?.tppdPremiumAmount -
                    (Number(quote?.tppdDiscount)
                      ? Number(quote?.tppdDiscount)
                      : 0)) *
                    12) /
                    100
              )
            );
          }
        } else {
          setTotalPremiumGst(Math.round(Number(totalPremium * 0.18)));
          // console.log(
          //   "gstcal",
          //   Number(totalPremium * 0.18),
          //   totalPremium,
          //   quote?.company_alias,
          //   gst,
          //   extraLoading
          // );
        }
        console.log(
          "setTotalPremiumGst",
          totalPremiumB -
            (Number(quote?.tppdDiscount) ? Number(quote?.tppdDiscount) : 0),
          totalPremiumA,
          totalAddon,
          totalPremiumC,
          ((totalPremiumB -
            quote?.tppdPremiumAmount +
            //total od + addons - ncb
            totalPremiumA +
            totalAddon -
            (totalPremiumC -
              (Number(quote?.tppdDiscount)
                ? Number(quote?.tppdDiscount)
                : 0))) *
            18) /
            100
        );
        if (gst) {
          setFinalPremium(Math.round(Number(totalPremium) + Number(gst)));
        }
      }
    } else {
      if (quote?.noCalculation === "Y") {
        //total premium
        setTotalPremium(Number(quote?.finalNetPremium));
        //gst
        setTotalPremiumGst(quote?.serviceTaxAmount);
        //finalPremium with gst
        setFinalPremium(quote?.finalPayableAmount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    quote,
    totalPremium,
    totalAddon,
    gst,
    totalPremiumA,
    totalPremiumB,
    totalPremiumC,
    sort,
    quote?.finalTotalDiscount,
    quote?.finalOdPremium,
    quote?.tppdPremiumAmount,
    quote?.tppdDiscount,
    uwLoading,
    extraLoading,
  ]);

  //-----------------setting changed premium for premiuym recalculation-----------------

  useEffect(() => {
    if (tempData?.oldPremium && finalPremium) {
      setDifference(tempData?.oldPremium - finalPremium);
    } else {
      setDifference(false);
    }
  }, [finalPremium]);

  //------------------finalPremiumSave---------------------

  useEffect(() => {
    if (finalPremium && gst && onCompare) {
      var data = [
        {
          finalPremium: finalPremium,
          gst: gst,
          policyId: quote?.policyId,
          totalPremiumB: totalPremiumB,
          applicableAddons: applicableAddons,
          addonPremiumTotal: totalAddon,
        },
      ];
      dispatch(setFinalPremiumList(data));
    }
  }, [finalPremium, gst, onCompare]);

  useEffect(() => {
    if (finalPremium && gst && (sendQuotes || tempData?.sendQuote)) {
      var data = [
        {
          finalPremium: finalPremium,
          finalPremiumNoGst: totalPremium,
          gst: gst,
          policyId: quote?.policyId,
          name: quote?.companyName,
          idv: quote?.idv,
          logo: quote?.companyLogo,
          productName: quote?.productName,
          policyType: quote?.policyType,
        },
      ];
      dispatch(setFinalPremiumList1(data));
    } else {
      dispatch(clearFinalPremiumList());
    }
  }, [finalPremium, gst, sendQuotes, tempData?.sendQuote]);

  //------------expiry logic-----------------------

  const [daysToExpiry, setDaysToExpiry] = useState(false);

  useEffect(() => {
    let a = temp_data?.expiry;
    let b = moment().format("DD-MM-YYYY");
    let diffDays = a && b && differenceInDays(toDate(b), toDate(a));
    setDaysToExpiry(diffDays);
  }, [temp_data?.expiry]);

  //-----------------previous ic condition for popup-----------------
  let prevInsName = prevInsList.filter((i) => i.tataAig === temp_data?.prevIc);

  const [prevIcData, setPrevIcData] = useState(false);

  useEffect(() => {
    if (
      temp_data?.prevIc &&
      temp_data?.prevIc !== "others" &&
      temp_data?.prevIc !== "Not selected"
    ) {
      setPrevIcData(true);
    } else {
      setPrevIcData(false);
    }
  }, [temp_data?.prevIc]);

  //---------------handling buy now button click-----------------

  const handleClick = async () => {
    dispatch(setLoadingFromPDf(true));
    dispatch(CancelAll(true));

    if (
      ((quote?.policyType === "Third Party" &&
        process.env?.REACT_APP_BROKER === "GRAM") ||
        tempData?.policyType === "Third-party") &&
      // ||(temp_data?.tab === "tab1" && daysToExpiry > 90))
      !prevIcData &&
      !temp_data?.fastlaneNcbPopup &&
      !temp_data?.newCar &&
      daysToExpiry <= 90
    ) {
      setQuoteData({
        enquiryId: temp_data?.enquiry_id || enquiry_id,
        icId: quote?.companyId,
        icAlias: quote?.companyName,
        productSubTypeId: quote?.productSubTypeId,
        masterPolicyId: quote?.masterPolicyId?.policyId,
        premiumJson: {
          ...quote,
          deductionOfNcb: revisedNcb,
          ...(temp_data?.odOnly && { IsOdBundledPolicy: "Y" }),
          ...(quote?.companyAlias === "royal_sundaram" && {
            icAddress: displayAddress,
            addOnsData: {
              ...quote?.addOnsData,
              ...(!_.isEmpty(quote?.addOnsData?.additional)
                ? {
                    additional: Object.fromEntries(
                      Object.entries(quote?.addOnsData?.additional).map(
                        ([k, v]) => [
                          k,
                          v * 1 !== 0
                            ? [
                                "ncbProtection",
                                "engineProtector",
                                "tyreSecure",
                              ].includes(k)
                              ? v - (v * addonDiscountPercentage) / 100 < 200
                                ? 200
                                : v - (v * addonDiscountPercentage) / 100
                              : k === "roadSideAssistance"
                              ? v
                              : v - (v * addonDiscountPercentage) / 100 < 100
                              ? 100
                              : v - (v * addonDiscountPercentage) / 100
                            : 0,
                        ]
                      )
                    ),
                  }
                : {}),
              ...(!_.isEmpty(quote?.addOnsData?.inBuilt)
                ? {
                    inBuilt: Object.fromEntries(
                      Object.entries(quote?.addOnsData?.inBuilt).map(
                        ([k, v]) => [
                          k,
                          v * 1 !== 0
                            ? [
                                "ncbProtection",
                                "engineProtector",
                                "tyreSecure",
                              ].includes(k)
                              ? v - (v * addonDiscountPercentage) / 100 < 200
                                ? 200
                                : v - (v * addonDiscountPercentage) / 100
                              : k === "roadSideAssistance"
                              ? v
                              : v - (v * addonDiscountPercentage) / 100 < 100
                              ? 100
                              : v - (v * addonDiscountPercentage) / 100
                            : 0,
                        ]
                      )
                    ),
                  }
                : {}),
            },
          }),
          ...(quote?.companyAlias === "sbi" &&
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) &&
            !_.isEmpty(addOnsAndOthers?.isTenure) &&
            quote?.coverUnnamedPassengerValue * 1 && {
              coverUnnamedPassengerValue:
                quote?.coverUnnamedPassengerValue *
                (TypeReturn(type) === "bike" ? 5 : 3),
            }),
          ...(quote?.companyAlias === "sbi" &&
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) &&
            !_.isEmpty(addOnsAndOthers?.isTenure) &&
            quote?.motorAdditionalPaidDriver * 1 && {
              motorAdditionalPaidDriver:
                quote?.motorAdditionalPaidDriver *
                (TypeReturn(type) === "bike" ? 5 : 3),
            }),
        },
        exShowroomPriceIdv: quote?.idv,
        exShowroomPrice: quote?.showroomPrice,
        finalPremiumAmount: finalPremium,
        odPremium: totalPremiumA,
        tpPremium: totalPremiumB,
        addonPremiumTotal: totalAddon,
        serviceTax: gst,
        revisedNcb: revisedNcb,
        applicableAddons:
          quote?.companyAlias === "royal_sundaram"
            ? !_.isEmpty(applicableAddons)
              ? applicableAddons?.map((el) => ({
                  ...el,
                  ...{
                    premium:
                      el?.premium * 1 !== 0
                        ? [
                            "NCB Protection",
                            "Engine Protector",
                            "Tyre Secure",
                          ].includes(el?.name)
                          ? el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100 <
                            200
                            ? 200
                            : el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100
                          : el?.name === "Road Side Assistance"
                          ? el?.premium
                          : el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100 <
                            100
                          ? 100
                          : el?.premium -
                            (el?.premium * addonDiscountPercentage) / 100
                        : 0,
                  },
                }))
              : []
            : applicableAddons,
        prevInsName: prevInsName[0]?.previousInsurer,
      });
      setPrevPopupTp(true);
    } else if (
      !temp_data?.newCar &&
      !prevIcData &&
      !popupCard &&
      tempData?.policyType !== "Third-party" &&
      (quote?.policyType === "Comprehensive" ||
        quote?.policyType === "Short Term" ||
        quote?.policyType === "Own Damage") &&
      daysToExpiry <= 90
    ) {
      setPrevPopup(true);
      setSelectedId(quote?.policyId);
      setSelectedCompanyName(quote?.companyName);
      setSelectedCompanyAlias(quote?.company_alias);
      setApplicableAddonsLits(
        !_.isEmpty(applicableAddons) && applicableAddons.map((x) => x.name)
      );
      setSelectedIcId(quote?.companyId);
      dispatch(
        setTempData({
          oldPremium: finalPremium,
        })
      );
    } else if (
      !prevPopup &&
      (temp_data?.newCar ||
        prevIcData ||
        !temp_data?.fastlaneNcbPopup ||
        quote?.policyType === "Third Party" ||
        tempData?.policyType === "Third-party" ||
        daysToExpiry > 90)
    ) {
      dispatch(setSelectedQuote(quote));

      if (
        temp_data?.tab === "tab2" ||
        tempData?.policyType === "Third-party" ||
        daysToExpiry > 90
      ) {
        if (temp_data?.tab === "tab2") {
          var newSelectedAccesories = [];
          if (
            addOnsAndOthers?.selectedAccesories?.includes(
              "External Bi-Fuel Kit CNG/LPG"
            )
          ) {
            var newD = {
              name: "External Bi-Fuel Kit CNG/LPG",
              sumInsured: Number(addOnsAndOthers?.externalBiFuelKit),
            };
            newSelectedAccesories.push(newD);
          }
          var discount = [];

          if (addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover")) {
            discount.push({ name: "TPPD Cover" });
          }
          if (
            addOnsAndOthers?.selectedDiscount?.includes(
              "Vehicle Limited to Own Premises"
            )
          ) {
            discount.push({ name: "Vehicle Limited to Own Premises" });
          }
          var data1 = {
            enquiryId: temp_data?.enquiry_id || enquiry_id,

            addonData: {
              addons: null,
              accessories: newSelectedAccesories,
              discounts: discount,
            },
          };

          dispatch(SaveAddonsData(data1));
        }
      } else {
        let addonLists = [];
        let addonListRedux = addOnsAndOthers?.selectedAddons || [];
        addonListRedux.forEach((el) => {
          let data = {
            name: getAddonName(el),
          };
          addonLists.push(data);
        });

        var data2 = {
          enquiryId: temp_data?.enquiry_id || enquiry_id,

          addonData: {
            addons: addonLists,
            compulsory_personal_accident:
              addOnsAndOthers?.selectedCpa?.includes(
                "Compulsory Personal Accident"
              )
                ? [
                    {
                      name: "Compulsory Personal Accident",
                      ...(!_.isEmpty(_.compact(addOnsAndOthers?.isTenure)) && {
                        tenure: TypeReturn(type) === "car" ? 3 : 5,
                      }),
                    },
                  ]
                : [
                    {
                      reason:
                        "I have another motor policy with PA owner driver cover in my name",
                    },
                  ],
          },
        };
        dispatch(SaveAddonsData(data2));
      }

      var QuoteData = {
        enquiryId: temp_data?.enquiry_id || enquiry_id,
        icId: quote?.companyId,
        icAlias: quote?.companyName,
        productSubTypeId: quote?.productSubTypeId,
        masterPolicyId: quote?.masterPolicyId?.policyId,
        premiumJson: {
          ...quote,
          deductionOfNcb: revisedNcb,
          ...(temp_data?.odOnly && { IsOdBundledPolicy: "Y" }),
          ...(quote?.companyAlias === "royal_sundaram" && {
            icAddress: displayAddress,
            addOnsData: {
              ...quote?.addOnsData,
              ...(!_.isEmpty(quote?.addOnsData?.additional)
                ? {
                    additional: Object.fromEntries(
                      Object.entries(quote?.addOnsData?.additional).map(
                        ([k, v]) => [
                          k,
                          v * 1 !== 0
                            ? [
                                "ncbProtection",
                                "engineProtector",
                                "tyreSecure",
                              ].includes(k)
                              ? v - (v * addonDiscountPercentage) / 100 < 200
                                ? 200
                                : v - (v * addonDiscountPercentage) / 100
                              : k === "roadSideAssistance"
                              ? v
                              : v - (v * addonDiscountPercentage) / 100 < 100
                              ? 100
                              : v - (v * addonDiscountPercentage) / 100
                            : 0,
                        ]
                      )
                    ),
                  }
                : {}),
              ...(!_.isEmpty(quote?.addOnsData?.inBuilt)
                ? {
                    inBuilt: Object.fromEntries(
                      Object.entries(quote?.addOnsData?.inBuilt).map(
                        ([k, v]) => [
                          k,
                          v * 1 !== 0
                            ? [
                                "ncbProtection",
                                "engineProtector",
                                "tyreSecure",
                              ].includes(k)
                              ? v - (v * addonDiscountPercentage) / 100 < 200
                                ? 200
                                : v - (v * addonDiscountPercentage) / 100
                              : k === "roadSideAssistance"
                              ? v
                              : v - (v * addonDiscountPercentage) / 100 < 100
                              ? 100
                              : v - (v * addonDiscountPercentage) / 100
                            : 0,
                        ]
                      )
                    ),
                  }
                : {}),
            },
          }),
          ...(quote?.companyAlias === "sbi" &&
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) &&
            !_.isEmpty(addOnsAndOthers?.isTenure) &&
            quote?.coverUnnamedPassengerValue * 1 && {
              coverUnnamedPassengerValue:
                quote?.coverUnnamedPassengerValue *
                (TypeReturn(type) === "bike" ? 5 : 3),
            }),
          ...(quote?.companyAlias === "sbi" &&
            addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) &&
            !_.isEmpty(addOnsAndOthers?.isTenure) &&
            quote?.motorAdditionalPaidDriver * 1 && {
              motorAdditionalPaidDriver:
                quote?.motorAdditionalPaidDriver *
                (TypeReturn(type) === "bike" ? 5 : 3),
            }),
        },
        exShowroomPriceIdv: quote?.idv,
        exShowroomPrice: quote?.showroomPrice,
        finalPremiumAmount: finalPremium,
        odPremium: totalPremiumA,
        tpPremium: totalPremiumB,
        addonPremiumTotal: totalAddon,
        serviceTax: gst,
        revisedNcb: revisedNcb,
        applicableAddons:
          quote?.companyAlias === "royal_sundaram"
            ? !_.isEmpty(applicableAddons)
              ? applicableAddons?.map((el) => ({
                  ...el,
                  ...{
                    premium:
                      el?.premium * 1 !== 0
                        ? [
                            "NCB Protection",
                            "Engine Protector",
                            "Tyre Secure",
                          ].includes(el?.name)
                          ? el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100 <
                            200
                            ? 200
                            : el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100
                          : el?.name === "Road Side Assistance"
                          ? el?.premium
                          : el?.premium -
                              (el?.premium * addonDiscountPercentage) / 100 <
                            100
                          ? 100
                          : el?.premium -
                            (el?.premium * addonDiscountPercentage) / 100
                        : 0,
                  },
                }))
              : []
            : applicableAddons,
        prevInsName: prevInsName[0]?.previousInsurer,
      };
      dispatch(SaveQuotesData(QuoteData));
    }
  };
  //---------------redirect to proposal after buy now succeed-----------------

  useEffect(() => {
    if (saveQuoteResponse && !updateQuoteLoader) {
      dispatch(CancelAll(false));

      history.push(
        `/${type}/proposal-page?enquiry_id=${enquiry_id}${
          token ? `&token=${token}` : ``
        }${typeId ? `&typeid=${typeId}` : ``}${
          journey_type ? `&journey_type=${journey_type}` : ``
        }`
      );
      dispatch(saveSelectedQuoteResponse(false));
      dispatch(setQuotesList([]));
      dispatch(clear());
    }
  }, [saveQuoteResponse, updateQuoteLoader]);

  //--------------for displaying base premium quote card-----------------

  const [basePrem, setBasePrem] = useState(quote?.finalPayableAmount);
  const [basePremNoGst, setBasePremNoGst] = useState(quote?.finalPayableAmount);

  useEffect(() => {
    if (temp_data?.journeyCategory !== "GCV") {
      setBasePrem(
        totalPremiumA * 1 -
          totalPremiumC * 1 +
          quote?.finalTpPremium * 1 +
          uwLoading * 1 +
          (totalPremiumA * 1 -
            totalPremiumC * 1 +
            quote?.finalTpPremium * 1 +
            uwLoading * 1) *
            0.18
      );
      setBasePremNoGst(
        totalPremiumA * 1 -
          totalPremiumC * 1 +
          quote?.finalTpPremium * 1 +
          uwLoading * 1
      );
    } else {
      setBasePrem(
        totalPremiumA * 1 -
          totalPremiumC * 1 +
          quote?.finalTpPremium * 1 +
          (totalPremiumA * 1 -
            totalPremiumC * 1 +
            quote?.finalTpPremium * 1 -
            quote?.tppdPremiumAmount * 1 +
            quote?.tppdDiscount * 1) *
            0.18 +
          (quote?.tppdPremiumAmount * 1 - quote?.tppdDiscount * 1) * 0.12
      );
      setBasePremNoGst(
        totalPremiumA * 1 - totalPremiumC * 1 + quote?.finalTpPremium * 1
      );
    }
  }, [
    quote?.finalOdPremium,
    totalPremiumC,
    quote?.finalTpPremium,
    quote?.tppdPremiumAmount,
    sort,
    quote,
    addOnsAndOthers?.selectedAddons,
    quote?.tppdDiscount,
    uwLoading,
    totalPremiumA,
  ]);

  //--------------------display logic of addon card car-----------------

  const GetValidAdditionalKeys = (additional) => {
    var y = Object.entries(additional)
      .filter(([, v]) => Number(v) > 0)
      .map(([k]) => k);
    return y;
  };

  const compareSelection =
    (!_.isEmpty(CompareData) &&
      !_.isEmpty(CompareData?.filter((x) => x.policyId === quote?.policyId))) ||
    "" ||
    "";
  console.log(compareSelection, "compareSelection");

  const [totalApplicableAddonsMotor, setTotalApplicableAddonsMotor] = useState(
    []
  );

  //-----------------setting dummy psave while quote loading-----------------
  const [numberOfInbuilt, setNumberOfInbuilt] = useState(0);
  const [dummySpace, setDummySpace] = useState(0);
  const [dummySpace1, setDummySpace1] = useState(0);
  useEffect(() => {
    if (maxAddonsMotor && quote) {
      setDummySpace1(maxAddonsMotor + numberOfInbuilt - 1);
    } else {
      setDummySpace1(0);
    }
  }, [quote, maxAddonsMotor, numberOfInbuilt]);
  useEffect(() => {
    if (maxAddonsMotor && quote) {
      setDummySpace(maxAddonsMotor - numberOfInbuilt);
    } else {
      setDummySpace(0);
    }
  }, [quote, maxAddonsMotor, numberOfInbuilt]);

  //-------------setting aplicable addons for quote card-----------------

  useEffect(() => {
    if (quote) {
      let x1 = quote?.addOnsData?.inBuilt
        ? Object.keys(quote?.addOnsData?.inBuilt)
        : [];

      //	let additional1 = quote?.addOnsData?.additional;
      //	var y1 = GetValidAdditionalKeys(additional1);
      var a1 = addOnsAndOthers?.selectedAddons;
      let z1 = [...x1];
      let applicableAddonMotor = [];
      if (a1 && x1) {
        applicableAddonMotor = _.union(a1, x1);
      }

      setNumberOfInbuilt(
        applicableAddonMotor ? applicableAddonMotor?.length : 0
      );
      setTotalApplicableAddonsMotor(applicableAddonMotor);
    }
  }, [quote, addOnsAndOthers?.selectedAddons]);

  //-----------------handle know more click-----------------

  const handleKnowMoreClick = async (data) => {
    setSelectedKnowMore(data);
    setKnowMore(true);
    var data1 = {
      quote: quote,
      totalAddon: totalAddon,
      totalPremium: totalPremium,
      gst: gst,
      finalPremium: finalPremium,
      totalPremiumA: totalPremiumA,
      totalPremiumB: totalPremiumB,
      totalPremiumC: totalPremiumC,
      applicableAddons: applicableAddons,
      type: type,
      prevInsName: prevInsName,
      revisedNcb: revisedNcb,
      popupCard: popupCard,
      setPrevPopup: setPrevPopup,
      prevPopup: prevPopup,
      addonDiscount: addonDiscount,
      addonDiscountPercentage: addonDiscountPercentage,
      totalOthersAddon: totalOthersAddon,
      totalApplicableAddonsMotor: totalApplicableAddonsMotor,
      uwLoading: uwLoading,
      otherDiscounts: otherDiscounts,
      icAddress: displayAddress,
    };
    setKnowMoreObject(data1);
  };

  //-----------------handle know more dynamically when grouping and quotes changed from premium breakup on ddon selection-----------------

  useEffect(() => {
    if (
      knowMore &&
      (quote?.modifiedAlias
        ? knowMoreCompAlias === quote?.modifiedAlias
        : knowMoreCompAlias === quote?.companyAlias)
    ) {
      var data = {
        quote: quote,
        selectedKnowMore: data,
        totalAddon: totalAddon,
        totalPremium: totalPremium,
        gst: gst,
        finalPremium: finalPremium,
        totalPremiumA: totalPremiumA,
        totalPremiumB: totalPremiumB,
        totalPremiumC: totalPremiumC,
        applicableAddons: applicableAddons,
        type: type,
        prevInsName: prevInsName,
        revisedNcb: revisedNcb,
        popupCard: popupCard,
        setPrevPopup: setPrevPopup,
        prevPopup: prevPopup,
        addonDiscount: addonDiscount,
        addonDiscountPercentage: addonDiscountPercentage,
        totalOthersAddon: totalOthersAddon,
        totalApplicableAddonsMotor: totalApplicableAddonsMotor,
        uwLoading: uwLoading,
        otherDiscounts: otherDiscounts,
        icAddress: displayAddress,
      };
      setKnowMoreObject(data);
    }
  }, [
    addOnsAndOthers,
    knowMore,
    quoteComprehesiveGrouped,
    knowMoreCompAlias,
    totalPremium,
    finalPremium,
    totalApplicableAddonsMotor,
    totalOthersAddon,
    totalAddon,
    totalPremiumB,
    totalPremiumC,
    applicableAddons,
    revisedNcb,
    gst,
    totalPremiumA,
    displayAddress,
  ]);
  let lessthan600 = useMediaPredicate("(max-width: 600px)");
  let lessthan400 = useMediaPredicate("(max-width: 400px)");

  useEffect(() => {
    if (
      // process.env.REACT_APP_BROKER !== "RB" &&
      TypeReturn(type) === "car" &&
      ((!_.isEmpty(claimList) && quote?.gdd !== "Y") ||
        (!_.isEmpty(claimList_gdd) && quote?.gdd === "Y"))
    ) {
      let check =
        quote?.companyAlias === "godigit" &&
        applicableAddons &&
        !_.isEmpty(
          applicableAddons.filter((item) => item?.name === "Zero Depreciation")
        );
      dispatch(setzdAvailablity([...zdAvailablity, check]));
    }
  }, [applicableAddons]);

  const ZD_Availablity = () => {
    // return process.env.REACT_APP_BROKER !== "RB" &&
      return TypeReturn(type) === "car" &&
      applicableAddons &&
      !_.isEmpty(
        applicableAddons.filter((item) => item?.name === "Zero Depreciation")
      ) &&
      quote?.companyAlias === "godigit" &&
      ((!_.isEmpty(claimList) && quote?.gdd !== "Y") ||
        (!_.isEmpty(claimList_gdd) && quote?.gdd === "Y"))
      ? true
      : false;
  };



  const mobRibbon = {
    fontSize: "8px",
    width: isMobileIOS ? "130px" : "115px",
    top: "-16px",
    right: "35px",
    textAlign: "center",
  };

  return lessthan767 && !popupCard ? (
    <>
      <MobileQuoteCard getSelected={compareSelection}>
        <CompareCheckMobile>
          {!popupCard && quote?.isInspectionApplicable === "Y" ? (
            <FoldedRibbon
              style={{
                ...mobRibbon,
              }}
            >
              Inspection Required
            </FoldedRibbon>
          ) : (
            <noscript />
          )}
          {!popupCard &&
          quote?.isRenewal === "Y" &&
          temp_data?.expiry &&
          quote?.gdd !== "Y" ? (
            <FoldedRibbon
              style={{
                ...mobRibbon,
              }}
            >
              Renewal Quote
            </FoldedRibbon>
          ) : (
            <noscript />
          )}
          {quote?.gdd === "Y" && (
            <FoldedRibbon
              id={`robbon${index}`}
              style={{
                ...mobRibbon,
              }}
            >
              Pay As You Drive
            </FoldedRibbon>
          )}
          <StyledDiv
            disabled={temp_data.tab === "tab2"}
            mouseHover={mouseHover}
            onClick={() =>
              document.getElementById(`reviewAgree${quote?.policyId}`)
                ? document
                    .getElementById(`reviewAgree${quote?.policyId}`)
                    .click()
                : {}
            }
            style={{
              cursor: temp_data.tab === "tab2" ? "not-allowed" : "pointer",
              fontSize: "12px",
              pointerEvents: allQuoteloading ? "none" : "",
              display: "none",
            }}
          >
            Compare
          </StyledDiv>

          <StyledDiv1
            mobileComp={
              mobileComp &&
              (quote?.companyAlias === "godigit" ||
                (!popupCard && quote?.isInspectionApplicable === "Y") ||
                (!popupCard &&
                  quote?.isRenewal === "Y" &&
                  temp_data?.expiry &&
                  differenceInDays(
                    toDate(moment().format("DD-MM-YYYY")),
                    toDate(temp_data?.expiry)
                  ) <= 90))
            }
          >
            {mobileComp ? (
              <span
                className="group-check float-right"
                style={{
                  width: "5%",
                  display: "none",
                }}
              >
                {/* <input
                  type="checkbox"
                  className="round-check"
                  id={`reviewAgree${quote?.policyId}`}
                  name={`checkmark1[${index}]`}
                  ref={register}
                  value={quote?.policyId}
                  defaultValue={quote?.policyId}
                  disabled={
                    (length >= (lessthan767 ? 2 : 3) &&
                      !watch(`checkmark1[${index}]`)) ||
                    temp_data.tab === "tab2" ||
                    allQuoteloading ||
                    (process.env.REACT_APP_BROKER === "BAJAJ" &&
                      quote.idv * 1 > 5000000 &&
                      temp_data?.isRedirectionDone &&
                      temp_data?.isRedirectionDone !== "Y" &&
                      isRedirectionDone === "N" &&
                      token) ||
                    (quote?.companyAlias === "hdfc_ergo" &&
                      temp_data?.carOwnership)
                      ? true
                      : false
                  }
                />
                <label
                  style={{ display: temp_data?.tab === "tab2" && "none" }}
                  className="round-label"
                  htmlFor={`reviewAgree${quote?.policyId}`}
                ></label> */}
              </span>
            ) : (
              <noscript />
            )}
          </StyledDiv1>
        </CompareCheckMobile>

        <MobileQuoteCardTop>
          <Row>
            <Col lg={4} md={4} sm={4} xs="4">
              <LogoImg
                style={{ height: lessthan600 && "auto" }}
                src={quote?.companyLogo ? quote?.companyLogo : demoLogo}
                alt="Plan Logo"
              />
            </Col>
            <Col lg={4} md={4} sm={4} xs="4" style={{ padding: "0px" }}>
              <MobileIdvContainer>
                <div className="idvMobContainer">
                  <span
                    className="idvTextMob"
                    style={{ fontSize: !isMobileIOS ? "13px" : "11px" }}
                  >
                    {" "}
                    IDV {false ? "Value" : ""} :{" "}
                  </span>
                  <span
                    className="idvValMob"
                    style={{ fontSize: !isMobileIOS ? "13px" : "11px" }}
                  >
                    {temp_data?.tab === "tab2" ? (
                      <Badge
                        variant="secondary"
                        style={{
                          cursor: "pointer",
                          marginBottom: "5px",
                        }}
                      >
                        Not Applicable
                      </Badge>
                    ) : (
                      `₹ ${currencyFormater(quote?.idv)}`
                    )}
                  </span>
                </div>
                <PolicyDetails
                  isMobileIOS={isMobileIOS}
                  onClick={
                    process.env.REACT_APP_BROKER === "BAJAJ" &&
                    !_.isEmpty(temp_data?.agentDetails) &&
                    !_.isEmpty(
                      temp_data?.agentDetails?.filter(
                        (o) => o?.sellerType === "P"
                      )
                    ) &&
                    quote.idv * 1 > 5000000 &&
                    temp_data?.isRedirectionDone &&
                    temp_data?.isRedirectionDone !== "Y" &&
                    isRedirectionDone === "N" &&
                    token &&
                    true
                      ? () => {
                          swal(
                            "Please Note",
                            "User IDV should not be higher than ₹ 50 Lakhs for POSP user",
                            "info"
                          );
                        }
                      : () => {
                          quote?.companyAlias === "hdfc_ergo" &&
                          temp_data?.carOwnership
                            ? swal({
                                title: "Please Note",
                                text: 'Transfer of ownership is not allowed for this quote. Please select ownership change as "NO" to buy this quote',
                                icon: "info",
                              })
                            : quote?.noCalculation === "Y"
                            ? swal(
                                "Please Note",
                                "Premium Breakup is not available for this quote",
                                "info"
                              )
                            : handleKnowMoreClick("premiumBreakupPop");
                        }
                  }
                >
                  Premium Breakup &gt;
                </PolicyDetails>
              </MobileIdvContainer>
            </Col>
            <Col lg={4} md={4} sm={4} xs="4">
              <CardBuyButton
                themeDisable={
                  (quote?.companyAlias === "hdfc_ergo" &&
                    temp_data?.carOwnership) ||
                  (process.env.REACT_APP_BROKER === "BAJAJ" &&
                    quote.idv * 1 > 5000000 &&
                    temp_data?.isRedirectionDone &&
                    temp_data?.isRedirectionDone !== "Y" &&
                    isRedirectionDone === "N" &&
                    token)
                }
                onClick={() => [
                  navigator &&
                    navigator?.vibrate &&
                    navigator.vibrate([100, 0, 50]),
                  quote?.companyAlias === "hdfc_ergo" && temp_data?.carOwnership
                    ? swal({
                        title: "Please Note",
                        text: 'Transfer of ownership is not allowed for this quote. Please select ownership change as "NO" to buy this quote',
                        icon: "info",
                      })
                    : (process.env.REACT_APP_BROKER === "RB" ||
                        process.env.REACT_APP_BROKER === "BAJAJ") &&
                      quote.idv * 1 > 5000000 &&
                      temp_data?.isRedirectionDone &&
                      temp_data?.isRedirectionDone !== "Y" &&
                      isRedirectionDone === "N" &&
                      token
                    ? process.env.REACT_APP_BROKER === "RB"
                      ? swal({
                          title: "Please Note",
                          text: "Dear Partner, IDV greater than ₹ 50 Lakhs sum-insured is above the eligiblity limit and is a Non-POS product.It can be purchased by the customer directly through our website.Please wait while we redirect the customer to the RenewBuy website.",
                          icon: "info",
                          buttons: {
                            cancel: "Cancel",
                            catch: {
                              text: "Confirm",
                              value: "confirm",
                            },
                          },
                          dangerMode: true,
                        }).then((caseValue) => {
                          switch (caseValue) {
                            case "confirm":
                              handleClick();
                              break;
                            default:
                          }
                        })
                      : swal(
                          "Please Note",
                          "User IDV should not be higher than ₹ 50 Lakhs for POSP user",
                          "info"
                        )
                    : handleClick(),
                ]}
                style={{
                  height: lessthan600 && "0",
                  lineHeight: lessthan600 && "0",
                  padding: lessthan600 && "17px 0px",
                }}
              >
                {"₹ "}
                {!gstToggle && !popupCard
                  ? currencyFormater(totalPremium)
                  : currencyFormater(finalPremium) || ""}
              </CardBuyButton>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={4} sm={4} xs="4">
              {!_.isEmpty(quote?.usp) && (
                <UspContainer
                  onClick={() => setMouseHoverBenefits(!mouseHoverBenefits)}
                >
                  Features
                </UspContainer>
              )}
            </Col>
            <Col lg={4} md={4} sm={4} xs="4" className={"p-0"}>
              {/* <RibbonBadge>
                {!popupCard && quote?.isInspectionApplicable === "Y" ? (
                  <Badge variant="secondary">Inspection Required</Badge>
                ) : (
                  <noscript />
                )}
                {!popupCard &&
                quote?.isRenewal === "Y" &&
                temp_data?.expiry &&
                quote?.gdd !== "Y" ? (
                  <Badge variant="secondary">Renewal Quote</Badge>
                ) : (
                  <noscript />
                )}
                {quote?.gdd === "Y" && (
                  <Badge variant="secondary">Pay As You Drive</Badge>
                )}
              </RibbonBadge> */}
            </Col>
            <Col lg={4} md={4} sm={4} xs="4">
              {!mobileComp ? (
                <CashlessGarageMobContainer>
                  <CashlessGarageMob
                    style={{
                      cursor: !quote?.garageCount && "not-allowed",
                      color: !quote?.garageCount && "#6b6e7166",
                    }}
                    onClick={() => {
                      quote?.companyAlias === "hdfc_ergo" &&
                      temp_data?.carOwnership
                        ? swal({
                            title: "Please Note",
                            text: 'Transfer of ownership is not allowed for this quote. Please select ownership change as "NO" to buy this quote',
                            icon: "info",
                          })
                        : quote?.garageCount &&
                          handleKnowMoreClick("cashlessGaragePop");
                    }}
                  >
                    Cashless Garage
                  </CashlessGarageMob>
                </CashlessGarageMobContainer>
              ) : (
                <CashlessGarageMobContainer>
                  <CheckBoxContainer getSelected={compareSelection}>
                    <input
                      type="checkbox"
                      className="round-check"
                      id={`reviewAgree${quote?.policyId}`}
                      name={`checkmark[${index}]`}
                      ref={register}
                      value={quote?.policyId}
                      defaultValue={quote?.policyId}
                      onClick={() =>
                        CompareData?.length === 1 &&
                        compareSelection &&
                        setMobileComp(false)
                      }
                      disabled={
                        (length >= (lessthan767 ? 2 : 3) &&
                          !watch(`checkmark[${index}]`)) ||
                        temp_data.tab === "tab2" ||
                        allQuoteloading ||
                        (process.env.REACT_APP_BROKER === "BAJAJ" &&
                          quote.idv * 1 > 5000000 &&
                          temp_data?.isRedirectionDone &&
                          temp_data?.isRedirectionDone !== "Y" &&
                          isRedirectionDone === "N" &&
                          token) ||
                        (quote?.companyAlias === "hdfc_ergo" &&
                          temp_data?.carOwnership)
                          ? true
                          : false
                      }
                    />
                    <label
                      style={{
                        display: temp_data?.tab === "tab2" && "none",
                        fontSize: "9px",
                      }}
                      className="round-label"
                      htmlFor={`reviewAgree${quote?.policyId}`}
                    >
                      {compareSelection ? (
                        <text
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <DeleteOutlineOutlinedIcon
                            style={{ fontSize: "13px" }}
                          />
                          Remove
                        </text>
                      ) : (
                        "+ Compare"
                      )}
                    </label>
                  </CheckBoxContainer>
                </CashlessGarageMobContainer>
              )}
            </Col>
          </Row>
        </MobileQuoteCardTop>

        <HowerTabsMobile>
          <ContentTabBenefitsMobile
            className={
              mouseHoverBenefits && quote?.usp?.length > 0
                ? "showBenefits"
                : "hideBenefits"
            }
            style={{ cursor: "default" }}
          >
            <ul>
              {quote?.usp?.length > 0 &&
                quote?.usp?.map((item, index) => <li>{item?.usp_desc}</li>)}
            </ul>
          </ContentTabBenefitsMobile>
        </HowerTabsMobile>

        <AddonAndCpaMobile>
          {ZD_Availablity() ? (
            <div
              style={{
                background: "rgb(234 234 234)",
                borderRadius: "5px",
                color: "black",
                fontWeight: "600",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "5px -7px",
                padding: "2px 5px 2px 12px",
              }}
            >
              <div style={{ fontSize: "11px" }}>Zero-dep claim</div>
              <div className="text-right" style={{ fontSize: "14px" }}>
                {quote?.gdd !== "Y" || "" ? (
                  !_.isEmpty(claimList) && claimList.length > 1 && true ? (
                    <>
                      {
                        <Badge
                          variant={"light"}
                          className="mx-1"
                          style={
                            claimList.sort().indexOf(zdlp) > 0
                              ? {
                                  color: "red",
                                  position: "relative",
                                  bottom: "1px",
                                  cursor: "pointer",
                                }
                              : {
                                  visibility: "hidden",
                                }
                          }
                          onClick={() =>
                            setZdlp(
                              claimList[claimList.sort().indexOf(zdlp) - 1]
                            )
                          }
                        >
                          <i class="fa fa-minus"></i>
                        </Badge>
                      }
                      <Badge
                        style={{
                          fontSize: [
                            "BAJAJ",
                            "ACE",
                            "PINC",
                            "SRIDHAR",
                          ].includes(process.env.REACT_APP_BROKER || "")
                            ? "11px"
                            : "12px",
                        }}
                      >
                        {zdlp === "ONE"
                          ? "ONE CLAIM"
                          : zdlp === "TWO"
                          ? "TWO CLAIM"
                          : `${zdlp}`}
                      </Badge>
                      <Badge
                        variant={"light"}
                        className="mx-1 mb-1"
                        style={
                          claimList?.sort().indexOf(zdlp) <
                          claimList?.length * 1 - 1
                            ? {
                                color: "green",
                                position: "relative",
                                bottom: "1px",
                                cursor: "pointer",
                              }
                            : {
                                visibility: "hidden",
                              }
                        }
                        onClick={() =>
                          setZdlp(claimList[claimList.sort().indexOf(zdlp) + 1])
                        }
                      >
                        <i class="fa fa-plus"></i>
                      </Badge>
                    </>
                  ) : (
                    <noscript />
                  )
                ) : !_.isEmpty(claimList_gdd) && claimList_gdd.length > 1 ? (
                  <>
                    {
                      <Badge
                        variant={"light"}
                        className="mx-1"
                        style={
                          claimList_gdd.sort().indexOf(zdlp_gdd) > 0
                            ? {
                                color: "red",
                                position: "relative",
                                bottom: "1px",
                                cursor: "pointer",
                              }
                            : {
                                visibility: "hidden",
                              }
                        }
                        onClick={() =>
                          setZdlp_gdd(
                            claimList_gdd[
                              claimList_gdd.sort().indexOf(zdlp_gdd) - 1
                            ]
                          )
                        }
                      >
                        <i class="fa fa-minus"></i>
                      </Badge>
                    }
                    <Badge
                      style={{
                        fontSize: ["BAJAJ", "ACE", "PINC", "SRIDHAR"].includes(
                          process.env.REACT_APP_BROKER || ""
                        )
                          ? "11px"
                          : "12px",
                      }}
                    >
                      {zdlp_gdd === "ONE"
                        ? "ONE CLAIM"
                        : zdlp_gdd === "TWO"
                        ? "TWO CLAIM"
                        : `${zdlp_gdd}`}
                    </Badge>
                    <Badge
                      variant={"light"}
                      className="mx-1 mb-1"
                      style={
                        claimList_gdd.sort().indexOf(zdlp_gdd) <
                        claimList_gdd?.length * 1 - 1
                          ? {
                              color: "green",
                              position: "relative",
                              bottom: "1px",
                              cursor: "pointer",
                            }
                          : {
                              visibility: "hidden",
                            }
                      }
                      onClick={() =>
                        setZdlp_gdd(
                          claimList_gdd[
                            claimList_gdd.sort().indexOf(zdlp_gdd) + 1
                          ]
                        )
                      }
                    >
                      <i class="fa fa-plus"></i>
                    </Badge>
                  </>
                ) : (
                  <noscript />
                )}
              </div>
            </div>
          ) : (
            <noscript />
          )}
          <Row>
            <Col lg={6} md={6} sm={6} xs="6">
              <AddonContainerMobile>
                <div className="addonNameMobile">Base Premium</div>
                <div className="addonValueMobile">
                  {" "}
                  ₹{" "}
                  {!gstToggle
                    ? currencyFormater(basePremNoGst)
                    : currencyFormater(basePrem)}
                </div>
              </AddonContainerMobile>
            </Col>
            {(addOnsAndOthers?.selectedCpa?.includes(
              "Compulsory Personal Accident"
            ) ||
              totalApplicableAddonsMotor.length > 0) && (
              <>
                <>
                  {addOnsAndOthers?.selectedCpa?.includes(
                    "Compulsory Personal Accident"
                  ) && (
                    <Col lg={6} md={6} sm={6} xs="6">
                      <AddonContainerMobile>
                        <div className="addonNameMobile">Compulsory PA</div>
                        <div className="addonValueMobile">
                          {_.isEmpty(addOnsAndOthers?.isTenure) ? (
                            !quote?.compulsoryPaOwnDriver * 1 ? (
                              <>
                                {/*<i
                                className="fa fa-close"
                                style={{ color: "red" }}
                            ></i>*/}
                                N/A
                              </>
                            ) : gstToggle == 0 ? (
                              `₹ ${currencyFormater(
                                parseInt(quote?.compulsoryPaOwnDriver)
                              )}`
                            ) : (
                              `₹ ${currencyFormater(
                                parseInt(quote?.compulsoryPaOwnDriver * 1.18)
                              )}`
                            )
                          ) : !quote?.multiYearCpa * 1 ? (
                            <>
                              {/*<i
                              className="fa fa-close"
                              style={{ color: "red" }}
                          ></i>*/}
                              N/A
                            </>
                          ) : gstToggle == 0 ? (
                            `₹ ${currencyFormater(
                              parseInt(quote?.multiYearCpa)
                            )}`
                          ) : (
                            `₹ ${currencyFormater(
                              parseInt(quote?.multiYearCpa * 1.18)
                            )}`
                          )}
                        </div>
                      </AddonContainerMobile>
                    </Col>
                  )}
                </>
                <>
                  {temp_data?.tab !== "tab2" &&
                    totalApplicableAddonsMotor
                      .sort()
                      .reverse()
                      .map((item, index) => (
                        <>
                          <Col
                            lg={6}
                            md={6}
                            sm={6}
                            xs="6"
                            style={{
                              display:
                                quote?.company_alias === "reliance" &&
                                item === "roadSideAssistance" &&
                                TypeReturn(type) === "cv" &&
                                "none",
                            }}
                          >
                            <AddonContainerMobile>
                              <div className="addonNameMobile">
                                {getAddonName(item)}
                              </div>
                              <div className="addonValueMobile">
                                {GetAddonValue(
                                  item,
                                  addonDiscountPercentage
                                ) === "N/A" ? (
                                  <>
                                    {/* <i
                                      className="fa fa-close"
                                      style={{ color: "red" }}
                                    ></i> */}
                                    N/A
                                  </>
                                ) : (
                                  <>
                                    {!gstToggle
                                      ? GetAddonValueNoGst(
                                          item,
                                          addonDiscountPercentage
                                        )
                                      : GetAddonValue(
                                          item,
                                          addonDiscountPercentage
                                        )}
                                  </>
                                )}
                              </div>
                            </AddonContainerMobile>
                          </Col>
                        </>
                      ))}
                </>
              </>
            )}
          </Row>
        </AddonAndCpaMobile>
      </MobileQuoteCard>
    </>
  ) : (
    // Mobile Card End
    <>
      <Col
        lg={!popupCard ? 4 : multiPopupCard ? 4 : 6}
        md={6}
        sm={12}
        style={{
          marginTop: !popupCard ? "30px" : "20px",
          maxWidth: popupCard ? (lessthan767 ? "100%" : "45%") : "",
        }}
      >
        <QuoteCardMain
          onMouseEnter={() => setMouseHover(true)}
          onMouseLeave={() => setMouseHover(false)}
          isRenewal={quote?.isRenewal === "Y" && !popupCard}
          hover={!popupCard}
        >
          {!popupCard && quote?.isInspectionApplicable === "Y" ? (
            <FoldedRibbon>Inspection Required</FoldedRibbon>
          ) : (
            <noscript />
          )}
          {(!popupCard &&
            quote?.isRenewal === "Y" &&
            temp_data?.expiry &&
            quote?.gdd !== "Y") ||
          "" ? (
            <FoldedRibbon>Renewal Quote</FoldedRibbon>
          ) : (
            <noscript />
          )}
          {quote?.gdd === "Y" && (
            <>
              <FoldedRibbon
                style={{
                  fontSize: "9.5px",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
                id={`gdd`}
                data-tip={
                  "<h3 > DIGIT's Pay As You Drive Plan</h3> <div>Insurer offers an extra discount on your Own Damage (OD) premium if you drive less than 15,000 kms per year, in exchange for you uploading 7 Photos of your car before your current policy expires.</div>"
                }
                data-html={true}
                data-for={`gddToolTip${index}`}
                // htmlFor="gddToolTip"
              >
                Pay As You Drive
              </FoldedRibbon>
              <CustomTooltip
                rider="true"
                id={`gddToolTip${index}`}
                place={"left"}
                customClassName="bg-white"
                arrowPosition="top"
                backColor="#fff"
                arrowColor
                Position={{ top: 40, left: 50 }}
              />
            </>
          )}
          {!popupCard && (
            <>
              <StyledDiv
                tab={temp_data.tab}
                disabled={temp_data.tab === "tab2"}
                mouseHover={mouseHover}
                onClick={() =>
                  document.getElementById(`reviewAgree${quote?.policyId}`)
                    ? document
                        .getElementById(`reviewAgree${quote?.policyId}`)
                        .click()
                    : {}
                }
                style={{
                  cursor: temp_data.tab === "tab2" ? "not-allowed" : "pointer",
                  fontSize: "12px",
                  pointerEvents: allQuoteloading ? "none" : "",
                }}
              >
                Compare
              </StyledDiv>

              <StyledDiv1 tab={temp_data.tab}>
                {true && (
                  <span
                    className="group-check float-right  "
                    style={{
                      width: "5%",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="round-check"
                      id={`reviewAgree${quote?.policyId}`}
                      name={`checkmark[${index}]`}
                      ref={register}
                      value={quote?.policyId}
                      defaultValue={quote?.policyId}
                      disabled={
                        //disabled during loading
                        (length >= (lessthan767 ? 2 : 3) &&
                          !watch(`checkmark[${index}]`)) ||
                        temp_data.tab === "tab2" ||
                        allQuoteloading ||
                        //disabled when buy now is disabled
                        (process.env.REACT_APP_BROKER === "BAJAJ" &&
                          quote.idv * 1 > 5000000 &&
                          temp_data?.isRedirectionDone &&
                          temp_data?.isRedirectionDone !== "Y" &&
                          isRedirectionDone === "N" &&
                          token)
                          ? true
                          : false
                      }
                    />
                    <label
                      className="round-label"
                      htmlFor={`reviewAgree${quote?.policyId}`}
                    ></label>
                  </span>
                )}
              </StyledDiv1>
            </>
          )}
          <CardOtherItemInner>
            {!popupCard ? (
              <>
                <Row>
                  <Col lg={6} md={6} sm={6} xs="6">
                    <LogoImg
                      src={quote?.companyLogo ? quote?.companyLogo : demoLogo}
                      alt="Plan Logo"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={5} md={5} sm={5} xs="5">
                    <div className="coverIdv"> IDV Value</div>
                    <div className="idvPrice">
                      {temp_data?.tab === "tab2" ? (
                        <Badge
                          variant="secondary"
                          style={{
                            cursor: "pointer",
                            marginBottom: "5px",
                          }}
                        >
                          Not Applicable
                        </Badge>
                      ) : (
                        `₹ ${currencyFormater(quote?.idv)}`
                      )}
                    </div>
                  </Col>
                  <Col lg={7} md={7} sm={7} xs="7">
                    <CardBuyButton
                      themeDisable={
                        (quote?.companyAlias === "hdfc_ergo" &&
                          temp_data?.carOwnership) ||
                        (process.env.REACT_APP_BROKER === "BAJAJ" &&
                          !_.isEmpty(temp_data?.agentDetails) &&
                          !_.isEmpty(
                            temp_data?.agentDetails?.filter(
                              (o) => o?.sellerType === "P"
                            )
                          ) &&
                          quote.idv * 1 > 5000000 &&
                          temp_data?.isRedirectionDone &&
                          temp_data?.isRedirectionDone !== "Y" &&
                          isRedirectionDone === "N" &&
                          token)
                      }
                      onClick={() =>
                        quote?.companyAlias === "hdfc_ergo" &&
                        temp_data?.carOwnership
                          ? swal({
                              title: "Please Note",
                              text: 'Transfer of ownership is not allowed for this quote. Please select ownership change as "NO" to buy this quote',
                              icon: "info",
                            })
                          : (process.env.REACT_APP_BROKER === "RB" ||
                              (process.env.REACT_APP_BROKER === "BAJAJ" &&
                                !_.isEmpty(temp_data?.agentDetails) &&
                                !_.isEmpty(
                                  temp_data?.agentDetails?.filter(
                                    (o) => o?.sellerType === "P"
                                  )
                                ))) &&
                            quote.idv * 1 > 5000000 &&
                            temp_data?.isRedirectionDone &&
                            temp_data?.isRedirectionDone !== "Y" &&
                            isRedirectionDone === "N" &&
                            token
                          ? process.env.REACT_APP_BROKER === "RB"
                            ? swal({
                                title: "Please Note",
                                text: "Dear Partner, IDV greater than ₹ 50 Lakhs sum-insured is above the eligiblity limit and is a Non-POS product.It can be purchased by the customer directly through our website.Please wait while we redirect the customer to the RenewBuy website.",
                                icon: "info",
                                buttons: {
                                  cancel: "Cancel",
                                  catch: {
                                    text: "Confirm",
                                    value: "confirm",
                                  },
                                },
                                dangerMode: true,
                              }).then((caseValue) => {
                                switch (caseValue) {
                                  case "confirm":
                                    handleClick();
                                    break;
                                  default:
                                }
                              })
                            : swal(
                                "Please Note",
                                "User IDV should not be higher than ₹ 50 Lakhs for POSP user",
                                "info"
                              )
                          : handleClick()
                      }
                      id={`buy-${quote?.policyId}`}
                    >
                      <div className="buyText">
                        {" "}
                        {popupCard ? "PROCEED" : "BUY NOW"}
                      </div>
                      <div
                        style={{
                          fontWeight:
                            process.env.REACT_APP_BROKER === "RB"
                              ? "600"
                              : "1000",
                          fontSize: "18px",
                        }}
                        className="buyPrice"
                      >
                        {" "}
                        ₹{" "}
                        {!gstToggle
                          ? currencyFormater(totalPremium * 1)
                          : currencyFormater(finalPremium * 1)}
                      </div>
                    </CardBuyButton>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col lg={6} md={6} sm={6} xs="6">
                    <LogoImg
                      src={quote?.companyLogo ? quote?.companyLogo : demoLogo}
                      alt="Plan Logo"
                    />
                  </Col>

                  <Col lg={6} md={6} sm={6} xs="6">
                    <div className="values" style={{ border: "none" }}>
                      <div className="coverIdv text-center"> IDV Value</div>
                      <div className="idvPrice text-center">
                        {temp_data?.tab === "tab2" ? (
                          <Badge
                            variant="secondary"
                            style={{
                              cursor: "pointer",
                              marginLeft: "10px",
                              marginBottom: "5px",
                            }}
                          >
                            Not Applicable
                          </Badge>
                        ) : (
                          `₹ ${currencyFormater(quote?.idv)}`
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} md={12} sm={12} xs="12">
                    <CardBuyButton
                      onClick={handleClick}
                      style={{ width: "58%", padding: "3px 0px" }}
                      id={`buy-${quote?.policyId}`}
                    >
                      {popupCard ? "PROCEED" : "BUY NOW"}
                      <span style={{ fontWeight: "1000" }}>
                        {" "}
                        ₹{" "}
                        {!gstToggle && !popupCard
                          ? currencyFormater(totalPremium)
                          : currencyFormater(finalPremium)}
                      </span>
                    </CardBuyButton>
                  </Col>
                </Row>
              </>
            )}

            {quote?.usp && quote?.usp?.length > 0 && (
              <HowerTabs>
                <Badge
                  variant="secondary"
                  style={{
                    zIndex: 997,
                  }}
                  onMouseEnter={() => setMouseHoverBenefits(true)}
                  onMouseLeave={() => setMouseHoverBenefits(false)}
                >
                  Features{" "}
                  {mouseHoverBenefits ? (
                    <ExpandLess className="arrowIcon" />
                  ) : (
                    <ExpandMore className="arrowIcon" />
                  )}
                </Badge>
                <ContentTabBenefits
                  className={
                    mouseHoverBenefits && quote?.usp?.length > 0
                      ? "showBenefits"
                      : "hideBenefits"
                  }
                  style={{ cursor: "default" }}
                >
                  <ul>
                    {quote?.usp?.length > 0 &&
                      quote?.usp?.map((item, index) => (
                        <li>{item?.usp_desc}</li>
                      ))}
                  </ul>
                  {dummySpace1 && true && temp_data.tab !== "tab2" ? (
                    <>
                      {[...Array(dummySpace1)].map((elementInArray, index) => (
                        <>
                          <li style={{ listStyle: "none" }}>&nbsp;</li>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </ContentTabBenefits>
              </HowerTabs>
            )}
          </CardOtherItemInner>
          {ZD_Availablity() ? (
            <CardOtherItemInner autoHeight={true}>
              <Row
                style={{
                  background: "rgb(234 234 234)",
                  borderRadius: "5px",
                  color: "black",
                  fontWeight: "600",
                }}
              >
                <Col sm={5} md={5} lg={5} xl={5} className="p-0">
                  <span className="d-flex no-wrap">
                    <CustomTooltip
                      rider="true"
                      id={
                        quote?.gdd === "Y"
                          ? `zdlp_m_gdd${index}`
                          : `zdlp_m${index}`
                      }
                      place={"top"}
                      customClassName="mt-3 "
                      allowClick
                      arrowColor
                    >
                      <p
                        data-tip={`<div>With this upgrade, you will be eligible for the selected number of Zero Depreciation claims (instead of the usual one) in the policy period. All claims made in excess will be treated as non-zero dep claims.</div>`}
                        data-html={true}
                        data-for={
                          quote?.gdd === "Y"
                            ? `zdlp_m_gdd${index}`
                            : `zdlp_m${index}`
                        }
                        className="text-left mx-2"
                        onClick={() =>
                          quote?.gdd === "Y"
                            ? document.getElementById(`zdlp_gdd${index}`)
                              ? document
                                  .getElementById(`zdlp_gdd${index}`)
                                  .click()
                              : {}
                            : document.getElementById(`zdlp${index}`)
                            ? document.getElementById(`zdlp${index}`).click()
                            : {}
                        }
                        style={{
                          fontSize: [
                            "BAJAJ",
                            "ACE",
                            "PINC",
                            "SRIDHAR",
                          ].includes(process.env.REACT_APP_BROKER || "")
                            ? "11px"
                            : "12px",
                          cursor: "pointer",
                          margin: "0.27rem 0",
                        }}
                      >
                        Zero-dep claim
                      </p>
                    </CustomTooltip>
                  </span>
                </Col>
                <Col sm={7} md={7} lg={7} xl={7} className="p-0 d-flex w-100">
                  <div
                    className="text-right w-100"
                    style={{ fontSize: "14px", marginTop: "1.2px" }}
                  >
                    {quote?.gdd !== "Y" ? (
                      !_.isEmpty(claimList) && claimList.length > 1 ? (
                        <>
                          {
                            <Badge
                              variant={"light"}
                              className="mx-1"
                              style={
                                claimList.sort().indexOf(zdlp) > 0
                                  ? {
                                      color: "red",
                                      position: "relative",
                                      bottom: "1px",
                                      cursor: "pointer",
                                    }
                                  : {
                                      visibility: "hidden",
                                    }
                              }
                              onClick={() =>
                                setZdlp(
                                  claimList[claimList.sort().indexOf(zdlp) - 1]
                                )
                              }
                            >
                              <i class="fa fa-minus"></i>
                            </Badge>
                          }
                          <Badge
                            style={{
                              fontSize: [
                                "BAJAJ",
                                "ACE",
                                "PINC",
                                "SRIDHAR",
                              ].includes(process.env.REACT_APP_BROKER || "")
                                ? "11px"
                                : "12px",
                            }}
                          >
                            {zdlp === "ONE"
                              ? "ONE CLAIM"
                              : zdlp === "TWO"
                              ? "TWO CLAIM"
                              : `${zdlp}`}
                          </Badge>
                          <Badge
                            variant={"light"}
                            className="mx-1 mb-1"
                            style={
                              claimList.sort().indexOf(zdlp) <
                              claimList?.length * 1 - 1
                                ? {
                                    color: "green",
                                    position: "relative",
                                    bottom: "1px",
                                    cursor: "pointer",
                                  }
                                : {
                                    visibility: "hidden",
                                  }
                            }
                            onClick={() =>
                              setZdlp(
                                claimList[claimList.sort().indexOf(zdlp) + 1]
                              )
                            }
                          >
                            <i class="fa fa-plus"></i>
                          </Badge>
                        </>
                      ) : (
                        <noscript />
                      )
                    ) : !_.isEmpty(claimList_gdd) &&
                      claimList_gdd.length > 1 ? (
                      <>
                        {
                          <Badge
                            variant={"light"}
                            className="mx-1"
                            style={
                              claimList_gdd.sort().indexOf(zdlp_gdd) > 0
                                ? {
                                    color: "red",
                                    position: "relative",
                                    bottom: "1px",
                                    cursor: "pointer",
                                  }
                                : {
                                    visibility: "hidden",
                                  }
                            }
                            onClick={() =>
                              setZdlp_gdd(
                                claimList_gdd[
                                  claimList_gdd.sort().indexOf(zdlp_gdd) - 1
                                ]
                              )
                            }
                          >
                            <i class="fa fa-minus"></i>
                          </Badge>
                        }
                        <Badge
                          style={{
                            fontSize: [
                              "BAJAJ",
                              "ACE",
                              "PINC",
                              "SRIDHAR",
                            ].includes(process.env.REACT_APP_BROKER || "")
                              ? "11px"
                              : "12px",
                          }}
                        >
                          {zdlp_gdd === "ONE"
                            ? "ONE CLAIM"
                            : zdlp_gdd === "TWO"
                            ? "TWO CLAIM"
                            : `${zdlp_gdd}`}
                        </Badge>
                        <Badge
                          variant={"light"}
                          className="mx-1 mb-1"
                          style={
                            claimList_gdd.sort().indexOf(zdlp_gdd) <
                            claimList_gdd?.length * 1 - 1
                              ? {
                                  color: "green",
                                  position: "relative",
                                  bottom: "1px",
                                  cursor: "pointer",
                                }
                              : {
                                  visibility: "hidden",
                                }
                          }
                          onClick={() =>
                            setZdlp_gdd(
                              claimList_gdd[
                                claimList_gdd.sort().indexOf(zdlp_gdd) + 1
                              ]
                            )
                          }
                        >
                          <i class="fa fa-plus"></i>
                        </Badge>
                      </>
                    ) : (
                      <noscript />
                    )}
                  </div>
                </Col>
              </Row>
            </CardOtherItemInner>
          ) : (
            <noscript />
          )}
          <CardOtherItemNoBorder>
            <Row>
              {!popupCard && (
                <>
                  <>
                    <Col lg={7} md={7} sm={7} xs={7}>
                      <ItemName>Base Premium</ItemName>
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={5}>
                      <ItemPrice>
                        {" "}
                        ₹{" "}
                        {!gstToggle
                          ? currencyFormater(basePremNoGst)
                          : currencyFormater(basePrem)}
                      </ItemPrice>
                    </Col>
                    {addOnsAndOthers?.selectedCpa?.includes(
                      "Compulsory Personal Accident"
                    ) &&
                      _.isEmpty(addOnsAndOthers?.isTenure) && (
                        <>
                          <Col lg={8} md={8} sm={8} xs={8}>
                            <ItemName>Compulsory Personal Accident</ItemName>
                          </Col>
                          <Col lg={4} md={4} sm={4} xs={4}>
                            <ItemPrice>
                              {" "}
                              {gstToggle == 0 ? (
                                !quote?.compulsoryPaOwnDriver ||
                                quote?.compulsoryPaOwnDriver == 0 ? (
                                  <Badge
                                    variant="danger"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Not Available
                                  </Badge>
                                ) : (
                                  "₹ " +
                                  currencyFormater(
                                    parseInt(quote?.compulsoryPaOwnDriver)
                                  )
                                )
                              ) : !quote?.compulsoryPaOwnDriver ||
                                quote?.compulsoryPaOwnDriver == 0 ? (
                                <Badge
                                  variant="danger"
                                  style={{ cursor: "pointer" }}
                                >
                                  Not Available
                                </Badge>
                              ) : (
                                "₹ " +
                                currencyFormater(
                                  parseInt(quote?.compulsoryPaOwnDriver * 1.18)
                                )
                              )}
                            </ItemPrice>
                          </Col>
                        </>
                      )}
                    {addOnsAndOthers?.selectedCpa?.includes(
                      "Compulsory Personal Accident"
                    ) &&
                      !_.isEmpty(addOnsAndOthers?.isTenure) && (
                        <>
                          <Col lg={8} md={8} sm={8} xs={8}>
                            <ItemName>
                              CPA{" "}
                              {TypeReturn(type) === "car"
                                ? "3"
                                : TypeReturn(type) === "bike" && "5"}{" "}
                              years
                            </ItemName>
                          </Col>
                          <Col lg={4} md={4} sm={4} xs={4}>
                            <ItemPrice>
                              {quote?.multiYearCpa && "₹ "}
                              {!quote?.multiYearCpa ? (
                                <Badge
                                  variant="danger"
                                  style={{ cursor: "pointer" }}
                                >
                                  Not Available
                                </Badge>
                              ) : gstToggle == 0 ? (
                                currencyFormater(parseInt(quote?.multiYearCpa))
                              ) : (
                                currencyFormater(
                                  parseInt(quote?.multiYearCpa * 1.18)
                                )
                              )}
                            </ItemPrice>
                          </Col>
                        </>
                      )}
                    {temp_data?.tab !== "tab2" && (
                      <>
                        {(quote?.company_alias === "reliance" &&
                        totalApplicableAddonsMotor?.includes(
                          "roadSideAssistance"
                        ) &&
                        TypeReturn(type) === "cv"
                          ? [
                              ..._.without(
                                totalApplicableAddonsMotor,
                                "roadSideAssistance"
                              )
                                .sort()
                                .reverse(),
                              "roadSideAssistance",
                            ]
                          : (totalApplicableAddonsMotor || []).sort().reverse()
                        ).map((item, index) => (
                          <>
                            {/* {GetAddonValue(item) !== "N/A" && ( */}
                            <>
                              <Col
                                lg={6}
                                md={7}
                                sm={7}
                                xs={7}
                                style={
                                  quote?.company_alias === "reliance" &&
                                  item === "roadSideAssistance" &&
                                  TypeReturn(type) === "cv"
                                    ? {
                                        visibility: "hidden",
                                      }
                                    : {}
                                }
                              >
                                <ItemName>
                                  {" "}
                                  {item === "emergencyMedicalExpenses" &&
                                  (between9to12 || between13to14)
                                    ? "Emergency M.E"
                                    : getAddonName(item)}
                                </ItemName>
                              </Col>
                              <Col
                                lg={6}
                                md={5}
                                sm={5}
                                xs={5}
                                style={
                                  quote?.company_alias === "reliance" &&
                                  item === "roadSideAssistance" &&
                                  TypeReturn(type) === "cv"
                                    ? {
                                        visibility: "hidden",
                                      }
                                    : {}
                                }
                              >
                                <ItemPrice>
                                  {GetAddonValue(
                                    item,
                                    addonDiscountPercentage
                                  ) === "N/S" ? (
                                    <NoAddonCotainer>
                                      <Badge
                                        variant="secondary"
                                        style={{ cursor: "pointer" }}
                                      >
                                        Not selected
                                      </Badge>
                                    </NoAddonCotainer>
                                  ) : GetAddonValue(
                                      item,
                                      addonDiscountPercentage
                                    ) === "N/A" ? (
                                    <NoAddonCotainer>
                                      <Badge
                                        variant="danger"
                                        style={{ cursor: "pointer" }}
                                      >
                                        Not Available
                                      </Badge>
                                    </NoAddonCotainer>
                                  ) : (
                                    <NoAddonCotainer>
                                      {!gstToggle
                                        ? GetAddonValueNoGst(
                                            item,
                                            addonDiscountPercentage
                                          )
                                        : GetAddonValue(
                                            item,
                                            addonDiscountPercentage
                                          )}
                                    </NoAddonCotainer>
                                  )}
                                </ItemPrice>
                              </Col>
                            </>
                            {/* )} */}
                          </>
                        ))}
                        {dummySpace ? (
                          <>
                            {[...Array(dummySpace)].map(
                              (elementInArray, index) => (
                                <>
                                  {" "}
                                  <Col lg={8} md={8} sm={8} xs={8}>
                                    <ItemName> &nbsp;</ItemName>
                                  </Col>
                                  <Col lg={4} md={4} sm={4} xs={4}>
                                    <ItemPrice> &nbsp;</ItemPrice>
                                  </Col>
                                </>
                              )
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                </>
              )}
            </Row>
          </CardOtherItemNoBorder>
          {!_.isEmpty(claimList) &&
          !popupCard &&
          !ZD_Availablity() &&
          addOnsAndOthers?.selectedAddons &&
          addOnsAndOthers?.selectedAddons.includes("zeroDepreciation") ? (
            <CardOtherItemInner
              autoHeight={true}
              style={{ visibility: "hidden" }}
            >
              <Row
                style={{
                  background: "#CDCDCD",
                  borderRadius: "5px",
                  color: "black",
                  fontWeight: "600",
                }}
              >
                <Col sm={5} md={5} lg={5} xl={5} className="p-0">
                  {" "}
                  <p
                    className="text-left mx-2"
                    style={{
                      fontSize: ["BAJAJ", "ACE", "PINC", "SRIDHAR"].includes(
                        process.env.REACT_APP_BROKER
                      )
                        ? "11px"
                        : "12px",
                      cursor: "pointer",
                      margin: "0.27rem 0",
                    }}
                  >
                    Zero-dep claim
                  </p>
                </Col>
                <Col sm={7} md={7} lg={7} xl={7} className="p-0 d-flex w-100">
                  <div
                    className="text-right w-100"
                    style={{ fontSize: "14px", marginTop: "1.2px" }}
                  >
                    {quote?.gdd !== "Y" ? (
                      !_.isEmpty(claimList) && claimList.length > 1 ? (
                        <>
                          {
                            <Badge
                              variant={"light"}
                              className="mx-1"
                              style={
                                claimList.sort().indexOf(zdlp) > 0
                                  ? {
                                      color: "red",
                                      position: "relative",
                                      bottom: "1px",
                                      cursor: "pointer",
                                    }
                                  : {
                                      visibility: "hidden",
                                    }
                              }
                              onClick={() =>
                                setZdlp(
                                  claimList[claimList.sort().indexOf(zdlp) - 1]
                                )
                              }
                            >
                              <i class="fa fa-minus"></i>
                            </Badge>
                          }
                          <Badge
                            style={{
                              fontSize: [
                                "BAJAJ",
                                "ACE",
                                "PINC",
                                "SRIDHAR",
                              ].includes(process.env.REACT_APP_BROKER || "")
                                ? "11px"
                                : "12px",
                            }}
                          >
                            {zdlp === "ONE"
                              ? "ONE CLAIM"
                              : zdlp === "TWO"
                              ? "TWO CLAIM"
                              : `${zdlp}`}
                          </Badge>
                          <Badge
                            variant={"light"}
                            className="mx-1 mb-1"
                            style={
                              claimList.sort().indexOf(zdlp) <
                              claimList?.length * 1 - 1
                                ? {
                                    color: "green",
                                    position: "relative",
                                    bottom: "1px",
                                    cursor: "pointer",
                                  }
                                : {
                                    visibility: "hidden",
                                  }
                            }
                            onClick={() =>
                              setZdlp(
                                claimList[claimList.sort().indexOf(zdlp) + 1]
                              )
                            }
                          >
                            <i class="fa fa-plus"></i>
                          </Badge>
                        </>
                      ) : (
                        <noscript />
                      )
                    ) : !_.isEmpty(claimList_gdd) &&
                      claimList_gdd.length > 1 ? (
                      <>
                        {
                          <Badge
                            variant={"light"}
                            className="mx-1"
                            style={
                              claimList_gdd.sort().indexOf(zdlp_gdd) > 0
                                ? {
                                    color: "red",
                                    position: "relative",
                                    bottom: "1px",
                                    cursor: "pointer",
                                  }
                                : {
                                    visibility: "hidden",
                                  }
                            }
                            onClick={() =>
                              setZdlp_gdd(
                                claimList_gdd[
                                  claimList_gdd.sort().indexOf(zdlp_gdd) - 1
                                ]
                              )
                            }
                          >
                            <i class="fa fa-minus"></i>
                          </Badge>
                        }
                        <Badge
                          style={{
                            fontSize: [
                              "BAJAJ",
                              "ACE",
                              "PINC",
                              "SRIDHAR",
                            ].includes(process.env.REACT_APP_BROKER || "")
                              ? "11px"
                              : "12px",
                          }}
                        >
                          {zdlp_gdd === "ONE"
                            ? "ONE CLAIM"
                            : zdlp_gdd === "TWO"
                            ? "TWO CLAIM"
                            : `${zdlp_gdd}`}
                        </Badge>
                        <Badge
                          variant={"light"}
                          className="mx-1 mb-1"
                          style={
                            claimList_gdd.sort().indexOf(zdlp_gdd) <
                            claimList_gdd?.length * 1 - 1
                              ? {
                                  color: "green",
                                  position: "relative",
                                  bottom: "1px",
                                  cursor: "pointer",
                                }
                              : {
                                  visibility: "hidden",
                                }
                          }
                          onClick={() =>
                            setZdlp_gdd(
                              claimList_gdd[
                                claimList_gdd.sort().indexOf(zdlp_gdd) + 1
                              ]
                            )
                          }
                        >
                          <i class="fa fa-plus"></i>
                        </Badge>
                      </>
                    ) : (
                      <noscript />
                    )}
                  </div>
                </Col>
              </Row>
            </CardOtherItemInner>
          ) : (
            <noscript />
          )}
          {!popupCard && (
            <Row
              mb-10
              style={{
                marginBottom: "10px",
              }}
            >
              <Col
                lg={6}
                onClick={() => {
                  //setKnowMore(true);
                  quote?.companyAlias === "hdfc_ergo" && temp_data?.carOwnership
                    ? swal({
                        title: "Please Note",
                        text: 'Transfer of ownership is not allowed for this quote. Please select ownership change as "NO" to buy this quote',
                        icon: "info",
                      })
                    : quote?.garageCount &&
                      handleKnowMoreClick("cashlessGaragesPop");
                }}
                style={{
                  cursor: !quote?.garageCount && "not-allowed",
                  pointerEvents:
                    process.env?.REACT_APP_BROKER === "ABIBL" &&
                    process.env?.REACT_APP_API_BASE_URL !==
                      "https://api-carbike.fynity.in/api"
                      ? "none"
                      : "",
                }}
              >
                <CardOtherItemBtn
                  style={{
                    cursor: !quote?.garageCount && "not-allowed",
                    color: !quote?.garageCount && "#6b6e7166",
                  }}
                >
                  Cashless Garages
                </CardOtherItemBtn>
              </Col>
              <Col
                lg={6}
                onClick={
                  process.env.REACT_APP_BROKER === "BAJAJ" &&
                  !_.isEmpty(temp_data?.agentDetails) &&
                  !_.isEmpty(
                    temp_data?.agentDetails?.filter(
                      (o) => o?.sellerType === "P"
                    )
                  ) &&
                  quote.idv * 1 > 5000000 &&
                  temp_data?.isRedirectionDone &&
                  temp_data?.isRedirectionDone !== "Y" &&
                  isRedirectionDone === "N" &&
                  token &&
                  true
                    ? () => {
                        document.getElementById(`buy-${quote?.policyId}`) &&
                          document
                            .getElementById(`buy-${quote?.policyId}`)
                            .click();
                      }
                    : () => {
                        quote?.companyAlias === "hdfc_ergo" &&
                        temp_data?.carOwnership
                          ? swal({
                              title: "Please Note",
                              text: 'Transfer of ownership is not allowed for this quote. Please select ownership change as "NO" to buy this quote',
                              icon: "info",
                            })
                          : quote?.noCalculation === "Y"
                          ? swal(
                              "Please Note",
                              "Premium Breakup is not available for this quote",
                              "info"
                            )
                          : handleKnowMoreClick("premiumBreakupPop");
                      }
                }
              >
                <CardOtherItemBtn>Premium Breakup</CardOtherItemBtn>
              </Col>
            </Row>
          )}
          {popupCard && (
            <>
              {multiPopupCard && (
                <ProductName>{quote?.productName}</ProductName>
              )}

              <div className="saved_money">
                <div
                  className="row text-center"
                  style={{
                    fontSize: "0.85rem",
                    fontFamily: ({ theme }) =>
                      theme?.QuoteBorderAndFont?.fontFamily
                        ? theme?.QuoteBorderAndFont?.fontFamily
                        : "basier_squareregular",
                  }}
                >
                  <div className="col-6">
                    <p className="p-0 m-0 text-muted">Old Premium</p>
                  </div>
                  <div className="col-6">
                    <p className="p-0 m-0">
                      {currencyFormater(parseInt(tempData?.oldPremium))}
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="p-0 m-0">New Premium</p>
                  </div>
                  <div className="col-6">
                    <p className="p-0 m-0 text-success">
                      {currencyFormater(parseInt(finalPremium))}
                    </p>
                  </div>
                </div>

                <div className="saved_money_div">
                  <img
                    src={`${
                      process.env.REACT_APP_BASENAME !== "NA"
                        ? `/${process.env.REACT_APP_BASENAME}`
                        : ""
                    }/assets/images/money.svg`}
                    className="saved_money_image"
                  />
                  <span className="saved_money_text mx-auto">
                    {difference > 0 ? (
                      <>You have saved Rs {parseInt(difference)}</>
                    ) : (
                      <>
                        Premium changed by Rs{" "}
                        {Number(parseInt(difference)) * -1}
                      </>
                    )}
                  </span>
                </div>
              </div>
            </>
          )}
        </QuoteCardMain>
      </Col>
    </>
  );
};

export const QuoteSkelton = ({
  popupCard,
  type,
  maxAddonsMotor,
  quotesLoaded,
  loading,
  multiPopupCard,
}) => {
  let lessthan767 = useMediaPredicate("(max-width: 767px)");

  return lessthan767 && !popupCard ? (
    <>
      <MobileQuoteCard style={{ padding: "15px 20px" }}>
        <Row>
          <Col lg={4} md={4} sm={4} xs="4">
            <Skeleton width={80} height={20}></Skeleton>
          </Col>
          <Col lg={4} md={4} sm={4} xs="4">
            <Skeleton width={80} height={20}></Skeleton>
          </Col>
          <Col lg={4} md={4} sm={4} xs="4">
            <Skeleton width={80} height={20}></Skeleton>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={4} xs="4">
            <Skeleton width={80} height={20}></Skeleton>
          </Col>
          <Col lg={4} md={4} sm={4} xs="4"></Col>
          <Col lg={4} md={4} sm={4} xs="4">
            <Skeleton width={80} height={20}></Skeleton>
          </Col>
        </Row>
      </MobileQuoteCard>
    </>
  ) : (
    <>
      <Col
        lg={!popupCard ? 4 : multiPopupCard ? 4 : 6}
        md={6}
        sm={12}
        style={{
          marginTop: !popupCard ? "30px" : "20px",
          maxWidth: popupCard ? (lessthan767 ? "100%" : "45%") : "",
          cursor: quotesLoaded || loading ? "progress" : "default",
        }}
      >
        <QuoteCardMain
          style={{
            ...(lessthan767 &&
              popupCard && {
                minHeight: "310px",
              }),
          }}
        >
          <CardOtherItemInner>
            <Row>
              <Col
                xlg={6}
                lg={6}
                style={{ marginTop: "15px", marginBottom: "30px" }}
              >
                <Skeleton width={50} height={50}></Skeleton>
              </Col>
              <Col
                xlg={6}
                lg={6}
                style={{ marginTop: "15px", marginBottom: "30px" }}
              >
                <Row>
                  {" "}
                  <Skeleton
                    width={100}
                    height={25}
                    style={{
                      ...(lessthan767 &&
                        popupCard && {
                          display: "none",
                        }),
                    }}
                  ></Skeleton>
                </Row>
                <Row>
                  {" "}
                  <Skeleton
                    width={100}
                    height={25}
                    style={{
                      ...(lessthan767 &&
                        popupCard && {
                          display: "none",
                        }),
                    }}
                  ></Skeleton>
                </Row>
              </Col>
              <Col lg={12}>
                <CardOtherName>
                  <Skeleton width={200} height={10}></Skeleton>
                  <Skeleton width={200} height={10}></Skeleton>
                  <Skeleton width={200} height={25}></Skeleton>
                </CardOtherName>
              </Col>
            </Row>
            <CardOtherIdv></CardOtherIdv>
          </CardOtherItemInner>

          <CardOtherItemNoBorder
            style={{ marginBottom: "10px", marginTop: "40px" }}
          >
            <Row>
              {TypeReturn(type) === "car" ? (
                <>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Skeleton width={220} height={15}></Skeleton>
                  </Col>
                  {/* <Col lg={12} md={12} sm={12} xs={12}>
										<Skeleton width={250} height={15}></Skeleton>
									</Col> */}
                  {[...Array(maxAddonsMotor)].map((elementInArray, index) => (
                    <>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <Skeleton width={220} height={15}></Skeleton>
                      </Col>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Skeleton width={220} height={15}></Skeleton>
                  </Col>
                  {[...Array(maxAddonsMotor)].map((elementInArray, index) => (
                    <>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <Skeleton width={220} height={15}></Skeleton>
                      </Col>
                    </>
                  ))}
                </>
              )}
            </Row>
          </CardOtherItemNoBorder>
          {!popupCard && (
            <Row mb-10 style={{ marginBottom: "10px" }}>
              <Col lg={6}>
                <CardOtherItemBtn>
                  {" "}
                  <Skeleton width={100} height={20}></Skeleton>
                </CardOtherItemBtn>
              </Col>
              <Col lg={6}>
                <CardOtherItemBtn>
                  {" "}
                  <Skeleton width={100} height={20}></Skeleton>
                </CardOtherItemBtn>
              </Col>
            </Row>
          )}
        </QuoteCardMain>
      </Col>
    </>
  );
};

const FoldedRibbon = styled.div`
  --f: 5px; /* control the folded part*/
  --r: 5px; /* control the ribbon shape */
  --t: 5px; /* the top offset */

  position: absolute;
  overflow: visible;
  font-size: 11.5px;
  font-weight: 600;
  color: #fff;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: ${({ theme }) => theme.Tab?.color || "#4ca729"};
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
  /* z-index: 999 !important; */
`;

const QuoteCardMain = styled.div`
  display: inline-block;
  position: relative;
  // width: 303px;
  margin-right: 16px;
  padding: 10px 0 0;
  border-radius: 8px;
  width: 100%;
  // overflow: hidden;
  min-height: "min-content";
  box-shadow: 0 8px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border: ${({ isRenewal, theme }) =>
    isRenewal
      ? theme?.Registration?.proceedBtn?.background
        ? `1px solid ${theme?.Registration?.proceedBtn?.background}`
        : `1px solid #4ca729`
      : `1px solid #d0d0d0d0`};
  background-color: #ffffff;
  text-align: center;
  @media screen and (max-width: 1290px) {
    // width: 95%;
  }
  &:hover {
    transform: scale(1.05);
    transition: transform 0.7s;
    box-shadow: ${({ theme }) =>
      theme?.QuoteCard?.boxShadow ||
      "0 8px 25px 1px #b3ffb3, 0 10px 10px 1px #b3ffb3"};
  }
  .saved_money {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    @media (max-width: 991px) {
      padding-left: 14px;
      padding-right: 14px;
    }
  }
  .saved_money_div {
    border-radius: 20px 5px 5px 20px;
    display: flex;
    align-items: center;
    margin-top: 12px;
    background: ${({ theme }) =>
      theme.QuoteBorderAndFont?.moneyBackground || "rgba(96, 241, 96, 0.25)"};
    width: 95%;
    margin-left: 18px;
  }
  .saved_money_image {
    width: 35px;
    height: 35px;
    margin-left: -18px;
  }
  .saved_money_text {
    font-size: 0.8rem;
    font-family: ${({ theme }) =>
      theme?.regularFont?.fontFamily
        ? theme?.regularFont?.fontFamily
        : `"ui-serif"`};
    padding-right: 20px;
  }
`;
const CardOtherItemInner = styled.div`
  ${(props) =>
    props?.autoHeight
      ? `border-bottom: solid 1px transparent;`
      : `border-bottom: solid 1px #e3e4e8;`}
  padding: 15px 20px 0;
  ${(props) => (props?.autoHeight ? `` : ``)}
  height: ${(props) => (props?.autoHeight ? "" : `146px`)};
  @media only screen and (max-width: 1200px) and (min-width: 950px) {
    height: 230px;
  }
  .coverIdv {
    padding: 0px 2.5px;
    font-size: 12.5px;
    text-align: left;
    margin-top: 3px;
    color: #4d5154;
  }
  .idvPrice {
    padding: 0px 2.5px;
    text-align: left;
    font-size: 14px !important;
    white-space: pre;
    @media only screen and (max-width: 1350px) and (min-width: 993px) {
      font-size: 12.5px !important;
    }
  }
`;

const LogoImg = styled.img`
  max-height: 56px;
  margin-bottom: 15px;
  //margin-left: -10px;
  margin-top: -5px;
  height: 56px;
  min-height: 45px;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  padding: 1px;
  border: 0.5px solid #c0c0c0;
  border-radius: 8px;
  object-fit: contain;

  @media only screen and (max-width: 993px) {
    max-width: 120px;
  }
  @media only screen and (max-width: 768px) {
    // border: none;
  }
`;

const CardOtherName = styled.div`
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-SemiBold"};
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 4px;
  height: 20px;
  @media only screen and (max-width: 1200px) and (min-width: 950px) {
    height: 40px;
  }
`;
//

const CardOtherIdv = styled.div`
  float: left;
  position: relative;
  width: 100%;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  font-size: 15px;
  line-height: 20px;
  color: #000;
  margin-bottom: 11px;
  cursor: pointer;
  font-weight: 600;
  height: 20px;
  @media only screen and (max-width: 1200px) and (min-width: 950px) {
    height: 30px;
  }
`;

const CardBuyButton = styled.button`
  float: left;
  // width: 220px;
  display: flex;
  height: 47px;
  margin-top: 6px;

  background-color: ${({ theme, themeDisable }) =>
    `${
      themeDisable
        ? "#787878"
        : theme.QuoteCard?.color3
        ? theme.QuoteCard?.color3
        : "#bdd400 !important"
    } `};

  border: ${({ theme, themeDisable }) =>
    themeDisable
      ? "1px solid #787878"
      : theme.QuoteCard?.border || "1px solid #bdd400"};
  color: #fff !important;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-SemiBold"};
  font-size: 12px;
  line-height: 40px;
  border-radius: 50px;
  margin-left: 0;
  outline: none;
  display: grid;
  width: 90%;
  float: none;
  font-weight: 1000;
  padding: 0px 0px;
  color: ${({ theme }) => theme.QuoteCard?.color || "#6c757d"};
  margin-bottom: 16px !important;
  margin: 0 auto;
  justify-contnet: space-between;
  transition: 0.2s ease-in-out;
  position: relative;
  bottom: 3px;
  @media only screen and (max-width: 1350px) {
    font-size: 10px !important;
  }
  & span {
    font-size: 15px;
    display: contents;
    font-family: ${({ theme }) =>
      theme?.fontFamily ? theme?.fontFamily : `"Inter-Light"`};
    @media only screen and (max-width: 1200px) {
      font-size: 13px !important;
    }
  }
  &:hover {
    ${(props) =>
      props?.themeDisable ? "" : `background-color: #fff !important`};
    color: ${({ theme, themeDisable }) =>
      `${
        themeDisable
          ? "#787878"
          : theme.QuoteCard?.color3
          ? theme.QuoteCard?.color3
          : "#bdd400 !important"
      } `};
    .buyPrice {
      color: ${({ theme, themeDisable }) =>
        `${
          themeDisable
            ? "white"
            : theme.QuoteCard?.color3
            ? theme.QuoteCard?.color3
            : "#bdd400 !important"
        } `};
    }
    .buyText {
      color: ${({ theme, themeDisable }) =>
        `${
          themeDisable
            ? "white"
            : theme.QuoteCard?.color3
            ? theme.QuoteCard?.color3
            : "#bdd400 !important"
        } `};
    }
    ${(props) =>
      props?.themeDisable
        ? ""
        : `border: ${({ theme }) =>
            theme.QuoteCard?.border || "1px solid #bdd400 !important"};`};
    &:before {
      transform: translateX(300px) skewX(-15deg);
      opacity: 0.6;
      transition: 0.7s;
    }
    &:after {
      transform: translateX(300px) skewX(-15deg);
      opacity: 1;
      transition: 0.7s;
    }
  }
  .buyPrice {
    position: relative;
    bottom: 32px;
    text-align: left;
    white-space: pre;
    display: flex;
    justify-content: center;
    align-self: center;
    color: "white";
  }
  .buyText {
    position: relative;
    bottom: 6px;
    color: white;
    text-align: left;
    white-space: pre;
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 10px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 13px !important;
    margin-top: 5px;
    padding: 3px;
  }
`;

const CardOtherItemNoBorder = styled.div`
  padding: 4px 12px 0px 15px;
  border-bottom: none;
  margin-top: 10px;
`;

const ItemName = styled.p`
  font-size: ${["BAJAJ", "ACE", "PINC", "SRIDHAR"].includes(
    process.env.REACT_APP_BROKER
  )
    ? "11px"
    : "12px"};
  text-align: left;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  white-space: nowrap;
  color: #6c757d !important;
  font-weight: ${({ theme }) => theme.regularFont?.fontWeight || "600"};

  @media only screen and (max-width: 1150px) and (min-width: 993px) {
    font-size: 8px !important;
  }
  @media only screen and (max-width: 1350px) and (min-width: 1151px) {
    font-size: 10px !important;
  }
`;

const ItemPrice = styled.p`
  text-align: end;
  font-weight: 600;
  font-size: ${["BAJAJ", "ACE", "PINC", "SRIDHAR"].includes(
    process.env.REACT_APP_BROKER
  )
    ? "11px"
    : "12px"};
  margin-right: 5px;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  height: 18px !important;
  @media only screen and (max-width: 1150px) and (min-width: 993px) {
    font-size: 8px !important;
  }
  @media only screen and (max-width: 1350px) and (min-width: 1151px) {
    font-size: 10px !important;
  }
`;

const CardOtherItemBtn = styled.span`
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  background-color: rgba(241, 242, 246, 0.3);
  color: ${({ theme }) => theme.QuoteCard?.color || "#bdd400"};
  cursor: pointer;
  height: 40px;
  font-size: 11px;
  line-height: 20px;
  padding: 10px 0px 10px 0;
  /* border-top: solid 1px #e3e4e8; */
  /* text-align: left; */
  font-weight: 600;
  @media only screen and (max-width: 1300px) and (min-width: 950px) {
    font-size: 9px;
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  //top: -24px;
  top: 36px;
  background: #ffffff;
  font-family: ${({ theme }) =>
    theme?.fontFamily ? theme?.fontFamily : `"basier_squaremedium"`};
  font-size: 10px;
  line-height: 12px;
  color: ${({ tab }) => (tab === "tab2" ? "#6b6e7166" : "#6b6e71")};
  text-align: center;
  width: 120px;
  //border: 1px solid #bdd400;
  border-bottom: none;
  //	z-index: 100;
  padding: 6px 4px 6px 0px;
  right: 0px;
  //	border-radius: 60px 0px 0px 0px;
  // 	0 10px 10px -5px rgba(0, 0, 0, 0.04);
  clip-path: inset(-11px -11px 0px -21px);

  @media only screen and (max-width: 992px) {
    width: 160px;
  }
  @media only screen and (max-width: 767px) {
    width: 115px;
    top: -6px;
    right: 0px;
    width: unset;
  }
`;

const StyledDiv1 = styled.div`
  position: relative;
  left: -102px;
  bottom: -15px;
  /* z-index: 101; */
  .round-label:before {
    cursor: ${({ tab }) => (tab === "tab2" ? "not-allowed" : "pointer")};
    @media (max-width: 767px) {
      border-radius: 50%;
      left: 3px;
      background: ${({ mobileComp }) => mobileComp && "#ffffff"};
      border: ${({ theme }) =>
        theme?.QuoteCard?.borderCheckBox || "1px solid  rgb(129 129 129)"};
    }
  }

  .group-check input[type="checkbox"]:checked + label:before {
    transform: scale(1);
    background-color: ${({ theme }) => theme.CheckBox?.color || "#bdd400"};
    border: ${({ theme }) => theme.CheckBox?.border || "1px solid #bdd400"};
    box-shadow: ${({ theme }) =>
      theme.QuoteBorderAndFont?.shadowCheck || "none"};
    filter: ${({ theme }) =>
      theme.QuoteBorderAndFont?.filterPropertyCheckBox || "none"};
    @media (max-width: 767px) {
      border-radius: 50%;
      left: 3px;
      border-color: ${({ mobileComp }) => mobileComp && "#ffffff"};
    }
  }
  @media (max-width: 993px) {
    left: -121px;
    bottom: -18px;
  }

  @media (max-width: 767px) {
    left: -62px;
    bottom: 21px;
  }
`;

const NoAddonCotainer = styled.div`
  position: relative;
  //	bottom: 5px;
`;
const RibbonBadge = styled.div`
  display: flex;
  align-items: center;
  .badge-secondary {
    background: ${({ theme }) => theme.Tab?.color || "#4ca729"} !important;
    font-size: 7px !important;
    padding: 2px 6px !important;
    line-height: 1.5 !important;
  }
`;

const HowerTabs = styled.div`
  font-family: ${({ theme }) => theme?.fontFamily && theme?.fontFamily};
  z-index: 997;
  display: flex;
  position: relative;
  bottom: 8px;
  justify-content: center;
  align-items: center;
  .badge-secondary {
    background: white !important;
    cursor: pointer !important;
    color: ${({ theme }) =>
      theme.QuoteBorderAndFont?.linkColor || "#bdd400"} !important;
  }
  flex-direction: column;
  .hideBenefits {
    display: none !important;
  }
  .showBenefits {
    display: block !important;
  }
  .arrowIcon {
    color: ${({ theme }) =>
      theme.QuoteBorderAndFont?.linkColor || "#bdd400"} !important;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
  }
`;
const moveDown = keyframes`
  0% {
	 transform: translateY(-10px);
    opacity: 0;
  }

  100% {
	 transform: translateY(6px);
	 opacity: 1;
  }
`;

const ContentTabBenefits = styled.div`
  display: flex;
  // min-height: 400px;
  background: white;
  z-index: 1000;
  width: 113%;
  font-size: 12px;
  text-align: left;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  transform: translateY(6px);
  animation: ${moveDown} 0.9s;
  padding: 0px 20px 0px 0px;
  @media only screen and (max-width: 993px) {
    width: 105%;
  }
`;

const GstToggleDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  .checkgst {
    display: flex;
    justify-content: center;
    align-content: center;

    input[type="checkbox"] {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 30.5px;
      height: 15px;
      background: #d0d0d0;
      display: block;
      border-radius: 100px;
      position: relative;
    }

    label:after {
      content: "";
      position: absolute;
      top: 2.4px;
      left: 3.75px;
      width: 12px;
      height: 10.5px;
      background: #fff;
      border-radius: 90px;
      transition: 0.2s;
    }

    input:checked + label {
      background: #bada55;
    }

    input:checked + label:after {
      left: calc(100% - 14px);
      // transform: translateX(-100%);
      top: 1.4px;
    }

    // label:active:after {
    // 	width: 19.25px;
    // 	top: 2.25px;
    // }
  }
`;

const ProductName = styled.div`
  display: flex;
  justify-content: center;
  align-items: cenetr;
  font-size: 16px;
  font-weight: bold;
  min-height: 48px !important;
`;
const MobileQuoteCard = styled.div`
  width: 100%;
  margin-top: 10px;
  box-shadow: rgb(149 157 165 / 70%) 0px 8px 10px;
  padding: 10px 0px;
  border: ${({ getSelected, isRenewal, theme }) =>
    isRenewal || getSelected
      ? theme?.Registration?.proceedBtn?.background
        ? `1px solid ${theme?.Registration?.proceedBtn?.background}`
        : `1px solid #bdd400 `
      : `1px solid #d0d0d0d0`};
`;
const MobileQuoteCardTop = styled.div`
  padding: 0px 10px;
`;

const MobileIdvContainer = styled.div`
  .idvTextMob {
    font-size: 10.5px;
  }
  .idvValMob {
    font-size: 11px;
    font-weight: 600;
    @media only screen and (max-width: 400px) {
      font-size: 9px;
    }
    @media only screen and (max-width: 330px) {
      font-size: 8px;
    }
  }
  .coverages {
    font-size: 10px;
    font-weight: 600;
    color: ${({ theme }) => theme.QuotePopups?.color2 || "#060"};
  }
  .idvMobContainer {
    white-space: nowrap;
  }
`;

const PolicyDetails = styled.div`
  color: ${({ theme }) => theme.QuoteCard?.color || "#bdd400"};
  padding: 5px 0px 10px 0px;
  font-size: ${({ isMobileIOS }) => (isMobileIOS ? "11px" : "12px")};
  font-weight: 600;
  white-space: nowrap;
`;

const CashlessGarageMob = styled.button`
  border-radius: 30px;
  background-color: #f1f4f7;
  font-size: 9px;
  padding: 5px;
  border: 1px solid #f1f4f7;
  position: relative;
  bottom: 6px;
`;
const CheckBoxContainer = styled.div`
  width: 78px;
  text-align: center;
  border-radius: 30px;
  font-size: 9px;
  padding: 5px 5px 0px 5px;
  border: 1px solid #f1f4f7;
  position: relative;
  background: #f1f4f7;
  bottom: 6px;
  color: ${({ getSelected, theme }) =>
    theme.QuoteCard?.color ? `${theme.QuoteCard?.color}` : "#bdd400 "};
  border: ${({ theme }) =>
    theme?.Registration?.proceedBtn?.background
      ? `1px solid ${theme?.Registration?.proceedBtn?.background}`
      : `1px solid #bdd400 `};

  .round-label:before {
    @media (max-width: 767px) {
      visibility: hidden;
    }
  }
`;

const CashlessGarageMobContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UspContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-left: 15px;
  font-weight: 600;
  color: ${({ theme }) =>
    theme.QuoteBorderAndFont?.linkColor || "#bdd400"} !important;
  text-decoration: underline;
`;
const AddonAndCpaMobile = styled.div`
  width: 100%;
  border-top: 1px solid #d0d0d0;
  padding: 2px 20px;
  font-size: 9px;
`;
const AddonContainerMobile = styled.div`
  margin: 1px 0px;
  display: flex;
  .addonNameMobile {
    width: 70%;
    font-weight: 600;
  }
  .addonValueMobile {
    width: 30%;
    font-size: 9.5px !important;
    font-weight: 600;
    text-align: end;
    white-space: nowrap;
  }
`;
const CompareCheckMobile = styled.div`
  position: absolute;
  right: -40px;
  z-index: 400;
`;

const HowerTabsMobile = styled.div`
  z-index: 997;
  display: flex;
  position: relative;
  bottom: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .hideBenefits {
    display: none !important;
  }
  .showBenefits {
    display: block !important;
  }
`;

const ContentTabBenefitsMobile = styled.div`
  display: flex;
  min-height: 66px;
  background: white;
  z-index: 1000;
  width: 100%;
  font-size: 9px;
  text-align: left;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  transform: translateY(6px);
  animation: ${moveDown} 0.9s;
  padding: 0px 20px 0px 0px;
`;

const FilterMenuBoxCheckConatiner = styled.div`
  .filterMenuBoxCheck input[type="checkbox"]:checked + label:before {
    background-color: ${({ theme }) => theme.CheckBox?.color || "#bdd400"};
    border: ${({ theme }) => theme.CheckBox?.border || "1px solid #bdd400"};
    box-shadow: ${({ theme }) =>
      theme.QuoteBorderAndFont?.shadowCheck || "none"};
    filter: ${({ theme }) =>
      theme.QuoteBorderAndFont?.filterPropertyCheckBox || "none"};
  }
`;
