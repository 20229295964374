import React, { useState, useEffect, useMemo } from "react";
import { Button, ErrorMsg } from "../../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Row, Col, Form, ToggleButton, Spinner } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { FormGroupTag, ButtonGroupTag, H4Tag2, ColDiv } from "../style.js";
import DateInput from "../DateInput";
import _ from "lodash";
import { numOnly, reloadPage, toDate as DateUtil } from "../../../utils";
import { subMonths, subYears } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  CarPincode as Pincode,
  clear,
  getAgreement,
  getFinancer,
  Category,
  Usage,
  AdrilaLoad,
  Save,
  getColor,
} from "../proposal.slice";
import swal from "sweetalert";
import { SearchInput } from "../typehead";
import styled from "styled-components";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import moment from "moment";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme1 = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const VehicleCard = ({
  onSubmitVehicle,
  vehicle,
  CardData,
  Theme,
  type,
  lessthan768,
  lessthan376,
  fields,
  PolicyCon,
  TypeReturn,
  enquiry_id,
  token,
}) => {
  const history = useHistory();
  const {
    temp_data,
    carPincode: pin,
    financer: financerList,
    agreement,
    category,
    usage,
    ongridLoad,
    gridLoad,
    colors,
  } = useSelector((state) => state.proposal);
  /*---------------date config----------------*/
  const AdultCheck = subYears(new Date(Date.now() - 86400000), 18);
  const ManfVal =
    !_.isEmpty(temp_data) &&
    subMonths(
      new Date(
        temp_data?.manfDate.split("-")[1],
        temp_data?.manfDate.split("-")[0] * 1 - 1,
        "01"
      ),
      15
    );
  const ManfValMax =
    !_.isEmpty(temp_data) &&
    new Date(
      temp_data?.manfDate.split("-")[1],
      temp_data?.manfDate.split("-")[0] * 1 - 1,
      "01"
    );
  /*-----x---------date config-----x----------*/

  const [addValidation, setAddValidation] = useState(false);
  const [financeValidation, setFinanceValidation] = useState(false);
  const [validations, setValidations] = useState(null);
  const [regNumber, setRegNumber] = useState("");
  const [pucVal, setPucVal] = useState("");
  const dispatch = useDispatch();

  const { validationConfig: validation } = useSelector((state) => state.home);

  //fetching ic based validations
  useEffect(() => {
    if (!_.isEmpty(validation)) {
      if (
        temp_data?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo ===
        "NEW"
      ) {
        setValidations(
          validation?.find(
            (item) =>
              item?.IcName === temp_data?.selectedQuote?.companyAlias &&
              item?.journeytype === "NEW"
          )
        );
      } else {
        setValidations(
          validation?.find(
            (item) =>
              item?.IcName === temp_data?.selectedQuote?.companyAlias &&
              item?.journeytype !== "NEW"
          )
        );
      }
    } else {
      setValidations(null);
    }
  }, [validation]);

  //chassis validation
  const chasisVal = {
    minlen: !_.isEmpty(validations)
      ? validations?.data?.chasisNomin
      : temp_data?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo !==
        "NEW"
      ? 5
      : 17,
    textmin: !_.isEmpty(validations)
      ? `Minimum ${validations?.data?.chasisNomin} characters required`
      : temp_data?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo !==
        "NEW"
      ? "Minimum 5 characters required"
      : "Minimum 17 characters required",
    maxlen: !_.isEmpty(validations) ? validations?.data?.chasisNomax : 25,
    textmax: !_.isEmpty(validations)
      ? `Maximum ${validations?.data?.chasisNomax} characters allowed`
      : "Maximum 25 characters allowed",
  };

  //engine validation
  const engineVal = {
    minlen: !_.isEmpty(validations) ? validations?.data?.engineNomin : 6,
    textmin: !_.isEmpty(validations)
      ? `Minimum ${validations?.data?.engineNomin} characters required`
      : "Minimum 6 characters required",
    maxlen: !_.isEmpty(validations) ? validations?.data?.engineNomax : 25,
    textmax: !_.isEmpty(validations)
      ? `Maximum ${validations?.data?.engineNomax} characters allowed`
      : "Maximum 25 characters allowed",
  };

  /*----------------Validation Schema---------------------*/
  const yupValidate = yup.object(
    temp_data?.selectedQuote?.isRenewal === "Y"
      ? {
          ...(addValidation && {
            carRegistrationCityId: yup.string().required("city id is Required"),
            carRegistrationStateId: yup
              .string()
              .required("state id is Required"),
            carRegistrationPincode: yup
              .string()
              .required("Pincode is Required")
              .matches(/^[0-9]+$/, "Must be only digits")
              .min(6, "Must be 6 digits")
              .max(6, "Must be 6 digits"),
            carRegistrationAddress1: yup
              .string()
              .max(30, "Max 30 chars allowed")
              .required("Address1 is Required")
              .trim(),
            carRegistrationAddress2: yup
              .string()
              .max(30, "Max 30 chars allowed")
              .required("Address2 is Required")
              .trim(),
            carRegistrationCity: yup
              .string()
              .required("Required")
              .matches(/[1-9A-Za-z]/, "City Required"),
            carRegistrationState: yup
              .string()
              .required("Required")
              .matches(/[1-9A-Za-z]/, "State Required"),
          }),
          ...(financeValidation && {
            nameOfFinancer: yup.string().required("Financer is required"),
            financerAgreementType: yup
              .string()
              .required("Financer Type is required")
              .matches(/[^@]/, "Financer Type is required"),
            ...(temp_data?.selectedQuote?.companyAlias === "shriram"
              ? {
                  ...(fields.includes("hypothecationCity") && {
                    hypothecationCity: yup
                      .string()
                      .max(12, "Maximum 12 characters allowed")
                      .required("Financer city/branch is required")
                      .trim(),
                  }),
                }
              : {
                  ...(fields.includes("hypothecationCity") && {
                    hypothecationCity: yup
                      .string()
                      .required("Financer city/branch is required")
                      .trim(),
                  }),
                }),
          }),
        }
      : {
          ...(addValidation && {
            carRegistrationCityId: yup.string().required("city id is Required"),
            carRegistrationStateId: yup
              .string()
              .required("state id is Required"),
            carRegistrationPincode: yup
              .string()
              .required("Pincode is Required")
              .matches(/^[0-9]+$/, "Must be only digits")
              .min(6, "Must be 6 digits")
              .max(6, "Must be 6 digits"),
            carRegistrationAddress1: yup
              .string()
              .max(30, "Max 30 chars allowed")
              .required("Address1 is Required")
              .trim(),
            carRegistrationAddress2: yup
              .string()
              .max(30, "Max 30 chars allowed")
              .required("Address2 is Required")
              .trim(),
            carRegistrationCity: yup
              .string()
              .required("Required")
              .matches(/[1-9A-Za-z]/, "City Required"),
            carRegistrationState: yup
              .string()
              .required("Required")
              .matches(/[1-9A-Za-z]/, "State Required"),
          }),
          ...{
            ...(((fields.includes("pucNo") &&
              temp_data?.corporateVehiclesQuoteRequest?.businessType !==
                "newbusiness" &&
              temp_data?.selectedQuote?.companyAlias !== "royal_sundaram" &&
              temp_data?.selectedQuote?.companyAlias !== "hdfc_ergo" &&
              temp_data?.selectedQuote?.companyAlias !== "tata_aig") ||
              (fields.includes("pucNo") &&
                ((temp_data?.selectedQuote?.companyAlias === "tata_aig" &&
                  regNumber === "DL" &&
                  temp_data?.corporateVehiclesQuoteRequest?.businessType !==
                    "newbusiness") ||
                  pucVal))) && {
              pucNo: yup.string().required("PUC is required").trim(),
            }),
            ...(((fields.includes("pucExpiry") &&
              temp_data?.corporateVehiclesQuoteRequest?.businessType !==
                "newbusiness" &&
              temp_data?.selectedQuote?.companyAlias !== "tata_aig") ||
              (fields.includes("pucExpiry") &&
                ((temp_data?.selectedQuote?.companyAlias === "tata_aig" &&
                  regNumber === "DL" &&
                  temp_data?.corporateVehiclesQuoteRequest?.businessType !==
                    "newbusiness") ||
                  pucVal))) && {
              pucExpiry: yup.string().required("PUC expiry is required").trim(),
            }),
          },
          ...((temp_data?.selectedQuote?.companyAlias === "sbi" &&
            TypeReturn(type) !== "cv") ||
          ["sbi", "universal_sompo", "new_india", "oriental"].includes(
            temp_data?.selectedQuote?.companyAlias
          )
            ? {
                ...(fields.includes("vehicleColor") && {
                  vehicleColor: yup
                    .string()
                    .required("Color is required")
                    .matches(
                      /^[a-zA-Z\s]+$/,
                      "Input must consist of alphabets only"
                    )
                    .trim(),
                }),
              }
            : {
                ...(fields.includes("vehicleColor") && {
                  vehicleColor: yup.string().nullable().trim(),
                }),
              }),
          vehicaleRegistrationNumber: yup
            .string()
            .required("Registration Number is required"),
          engineNumber: yup
            .string()
            .required(
              temp_data?.corporateVehiclesQuoteRequest?.fuelType === "ELECTRIC"
                ? "Battery Number is required"
                : "Engine Number is required"
            )
            .matches(
              /([0-9]+|([a-zA-Z]+[0-9]+)+)/i,
              "Engine number should be Numeric/Alphanumeric"
            )
            .min(engineVal.minlen, engineVal.textmin)
            .max(engineVal?.maxlen, engineVal?.textmax)
            .trim(),
          vehicleManfYear: yup
            .string()
            .required("Manufacture year is required"),
          chassisNumber: yup
            .string()
            .required("Chassis Number is required")
            .matches(
              /([0-9]+|([a-zA-Z]+[0-9]+)+)/i,
              "Chassis number should be Numeric/Alphanumeric"
            )
            .min(chasisVal.minlen, chasisVal.textmin)
            .max(chasisVal?.maxlen, chasisVal?.textmax)
            .trim(),
          ...(temp_data?.regNo !== "NEW" && {
            regNo1: yup.string().required("Registration No. is required"),
            regNo2: yup
              .string()
              .matches(/^[A-Z\s]{1,3}$/, "Invalid Number")
              .nullable()
              .transform((v, o) => (o === "" ? null : v)),
            regNo3: yup
              .string()
              .required("Number is required")
              .matches(/^[0-9]{4}$/, "Invalid Number"),
          }),
          ...((Number(temp_data?.quoteLog?.icId) === 20 ||
            temp_data?.selectedQuote?.companyAlias === "reliance") &&
            TypeReturn(type) === "cv" &&
            Number(temp_data?.productSubTypeId) === 6 &&
            temp_data?.parent?.productSubTypeCode !== "GCV" && {
              vehicleUsageType: yup
                .string()
                .required("Usage Type is required")
                .matches(/[^@]/, "Usage Type is required"),
              vehicleCategory: yup
                .string()
                .required("Category Type is required")
                .matches(/[^@]/, "Category Type is required"),
            }),
          ...(financeValidation && {
            nameOfFinancer: yup.string().required("Financer is required"),
            financerAgreementType: yup
              .string()
              .required("Financer Type is required")
              .matches(/[^@]/, "Financer Type is required"),
            ...(temp_data?.selectedQuote?.companyAlias === "shriram"
              ? {
                  ...(fields.includes("hypothecationCity") && {
                    hypothecationCity: yup
                      .string()
                      .max(12, "Maximum 12 characters allowed")
                      .required("Financer city/branch is required")
                      .matches(
                        /^([1-9A-Za-z\s/'-.~*)(:,])+$/,
                        "Invalid city/branch name"
                      )
                      .trim(),
                  }),
                }
              : {
                  ...(fields.includes("hypothecationCity") && {
                    hypothecationCity: yup
                      .string()
                      .required("Financer city/branch is required")
                      .matches(
                        /^([1-9A-Za-z\s/'-.~*)(:,])+$/,
                        "Invalid city/branch name"
                      )
                      .trim(),
                  }),
                }),
          }),
        }
  );
  /*----------x------Validation Schema----------x-----------*/
  const regNo = temp_data?.regNo ? temp_data?.regNo : "";
  const regNo2 = temp_data?.regNo1 ? temp_data?.regNo2 : "";
  const regNo3 = temp_data?.regNo1 ? temp_data?.regNo3 : "";

  const { handleSubmit, register, errors, control, reset, setValue, watch } =
    useForm({
      defaultValues: !_.isEmpty(vehicle)
        ? temp_data?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo ===
            "NEW" ||
          "" ||
          ""
          ? vehicle?.regNo1 ===
            temp_data?.corporateVehiclesQuoteRequest?.rtoCode
            ? vehicle
            : {
                ...vehicle,
                regNo1: temp_data?.corporateVehiclesQuoteRequest?.rtoCode,
              }
          : vehicle?.vehicaleRegistrationNumber === regNo
          ? {
              ...vehicle,
              registrationDate:
                temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate ||
                temp_data?.quoteLog?.quoteDetails?.vehicleRegisterDate,
            }
          : {
              ...vehicle,
              vehicaleRegistrationNumber: regNo,
              regNo1: temp_data?.regNo1 || temp_data?.rtoNumber,
              regNo2:
                temp_data?.regNo2 ||
                (regNo !== "NEW" && vehicle?.regNo2 ? vehicle?.regNo2 : ""),
              regNo3:
                temp_data?.regNo3 ||
                (regNo !== "NEW" && vehicle?.regNo3 ? vehicle?.regNo3 : ""),
              registrationDate:
                temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate ||
                temp_data?.quoteLog?.quoteDetails?.vehicleRegisterDate,
            }
        : !_.isEmpty(CardData.vehicle)
        ? temp_data?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo ===
          "NEW"
          ? vehicle?.regNo1 ===
            temp_data?.corporateVehiclesQuoteRequest?.rtoCode
            ? CardData?.vehicle
            : {
                ...CardData?.vehicle,
                regNo1: temp_data?.corporateVehiclesQuoteRequest?.rtoCode,
              }
          : CardData?.vehicle?.vehicaleRegistrationNumber === regNo
          ? {
              ...CardData?.vehicle,
              registrationDate:
                temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate ||
                temp_data?.quoteLog?.quoteDetails?.vehicleRegisterDate,
            }
          : {
              ...CardData?.vehicle,
              vehicaleRegistrationNumber: regNo,
              regNo1: temp_data?.regNo1 || temp_data?.rtoNumber,
              regNo2:
                temp_data?.regNo2 ||
                (regNo !== "NEW" && CardData?.vehicle?.regNo2
                  ? CardData?.vehicle?.regNo2
                  : ""),
              regNo3:
                temp_data?.regNo3 ||
                (regNo !== "NEW" && CardData?.vehicle?.regNo3
                  ? CardData?.vehicle?.regNo3
                  : ""),
              registrationDate:
                temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate ||
                temp_data?.quoteLog?.quoteDetails?.vehicleRegisterDate,
            }
        : {},
      resolver: yupResolver(yupValidate),
      mode: "onBlur",
      reValidateMode: "onBlur",
    });

  const allFieldsReadOnly =
    temp_data?.selectedQuote?.isRenewal === "Y" ||
    temp_data?.corporateVehiclesQuoteRequest?.rolloverRenewal === "Y";

  console.log("allFieldsReadOnly", allFieldsReadOnly);

  let regNumberRto = watch("regNo1");
  useEffect(() => {
    regNumberRto &&
      regNumberRto !== "NEW" &&
      setRegNumber(regNumberRto.split("-")[0]);
  }, [regNumberRto]);

  const PUC_NO = watch("pucNo");
  const PUC_EXPIRY = watch("pucExpiry");

  useEffect(() => {
    if (PUC_NO || PUC_EXPIRY) {
      setPucVal(true);
    } else {
      setPucVal(false);
    }
  }, [PUC_NO, PUC_EXPIRY]);

  //prefill Api
  useEffect(() => {
    if (_.isEmpty(vehicle) && !_.isEmpty(CardData?.vehicle)) {
      reset(
        temp_data?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo ===
          "NEW"
          ? vehicle?.regNo1 ===
            temp_data?.corporateVehiclesQuoteRequest?.rtoCode
            ? CardData?.vehicle
            : {
                ...CardData?.vehicle,
                regNo1: temp_data?.corporateVehiclesQuoteRequest?.rtoCode,
              }
          : CardData?.vehicle?.vehicaleRegistrationNumber === regNo
          ? {
              ...CardData?.vehicle,
              registrationDate:
                temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate ||
                temp_data?.quoteLog?.quoteDetails?.vehicleRegisterDate,
            }
          : {
              ...CardData?.vehicle,
              vehicaleRegistrationNumber: regNo,
              regNo1: temp_data?.regNo1 || temp_data?.rtoNumber,
              regNo2:
                temp_data?.regNo2 ||
                (regNo !== "NEW" && CardData?.vehicle?.regNo2
                  ? CardData?.vehicle?.regNo2
                  : ""),
              regNo3:
                temp_data?.regNo3 ||
                (regNo !== "NEW" && CardData?.vehicle?.regNo3
                  ? CardData?.vehicle?.regNo3
                  : ""),
              registrationDate:
                temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate ||
                temp_data?.quoteLog?.quoteDetails?.vehicleRegisterDate,
            }
      );
      setFinanceValidation(CardData?.isVehicleFinance);
      setAddValidation(CardData?.isCarRegistrationAddressSame);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CardData.vehicle]);

  //setting validations
  const isVehicleFinance = watch("isVehicleFinance");
  const isCarRegistrationAddressSame = watch("isCarRegistrationAddressSame");

  useEffect(() => {
    if (isVehicleFinance) {
      setFinanceValidation(true);
    } else {
      setFinanceValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVehicleFinance]);

  useEffect(() => {
    if (!isCarRegistrationAddressSame) {
      setAddValidation(true);
    } else {
      setAddValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCarRegistrationAddressSame]);

  //pincode
  const companyAlias = !_.isEmpty(temp_data?.selectedQuote)
    ? temp_data?.selectedQuote?.companyAlias
    : "";
  const PinCode = watch("carRegistrationPincode");

  useEffect(() => {
    if (PinCode?.length === 6 && companyAlias) {
      dispatch(
        Pincode({
          companyAlias: companyAlias,
          pincode: PinCode,
          enquiryId: enquiry_id,
        })
      );
    } else {
      dispatch(clear("car_pincode"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PinCode]);

  useEffect(() => {
    if (!_.isEmpty(pin)) {
      setValue("carRegistrationState", pin?.state?.state_name);
      setValue("carRegistrationStateId", pin?.state?.state_id);
    } else {
      setValue("carRegistrationState", "");
      setValue("carRegistrationStateId", "");
      setValue("carRegistrationCity", "");
      setValue("carRegistrationCityId", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  // auto selecting if only one option is present.
  const city =
    watch("carRegistrationCity") ||
    vehicle?.carRegistrationCity ||
    CardData?.vehicle?.carRegistrationCity ||
    (!_.isEmpty(pin?.city) &&
      pin?.city?.length === 1 &&
      pin?.city[0]?.city_name);

  useEffect(() => {
    if (city) {
      const city_id = pin?.city?.filter(({ city_name }) => city_name === city);

      !_.isEmpty(city_id)
        ? setValue("carRegistrationCityId", city_id[0]?.city_id)
        : setValue(
            "carRegistrationCityId",
            vehicle?.carRegistrationCityId ||
              CardData?.vehicle?.carRegistrationCityId
          );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, pin]);
  //vehicle details
  const manfYear = temp_data?.manfDate ? temp_data?.manfDate : "";
  const RegDate =
    temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate ||
    temp_data?.quoteLog?.quoteDetails?.vehicleRegisterDate;

  useEffect(() => {
    if (manfYear) setValue("vehicleManfYear", manfYear);
    if (RegDate) setValue("registrationDate", RegDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manfYear, RegDate]);

  //Toggle
  const ToggleElem = (name, content, defaultChecked, readOnly, checked) => (
    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
      <TopDiv>
        <div className="toggleRadio m-0">
          <label
            className="selectLabel ml-0"
            style={{
              color: Theme?.questionsProposal?.color
                ? Theme?.questionsProposal?.color
                : "#00CC00",
              letterSpacing: "1px",
              fontSize: lessthan376 ? "11px" : "12px",
            }}
          >
            {content}
          </label>
          <label className="selectLabel ml-0">No</label>
          <label
            toggleBackgroundColor={
              Theme?.questionsProposal?.toggleBackgroundColor
            }
            className="switch yes-switch"
          >
            <input
              name={name}
              type="checkbox"
              id={name}
              className="min-y"
              checked={checked}
              defaultChecked={defaultChecked}
              ref={register}
              readOnly={readOnly || allFieldsReadOnly}
            />
            <span className="slider round"></span>
          </label>
          <label class="selectLabel">Yes</label>
        </div>
      </TopDiv>
    </Col>
  );

  //setting hidden i/p
  const financer_sel = watch("financer_sel");

  //Financer
  const handleSearch = (query) => {
    if (companyAlias && query?.length >= 3) {
      dispatch(
        getFinancer({
          companyAlias,
          searchString: query,
          enquiryId: enquiry_id,
        })
      );
    }
  };

  useEffect(() => {
    if (!_.isEmpty(financer_sel) && financer_sel) {
      if (financer_sel[0]?.code && financer_sel[0]?.name) {
        //Incase of a normal selection.
        setValue("nameOfFinancer", financer_sel[0]?.code);
        setValue("financer_name", financer_sel[0]?.name);
      }
      if (financer_sel[0]?.customOption) {
        //Incase of custon selection
        setValue("nameOfFinancer", financer_sel[0]?.name);
        setValue("financer_name", financer_sel[0]?.name);
      }
    } else {
      if (_.isEmpty(financer_sel) && CardData?.vehicle?.financerName) {
        setValue("financer_name", CardData?.vehicle?.financerName);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financer_sel]);

  useEffect(() => {
    if (companyAlias) {
      dispatch(getAgreement({ companyAlias, enquiryId: enquiry_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAlias]);

  useEffect(() => {
    if (
      ["sbi", "universal_sompo", "new_india"].includes(
        temp_data?.selectedQuote?.companyAlias
      )
    ) {
      dispatch(getColor(temp_data?.selectedQuote?.companyAlias));
    }
  }, []);

  const Agreement = !_.isEmpty(agreement)
    ? agreement?.map(({ name, code }) => {
        return { name, code };
      })
    : [];

  const AgreementType = watch("financerAgreementType");
  const AgreementTypeName = !_.isEmpty(agreement)
    ? agreement?.filter(({ code }) => code === AgreementType)[0]?.name
    : "";
  const num = watch("vehicaleRegistrationNumber");
  //Reconstructing Vehicle Reg Number
  const RegNo1 = watch("regNo1");
  const RegNo2 = watch("regNo2");
  const RegNo3 = watch("regNo3");

  useEffect(() => {
    if (RegNo1 && RegNo2 && RegNo3) {
      setValue(
        "vehicaleRegistrationNumber",
        `${RegNo1.replace(/\s/g, "")}-${RegNo2.replace(
          /\s/g,
          ""
        )}-${RegNo3.replace(/\s/g, "")}`
      );
    }
    if (RegNo1 && !RegNo2 && RegNo3) {
      setValue(
        "vehicaleRegistrationNumber",
        `${RegNo1.replace(/\s/g, "")}-${RegNo3.replace(/\s/g, "")}`
      );
    }
    if (
      RegNo1 &&
      !RegNo2 &&
      !RegNo3 &&
      temp_data?.corporateVehiclesQuoteRequest?.businessType === "newbusiness"
    ) {
      setValue("vehicaleRegistrationNumber", `NEW`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RegNo1, RegNo2, RegNo3]);

  //Vehicle Categories
  useEffect(() => {
    dispatch(Category());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const vehicleCategoryVar = watch("vehicleCategory");
  const vehicleUsageTypeVar = watch("vehicleUsageType");
  //vehicle Usage Type
  useEffect(() => {
    if (vehicleCategoryVar) dispatch(Usage(vehicleCategoryVar));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleCategoryVar]);

  //fastLane prefill
  useEffect(() => {
    _.isEmpty(CardData?.vehicle) &&
      !vehicle?.chassisNumber &&
      temp_data?.userProposal?.chassisNumber &&
      setValue("chassisNumber", temp_data?.userProposal?.chassisNumber);
    _.isEmpty(CardData?.vehicle) &&
      !vehicle?.vehicleColor &&
      temp_data?.userProposal?.engineNumber &&
      setValue("engineNumber", temp_data?.userProposal?.engineNumber);
    _.isEmpty(CardData?.vehicle) &&
      !vehicle?.engineNumber &&
      temp_data?.userProposal?.vehicleColor &&
      setValue("vehicleColor", temp_data?.userProposal?.vehicleColor);
    _.isEmpty(CardData?.vehicle) &&
      !vehicle?.nameOfFinancer &&
      temp_data?.userProposal?.nameOfFinancer &&
      setValue("isVehicleFinance", true);
    _.isEmpty(CardData?.vehicle) &&
      !vehicle?.vehicleCategory &&
      temp_data?.userProposal?.vehicleCategory &&
      setValue("vehicleCategory", temp_data?.userProposal?.vehicleCategory);
  }, [CardData?.vehicle]);

  useEffect(() => {
    vehicleCategoryVar &&
      _.isEmpty(CardData?.vehicle) &&
      !vehicle?.vehicleUsageType &&
      !vehicleUsageTypeVar &&
      temp_data?.userProposal?.vehicleUsageType &&
      setValue("vehicleUsageType", temp_data?.userProposal?.vehicleUsageType);
  }, [vehicleCategoryVar]);

  useEffect(() => {
    isVehicleFinance &&
      _.isEmpty(CardData?.vehicle) &&
      !vehicle?.nameOfFinancer &&
      temp_data?.userProposal?.nameOfFinancer &&
      setValue("nameOfFinancer", temp_data?.userProposal?.nameOfFinancer);
    isVehicleFinance &&
      _.isEmpty(CardData?.vehicle) &&
      !vehicle?.nameOfFinancer &&
      temp_data?.userProposal?.nameOfFinancer &&
      setValue("financer_sel", temp_data?.userProposal?.nameOfFinancer);
    isVehicleFinance &&
      _.isEmpty(CardData?.vehicle) &&
      !vehicle?.financerAgreementType &&
      temp_data?.userProposal?.financerAgreementType &&
      setValue(
        "financerAgreementType",
        temp_data?.userProposal?.financerAgreementType
      );
    isVehicleFinance &&
      _.isEmpty(CardData?.vehicle) &&
      !vehicle?.hypothecationCity &&
      temp_data?.userProposal?.hypothecationCity &&
      setValue("hypothecationCity", temp_data?.userProposal?.hypothecationCity);
    isVehicleFinance &&
      _.isEmpty(CardData?.vehicle) &&
      !vehicle?.financerLocation &&
      temp_data?.userProposal?.financerLocation &&
      setValue("financerLocation", temp_data?.userProposal?.financerLocation);
  }, [isVehicleFinance, CardData?.vehicle]);

  //puc deails
  const PUC_EXP = watch("pucExpiry");

  //financer_sel
  const financer_sel_opt =
    vehicle?.financer_name ||
    vehicle?.financerName ||
    CardData?.vehicle?.financerName ||
    temp_data?.userProposal?.financerName ||
    vehicle?.nameOfFinancer ||
    CardData?.vehicle?.nameOfFinancer ||
    temp_data?.userProposal?.nameOfFinancer ||
    "";

  //ongridload
  const OnGridLoad = () => {
    if (
      RegNo1 &&
      RegNo1.match(/^[A-Z]{2}[-\s][0-9]{1,2}$/) &&
      RegNo2 &&
      RegNo2.match(/^[A-Z\s]{1,3}$/) &&
      RegNo3 &&
      RegNo3.match(/^[0-9]{4}$/) &&
      (!temp_data?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo ||
        process.env.REACT_APP_BROKER === "RB" ||
        process.env.REACT_APP_BROKER === "TATA" ||
        process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "ABIBL" ||
        (process.env.REACT_APP_BROKER === "GRAM" &&
          TypeReturn(type) === "bike") ||
        process.env.REACT_APP_BROKER === "SPA") &&
      (process.env.REACT_APP_BROKER === "OLA" ||
        process.env.REACT_APP_BROKER === "TATA" ||
        process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "ABIBL" ||
        process.env.REACT_APP_BROKER === "SPA" ||
        (process.env.REACT_APP_BROKER === "GRAM" &&
          TypeReturn(type) === "bike") ||
        process.env.REACT_APP_BROKER === "RB")
    ) {
      dispatch(
        AdrilaLoad(
          {
            registration_no: `${RegNo1}-${RegNo2}-${RegNo3}`,
            enquiryId: enquiry_id,
            section: TypeReturn(type),
            vehicleValidation: "Y",
          },
          true
        )
      );
    } else if (
      !RegNo2 &&
      RegNo1 &&
      RegNo1.match(/^[A-Z]{2}[-\s][0-9]{1,2}$/) &&
      RegNo3 &&
      RegNo3.match(/^[0-9]{4}$/) &&
      (!temp_data?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo ||
        process.env.REACT_APP_BROKER === "RB" ||
        process.env.REACT_APP_BROKER === "TATA" ||
        process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "ABIBL" ||
        (process.env.REACT_APP_BROKER === "GRAM" &&
          TypeReturn(type) === "bike") ||
        process.env.REACT_APP_BROKER === "SPA") &&
      (process.env.REACT_APP_BROKER === "OLA" ||
        process.env.REACT_APP_BROKER === "TATA" ||
        process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "ABIBL" ||
        (process.env.REACT_APP_BROKER === "GRAM" &&
          TypeReturn(type) === "bike") ||
        process.env.REACT_APP_BROKER === "SPA" ||
        process.env.REACT_APP_BROKER === "RB")
    ) {
      dispatch(
        AdrilaLoad(
          {
            registration_no: `${RegNo1}-${RegNo3}`,
            enquiryId: enquiry_id,
            section: TypeReturn(type),
            resetDetails: true,
            vehicleValidation: "Y",
          },
          true
        )
      );
    }
  };

  //onGridLoad if reg no is already populated and readOnly
  useEffect(() => {
    if (
      temp_data?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo &&
      (process.env.REACT_APP_BROKER === "RB" ||
        process.env.REACT_APP_BROKER === "TATA" ||
        process.env.REACT_APP_BROKER === "BAJAJ" ||
        process.env.REACT_APP_BROKER === "ABIBL" ||
        (process.env.REACT_APP_BROKER === "GRAM" &&
          TypeReturn(type) === "bike") ||
        process.env.REACT_APP_BROKER === "SPA")
    ) {
      OnGridLoad();
    }
  }, [temp_data?.corporateVehiclesQuoteRequest?.vehicleRegistrationNo, RegNo3]);

  const productType = (productType) => {
    switch (productType) {
      case "cv":
        return "commercial vehicle";
      case "car":
        return "car";
      case "bike":
        return "two-wheeler";
      default:
        return productType;
    }
  };

  //onSuccess
  useEffect(() => {
    if (
      ongridLoad &&
      !_.isEmpty(ongridLoad) &&
      ongridLoad?.status * 1 !== 101 &&
      ongridLoad?.status * 1 !== 102 &&
      ongridLoad?.status
    ) {
      if (process.env.REACT_APP_BROKER === "OLA") {
        if (ongridLoad?.ft_product_code === TypeReturn(type)) {
          swal(
            "Info",
            "Vehicle Details Found. Proposal will be updated accordingly.",
            "info"
          ).then(() => false && window.location.reload());
        } else {
          if (
            ongridLoad?.ft_product_code &&
            ongridLoad?.ft_product_code !== TypeReturn(type)
          ) {
            swal({
              text: `You've entered the registration number of a ${productType(
                ongridLoad?.ft_product_code
              )}`,
              icon: "warning",
              buttons: {
                ...(temp_data?.corporateVehiclesQuoteRequest
                  ?.journeyWithoutRegno === "Y" && {
                  catch: {
                    text: "Edit Reg. No.",
                    value: "confirm",
                  },
                }),
              },
              dangerMode: true,
              closeOnClickOutside: false,
            }).then((caseValue) => {
              switch (caseValue) {
                case "confirm":
                  navigator &&
                    navigator?.vibrate &&
                    navigator.vibrate([100, 0, 50]);
                  setValue("regNo2", "");
                  setValue("regNo3", "");
                  break;
                default:
              }
            });
          }
        }
      }
      if (
        (process.env.REACT_APP_BROKER === "RB" ||
          process.env.REACT_APP_BROKER === "TATA" ||
          process.env.REACT_APP_BROKER === "BAJAJ" ||
          process.env.REACT_APP_BROKER === "ABIBL" ||
          (process.env.REACT_APP_BROKER === "GRAM" &&
            TypeReturn(type) === "bike") ||
          process.env.REACT_APP_BROKER === "SPA") &&
        ongridLoad?.ft_product_code !== TypeReturn(type)
      ) {
        swal({
          text: `You've entered the registration number of a ${productType(
            ongridLoad?.ft_product_code
          )}`,
          icon: "warning",
          buttons: {
            ...(temp_data?.corporateVehiclesQuoteRequest
              ?.journeyWithoutRegno === "Y" && {
              catch: {
                text: "Edit Reg. No.",
                value: "confirm",
              },
            }),
            No: {
              text: `Proceed to ${ongridLoad?.ft_product_code} journey`,
              value: "No",
            },
          },
          dangerMode: true,
          closeOnClickOutside: false,
        }).then((caseValue) => {
          switch (caseValue) {
            case "confirm":
              navigator &&
                navigator?.vibrate &&
                navigator.vibrate([100, 0, 50]);
              setValue("regNo2", "");
              setValue("regNo3", "");
              break;
            case "No":
              ongridLoad?.redirectionUrl &&
                reloadPage(
                  `${
                    ongridLoad?.redirectionUrl
                  }/registration?enquiry_id=${enquiry_id}${
                    token ? `&token=${token}` : ""
                  }`
                );
              break;
            default:
          }
        });
      }
    }
    if (
      ongridLoad &&
      !_.isEmpty(ongridLoad) &&
      (ongridLoad?.status * 1 === 102 || ongridLoad?.status * 1 === 110)
    ) {
      swal(
        "Please note",
        "No record found for the entered vehicle registration number. Please check the registration number entered.",
        "error"
      ).then(() =>
        history.replace(
          `/${type}/registration?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ``
          }`
        )
      );
      swal({
        title: "Please note",
        text: "No record found for the entered vehicle registration number. Please check the registration number entered.",
        icon: "warning",
        buttons: {
          ...(temp_data?.corporateVehiclesQuoteRequest?.journeyWithoutRegno ===
            "Y" && {
            catch: {
              text: "Edit Reg. No.",
              value: "confirm",
            },
          }),
          cancel: "Restart Journey",
        },
        dangerMode: true,
        closeOnClickOutside: false,
      }).then((caseValue) => {
        switch (caseValue) {
          case "confirm":
            navigator && navigator?.vibrate && navigator.vibrate([100, 0, 50]);
            setValue("regNo2", "");
            setValue("regNo3", "");
            break;
          default:
            history.replace(
              `/${type}/registration?enquiry_id=${enquiry_id}${
                token ? `&token=${token}` : ``
              }`
            );
        }
      });
    }
  }, [ongridLoad]);

  //for selecting cascade dropdowns
  const [oneClick, setnewClick] = useState(0);
  useEffect(() => {
    if (
      _.isEmpty(CardData?.vehicle) &&
      _.isEmpty(vehicle) &&
      !_.isEmpty(category) &&
      !oneClick
    ) {
      document.getElementById("vehicleCategory") &&
        document.getElementById("vehicleCategory").focus();
      document.getElementById("vehicleCategory") &&
        document.getElementById("vehicleCategory").blur();
      setnewClick(1);
    }
  }, [category]);
  console.log(CardData, "CardData");

  return (
    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitVehicle)}>
      <Row
        style={{
          margin: lessthan768
            ? "-60px -30px 20px -30px"
            : "-60px -20px 20px -30px",
        }}
        className="p-2"
      >
        <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
          <div className="py-2">
            <FormGroupTag>Vehicle Registration No.</FormGroupTag>
            <div className="regGrp">
              <div className="regSplit">
                <Form.Control
                  autoComplete="off"
                  type="text"
                  // placeholder="Registration Number"
                  name="regNo1"
                  // readOnly={regNo ? true : false}
                  readOnly
                  ref={register}
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                  errors={
                    errors?.vehicaleRegistrationNumber ||
                    errors?.regNo1?.message
                  }
                  size="sm"
                  defaultValue={temp_data?.regNo1 || temp_data?.rtoNumber}
                  style={{ cursor: "not-allowed" }}
                />
              </div>
              <div className="regSplit">
                <Form.Control
                  autoComplete="off"
                  type="text"
                  // placeholder="AR"
                  name="regNo2"
                  readOnly={
                    regNo &&
                    (temp_data?.corporateVehiclesQuoteRequest
                      ?.journeyWithoutRegno === "N" ||
                      regNo === "NEW")
                      ? true
                      : false
                  }
                  ref={register}
                  onBlur={() => OnGridLoad()}
                  onInput={(e) =>
                    (e.target.value = e.target.value
                      .replace(/[^A-Za-z0-9\s]/gi, "")
                      .toUpperCase())
                  }
                  onChange={(e) =>
                    e.target.value.length === 3
                      ? document.querySelector(`input[name=regNo3]`).focus()
                      : ""
                  }
                  errors={
                    errors?.vehicaleRegistrationNumber ||
                    errors?.regNo2?.message
                  }
                  size="sm"
                  defaultValue={regNo2}
                />
              </div>
              <div className="regSplit">
                <Form.Control
                  autoComplete="off"
                  type="text"
                  // placeholder="7070"
                  name="regNo3"
                  readOnly={
                    regNo &&
                    true &&
                    (temp_data?.corporateVehiclesQuoteRequest
                      ?.journeyWithoutRegno === "N" ||
                      regNo === "NEW" ||
                      "")
                      ? true
                      : false
                  }
                  ref={register}
                  maxLength={"4"}
                  onBlur={() => OnGridLoad()}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                  errors={
                    errors?.vehicaleRegistrationNumber ||
                    errors?.regNo3?.message
                  }
                  size="sm"
                  defaultValue={regNo3}
                  onKeyDown={numOnly}
                />
              </div>
            </div>
            {!!errors?.vehicaleRegistrationNumber ||
            errors?.regNo1 ||
            errors?.regNo2 ||
            errors?.regNo3 ? (
              <ErrorMsg fontSize={"12px"}>
                {errors?.vehicaleRegistrationNumber?.message ||
                  errors?.regNo1?.message ||
                  errors?.regNo2?.message ||
                  errors?.regNo3?.message}
              </ErrorMsg>
            ) : (
              <Form.Text className="text-muted">
                <text style={{ color: "#bdbdbd" }}>e.g MH-04-AR-7070</text>
              </Form.Text>
            )}
            <input
              type="hidden"
              ref={register}
              name="vehicaleRegistrationNumber"
              value={
                temp_data?.corporateVehiclesQuoteRequest
                  ?.vehicleRegistrationNo !== "NEW"
                  ? `${RegNo1?.replace(/\s/g, "")}-${RegNo2?.replace(
                      /\s/g,
                      ""
                    )}-${RegNo3?.replace(/\s/g, "")}`
                  : "NEW"
              }
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
          <div className="py-2">
            <FormGroupTag>
              {temp_data?.corporateVehiclesQuoteRequest?.fuelType === "ELECTRIC"
                ? "Motor/Battery Number"
                : "Engine Number"}
            </FormGroupTag>
            <Form.Control
              autoComplete="off"
              type="text"
              readOnly={allFieldsReadOnly}
              placeholder={
                temp_data?.corporateVehiclesQuoteRequest?.fuelType ===
                "ELECTRIC"
                  ? "Enter Motor/Battery Number"
                  : "Enter Engine Number"
              }
              name="engineNumber"
              minlength="5"
              ref={register}
              onInput={(e) =>
                (e.target.value = e.target.value
                  .replace(/[^A-Za-z0-9]/gi, "")
                  .toUpperCase())
              }
              errors={errors?.engineNumber}
              isInvalid={errors?.engineNumber}
              size="sm"
            />
            {!!errors?.engineNumber && (
              <ErrorMsg fontSize={"12px"}>
                {errors?.engineNumber?.message}
              </ErrorMsg>
            )}
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
          <div className="py-2">
            <FormGroupTag>Chassis Number</FormGroupTag>
            <Form.Control
              autoComplete="off"
              readOnly={allFieldsReadOnly}
              name="chassisNumber"
              ref={register}
              type="text"
              onInput={(e) =>
                (e.target.value = e.target.value
                  .replace(/[^A-Za-z0-9]/gi, "")
                  .toUpperCase())
              }
              placeholder="Enter Chassis Number"
              errors={errors?.chassisNumber}
              isInvalid={errors?.chassisNumber}
              size="sm"
            />
            {!!errors?.chassisNumber && (
              <ErrorMsg fontSize={"12px"}>
                {errors?.chassisNumber?.message}
              </ErrorMsg>
            )}
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
          <StyledDatePicker disabled={true}>
            <div className="py-2 dateTimeOne">
              <FormGroupTag>{"Registration Date"}</FormGroupTag>
              <Controller
                control={control}
                name="registrationDate"
                render={({ onChange, onBlur, value, name }) => (
                  <DateInput
                    minDate={false}
                    maxDate={AdultCheck}
                    value={value}
                    name={name}
                    onChange={onChange}
                    ref={register}
                    readOnly
                  />
                )}
              />
            </div>
          </StyledDatePicker>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
          <StyledDatePicker>
            <div className="py-2 dateTimeOne">
              <FormGroupTag>{"Manufacture Month & Year"}</FormGroupTag>
              <Controller
                control={control}
                name="vehicleManfYear"
                render={({ onChange, onBlur, value, name }) => (
                  <DateInput
                    minDate={ManfVal}
                    maxDate={ManfValMax}
                    value={value}
                    name={name}
                    showMonthYearPicker
                    onChange={onChange}
                    ref={register}
                    readOnly={
                      temp_data?.corporateVehiclesQuoteRequest?.businessType ===
                        "newbusiness" || allFieldsReadOnly
                        ? true
                        : false
                    }
                  />
                )}
              />
              {!!errors?.vehicleManfYear && (
                <ErrorMsg fontSize={"12px"}>
                  {errors?.vehicleManfYear?.message}
                </ErrorMsg>
              )}
            </div>
          </StyledDatePicker>
        </Col>
        {(Number(temp_data?.quoteLog?.icId) === 20 ||
          temp_data?.selectedQuote?.companyAlias === "reliance") &&
          TypeReturn(type) === "cv" &&
          Number(temp_data?.productSubTypeId) === 6 &&
          temp_data?.parent?.productSubTypeCode !== "GCV" && (
            <>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2 fname">
                  <FormGroupTag>Vehicle Category</FormGroupTag>
                  <Form.Control
                    autoComplete="off"
                    as="select"
                    size="sm"
                    readOnly={allFieldsReadOnly}
                    ref={register}
                    name="vehicleCategory"
                    className="title_list"
                    id={"vehicleCategory"}
                    isInvalid={errors?.vehicleCategory}
                  >
                    {category.map(
                      ({ vehicleCategory, vehicleCategoryId }, index) => (
                        <option
                          selected={
                            Number(CardData?.vehicle?.vehicleCategory) ===
                              Number(vehicleCategoryId) ||
                            (_.isEmpty(CardData?.vehicle) &&
                              _.isEmpty(vehicle) &&
                              vehicleCategory &&
                              vehicleCategory.toLowerCase() === "taxi")
                          }
                          value={vehicleCategoryId}
                        >
                          {vehicleCategory}
                        </option>
                      )
                    )}
                  </Form.Control>
                  {!!errors?.vehicleCategory && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.vehicleCategory?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2 fname">
                  <FormGroupTag>Vehicle Usage Type</FormGroupTag>
                  <Form.Control
                    autoComplete="off"
                    as="select"
                    size="sm"
                    readOnly={allFieldsReadOnly}
                    ref={register}
                    name="vehicleUsageType"
                    className="title_list"
                    isInvalid={errors?.vehicleUsageType}
                  >
                    <option selected={true} value={"@"}>
                      Select
                    </option>
                    {usage.map(
                      ({ vehicleUsageType, vehicleUsageTypeId }, index) => (
                        <option
                          selected={
                            Number(CardData?.vehicle?.vehicleUsageType) ===
                              Number(vehicleUsageTypeId) ||
                            Number(
                              temp_data?.userProposal?.vehicleUsageType
                            ) === Number(vehicleUsageTypeId) ||
                            (_.isEmpty(CardData?.vehicle) &&
                              _.isEmpty(vehicle) &&
                              vehicleUsageType &&
                              process.env.REACT_APP_BROKER === "OLA" &&
                              vehicleUsageType.toLowerCase() === "others")
                          }
                          value={vehicleUsageTypeId}
                        >
                          {vehicleUsageType}
                        </option>
                      )
                    )}
                  </Form.Control>
                  {!!errors?.vehicleUsageType && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.vehicleUsageType?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
            </>
          )}
        {fields.includes("vehicleColor") && (
          <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
            <div className="py-2">
              <FormGroupTag>{`Vehicle Color ${
                (temp_data?.selectedQuote?.companyAlias === "sbi" &&
                  TypeReturn(type) !== "cv") ||
                ["sbi", "universal_sompo", "new_india", "oriental"].includes(
                  temp_data?.selectedQuote?.companyAlias
                )
                  ? ""
                  : "(Optional)"
              }`}</FormGroupTag>
              {["sbi", "universal_sompo", "new_india"].includes(
                temp_data?.selectedQuote?.companyAlias
              ) ? (
                <div className="fname">
                  <Form.Control
                    as="select"
                    // readOnly={allFieldsReadOnly}
                    size="sm"
                    ref={register}
                    name={`vehicleColor`}
                    errors={errors?.vehicleColor}
                    isInvalid={errors?.vehicleColor}
                    style={{ cursor: "pointer" }}
                  >
                    <option selected={true} value={"@"}>
                      Select
                    </option>

                    {colors?.map((color, index) => (
                      <option
                        selected={
                          CardData?.vehicle?.vehicleColor === color ||
                          temp_data?.userProposal?.vehicleColor === color
                        }
                        value={color}
                      >
                        {color}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              ) : (
                <Form.Control
                  autoComplete="off"
                  type="text"
                  // readOnly={allFieldsReadOnly}
                  placeholder="Enter Vehicle Color"
                  size="sm"
                  name="vehicleColor"
                  onInput={(e) =>
                    (e.target.value = ("" + e.target.value).toUpperCase())
                  }
                  maxLength="50"
                  ref={register}
                  errors={errors?.vehicleColor}
                  isInvalid={errors?.vehicleColor}
                />
              )}
              {!!errors?.vehicleColor && (
                <ErrorMsg fontSize={"12px"}>
                  {errors?.vehicleColor?.message}
                </ErrorMsg>
              )}
            </div>
          </Col>
        )}
        {fields.includes("inspectionType") &&
          TypeReturn(type) !== "bike" &&
          temp_data?.corporateVehiclesQuoteRequest?.businessType ===
            "breakin" && (
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2 fname">
                <FormGroupTag>Inspection type</FormGroupTag>
                <Form.Control
                  autoComplete="off"
                  as="select"
                  size="sm"
                  ref={register}
                  name="inspectionType"
                  className="title_list"
                  readOnly={allFieldsReadOnly}
                  style={{ cursor: "pointer" }}
                >
                  <option value={"Self"}>Self</option>
                  <option value={"Manual"}>Manual</option>
                </Form.Control>
              </div>
            </Col>
          )}
        {
          <>
            {(fields.includes("pucNo") ||
              temp_data?.selectedQuote?.companyAlias === "tata_aig") && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>{`PUC Number`}</FormGroupTag>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Enter PUC Number"
                    size="sm"
                    name="pucNo"
                    // readOnly={allFieldsReadOnly}
                    onInput={(e) =>
                      (e.target.value = ("" + e.target.value).toUpperCase())
                    }
                    maxLength="50"
                    ref={register}
                    errors={errors?.pucNo}
                    isInvalid={errors?.pucNo}
                  />
                  {!!errors?.pucNo && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.pucNo?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
            )}
            {fields.includes("pucExpiry") && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <StyledDatePicker>
                  <div className="py-2 dateTimeOne">
                    <FormGroupTag>PUC Expiry Date</FormGroupTag>
                    <Controller
                      control={control}
                      name="pucExpiry"
                      render={({ onChange, onBlur, value, name }) => (
                        <DateInput
                          minDate={new Date()}
                          // readOnly={allFieldsReadOnly}
                          //maxDate={new Date()}
                          value={value}
                          name={name}
                          onChange={onChange}
                          ref={register}
                          selected={
                            PUC_EXP ||
                            vehicle?.pucExpiry ||
                            CardData?.vehicle?.pucExpiry
                              ? DateUtil(
                                  PUC_EXP ||
                                    vehicle?.pucExpiry ||
                                    CardData?.vehicle?.pucExpiry
                                )
                              : false
                          }
                        />
                      )}
                    />
                    {!!errors?.pucExpiry && (
                      <ErrorMsg fontSize={"12px"}>
                        {errors?.pucExpiry?.message}
                      </ErrorMsg>
                    )}
                  </div>
                </StyledDatePicker>
              </Col>
            )}
          </>
        }
        <div style={{ display: "none" }}>
          {ToggleElem(
            "isValidPuc",
            "Do you have a valid PUC Certificate?",
            true,
            true,
            true
          )}
        </div>
        {ToggleElem("isVehicleFinance", "Is your Vehicle Financed?")}
        {watch("isVehicleFinance") ? (
          <>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className=" mt-1"
              style={{ marginBottom: "-10px" }}
            >
              <p
                style={{
                  color: Theme?.proposalHeader?.color
                    ? Theme?.proposalHeader?.color
                    : "#1a5105",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Financer details
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={8} className="">
              <div className="py-2 fname csip">
                <FormGroupTag>Select Financer</FormGroupTag>
                <SearchInput
                  handleSearch={handleSearch}
                  readOnly={allFieldsReadOnly}
                  options={financerList}
                  allowNew={false}
                  newSelectionPrefix={"Add new financer: "}
                  Controller={Controller}
                  control={control}
                  register={register}
                  name="financer_sel"
                  selected={financer_sel_opt}
                />
                {!!errors?.nameOfFinancer && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.nameOfFinancer?.message}
                  </ErrorMsg>
                )}
              </div>
              <>
                <input type="hidden" ref={register} name="nameOfFinancer" />
                <input type="hidden" ref={register} name="financer_name" />
              </>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2 fname">
                <FormGroupTag>Financer agreement type</FormGroupTag>
                <Form.Control
                  autoComplete="off"
                  as="select"
                  size="sm"
                  ref={register}
                  name="financerAgreementType"
                  className="title_list"
                  // readOnly={allFieldsReadOnly}
                  style={{ cursor: "pointer" }}
                  errors={errors?.financerAgreementType}
                  isInvalid={errors?.financerAgreementType}
                >
                  <option selected={true} value={"@"}>
                    Select
                  </option>
                  {Agreement.map(({ name, code }, index) => (
                    <option
                      selected={
                        CardData?.vehicle?.financerAgreementType === code ||
                        Agreement.length === 1 ||
                        (_.isEmpty(CardData?.vehicle) &&
                          _.isEmpty(vehicle) &&
                          name &&
                          name.toLowerCase() === "hypothecation")
                      }
                      value={code}
                    >
                      {name}
                    </option>
                  ))}
                </Form.Control>
                {!!errors?.financerAgreementType && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.financerAgreementType?.message}
                  </ErrorMsg>
                )}
                <input
                  type="hidden"
                  ref={register}
                  name="agreement_type"
                  value={AgreementTypeName}
                />
              </div>
            </Col>
            {fields.includes("hypothecationCity") && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2">
                  <FormGroupTag>Financer (City/Branch)</FormGroupTag>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Financer (City/Branch)"
                    name="hypothecationCity"
                    // readOnly={allFieldsReadOnly}
                    maxLength="50"
                    minlength="2"
                    ref={register}
                    onInput={(e) =>
                      (e.target.value =
                        e.target.value.length <= 1
                          ? ("" + e.target.value).toUpperCase()
                          : e.target.value)
                    }
                    errors={errors?.hypothecationCity}
                    isInvalid={errors?.hypothecationCity}
                    size="sm"
                  />
                  {!!errors?.hypothecationCity && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.hypothecationCity?.message}
                    </ErrorMsg>
                  )}
                </div>
              </Col>
            )}
          </>
        ) : (
          <noscript />
        )}
        {ToggleElem(
          "isCarRegistrationAddressSame",
          "Is your Vehicle registration address same as communication address?",
          true,
          allFieldsReadOnly ? allFieldsReadOnly : null,
          allFieldsReadOnly ? allFieldsReadOnly : null
        )}
        {!watch("isCarRegistrationAddressSame") && (
          <>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className=" mt-1"
              style={{ marginBottom: "-10px" }}
            >
              <p
                style={{
                  color:
                    process.env.REACT_APP_BROKER !== "OLA"
                      ? "black"
                      : Theme?.proposalHeader?.color
                      ? Theme?.proposalHeader?.color
                      : "#1a5105",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Vehicle Registration Address
              </p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>Address Line 1</FormGroupTag>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  // readOnly={allFieldsReadOnly}
                  placeholder="Address Line 1"
                  name="carRegistrationAddress1"
                  maxLength="50"
                  minlength="2"
                  ref={register}
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length <= 1
                        ? ("" + e.target.value)
                            .toUpperCase()
                            .replace(
                              /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
                              ""
                            )
                        : e.target.value)
                  }
                  errors={errors?.carRegistrationAddress1}
                  isInvalid={errors?.carRegistrationAddress1}
                  size="sm"
                />
                {!!errors?.carRegistrationAddress1 && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.carRegistrationAddress1?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>Address Line 2</FormGroupTag>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  // readOnly={allFieldsReadOnly}
                  placeholder="Address Line 2"
                  name="carRegistrationAddress2"
                  maxLength="50"
                  minlength="2"
                  ref={register}
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length <= 1
                        ? ("" + e.target.value)
                            .toUpperCase()
                            .replace(
                              /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
                              ""
                            )
                        : e.target.value)
                  }
                  errors={errors?.carRegistrationAddress2}
                  isInvalid={errors?.carRegistrationAddress2}
                  size="sm"
                />
                {!!errors?.carRegistrationAddress2 && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.carRegistrationAddress2?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>Address Line 3 (optional)</FormGroupTag>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  placeholder="Address Line 3"
                  name="carRegistrationAddress3"
                  maxLength="50"
                  // readOnly={allFieldsReadOnly}
                  minlength="2"
                  ref={register}
                  onInput={(e) =>
                    (e.target.value =
                      e.target.value.length <= 1
                        ? ("" + e.target.value)
                            .toUpperCase()
                            .replace(
                              /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
                              ""
                            )
                        : e.target.value)
                  }
                  errors={errors?.carRegistrationAddress3}
                  isInvalid={errors?.carRegistrationAddress3}
                  size="sm"
                />
                {!!errors?.carRegistrationAddress3 && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.carRegistrationAddress3?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>Pincode</FormGroupTag>
                <Form.Control
                  autoComplete="off"
                  name="carRegistrationPincode"
                  ref={register}
                  type="tel"
                  // readOnly={allFieldsReadOnly}
                  placeholder="Pincode"
                  errors={errors?.carRegistrationPincode}
                  isInvalid={errors?.carRegistrationPincode}
                  size="sm"
                  onKeyDown={numOnly}
                  maxLength="6"
                />
                {!!errors?.carRegistrationPincode && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.carRegistrationPincode?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>State</FormGroupTag>
                <Form.Control
                  name="carRegistrationState"
                  ref={register}
                  type="text"
                  placeholder="State"
                  errors={errors?.carRegistrationState}
                  isInvalid={errors?.carRegistrationState}
                  size="sm"
                  readOnly
                  style={{ cursor: "not-allowed" }}
                />
                {!!errors?.carRegistrationState && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.carRegistrationState?.message}
                  </ErrorMsg>
                )}
              </div>
              <input
                name="carRegistrationStateId"
                ref={register}
                type="hidden"
                value={pin?.state?.state_id}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2 fname">
                <FormGroupTag>City</FormGroupTag>
                <Form.Control
                  as="select"
                  size="sm"
                  // readOnly={allFieldsReadOnly}
                  ref={register}
                  name={`carRegistrationCity`}
                  errors={errors?.carRegistrationCity}
                  isInvalid={errors?.carRegistrationCity}
                  style={{ cursor: "pointer" }}
                >
                  <option selected value={"@"}>
                    Select
                  </option>
                  {pin?.city?.map(({ city_name, city_id }, index) => (
                    <option
                      selected={
                        CardData?.vehicle?.carRegistrationCity?.trim() ===
                          city_name?.trim() ||
                        (pin?.city?.length === 1 &&
                          !CardData?.vehicle?.carRegistrationCity?.trim()) ||
                        (_.isEmpty(CardData?.vehicle) &&
                          _.isEmpty(vehicle) &&
                          temp_data?.userProposal?.carRegistrationCity &&
                          temp_data?.userProposal?.carRegistrationCity.trim() ===
                            city_name?.trim())
                      }
                      value={city_name}
                    >
                      {city_name}
                    </option>
                  ))}
                </Form.Control>
                {!!errors?.carRegistrationCity && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.carRegistrationCity?.message}
                  </ErrorMsg>
                )}
              </div>
              <input
                name="carRegistrationCityId"
                ref={register}
                type="hidden"
              />
            </Col>
          </>
        )}
        {/*-----Hidden I/P-----*/}
        <input
          type="hidden"
          ref={register}
          name="rtoLocation"
          value={temp_data?.regNo1 || temp_data?.rtoNumber}
        />
        <Col
          sm={12}
          lg={12}
          md={12}
          xl={12}
          className="d-flex justify-content-center mt-5"
        >
          <Button
            type="submit"
            buttonStyle="outline-solid"
            disabled={gridLoad}
            hex1={
              Theme?.proposalProceedBtn?.hex1
                ? Theme?.proposalProceedBtn?.hex1
                : "#4ca729"
            }
            hex2={
              Theme?.proposalProceedBtn?.hex2
                ? Theme?.proposalProceedBtn?.hex2
                : "#4ca729"
            }
            borderRadius="5px"
            color="white"
            shadow={"none"}
            onClick={() =>
              navigator && navigator?.vibrate && navigator.vibrate([100, 0, 50])
            }
            // disabled={!PUC ? true : false}
          >
            <text
              style={{
                fontSize: "15px",
                padding: "-20px",
                margin: "-20px -5px -20px -5px",
                fontWeight: "400",
              }}
            >
              {gridLoad ? (
                <text>
                  Fetching Details{" "}
                  <Spinner animation="grow" variant="light" size="sm" />
                </text>
              ) : PolicyCon ? (
                `Proceed to Policy${!lessthan376 ? " Details" : ""}`
              ) : (
                "Proceed" || ""
              )}
            </text>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const TopDiv = styled.div`
  .switch input:checked + .slider {
    background-color: ${({ theme }) =>
      theme.questionsProposal?.toggleBackgroundColor || "#006600"};
  }
`;

const StyledDatePicker = styled.div`
  .dateTimeOne .date-header {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
  .dateTimeOne {
    ${(props) => (props?.disabled ? `cursor: not-allowed !important;` : ``)}
  }
  .dateTimeOne .react-datepicker__input-container input {
    ${(props) => (props?.disabled ? `cursor: not-allowed !important;` : ``)}
  }
  .dateTimeOne .react-datepicker__day:hover {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
`;

export default VehicleCard;
