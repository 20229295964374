import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Table, Form, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  currencyFormater,
  camelToUnderscore,
  scrollToTargetAdjusted,
} from "utils";
import CustomTooltip from "../../../../components/tooltip/CustomTooltip";
import _ from "lodash";
import { TypeReturn } from "modules/type";

export const MobilePremiumBreakup = ({
  quote,
  handlePremPdfClick,
  handleEmailClick,
  type,
  totalPremiumB,
  revisedNcb,
  totalPremiumC,
  GetAddonValue,
  getAddonName,
  totalApplicableAddonsMotor,
  addonDiscountPercentage,
  others,
  othersList,
  totalAddon,
  totalPremium,
  gst,
  finalPremium,
  handleClick,
  otherDiscounts,
  show,
  lessthan767,
  ElectricalValue,
  NonElectricalValue,
  BiFuelValue,
  totalPremiumA,
  claimList,
  setZdlp,
  zdlp,
  claimList_gdd,
  setZdlp_gdd,
  zdlp_gdd,
}) => {
  const { temp_data } = useSelector((state) => state.home);
  const { addOnsAndOthers } = useSelector((state) => state.quotes);

  console.log("addOnsAndOthers", addOnsAndOthers?.selectedAdditions);

  const { height, width } = useWindowDimensions();
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  console.log("addons", quote.applicableAddons);
  let userAgent = navigator.userAgent;
  let isMobileIOS = false; //initiate as false
  // device detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream && lessthan767) {
    isMobileIOS = true;
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  useEffect(() => {
    scrollToTargetAdjusted("bodyCard", 45);
  }, [show]);
  const [Info, setInfo] = useState(lessthan767 ? false : true);

  const innerHeight = window.innerHeight;

  //LL-paid-driver/cleaner/conductor
  const llpdCon =
    quote?.llPaidDriverPremium * 1 ||
    quote?.llPaidConductorPremium * 1 ||
    quote?.llPaidCleanerPremium * 1;

  return (
    <Container innerHeight={innerHeight}>
      <Header>
        <LogoContainer>
          {" "}
          <img
            src={quote?.companyLogo ? quote?.companyLogo : ""}
            alt=""
            className="PremIconMobile"
            id="premium_breakup_ic_img"
            style={{ height: "auto", width: "100%" }}
          />
        </LogoContainer>
        {/* <DataContainer>
					<div className="premBreakupHeading"> Premium Breakup &gt;</div>
				</DataContainer> */}
        <PdfEmailContainer style={{ display: "flex", alignItems: "center" }}>
          <div className="mailAndPdfContainer">
            <div className="mailAndPdf" onClick={handlePremPdfClick}>
              {" "}
              <div className="logoWrapper">
                {" "}
                <i
                  className="fa fa-file-pdf-o"
                  style={{
                    fontSize: "16px",
                    color:
                      process.env.REACT_APP_BROKER === "ABIBL"
                        ? "#fff"
                        : "#333",
                  }}
                  aria-hidden="true"
                ></i>{" "}
              </div>
              <div className="emailText" style={{ fontSize: "14px" }}>
                {" "}
                PDF{" "}
              </div>
            </div>
            <button
              // disabled={process.env.REACT_APP_BROKER === "BAJAJ" && true}
              style={{
                all: "unset",
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              }}
              onClick={handleEmailClick}
            >
              {" "}
              <div className="logoWrapper">
                {" "}
                <i
                  className="fa fa-share-alt"
                  //	style="color:#1f0054!important;"
                  style={{
                    fontSize: "15px",
                    color:
                      process.env.REACT_APP_BROKER === "ABIBL"
                        ? "#fff"
                        : "#333",
                  }}
                  aria-hidden="true"
                ></i>{" "}
              </div>
              <div className="emailText" style={{ fontSize: "14px" }}>
                {" "}
                SHARE{" "}
              </div>
            </button>
          </div>
        </PdfEmailContainer>
      </Header>
      <Body height={height}>
        {
          <BodyDetails>
            {lessthan767 && (
              <p
                onClick={() => (Info ? setInfo(false) : setInfo(true))}
                style={
                  Info
                    ? {
                        marginBottom: "10px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }
                    : { fontSize: "12px", fontWeight: 600 }
                }
              >
                {"Vehicle Details"}
                {
                  <i
                    style={{
                      fontSize: "18px",
                      position: "relative",
                      top: "1.5px",
                    }}
                    className={
                      Info ? "ml-1 fa fa-angle-up" : "ml-1 fa fa-angle-down"
                    }
                  ></i>
                }
              </p>
            )}
            {Info && (
              <div className="vehicleDetails">
                <div className="idvData">
                  IDV:{" "}
                  {temp_data?.tab === "tab2" ? (
                    <Badge
                      variant="secondary"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Not Applicable
                    </Badge>
                  ) : (
                    ` ₹ ${currencyFormater(quote?.idv)}`
                  )}
                </div>
                <div className="mmvData">
                  {quote?.mmvDetail?.manfName}-{quote?.mmvDetail?.modelName}-
                  {quote?.mmvDetail?.versionName}-
                  {temp_data?.journeyCategory === "GCV"
                    ? quote?.mmvDetail?.grossVehicleWeight
                    : quote?.mmvDetail?.cubicCapacity}
                  {temp_data?.journeyCategory === "GCV" ? "GVW" : "cc"}
                </div>
                <div>
                  {quote?.fuelType} | {quote?.vehicleRegistrationNo} -{" "}
                  {temp_data?.rtoCity}
                </div>
                {temp_data?.corporateVehiclesQuoteRequest?.selectedGvw && (
                  <div>
                    Gross Vehicle Weight (lbs) :{" "}
                    {temp_data?.corporateVehiclesQuoteRequest?.selectedGvw}
                  </div>
                )}
              </div>
            )}
          </BodyDetails>
        }
        <BodyPremiumBreakup
          id={"bodyCard"}
          style={Info ? { marginTop: "-10px" } : { marginTop: "-40px" }}
        >
          <PremiumBreakupMobSection>
            <div className="premiumBreakupMobSection__header">Own Damage</div>
            <div className="premiumBreakupMobSection__content">
              <div>Basic Own Damage : </div>
              <div className="premText">
                {" "}
                ₹ {currencyFormater(quote?.basicPremium)}
              </div>
            </div>
            {addOnsAndOthers.selectedAccesories?.includes(
              "Electrical Accessories"
            ) && (
              <div className="premiumBreakupMobSection__content">
                <div>Electrical Accessories : </div>
                <div className="premText">
                  {quote?.motorElectricAccessoriesValue * 1 ||
                  ElectricalValue() > 0
                    ? `₹ 
                  ${currencyFormater(
                    quote?.motorElectricAccessoriesValue * 1 +
                      (ElectricalValue() || 0)
                  )}`
                    : "N/A"}
                </div>
              </div>
            )}
            {addOnsAndOthers.selectedAccesories?.includes(
              "Non-Electrical Accessories"
            ) && (
              <div className="premiumBreakupMobSection__content">
                <div>Non-Electrical Accessories : </div>
                <div className="premText">
                  {quote?.motorNonElectricAccessoriesValue * 1 ||
                  NonElectricalValue() > 0
                    ? `₹ 
                  ${currencyFormater(
                    quote?.motorNonElectricAccessoriesValue * 1 +
                      (NonElectricalValue() || 0)
                  )}`
                    : "N/A"}
                </div>
              </div>
            )}
            {(quote?.motorLpgCngKitValue * 1 ||
              quote?.motorLpgCngKitValue * 1 === 0) && (
              <div className="premiumBreakupMobSection__content">
                <div>LPG/CNG Kit : </div>
                <div className="premText">
                  {quote?.motorLpgCngKitValue * 1 || BiFuelValue() > 0 ? (
                    `₹ 
                  ${currencyFormater(
                    quote?.company_alias === "hdfc_ergo"
                      ? quote?.motorLpgCngKitValue + (BiFuelValue() || 0)
                      : quote?.motorLpgCngKitValue
                  )}`
                  ) : temp_data?.fuel === "CNG" ? (
                    <Badge
                      variant="primary"
                      style={{
                        position: "relative",
                        bottom: "2px",
                      }}
                    >
                      Included
                    </Badge>
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>
            )}
            {/* {addOnsAndOthers.selectedAccesories?.includes(
              "External Bi-Fuel Kit CNG/LPG"
            ) && (
              <div className="premiumBreakupMobSection__content">
                <div>External Bi-Fuel Kit CNG/LPG : </div>
                <div className="premText">
                  {" "}
                  ₹{" "}
                  {currencyFormater(
                    quote?.motorLpgCngKitValue * 1 + (BiFuelValue() || 0)
                  )}
                </div>
              </div>
            )} */}
            {addOnsAndOthers.selectedAccesories?.includes("Trailer") ? (
              <div className="premiumBreakupMobSection__content">
                <div>Trailer : </div>
                <div className="premText">
                  {" "}
                  ₹ {currencyFormater(quote?.trailerValue)}
                </div>
              </div>
            ) : (
              <noscript />
            )}
            {quote?.limitedtoOwnPremisesOD ? (
              <div className="premiumBreakupMobSection__content">
                <div>Vehicle limited to own premises : </div>
                <div className="premText">
                  {" "}
                  ₹ {currencyFormater(quote?.limitedtoOwnPremisesOD)}
                </div>
              </div>
            ) : (
              <noscript />
            )}
            {addOnsAndOthers?.selectedAdditions?.includes(
              "Geographical Extension"
            ) && (
              <div className="premiumBreakupMobSection__content">
                <div>Geographical Extension : </div>
                <div className="premText">
                  {" "}
                  ₹ {currencyFormater(quote?.geogExtensionODPremium)}
                </div>
              </div>
            )}
            <div className="premiumBreakupMobSection__header">
              <>
                <div> Total OD Premium (A) </div>
                <div className="premText">
                  {" "}
                  ₹{" "}
                  {currencyFormater(
                    quote?.finalOdPremium * 1 +
                      (ElectricalValue() + NonElectricalValue() + BiFuelValue())
                  )}
                </div>
              </>
            </div>
          </PremiumBreakupMobSection>
          <PremiumBreakupMobSection>
            <div className="premiumBreakupMobSection__header">Liability</div>
            <div className="premiumBreakupMobSection__content">
              <div>Third Party Liability : </div>
              <div className="premText">
                {" "}
                ₹ {currencyFormater(quote?.tppdPremiumAmount)}
              </div>
            </div>
            <div className="premiumBreakupMobSection__content">
              <div>LPG/CNG Kit TP : </div>
              <div className="premText">
                {quote?.cngLpgTp * 1 ? "₹" : ""}{" "}
                {quote?.cngLpgTp * 1
                  ? currencyFormater(quote?.cngLpgTp)
                  : "N/A"}
              </div>
            </div>
            {addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover") && (
              <div className="premiumBreakupMobSection__content">
                <div>TPPD Cover : </div>
                <div className="premText">
                  {" "}
                  - ₹ {currencyFormater(quote?.tppdDiscount)}
                </div>
              </div>
            )}
            {addOnsAndOthers?.selectedAdditions?.includes(
              "Unnamed Passenger PA Cover"
            ) && (
              <div className="premiumBreakupMobSection__content">
                <div>PA For Unnamed Passenger : </div>
                <div className="premText">
                  {" "}
                  {quote?.coverUnnamedPassengerValue * 1
                    ? `₹
                  ${currencyFormater(
                    quote?.companyAlias === "sbi" &&
                      addOnsAndOthers?.selectedCpa?.includes(
                        "Compulsory Personal Accident"
                      ) &&
                      !_.isEmpty(addOnsAndOthers?.isTenure)
                      ? quote?.coverUnnamedPassengerValue *
                          (type === "bike" ? 5 : 3)
                      : quote?.coverUnnamedPassengerValue
                  )}`
                    : "N/A"}
                </div>
              </div>
            )}

            {addOnsAndOthers?.selectedAdditions?.includes(
              "PA cover for additional paid driver"
            ) ||
            addOnsAndOthers?.selectedAdditions?.includes(
              "PA paid driver/conductor/cleaner"
            ) ? (
              <div className="premiumBreakupMobSection__content">
                <div>Additional PA Cover To Paid Driver : </div>
                <div className="premText">
                  {" "}
                  {quote?.motorAdditionalPaidDriver * 1
                    ? `₹
                  ${currencyFormater(
                    quote?.companyAlias === "sbi" &&
                      addOnsAndOthers?.selectedCpa?.includes(
                        "Compulsory Personal Accident"
                      ) &&
                      !_.isEmpty(addOnsAndOthers?.isTenure)
                      ? quote?.motorAdditionalPaidDriver *
                          (type === "bike" ? 5 : 3)
                      : quote?.motorAdditionalPaidDriver
                  )}`
                    : "N/A"}
                </div>
              </div>
            ) : (
              <noscript />
            )}

            {addOnsAndOthers?.selectedAdditions?.includes(
              "PA paid driver/conductor/cleaner"
            ) && (
              <div className="premiumBreakupMobSection__content">
                <div>PA Cover To Paid Driver/Conductor/Cleaner: </div>
                <div className="premText">
                  {" "}
                  ₹ {currencyFormater(quote?.motorAdditionalPaidDriver)}
                </div>
              </div>
            )}
            {addOnsAndOthers?.selectedAdditions?.includes("LL paid driver") ? (
              <div className="premiumBreakupMobSection__content">
                <div>Legal Liability To Paid Driver : </div>
                <div className="premText">
                  {" "}
                  ₹ {currencyFormater(quote?.defaultPaidDriver)}
                </div>
              </div>
            ) : (
              <noscript />
            )}
            {quote?.limitedtoOwnPremisesTP ? (
              <div className="premiumBreakupMobSection__content">
                <div>Vehicle limited to own premises : </div>
                <div className="premText">
                  {" "}
                  ₹ {currencyFormater(quote?.limitedtoOwnPremisesTP)}
                </div>
              </div>
            ) : (
              <noscript />
            )}
            {addOnsAndOthers?.selectedAdditions?.includes(
              "Geographical Extension"
            ) && (
              <div className="premiumBreakupMobSection__content">
                <div>Geographical Extension : </div>
                <div className="premText">
                  {" "}
                  ₹ {currencyFormater(quote?.geogExtensionTPPremium)}
                </div>
              </div>
            )}

            {addOnsAndOthers?.selectedAdditions?.includes(
              "LL paid driver/conductor/cleaner"
            ) &&
              (!llpdCon ? (
                <div className="premiumBreakupMobSection__content">
                  <div>Legal Liability To Paid Driver/Conductor/Cleaner :</div>
                  <div className="premText">
                    {quote?.defaultPaidDriver * 1
                      ? `₹ ${currencyFormater(quote?.defaultPaidDriver)}`
                      : "N/A"}
                  </div>
                </div>
              ) : (
                <>
                  <div className="premiumBreakupMobSection__content">
                    <div>{"Legal Liability To Paid Driver"} : </div>
                    <div className="premText">
                      {quote?.llPaidDriverPremium * 1
                        ? `₹ ${currencyFormater(quote?.llPaidDriverPremium)}`
                        : "N/A"}
                    </div>
                  </div>
                  <div className="premiumBreakupMobSection__content">
                    <div>
                      {`Legal Liability To Paid Conductor${
                        quote?.companyAlias === "icici_lombard"
                          ? "/Cleaner"
                          : ""
                      }`}{" "}
                      :{" "}
                    </div>
                    <div className="premText">
                      {quote?.llPaidConductorPremium * 1
                        ? `₹ ${currencyFormater(quote?.llPaidConductorPremium)}`
                        : "N/A"}
                    </div>
                  </div>
                  {quote?.companyAlias !== "icici_lombard" ? (
                    <div className="premiumBreakupMobSection__content">
                      <div>{"Legal Liability To Paid Cleaner"} : </div>
                      <div className="premText">
                        {quote?.llPaidCleanerPremium * 1
                          ? `₹ ${currencyFormater(quote?.llPaidCleanerPremium)}`
                          : "N/A"}
                      </div>
                    </div>
                  ) : (
                    <noscript />
                  )}
                </>
              ))}

            {temp_data?.ownerTypeId === 1 && !temp_data?.odOnly && (
              <>
                <div className="premiumBreakupMobSection__content">
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <text
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        document.getElementById(
                          `Compulsory Personal Accident`
                        ) &&
                          document
                            .getElementById(`Compulsory Personal Accident`)
                            .click();
                      }}
                    >
                      Compulsory PA Cover For Owner Driver{" "}
                    </text>
                    <FilterMenuBoxCheckConatiner>
                      <div className="filterMenuBoxCheck">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={addOnsAndOthers?.selectedCpa?.includes(
                            "Compulsory Personal Accident"
                          )}
                          onClick={() => {
                            document.getElementById(
                              `Compulsory Personal Accident`
                            ) &&
                              document
                                .getElementById(`Compulsory Personal Accident`)
                                .click();
                          }}
                        />

                        <label
                          style={{ border: "none" }}
                          className="form-check-label"
                          htmlFor={"Compulsory Personal Accident"}
                        ></label>
                      </div>
                    </FilterMenuBoxCheckConatiner>{" "}
                  </div>

                  <div className="premText">
                    {" "}
                    {addOnsAndOthers?.selectedCpa?.includes(
                      "Compulsory Personal Accident"
                    ) ? (
                      !_.isEmpty(addOnsAndOthers?.isTenure) ? (
                        !quote?.multiYearCpa * 1 ? (
                          "N/A"
                        ) : (
                          <>₹ {currencyFormater(quote?.multiYearCpa)}</>
                        )
                      ) : !quote?.compulsoryPaOwnDriver * 1 ? (
                        "N/A"
                      ) : (
                        <>₹ {currencyFormater(quote?.compulsoryPaOwnDriver)}</>
                      )
                    ) : (
                      <>₹ 0</>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="premiumBreakupMobSection__header">
              <div> Total Liability Premium (B) </div>
              <div className="premText">
                {" "}
                ₹{" "}
                {currencyFormater(
                  totalPremiumB - (quote?.tppdDiscount * 1 || 0)
                )}
              </div>
            </div>
          </PremiumBreakupMobSection>
          <PremiumBreakupMobSection>
            <div className="premiumBreakupMobSection__header">
              Own Damage Discounts
            </div>
            <div className="premiumBreakupMobSection__content">
              <div>Deduction of NCB : </div>
              <div className="premText"> ₹ {currencyFormater(revisedNcb)}</div>
            </div>

            {addOnsAndOthers?.selectedDiscount?.includes(
              "Is the vehicle fitted with ARAI approved anti-theft device?"
            ) && (
              <div className="premiumBreakupMobSection__content">
                <div>Anti-Theft : </div>
                <div className="premText">
                  {" "}
                  ₹ {currencyFormater(quote?.antitheftDiscount)}
                </div>
              </div>
            )}

            {addOnsAndOthers?.selectedDiscount?.includes(
              "Voluntary Discounts"
            ) && (
              <div className="premiumBreakupMobSection__content">
                <div>Voluntary Deductible : </div>
                <div className="premText">
                  {quote?.voluntaryExcess * 1
                    ? `₹ ${currencyFormater(quote?.voluntaryExcess)}`
                    : "N/A"}
                </div>
              </div>
            )}

            {addOnsAndOthers?.selectedDiscount?.includes(
              "Automobile Association of India Discount"
            ) && (
              <div className="premiumBreakupMobSection__content">
                <div>Automobile Association of India : </div>
                <div className="premText">
                  {quote?.aaiDiscount * 1
                    ? `₹ ${currencyFormater(quote?.aaiDiscount)}`
                    : "N/A"}
                </div>
              </div>
            )}

            {otherDiscounts * 1 ? (
              <div className="premiumBreakupMobSection__content">
                <div>Other Discounts: </div>
                <div className="premText">
                  {" "}
                  ₹ {currencyFormater(otherDiscounts)}
                </div>
              </div>
            ) : (
              <noscript />
            )}

            <div className="premiumBreakupMobSection__header">
              <div> Total Discount (C) </div>
              <div className="premText">
                {" "}
                ₹{" "}
                {currencyFormater(
                  totalPremiumC - (quote?.tppdDiscount * 1 || 0)
                )}
              </div>
            </div>
          </PremiumBreakupMobSection>
          <PremiumBreakupMobSection>
            <div className="premiumBreakupMobSection__header">Addons</div>
            <>
              {quote?.company_alias === "godigit" &&
                ((quote?.gdd !== "Y" &&
                  !_.isEmpty(claimList) &&
                  claimList.length > 1) ||
                  (quote?.gdd === "Y" &&
                    !_.isEmpty(claimList_gdd) &&
                    claimList_gdd.length > 1)) && (
                  <div
                    style={{
                      background: "#E0E0E0",
                      borderRadius: "5px",
                      color: "black",
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "5px 0px",
                      padding: "2px 0px 3px 7px",
                    }}
                  >
                    <div style={{ fontSize: "11px" }}>Zero-dep claim</div>
                    <div className="text-right" style={{ fontSize: "14px" }}>
                      {quote?.gdd !== "Y" ? (
                        !_.isEmpty(claimList) && claimList.length > 1 ? (
                          <>
                            {
                              <Badge
                                variant={"light"}
                                className="mx-1"
                                style={
                                  claimList.sort().indexOf(zdlp) > 0
                                    ? {
                                        color: "red",
                                        position: "relative",
                                        bottom: "1px",
                                      }
                                    : {
                                        visibility: "hidden",
                                      }
                                }
                                onClick={() =>
                                  setZdlp(
                                    claimList[
                                      claimList.sort().indexOf(zdlp) - 1
                                    ]
                                  )
                                }
                              >
                                <i class="fa fa-minus"></i>
                              </Badge>
                            }
                            <Badge
                              style={{
                                fontSize: [
                                  "BAJAJ",
                                  "ACE",
                                  "PINC",
                                  "SRIDHAR",
                                ].includes(process.env.REACT_APP_BROKER || "")
                                  ? "11px"
                                  : "12px",
                              }}
                            >
                              {zdlp === "ONE"
                                ? "ONE CLAIM"
                                : zdlp === "TWO"
                                ? "TWO CLAIM"
                                : `${zdlp}`}
                            </Badge>
                            <Badge
                              variant={"light"}
                              className="mx-1 mb-1"
                              style={
                                claimList.sort().indexOf(zdlp) <
                                claimList?.length * 1 - 1
                                  ? {
                                      color: "green",
                                      position: "relative",
                                      bottom: "1px",
                                    }
                                  : {
                                      visibility: "hidden",
                                    }
                              }
                              onClick={() =>
                                setZdlp(
                                  claimList[claimList.sort().indexOf(zdlp) + 1]
                                )
                              }
                            >
                              <i class="fa fa-plus"></i>
                            </Badge>
                          </>
                        ) : (
                          <noscript />
                        )
                      ) : !_.isEmpty(claimList_gdd) &&
                        claimList_gdd.length > 1 ? (
                        <>
                          {
                            <Badge
                              variant={"light"}
                              className="mx-1"
                              style={
                                claimList_gdd.sort().indexOf(zdlp_gdd) > 0
                                  ? {
                                      color: "red",
                                      position: "relative",
                                      bottom: "1px",
                                    }
                                  : {
                                      visibility: "hidden",
                                    }
                              }
                              onClick={() =>
                                setZdlp_gdd(
                                  claimList_gdd[
                                    claimList_gdd.sort().indexOf(zdlp_gdd) - 1
                                  ]
                                )
                              }
                            >
                              <i class="fa fa-minus"></i>
                            </Badge>
                          }
                          <Badge
                            style={{
                              fontSize: [
                                "BAJAJ",
                                "ACE",
                                "PINC",
                                "SRIDHAR",
                              ].includes(process.env.REACT_APP_BROKER || "")
                                ? "11px"
                                : "12px",
                            }}
                          >
                            {zdlp_gdd === "ONE"
                              ? "ONE CLAIM"
                              : zdlp_gdd === "TWO"
                              ? "TWO CLAIM"
                              : `${zdlp_gdd}`}
                          </Badge>
                          <Badge
                            variant={"light"}
                            className="mx-1 mb-1"
                            style={
                              claimList_gdd.sort().indexOf(zdlp_gdd) <
                              claimList_gdd?.length * 1 - 1
                                ? {
                                    color: "green",
                                    position: "relative",
                                    bottom: "1px",
                                  }
                                : {
                                    visibility: "hidden",
                                  }
                            }
                            onClick={() =>
                              setZdlp_gdd(
                                claimList_gdd[
                                  claimList_gdd.sort().indexOf(zdlp_gdd) + 1
                                ]
                              )
                            }
                          >
                            <i class="fa fa-plus"></i>
                          </Badge>
                        </>
                      ) : (
                        <noscript />
                      )}
                    </div>
                  </div>
                )}
            </>
            <>
              {quote?.applicableAddons?.length > 0 &&
                quote?.applicableAddons?.map((item, index) => (
                  <div
                    style={{
                      display:
                        quote?.company_alias === "reliance" &&
                        item === "roadSideAssistance" &&
                        TypeReturn(type) === "cv" &&
                        "none",
                    }}
                    className="premiumBreakupMobSection__content"
                  >
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <text
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            document.getElementById(
                              `${getAddonName(item, addonDiscountPercentage)}`
                            ) &&
                              document
                                .getElementById(
                                  `${getAddonName(
                                    item,
                                    addonDiscountPercentage
                                  )}`
                                )
                                .click();
                          }}
                        >
                          {getAddonName(item, addonDiscountPercentage)}{" "}
                        </text>
                        <FilterMenuBoxCheckConatiner>
                          <div className="filterMenuBoxCheck">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={addOnsAndOthers?.selectedAddons?.includes(
                                item
                              )}
                              onClick={() => {
                                document.getElementById(
                                  `${getAddonName(
                                    item,
                                    addonDiscountPercentage
                                  )}`
                                ) &&
                                  document
                                    .getElementById(
                                      `${getAddonName(
                                        item,
                                        addonDiscountPercentage
                                      )}`
                                    )
                                    .click();
                              }}
                            />

                            <label
                              style={{ border: "none" }}
                              className="form-check-label"
                              htmlFor={`${getAddonName(
                                item,
                                addonDiscountPercentage
                              )}`}
                            ></label>

                            <span style={{ marginLeft: "3px" }}></span>
                          </div>
                        </FilterMenuBoxCheckConatiner>
                      </div>

                      {GetAddonValue(item, addonDiscountPercentage) !==
                      "N/A" ? (
                        <div className="premText">
                          {" "}
                          {GetAddonValue(item, addonDiscountPercentage) ===
                          "N/S" ? (
                            <>N/S</>
                          ) : GetAddonValue(item, addonDiscountPercentage) ===
                            "N/A" ? (
                            <Badge
                              variant="danger"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              Not Available
                            </Badge>
                          ) : (
                            <>{GetAddonValue(item, addonDiscountPercentage)}</>
                          )}
                        </div>
                      ) : (
                        <>N/A</>
                      )}
                    </>
                  </div>
                ))}
              {others.map((item, index) => (
                <div className="premiumBreakupMobSection__content">
                  <div>
                    {camelToUnderscore(item) &&
                      camelToUnderscore(item)
                        .replace(/_/g, " ")
                        .split(" ")
                        .map(_.capitalize)
                        .join(" ")}
                  </div>
                  <div className="premText">
                    {" "}
                    {Number(othersList[item]) === 0 ? (
                      <i className="fa fa-check" style={{ color: "green" }}></i>
                    ) : (
                      `₹ ${currencyFormater(othersList[item])}`
                    )}{" "}
                  </div>
                </div>
              ))}
            </>

            <div className="premiumBreakupMobSection__header">
              <div> Total Addon Premium (D) </div>
              <div className="premText"> ₹ {currencyFormater(totalAddon)}</div>
            </div>
            {quote?.company_alias === "bajaj_allianz" &&
              type === "bike" &&
              Number(quote?.totalLoadingAmount) > 0 && (
                <div className="premiumBreakupMobSection__header">
                  <div> Total Loading Amount </div>
                  <div className="premText">
                    {" "}
                    ₹ {currencyFormater(totalAddon)}
                  </div>
                </div>
              )}
          </PremiumBreakupMobSection>

          <PremiumBreakupMobSection>
            <div className="premiumBreakupMobSection__header">
              <div> Total OD Payable (A + D - C) </div>
              <div className="premText">
                {" "}
                ₹{" "}
                {currencyFormater(
                  (totalPremiumA * 1 || 0) +
                    (quote?.totalLoadingAmount * 1 || 0) +
                    (quote?.underwritingLoadingAmount * 1 || 0) +
                    (totalAddon * 1 || 0) -
                    ((totalPremiumC * 1 || 0) - (quote?.tppdDiscount * 1 || 0))
                )}
              </div>
            </div>
            <div className="premiumBreakupMobSection__header">
              <div> Total TP Payable (B) </div>
              <div className="premText">
                {" "}
                ₹{" "}
                {currencyFormater(
                  totalPremiumB - (quote?.tppdDiscount * 1 || 0)
                )}
              </div>
            </div>
            <div className="premiumBreakupMobSection__header">
              <div> Net Premium </div>
              <div className="premText">
                {" "}
                ₹ {currencyFormater(totalPremium)}
              </div>
            </div>
            <div className="premiumBreakupMobSection__header">
              <div> GST </div>
              <div className="premText"> ₹ {currencyFormater(gst)}</div>
            </div>
            <div className="premiumBreakupMobSection__header">
              <div> Final Premium </div>
              <div className="premText">
                {" "}
                ₹ {currencyFormater(finalPremium)}
              </div>
            </div>
          </PremiumBreakupMobSection>
        </BodyPremiumBreakup>
      </Body>

      <BuyButtonMobile
        onClick={() => [
          navigator && navigator?.vibrate && navigator.vibrate([100, 0, 50]),
          handleClick(),
        ]}
      >
        BUY NOW <div className="amount">₹ {currencyFormater(finalPremium)}</div>
      </BuyButtonMobile>
    </Container>
  );
};

const Container = styled.div`
  display: none;
  height: ${({ innerHeight }) => (innerHeight ? innerHeight + "px" : "100vh")};
  min-height: calc(100vh - 100%);
  // min-height: -webkit-fill-available !important;

  @media (max-width: 993px) {
    display: flex;
    flex-direction: column;
    font-size: 10px;
  }
`;
const Header = styled.div`
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 5px 10px;
  //	justify-content: space-around;
  position: relative;
`;
const LogoContainer = styled.div`
  width: 20%;
`;
const DataContainer = styled.div`
  width: 40%;
  flex-direction: column;
  .premBreakupHeading {
    color: ${({ theme }) => theme.QuotePopups?.color2 || "#060"};
    padding: 0px 0px 5px 0px;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 400px) {
    max-width: 100px;
  }
`;
const PdfEmailContainer = styled.div`
  .mailAndPdfContainer {
    display: flex;
    justify-content: space-evenly;
    align-self: center;
    margin-left: 20px;
  }
  .mailAndPdf {
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .logoWrapper {
    border-radius: 50%;
    background-color: ${({ color, theme }) =>
      theme.FilterConatiner?.color || "#f3ff91"};
    width: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 30px;
  }
  .emailText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    margin-left: 5px;
    margin-right: 10px;
  }
`;
const Body = styled.div`
  display: flex;
  // min-height: ${(props) =>
    props.height ? `${props.height - 80}px` : "90vh"};
  height: 100%;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
`;
const BodyDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  padding: 10px 20px 10px 20px;
  .vehicleDetails {
    display: flex;
    flex-direction: column;
    font-size: 9px;
    background-color: #ebeef3;
    padding: 10px 10px 10px 10px;
    font-weight: 600;
  }
  .addonsAndCpa {
    padding: 2px 0px;
  }
`;
const BodyPremiumBreakup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 0px;
`;

const AddonInfo = styled.div`
  margin-top: 10px;
  .addonHead {
    font-size: 11px;
    margin-bottom: 15px;
    text-align: left;
    colr: #f1f4f7;
  }
  @media (max-width: 350px) {
    //	max-width: 100px;
  }
`;
const FilterMenuBoxCheckConatiner = styled.div`
  .filterMenuBoxCheck label:before {
    margin: -10px 0px 0 5px;
    right: -25px;
    left: unset;
    height: 14px;
    width: 14px;
    border-radius: 3px;
  }
  .filterMenuBoxCheck label {
    font-size: 10px !important;
    padding-left: 0px;
    border: 1px solid #686868;
    //min-width: 0%;
    //max-width: 90%;
  }
  .filterMenuBoxCheck input[type="checkbox"]:checked + label:before {
    background-color: ${({ theme }) => theme.CheckBox?.color || "#bdd400"};
    border: ${({ theme }) => theme.CheckBox?.border || "1px solid #bdd400"};
    box-shadow: ${({ theme }) =>
      theme.QuoteBorderAndFont?.shadowCheck || "none"};
    filter: ${({ theme }) =>
      theme.QuoteBorderAndFont?.filterPropertyCheckBox || "none"};
  }
`;

const PremiumBreakupMobSection = styled.div`
  padding: 7px 0px;
  margin: 0px 15px;
  border-bottom: 1px solid #ebeef3;
  .premiumBreakupMobSection__header {
    font-size: 11px;
    font-weight: 600;
    padding: 3px 5px;
    display: flex;
    justify-content: space-between;
    .premText {
      font-weight: 600;
      white-space: nowrap;
      margin-left: 4px;
    }
  }
  .premiumBreakupMobSection__content {
    font-size: 10px;
    font-weight: 400;
    padding: 0px 5px;
    display: flex;
    justify-content: space-between;
    .premText {
      font-weight: 400;
      white-space: nowrap;
      display: flex;
      margin-left: 4px;
      align-items: center;
    }
  }
`;

const BuyButtonMobile = styled.div`
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0px;
  font-size: 13px;
  background-color: ${({ theme }) => theme.QuotePopups?.color || "#bdd400"};
  height: 50px;
  color: white;
  font-weight: 600;
  width: 100%;
  .amount {
    font-size: 17px;
    margin-left: 10px;
  }
`;

export default MobilePremiumBreakup;
