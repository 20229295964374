import { currencyFormater } from "utils";

export const GetAddonValueNoBadge = (
  addonName,
  addonDiscountPercentage,
  quote,
  addOnsAndOthers
) => {
  let inbuilt = quote?.addOnsData?.inBuilt
    ? Object.keys(quote?.addOnsData?.inBuilt)
    : [];
  let additional = quote?.addOnsData?.additional
    ? Object.keys(quote?.addOnsData?.additional)
    : [];
  let selectedAddons = addOnsAndOthers?.selectedAddons;
  if (inbuilt?.includes(addonName)) {
    return `${
      Number(quote?.addOnsData?.inBuilt[addonName]) !== 0
        ? `₹ ${currencyFormater(
            parseInt(
              quote?.company_alias === "royal_sundaram"
                ? addonName === "ncbProtection" ||
                  addonName === "engineProtector" ||
                  addonName === "tyreSecure"
                  ? quote?.addOnsData?.inBuilt[addonName] -
                      (quote?.addOnsData?.inBuilt[addonName] *
                        addonDiscountPercentage) /
                        100 <
                    200
                    ? 200
                    : quote?.addOnsData?.inBuilt[addonName] -
                      quote?.addOnsData?.inBuilt[addonName] -
                      (quote?.addOnsData?.inBuilt[addonName] *
                        addonDiscountPercentage) /
                        100
                  : addonName === "roadSideAssistance"
                  ? quote?.addOnsData?.inBuilt[addonName]
                  : quote?.addOnsData?.inBuilt[addonName] -
                      (quote?.addOnsData?.inBuilt[addonName] *
                        addonDiscountPercentage) /
                        100 <
                    100
                  ? 100
                  : quote?.addOnsData?.inBuilt[addonName] -
                    (quote?.addOnsData?.inBuilt[addonName] *
                      addonDiscountPercentage) /
                      100
                : quote?.addOnsData?.inBuilt[addonName]
            )
          )}`
        : addonName === "roadSideAssistance" &&
          quote?.company_alias === "reliance"
        ? "-"
        : "Included"
    }`;
  } else if (
    additional?.includes(addonName) &&
    selectedAddons?.includes(addonName) &&
    Number(quote?.addOnsData?.additional[addonName]) !== 0 &&
    typeof quote?.addOnsData?.additional[addonName] === "number"
  ) {
    return `₹ ${currencyFormater(
      quote?.company_alias === "royal_sundaram"
        ? addonName === "ncbProtection" ||
          addonName === "engineProtector" ||
          addonName === "tyreSecure"
          ? quote?.addOnsData?.additional[addonName] -
              (quote?.addOnsData?.additional[addonName] *
                addonDiscountPercentage) /
                100 <
            200
            ? 200
            : quote?.addOnsData?.additional[addonName] -
              (quote?.addOnsData?.additional[addonName] *
                addonDiscountPercentage) /
                100
          : addonName === "roadSideAssistance"
          ? quote?.addOnsData?.additional[addonName]
          : quote?.addOnsData?.additional[addonName] -
              (quote?.addOnsData?.additional[addonName] *
                addonDiscountPercentage) /
                100 <
            100
          ? 100
          : quote?.addOnsData?.additional[addonName] -
            (quote?.addOnsData?.additional[addonName] *
              addonDiscountPercentage) /
              100
        : quote?.addOnsData?.additional[addonName]
    )}`;
  } else if (
    additional?.includes(addonName) &&
    Number(quote?.addOnsData?.additional[addonName]) === 0
  ) {
    return quote?.applicableAddons?.includes(addonName)
      ? "Optional"
      : "Not Available";
  } else if (
    !additional?.includes(addonName) &&
    selectedAddons?.includes(addonName)
  ) {
    return quote?.applicableAddons?.includes(addonName)
      ? "Optional"
      : "Not Available";
  } else if (Number(quote?.addOnsData?.additional[addonName]) === 0) {
    return quote?.applicableAddons?.includes(addonName)
      ? "Optional"
      : "Not Available";
  } else if (
    additional?.includes(addonName) &&
    !selectedAddons?.includes(addonName)
  ) {
    return "Optional";
  } else {
    return quote?.applicableAddons?.includes(addonName)
      ? "Optional"
      : "Not Available";
  }
};

export const getBrokerWebsite = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return "https://ola.fynity.in/";
    case "UNILIGHT":
      return "https://pcv.policyongo.com/landing-page";
    case "CP":
      return "https://motor.comparepolicy.com";
    case "FYNTUNE":
      return "https://cardemo-re8h1ssyiyqenhj8gzym7hqmo7n67jdq.fynity.in/";
    default:
      break;
  }
};
