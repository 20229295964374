import React from "react";
import "./compare-plan.scss";
import { createGlobalStyle } from "styled-components";
import CancelIcon from "@material-ui/icons/Cancel";

export const ComparePlan = ({ plan, logoUrl, id }) => {
  return (
    <>
      <div
        className="compare-box"
        style={{ border: plan && "solid 1px #e3e4e8" }}
      >
        {plan ? (
          <div className="compare-box__inside">
            <div
              style={{ cursor: "pointer" }}
              className="closeBtnCompare"
              onClick={() =>
                document.getElementById(`reviewAgree${id}`) && id
                  ? document.getElementById(`reviewAgree${id}`).click()
                  : {}
              }
            >
              <CancelIcon fontSize="small" />
            </div>
            <img className="compare-box__logo" src={logoUrl} alt="quote-logo" />
            <div className="compare-box__plan-name">{plan}</div>
          </div>
        ) : (
          <div className="compare-box__inside no-plan">Add Plans</div>
        )}
      </div>
    </>
  );
};

const GlobalStyle = createGlobalStyle`

${({ theme }) =>
  theme?.fontFamily &&
  `.compare-box {
  &__plan-name {
       font-family: ${theme?.fontFamily};
  }
}`};


`;
