import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Col, Row } from "react-bootstrap";
import { FloatButton } from "components";
import { useLocation, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Container, FormContainer, Avatar } from "./style";
import { clear, Prefill, LinkTrigger } from "./home.slice";
import {
  Registration,
  CarDetails,
  LeadPage,
  VehicleType,
  JourneyType,
} from "./steps";
import _ from "lodash";
import { reloadPage, RedirectFn, AccessControl } from "utils";
import { useMediaPredicate } from "react-media-hook";
import RenewalRegistration from "modules/Home/steps/Renewal/RenewalRegistration";
import TimeoutPopup from "modules/quotesPage/AbiblPopup/TimeoutPopup";
import { useIdleTimer } from "react-idle-timer";
import { TypeReturn } from "modules/type";

export const Home = (props) => {
  const dispatch = useDispatch();
  const { error, temp_data, theme_conf, errorSpecific } = useSelector(
    (state) => state.home
  );
  const { typeAccess } = useSelector((state) => state.login);

  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const typeId = query.get("typeid");
  const stepperfill = query.get("stepperfill");
  const journey_type = query.get("journey_type");
  const key = query.get("key");
  const savedStep = query.get("stepNo");
  const isPartner = query.get("is_partner");
  const reg_no_url = query.get("reg_no");
  const policy_no_url = query.get("policy_no");
  const { type } = props?.match?.params;

  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const lessthan400 = useMediaPredicate("(max-width: 400px)");
  const lessthan330 = useMediaPredicate("(max-width: 330px)");

  //IOS check.
  let userAgent = navigator.userAgent;
  let isMobileIOS = false; //initiate as false
  // device detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream && lessthan767) {
    isMobileIOS = true;
  }

  //Link-Click & Delivery
  useEffect(() => {
    key && dispatch(LinkTrigger({ key: key }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const checkSellerType = !_.isEmpty(temp_data?.agentDetails)
    ? temp_data?.agentDetails?.map((seller) => seller.sellerType)
    : [];

  //---------------Temp B2C block-------------------------
  useEffect(() => {
    if (
      (process.env?.REACT_APP_BROKER === "GRAM" ||
        process.env?.REACT_APP_BROKER === "ACE" ||
        (process.env?.REACT_APP_BROKER === "BAJAJ" &&
          process.env.REACT_APP_BASENAME !== "general-insurance")) &&
      !_.isEmpty(temp_data.corporateVehiclesQuoteRequest) &&
      !(checkSellerType?.includes("P") || checkSellerType?.includes("E")) &&
      !token &&
      process.env.REACT_APP_PROD === "YES"
    ) {
      if (process.env?.REACT_APP_BROKER === "GRAM") {
        swal("Access Control Error. User login required.", {
          closeOnClickOutside: false,
        }).then(() => reloadPage("https://dashboard.gramcover.com/"));
      }
      if (process.env?.REACT_APP_BROKER === "BAJAJ") {
        swal("Access Control Error. User login required.", {
          closeOnClickOutside: false,
        }).then(() => reloadPage("https://partner.bajajcapitalinsurance.com/"));
      }
      if (process.env?.REACT_APP_BROKER === "ACE") {
        swal("Access Control Error. User login required.", {
          closeOnClickOutside: false,
        }).then(() => reloadPage("https://dashboard.aceinsurance.com/"));
      }
    }
    if (
      process.env?.REACT_APP_BROKER === "OLA" &&
      !token &&
      !journey_type &&
      !_.isEmpty(temp_data) &&
      temp_data?.corporateVehiclesQuoteRequest?.journeyType !==
        "embeded-excel" &&
      temp_data?.corporateVehiclesQuoteRequest?.journeyType !== "embedded_scrub"
    ) {
      swal("Access Control Error. User login required.").then(() =>
        reloadPage(RedirectFn(token))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, temp_data]);

  //Access-Control
  useEffect(() => {
    if (!_.isEmpty(typeAccess)) {
      AccessControl(type, typeAccess, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeAccess]);

  //check enquiry
  useEffect(() => {
    if (location.pathname !== `/${type}/lead-page`) {
      if (temp_data?.enquiry_id || (enquiry_id && enquiry_id !== "null")) {
      } else {
        swal("Info", "Enquiry id not found, redirecting to homepage", "info", {
          closeOnClickOutside: false,
        }).then(() => history.replace(`/${type}/lead-page`));
      }
    }

    //Redirection after breakin submission
    if (
      temp_data?.userProposal?.isBreakinCase === "Y" ||
      temp_data?.userProposal?.isBreakinCase === "y"
    ) {
      swal("Info", "Breakin policy already generated.", "info", {
        closeOnClickOutside: false,
      }).then(() =>
        token
          ? reloadPage(RedirectFn(token))
          : history.replace(`/${type}/lead-page`)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data]);

  //Prefill Api
  useEffect(() => {
    if (enquiry_id) dispatch(Prefill({ enquiryId: enquiry_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  //onError
  useEffect(() => {
    if (error) {
      swal({
        title: "Error",
        text: enquiry_id
          ? `${`Trace ID:- ${enquiry_id}.\n Error Message:- ${error}`}`
          : error,
        icon: "error",
        buttons: {
          cancel: "Dismiss",
          ...(errorSpecific && {catch: {
            text: "See more details",
            value: "confirm",
          }}),
        },
        dangerMode: true,
      }).then((caseValue) => {
        switch (caseValue) {
          case "confirm":
            swal(
              "Error",
              enquiry_id
                ? `${`Trace ID:- ${enquiry_id}.\n Error Message:- ${errorSpecific}`}`
                : errorSpecific,
              "error"
            );
            break;
          default:
        }
      });
    }
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const [timerShow, setTimerShow] = useState(false);
  const handleOnIdle = () => {
    setTimerShow(true);
  };

  // eslint-disable-next-line no-unused-vars
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout:
      (theme_conf?.broker_config?.time_out * 1
        ? theme_conf?.broker_config?.time_out * 1
        : 15) *
      1000 *
      60,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <>
      <Container>
        <FormContainer
          width={location.pathname === `/${type}/vehicle-type` ? "780px" : ""}
        >
          {
            <Row>
              {process.env.REACT_APP_BROKER !== "ABIBL" && !lessthan767 ? (
                <Col className="landing-laxmi mx-auto" xl={3} lg={3} md={3}>
                  <div className="review-details3 text-center">
                    <Avatar
                      src={
                        TypeReturn(type) !== "bike"
                          ? `${
                              process.env.REACT_APP_BASENAME !== "NA"
                                ? `/${process.env.REACT_APP_BASENAME}`
                                : ""
                            }/assets/images/taxi-car1.png`
                          : `${
                              process.env.REACT_APP_BASENAME !== "NA"
                                ? `/${process.env.REACT_APP_BASENAME}`
                                : ""
                            }/assets/images/vehicle/bike3.png`
                      }
                      alt="avatarImage"
                    />
                  </div>
                </Col>
              ) : (
                <noscript />
              )}
            </Row>
          }
          {location.pathname === `/${type}/lead-page` && (
            <LeadPage
              type={type}
              lessthan767={lessthan767}
              TypeReturn={TypeReturn}
              isPartner={isPartner}
            />
          )}
          {location.pathname === `/${type}/journey-type` && (
            <JourneyType
              enquiry_id={enquiry_id}
              type={type}
              token={token}
              errorProp={error}
              typeId={typeId}
              TypeReturn={TypeReturn}
            />
          )}
          {location.pathname === `/${type}/registration` && (
            <Registration
              enquiry_id={enquiry_id}
              type={type}
              token={token}
              errorProp={error}
              lessthan767={lessthan767}
              lessthan400={lessthan400}
              lessthan330={lessthan330}
              typeId={typeId}
              isMobileIOS={isMobileIOS}
              journey_type={journey_type}
              TypeReturn={TypeReturn}
              isPartner={isPartner}
              reg_no_url={reg_no_url}
            />
          )}
          {location.pathname === `/${type}/renewal` && (
            <RenewalRegistration
              enquiry_id={enquiry_id}
              type={type}
              token={token}
              errorProp={error}
              lessthan767={lessthan767}
              lessthan400={lessthan400}
              lessthan330={lessthan330}
              typeId={typeId}
              isMobileIOS={isMobileIOS}
              journey_type={journey_type}
              TypeReturn={TypeReturn}
              isPartner={isPartner}
              policy_no_url={policy_no_url}
              reg_no_url={reg_no_url}
            />
          )}
          {location.pathname === `/${type}/vehicle-type` && (
            <VehicleType
              enquiry_id={enquiry_id}
              type={type}
              token={token}
              errorProp={error}
              typeId={typeId}
              lessthan767={lessthan767}
              isMobileIOS={isMobileIOS}
              journey_type={journey_type}
              TypeReturn={TypeReturn}
              isPartner={isPartner}
            />
          )}
          {location.pathname === `/${type}/vehicle-details` && (
            <CarDetails
              enquiry_id={enquiry_id}
              type={type}
              token={token}
              errorProp={error}
              typeId={typeId}
              stepperfill={stepperfill}
              isMobileIOS={isMobileIOS}
              journey_type={journey_type}
              savedStep={savedStep}
              TypeReturn={TypeReturn}
              isPartner={isPartner}
            />
          )}
        </FormContainer>
      </Container>
      <FloatButton />
      <TimeoutPopup
        enquiry_id={enquiry_id}
        show={timerShow}
        onClose={() => setTimerShow(false)}
      />
    </>
  );
};
