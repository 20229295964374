import React, { useEffect, useState } from "react";
import { FormGroupTag } from "../style";
import { Badge, Form, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMsg, MultiSelect } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash";
import {
  ValidationConfig,
  getValidationConfig,
  getIcList,
  clear,
} from "modules/Home/home.slice";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import swal from "sweetalert";

const ProposalValidation = () => {
  const [all, setAll] = useState([]);
  const [prefillData, setPrefillData] = useState([])
  const {
    validationConfig: validation,
    icList,
    error,
  } = useSelector((state) => state.home);
  const [data, setData] = useState();

  const yupValidate = yup.object({
    chasisNomax: yup
      .string()
      .required("Maximum Number is Required")
      .matches(/^[a-z0-9]+$/, "Must be only digits")
      //  .min(6, "Must be 6 digits")
      //  .max(6, "Must be 6 digits")
      .trim(),
    engineNomax: yup
      .string()
      .required("Maximum Number is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      // .min(6, "Must be 6 digits")
      // .max(6, "Must be 6 digits")
      .trim(),
    chasisNomin: yup
      .string()
      .required("Minimum Number is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      // .min(6, "Must be 6 digits")
      // .max(6, "Must be 6 digits")
      .trim(),
    engineNomin: yup
      .string()
      .required("Minimum Number is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      // .min(6, "Must be 6 digits")
      // .max(6, "Must be 6 digits")
      .trim(),
    eNumberSelectedIc: yup
      .string()
      .required("IC is Required"),
    journeytype: yup
      .string()
      .required("Journey Type is Required")
    // Pmin: yup
    //   .string()
    //   .required("Minimum Number is Required")
    //   .matches(/^[0-9]+$/, "Must be only digits")
    //   // .min(6, "Must be 6 digits")
    //   // .max(6, "Must be 6 digits")
    //   .trim(),
    // Pmax: yup
    //   .string()
    //   .required("Maximum Number is Required")
    //   .matches(/^[0-9]+$/, "Must be only digits")
    //   // .min(6, "Must be 6 digits")
    //   // .max(6, "Must be 6 digits")
    //   .trim(),
    // icmin: yup
    //   .string()
    //   .required("Minimum Number is Required")
    //   .matches(/^[0-9]+$/, "Must be only digits")
    //   // .min(6, "Must be 6 digits")
    //   // .max(6, "Must be 6 digits")
    //   .trim(),
    // icmax: yup
    //   .string()
    //   .required("Maximum Number is Required")
    //   .matches(/^[0-9]+$/, "Must be only digits")
    //   // .min(6, "Must be 6 digits")
    //   // .max(6, "Must be 6 digits")
    //   .trim(),
  });

  const { register, errors, watch, control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onBlur",
  });
  // console.log("validation", validation);
  console.log("errors", errors);

  const selectedIc = watch("eNumberSelectedIc")
  const selectedjourney=watch("journeytype")

  const checkItem = (identify) => {
    console.log(identify);
    let add = [...all, identify];
    setAll(add);
  };

  useEffect(() => {
    // setData(validation?.prefill ? validation?.prefill : {});
    !_.isEmpty(validation) ? setPrefillData([...validation])
      : setPrefillData([])
    console.log("icList", icList, validation?.prefill);
  }, [validation, selectedIc]);
  // const removeItem = (identify) => {
  //   let add = all.filter((x) => x !== identify);
  //   setAll(add);
  // };

  // useEffect(() => {
  //   console.log("all", all);
  // }, [all]);

  //  errors
  useEffect(() => {
    if (error) {
      swal("alert !", error, "error");
    }
    return () => {
      dispatch(clear());
    };
  }, [error]);

  const options = icList?.map((item) => {
    return {
      label: _.capitalize(item.replace(/_/gi, " ")),
      name: item,
      id: item,
      value: item,
    };
  });



  const journeytype = [
    {
      label: "New",
      name: "NEW",
      id: "New",
      value: "NEW"
    },
    {
      label: "Rollover",
      name: "Rollover",
      id: "Rollover",
      value: "Rollover"
    },
  ]


  const customQuotes = watch("customQuotes");
  const chasisQuotes = watch("chasisQuotes");
  const policyQuotes = watch("policyQuotes");
  console.log("customQuotes", customQuotes);

  // Engine Quotes list
  // useEffect(() => {
  //   if (customQuotes?.length > 0) {
  //     let quotesLabel = customQuotes.map(x => x.label)
  //     setQuotesLabel(quotesLabel)
  //   } else {
  //     setQuotesLabel([])
  //   }
  // }, [customQuotes])

  // // Chasis Quotes list
  // useEffect(() => {
  //   if (chasisQuotes?.length > 0) {
  //     let quotesLabel = chasisQuotes.map(x => x.label)
  //     setChasisQuotesLabel(quotesLabel)
  //   } else {
  //     setChasisQuotesLabel([])
  //   }
  // }, [chasisQuotes])

  // Policy Quotes list
  // useEffect(() => {
  //   if (policyQuotes?.length > 0) {
  //     let quotesLabel = policyQuotes.map(x => x.label)
  //     setPolicyQuotesLabel(quotesLabel)
  //   } else {
  //     setPolicyQuotesLabel([])
  //   }
  // }, [policyQuotes])
  // useEffect(()=>{
  //  reset(maxmin)
  // },[maxmin])

  const Emin = watch("Emin");
  const Emax = watch("Emax");
  const Cmin = watch("Cmin");
  const Cmax = watch("Cmax");
  const Pmin = watch("Pmin");
  const Pmax = watch("Pmax");
  // console.log(Emin, "Emini")

  const engineNomin = watch("engineNomin");
  const engineNomax = watch("engineNomax");
  const chasisNomin = watch("chasisNomin");
  const chasisNomax = watch("chasisNomin");

  console.log(new RegExp("[a-zA-z0-9]+$"), "regex");

  // console.log("resp", response);

  // console.log(engineNo, "engineNo")

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(error());
    // dispatch(clear());
  }, [watch("toggleThree")]);
  useEffect(() => {
    console.log("Run");
    dispatch(getValidationConfig());
    dispatch(getIcList());
  }, [selectedIc]);

  // prefilling form
  useEffect(() => {
    if (!_.isEmpty(data)) {
      reset(data);
    }
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(selectedIc) && !_.isEmpty(validation) && !_.isEmpty(selectedjourney)) {
      const daata = validation && validation?.find(item=>{
       return item?.IcName===selectedIc?.value && item?.journeytype===selectedjourney?.value
      })
      console.log(daata)
      if (!_.isEmpty(daata)) {
        setValue('chasisNomax', daata.data.chasisNomax)
        setValue('chasisNomin', daata.data.chasisNomin)
        setValue('engineNomax', daata.data.engineNomax)
        setValue('engineNomin', daata.data.engineNomin)
      }
      else {
        setValue('chasisNomax', "")
        setValue('chasisNomin', "")
        setValue('engineNomax', "")
        setValue('engineNomin', "")
      }

    }
  }, [selectedIc, validation, selectedjourney])
  console.log(prefillData)
  const uiFun = (title, name, lebel) => {
    return (
      <FilterMenuBoxCheckConatiner>
        <div className="filterMenuBoxCheck">
          <input
            type="checkbox"
            className="form-check-input"
            id={`${title} ${name}`}
            name={`${title}.${name}`}
            ref={register}
          />

          <label className="form-check-label" htmlFor={`${title} ${name}`}>
            {lebel}{" "}
          </label>

          <span style={{ marginLeft: "3px" }}></span>
        </div>
      </FilterMenuBoxCheckConatiner>
    );
  };
  console.log("eNumberSelectedIc", watch("eNumberSelectedIc"));

  const onSubmit = (data) => {
    console.log(data, "inner")
    // if(watch("eNumberSelectedIc")){
    //   const res = watch("eNumberSelectedIc")?.map((item)=> item.name)
    //  const abc =  engineNo?.res?.map((item)=>min === "" && max === "" && true)
    // }
    if (theError === true) {
      return swal("Error", "Please fill all the details", "error");
    } else {
      const index = prefillData.map(item => item?.IcName).indexOf(selectedIc?.name);
      if (index !== -1) {
        prefillData.splice(index, 1)
        setPrefillData(prefillData)
      }

      let VData = new Object()
      VData.IcName = selectedIc?.value
      VData.journeytype=selectedjourney?.value
      VData.data = {
        engineNomax: data?.engineNomax,
        engineNomin: data?.engineNomin,
        chasisNomax: data?.chasisNomax,
        chasisNomin: data?.chasisNomin
      }
      //   VData.icName=selectedIc?.name
      //   VData.data={
      //     engineNo:
      //     {
      //       engineNomax:data?.engineNomax,engineNomin:data?.engineNomin
      //     },
      //   chasisNo:{
      //     chasisNomax:data?.chasisNomax,chasisNomin:data?.chasisNomin
      //   }
      // }
      console.log(index, "index");
      let response = [...prefillData, VData]
      // ...newData
      // name: `[${data?.Name?.Alphabets ? "a-zA-Z" : ""}${
      //   data?.Name?.Numbers ? "0-9" : ""
      // }${data?.Name?.Special_Character ? `\s.'` : ""}]+$`,
      // companyName: `[${data?.Company?.Alphabets ? "a-zA-Z" : ""}${
      //   data?.Company?.Numbers ? "0-9" : ""
      // }${data?.Company?.Special_Character ? `\s.'` : ""}]+$`,
      // engineNumber: {
      //   default: {
      //     min: Emin,
      //     max: Emax,
      //   },
      //   ...engineNo,
      // },
      // chasisNumber: {
      //   default: {
      //     min: Cmin,
      //     max: Cmax,
      //   },
      //   ...chasisNo,
      // },
      // policyNumber: {
      //   default: {
      //     min: Pmin,
      //     max: Pmax,
      //   },
      //   ...policyNo,
      // },
      console.log(response)
      dispatch(ValidationConfig(response));
      swal("Success", "response submitted successfully", "success")
    }
  };

  let theError;

  const showingErrorForMin = (x) => {
    if (!_.isEmpty(x) && !x?.min) {
      console.log("errorRes1");
      theError = true;
      return <ErrorMsg fontSize={"12px"}>Minimum No. is required</ErrorMsg>;
    }
  };

  const showingErrorForMax = (x) => {
    if (!_.isEmpty(x)) {
      console.log("errorRes1");
      theError = true;
      return <ErrorMsg fontSize={"12px"}>Maximum No. is required</ErrorMsg>;
    }
  };

  const errorRes = (x) => {
    if (!_.isEmpty(x) && !x?.min && !_.isEmpty(x) && !x?.max) {
      console.log("errorRes");
      theError = false;
    }
  };

  console.log(validation)
  return (
    <div>
      <TopDiv>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-8 mx-auto">
              <h1 className="text-center">Proposal Validations</h1>
              <div>
                <p
                  style={{
                    width: "100%",
                    background: "green",
                    color: "#fff",
                    padding: "10px",
                    margin: "0",
                    boxShadow: "grey 1px -1px 5px",
                  }}
                >
                  Proposal Card
                </p>

                <div
                  style={{
                    width: "100%",
                    boxShadow: "1px 1px 5px grey",
                    padding: "0px 20px 20px",
                  }}
                >
                  {/* <div
                    style={{
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <FormGroupTag
                      style={{
                        paddingTop: "20px",
                        fontSize: "17px",
                        fontWeight: "bold",
                      }}
                    >
                      Name
                    </FormGroupTag>
                  </div> */}
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    {uiFun("Name", "Alphabets", "Alphabets")}
                    {uiFun("Name", "Numbers", "Numbers")}
                    {uiFun("Name", "Special_Character", "Special Character")}
                  </div> */}
                  {/* <div style={{ width: "100%", marginTop: "18px" }}>
                    <FormGroupTag
                      style={{ fontSize: "17px", fontWeight: "bold" }}
                    >
                      Company Name
                    </FormGroupTag>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    {uiFun("Company", "Alphabets", "Alphabets")}
                    {uiFun("Company", "Numbers", "Numbers")}
                    {uiFun("Company", "Special_Character", "Special Character")}
                  </div> */}

                  <Row style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <Col lg={6} md={6}>
                      <Controller
                        control={control}
                        name="eNumberSelectedIc"
                        render={({ onChange, value, name }) => (
                          <Select
                            name={name}
                            value={value}
                            options={options}
                            classNamePrefix="select"
                            closeMenuOnSelect={true}
                            ref={register}
                            onChange={onChange}
                          />
                        )}
                      />
                      {
                        errors && errors?.eNumberSelectedIc &&
                        <ErrorMsg fontSize={"12px"}>
                          {errors?.eNumberSelectedIc?.message}
                        </ErrorMsg>
                      }

                    </Col>
                    <Col lg={6} md={6}>
                      <Controller
                        control={control}
                        name="journeytype"
                        render={({ onChange, value, name }) => (
                          <Select
                            name={name}
                            value={value}
                            options={journeytype}
                            classNamePrefix="select"
                            closeMenuOnSelect={true}
                            ref={register}
                            onChange={onChange}
                          />
                        )}
                      />
                      {
                        errors && errors?.businessType &&
                        <ErrorMsg fontSize={"12px"}>
                          {errors?.businessType?.message}
                        </ErrorMsg>
                      }

                    </Col>
                  </Row>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "18px",
                    }}
                  >
                    <FormGroupTag
                      style={{
                        display: "flex",
                        fontSize: "17px",
                        fontWeight: "bold",
                      }}
                    >
                      Engine Number{" "}
                    </FormGroupTag>
                    {/* <StyledToggle>
                      <div className="toggleRadio m-0 )">
                        <label className="selectLabel ml-0">Default</label>
                        <label className="switch yes-switch">
                          <input
                            name={"toggleOne"}
                            type="checkbox"
                            className="min-y"
                            ref={register}
                          />
                          <span className="slider round"></span>
                        </label>
                        <label class="selectLabel">Ic specific</label>
                      </div>
                    </StyledToggle> */}
                  </div>

                  {/* <div style={{ fontSize: "13px" }}>Default</div> */}
                  {/* <div className="row">
                    <div className="col-4 d-flex">
                      <div className="py-2 w-100">
                        <Form.Control
                          name="Emin"
                          ref={register}
                          type="number"
                          placeholder="Enter Minimum Number"
                          errors={errors?.min}
                          size="sm"
                          // onKeyDown={numOnly}
                          maxLength="10"
                        />
                        {!!errors?.Emin && (
                          <ErrorMsg fontSize={"12px"}>
                            {errors?.Emin?.message}
                          </ErrorMsg>
                        )}
                      </div>
                      <span className="ml-2" style={{ marginTop: "11px" }}>
                        Min
                      </span>
                    </div>
                    <div className="col-4 d-flex">
                      <div className="py-2 w-100">
                        <Form.Control
                          name="Emax"
                          ref={register}
                          type="number"
                          placeholder="Enter Maximum Number"
                          errors={errors?.max}
                          size="sm"
                          // onKeyDown={numOnly}
                          // maxLength="10"
                        />
                        {!!errors?.Emax && (
                          <ErrorMsg fontSize={"12px"}>
                            {errors?.Emax?.message}
                          </ErrorMsg>
                        )}
                      </div>
                      <span className="ml-2" style={{ marginTop: "11px" }}>
                        Max
                      </span>
                    </div>
                  </div> */}

                  {/* {watch("eNumberSelectedIc") &&
                    watch("eNumberSelectedIc")?.map((x) => ( */}
                  <>
                    {/* <div style={{ fontSize: "13px" }}>{x?.label}</div> */}
                    <div className="row">
                      <div className="col-4 d-flex">
                        <div className="py-2 w-100">
                          <Form.Control
                            name={`engineNomin`}
                            ref={register}
                            type="number"
                            placeholder="Enter Minimum Number"
                            // errors={errors?.icmin}
                            size="sm"
                            // onKeyDown={numOnly}
                            maxLength="10"
                          />
                          {
                            errors && errors?.engineNomin &&
                            <ErrorMsg fontSize={"12px"}>
                              {errors?.engineNomin?.message}
                            </ErrorMsg>
                            // !engineNo?.[x.label]?.min
                            //   ? showingErrorForMin(engineNo?.[x.label])
                            //   : errorRes(engineNo?.[x.label])
                            //  &&
                            //  (
                            //   <ErrorMsg fontSize={"12px"}>
                            //     Minimum No. is required
                            //   </ErrorMsg>
                            // ) : (
                            //   Number(engineNo?.[x.label]?.min) <= 0 && (
                            //     <ErrorMsg fontSize={"12px"}>
                            //       Number should be greater than 0
                            //     </ErrorMsg>
                            //   )
                            // )
                          }
                        </div>
                        <span
                          className="ml-2"
                          style={{ marginTop: "11px" }}
                        >
                          Min
                        </span>
                      </div>
                      <div className="col-4 d-flex">
                        <div className="py-2 w-100">
                          <Form.Control
                            name={`engineNomax`}
                            ref={register}
                            type="number"
                            min={"1"}
                            placeholder="Enter Maximum Number"
                            // errors={errors?.icmax}
                            size="sm"
                          // onKeyDown={numOnly}
                          // maxLength="10"
                          />
                          {/* { showingError(engineNo?.[x.label]) } */}

                          {
                            errors && errors?.engineNomax &&
                            <ErrorMsg fontSize={"12px"}>
                              {errors?.engineNomax?.message}
                            </ErrorMsg>
                            // !engineNo?.[x.label]?.max
                            //   ? showingErrorForMax(engineNo?.[x.label])
                            //   : errorRes(engineNo?.[x.label])
                            // (
                            //   <ErrorMsg fontSize={"12px"}>
                            //     Maximum No. is required
                            //   </ErrorMsg>
                            // ) : (
                            //   Number(engineNo?.[x.label]?.max) <= 0 && (
                            //     <ErrorMsg fontSize={"12px"}>
                            //       Number should be greater than 0
                            //     </ErrorMsg>
                            //   )
                            // )
                          }
                        </div>
                        <span
                          className="ml-2"
                          style={{ marginTop: "11px" }}
                        >
                          Max
                        </span>
                      </div>
                    </div>
                  </>
                  {/* ))} */}

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "18px",
                    }}
                  >
                    <FormGroupTag
                      style={{ fontSize: "17px", fontWeight: "bold" }}
                    >
                      Chassis Number{" "}
                    </FormGroupTag>
                    {/* <StyledToggle>
                      <div className="toggleRadio m-0 )">
                        <label className="selectLabel ml-0">Default</label>
                        <label className="switch yes-switch">
                          <input
                            name={"toggleTwo"}
                            type="checkbox"
                            className="min-y"
                            ref={register}
                          />
                          <span className="slider round"></span>
                        </label>
                        <label class="selectLabel">Ic specific</label>
                      </div>
                    </StyledToggle> */}
                  </div>
                  {watch("toggleTwo") && (<></>
                    // <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                    //   <Col lg={8} md={8}>
                    //     <Controller
                    //       control={control}
                    //       name="cNumberSelectedIc"
                    //       render={({ onChange, value, name }) => (
                    //         <Select
                    //           closeMenuOnSelect={false}
                    //           isMulti
                    //           name={name}
                    //           value={value}
                    //           options={options}
                    //           className="basic-multi-select"
                    //           ref={register}
                    //           onChange={onChange}
                    //         />
                    //       )}
                    //     />
                    //   </Col>
                    // </Row>
                  )}
                  {/* <div style={{ fontSize: "13px" }}>Default</div>
                  <div className="row">
                    <div className="col-4 d-flex">
                      <div className="py-2 w-100">
                        <Form.Control
                          name="Cmin"
                          ref={register}
                          type="number"
                          placeholder="Enter Minimum Number"
                          errors={errors?.min}
                          size="sm"
                          // onKeyDown={numOnly}
                          maxLength="10"
                        />
                        {!!errors?.Cmin && (
                          <ErrorMsg fontSize={"12px"}>
                            {errors?.Cmin?.message}
                          </ErrorMsg>
                        )}
                      </div>
                      <span className="ml-2" style={{ marginTop: "11px" }}>
                        Min
                      </span>
                    </div>
                    <div className="col-4 d-flex">
                      <div className="py-2 w-100">
                        <Form.Control
                          name="Cmax"
                          ref={register}
                          type="number"
                          placeholder="Enter Maximum Number"
                          errors={errors?.max}
                          size="sm"
                          // onKeyDown={numOnly}
                          // maxLength="10"
                        />
                        {!!errors?.Cmax && (
                          <ErrorMsg fontSize={"12px"}>
                            {errors?.Cmax?.message}
                          </ErrorMsg>
                        )}
                      </div>
                      <span className="ml-2" style={{ marginTop: "11px" }}>
                        Max
                      </span>
                    </div>
                  </div> */}

                  {/* {watch("cNumberSelectedIc") &&
                    watch("cNumberSelectedIc").map((x) => ( */}
                  <>
                    {/* <div style={{ fontSize: "13px" }}>{x?.label}</div> */}
                    <div className="row">
                      <div className="col-4 d-flex">
                        <div className="py-2 w-100">
                          <Form.Control
                            name={`chasisNomin`}
                            ref={register}
                            type="number"
                            placeholder="Enter Minimum Number"
                            // errors={errors?.icmin}
                            size="sm"
                            // onKeyDown={numOnly}
                            maxLength="10"
                          />
                          {
                            errors && errors?.chasisNomin &&
                            <ErrorMsg fontSize={"12px"}>
                              {errors?.chasisNomin?.message}
                            </ErrorMsg>
                            // !chasisNo?.[x.label]?.min
                            //   ? showingErrorForMin(chasisNo?.[x.label])
                            //   : errorRes(engineNo?.[x.label])
                            // (
                            //   <ErrorMsg fontSize={"12px"}>
                            //     Minimum No. is required
                            //   </ErrorMsg>
                            // ) : (
                            //   Number(chasisNo?.[x.label]?.min) <= 0 && (
                            //     <ErrorMsg fontSize={"12px"}>
                            //       Number should be greater than 0
                            //     </ErrorMsg>
                            //   )
                            // )
                          }
                        </div>
                        <span
                          className="ml-2"
                          style={{ marginTop: "11px" }}
                        >
                          Min
                        </span>
                      </div>
                      <div className="col-4 d-flex">
                        <div className="py-2 w-100">
                          <Form.Control
                            name={`chasisNomax`}
                            ref={register}
                            type="number"
                            placeholder="Enter Maximum Number"
                            errors={errors?.chasisNomax}
                            size="sm"
                          // onKeyDown={numOnly}
                          // maxLength="10"
                          />
                          {

                          }
                          {
                            // !_.isEmpty(chasisNomax) && !chasisNomax
                            //   ? showingErrorForMax(chasisNomax)
                            //   : errorRes(engineNo?.[x.label])
                            // (
                            //   <ErrorMsg fontSize={"12px"}>
                            //     Maximum No. is required
                            //   </ErrorMsg>
                            // ) : (
                            //   !_.isEmpty(chasisNo) &&
                            //   Number(chasisNo[x.label]?.max) <= 0 && (
                            //     <ErrorMsg fontSize={"12px"}>
                            //       Number should be greater than 0
                            //     </ErrorMsg>
                            //   )
                            // )
                            errors && errors?.chasisNomax &&
                            <ErrorMsg fontSize={"12px"}>
                              {errors?.chasisNomax?.message}
                            </ErrorMsg>
                          }
                        </div>
                        <span
                          className="ml-2"
                          style={{ marginTop: "11px" }}
                        >
                          Max
                        </span>
                      </div>
                    </div>
                  </>
                  {/* ))} */}

                  {/* <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "18px",
                    }}
                  > */}
                  {/* <FormGroupTag
                      style={{
                        display: "flex",
                        fontSize: "17px",
                        fontWeight: "bold",
                      }}
                    >
                      Policy Number{" "}
                    </FormGroupTag> */}
                  {/* <StyledToggle>
                      <div className="toggleRadio m-0 )">
                        <label className="selectLabel ml-0">Default</label>
                        <label className="switch yes-switch">
                          <input
                            name={"toggleThree"}
                            type="checkbox"
                            className="min-y"
                            ref={register}
                          />
                          <span className="slider round"></span>
                        </label>
                        <label class="selectLabel">Ic specific</label>
                      </div>
                    </StyledToggle> */}
                  {/* </div> */}
                  {/* {watch("toggleThree") && (
                    <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <Col lg={8} md={8}>
                        <Controller
                          control={control}
                          name="pNumberSelectedIc"
                          render={({ onChange, value, name }) => (
                            <Select
                              closeMenuOnSelect={false}
                              isMulti
                              name={name}
                              value={value}
                              options={options}
                              className="basic-multi-select"
                              ref={register}
                              onChange={onChange}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  )} */}
                  {/* <div style={{ fontSize: "13px" }}>Default</div>
                  <div className="row">
                    <div className="col-4 d-flex">
                      <div className="py-2 w-100">
                        <Form.Control
                          name="Pmin"
                          ref={register}
                          type="number"
                          placeholder="Enter Minimum Number"
                          errors={errors?.min}
                          size="sm"
                          // onKeyDown={numOnly}
                          maxLength="10"
                        />
                        {!!errors?.Pmin && (
                          <ErrorMsg fontSize={"12px"}>
                            {errors?.Pmin?.message}
                          </ErrorMsg>
                        )}
                      </div>
                      <span className="ml-2" style={{ marginTop: "11px" }}>
                        Min
                      </span>
                    </div>
                    <div className="col-4 d-flex">
                      <div className="py-2 w-100">
                        <Form.Control
                          name="Pmax"
                          ref={register}
                          type="number"
                          placeholder="Enter Maximum Number"
                          errors={errors?.max}
                          size="sm"
                          // onKeyDown={numOnly}
                          // maxLength="10"
                        />
                        {!!errors?.Pmax && (
                          <ErrorMsg fontSize={"12px"}>
                            {errors?.Pmax?.message}
                          </ErrorMsg>
                        )}
                      </div>
                      <span className="ml-2" style={{ marginTop: "11px" }}>
                        Max
                      </span>
                    </div>
                  </div> */}

                  {watch("pNumberSelectedIc") &&
                    watch("pNumberSelectedIc").map((x) => (
                      <>
                        <div style={{ fontSize: "13px" }}>{x?.label}</div>
                        <div className="row">
                          <div className="col-4 d-flex">
                            <div className="py-2 w-100">
                              <Form.Control
                                name={`policyNo.${x.label}.min`}
                                ref={register}
                                type="number"
                                placeholder="Enter Minimum Number"
                                // errors={errors?.icmin}
                                size="sm"
                                // onKeyDown={numOnly}
                                maxLength="10"
                              />
                              {
                                // !_.isEmpty(policyNo) && !policyNo[x.label]?.min
                                //   ? showingErrorForMin(policyNo?.[x.label])
                                //   : errorRes(engineNo?.[x.label])
                                // (
                                //   <ErrorMsg fontSize={"12px"}>
                                //     Minimum No. is required
                                //   </ErrorMsg>
                                // ) : (
                                //   !_.isEmpty(policyNo) &&
                                //   Number(policyNo[x.label]?.min) <= 0 && (
                                //     <ErrorMsg fontSize={"12px"}>
                                //       Number should be greater than 0
                                //     </ErrorMsg>
                                //   )
                                // )
                              }
                            </div>
                            <span
                              className="ml-2"
                              style={{ marginTop: "11px" }}
                            >
                              Min
                            </span>
                          </div>
                          <div className="col-4 d-flex">
                            <div className="py-2 w-100">
                              <Form.Control
                                name={`policyNo.${x.label}.max`}
                                ref={register}
                                type="number"
                                placeholder="Enter Maximum Number"
                                onTouchEnd
                                // errors={errors?.icmax}
                                size="sm"
                              // onKeyDown={numOnly}
                              // maxLength="10"
                              />
                              {
                                // !_.isEmpty(policyNo) && !policyNo[x.label]?.max
                                //   ? showingErrorForMax(policyNo?.[x.label])
                                //   : errorRes(engineNo?.[x.label])
                                //  (
                                //   <ErrorMsg fontSize={"12px"}>
                                //     Maximum No. is required
                                //   </ErrorMsg>
                                // ) : (
                                //   !_.isEmpty(policyNo) &&
                                //   Number(policyNo[x.label]?.max) && (
                                //     <ErrorMsg fontSize={"12px"}>
                                //       Number should be greater than 0
                                //     </ErrorMsg>
                                //   )
                                // )
                              }
                            </div>
                            <span
                              className="ml-2"
                              style={{ marginTop: "11px" }}
                            >
                              Max
                            </span>
                          </div>
                        </div>
                      </>
                    ))}
                  {/* <div style={{ display: "flex", justifyContent: "space-between", fontSize: "12px", marginTop: "5px" }}>
                      <div style={{ display: "flex", cursor: "pointer" }} onClick={all.includes("E-alphabets") ? () => removeItem("E-alphabets") : () => checkItem("E-alphabets")}>
                        { all.includes("E-alphabets") ?  <i
                              style={{
                                background: "green",
                                color: "#fff",
                                // marginTop: "15px",
                                width: "15px",
                                height: "15px",
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: "pointer",
                                marginTop: "1px",
                                marginRight: "5px"
                              }}
                              // onClick={() => removeItem("alphabets")}
                              className="fa fa-check"
                            ></i> :  
                        <div style={{ width: "15px", height: "15px", border: "1px solid black", marginTop: "1px", marginRight: "5px" }}></div>
                            }
                        <div>Alphabets</div>
                      </div>
                      <div style={{ display: "flex", cursor: "pointer" }} onClick={all.includes("E-numbers") ? () => removeItem("E-numbers") : () => checkItem("E-numbers")}>
                      { all.includes("E-numbers") ?  <i
                              style={{
                                background: "green",
                                color: "#fff",
                                // marginTop: "15px",
                                width: "15px",
                                height: "15px",
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: "pointer",
                                marginTop: "1px",
                                marginRight: "5px"
                              }}
                              // onClick={() => removeItem("alphabets")}
                              className="fa fa-check"
                            ></i> :  
                        <div style={{ width: "15px", height: "15px", border: "1px solid black", marginTop: "1px", marginRight: "5px" }}></div>
                            }
                        <div>Numbers</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", cursor: "pointer" }} onClick={all.includes("E-special") ? () => removeItem("E-special") : () => checkItem("E-special")}>
                      { all.includes("E-special") ?  <i
                              style={{
                                background: "green",
                                color: "#fff",
                                // marginTop: "15px",
                                width: "15px",
                                height: "15px",
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: "pointer",
                                marginTop: "1px",
                                marginRight: "5px"
                              }}
                              // onClick={() => removeItem("alphabets")}
                              className="fa fa-check"
                            ></i> :  
                        <div style={{ width: "15px", height: "15px", border: "1px solid black", marginTop: "1px", marginRight: "5px" }}></div>
                            }
                        <div>Special Character</div>
                      </div>
                      </div>
                    </div> */}
                  {/* <div style={{ width: "100%", marginTop: "18px" }}>
                    <FormGroupTag style={{ fontSize: "17px", fontWeight: "bold" }}>
                    Chasis Number
                    </FormGroupTag>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: "12px", marginTop: "5px" }}>
                      <div style={{ display: "flex", cursor: "pointer" }} onClick={all.includes("C-alphabets") ? () => removeItem("C-alphabets") : () => checkItem("C-alphabets")}>
                        { all.includes("C-alphabets") ?  <i
                              style={{
                                background: "green",
                                color: "#fff",
                                // marginTop: "15px",
                                width: "15px",
                                height: "15px",
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: "pointer",
                                marginTop: "1px",
                                marginRight: "5px"
                              }}
                              // onClick={() => removeItem("alphabets")}
                              className="fa fa-check"
                            ></i> :  
                        <div style={{ width: "15px", height: "15px", border: "1px solid black", marginTop: "1px", marginRight: "5px" }}></div>
                            }
                        <div>Alphabets</div>
                      </div>
                      <div style={{ display: "flex", cursor: "pointer" }} onClick={all.includes("C-numbers") ? () => removeItem("C-numbers") : () => checkItem("C-numbers")}>
                      { all.includes("C-numbers") ?  <i
                              style={{
                                background: "green",
                                color: "#fff",
                                // marginTop: "15px",
                                width: "15px",
                                height: "15px",
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: "pointer",
                                marginTop: "1px",
                                marginRight: "5px"
                              }}
                              // onClick={() => removeItem("alphabets")}
                              className="fa fa-check"
                            ></i> :  
                        <div style={{ width: "15px", height: "15px", border: "1px solid black", marginTop: "1px", marginRight: "5px" }}></div>
                            }
                        <div>Numbers</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", cursor: "pointer" }} onClick={all.includes("C-special") ? () => removeItem("C-special") : () => checkItem("C-special")}>
                      { all.includes("C-special") ?  <i
                              style={{
                                background: "green",
                                color: "#fff",
                                // marginTop: "15px",
                                width: "15px",
                                height: "15px",
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: "pointer",
                                marginTop: "1px",
                                marginRight: "5px"
                              }}
                              // onClick={() => removeItem("alphabets")}
                              className="fa fa-check"
                            ></i> :  
                        <div style={{ width: "15px", height: "15px", border: "1px solid black", marginTop: "1px", marginRight: "5px" }}></div>
                            }
                        <div>Special Character</div>
                      </div>
                      </div>
                    </div>
                    <div style={{ width: "100%", marginTop: "18px" }}>
                    <FormGroupTag style={{ fontSize: "17px", fontWeight: "bold" }}>
                    Policy Number
                    </FormGroupTag>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: "12px", marginTop: "5px" }}>
                      <div style={{ display: "flex", cursor: "pointer" }} onClick={all.includes("P-alphabets") ? () => removeItem("P-alphabets") : () => checkItem("P-alphabets")}>
                        { all.includes("P-alphabets") ?  <i
                              style={{
                                background: "green",
                                color: "#fff",
                                // marginTop: "15px",
                                width: "15px",
                                height: "15px",
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: "pointer",
                                marginTop: "1px",
                                marginRight: "5px"
                              }}
                              // onClick={() => removeItem("alphabets")}
                              className="fa fa-check"
                            ></i> :  
                        <div style={{ width: "15px", height: "15px", border: "1px solid black", marginTop: "1px", marginRight: "5px" }}></div>
                            }
                        <div>Alphabets</div>
                      </div>
                      <div style={{ display: "flex", cursor: "pointer" }} onClick={all.includes("P-numbers") ? () => removeItem("P-numbers") : () => checkItem("P-numbers")}>
                      { all.includes("P-numbers") ?  <i
                              style={{
                                background: "green",
                                color: "#fff",
                                // marginTop: "15px",
                                width: "15px",
                                height: "15px",
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: "pointer",
                                marginTop: "1px",
                                marginRight: "5px"
                              }}
                              // onClick={() => removeItem("alphabets")}
                              className="fa fa-check"
                            ></i> :  
                        <div style={{ width: "15px", height: "15px", border: "1px solid black", marginTop: "1px", marginRight: "5px" }}></div>
                            }
                        <div>Numbers</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", cursor: "pointer" }} onClick={all.includes("P-special") ? () => removeItem("P-special") : () => checkItem("P-special")}>
                      { all.includes("P-special") ?  <i
                              style={{
                                background: "green",
                                color: "#fff",
                                // marginTop: "15px",
                                width: "15px",
                                height: "15px",
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: "pointer",
                                marginTop: "1px",
                                marginRight: "5px"
                              }}
                              // onClick={() => removeItem("alphabets")}
                              className="fa fa-check"
                            ></i> :  
                        <div style={{ width: "15px", height: "15px", border: "1px solid black", marginTop: "1px", marginRight: "5px" }}></div>
                            }
                        <div>Special Character</div>
                      </div>
                      </div>
                    </div> */}
                </div>
              </div>
              <div
                className="text-center py-3 "
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <button
                  type="submit"
                  className="submitBtn"
                  onClick={() => {
                    console.log("clicked", errors);
                  }}
                >
                  Apply Validations
                </button>
                <button
                  className="submitBtn"
                  onClick={() => dispatch(ValidationConfig([]))}
                  type="reset"
                >
                  reset
                </button>
              </div>
            </div>
          </div>
        </Form>
      </TopDiv>
    </div>
  );
};

const TopDiv = styled.div`
  .submitBtn {
    padding: 8px 30px;
    border: none;
    background: green;
    color: #fff;
    border-radius: 20px;
  }
`;

const FilterMenuBoxCheckConatiner = styled.div`
  .filterMenuBoxCheck input[type="checkbox"]:checked + label:before {
    background-color: ${({ theme }) => theme.CheckBox?.color || "#bdd400"};
    border: ${({ theme }) => theme.CheckBox?.border || "1px solid #bdd400"};
    box-shadow: ${({ theme }) =>
    theme.QuoteBorderAndFont?.shadowCheck || "none"};
    filter: ${({ theme }) =>
    theme.QuoteBorderAndFont?.filterPropertyCheckBox || "none"};
  }
`;

const StyledToggle = styled.div`
  .switch input:checked + .slider {
    background-color: ${({ theme }) =>
    theme.questionsProposal?.toggleBackgroundColor || "#006600"};
  }
  .toggleRadio {
    padding: 0px 20px 0px;
    margin: auto;
    /* margin-left: 10px; */
  }
  .toggleRadio label {
    font-size: 10px;
    margin: auto 4px;
  }
  .slider::before {
    left: 3px;
  }
`;

export default ProposalValidation;
