import React, { useEffect, useState, Fragment } from "react";
import { Button, ErrorMsg } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Row, Col, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { FormGroupTag } from "../style.js";
import DateInput from "../DateInput";
import _ from "lodash";
import { numOnly, toDateDayOld, toDate } from "utils";
import { useSelector, useDispatch } from "react-redux";
import { PrevIc } from "../proposal.slice";
import { addYears, subDays, subYears, differenceInDays } from "date-fns";
import moment from "moment";
import { SaveAddon } from "modules/proposal/proposal.slice";
import styled from "styled-components";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme1 = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const PolicyCard = ({
  onSubmitPrepolicy,
  prepolicy,
  CardData,
  prevPolicyCon,
  PACon,
  enquiry_id,
  Theme,
  OwnDamage,
  type,
  lessthan768,
  isNcbApplicable,
  TypeReturn,
  fields,
  PolicyValidationExculsion,
}) => {
  const [reasonCpa, setReasonCpa] = useState("");
  /*----------------Validation Schema---------------------*/
  const { temp_data, prevIc } = useSelector((state) => state.proposal);

  const yupValidate = yup.object(
    temp_data?.selectedQuote?.isRenewal === "Y"
      ? {}
      : {
          previousNcb: yup
            .string()
            .nullable()
            .transform((v, o) => (o === "" ? null : v))
            .matches(/^[^@]+$/, "Previous NCB is required"),
          ...(prevPolicyCon &&
            !PolicyValidationExculsion && {
              previousInsuranceCompany: yup
                .string()
                .required("Previous insurance company is required")
                .matches(/^[^@]+$/, "Previous insurance company is required"),
              prevPolicyExpiryDate: yup
                .string()
                .required("Expiry date is required"),
              previousPolicyNumber: yup
                .string()
                .required("Policy number is required")
                .min(5, "Minimum 5 chars are required")
                .max(
                  temp_data?.selectedQuote?.companyAlias === "sbi" ? 40 : 40,
                  temp_data?.selectedQuote?.companyAlias === "sbi"
                    ? "Maximum 40 chars allowed"
                    : "Maximum 40 chars allowed "
                ),
            }),
          ...((temp_data?.prevShortTerm &&
            prevPolicyCon && {
              previousPolicyStartDate: yup
                .string()
                .required("Start date is required"),
            }) ||
            {}),
          ...(prevPolicyCon &&
            OwnDamage && {
              tpInsuranceCompany: yup
                .string()
                .required("TP insurance company is required")
                .matches(/^[^@]+$/, "TP insurance company is required"),
              tpStartDate: yup.string().required("TP start date is required"),
              tpEndDate: yup.string().required("TP end date is required"),
              tpInsuranceNumber: yup
                .string()
                .required("TP policy number is required")
                .min(5, "Minimum 5 chars are required")
                .max(40, "Maximum 40 chars allowed "),
            }),
          ...(PACon &&
            reasonCpa !== "I do not have a valid driving license." && {
              cPAInsComp: yup
                .string()
                .required("Insurance company is required")
                .matches(/^[^@]+$/, "Insurance company is required"),
              cPAPolicyFmDt: yup.string().required("Date is required"),
              cPAPolicyToDt: yup.string().required("Date is required"),
              cPAPolicyNo: yup.string().required("Policy number is required"),
              cPASumInsured: yup
                .string()
                .required("Sum Insured is required")
                .trim()
                .test(
                  "cPASumInsured",
                  "Sum insured should be above 1500000",
                  (value) => {
                    return value >= 1500000;
                  }
                ),
            }),
        }
  );
  /*----------x------Validation Schema----------x-----------*/
  const dispatch = useDispatch();
  const expiryDate = temp_data?.corporateVehiclesQuoteRequest
    ?.previousPolicyExpiryDate
    ? temp_data?.corporateVehiclesQuoteRequest?.previousPolicyExpiryDate
    : "";
  const startDate = temp_data?.userProposal?.prevPolicyStartDate
    ? temp_data?.userProposal?.prevPolicyStartDate
    : "";

  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: !_.isEmpty(prepolicy)
      ? prepolicy
      : !_.isEmpty(CardData?.prepolicy)
      ? CardData?.prepolicy.prevPolicyExpiryDate === expiryDate && expiryDate
        ? CardData?.prepolicy
        : { ...CardData?.prepolicy, prevPolicyExpiryDate: expiryDate }
      : {},
    resolver: yupResolver(yupValidate),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const allFieldsReadOnly =
    temp_data?.selectedQuote?.isRenewal === "Y" ||
    temp_data?.corporateVehiclesQuoteRequest?.rolloverRenewal === "Y";

  //Setting Policy Expiry Date
  useEffect(() => {
    if (expiryDate) {
      setValue("prevPolicyExpiryDate", expiryDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryDate]);

  //prefill Api
  useEffect(() => {
    if (_.isEmpty(prepolicy) && !_.isEmpty(CardData?.prepolicy)) {
      reset(
        CardData?.prepolicy.prevPolicyExpiryDate === expiryDate && expiryDate
          ? CardData?.prepolicy
          : { ...CardData?.prepolicy, prevPolicyExpiryDate: expiryDate }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CardData.prepolicy, expiryDate]);

  //fixed values
  const companyAlias = !_.isEmpty(temp_data?.selectedQuote)
    ? temp_data?.selectedQuote?.companyAlias
    : "";

  //Load Previous IC List
  useEffect(() => {
    if (companyAlias)
      dispatch(
        PrevIc({
          companyAlias: companyAlias,
          enquiryId: enquiry_id,
          ...(temp_data?.selectedQuote?.policyType === "Third Party" && {
            tp: "Y",
          }),
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAlias]);

  const Previnsurer = !_.isEmpty(prevIc)
    ? prevIc?.map(({ name, code }) => {
        return { name, code };
      })
    : [];

  //filter name of prev IC
  let previousInsuranceCompany = watch("previousInsuranceCompany")
    ? watch("previousInsuranceCompany")
    : prepolicy?.previousInsuranceCompany
    ? prepolicy?.previousInsuranceCompany
    : CardData?.prepolicy?.previousInsuranceCompany
    ? CardData?.prepolicy?.previousInsuranceCompany
    : !_.isEmpty(Previnsurer) &&
      temp_data?.corporateVehiclesQuoteRequest?.previousInsurer &&
      (temp_data?.corporateVehiclesQuoteRequest?.previousInsurer !== "XYZ" ||
        "")
    ? Previnsurer?.filter(
        ({ code, name }) =>
          temp_data?.corporateVehiclesQuoteRequest?.previousInsurer === name
      )[0]?.code
    : !_.isEmpty(Previnsurer) && temp_data?.userProposal?.previousInsurer
    ? Previnsurer?.filter(
        ({ code, name }) => temp_data?.userProposal?.previousInsurer === name
      )[0]?.code || ""
    : null;

  const IcName = Previnsurer?.filter(
    ({ code }) => previousInsuranceCompany === code
  );
  console.log("expiryDate", expiryDate, IcName, previousInsuranceCompany);
  //calculating bundled policy year
  const regYear =
    temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate &&
    !_.isEmpty(
      temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate.split("-")
    ) &&
    temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate.split("-")
      .length > 2 &&
    temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate.split("-")[2];

  const ODlastYr =
    differenceInDays(
      toDate(moment().format("DD-MM-YYYY")),
      toDate(temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate) <=
        365
    ) || "";

  useEffect(() => {
    if (!_.isEmpty(IcName)) {
      setValue("InsuranceCompanyName", IcName[0]?.name);
      TypeReturn(type) !== "cv" &&
        ODlastYr &&
        watch("disabled_id") !== IcName[0]?.name &&
        setValue("disabled_id", IcName[0]?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IcName]);

  /*-----CPA Details-----*/
  //trigger addon save
  const ReasonIP = watch("reason");
  useEffect(() => {
    if (ReasonIP && PACon) {
      setReasonCpa(ReasonIP);
      dispatch(
        SaveAddon({
          enquiryId: enquiry_id,
          addonData: {
            compulsory_personal_accident: [{ reason: ReasonIP }],
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ReasonIP, PACon]);

  const CpaInsuranceCompany =
    watch("cPAInsComp") ||
    prepolicy?.cPAInsComp ||
    CardData?.prepolicy?.cPAInsComp;

  const CpaIcName = Previnsurer?.filter(
    ({ code }) => CpaInsuranceCompany === code
  );

  useEffect(() => {
    if (!_.isEmpty(CpaIcName))
      setValue("CpaInsuranceCompany", CpaIcName[0]?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CpaIcName]);

  //Names for Summary
  // const CpaIns = watch('cPAInsComp');
  const cpaPolicyNo = watch("cPAPolicyNo");
  const CpaFmDate = watch("cPAPolicyFmDt");
  const CpaToDate = watch("cPAPolicyToDt");
  const CpaSumIns = watch("cPASumInsured");
  //resetting cpa details
  useEffect(() => {
    if (
      (!_.isEmpty(prepolicy) || !_.isEmpty(CardData?.prepolicy)) &&
      reasonCpa &&
      reasonCpa === "I do not have a valid driving license."
    ) {
      setValue("cPAPolicyNo", "");
      setValue("cPAPolicyFmDt", "");
      setValue("cPAPolicyToDt", "");
      setValue("cPASumInsured", "");
      setValue("cpaPolicyNumber", "");
      setValue("cpaPolicyStartDate", "");
      setValue("cpaPolicyEndDate", "");
      setValue("cpaSumInsured", "");
    }
  }, [reasonCpa]);

  /*--x--CPA Details--x--*/

  /*-----TP Details-----*/

  const TpProposalPrefill =
    watch("tpInsuranceCompany") || ""
      ? watch("tpInsuranceCompany")
      : prepolicy?.tpInsuranceCompany
      ? prepolicy?.tpInsuranceCompany
      : CardData?.prepolicy?.tpInsuranceCompany
      ? CardData?.prepolicy?.tpInsuranceCompany
      : !_.isEmpty(Previnsurer) && temp_data?.userProposal?.tpInsuranceCompany
      ? Previnsurer?.filter(
          ({ code, name }) =>
            temp_data?.userProposal?.tpInsuranceCompany === name
        )[0]?.code
      : !_.isEmpty(Previnsurer) &&
        temp_data?.corporateVehiclesQuoteRequest?.previousInsurer
      ? Previnsurer?.filter(
          ({ code, name }) =>
            temp_data?.corporateVehiclesQuoteRequest?.previousInsurer === name
        )[0]?.code
      : null || "";

  const TpInsuranceCompany =
    TpProposalPrefill || "" ? TpProposalPrefill || "" : null;

  const TpIcName = Previnsurer?.filter(
    ({ code }) => TpInsuranceCompany === code
  );
  console.log("tpicname", TpIcName, TpInsuranceCompany, TpProposalPrefill);
  useEffect(() => {
    if (!_.isEmpty(TpIcName))
      setValue("tpInsuranceCompanyName", TpIcName[0]?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TpIcName]);

  //TP Policy End Date Calculation  i.e  3yr - 1d for Bike & 5yr - 1d for car
  const TPStartDate = watch("tpStartDate");
  useEffect(() => {
    if (TPStartDate) {
      const TpEndDate = TPStartDate
        ? moment(TPStartDate, "DD-MM-YYYY")
            .add(TypeReturn(type) === "bike" ? 5 : 3, "years")
            .subtract(1, "days")
        : false;
      setValue("tpEndDate", moment(TpEndDate).format("DD-MM-YYYY"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TPStartDate]);

  /*--x--TP Details--x--*/

  //tp start date prefill
  useEffect(() => {
    if (
      !_.isEmpty(temp_data) &&
      _.isEmpty(CardData?.prepolicy) &&
      !TPStartDate
    ) {
      setValue(
        "tpStartDate",
        temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate
      );
    }
  }, [temp_data]);

  //fastLane prefill
  useEffect(() => {
    if (_.isEmpty(CardData?.prepolicy)) {
      temp_data?.userProposal?.previousInsuranceCompany &&
        setValue(
          "previousInsuranceCompany",
          temp_data?.userProposal?.previousInsuranceCompany
        );
      temp_data?.userProposal?.previousPolicyNumber &&
        setValue(
          "previousPolicyNumber",
          temp_data?.userProposal?.previousPolicyNumber
        );
      temp_data?.userProposal?.tpInsuranceNumber &&
        setValue(
          "tpInsuranceNumber",
          temp_data?.userProposal?.tpInsuranceNumber
        );
      temp_data?.userProposal?.tpInsuranceCompany &&
        setValue(
          "tpInsuranceCompany",
          temp_data?.userProposal?.tpInsuranceCompany
        );
      temp_data?.userProposal?.tpInsuranceCompanyName &&
        setValue(
          "tpInsuranceCompanyName",
          temp_data?.userProposal?.tpInsuranceCompanyName
        );
      temp_data?.userProposal?.tpStartDate &&
        setValue("tpStartDate", temp_data?.userProposal?.tpStartDate);
    }
  }, [CardData?.prepolicy]);

  //variables for hidden i/p ( summary header changes)
  const previousPolicyExpiry = watch("prevPolicyExpiryDate");

  //previous policy number condition for first renewal
  const previousPolicyNumber = watch("previousPolicyNumber");

  useEffect(() => {
    if (
      differenceInDays(
        toDate(moment().format("DD-MM-YYYY")),
        toDate(temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate) <=
          365
      ) &&
      !PolicyValidationExculsion &&
      !temp_data?.userProposal?.tpInsuranceNumber
    ) {
      setValue("tpInsuranceNumber", previousPolicyNumber);
    }
  }, [previousPolicyNumber]);

  console.log(temp_data, "temp data");

  useEffect(() => {
    temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y" &&
      startDate &&
      setValue("previousPolicyStartDate", startDate);
  }, [startDate]);

  //TP policy Number Read Only Condition
  const TpReadOnly =
    (ODlastYr && !PolicyValidationExculsion) || allFieldsReadOnly;

  const getNewNcb = (ncb) => {
    switch (ncb * 1) {
      case 0:
        return 20;
      case 20:
        return 25;
      case 25:
        return 35;
      case 35:
        return 45;
      case 45:
        return 50;
      case 50:
        return 50;
      default:
        return 20;
    }
  };

  //embeded link ncb deselection
  const previousNcbIP = watch("previousNcb");
  const isNCBPresent = document.getElementById("ncbPreOpt") ? true : false;
  // useEffect(() => {
  //   if (
  //     temp_data?.corporateVehiclesQuoteRequest?.journeyType ===
  //       "embeded-excel" &&
  //     !CardData?.prepolicy?.previousNcb &&
  //     !prepolicy?.previousNcb
  //   ) {
  //     setTimeout(() => setValue("previousNcb", "@"), 50);
  //   }
  // }, [isNCBPresent]);

  const ncbOptionsJsx = (true && (
    <Fragment id="ncbPreOpt">
      {!CardData?.prepolicy?.previousNcb && !prepolicy?.previousNcb && (
        <option value={"@"}>Select</option>
      )}
      <option
        selected={
          (temp_data?.corporateVehiclesQuoteRequest?.previousNcb * 1 === 0 &&
            !CardData?.prepolicy?.previousNcb * 1 !== 0) ||
          CardData?.prepolicy?.previousNcb === 0
        }
        value={"0"}
      >
        0%
      </option>
      <option
        selected={
          (temp_data?.corporateVehiclesQuoteRequest?.previousNcb * 1 === 20 &&
            !CardData?.prepolicy?.previousNcb * 1 !== 20) ||
          CardData?.prepolicy?.previousNcb * 1 === 20
        }
        value={"20"}
      >
        20%
      </option>
      <option
        selected={
          (temp_data?.corporateVehiclesQuoteRequest?.previousNcb * 1 === 25 &&
            !CardData?.prepolicy?.previousNcb * 1 !== 25) ||
          CardData?.prepolicy?.previousNcb * 1 === 25
        }
        value={"25"}
      >
        25%
      </option>
      <option
        selected={
          (temp_data?.corporateVehiclesQuoteRequest?.previousNcb * 1 === 35 &&
            !CardData?.prepolicy?.previousNcb * 1 !== 35) ||
          CardData?.prepolicy?.previousNcb * 1 === 35
        }
        value={"35"}
      >
        35%
      </option>
      <option
        selected={
          (temp_data?.corporateVehiclesQuoteRequest?.previousNcb * 1 === 45 &&
            CardData?.prepolicy?.previousNcb * 1 !== 45) ||
          CardData?.prepolicy?.previousNcb * 1 === 45
        }
        value={"45"}
      >
        45%
      </option>
      <option
        selected={
          (temp_data?.corporateVehiclesQuoteRequest?.previousNcb * 1 === 50 &&
            !CardData?.prepolicy?.previousNcb * 1 !== 50) ||
          CardData?.prepolicy?.previousNcb * 1 === 50
        }
        value={"50"}
      >
        50%
      </option>
    </Fragment>
  )) || <></>;

  //Title Fn
  const SubTitleFn = (subtitle) => (
    <Col
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      className=" mt-1"
      style={{ marginBottom: "-10px" }}
    >
      <p
        style={{
          color: Theme?.proposalHeader?.color
            ? Theme?.proposalHeader?.color
            : "#1a5105",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {subtitle}
      </p>
    </Col>
  );

  //disable submit button
  const generalCon =
    prevPolicyCon && _.isEmpty(IcName) && !PolicyValidationExculsion;
  const SubmitCon =
    ((prevPolicyCon || OwnDamage) &&
      (_.isEmpty(Previnsurer) ||
        generalCon ||
        (OwnDamage && _.isEmpty(TpIcName)))) ||
    "";
  console.log("disabledProceed", generalCon, _.isEmpty(TpIcName), OwnDamage);
  //additional cpa-opt-out reason
  const additionalReason = ((temp_data?.selectedQuote?.companyAlias ===
    "royal_sundaram" ||
    "") && (
    <option
      selected={
        CardData?.prepolicy?.reason &&
        CardData?.prepolicy?.reason ===
          `I have another motor policy with PA owner driver cover in my name`
      }
      value={`I have another motor policy with PA owner driver cover in my name`}
    >{`I have another motor policy with PA owner driver cover in my name`}</option>
  )) || <noscript />;

  const prevIcUnavailablity =
    !CardData?.prepolicy?.previousInsuranceCompany &&
    !temp_data?.userProposal?.previousInsurer &&
    (!temp_data?.corporateVehiclesQuoteRequest?.previousInsurer ||
      temp_data?.corporateVehiclesQuoteRequest?.previousInsurer === "NEW" ||
      "");

  const PrevPolicyStDt = (
    <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
      <StyledDatePicker>
        <div className="py-2 dateTimeOne">
          <FormGroupTag>Previous policy start date</FormGroupTag>
          <Controller
            control={control}
            name={`previousPolicyStartDate`}
            render={({ onChange, onBlur, value, name }) => (
              <DateInput
                readOnly={allFieldsReadOnly}
                minDate={
                  previousPolicyExpiry
                    ? moment(
                        moment(previousPolicyExpiry, "DD-MM-YYYY").subtract(
                          1,
                          "years"
                        )
                      )?._d
                    : false
                }
                value={value}
                name={name}
                onChange={onChange}
                ref={register}
              />
            )}
          />
          {!!errors?.previousPolicyStartDate && (
            <ErrorMsg fontSize={"12px"}>
              {errors?.previousPolicyStartDate?.message}
            </ErrorMsg>
          )}
          <input
            name="previousPolicyExpiryDate"
            type="hidden"
            ref={register}
            value={previousPolicyExpiry}
          />
        </div>
      </StyledDatePicker>
    </Col>
  );

  const prevPolicyStDtCon = temp_data?.prevShortTerm ? (
    PrevPolicyStDt
  ) : (
    <noscript />
  );

  const TP_PREV_CON =
    differenceInDays(
      toDate(moment().format("DD-MM-YYYY")),
      toDate(temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate) <=
        365
    ) &&
    temp_data?.corporateVehiclesQuoteRequest?.previousPolicyType !== "Not sure";

  return (
    <Form onSubmit={handleSubmit(onSubmitPrepolicy)} autoComplete="off">
      <Row
        style={{
          margin: lessthan768
            ? "-60px -30px 20px -30px"
            : "-60px -20px 20px -30px",
        }}
        className="p-2"
      >
        {prevPolicyCon && (!PolicyValidationExculsion || "") ? (
          <>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2 fname">
                <FormGroupTag>Previous Insurance Company</FormGroupTag>
                <Form.Control
                  autoComplete="off"
                  readOnly={allFieldsReadOnly}
                  as="select"
                  size="sm"
                  ref={register}
                  placeholder="Insurance Company"
                  name={`previousInsuranceCompany`}
                  style={{ cursor: "pointer" }}
                  errors={errors?.previousInsuranceCompany}
                  isInvalid={errors?.previousInsuranceCompany}
                >
                  {(prevIcUnavailablity || "") && (
                    <option selected={true} value={"@"}>
                      Select
                    </option>
                  )}
                  {Previnsurer.map(({ name, code }, index) => (
                    <option
                      selected={
                        CardData?.prepolicy?.previousInsuranceCompany
                          ? CardData?.prepolicy?.previousInsuranceCompany ===
                            code
                          : temp_data?.corporateVehiclesQuoteRequest
                              ?.previousInsurer &&
                            temp_data?.corporateVehiclesQuoteRequest
                              ?.previousInsurer !== "XYZ"
                          ? temp_data?.corporateVehiclesQuoteRequest
                              ?.previousInsurer === name
                          : temp_data?.userProposal?.previousInsurer === name
                      }
                      value={code}
                    >
                      {name}
                    </option>
                  ))}
                </Form.Control>
                {!!errors?.previousInsuranceCompany && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.previousInsuranceCompany?.message}
                  </ErrorMsg>
                )}
              </div>
              <input
                type="hidden"
                name={"InsuranceCompanyName"}
                ref={register}
              />
            </Col>
            {prevPolicyStDtCon}
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <StyledDatePicker disabled={true}>
                <div className="py-2 dateTimeOne">
                  <FormGroupTag>Date of expiry</FormGroupTag>
                  <Controller
                    control={control}
                    name={`prevPolicyExpiryDate`}
                    render={({ onChange, onBlur, value, name }) => (
                      <DateInput
                        value={value}
                        name={name}
                        onChange={onChange}
                        ref={register}
                        readOnly
                      />
                    )}
                  />
                  {!!errors?.prevPolicyExpiryDate && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.prevPolicyExpiryDate?.message}
                    </ErrorMsg>
                  )}
                  <input
                    name="previousPolicyExpiryDate"
                    type="hidden"
                    ref={register}
                    value={previousPolicyExpiry}
                  />
                </div>
              </StyledDatePicker>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>Policy Number</FormGroupTag>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  placeholder="Enter Policy Number"
                  name={`previousPolicyNumber`}
                  readOnly={allFieldsReadOnly}
                  ref={register}
                  maxLength="50"
                  onInput={(e) =>
                    (e.target.value = e.target.value
                      .toUpperCase()
                      .replace(/[^a-zA-Z0-9-/|]/g, ""))
                  }
                  minlength="2"
                  size="sm"
                  errors={errors?.previousPolicyNumber}
                  isInvalid={errors?.previousPolicyNumber}
                />
                {!!errors?.previousPolicyNumber && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.previousPolicyNumber?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            {!isNcbApplicable &&
            temp_data?.corporateVehiclesQuoteRequest
              ?.previousPolicyExpiryDate &&
            temp_data?.corporateVehiclesQuoteRequest
              ?.previousPolicyExpiryDate !== "New" &&
            fields.includes("ncb") &&
            differenceInDays(
              toDate(moment().format("DD-MM-YYYY")),
              toDate(
                temp_data?.corporateVehiclesQuoteRequest
                  ?.previousPolicyExpiryDate
              )
            ) < 90 ? (
              <>
                <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                  <div className="py-2 fname">
                    <FormGroupTag>Claim Details</FormGroupTag>
                    <Form.Control
                      autoComplete="off"
                      as="select"
                      size="sm"
                      ref={register}
                      placeholder="Claim Details"
                      readOnly={allFieldsReadOnly}
                      name={`isClaim`}
                      style={{ cursor: "pointer" }}
                    >
                      <option
                        selected={
                          (temp_data?.corporateVehiclesQuoteRequest?.isClaim ===
                            "Y" &&
                            !CardData?.prepolicy?.isClaim) ||
                          CardData?.prepolicy?.isClaim === "Y"
                        }
                        value={"Y"}
                      >
                        YES
                      </option>
                      <option
                        selected={
                          (temp_data?.corporateVehiclesQuoteRequest?.isClaim ===
                            "N" &&
                            !CardData?.prepolicy?.isClaim) ||
                          CardData?.prepolicy?.isClaim === "N"
                        }
                        value={"N"}
                      >
                        NO
                      </option>
                    </Form.Control>
                  </div>
                  <input
                    ref={register}
                    name={"claim"}
                    value={watch("isClaim") === "Y" ? "YES" : "NO" || ""}
                    type="hidden"
                  />
                </Col>
                {watch("isClaim") !== "Y" && !temp_data?.carOwnership ? (
                  <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                    <div className="py-2 fname">
                      <FormGroupTag>No Claim Bonus</FormGroupTag>
                      <Form.Control
                        autoComplete="off"
                        as="select"
                        size="sm"
                        ref={register}
                        placeholder="Claim Details"
                        readOnly={allFieldsReadOnly}
                        name={`previousNcb`}
                        id="ncbPre"
                        style={{ cursor: "pointer" }}
                      >
                        {ncbOptionsJsx}
                      </Form.Control>
                      {!!errors?.previousNcb ? (
                        <ErrorMsg fontSize={"10px"}>
                          {errors?.previousNcb?.message}
                        </ErrorMsg>
                      ) : (
                        <Form.Text className="text-muted">
                          <text style={{ color: "#bdbdbd" }}>
                            (Incorrect declaration of NCB might lead to NCB
                            recovery or decline of claim)
                          </text>
                        </Form.Text>
                      )}
                    </div>
                    <input
                      type="hidden"
                      name="applicableNcb"
                      ref={register}
                      value={
                        temp_data?.prevShortTerm * 1
                          ? watch("previousNcb")
                            ? watch("previousNcb")
                            : "0"
                          : getNewNcb(
                              watch("previousNcb") ? watch("previousNcb") : "0"
                            )
                      }
                    />
                  </Col>
                ) : (
                  <>
                    <input
                      type="hidden"
                      name="previousNcb"
                      ref={register}
                      value={
                        temp_data?.corporateVehiclesQuoteRequest?.previousNcb
                      }
                    />
                    <input
                      type="hidden"
                      name="applicableNcb"
                      ref={register}
                      value={"0"}
                    />
                  </>
                )}
              </>
            ) : (
              !fields.includes("ncb") &&
              temp_data?.selectedQuote?.policyType !== "Third Party" && (
                <>
                  <input
                    type="hidden"
                    name="isClaim"
                    ref={register}
                    value={temp_data?.corporateVehiclesQuoteRequest?.isClaim}
                  />
                  <input
                    type="hidden"
                    name="previousNcb"
                    ref={register}
                    value={
                      temp_data?.corporateVehiclesQuoteRequest?.previousNcb
                    }
                  />
                  <input
                    type="hidden"
                    name="applicableNcb"
                    ref={register}
                    value={
                      temp_data?.corporateVehiclesQuoteRequest?.applicableNcb
                    }
                  />
                </>
              )
            )}
          </>
        ) : (
          <noscript />
        )}
        {prevPolicyCon && OwnDamage && (
          <>
            {!PolicyValidationExculsion && SubTitleFn("TP Details")}
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2 fname">
                <FormGroupTag>TP Insurance Company</FormGroupTag>
                {TP_PREV_CON || "" ? (
                  <>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      // placeholder="Enter TP Policy Number"
                      name={`disabled_id`}
                      ref={register}
                      disabled={true}
                      size="sm"
                    />
                    <input
                      type="hidden"
                      ref={register}
                      value={watch("previousInsuranceCompany")}
                      name="tpInsuranceCompany"
                    />
                  </>
                ) : (
                  (
                    <Form.Control
                      autoComplete="off"
                      as="select"
                      size="sm"
                      ref={register}
                      placeholder="TP Insurance Company"
                      name={`tpInsuranceCompany`}
                      readOnly={allFieldsReadOnly}
                      style={{ cursor: "pointer" }}
                      errors={errors?.tpInsuranceCompany}
                      isInvalid={errors?.tpInsuranceCompany}
                    >
                      {(!temp_data?.corporateVehiclesQuoteRequest
                        ?.previousInsurer ||
                        temp_data?.corporateVehiclesQuoteRequest
                          ?.previousInsurer === "NEW" ||
                        "s") &&
                        !CardData?.prepolicy?.tpInsuranceCompany &&
                        (temp_data?.userProposal?.tpInsuranceCompany || "") && (
                          <option selected={true} value={"@"}>
                            Select
                          </option>
                        )}
                      {Previnsurer.map(({ name, code }, index) => (
                        <option
                          selected={
                            CardData?.prepolicy?.tpInsuranceCompany
                              ? CardData?.prepolicy?.tpInsuranceCompany === code
                              : temp_data?.userProposal?.tpInsuranceCompany
                              ? temp_data?.userProposal?.tpInsuranceCompany ===
                                name
                              : temp_data?.corporateVehiclesQuoteRequest
                                  ?.previousInsurer === name
                          }
                          value={code}
                        >
                          {name}
                        </option>
                      ))}
                    </Form.Control>
                  ) || <noscript />
                )}

                {!!errors?.tpInsuranceCompany && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.tpInsuranceCompany?.message}
                  </ErrorMsg>
                )}
              </div>
              <input
                type="hidden"
                name={"tpInsuranceCompanyName"}
                ref={register}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <div className="py-2">
                <FormGroupTag>TP Policy Number</FormGroupTag>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="Enter TP Policy Number"
                  name={`tpInsuranceNumber`}
                  ref={register}
                  readOnly={
                    TpReadOnly && temp_data?.selectedQuote?.isRenewal !== "Y"
                  }
                  maxLength="50"
                  onInput={(e) =>
                    (e.target.value = e.target.value
                      .toUpperCase()
                      .replace(/[^a-zA-Z0-9-/]/g, ""))
                  }
                  minlength="2"
                  size="sm"
                  errors={errors?.tpInsuranceNumber}
                  isInvalid={errors?.tpInsuranceNumber}
                />
                {!!errors?.tpInsuranceNumber && (
                  <ErrorMsg fontSize={"12px"}>
                    {errors?.tpInsuranceNumber?.message}
                  </ErrorMsg>
                )}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <StyledDatePicker>
                <div className="py-2 dateTimeOne">
                  <FormGroupTag>TP Policy Start Date</FormGroupTag>
                  <Controller
                    control={control}
                    name={`tpStartDate`}
                    render={({ onChange, onBlur, value, name }) => (
                      <DateInput
                        value={value}
                        name={name}
                        minDate={
                          ODlastYr &&
                          temp_data?.corporateVehiclesQuoteRequest
                            ?.previousPolicyExpiryDate
                            ? subYears(
                                subDays(
                                  new Date(
                                    new Date(
                                      `${
                                        temp_data?.corporateVehiclesQuoteRequest?.previousPolicyExpiryDate?.split(
                                          "-"
                                        )[2]
                                      }`,
                                      `${
                                        temp_data?.corporateVehiclesQuoteRequest?.previousPolicyExpiryDate?.split(
                                          "-"
                                        )[1] *
                                          1 -
                                        1
                                      }`,
                                      `${
                                        temp_data?.corporateVehiclesQuoteRequest?.previousPolicyExpiryDate?.split(
                                          "-"
                                        )[0]
                                      }`
                                    )
                                  ),
                                  30
                                ),
                                1
                              )
                            : temp_data?.corporateVehiclesQuoteRequest
                                ?.vehicleRegisterDate
                            ? subDays(
                                new Date(
                                  new Date(
                                    `${
                                      temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate?.split(
                                        "-"
                                      )[2]
                                    }`,
                                    `${
                                      temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate?.split(
                                        "-"
                                      )[1] *
                                        1 -
                                      1
                                    }`,
                                    `${
                                      temp_data?.corporateVehiclesQuoteRequest?.vehicleRegisterDate?.split(
                                        "-"
                                      )[0]
                                    }`
                                  )
                                ),
                                365
                              )
                            : new Date(["2018", "08", "01"])
                        }
                        maxDate={new Date()}
                        onChange={onChange}
                        ref={register}
                        readOnly={
                          allFieldsReadOnly &&
                          process.env.REACT_APP_BROKER !== "BAJAJ"
                        }
                        selected={
                          !TPStartDate &&
                          ODlastYr &&
                          temp_data?.corporateVehiclesQuoteRequest
                            ?.previousPolicyExpiryDate
                            ? moment(
                                temp_data?.corporateVehiclesQuoteRequest
                                  ?.previousPolicyExpiryDate,
                                "DD-MM-YYYY"
                              )
                                .subtract(1, "years")
                                .add(1, "days")._d
                            : TPStartDate ||
                              prepolicy?.tpStartDate ||
                              CardData?.prepolicy?.tpStartDate
                            ? toDate(
                                TPStartDate ||
                                  prepolicy?.tpStartDate ||
                                  CardData?.prepolicy?.tpStartDate
                              )
                            : false
                        }
                      />
                    )}
                  />
                  {!!errors?.tpStartDate && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.tpStartDate?.message}
                    </ErrorMsg>
                  )}
                </div>
              </StyledDatePicker>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
              <StyledDatePicker>
                <div className="py-2 dateTimeOne">
                  <FormGroupTag>TP Policy End Date</FormGroupTag>
                  <Controller
                    control={control}
                    name={`tpEndDate`}
                    render={({ onChange, onBlur, value, name }) => (
                      <DateInput
                        value={value}
                        name={name}
                        onChange={onChange}
                        ref={register}
                        readOnly={true}
                      />
                    )}
                  />
                  {!!errors?.tpEndDate && (
                    <ErrorMsg fontSize={"12px"}>
                      {errors?.tpEndDate?.message}
                    </ErrorMsg>
                  )}
                </div>
              </StyledDatePicker>
            </Col>
          </>
        )}
        {PACon ? (
          <>
            {prevPolicyCon && SubTitleFn("PA Exclusion Details")}
            {PACon && (
              <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                <div className="py-2 fname">
                  <FormGroupTag>
                    {lessthan768
                      ? "Reason for not opting for personal accident cover"
                      : "CPA opt-out reason"}
                  </FormGroupTag>
                  <Form.Control
                    autoComplete="off"
                    as="select"
                    size="sm"
                    ref={register}
                    readOnly={allFieldsReadOnly}
                    placeholder="CPA OPT-OUT REASON"
                    name={`reason`}
                    style={{ cursor: "pointer" }}
                  >
                    {additionalReason || ""}
                    <option
                      selected={
                        CardData?.prepolicy?.reason &&
                        CardData?.prepolicy?.reason ===
                          `I have another PA policy with cover amount of INR 15 Lacs or more`
                      }
                      value={`I have another PA policy with cover amount of INR 15 Lacs or more`}
                    >{`I have another PA policy with cover amount of INR 15 Lacs or more`}</option>
                    <option
                      selected={
                        (CardData?.prepolicy?.reason &&
                          CardData?.prepolicy?.reason ===
                            `I have another PA policy with cover amount greater than INR 15 Lacs`) ||
                        (!CardData?.prepolicy?.reason && true)
                      }
                      value={`I do not have a valid driving license.`}
                    >{`I do not have a valid driving license.`}</option>
                  </Form.Control>
                </div>
              </Col>
            )}
            {watch("reason") !== `I do not have a valid driving license.` ? (
              <>
                <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                  <div className="py-2 fname">
                    <FormGroupTag>CPA Insurance company</FormGroupTag>
                    <Form.Control
                      autoComplete="off"
                      as="select"
                      size="sm"
                      ref={register}
                      placeholder="CPA Insurance company"
                      readOnly={allFieldsReadOnly}
                      name={`cPAInsComp`}
                      style={{ cursor: "pointer" }}
                      errors={errors?.cPAInsComp}
                      isInvalid={errors?.cPAInsComp}
                    >
                      <option selected={true} value={"@"}>
                        Select
                      </option>
                      {Previnsurer.map(({ name, code }, index) => (
                        <option
                          selected={
                            CardData?.prepolicy?.cPAInsComp &&
                            CardData?.prepolicy?.cPAInsComp === code
                          }
                          value={code}
                        >
                          {name}
                        </option>
                      ))}
                    </Form.Control>
                    {!!errors?.cPAInsComp && (
                      <ErrorMsg fontSize={"12px"}>
                        {errors?.cPAInsComp?.message}
                      </ErrorMsg>
                    )}
                  </div>
                  <input
                    type="hidden"
                    name={"CpaInsuranceCompany"}
                    ref={register}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                  <StyledDatePicker>
                    <div className="py-2 dateTimeOne">
                      <FormGroupTag>CPA Policy Start Date</FormGroupTag>
                      <Controller
                        control={control}
                        name={`cPAPolicyFmDt`}
                        render={({ onChange, onBlur, value, name }) => (
                          <DateInput
                            value={value}
                            name={name}
                            readOnly={allFieldsReadOnly}
                            minDate={
                              moment().add(1, "days").subtract(10, "years")?._d
                            }
                            selected={
                              CpaFmDate ||
                              prepolicy?.cPAPolicyFmDt ||
                              CardData?.prepolicy?.cPAPolicyFmDt
                                ? toDate(
                                    CpaFmDate ||
                                      prepolicy?.cPAPolicyFmDt ||
                                      CardData?.prepolicy?.cPAPolicyFmDt
                                  )
                                : false
                            }
                            maxDate={new Date()}
                            onChange={onChange}
                            ref={register}
                          />
                        )}
                      />
                      {!!errors?.cPAPolicyFmDt && (
                        <ErrorMsg fontSize={"12px"}>
                          {errors?.cPAPolicyFmDt?.message}
                        </ErrorMsg>
                      )}
                    </div>
                  </StyledDatePicker>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                  <StyledDatePicker>
                    <div className="py-2 dateTimeOne">
                      <FormGroupTag>CPA Policy End Date</FormGroupTag>
                      <Controller
                        control={control}
                        name={`cPAPolicyToDt`}
                        render={({ onChange, onBlur, value, name }) => (
                          <DateInput
                            value={value}
                            name={name}
                            // maxDate={toDate(temp_data?.selectedQuote?.policyStartDate)}
                            minDate={CpaFmDate ? moment()?._d : moment()?._d}
                            maxDate={
                              CpaFmDate
                                ? moment(
                                    moment(CpaFmDate, "DD-MM-YYYY")
                                      .add(10, "years")
                                      .subtract(1, "days")
                                  )?._d
                                : moment().add(10, "years").subtract(1, "days")
                                    ?._d
                            }
                            selected={
                              CpaToDate ||
                              prepolicy?.cPAPolicyToDt ||
                              CardData?.prepolicy?.cPAPolicyToDt
                                ? toDate(
                                    CpaToDate ||
                                      prepolicy?.cPAPolicyToDt ||
                                      CardData?.prepolicy?.cPAPolicyToDt
                                  )
                                : false
                            }
                            onChange={onChange}
                            ref={register}
                            readOnly={CpaFmDate ? false : true}
                          />
                        )}
                      />
                      {!!errors?.cPAPolicyToDt && (
                        <ErrorMsg fontSize={"12px"}>
                          {errors?.cPAPolicyToDt?.message}
                        </ErrorMsg>
                      )}
                    </div>
                  </StyledDatePicker>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                  <div className="py-2">
                    <FormGroupTag>CPA Policy Number</FormGroupTag>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Enter CPA Policy Number"
                      name={`cPAPolicyNo`}
                      ref={register}
                      readOnly={allFieldsReadOnly}
                      maxLength="50"
                      onInput={(e) =>
                        (e.target.value = e.target.value
                          .toUpperCase()
                          .replace(/[^a-zA-Z0-9-/]/g, ""))
                      }
                      minlength="2"
                      size="sm"
                      errors={errors?.cPAPolicyNo}
                      isInvalid={errors?.cPAPolicyNo}
                    />
                    {!!errors?.cPAPolicyNo && (
                      <ErrorMsg fontSize={"12px"}>
                        {errors?.cPAPolicyNo?.message}
                      </ErrorMsg>
                    )}
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={4} className="">
                  <div className="py-2">
                    <FormGroupTag>CPA Sum Insured</FormGroupTag>
                    <Form.Control
                      autoComplete="off"
                      name="cPASumInsured"
                      ref={register}
                      type="tel"
                      onInput={(e) =>
                        (e.target.value = e.target.value * Number(1))
                      }
                      placeholder="Enter Sum Insured"
                      errors={errors?.cPASumInsured}
                      isInvalid={errors?.cPASumInsured}
                      size="sm"
                      onKeyDown={numOnly}
                      defaultValue={"1500000"}
                      readOnly={allFieldsReadOnly}
                    />
                    {!!errors?.cPASumInsured && (
                      <ErrorMsg fontSize={"12px"}>
                        {errors?.cPASumInsured?.message}
                      </ErrorMsg>
                    )}
                  </div>
                </Col>
              </>
            ) : (
              <noscript />
            )}
            {reasonCpa !== "I do not have a valid driving license." && (
              <>
                <input
                  type="hidden"
                  name={"cpaPolicyNumber"}
                  ref={register}
                  value={cpaPolicyNo}
                />
                <input
                  type="hidden"
                  name={"cpaPolicyStartDate"}
                  ref={register}
                  value={CpaFmDate}
                />
                <input
                  type="hidden"
                  name={"cpaPolicyEndDate"}
                  ref={register}
                  value={CpaToDate}
                />
                <input
                  type="hidden"
                  name={"cpaSumInsured"}
                  ref={register}
                  value={CpaSumIns}
                />{" "}
              </>
            )}
          </>
        ) : (
          <>
            {/*resetting CPA values to update DB*/}
            <input type="hidden" value={null} name={"cPAInsComp"} />
            <input type="hidden" value={null} name={"cPAPolicyNo"} />
            <input type="hidden" value={null} name={"cPAPolicyFmDt"} />
            <input type="hidden" value={null} name={"cPAPolicyToDt"} />
            <input type="hidden" value={null} name={"cPASumInsured"} />
          </>
        )}
        <Col
          sm={12}
          lg={12}
          md={12}
          xl={12}
          className="d-flex justify-content-center mt-5"
          //triggering input validation manually
          onClick={() => [
            trigger("previousInsuranceCompany"),
            trigger("tpInsuranceCompany"),
          ]}
        >
          <Button
            type="submit"
            buttonStyle="outline-solid"
            disabled={SubmitCon || ""}
            hex1={
              Theme?.proposalProceedBtn?.hex1
                ? Theme?.proposalProceedBtn?.hex1
                : "#4ca729"
            }
            hex2={
              Theme?.proposalProceedBtn?.hex2
                ? Theme?.proposalProceedBtn?.hex2
                : "#4ca729"
            }
            borderRadius="5px"
            color="white"
            shadow={"none"}
            onClick={() =>
              navigator && navigator?.vibrate && navigator.vibrate([100, 0, 50])
            }
          >
            <text
              style={{
                fontSize: "15px",
                padding: "-20px",
                margin: "-20px -5px -20px -5px",
                fontWeight: "400",
              }}
            >
              {"Proceed"}
            </text>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const StyledDatePicker = styled.div`
  .dateTimeOne .date-header {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
  .dateTimeOne {
    ${(props) => (props?.disabled ? `cursor: not-allowed !important;` : ``)}
  }
  .dateTimeOne .react-datepicker__input-container input {
    ${(props) => (props?.disabled ? `cursor: not-allowed !important;` : ``)}
  }
  .dateTimeOne .react-datepicker__day:hover {
    background: ${Theme1
      ? `${Theme1?.reactCalendar?.background} !important`
      : "#4ca729 !important"};
    border: ${Theme1
      ? `1px solid ${Theme1?.reactCalendar?.background} !important`
      : "1px solid #4ca729 !important"};
  }
`;

export default PolicyCard;
