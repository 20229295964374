import React, { useEffect } from "react";
import Popup from "components/Popup/Popup";
import styled from "styled-components";
import { useMediaPredicate } from "react-media-hook";
import { ContactFn } from "components";
import { LinkTrigger } from "modules/Home/home.slice";
import { useDispatch } from "react-redux";

const TimeoutPopup = ({ show, onClose, enquiry_id }) => {
  const dispatch = useDispatch();
//Timout beacon
  useEffect(() => {
    show &&
      dispatch(
        LinkTrigger(
          {
            user_product_journey_id: enquiry_id,
            dropout: "timeout",
          },
          true
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const lessthan767 = useMediaPredicate("(max-width: 767px)");
  const content = (
    <TopDiv>
      <div style={{ height: "200px", width: "100%", textAlign: "center" }}>
        <img
          src={`${
            process.env.REACT_APP_BASENAME !== "NA"
              ? `/${process.env.REACT_APP_BASENAME}`
              : ""
          }/assets/images/car-icon.jpg`}
          style={{ height: "100%" }}
          alt="car-icon"
        />
      </div>
      <h2
        className="text-center hello_text"
        style={{ color: "white", marginTop: "50px" }}
      >
        Hello, you seem to be inactive.
      </h2>
      <p
        className="mt-2 mb-4 help_text"
        style={{ color: "#fff", textAlign: "center" }}
      >
        Need help choosing the best plan? You can talk to us now.
      </p>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <button
          className="no-thanks-btn"
          style={{
            padding: "6px 30px",
            borderRadius: "8px",
            background: "#fff",
            border: "none",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          No, Thanks
        </button>

        <a
          href={lessthan767 && `tel:${ContactFn()}`}
          className="call-me-btn"
          style={{
            padding: "6px 30px",
            borderRadius: "8px",
            border: "none",
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={() => {
            onClose();
            return (
              document?.getElementById("callus1") &&
              document?.getElementById("callus1").click()
            );
          }}
        >
          Talk to us Now.
        </a>
      </div>
    </TopDiv>
  );

  return (
    <div>
      <Popup
        width="500px"
        height="auto"
        hiddenClose
        show={show}
        content={content}
        backGround="transparent"
      />
    </div>
  );
};

export default TimeoutPopup;

const TopDiv = styled.div`
  .no-thanks-btn {
    color: ${({ theme }) => theme.floatButton?.floatColor || "#bdd400"};
  }
  .call-me-btn {
    background: ${({ theme }) => theme.floatButton?.floatColor || "#bdd400"};
  }
  .call-me-btn:hover {
    text-decoration: none;
  }
  @media (max-width: 576px) {
    .hello_text {
      font-size: 1.2rem;
    }
    .help_text {
      font-size: 0.9rem;
    }
  }
`;
