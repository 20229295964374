import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import _ from "lodash";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { numOnly, toDate, Encrypt, reloadPage } from "utils";
import {
  SaveQuoteData,
  set_temp_data,
  Category,
  clear,
  getFastLaneRenewalDatas,
  setFastLaneRenewal,
  tabClick as TabClick,
  getFrontendUrl,
} from "modules/Home/home.slice";
import {
  CancelAll,
  clear as clr,
  setQuotesList,
  error,
} from "modules/quotesPage/quote.slice";
import {
  SaveLead,
  SaveQuoteData as SaveQuoteDataQuotes,
  error as quotesError,
} from "../../../quotesPage/filterConatiner/quoteFilter.slice";
import { TextInput, Button, BackButton } from "components";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { differenceInMonths, differenceInDays } from "date-fns";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { TypeReturn } from "modules/type";
import { redirection } from "modules/Home/steps/Registration/helper";
import JourneyMismatch from "modules/Home/steps/Registration/journey-mismatch";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const RenewalRegistration = ({
  stepper1,
  type,
  enquiry_id,
  journey_type,
  typeId,
  token,
  errorProp,
  lessthan767,
  lessthan400,
  lessthan330,
  isPartner,
  policy_no_url,
  reg_no_url,
}) => {
  // validation schema
  const yupValidate = yup.object({
    regNo1: yup
      .string()
      .matches(/^[A-Z]{2}[-][0-9\s]{1,2}$/, "Invalid RTO Number")
      .required("Registration No. is required"),
    regNo2: yup
      .string()
      .matches(/^[A-Za-z\s]{1,3}$/, "Invalid Number")
      .nullable()
      .transform((v, o) => (o === "" ? null : v)),
    regNo3: yup
      .string()
      .required("Number is required")
      .matches(/^[0-9]{4}$/, "Invalid Number"),
  });

  /*---------------- back button---------------------*/
  const back = () => {
    history.push(
      `/${type}/registration?enquiry_id=${temp_data?.enquiry_id || enquiry_id}${
        token ? `&token=${token}` : ``
      }${typeId ? `&typeid=${typeId}` : ``}${
        journey_type ? `&journey_type=${journey_type}` : ``
      }`
    );
  };
  /*----------x----- back button-------x-------------*/

  const { handleSubmit, register, errors, setValue, watch } = useForm({
    resolver: yupResolver(yupValidate),
    mode: "all",
    reValidateMode: "onBlur",
  });

  const [btnDisable, setbtnDisable] = useState(false);
  const [buffer, setBuffer] = useState(false);

  //modal state
  const [show, setShow] = useState(false);

  const {
    temp_data,
    saveQuoteData,
    category,
    fastLaneRenewalData,
    tabClick,
    frontendurl,
    theme_conf,
  } = useSelector((state) => state.home);

  const history = useHistory();
  const dispatch = useDispatch();

  //Renewbuy
  const forceSSO =
    _.isEmpty(temp_data?.agentDetails?.filter((o) => o?.sellerType === "U")) &&
    process.env.REACT_APP_BASE_URL !==
      "https://apipreprodmotor.rbstaging.in/api";

  //Initial boot
  useEffect(() => {
    _.isEmpty(frontendurl) && dispatch(getFrontendUrl());
  }, []);

  const onSubmit = (journeyType, policy) => {
    if (
      forceSSO &&
      process.env.REACT_APP_BROKER === "RB" &&
      !localStorage?.SSO_user_motor
    ) {
      swal(
        "Attention",
        "Login to experience the new & faster journey",
        "info"
      ).then(() => {
        setbtnDisable(false);
        setBuffer(false);
        document.getElementById("widgetJslogin") &&
          document.getElementById("widgetJslogin").click();
      });
    } else {
      dispatch(CancelAll(false));
      tabClick && dispatch(TabClick(false));
      if (
        (process.env?.REACT_APP_API_BASE_URL ===
          "https://apimotor.fynity.in/api" ||
          process.env?.REACT_APP_BROKER === "ABIBL" ||
          process.env?.REACT_APP_API_BASE_URL ===
            "https://api-carbike.fynity.in/api" ||
          process.env?.REACT_APP_BROKER === "OLA" ||
          process.env?.REACT_APP_BROKER === "ACE" ||
          process.env?.REACT_APP_BROKER === "EPOCH" ||
          process.env?.REACT_APP_BROKER === "PINC" ||
          process.env?.REACT_APP_BROKER === "BAJAJ" ||
          process.env?.REACT_APP_BROKER === "SPA" ||
          process.env?.REACT_APP_BROKER === "RB" ||
          process.env?.REACT_APP_BROKER === "TATA" ||
          (process.env?.REACT_APP_BROKER === "GRAM" &&
            (TypeReturn(type) === "car" ||
              TypeReturn(type) === "bike" ||
              TypeReturn(type) === "cv"))) &&
        (Number(journeyType) === 1 || !policy) &&
        fastLaneRenewalData?.status !== 101 &&
        fastLaneRenewalData?.status !== 108
      ) {
        onSubmitFastLaneRenewal();
      } else {
        if (
          (Number(journeyType) === 1 && regNo1 && regNo3) ||
          Number(journeyType) === 2 ||
          Number(journeyType) === 3
        ) {
          if (Number(journeyType) !== 1) {
            dispatch(
              set_temp_data({
                journeyWithoutRegno: "Y",
                journeyType,
                regNo: null,
                regNo1: null,
                regNo2: null,
                regNo3: null,
                regDate: null,
                fastlaneRenewalJourney: false,
                isRenewalRedirection: "Y",
                prefillPolicyNumber: policyNumber,
                ...(TypeReturn(type) !== "cv" &&
                  TypeReturn(type) &&
                  !_.isEmpty(category) && {
                    productSubTypeId: category?.product_sub_type_id,
                    productSubTypeCode: category?.product_sub_type_code,
                    productSubTypeName: category?.product_sub_type_code,
                  }),
                //clearing vehicle type
                // productSubTypeId: null,
                // productSubTypeCode: null,
                // productCategoryName: null,
                // carrierType: null,
              })
            );
            dispatch(
              SaveQuoteData({
                ...(localStorage?.SSO_user && {
                  tokenResp: JSON.parse(localStorage?.SSO_user),
                }),
                stage: "2",
                ...(isPartner === "Y" && { frontendTags: "Y" }),
                vehicleRegistrationNo:
                  Number(journeyType) === 3 ? "NEW" : "NULL",
                  journeyWithoutRegno: "Y",
                userProductJourneyId: enquiry_id,
                enquiryId: enquiry_id,
                vehicleRegisterDate: "NULL",
                policyExpiryDate: "NULL",
                previousInsurerCode: "NULL",
                previousInsurer: "NULL",
                previousPolicyType: "NULL",
                businessType: "NULL",
                policyType: "NULL",
                previousNcb: "NULL",
                applicableNcb: "NULL",
                fastlaneRenewalJourney: false,
                isRenewalRedirection: "Y",
                prefillPolicyNumber: policyNumber,
                ...(journey_type && {
                  journeyType: journey_type,
                }),
                ...(TypeReturn(type) !== "cv" &&
                  TypeReturn(type) &&
                  !_.isEmpty(category) && {
                    productSubTypeId: category?.product_sub_type_id,
                    productSubTypeCode: category?.product_sub_type_code,
                    productSubTypeName: category?.product_sub_type_code,
                  }),
              })
            );
          } else {
            if (
              (regNo1 &&
                regNo2 &&
                regNo3 &&
                `${regNo1}-${regNo2}-${regNo3}`.match(
                  /^[A-Z]{2}[-][0-9]{1,2}[-\s][A-Z0-9]{1,3}[-\s][0-9]{4}$/
                )) ||
              (regNo1 &&
                !regNo2 &&
                regNo3 &&
                `${regNo1}-${regNo3}`.match(
                  /^[A-Z]{2}[-][0-9]{1,2}[-\s][0-9]{4}$/
                ))
            ) {
              dispatch(
                set_temp_data({
                  isRenewalRedirection: "Y",
                  journeyWithoutRegno: "N",
                  prefillPolicyNumber: policyNumber,
                  journeyType,
                  regNo1,
                  regNo2,
                  regNo3,
                  regNo: regNo2
                    ? `${
                        Number(regNo1.split("-")[1]) < 10
                          ? `${regNo1.split("-")[0]}-0${Number(
                              regNo1.split("-")[1]
                            )}`
                          : regNo1
                      }-${regNo2}-${regNo3}`
                    : `${
                        Number(regNo1.split("-")[1]) < 10
                          ? `${regNo1.split("-")[0]}-0${Number(
                              regNo1.split("-")[1]
                            )}`
                          : regNo1
                      }--${regNo3}`,
                  vehicleRegisterDate: null,
                  fastlaneRenewalJourney: false,
                  ...(TypeReturn(type) !== "cv" &&
                    TypeReturn(type) &&
                    !_.isEmpty(category) && {
                      productSubTypeId: category?.product_sub_type_id,
                      productSubTypeCode: category?.product_sub_type_code,
                      productSubTypeName: category?.product_sub_type_code,
                    }),
                  //clearing vehicle TypeReturn(type)
                  // productSubTypeId: null,
                  // productSubTypeCode: null,
                  // productCategoryName: null,
                  // carrierType: null,
                })
              );
              dispatch(
                SaveQuoteData({
                  ...(localStorage?.SSO_user && {
                    tokenResp: JSON.parse(localStorage?.SSO_user),
                  }),
                  stage: "2",
                  journeyWithoutRegno: "N",
                  vehicleRegistrationNo: regNo2
                    ? `${
                        Number(regNo1.split("-")[1]) < 10
                          ? `${regNo1.split("-")[0]}-0${Number(
                              regNo1.split("-")[1]
                            )}`
                          : regNo1
                      }-${regNo2}-${regNo3}`
                    : `${
                        Number(regNo1.split("-")[1]) < 10
                          ? `${regNo1.split("-")[0]}-0${Number(
                              regNo1.split("-")[1]
                            )}`
                          : regNo1
                      }--${regNo3}`,
                  rtoNumber:
                    Number(regNo1.split("-")[1]) < 10
                      ? `${regNo1.split("-")[0]}-0${Number(
                          regNo1.split("-")[1]
                        )}`
                      : regNo1,
                  rto:
                    Number(regNo1.split("-")[1]) < 10
                      ? `${regNo1.split("-")[0]}-0${Number(
                          regNo1.split("-")[1]
                        )}`
                      : regNo1,
                  userProductJourneyId: enquiry_id,
                  vehicleRegisterAt:
                    Number(regNo1.split("-")[1]) < 10
                      ? `${regNo1.split("-")[0]}-0${Number(
                          regNo1.split("-")[1]
                        )}`
                      : regNo1,
                  ...(isPartner === "Y" && { frontendTags: "Y" }),
                  ...(journey_type && {
                    journeyType: journey_type,
                  }),
                  enquiryId: enquiry_id,
                  vehicleRegisterDate: "NULL",
                  policyExpiryDate: "NULL",
                  previousInsurerCode: "NULL",
                  previousInsurer: "NULL",
                  previousPolicyType: "NULL",
                  businessType: "NULL",
                  policyType: "NULL",
                  previousNcb: "NULL",
                  applicableNcb: "NULL",
                  isRenewalRedirection: "Y",
                  prefillPolicyNumber: policyNumber,
                  fastlaneRenewalJourney: false,
                  ...(TypeReturn(type) !== "cv" &&
                    TypeReturn(type) &&
                    !_.isEmpty(category) && {
                      productSubTypeId: category?.product_sub_type_id,
                      productSubTypeCode: category?.product_sub_type_code,
                      productSubTypeName: category?.product_sub_type_code,
                    }),
                })
              );
              setTimeout(setbtnDisable(false), 2000);
            } else {
              swal("Warning", "Invalid Registration Number", "warning").then(
                () => setTimeout(setbtnDisable(false), 1000)
              );
            }
          }
        } else {
          swal("Error", "Please fill all the details", "error").then(() =>
            setTimeout(setbtnDisable(false), 1000)
          );
        }
      }
    }
  };

  //fastlane logic to be discarded
  const onSubmitFastLaneRenewal = (policy) => {
    if (
      forceSSO &&
      process.env.REACT_APP_BROKER === "RB" &&
      !localStorage?.SSO_user_motor
    ) {
      swal(
        "Attention",
        "Login to experience the new & faster journey",
        "info"
      ).then(() => {
        setbtnDisable(false);
        setBuffer(false);
        document.getElementById("widgetJslogin") &&
          document.getElementById("widgetJslogin").click();
      });
    } else {
      setbtnDisable(true);
      if (
        process.env?.REACT_APP_API_BASE_URL ===
          "https://apimotor.fynity.in/api" ||
        process.env?.REACT_APP_BROKER === "ABIBL" ||
        process.env?.REACT_APP_API_BASE_URL ===
          "https://api-carbike.fynity.in/api" ||
        process.env?.REACT_APP_BROKER === "OLA" ||
        process.env?.REACT_APP_BROKER === "EPOCH" ||
        process.env?.REACT_APP_BROKER === "RB" ||
        process.env?.REACT_APP_BROKER === "ACE" ||
        process.env?.REACT_APP_BROKER === "PINC" ||
        process.env?.REACT_APP_BROKER === "BAJAJ" ||
        process.env?.REACT_APP_BROKER === "SPA" ||
        process.env?.REACT_APP_BROKER === "RB" ||
        process.env?.REACT_APP_BROKER === "TATA" ||
        (process.env?.REACT_APP_BROKER === "GRAM" &&
          (TypeReturn(type) === "car" ||
            TypeReturn(type) === "bike" ||
            TypeReturn(type) === "cv"))
      ) {
        setBuffer(true);
        const registration_no = regNo2
        ? `${
            Number(regNo1.split("-")[1]) < 10
              ? `${regNo1.split("-")[0]}-0${Number(
                  regNo1.split("-")[1]
                )}`
              : regNo1
          }-${regNo2}-${regNo3}`
        : `${
            Number(regNo1.split("-")[1]) < 10
              ? `${regNo1.split("-")[0]}-0${Number(
                  regNo1.split("-")[1]
                )}`
              : regNo1
          }--${regNo3}`;
        const data = {
          ...(localStorage?.SSO_user && {
            tokenResp: JSON.parse(localStorage?.SSO_user),
          }),
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          ...(!policy
            ? {
                registration_no: registration_no,
                unformatted_reg_no: registration_no.replace(/-/gi, ""),
              }
            : {
                policyNumber: policyNumber,
                registration_no: "NULL",
              }),
          ...(TypeReturn(type) !== "cv" && {
            productSubType: TypeReturn(type) === "car" ? 1 : 2,
          }),
          ...(journey_type && {
            journeyType: journey_type,
          }),
          section: TypeReturn(type),
          ...(reg_no_url &&
            (regNo2
              ? `${
                  Number(regNo1.split("-")[1]) < 10
                    ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
                    : regNo1
                }-${regNo2}-${regNo3}`
              : `${
                  Number(regNo1.split("-")[1]) < 10
                    ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
                    : regNo1
                }--${regNo3}`) &&
            (regNo2
              ? `${
                  Number(regNo1.split("-")[1]) < 10
                    ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
                    : regNo1
                }-${regNo2}-${regNo3}`
              : `${
                  Number(regNo1.split("-")[1]) < 10
                    ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
                    : regNo1
                }--${regNo3}`
            )
              .replace(/-/g, "")
              .replace(/0/g, "") ===
              reg_no_url.replace(/-/g, "").replace(/0/g, "") && {
              vendor_rc: reg_no_url,
            }),
        };
        dispatch(getFastLaneRenewalDatas(data));
      } else {
        onSubmit(policy ? 2 : 1, "policy");
      }
    }
  };

  useEffect(() => {
    dispatch(CancelAll(true));
    dispatch(clr());
    dispatch(clear());
    dispatch(quotesError(null));
    dispatch(error(null));
  }, []);

  useEffect(() => {
    //Cancel token
    dispatch(CancelAll(true));
    //quotes clr
    dispatch(setQuotesList([]));
    //quotes slice state clear
    dispatch(clr());
  }, []);

  /*---x---Handling changes for Inputs---x---*/
  const regIp = watch("regNo") || "";

  console.log(regIp);

  //setting the value of SingleRegIp to divided reg Ip's
  useEffect(() => {
    if (
      regIp.match(/^[A-Z]{2}[-][0-9]{1,2}[-\s][A-Z0-9]{1,3}[-\s][0-9]{4}$/) ||
      regIp.match(/^[A-Z]{2}[-][0-9]{1,2}[-\s][0-9]{4}$/)
    ) {
      setValue(
        "regNo1",
        regIp && regIp !== "NEW"
          ? `${regIp?.split("-")[0]}-${regIp?.split("-")[1]}`
          : ""
      );

      setValue(
        "regNo2",
        regIp && regIp && regIp !== "NEW"
          ? `${
              _.compact(regIp?.split("-"))?.length === 4
                ? regIp?.split("-")[2]
                : ""
            }`
          : ""
      );

      setValue(
        "regNo3",
        regIp && regIp !== "NEW"
          ? `${
              _.compact(regIp?.split("-"))?.length === 4
                ? regIp?.split("-")[3]
                : _.compact(regIp?.split("-"))[2]
            }`
          : ""
      );
    } else {
      setValue("regNo1", "");
      setValue("regNo2", "");
      setValue("regNo3", "");
    }
    if (!regIp) {
      setValue("regNo1", "");
      setValue("regNo2", "");
      setValue("regNo3", "");
    }
  }, [regIp]);

  //varibles for reg inputs
  let regNo1 = watch("regNo1");
  regNo1 = regNo1 ? regNo1.replace(/\s/g, "") : ""; //trim white-spaces
  let regNo2 = watch("regNo2");
  regNo2 = regNo2 ? regNo2.replace(/\s/g, "") : ""; //trim white-spaces
  let regNo3 = watch("regNo3");
  let policyNumber = watch("policyNo");

  //finding number of blocks
  const segmentIndexes = regIp && regIp.split("-");
  //finding the length of the rto block
  const segmentInfo =
    regIp &&
    segmentIndexes &&
    !_.isEmpty(segmentIndexes) &&
    segmentIndexes?.length >= 1
      ? segmentIndexes[1]?.length
      : "";

  //finding the length of middle segment
  let midBlockCheck =
    regIp &&
    regIp.length > (Number(segmentInfo) === 1 ? 4 : 5) &&
    regIp.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1;

  const MidsegmentInfo =
    regIp &&
    !midBlockCheck &&
    segmentIndexes &&
    !_.isEmpty(segmentIndexes) &&
    segmentIndexes?.length >= 2
      ? segmentIndexes[2]?.length
      : "";
  /*-----on paste-----*/
  const onPaste = (e, validate) => {
    if (!validate) {
      e.preventDefault();
    }
    let value = !validate
      ? e.clipboardData
          .getData("Text")
          .trim()
          .replace(/[^A-Za-z0-9]/gi, "")
      : e || "";
    let strlength = value.length;
    //formatting text
    let rtoState =
      Number(strlength) >= 2 &&
      value.slice(0, 2).match(/^[a-zA-Z]*$/) &&
      value.slice(0, 2);
    let rtoNum =
      Number(strlength) >= 4 && value.slice(2, 4).match(/^[0-9]*$/)
        ? value.slice(2, 4)
        : Number(strlength) >= 4 && value.slice(2, 3).match(/^[0-9]*$/)
        ? value.slice(2, 3)
        : "";
    let middleBlockStart = rtoNum ? rtoNum.length + 2 : false;
    //is middle block ineligible
    let middleblockcheck =
      rtoNum &&
      Number(strlength) >= middleBlockStart &&
      value.split("")[middleBlockStart] * 1;
    //middle block
    let middleblock =
      (rtoNum &&
        Number(strlength) >= middleBlockStart + 2 &&
        !middleblockcheck &&
        (Number(strlength) >= rtoNum.length + 5 &&
        value.slice(middleBlockStart, middleBlockStart + 3).match(/^[a-zA-Z]*$/)
          ? value.slice(middleBlockStart, middleBlockStart + 3)
          : value
              .slice(middleBlockStart, middleBlockStart + 2)
              .match(/^[a-zA-Z]*$/)
          ? value.slice(middleBlockStart, middleBlockStart + 2)
          : value
              .slice(middleBlockStart, middleBlockStart + 1)
              .match(/^[a-zA-Z]*$/)
          ? value.slice(middleBlockStart, middleBlockStart + 1)
          : "")) ||
      "";

    let lastBlock =
      !middleblockcheck && rtoNum && middleblock
        ? //with middle block
          Number(strlength) >= rtoNum.length + middleblock?.length + 3 &&
          (Number(strlength) >= rtoNum.length + middleblock?.length + 6 &&
          value
            .slice(
              rtoNum.length + middleblock?.length + 2,
              rtoNum.length + middleblock?.length + 6
            )
            .match(/^[0-9]*$/)
            ? value.slice(
                rtoNum.length + middleblock?.length + 2,
                rtoNum.length + middleblock?.length + 6
              )
            : Number(strlength) >= rtoNum.length + middleblock?.length + 5 &&
              value
                .slice(
                  rtoNum.length + middleblock?.length + 2,
                  rtoNum.length + middleblock?.length + 5
                )
                .match(/^[0-9]*$/)
            ? value.slice(
                rtoNum.length + middleblock?.length + 2,
                rtoNum.length + middleblock?.length + 5
              )
            : Number(strlength) >= rtoNum.length + middleblock?.length + 4 &&
              value
                .slice(
                  rtoNum.length + middleblock?.length + 2,
                  rtoNum.length + middleblock?.length + 4
                )
                .match(/^[0-9]*$/)
            ? value.slice(
                rtoNum.length + middleblock?.length + 2,
                rtoNum.length + middleblock?.length + 4
              )
            : value
                .slice(
                  rtoNum.length + middleblock?.length + 2,
                  rtoNum.length + middleblock?.length + 3
                )
                .match(/^[0-9]*$/)
            ? value.slice(
                rtoNum.length + middleblock?.length + 2,
                rtoNum.length + middleblock?.length + 3
              )
            : "")
        : //without middle block
          Number(strlength) >= rtoNum.length + 3 &&
          (Number(strlength) >= rtoNum.length + 6 &&
          value.slice(rtoNum.length + 2, rtoNum.length + 6).match(/^[0-9]*$/)
            ? value.slice(rtoNum.length + 2, rtoNum.length + 6)
            : Number(strlength) >= rtoNum.length + 5 &&
              value
                .slice(rtoNum.length + 2, rtoNum.length + 5)
                .match(/^[0-9]*$/)
            ? value.slice(rtoNum.length + 2, rtoNum.length + 5)
            : Number(strlength) >= rtoNum.length + 4 &&
              value
                .slice(rtoNum.length + 2, rtoNum.length + 4)
                .match(/^[0-9]*$/)
            ? value.slice(rtoNum.length + 2, rtoNum.length + 4)
            : Number(strlength) >= rtoNum.length + 3 &&
              value
                .slice(rtoNum.length + 2, rtoNum.length + 3)
                .match(/^[0-9]*$/)
            ? value.slice(rtoNum.length + 2, rtoNum.length + 3)
            : "");

    let finalRegNum =
      rtoState &&
      rtoNum &&
      `${rtoState}-${rtoNum}${middleblock ? `-${middleblock}` : ""}${
        lastBlock ? `-${lastBlock}` : ""
      }`;

    !validate && finalRegNum && setValue("regNo", finalRegNum.toUpperCase());
    return !validate ? false : finalRegNum;
  };
  /*--x--on paste--x--*/

  // single key
  const SingleKey = (e) => {
    let value = e.target.value.trim().replace(/--/g, "-");
    let middleBlock =
      value &&
      value.length > (Number(segmentInfo) === 1 ? 4 : 5) &&
      (value.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1 ||
        value.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1 === 0);
    //blocking keys when max len = e.target.value.length
    //handling blocks using length.
    /*-----rto block-----*/
    if (e.keyCode === 8 || e.keyCode === 46) {
      value = "";
    }
    if (value.length === 2 && !(e.keyCode === 8 || e.keyCode === 46)) {
      e.target.value = value.slice(-1) !== "-" ? value + "-" : value;
    }
    if (value.length === 5) {
      //if the user enters single serial after rto state then by hitting alphabet the user can generate next feild
      if (
        !(value.slice(-1) * 1 || value.slice(-1) * 1 === 0) &&
        !value.slice(-2).includes("-")
      ) {
        //slicing the last char, adding "-" and appending the last char
        e.target.value = value.slice(0, -1) + "-" + value.slice(-1);
      } else {
        //arrow keys are excluded for navigation purposes
        if (
          e.keyCode !== 37 &&
          e.keyCode !== 38 &&
          e.keyCode !== 39 &&
          e.keyCode !== 40
        )
          e.target.value = value.slice(-1) === "-" ? value : value + "-";
      }
    }
    /*--x--rto block--x--*/

    /*-----Middle & Last Block-----*/
    //if the user enters an alphabet after rto block
    if (value.length > 5) {
      //first two entries of the midle block are validated in onchangesingle
      //the third entry in middle block can be a number or an alphabet.
      if (!middleBlock && (value?.length > Number(segmentInfo) === 1 ? 7 : 8)) {
        //if the 2nd entry is a number
        if (
          (value.split("")[Number(segmentInfo) === 1 ? 7 : 8] * 1 ||
            value.split("")[Number(segmentInfo) === 1 ? 7 : 8] * 1 === 0) &&
          !value.slice(-2).includes("-") &&
          value.split("")[Number(segmentInfo) === 1 ? 7 : 8] !== "-" &&
          value.split("")[Number(segmentInfo) === 1 ? 6 : 7] !== "-"
        ) {
          e.target.value = value.slice(0, -1) + "-" + value.slice(-1);
        }
        //if the 2nd entry is a number
        else if (
          !middleBlock &&
          (value.split("")[Number(segmentInfo) === 1 ? 6 : 7] * 1 ||
            value.split("")[Number(segmentInfo) === 1 ? 6 : 7] * 1 === 0) &&
          (value?.length > Number(segmentInfo) === 1 ? 6 : 7) &&
          !value.slice(-2).includes("-") &&
          value.split("")[Number(segmentInfo) === 1 ? 7 : 8] !== "-"
        ) {
          e.target.value = value.slice(0, -1) + "-" + value.slice(-1);
        } else {
          //last block generation , if all the entries are non number
          if (
            !middleBlock &&
            value?.length === (Number(segmentInfo) === 1 ? 8 : 9) &&
            value.split("")[Number(segmentInfo) === 1 ? 7 : 8] !== "-" &&
            value.split("")[Number(segmentInfo) === 1 ? 6 : 7] !== "-"
          ) {
            e.target.value = value.slice(-2) === "-" ? value : value + "-";
          }
        }
      }

      //onSubmit
      if (
        !middleBlock &&
        MidsegmentInfo &&
        value.length ===
          (Number(segmentInfo) === 1
            ? Number(MidsegmentInfo) === 1
              ? 11
              : Number(MidsegmentInfo) === 2
              ? 12
              : 13
            : Number(MidsegmentInfo) === 1
            ? 12
            : Number(MidsegmentInfo) === 2
            ? 13
            : 14) &&
        e.keyCode !== 37 &&
        e.keyCode !== 37 &&
        e.keyCode !== 38 &&
        e.keyCode !== 39 &&
        e.keyCode !== 40 &&
        e.keyCode !== 8 &&
        e.keyCode !== 46
      ) {
        //onsubmit
        !stepper1 &&
          value.match(
            /^[A-Z]{2}[-][0-9]{1,2}[-\s][A-Z0-9]{1,3}[-\s][0-9]{4}$/
          ) &&
          temp_data?.regNo !== value &&
          setBuffer(true);

        !stepper1 &&
          value.match(
            /^[A-Z]{2}[-][0-9]{1,2}[-\s][A-Z0-9]{1,3}[-\s][0-9]{4}$/
          ) &&
          temp_data?.regNo !== value &&
          onSubmitFastLaneRenewal();
      }
      if (
        middleBlock &&
        value.length === (Number(segmentInfo) === 1 ? 9 : 10) &&
        e.keyCode !== 37 &&
        e.keyCode !== 37 &&
        e.keyCode !== 38 &&
        e.keyCode !== 39 &&
        e.keyCode !== 40 &&
        e.keyCode !== 8 &&
        e.keyCode !== 46
      ) {
        //onsubmit
        !stepper1 &&
          value.match(/^[A-Z]{2}[-][0-9]{1,2}[-\s][0-9]{4}$/) &&
          temp_data?.regNo !== value &&
          setBuffer(true);

        !stepper1 &&
          value.match(/^[A-Z]{2}[-][0-9]{1,2}[-\s][0-9]{4}$/) &&
          temp_data?.regNo !== value &&
          onSubmitFastLaneRenewal();
      }
      /*--x--Middle & Last Block--x--*/
    }
  };
  /*--x--single key--x--*/

  //Validations and key limitations
  const onChangeSingle = (e) => {
    let value = e.target.value.trim().replace(/--/g, "-");
    if (value.length < 3) {
      e.target.value = e.target.value.replace(/[^A-Za-z\s]/gi, "");
    }
    if (value.length === 4) {
      e.target.value =
        Number(value.split("")[3] * 1) || Number(value.split("")[3] * 1) === 0
          ? e.target.value
          : e.target.value.slice(0, -1);
    }
    let middleBlock =
      value &&
      value.length > (Number(segmentInfo) === 1 ? 4 : 5) &&
      (value.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1 ||
        value.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1 === 0);
    if (value.length > 5) {
      //checking the numeric block if middle block is not present
      if (value.length > (Number(segmentInfo) === 1 ? 6 : 7) && middleBlock) {
        e.target.value =
          e.target.value.slice(-1) * 1 || e.target.value.slice(-1) * 1 === 0
            ? e.target.value
            : e.target.value.slice(0, -1);
      }
      //middle block two alphabet check.
      if (
        value.length > (Number(segmentInfo) === 1 ? 5 : 6) &&
        value.length < (Number(segmentInfo) === 1 ? 8 : 9) &&
        !middleBlock
      ) {
        //checking if the middle block's 1st entry is an alphabet
        if (value.length === (Number(segmentInfo) === 1 ? 6 : 7)) {
          e.target.value = e.target.value
            .split("")
            [Number(segmentInfo) === 1 ? 5 : 6].match(/^[a-zA-Z]*$/)
            ? e.target.value
            : e.target.value.slice(0, -1);
        }
        // checking if the middle block's 2nd entry is an alphabet & alphabet
        if (value.length === (Number(segmentInfo) === 1 ? 7 : 8)) {
          e.target.value = e.target.value
            .split("")
            [Number(segmentInfo) === 1 ? 6 : 7].match(/^[a-zA-Z0-9]*$/)
            ? e.target.value
            : e.target.value.slice(0, -1);
        }
      }
      //The last block entries must be numeric
      if (
        !middleBlock &&
        MidsegmentInfo &&
        value.length >
          (Number(segmentInfo) === 1
            ? Number(MidsegmentInfo) === 2
              ? 8
              : 9
            : Number(MidsegmentInfo) === 2
            ? 9
            : 10)
      ) {
        //letter numeric check
        e.target.value =
          e.target.value.slice(-1) * 1 || e.target.value.slice(-1) * 1 === 0
            ? e.target.value
            : e.target.value.slice(0, -1);
      }
    }
  };
  // x  Validations and key limitations x

  //setting maxlength of regFeild & indicating if the middle block is empty.
  let maxlen =
    regIp &&
    segmentInfo &&
    regIp.length > (Number(segmentInfo) === 1 ? 4 : 5) &&
    regIp.split("")[Number(segmentInfo) === 1 ? 5 : 6] * 1
      ? Number(segmentInfo) === 1
        ? "9"
        : "10"
      : Number(segmentInfo) === 1
      ? Number(MidsegmentInfo) === 1
        ? "11"
        : Number(MidsegmentInfo) === 2
        ? "12"
        : "13"
      : Number(MidsegmentInfo) === 1
      ? "12"
      : Number(MidsegmentInfo) === 2
      ? "13"
      : "14";

  //redirected Prefill (Renewbuy)
  useEffect(() => {
    if (reg_no_url && reg_no_url !== "NULL") {
      setValue("regNo", reg_no_url);
    }
    if (policy_no_url && policy_no_url !== "NULL") {
      setValue("policyNo", policy_no_url);
    }
  }, [reg_no_url, policy_no_url]);

  //prefill
  const regIpCheck = watch("regNo") || "";
  useEffect(() => {
    if (temp_data?.regNo && (!regIpCheck || regIpCheck === temp_data?.regNo)) {
      temp_data?.regNo !== "NEW" &&
        setValue(
          "regNo",
          `${temp_data?.regNo1.split("-")[0]}-${
            temp_data?.regNo1.split("-")[0] === "DL" && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
              ? Number(temp_data?.regNo1.split("-")[1])
              : temp_data?.regNo1.split("-")[1]
          }-${temp_data?.regNo2}-${temp_data?.regNo3}`.replace(/--/g, "-")
        );
    }
    if (temp_data?.regNo1) {
      setValue(
        "regNo1",
        `${temp_data?.regNo1.split("-")[0]}-${
          temp_data?.regNo1.split("-")[0] === "DL" && !["GRAM", "SPA"].includes(process.env.REACT_APP_BROKER)
            ? Number(temp_data?.regNo1.split("-")[1])
            : temp_data?.regNo1.split("-")[1]
        }`
      );
    }
    if (temp_data?.regNo2) {
      setValue("regNo2", temp_data?.regNo2);
    }
    if (temp_data?.regNo3) {
      setValue("regNo3", temp_data?.regNo3);
    }
    if (temp_data?.prefillPolicyNumber) {
      setValue("policyNo", temp_data?.prefillPolicyNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp_data]);

  //onSuccess
  useEffect(() => {
    if (saveQuoteData && TypeReturn(type)) {
      setBuffer(false);
      //fast lane redirection
      if (fastLaneRenewalData?.status === 100) {
        fastLaneRenewalData?.RenwalData === "Y" &&
          fastLaneRenewalData?.additional_details.previousPolicyType ===
            "Third Party" &&
          dispatch(TabClick(true));
        dispatch(
          set_temp_data({
            isRenewalRedirection: "Y",
            prefillPolicyNumber: policyNumber,
            //storing data in redux for fastlane / ongrid
            ...(fastLaneRenewalData?.additional_details && {
              policyType:
                fastLaneRenewalData?.additional_details.previousPolicyType,
              productSubTypeId:
                fastLaneRenewalData?.additional_details?.productSubTypeId,
              newCar:
                fastLaneRenewalData?.additional_details?.businessType ===
                "newbusiness",
              breakIn:
                fastLaneRenewalData?.additional_details?.businessType ===
                "breakin",
              prevIc:
                fastLaneRenewalData?.additional_details?.previousInsurerCode,
              odOnly:
                fastLaneRenewalData?.additional_details?.policyType ===
                "own_damage",
            }),
          })
        );
        dispatch(CancelAll(false));
        setTimeout(() => {
          if (
            fastLaneRenewalData?.redirection_data?.is_redirection &&
            fastLaneRenewalData?.redirection_data?.redirection_url
          ) {
            reloadPage(fastLaneRenewalData?.redirection_data?.redirection_url);
          } else {
            //partial mmv logic
            if (!_.isEmpty(fastLaneRenewalData?.results)) {
              let { manfactureId, model, version, vehicleRegisterDate } =
                fastLaneRenewalData?.additional_details;
              //all data fetched
              if (manfactureId && model && version && vehicleRegisterDate) {
                history.push(
                  `/${type}/quotes?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }${journey_type ? `&journey_type=${journey_type}` : ``}`
                );
              }
              // reg date missing
              else if (manfactureId && model && version) {
                history.push(
                  `/${type}/vehicle-details?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }&stepperfill=${Encrypt("date")}${
                    journey_type ? `&journey_type=${journey_type}` : ``
                  }`
                );
              }
              //version missing
              else if (manfactureId && model) {
                history.push(
                  `/${type}/vehicle-details?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }&stepperfill=4${
                    journey_type ? `&journey_type=${journey_type}` : ``
                  }`
                );
              }
              //all data fetched
              else if (manfactureId) {
                history.push(
                  `/${type}/vehicle-details?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }&stepperfill=2${
                    journey_type ? `&journey_type=${journey_type}` : ``
                  }`
                );
              } else {
                history.push(
                  `/${type}/vehicle-details?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }&stepperfill=1${
                    journey_type ? `&journey_type=${journey_type}` : ``
                  }`
                );
              }
            }
          }
        }, 1000);
      }
      //Non fast lane redirection
      else {
        setBuffer(false);
        if (TypeReturn(type) === "cv" && TypeReturn(type)) {
          !typeId &&
            (!_.isEmpty(fastLaneRenewalData)
              ? swal(
                  "Please Note",
                  theme_conf?.broker_config?.fastlane_error_message ||
                    "We are unable to fetch your vehicle details at this moment. Please input your vehicle Make, Model, RTO details and proceed",
                  "info"
                ).then(() => {
                  if (theme_conf?.broker_config?.journey_block) {
                    setValue("regNo", "");
                  } else {
                    history.push(
                      `/${type}/vehicle-type?enquiry_id=${
                        temp_data?.enquiry_id || enquiry_id
                      }${token ? `&token=${token}` : ``}${
                        typeId ? `&typeid=${typeId}` : ``
                      }${journey_type ? `&journey_type=${journey_type}` : ``}`
                    );
                  }
                })
              : history.push(
                  `/${type}/vehicle-type?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }${journey_type ? `&journey_type=${journey_type}` : ``}`
                ));
          typeId &&
            (!_.isEmpty(fastLaneRenewalData)
              ? swal(
                  "Please Note",
                  theme_conf?.broker_config?.fastlane_error_message ||
                    "We are unable to fetch your vehicle details at this moment. Please input your vehicle Make, Model, RTO details and proceed",
                  "info"
                ).then(() => {
                  if (theme_conf?.broker_config?.journey_block) {
                    setValue("regNo", "");
                  } else {
                    history.push(
                      `/${type}/vehicle-type?enquiry_id=${
                        temp_data?.enquiry_id || enquiry_id
                      }${token ? `&token=${token}` : ``}${
                        typeId ? `&typeid=${typeId}` : ``
                      }${journey_type ? `&journey_type=${journey_type}` : ``}`
                    );
                  }
                })
              : history.push(
                  `/${type}/vehicle-type?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }${journey_type ? `&journey_type=${journey_type}` : ``}`
                ));
        } else {
          if (temp_data?.productSubTypeCode && TypeReturn(type)) {
            !_.isEmpty(fastLaneRenewalData)
              ? swal(
                  "Please Note",
                  theme_conf?.broker_config?.fastlane_error_message ||
                    "We are unable to fetch your vehicle details at this moment. Please input your vehicle Make, Model, RTO details and proceed",
                  "info"
                ).then(() => {
                  if (theme_conf?.broker_config?.journey_block) {
                    setValue("regNo", "");
                  } else {
                    history.push(
                      `/${type}/vehicle-details?enquiry_id=${
                        temp_data?.enquiry_id || enquiry_id
                      }${token ? `&token=${token}` : ``}${
                        typeId ? `&typeid=${typeId}` : ``
                      }${journey_type ? `&journey_type=${journey_type}` : ``}`
                    );
                  }
                })
              : history.push(
                  `/${type}/vehicle-details?enquiry_id=${
                    temp_data?.enquiry_id || enquiry_id
                  }${token ? `&token=${token}` : ``}${
                    typeId ? `&typeid=${typeId}` : ``
                  }${journey_type ? `&journey_type=${journey_type}` : ``}`
                );
          }
        }
      }
    }

    return () => {
      dispatch(clear("saveQuoteData"));
      saveQuoteData && dispatch(setFastLaneRenewal(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveQuoteData, temp_data]);

  //policy & business type calc
  //SAOD
  const saod = (regDate) => {
    let b = "01-09-2018";
    let c = regDate;
    let d = moment().format("DD-MM-YYYY");
    let diffMonthsOd = c && b && differenceInMonths(toDate(c), toDate(b));
    let diffDaysOd = c && b && differenceInDays(toDate(c), toDate(b));
    let diffMonthsOdCar = c && d && differenceInMonths(toDate(d), toDate(c));
    let diffDayOd = c && d && differenceInDays(toDate(d), toDate(c));

    return (
      (diffDaysOd >= 0 && diffDayOd > 270 && TypeReturn(type) === "bike") ||
      (diffDayOd > 270 && diffMonthsOdCar < 34 && TypeReturn(type) === "car")
    );
  };

  useEffect(() => {
    if (
      fastLaneRenewalData?.status === 100 &&
      (!_.isEmpty(fastLaneRenewalData?.results) ||
        process.env?.REACT_APP_BROKER === "ACE") &&
      (process.env?.REACT_APP_API_BASE_URL ===
        "https://apimotor.fynity.in/api" ||
        process.env?.REACT_APP_BROKER === "ABIBL" ||
        process.env?.REACT_APP_API_BASE_URL ===
          "https://api-carbike.fynity.in/api" ||
        process.env?.REACT_APP_BROKER === "OLA" ||
        process.env?.REACT_APP_BROKER === "ACE" ||
        process.env?.REACT_APP_BROKER === "PINC" ||
        process.env?.REACT_APP_BROKER === "EPOCH" ||
        process.env?.REACT_APP_BROKER === "BAJAJ" ||
        process.env?.REACT_APP_BROKER === "SPA" ||
        process.env?.REACT_APP_BROKER === "RB" ||
        process.env?.REACT_APP_BROKER === "TATA" ||
        (process.env?.REACT_APP_BROKER === "GRAM" &&
          (TypeReturn(type) === "car" ||
            TypeReturn(type) === "bike" ||
            TypeReturn(type) === "cv")))
    ) {
      let vehicleData =
        !_.isEmpty(fastLaneRenewalData?.results) &&
        fastLaneRenewalData?.results[0]?.vehicle;
      set_temp_data({
        newCar:
          fastLaneRenewalData?.additional_details?.businessType ===
          "newbusiness",
        breakIn:
          fastLaneRenewalData?.additional_details?.businessType === "breakin",
      });
      const quoteData = {
        ...(localStorage?.SSO_user && {
          tokenResp: JSON.parse(localStorage?.SSO_user),
        }),
        enquiryId: temp_data?.enquiry_id || enquiry_id,
        vehicleRegistrationNo: regNo2
          ? `${
              Number(regNo1.split("-")[1]) < 10
                ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
                : regNo1
            }-${regNo2}-${regNo3}`
          : `${
              Number(regNo1.split("-")[1]) < 10
                ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
                : regNo1
            }--${regNo3}`,
        userProductJourneyId: temp_data?.enquiry_id || enquiry_id,
        corpId: temp_data?.corpId,
        userId: temp_data?.userId,
        ...(TypeReturn(type) !== "cv" && {
          productSubTypeId: TypeReturn(type) === "car" ? 1 : 2,
        }), // from api
        fullName: temp_data?.firstName + " " + temp_data?.lastName,
        firstName: temp_data?.firstName,
        lastName: temp_data?.lastName,
        emailId: temp_data?.emailId,
        mobileNo: temp_data?.mobileNo,
        ...(journey_type && {
          journeyType: journey_type,
        }),
        ...(vehicleData && {
          policyType: saod(
            fastLaneRenewalData?.results[0]?.vehicle?.regn_dt
              .split("/")
              .join("-")
          )
            ? "own_damage"
            : "comprehensive",
        }),
        ...(vehicleData && {
          businessType:
            Number(fastLaneRenewalData?.results[0]?.vehicle?.manu_yr) ===
            Number(new Date().getFullYear())
              ? "newbusiness"
              : "rollover",
        }),
        rto:
          Number(regNo1.split("-")[1]) < 10
            ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
            : regNo1,
        ...(vehicleData && {
          manufactureYear:
            `${moment().format("DD-MM-YYYY").split("-")[1]}` -
            `${fastLaneRenewalData?.results[0]?.vehicle?.manu_yr}`,
        }),
        ...(vehicleData && {
          version: fastLaneRenewalData?.results[0]?.vehicle?.vehicle_cd,
        }), // from api
        ...(vehicleData && {
          versionName: fastLaneRenewalData?.results[0]?.vehicle?.fla_variant,
        }), // from api
        vehicleRegisterAt:
          Number(regNo1.split("-")[1]) < 10
            ? `${regNo1.split("-")[0]}-0${Number(regNo1.split("-")[1])}`
            : regNo1,
        vehicleRegisterDate:
          vehicleData?.regn_dt?.split("/").join("-") || "01-10-2016",
        ...(vehicleData && {
          vehicleRegisterDate: fastLaneRenewalData?.results[0]?.vehicle?.regn_dt
            .split("/")
            .join("-"),
        }),
        vehicleOwnerType: "I", // from api
        ...(vehicleData && {
          policyExpiryDate: fastLaneRenewalData?.results[0]?.insurance
            ?.insurance_upto
            ? fastLaneRenewalData?.results[0]?.insurance?.insurance_upto
                .split("/")
                .join("-")
            : moment().format("DD-MM-YYYY"),
        }),
        hasExpired: "no", //from api
        isNcb: "Yes", //from api
        isClaim: "N", //from api
        ...(vehicleData && {
          fuelType:
            fastLaneRenewalData?.results[0]?.vehicle?.fla_fuel_type_desc ||
            "PETROL",
        }),
        vehicleUsage: 2, //from api
        vehicleLpgCngKitValue: "", //from api,
        previousInsurer: temp_data?.prevIcFullName, //from api
        previousInsurerCode: temp_data?.prevIc, //from api
        previousPolicyType: "Comprehensive", //from api
        ...(vehicleData && {
          modelName: fastLaneRenewalData?.results[0]?.vehicle?.fla_model_desc,
          manfactureName:
            fastLaneRenewalData?.results[0]?.vehicle?.fla_maker_desc,
        }),
        ownershipChanged: "N", //from api
        ...(vehicleData && {
          engineNo:
            !_.isEmpty(fastLaneRenewalData?.results) &&
            fastLaneRenewalData?.results[0]?.vehicle?.eng_no,
          chassisNo:
            !_.isEmpty(fastLaneRenewalData?.results) &&
            fastLaneRenewalData?.results[0]?.vehicle?.chasi_no,
          vehicleColor:
            !_.isEmpty(fastLaneRenewalData?.results) &&
            fastLaneRenewalData?.results[0]?.vehicle?.color,
        }),
        leadJourneyEnd: true,
        stage: 11,
        preventKafkaPush: true,
      };
      // quotesFilter state
      (fastLaneRenewalData?.ft_product_code === TypeReturn(type) ||
        !fastLaneRenewalData?.ft_product_code) &&
        dispatch(
          SaveQuoteDataQuotes(
            {
              ...(isPartner === "Y" && { frontendTags: "Y" }),
              isRenewalRedirection: "Y",
              prefillPolicyNumber: policyNumber,
              ..._.pick(
                quoteData,
                _.without(_.keys(quoteData), "policyExpiryDate")
              ),
              ...(fastLaneRenewalData?.additional_details?.policyExpiryDate
                ? differenceInDays(
                    toDate(
                      fastLaneRenewalData?.additional_details?.policyExpiryDate
                    ),
                    toDate(moment().format("DD-MM-YYYY"))
                  ) < 45
                  ? { ...fastLaneRenewalData?.additional_details }
                  : _.pick(
                      fastLaneRenewalData?.additional_details,
                      _.without(
                        _.keys(fastLaneRenewalData?.additional_details),
                        "policyExpiryDate"
                      )
                    )
                : { ...fastLaneRenewalData?.additional_details }),
            },
            fastLaneRenewalData?.RenwalData === "Y"
              ? false
              : process.env.REACT_APP_BROKER === "RB"
          )
        );
      //home state
      (fastLaneRenewalData?.ft_product_code === TypeReturn(type) ||
        !fastLaneRenewalData?.ft_product_code) &&
        dispatch(
          SaveQuoteData({
            ...(isPartner === "Y" && { frontendTags: "Y" }),
            isRenewalRedirection: "Y",
            prefillPolicyNumber: policyNumber,
            ..._.pick(
              quoteData,
              _.without(_.keys(quoteData), "policyExpiryDate")
            ),
            ...(fastLaneRenewalData?.additional_details?.policyExpiryDate
              ? differenceInDays(
                  toDate(
                    fastLaneRenewalData?.additional_details?.policyExpiryDate
                  ),
                  toDate(moment().format("DD-MM-YYYY"))
                ) < 45
                ? { ...fastLaneRenewalData?.additional_details }
                : _.pick(
                    fastLaneRenewalData?.additional_details,
                    _.without(
                      _.keys(fastLaneRenewalData?.additional_details),
                      "policyExpiryDate"
                    )
                  )
              : {
                  ..._.pick(
                    fastLaneRenewalData?.additional_details,
                    _.without(
                      _.keys(fastLaneRenewalData?.additional_details),
                      "policyExpiryDate"
                    )
                  ),
                }),
          })
        );
      dispatch(
        SaveLead({
          enquiryId: temp_data?.enquiry_id || enquiry_id,
          leadStageId: 2,
        })
      );
      dispatch(
        set_temp_data({
          fastlaneRenewalJourney: true,
          fastlaneNcbPopup: true,
        })
      );

      // dispatch(SaveQuoteDataQuotesKey(null));
    } else {
      if (
        (fastLaneRenewalData?.status * 1 === 101 ||
          fastLaneRenewalData?.status * 1 === 108) &&
        !fastLaneRenewalData?.showMessage
      ) {
        onSubmit(regIp ? 1 : 2, "policy");
        console.log("fired_submit");
      } else {
        fastLaneRenewalData?.showMessage &&
          swal("Info", fastLaneRenewalData?.showMessage, "info").then(() => [
            dispatch(setFastLaneRenewal(false)),
            setBuffer(false),
            setbtnDisable(false),
            setValue("regNo", ""),
            setValue("policyNo", ""),
          ]);
      }
    }
  }, [fastLaneRenewalData]);

  //journey mismatch
  useEffect(() => {
    if (
      fastLaneRenewalData?.ft_product_code !== TypeReturn(type) &&
      // TypeReturn(type) !== "cv" &&
      (process.env.REACT_APP_BROKER === "ABIBL" ||
        process.env.REACT_APP_BROKER === "RB" ||
        process.env.REACT_APP_BROKER === "GRAM" ||
        process.env?.REACT_APP_BROKER === "SPA" ||
        process.env?.REACT_APP_BROKER === "PINC" ||
        process.env.REACT_APP_BROKER === "EPOCH" ||
        process.env.REACT_APP_BROKER === "OLA" ||
        process.env?.REACT_APP_BROKER === "BAJAJ" ||
        process.env?.REACT_APP_BROKER === "TATA" ||
        process.env.REACT_APP_BROKER === "ACE") &&
      fastLaneRenewalData?.status !== 101
    ) {
      setBuffer(false);
      setbtnDisable(false);
      setShow(fastLaneRenewalData?.ft_product_code);
    }
  }, [fastLaneRenewalData]);

  //onError
  useEffect(() => {
    if (errorProp) {
      setbtnDisable(false);
      setBuffer(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorProp]);

  /*-----journey change-----*/
  const journeyChange = () => {
    if (frontendurl) {
      if (frontendurl?.car_frontend_url && TypeReturn(show) === "car") {
        reloadPage(
          `${frontendurl?.car_frontend_url}/renewal?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`
        );
      } else if (
        frontendurl?.bike_frontend_url &&
        TypeReturn(show) === "bike"
      ) {
        reloadPage(
          `${frontendurl?.bike_frontend_url}/renewal?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`
        );
      } else if (frontendurl?.cv_frontend_url && TypeReturn(show) === "cv") {
        reloadPage(
          `${frontendurl?.cv_frontend_url}/renewal?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ""
          }`
        );
      }
    } else {
      reloadPage(
        redirection(
          TypeReturn(show),
          process.env.REACT_APP_BROKER,
          process.env.REACT_APP_API_BASE_URL,
          token,
          enquiry_id,
          true
        )
      );
    }
  };
  /*--x--journey change--x--*/

  //Product SubType (only when product category is not CV)
  useEffect(() => {
    if (TypeReturn(type) !== "cv" && TypeReturn(type)) {
      dispatch(Category({ productType: TypeReturn(type) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      <StyledBack className={lessthan767 ? "ml-1 backBtn" : "backBtn"}>
        <BackButton type="button" onClick={back}>
          {!lessthan767 ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                viewBox="0 0 24 24"
              >
                <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
              <text style={{ color: "black" }}>Back</text>
            </>
          ) : (
            <img
              src={`${
                process.env.REACT_APP_BASENAME !== "NA"
                  ? `/${process.env.REACT_APP_BASENAME}`
                  : ""
              }/assets/images/back-button.png`}
              alt="bck"
            />
          )}
        </BackButton>
      </StyledBack>
      <Container>
        <Header>
          <Logo>
            <img
              src={`${
                process.env.REACT_APP_BASENAME !== "NA"
                  ? `/${process.env.REACT_APP_BASENAME}`
                  : ""
              }/assets/images/stop-watch.png`}
              alt="Stop-watch"
              width="100%"
            />
          </Logo>
          <HeaderContent>
            <HeadText>Instant Renewal</HeadText>
            <HeaderBody>
              Renew the policy bought at{" "}
              <ColorText>
                {process.env.REACT_APP_BROKER === "RB" ||
                process.env.REACT_APP_BROKER === "BAJAJ"
                  ? process.env.REACT_APP_TITLE
                  : process.env.REACT_APP_BROKER}
              </ColorText>{" "}
              last year
            </HeaderBody>
          </HeaderContent>
        </Header>
        <HrLine />
        <Body>
          <p
            style={{
              fontWeight: "600",
              marginLeft: lessthan767 ? "19px" : "30px",
            }}
          >
            Renew With Registration Number
          </p>
          <InputContainer>
            <TextInput
              className="inputStyle"
              placeholder="Enter Registration No. (MH-04-AR-7070)"
              type="text"
              name="regNo"
              placeholderColor={"#FFFFF"}
              ref={register}
              maxLength={maxlen}
              disabled={buffer}
              onPaste={(e) => onPaste(e)}
              onKeyUp={(e) => SingleKey(e)}
              onKeyDown={(e) => SingleKey(e)}
              onChange={onChangeSingle}
              onInput={(e) => {
                //keeping i/p blur when -- is replaced & validations are met then refocusing.
                // eslint-disable-next-line no-sparse-arrays
                return [
                  e.target.value?.match(/--/g) &&
                    (e.target.value
                      .replace(/--/g, "-")
                      .match(
                        /^[A-Z]{2}[-][0-9]{1,2}[-\s][A-Z0-9]{1,3}[-\s][0-9]{4}$/
                      ) ||
                      e.target.value
                        .replace(/--/g, "-")
                        .match(/^[A-Z]{2}[-][0-9]{1,2}[-\s][0-9]{4}$/)) &&
                    document.querySelector(`input[name=regNo]`).blur(),
                  ,
                  (e.target.value = e.target.value
                    .replace(/\s/gi, "")
                    .replace(/--/g, "-")
                    .toUpperCase()),
                ];
              }}
            />
            <div className="d-none">
              <TextInput
                lg
                noPadding
                type="text"
                id="regNo1"
                name="regNo1"
                placeholder="MH-04"
                placeholderColor={"#FFFFF"}
                ref={register}
                maxLength={"5"}
                // onKeyUp={handleChange}
                error={
                  (!regNo1 ||
                    regNo1?.length >= 4 ||
                    (regNo1?.length >= 1 &&
                      regNo1?.length <= 2 &&
                      regNo1?.match(/[^A-Za-z\s]/gi))) &&
                  errors?.regNo1
                }
                onInput={(e) =>
                  (e.target.value = e.target.value
                    .replace(/\s/gi, "")
                    .toUpperCase())
                }
              />
            </div>
            <div className="d-none">
              <TextInput
                lg
                noPadding
                type="text"
                id="regNo2"
                name="regNo2"
                placeholder="AR"
                placeholderColor={"#FFFFF"}
                // onKeyUp={handleChange2}
                ref={register}
                error={(!regNo2 || regNo2?.length >= 2) && errors?.regNo2}
                onInput={(e) =>
                  (e.target.value =
                    e.target.value.length < 3
                      ? e.target.value
                          .replace(/[^A-Za-z\s]/gi, "")
                          .toUpperCase()
                      : e.target.value.toUpperCase())
                }
                onChange={(e) =>
                  e.target.value.length === 3
                    ? document.querySelector(`input[name=regNo3]`).focus()
                    : ""
                }
              />
            </div>
            <div className="d-none">
              <TextInput
                lg
                noPadding
                type="text"
                id="regNo3"
                name="regNo3"
                placeholder="7070"
                placeholderColor={"red"}
                ref={register}
                // onKeyUp={handleChange3}
                onChange={(e) =>
                  (e.target.value = e.target.value.toUpperCase().trim())
                }
                // onKeyDown={numOnly}
                maxLength={"4"}
                error={(!regNo3 || regNo3?.length >= 3) && errors?.regNo3}
                onInput={(e) =>
                  e.target.value.length === 4 ? onSubmitFastLaneRenewal() : ""
                }
              />
            </div>
            <Button
              id={"proceedBtn"}
              className="proceedBtnStyle"
              buttonStyle="outline-solid"
              style={
                (regNo1 && regNo3) || !btnDisable
                  ? { ...(lessthan767 && { width: "100%" }) }
                  : {
                      cursor: "not-allowed",
                      ...(lessthan767 && { width: "100%" }),
                    }
              }
              hex1={
                regNo1 && regNo3
                  ? Theme?.Registration?.proceedBtn?.background || "#bdd400"
                  : "#e7e7e7"
              }
              hex2={
                regNo1 && regNo3
                  ? Theme?.Registration?.proceedBtn?.background || "#bdd400"
                  : "#e7e7e7"
              }
              borderRadius={lessthan767 ? "30px" : "5px"}
              disabled={(regNo1 && regNo3 ? false : true) || btnDisable}
              onClick={() => {
                onSubmit(1);
                setbtnDisable(true);
              }}
              height="60px"
              type="submit"
            >
              {!buffer && (
                <text
                  style={{
                    color:
                      regNo1 && regNo3
                        ? Theme?.Registration?.proceedBtn?.color
                          ? Theme?.Registration?.proceedBtn?.color
                          : "black"
                        : "black",
                  }}
                >
                  {"Renew"}
                </text>
              )}
              {buffer && !policyNumber && (
                <>
                  <Spinner
                    variant="light"
                    as="span"
                    animation="grow"
                    size="sm"
                  />
                  <Spinner
                    variant="light"
                    as="span"
                    animation="grow"
                    size="sm"
                    className={"mx-1"}
                  />
                  <Spinner
                    variant="light"
                    as="span"
                    animation="grow"
                    size="sm"
                  />
                </>
              )}
            </Button>
          </InputContainer>
          <MoreContent>
            <ORLine>OR</ORLine>
            <p
              style={{
                fontWeight: "600",
                textAlign: "left",
                marginLeft: lessthan767 ? "19px" : "30px",
              }}
            >
              Renew With Previous Policy Number
            </p>
          </MoreContent>
          <InputContainer>
            <TextInput
              className="inputStyle"
              placeholder="Enter previous policy number"
              type="text"
              name="policyNo"
              placeholderColor={"#FFFFF"}
              ref={register}
              disabled={buffer}
              onChange={(e) => (e.target.value = e.target.value.toUpperCase())}
            />
            <Button
              id={"proceedBtn"}
              className="proceedBtnStyle"
              buttonStyle="outline-solid"
              style={
                policyNumber || !btnDisable
                  ? { ...(lessthan767 && { width: "100%" }) }
                  : {
                      cursor: "not-allowed",
                      ...(lessthan767 && { width: "100%" }),
                    }
              }
              hex1={
                policyNumber
                  ? Theme?.Registration?.proceedBtn?.background || "#bdd400"
                  : "#e7e7e7"
              }
              hex2={
                policyNumber
                  ? Theme?.Registration?.proceedBtn?.background || "#bdd400"
                  : "#e7e7e7"
              }
              borderRadius={lessthan767 ? "30px" : "5px"}
              disabled={(policyNumber ? false : true) || btnDisable}
              onClick={() => {
                // onSubmit(1);
                onSubmitFastLaneRenewal("policy");
                setbtnDisable(true);
              }}
              height="60px"
              type="submit"
            >
              {!buffer && (
                <text
                  style={{
                    color: policyNumber
                      ? Theme?.Registration?.proceedBtn?.color
                        ? Theme?.Registration?.proceedBtn?.color
                        : "black"
                      : "black",
                  }}
                >
                  {"Renew"}
                </text>
              )}
              {buffer && !(regNo1 && regNo3) && (
                <>
                  <Spinner
                    variant="light"
                    as="span"
                    animation="grow"
                    size="sm"
                  />
                  <Spinner
                    variant="light"
                    as="span"
                    animation="grow"
                    size="sm"
                    className={"mx-1"}
                  />
                  <Spinner
                    variant="light"
                    as="span"
                    animation="grow"
                    size="sm"
                  />
                </>
              )}
            </Button>
          </InputContainer>
        </Body>
      </Container>
      {/*--------------------Journey Mismatch Modal-------------------*/}
      <JourneyMismatch
        enquiry_id={enquiry_id}
        show={TypeReturn(show)}
        onHide={() => setShow(false)}
        setValue={setValue}
        journeyChange={journeyChange}
        clearFastLane={() => dispatch(setFastLaneRenewal(false))}
        Renewal
        frontendurl={frontendurl}
      />
      {/*---------------x----Journey Mismatch Modal--------x-----------*/}
      <GlobalStyle disabledBackdrop={false} />
    </>
  );
};

export default RenewalRegistration;

const GlobalStyle = createGlobalStyle`
body {
	.inputStyle{
    margin-right: 10px;
    text-align: center;
  }
  .proceedBtnStyle{
    width: 170px;
  }
   @media (max-width: 767px) {
    .inputStyle{
      margin-right: 0px;
      height: 60px;
      border: 2px solid rgb(210,211,212);
    }
    .proceedBtnStyle{
      margin-top: 15px;
    }
  }
}
`;

const ORLine = styled.h6`
  margin: 36px 100px 24px 100px;
  font-size: 20px;
  overflow: hidden;
  text-align: center;
  :before,
  :after {
    background-color: #000;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  :before {
    right: 0.5em;
    margin-left: -50%;
  }
  :after {
    left: 0.5em;
    margin-right: -50%;
  }
`;

const Container = styled.div`
  width: 600px;
  margin: 50px auto;
  background-color: white;
  // box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
  //   rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
  //   rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  padding-bottom: 80px;
  border-radius: 10px;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
`;

const Logo = styled.div`
  margin-left: 20px;
  width: 76px;
  padding: 10px;
  @media (max-width: 767px) {
    width: 125px;
  }
`;

const HeaderContent = styled.div`
  padding: 10px 10px 0px 10px;
`;

const HeadText = styled.h4`
  font-weight: bold;
`;

const HeaderBody = styled.p`
  color: grey;
`;

const HrLine = styled.hr`
  margin-top: 0 !important;
`;

const Body = styled.div`
  margin-top: 25px;
`;

const MoreContent = styled.div`
  text-align: center;
`;

const InputContainer = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const StyledBack = styled.div`
  padding-bottom: 30px;
  margin-top: -20px;
  z-index: 999;
  ${process.env.REACT_APP_BROKER === "ABIBL"
    ? `@media (max-width: 780px) {
    position: relative;
    top: -120px;
    left: -10%;
  }
  @media (max-width: 769px) {
    position: relative;
    top: -125px;
    left: -11%;
  }
  @media (max-width: 600px) {
    position: relative;
    top: -120px;
    left: -10%;
  }`
    : `@media (max-width: 780px) {
      position: relative;
      top: -73px;
      left: -10%;
    }
    @media (max-width: 769px) {
      position: relative;
      top: -125px;
      left: -11%;
    }
    @media (max-width: 600px) {
      position: relative;
      top: -73px;
      left: -10%;
    }`}
`;
const ColorText = styled.text`
  color: ${({ theme }) => theme.LandingPage?.color || "green"};
  font-weight: 600;
  @media (max-width: 1030px) {
    color: ${({ theme }) => theme.LandingPage?.color3 || "green"};
  }
`;
