/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import editImg from "../../../assets/img/edit.png";
import PolicyTypePopup from "../quotesPopup/policyTypePopup/policyTypePopup";
import IDVPopup from "../quotesPopup/idvPopup/IDVPopup";
import NCBPopup from "../quotesPopup/ncbPopup/NCBPopup";
import VehicleDetails from "../quotesPopup/vehicleDetails/vehicleDetails";
import EditInfoPopup from "../quotesPopup/editDetailsPopup/editDetails";
import EditInfoPopup2 from "../quotesPopup/editDetailsPopup/editDetails2";
import { useHistory } from "react-router-dom";
import Switch from "../../../components/switch/switch";
import Skeleton from "react-loading-skeleton";
import {
  addDays,
  differenceInDays,
  differenceInMonths,
  subMonths,
} from "date-fns";
import moment from "moment";
import {
  set_temp_data,
  Variant,
  getFuelType,
  setRedirectionFlag,
} from "modules/Home/home.slice";
import {
  MultiSelect,
  Error,
  ErrorMsg,
  BackButton,
  Toaster,
  ToasterPolicyChange,
} from "components";
import PrevInsurerPopup from "../quotesPopup/prevInsurerPopup/prevInsurerPopup";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import DateInput from "../../proposal/DateInput";
import { Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useOutsideClick } from "../../../hoc";
import { currencyFormater } from "utils";
import {
  clear,
  NcbList as getNcb,
  PrevInsList as getPrevInsList,
  SaveQuoteData,
  setTempData,
  SaveLead,
} from "./quoteFilter.slice";
import { subYears, getYear, getMonth } from "date-fns";
import {
  clear as clearQuote,
  setQuotesList,
  SaveAddonsData,
} from "../quote.slice";
import { useLocation } from "react-router";
import { toDate } from "utils";
import JourneyCategoryPopup from "../quotesPopup/journeyCategoryPopup/journeyCategoryPopup";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";
import { useMediaPredicate } from "react-media-hook";
import AbiblPopup from "../AbiblPopup/AbiblPopup";
import { TypeReturn } from "modules/type";
import { FiEdit } from "react-icons/fi";
import { ImPencil } from "react-icons/im";

/*---------------date config----------------*/
const policyMax = addDays(new Date(Date.now() - 86400000), 45);
const notSureDate = subMonths(new Date(Date.now()), 9);
const formatedDate = moment(notSureDate).format("DD-MM-YYYY");
/*-----x---------date config-----x----------*/

export const FilterContainer = ({
  reviewData,
  type,
  typeId,
  quote,
  allQuoteloading,
  setPopupOpen,
  isMobileIOS,
  assistedMode,
  showPrevPopUp,
  ConfigNcb,
  policyTypeCode,
}) => {
  const ls = new SecureLS();
  const ThemeLS = ls.get("themeData");
  const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;
  const lessthan993 = useMediaPredicate("(max-width: 993px)");
  const lessthan600 = useMediaPredicate("(max-width: 600px)");
  const lessthan400 = useMediaPredicate("(max-width: 400px)");
  const lessthan360 = useMediaPredicate("(max-width: 360px)");
  const dispatch = useDispatch();
  const { tempData, saveQuote, ncbList, prevInsList } = useSelector(
    (state) => state.quoteFilter
  );
  const { updateQuoteLoader, addonConfig } = useSelector(
    (state) => state.quotes
  );
  const loginData = useSelector((state) => state.login);
  const { handleSubmit, register, watch, control, errors, setValue } = useForm(
    {}
  );

  // console.log("Quote", quote)

  const userData = useSelector((state) => state.home);
  const regDate = watch("regDate");
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const [prevPopup, setPrevPopup] = useState(false);
  const [ncbPopup, setNcbPopup] = useState(false);
  const [idvPopup, setIdvPopup] = useState(false);
  const [policyType, setPolicyType] = useState(tempData?.policyType || false);
  const { prefillLoading, isRedirectionDone } = useSelector(
    (state) => state.home
  );
  const [dateEditor, setDateEditor] = useState(false);
  const [regisDate, setRegisDate] = useState(
    userData.temp_data?.regDate || "01-Apr-2018"
  );
  const [prevNcb, setPrevNcb] = useState(tempData?.ncbValue || "0%");
  const [newCar, setNewCar] = useState(false);
  const [timer, setTimer] = useState(false);
  const [policyPopup, setPolicyPopup] = useState(false);
  const [vehicleDetailsPopup, setVehicleDetailsPopup] = useState(false);
  const [journeyCategoryPopup, setJourneyCategoryPopup] = useState(false);
  const [editInfoPopup, setEditInfoPopup] = useState(false);
  const [editInfoPopup2, setEditInfoPopup2] = useState(false);
  const [editDate, setEditDate] = useState(false);
  const [visualIdv, setVisualIdv] = useState(0);



  //------------setting policy type from home slice to quote filter slice temp data (on reload)-------------------
  useEffect(() => {
    if (userData.temp_data?.policyType) {
      dispatch(
        setTempData({
          policyType: userData.temp_data?.policyType,
        })
      );
    }
  }, [userData.temp_data?.policyType]);

  //setting initial policy type whem state is empty
  useEffect(() => {
    !policyType && setPolicyType(tempData?.policyType);
  }, [tempData?.policyType]);

  setTimeout(() => {
    (addonConfig || !["ACE", "RB"].includes(process.env.REACT_APP_BROKER)) &&
      setTimer(true);
  }, 500);

  //--------------------setting new business logics and data------------------

  useEffect(() => {
    if (location.pathname === `/${type}/quotes` && !prefillLoading) {
      if (userData?.temp_data?.regNo === "NEW") {
        setNewCar(true);
      }
    }
  }, [userData?.temp_data?.regNo]);

  useEffect(() => {
    if (location.pathname === `/${type}/quotes` && !prefillLoading && timer) {
      let b = moment().format("DD-MM-YYYY");
      let c = userData.temp_data?.regDate;
      let diffMonthsRollOver =
        c && b && differenceInMonths(toDate(b), toDate(c));
      if (
        userData.temp_data.journeyType === 3 ||
        diffMonthsRollOver === 0 ||
        (diffMonthsRollOver < 9 && diffMonthsRollOver) ||
        userData.temp_data.regNo === "NEW"
      ) {
        setNewCar(true);
        setPrevPopup(false);
        dispatch(
          set_temp_data({
            newCar: true,
          })
        );
      } else {
        setNewCar(false);
        dispatch(
          set_temp_data({
            newCar: false,
          })
        );
        if (userData.temp_data?.expiry || assistedMode) {
          setPrevPopup(false);
        } else {
          setPrevPopup(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tempData,
    policyType,
    userData.temp_data?.policyType,
    userData.temp_data?.leadJourneyEnd,
    prefillLoading,
    timer,
    userData.temp_data?.regDate,
    userData.temp_data.regNo,
  ]);

  useEffect(() => {
    if (
      prevPopup ||
      ncbPopup ||
      idvPopup ||
      editInfoPopup ||
      editInfoPopup ||
      editDate ||
      policyPopup ||
      vehicleDetailsPopup ||
      journeyCategoryPopup
    ) {
      document.body.style.position = "fixed";
      //  document.body.style.height="100vh"
      document.body.style.overflowY = "hidden";
      document.body.style.width = "100%";
    } else {
      document.body.style.position = "relative";
      document.body.style.height = "auto";
      document.body.style.overflowY = "auto";
    }
  }, [
    prevPopup,
    ncbPopup,
    idvPopup,
    editInfoPopup,
    editInfoPopup2,
    editDate,
    policyPopup,
    vehicleDetailsPopup,
    journeyCategoryPopup,
  ]);

  //-----------------------------	date editor display logic----------------------------

  useEffect(() => {
    if (regDate && location.pathname === `/${type}/quotes`) {
      setRegisDate(regDate);
      dispatch(
        set_temp_data({
          regDate: regDate,
          manfDate: regDate && `${regDate.slice(3)}`,
        })
      );
      setDateEditor(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regDate]);

  //--------------------------------getting ncb values api-------------------------------

  useEffect(() => {
    if (ncbList.length === 0 && location.pathname === `/${type}/quotes`) {
      dispatch(getNcb());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempData?.ncbValue]);

  const [prevList, setPrevList] = useState(true);

  //-----------------------get available fuel types----------------
  // useEffect(() => {
  //   if (userData.temp_data?.productSubTypeId && userData.temp_data?.modelId)
  //     dispatch(
  //       getFuelType({
  //         modelId: userData.temp_data?.modelId,
  //         productSubTypeId: userData.temp_data?.productSubTypeId,
  //       })
  //     );
  // }, [userData.temp_data?.modelId, userData.temp_data?.productSubTypeId]);

  //--------------load Variant Data--------------------------
  // useEffect(() => {
  //   if (userData.temp_data?.modelId && userData.temp_data?.fuel) {
  //     dispatch(
  //       Variant({
  //         productSubTypeId: userData.temp_data?.productSubTypeId,
  //         modelId: userData.temp_data?.modelId,
  //         fuelType: userData.temp_data?.fuel,
  //         LpgCngKitValue: userData.temp_data?.kit_val
  //           ? userData.temp_data?.kit_val
  //           : null,
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userData.temp_data.modelId]);

  //---------------------------getting prev ic api------------------------------------

  useEffect(() => {
    if (
      prevInsList?.length === 0 &&
      prevList &&
      location.pathname === `/${type}/quotes`
    ) {
      dispatch(getPrevInsList());
      setPrevList(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevInsList]);

  //------------------------------------ new buisness ncb logic----------------------------

  useEffect(() => {
    if (location.pathname === `/${type}/quotes`) {
      let b = moment().format("DD-MM-YYYY");
      let c = userData.temp_data?.regDate;
      let diffMonthsRollOver =
        c && b && differenceInMonths(toDate(b), toDate(c));
      if (
        userData.temp_data.journeyType === 3 ||
        diffMonthsRollOver === 0 ||
        (diffMonthsRollOver < 9 && diffMonthsRollOver)
      ) {
        dispatch(
          set_temp_data({
            ncb: "0%",
            expiry: "New",
            noClaimMade: true,
            policyExpired: true,
            prevYearNcb: "0%",
            newNcb: "0%",
            prevIc: "New",
            prevIcFullName: "New",
            newCar: true,
            leadJourneyEnd: true,
          })
        );

        dispatch(
          setTempData({
            policyType: "New",
          })
        );
      } else {
        dispatch(
          setTempData({
            newCar: false,
          })
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempData?.ncbValue, userData.temp_data?.regDate]);

  //-------------------------setting od only-----------------------------

  const [odOnly, setOdOnly] = useState(false);

  useEffect(() => {
    let b = "01-09-2018";
    let c = userData.temp_data?.regDate;
    let d = moment().format("DD-MM-YYYY");
    let diffMonthsOd = c && b && differenceInMonths(toDate(c), toDate(b));
    let diffDaysOd = c && b && differenceInDays(toDate(c), toDate(b));
    let diffMonthsOdCar = c && d && differenceInMonths(toDate(d), toDate(c));
    let diffDayOd = c && d && differenceInDays(toDate(d), toDate(c));

    if (
      ((diffDaysOd >= 0 &&
        diffDayOd > 270 &&
        diffMonthsOdCar < 58 &&
        TypeReturn(type) === "bike") ||
        (diffDayOd > 270 &&
          diffMonthsOdCar < 34 &&
          TypeReturn(type) === "car")) &&
      !(
        tempData?.policyType === "Not sure" ||
        userData?.temp_data?.policyType === "Not sure" ||
        userData?.temp_data?.previousPolicyTypeIdentifier === "Y" ||
        tempData?.previousPolicyTypeIdentifier === "Y"
      )
    ) {
      dispatch(
        set_temp_data({
          odOnly: true,
        })
      );
      setOdOnly(true);
    } else {
      dispatch(
        set_temp_data({
          odOnly: false,
        })
      );
      setOdOnly(false);
    }
  }, [
    userData.temp_data?.regDate,
    userData.temp_data?.policyType,
    userData.temp_data?.previousPolicyTypeIdentifier,
  ]);
  console.log("setOdOnlysetOdOnly", userData?.temp_data?.odOnly);
  //-----------------------------------setJourneyType------------------------------------
  console.log("userData?.temp_data?.odOnly", userData?.temp_data?.odOnly);
  useEffect(() => {
    if (userData.temp_data?.ownerTypeId === 2) {
      dispatch(
        set_temp_data({
          ownerTypeId: 2,
        })
      );
    } else {
      dispatch(
        set_temp_data({
          ownerTypeId: 1,
        })
      );
    }
  }, [userData.temp_data?.ownerTypeId]);

  //------ In case of fastlane/ongrid ~ RB NCB is to be confirmed atleast once----
  //setting flag userData.temp_data?.isNcbConfirmed
  useEffect(() => {
    if (
      !userData.temp_data?.isNcbConfirmed &&
      userData.temp_data?.expiry &&
      userData.temp_data?.corporateVehiclesQuoteRequest?.previousInsurer !==
        "NEW" &&
      !saveQuote &&
      userData?.temp_data.corporateVehiclesQuoteRequest?.journeyType &&
      ["fastlane", "ongrid"].includes(
        userData?.temp_data.corporateVehiclesQuoteRequest?.journeyType
      ) &&
      process.env.REACT_APP_BROKER === "RB"
    ) {
      showPrevPopUp({}, {});
    }
  }, [userData.temp_data?.expiry]);
  //-----------------------initial save quote request data api always calls on quote page load except fastlane-----------------
  useEffect(() => {
    if (
      location.pathname === `/${type}/quotes` &&
      !saveQuote
      // &&
      // ((userData?.temp_data?.corporateVehiclesQuoteRequest?.isRenewal !== "Y" &&
      //   !_.isEmpty(userData?.temp_data)) ||
      //   (userData?.temp_data?.isClaimVerified  === "Y" &&
      //     userData?.temp_data?.corporateVehiclesQuoteRequest?.isRenewal ===
      //       "Y"))
    ) {
      if (
        userData.temp_data &&
        userData.temp_data?.leadJourneyEnd === true &&
        userData.temp_data?.expiry && //mandatory NCB popup condition
        //prev INS popup should always be asked on quote page load through (fastlane/ongrid) ~ RB
        ((((userData?.temp_data.corporateVehiclesQuoteRequest?.journeyType &&
          !["fastlane", "ongrid"].includes(
            userData?.temp_data.corporateVehiclesQuoteRequest?.journeyType
          )) ||
          //No popup needed in ths case unless NCB config is set to true
          !userData?.temp_data.corporateVehiclesQuoteRequest?.journeyType) &&
          process.env.REACT_APP_BROKER === "RB") ||
          process.env.REACT_APP_BROKER !== "RB" ||
          userData.temp_data?.isNcbConfirmed ||
          userData.temp_data?.corporateVehiclesQuoteRequest?.previousInsurer ===
            "NEW")
      ) {
        const quoteData = {
          lsq_stage: "Quote Seen",
          enquiryId: userData.temp_data?.enquiry_id || enquiry_id,
          vehicleRegistrationNo:
            userData.temp_data?.journeyType === 3
              ? "NEW"
              : userData.temp_data?.regNo
              ? userData.temp_data?.regNo
              : Number(
                  userData.temp_data?.regDate?.slice(
                    userData.temp_data?.regDate?.length - 4
                  )
                ) === new Date().getFullYear() && userData.temp_data?.newCar
              ? "NEW"
              : "NULL",
          userProductJourneyId: userData.temp_data?.enquiry_id || enquiry_id,
          corpId: loginData?.corpId || userData.temp_data?.corpId,
          userId: loginData?.userId || userData.temp_data?.userId,
          productSubTypeId: userData?.temp_data?.productSubTypeId,
          fullName:
            userData.temp_data?.firstName + " " + userData.temp_data?.lastName,
          firstName: userData.temp_data?.firstName,
          lastName: userData.temp_data?.lastName,
          emailId: userData.temp_data?.emailId,
          mobileNo: userData.temp_data?.mobileNo,
          //remove and rebuild this logic
          businessType: userData.temp_data?.newCar
            ? "newbusiness"
            : userData.temp_data?.breakIn
            ? "breakin"
            : "rollover",
          // policyType: userData.temp_data?.newCar
          // 	? "newbusiness"
          // 	: userData.temp_data?.breakIn
          // 	? "breakin"
          // 	: odOnly
          // 	? "own_damage"
          // 	: "rollover",
          policyType:
            (userData?.temp_data?.odOnly || odOnly) &&
            tempData?.policyType !== "Not sure"
              ? "own_damage"
              : "comprehensive",
          //------------------
          rto: userData.temp_data?.rtoNumber,
          manfactureId: userData.temp_data?.manfId,
          manufactureYear: userData.temp_data?.manfDate,
          //	motorManufactureYear: userData.temp_data?.manfDate,
          model: userData.temp_data?.modelId,
          version: userData.temp_data?.versionId,
          versionName: userData.temp_data?.versionName,
          vehicleRegisterAt: userData.temp_data?.rtoNumber,
          vehicleRegisterDate: userData.temp_data?.regDate,
          vehicleOwnerType: userData.temp_data?.ownerTypeId === 2 ? "C" : "I",
          policyExpiryDate:
            userData.temp_data?.expiry === "Not Sure" ||
            userData.temp_data?.expiry === "New"
              ? "New"
              : userData.temp_data?.expiry,
          hasExpired: userData.temp_data?.policyExpired ? "yes" : "no",
          isNcb: userData.temp_data?.ncb ? "Yes" : "No",

          isClaim: userData.temp_data?.noClaimMade ? "N" : "Y",
          previousNcb:
            (userData.temp_data?.ncb &&
              userData.temp_data?.ncb?.slice(0, -1)) ||
            0,
          applicableNcb:
            (userData.temp_data?.newNcb &&
              userData.temp_data?.newNcb?.slice(0, -1)) ||
            0,

          fuelType: userData.temp_data?.fuel,
          vehicleUsage: userData.temp_data?.carrierType || 2,

          vehicleLpgCngKitValue: userData.temp_data?.kit_val
            ? userData.temp_data?.kit_val
            : "",

          previousInsurer:
            userData.temp_data?.prevIcFullName !== "NEW"
              ? userData.temp_data?.prevIcFullName === "New"
                ? "NEW"
                : userData.temp_data?.prevIcFullName
              : "NEW",
          previousInsurerCode:
            userData.temp_data?.prevIc !== "NEW"
              ? userData.temp_data?.prevIc === "New"
                ? "NEW"
                : userData.temp_data?.prevIc
              : "NEW",

          previousPolicyType:
            !newCar && !userData.temp_data?.newCar
              ? tempData?.policyType === "New"
                ? "Not sure"
                : tempData?.policyType || userData.temp_data?.policyType
              : "NEW",

          modelName: userData.temp_data?.modelName,
          manfactureName: userData.temp_data?.manfName,
          ownershipChanged: userData.temp_data?.carOwnership ? "Y" : "N",
          leadJourneyEnd: userData.temp_data?.leadJourneyEnd
            ? userData.temp_data?.leadJourneyEnd
            : false,
          isNcbVerified: userData.temp_data?.isNcbVerified === "Y" ? "Y" : "N",
          isClaimVerified: userData.temp_data?.isClaimVerified,
          stage: 11,
          lsq_stage: "Quote Seen",
          isNcbConfirmed: userData.temp_data?.isNcbConfirmed,
          selectedGvw: userData?.temp_data?.selectedGvw,
          defaultGvw: userData?.temp_data?.defaultGvw,
          previousPolicyTypeIdentifier:
            userData?.temp_data?.previousPolicyTypeIdentifier,
          isMultiYearPolicy:
            !_.isEmpty(userData.temp_data?.regDate?.split("-")) &&
            userData.temp_data?.regDate?.split("-")[2] * 1 === 2019 &&
            userData?.temp_data?.isMultiYearPolicy
              ? "Y"
              : "N",
          previousPolicyTypeIdentifierCode: policyTypeCode(),
          // !_.isEmpty(userData.temp_data?.regDate?.split("-")) &&
          // userData.temp_data?.regDate?.split("-")[2] * 1 === 2019 &&
          // userData?.temp_data?.previousPolicyTypeIdentifierCode
          //   ? userData?.temp_data?.previousPolicyTypeIdentifierCode
          //   : null,
        };
        dispatch(SaveQuoteData(quoteData));
        dispatch(
          SaveLead({
            enquiryId: userData.temp_data?.enquiry_id || enquiry_id,
            leadStageId: 2,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userData.temp_data?.leadJourneyEnd,
    userData.temp_data?.expiry,
    userData.temp_data?.isClaimVerified,
    userData.temp_data?.isNcbConfirmed,
  ]);
  console.log("newCarnewCarnewCar", newCar);
  const dateOutRef = useRef(null);

  useOutsideClick(dateOutRef, () => setDateEditor(false));

  //------------------------------- adjust scroll-------------------------------------------

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //----------------------------higlight fastlane  abibl conditions---------------------------
  const [toasterShown, setToasterShown] = useState(true);
  const [callToaster, setCallToaster] = useState(false);

  //---------------ABIBL Popup Changes-------------------------

  const [showAbiblPopup, setShowAbiblPopup] = useState(false);

  useEffect(() => {
    if (
      (userData?.temp_data?.expiry || userData?.temp_data?.newCar) &&
      toasterShown &&
      // !lessthan993 &&
      userData?.temp_data?.fastlaneNcbPopup &&
      // type === "bike" &&
      process.env.REACT_APP_BROKER === "ABIBL" &&
      process.env.REACT_APP_API_BASE_URL !== "https://carprod-abibl.fynity.in"
    ) {
      setTimeout(() => {
        // setCallToaster(true);
        setShowAbiblPopup(true);
      }, 2000);
    }
  }, [userData?.temp_data?.expiry, toasterShown, userData?.temp_data?.newCar]);

  //-----------------------------------------toaster policy change---------------------------------

  const [toasterPolicyChange, setToasterPolicyChange] = useState(false);
  const [policyChangeToast, setPolicyChangeToast] = useState(false);

  useEffect(() => {
    if (toasterPolicyChange && process.env.REACT_APP_BROKER === "GRAM") {
      setPolicyChangeToast(true);
    }
  }, [toasterPolicyChange]);

  //-------------------------------------------getting lowest idv-----------------------------------
  const getLowestIdv = () => {
    let Min = _.minBy(quote, "minIdv");
    return parseInt(Min?.minIdv);
  };

  // checking any popup open or not

  useEffect(() => {
    if (
      idvPopup ||
      editInfoPopup ||
      journeyCategoryPopup ||
      vehicleDetailsPopup ||
      ncbPopup ||
      prevPopup ||
      policyPopup ||
      editInfoPopup2
    ) {
      setPopupOpen(true);
    } else {
      setPopupOpen(false);
    }
  }, [
    idvPopup,
    editInfoPopup,
    journeyCategoryPopup,
    vehicleDetailsPopup,
    ncbPopup,
    prevPopup,
    policyPopup,
    editInfoPopup2,
  ]);

  const prevPolicy = newCar
    ? "N/A"
    : tempData?.policyType
    ? userData?.temp_data?.prevShortTerm * 1
      ? "SHORT TERM"
      : tempData?.policyType.toUpperCase()
    : // userData.temp_data?.odOnly ? "Own Damage"
    tempData?.policyType && tempData?.policyType !== 0
    ? tempData?.policyType?.toUpperCase()
    : "NOT SURE";

  let calculatedOd =
    (((userData.temp_data?.regDate &&
      differenceInDays(
        toDate(userData.temp_data?.regDate),
        toDate(moment().format("01-09-2018"))
      )) >= 0 &&
      (userData.temp_data?.regDate &&
        moment().format("DD-MM-YYYY") &&
        differenceInDays(
          toDate(moment().format("DD-MM-YYYY")),
          toDate(userData.temp_data?.regDate)
        )) > 270 &&
      (userData.temp_data?.regDate &&
        moment().format("DD-MM-YYYY") &&
        differenceInMonths(
          toDate(moment().format("DD-MM-YYYY")),
          toDate(userData.temp_data?.regDate)
        )) < 58 &&
      TypeReturn(type) === "bike") ||
      ((userData.temp_data?.regDate &&
        moment().format("DD-MM-YYYY") &&
        differenceInDays(
          toDate(moment().format("DD-MM-YYYY")),
          toDate(userData.temp_data?.regDate)
        )) > 270 &&
        (userData.temp_data?.regDate &&
          moment().format("DD-MM-YYYY") &&
          differenceInMonths(
            toDate(moment().format("DD-MM-YYYY")),
            toDate(userData.temp_data?.regDate)
          )) < 34 &&
        TypeReturn(type) === "car")) &&
    !(
      tempData?.policyType === "Not sure" ||
      userData?.temp_data?.policyType === "Not sure" ||
      userData?.temp_data?.previousPolicyTypeIdentifier === "Y" ||
      tempData?.previousPolicyTypeIdentifier === "Y"
    );

  const bundledPolicy =
    (calculatedOd ||
      userData?.temp_data?.odOnly ||
      ((userData.temp_data?.regDate &&
        moment().format("DD-MM-YYYY") &&
        differenceInMonths(
          toDate(moment().format("DD-MM-YYYY")),
          toDate(userData.temp_data?.regDate)
        )) >= 34 &&
        (userData.temp_data?.regDate &&
          differenceInDays(
            toDate(userData.temp_data?.regDate),
            toDate(moment().format("01-09-2018"))
          )) >= 0 &&
        (userData.temp_data?.regDate &&
          moment().format("DD-MM-YYYY") &&
          differenceInDays(
            toDate(moment().format("DD-MM-YYYY")),
            toDate(userData.temp_data?.regDate)
          )) > 270 &&
        ((userData.temp_data?.regDate &&
          moment().format("DD-MM-YYYY") &&
          differenceInMonths(
            toDate(moment().format("DD-MM-YYYY")),
            toDate(userData.temp_data?.regDate)
          )) < 36 ||
          ((userData.temp_data?.regDate &&
            moment().format("DD-MM-YYYY") &&
            differenceInMonths(
              toDate(moment().format("DD-MM-YYYY")),
              toDate(userData.temp_data?.regDate)
            )) === 36 &&
            (userData.temp_data?.regDate &&
              moment().format("DD-MM-YYYY") &&
              differenceInDays(
                toDate(moment().format("DD-MM-YYYY")),
                toDate(userData.temp_data?.regDate)
              )) <= 1095)) &&
        type === "car") ||
      ((userData.temp_data?.regDate &&
        moment().format("DD-MM-YYYY") &&
        differenceInMonths(
          toDate(moment().format("DD-MM-YYYY")),
          toDate(userData.temp_data?.regDate)
        )) >= 58 &&
        (userData.temp_data?.regDate &&
          differenceInDays(
            toDate(userData.temp_data?.regDate),
            toDate(moment().format("01-09-2018"))
          )) >= 0 &&
        (userData.temp_data?.regDate &&
          moment().format("DD-MM-YYYY") &&
          differenceInDays(
            toDate(moment().format("DD-MM-YYYY")),
            toDate(userData.temp_data?.regDate)
          )) > 270 &&
        ((userData.temp_data?.regDate &&
          moment().format("DD-MM-YYYY") &&
          differenceInMonths(
            toDate(moment().format("DD-MM-YYYY")),
            toDate(userData.temp_data?.regDate)
          )) < 60 ||
          ((userData.temp_data?.regDate &&
            moment().format("DD-MM-YYYY") &&
            differenceInMonths(
              toDate(moment().format("DD-MM-YYYY")),
              toDate(userData.temp_data?.regDate)
            )) === 60 &&
            (userData.temp_data?.regDate &&
              moment().format("DD-MM-YYYY") &&
              differenceInDays(
                toDate(moment().format("DD-MM-YYYY")),
                toDate(userData.temp_data?.regDate)
              )) <= 1095)) &&
        type === "bike")) &&
    userData?.temp_data?.previousPolicyTypeIdentifier !== "Y" &&
    new Date().getFullYear() -
      Number(
        userData?.temp_data?.regDate?.slice(
          userData?.temp_data?.regDate?.length - 4
        )
      ) >=
      1;

  let inspectionCase =
    (userData?.temp_data?.breakIn ||
      (tempData?.policyType === "Third-party" &&
        userData?.temp_data?.tab !== "tab2")) &&
    TypeReturn(type) !== "bike" &&
    userData?.temp_data?.tab !== "tab2";

  let text =
    tempData?.policyType === "Third-party"
      ? "Third-party"
      : "expired" || "expired";

  let reg1 = userData?.temp_data?.regNo || userData?.temp_data?.rtoNumber;
  let reg2 = userData?.temp_data?.rtoNumber || userData?.temp_data?.regNo;

  return (
    <>
      <Col style={{ ...(!lessthan993 && { display: "none" }) }}>
        <FilterContainerMobile>
          <FilterMobileTop highlighted={showAbiblPopup}>
            <Row>
              <Col
                lg={6}
                md={6}
                sm={6}
                xs="6"
                className={lessthan600 ? "px-0 mx-0" : ""}
              >
                <FilterMobileTopItem
                  mask={true}
                  isMobileIOS={isMobileIOS}
                  // onClick={() => {
                  //   setEditInfoPopup2(true);
                  // }}
                >
                  <span className="rtoNameMobile">
                    {!newCar
                      ? userData?.temp_data?.regNo ||
                        userData?.temp_data?.rtoNumber
                      : userData?.temp_data?.rtoNumber ||
                        userData?.temp_data?.regNo}{" "}
                    {/* <ImPencil
                      className="editImageMobile"
                      onClick={() => {
                        setEditInfoPopup2(true);
                      }}
                    /> */}
                  </span>
                </FilterMobileTopItem>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={6}
                xs="6"
                className={lessthan600 ? "px-0 mx-0" : ""}
              >
                <FilterMobileTopItem
                  isMobileIOS={isMobileIOS}
                  onClick={
                    userData?.temp_data?.corporateVehiclesQuoteRequest
                      ?.isRenewal !== "Y" && !allQuoteloading
                      ? () => {
                          setEditInfoPopup(true);
                          // document.getElementById("regNoId") &&
                          // document.getElementById("regNoId").click()
                        }
                      : () => {}
                  }
                >
                  <span className="rtoNameMobile">REG DATE: </span>

                  <span className="rtoNameMobile">
                    <text className="rtoNameMobile1">
                      {userData?.temp_data?.regDate
                        ? `${userData?.temp_data?.regDate.split("-")[0]}/${
                            userData?.temp_data?.regDate.split("-")[1]
                          }/${userData?.temp_data?.regDate
                            .split("-")[2]
                            .slice(-2)}`
                        : ""}
                    </text>{" "}
                    {userData?.temp_data?.corporateVehiclesQuoteRequest
                      ?.isRenewal !== "Y" && !allQuoteloading ? (
                      <ImPencil
                        className="editImageMobile"
                        onClick={() => setEditInfoPopup(true)}
                      />
                    ) : (
                      <noscript />
                    )}{" "}
                  </span>
                </FilterMobileTopItem>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={6}
                xs="6"
                className={lessthan600 ? "px-0 mx-0" : ""}
              >
                <FilterMobileTopItem
                  mask={true}
                  isMobileIOS={isMobileIOS}
                  onClick={
                    userData?.temp_data?.corporateVehiclesQuoteRequest
                      ?.isRenewal !== "Y"
                      ? () => setEditInfoPopup2(true)
                      : () => {}
                  }
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <span
                    className="rtoNameMobile"
                    style={{ paddingRight: "5px" }}
                  >
                    <>
                      {userData?.temp_data?.manfName}-
                      {userData?.temp_data?.modelName}-
                      {userData?.temp_data?.versionName}{" "}
                    </>
                  </span>
                  {userData?.temp_data?.corporateVehiclesQuoteRequest
                    ?.isRenewal !== "Y" && (
                    <ImPencil
                      className="editImageMobile"
                      onClick={() => setEditInfoPopup2(true)}
                    />
                  )}
                </FilterMobileTopItem>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={6}
                xs="6"
                className={lessthan600 ? "px-0 mx-0" : ""}
              >
                <FilterMobileTopItem
                  isMobileIOS={isMobileIOS}
                  onClick={
                    userData?.temp_data?.corporateVehiclesQuoteRequest
                      ?.isRenewal !== "Y"
                      ? () => {
                          document.getElementById("prevAndEditPopId") &&
                            document.getElementById("prevAndEditPopId") !==
                              undefined &&
                            document.getElementById("prevAndEditPopId").click();
                        }
                      : () => {}
                  }
                >
                  <span className="rtoNameMobile">PREV POLICY EXP:</span>
                  <span className="rtoNameMobile noWrapExpiry">
                    {userData?.temp_data?.currentPolicyType === "newbusiness" ||
                    newCar
                      ? "N/A"
                      : userData?.temp_data?.breakIn
                      ? userData?.temp_data?.expiry === "NEW" ||
                        moment(subMonths(new Date(Date.now()), 9)).format(
                          "DD-MM-YYYY"
                        ) === userData?.temp_data?.expiry
                        ? "N/A"
                        : userData?.temp_data?.expiry &&
                          !_.isEmpty(userData?.temp_data?.expiry.split("-")) &&
                          Number(
                            userData?.temp_data?.expiry.split("-")?.length
                          ) >= 2
                        ? `${userData?.temp_data?.expiry.split("-")[0]}/${
                            userData?.temp_data?.expiry.split("-")[1]
                          }/${userData?.temp_data?.expiry
                            ?.split("-")[2]
                            ?.slice(-2)}`
                        : ""
                      : userData?.temp_data?.expiry &&
                        !_.isEmpty(userData?.temp_data?.expiry.split("-")) &&
                        Number(
                          userData?.temp_data?.expiry.split("-")?.length
                        ) >= 2
                      ? `${userData?.temp_data?.expiry.split("-")[0]}/${
                          userData?.temp_data?.expiry.split("-")[1]
                        }/${userData?.temp_data?.expiry
                          ?.split("-")[2]
                          ?.slice(-2)}` || "NEW"
                      : ""}{" "}
                    <ImPencil
                      style={{
                        visibility: `${
                          !newCar &&
                          tempData?.policyType !== "Not sure" &&
                          userData?.temp_data?.corporateVehiclesQuoteRequest
                            ?.isRenewal !== "Y"
                            ? "visible"
                            : "hidden"
                        }`,
                      }}
                      className="editImageMobile"
                      onClick={() => {
                        setPrevPopup(true);
                        setEditDate(true);
                      }}
                    />
                  </span>
                </FilterMobileTopItem>
              </Col>
            </Row>
          </FilterMobileTop>
          <FilterMobileBottom>
            <FilterMobileBottomItem
              isMobileIOS={isMobileIOS}
              onClick={
                userData?.temp_data?.corporateVehiclesQuoteRequest
                  ?.isRenewal !== "Y"
                  ? () => {
                      document.getElementById("policyPopupId") &&
                        document.getElementById("policyPopupId").click !==
                          undefined &&
                        document.getElementById("policyPopupId").click();
                    }
                  : () => {}
              }
            >
              <div className="caption ">
                {lessthan400 ? "PREV POLICY TYPE" : "PREV POLICY TYPE"}
              </div>
              <div className="selection ">
                <span
                  className="selectionText"
                  style={
                    tempData?.policyType === "Own-damage" ||
                    (!bundledPolicy && userData?.temp_data?.odOnly)
                      ? {}
                      : bundledPolicy
                      ? { fontSize: "10px" }
                      : prevPolicy.toLowerCase() === "comprehensive" &&
                        lessthan400
                      ? { fontSize: "9px" }
                      : {}
                  }
                >
                  {" "}
                  {tempData?.policyType === "Not sure"
                    ? "Not sure".toUpperCase()
                    : tempData?.policyType === "Third-party"
                    ? "Third-party".toUpperCase()
                    : tempData?.policyType === "Own-damage" ||
                      (!bundledPolicy && userData?.temp_data?.odOnly)
                    ? lessthan400
                      ? "OD"
                      : "Own-damage".toUpperCase()
                    : bundledPolicy ||
                      //3+3/ 5+5 condition
                      ((tempData?.isMultiYearPolicy === "Y" ||
                        userData?.temp_data?.isMultiYearPolicy === "Y") &&
                        userData?.temp_data?.regDate &&
                        !_.isEmpty(userData?.temp_data?.regDate.split("-")) &&
                        userData?.temp_data?.regDate.split("-")?.length > 1 &&
                        userData?.temp_data?.regDate.split("-")[2] * 1 ===
                          2019 &&
                        prevPolicy.toLowerCase() === "comprehensive")
                    ? !lessthan600
                      ? "Bundled Policy".toUpperCase()
                      : "BUNDLED"
                    : prevPolicy}{" "}
                </span>
                {userData?.temp_data?.corporateVehiclesQuoteRequest
                  ?.isRenewal !== "Y" &&
                  !newCar && (
                    <i
                      className="fa fa-angle-down arrowDown"
                      aria-hidden="true"
                      fontSize="15px"
                      id="policyPopupId"
                      onClick={() => {
                        setPolicyPopup(true);
                      }}
                    ></i>
                  )}
              </div>
            </FilterMobileBottomItem>

            <FilterMobileBottomItem
              isMobileIOS={isMobileIOS}
              onClick={
                true
                  ? () => {
                      document.getElementById("ncbPopupId") &&
                        document.getElementById("ncbPopupId").click !==
                          undefined &&
                        document.getElementById("ncbPopupId").click();
                    }
                  : () => {}
              }
            >
              <div className="caption ">ELIGIBLE NCB</div>
              <div className="selection ">
                <span className="selectionText">
                  {" "}
                  {userData.temp_data?.newNcb
                    ? userData.temp_data?.tab === "tab2"
                      ? "NA"
                      : userData.temp_data?.newCar
                      ? "0%"
                      : userData?.temp_data?.newNcb
                    : "0%"}{" "}
                </span>
                {location.pathname === `/${type}/quotes` &&
                  userData.temp_data?.tab !== "tab2" &&
                  tempData.policyType !== "Third-party" &&
                  !newCar &&
                  userData?.temp_data?.expiry &&
                  differenceInDays(
                    toDate(moment().format("DD-MM-YYYY")),
                    toDate(userData?.temp_data?.expiry)
                  ) <= 90 && (
                    <i
                      className="fa fa-angle-down arrowDown"
                      aria-hidden="true"
                      fontSize="15px"
                      id="ncbPopupId"
                      onClick={() => setNcbPopup(true)}
                    ></i>
                  )}
              </div>
            </FilterMobileBottomItem>

            <FilterMobileBottomItem
              isMobileIOS={isMobileIOS}
              onClick={() =>
                userData?.temp_data?.tab !== "tab2" && setIdvPopup(true)
              }
            >
              {userData?.temp_data?.isOdDiscountApplicable ||
              userData?.temp_data?.corporateVehiclesQuoteRequest?.isRenewal ===
                "Y" ||
              userData?.temp_data?.tab === "tab2" ? (
                <div className="selection">
                  <span className="selectionText">
                    {userData?.temp_data?.isOdDiscountApplicable ||
                    userData?.temp_data?.corporateVehiclesQuoteRequest
                      ?.isRenewal === "Y" ||
                    userData?.temp_data?.tab === "tab2" ? (
                      userData?.temp_data?.tab !== "tab2" ? (
                        visualIdv !== 0 ? (
                          `₹ ${currencyFormater(visualIdv)}`
                        ) : tempData?.idvChoosed ? (
                          `₹ ${currencyFormater(tempData?.idvChoosed)}`
                        ) : (
                          `₹ ${currencyFormater(getLowestIdv())}`
                        )
                      ) : (
                        "Not Applicable"
                      )
                    ) : (
                      <>
                        SELECT IDV
                        <i
                          className="fa fa-angle-down arrowDown"
                          aria-hidden="true"
                          style={{ fontSize: "18px !important" }}
                        ></i>
                      </>
                    )}
                  </span>
                  {userData?.temp_data?.tab !== "tab2" && (
                    <i
                      className="fa fa-angle-down arrowDown"
                      aria-hidden="true"
                      fontSize="15px"
                      onClick={() =>
                        userData?.temp_data?.tab !== "tab2" && setIdvPopup(true)
                      }
                    ></i>
                  )}
                </div>
              ) : (
                <div className="caption ">
                  SELECT IDV{" "}
                  <i
                    className="fa fa-angle-down arrowDown"
                    aria-hidden="true"
                    fontSize="15px"
                  ></i>
                </div>
              )}
            </FilterMobileBottomItem>
          </FilterMobileBottom>
        </FilterContainerMobile>
        {/* {(userData?.temp_data?.breakIn ||
          (tempData?.policyType === "Third-party" &&
            userData?.temp_data?.tab !== "tab2")) &&
          type !== "bike" &&
          userData?.temp_data?.tab !== "tab2" && (
          
          )} */}
        {inspectionCase && (
          <AlertCoverMobile>
            Vehicle inspection is required as your previous policy is {text}
            {/* {tempData?.policyType === "Third-party" ?
              "Third-party"
            : "expired"} */}
          </AlertCoverMobile>
        )}
      </Col>

      <>
        <FilterContainerMain
          style={{
            ...(lessthan993 && { display: "none" }),
          }}
          scroll={
            scrollPosition >
            (Theme?.QuoteBorderAndFont?.scrollHeight
              ? Theme?.QuoteBorderAndFont?.scrollHeight
              : 78.4)
          }
          highlighted={showAbiblPopup}
        >
          {prefillLoading || updateQuoteLoader ? (
            <FilterMenuWrap>
              <FilterMenuRow>
                <Row style={{ margin: "auto" }}>
                  <Col lg={3} md={12}>
                    <FilterMenuOpenWrap>
                      <FilterMenuOpenTitle>
                        <Skeleton width={200} height={15}></Skeleton>
                      </FilterMenuOpenTitle>
                      <FilterMenuOpenSub>
                        {" "}
                        <Skeleton width={200} height={15}></Skeleton>
                      </FilterMenuOpenSub>
                    </FilterMenuOpenWrap>
                  </Col>

                  <Col lg={3} md={12}>
                    <FilterMenuOpenWrap>
                      <FilterMenuOpenSub>
                        <Skeleton width={100} height={15}></Skeleton>
                        <FilterMenuOpenSubBold
                          style={{ textTransform: "capitalize" }}
                        >
                          <Skeleton width={100} height={15}></Skeleton>
                        </FilterMenuOpenSubBold>
                      </FilterMenuOpenSub>
                      <FilterMenuOpenEdit>
                        <FilterMenuOpenTitle>
                          <Skeleton width={100} height={15}></Skeleton>
                          <FilterMenuOpenSubBold>
                            {" "}
                            <Skeleton width={100} height={15}></Skeleton>
                          </FilterMenuOpenSubBold>
                        </FilterMenuOpenTitle>
                      </FilterMenuOpenEdit>
                    </FilterMenuOpenWrap>
                  </Col>

                  <Col lg={3} md={12}>
                    <FilterMenuOpenWrap>
                      <FilterMenuOpenSub>
                        <Skeleton width={100} height={15}></Skeleton>
                        <FilterMenuOpenSubBold>
                          {" "}
                          <Skeleton width={100} height={15}></Skeleton>
                        </FilterMenuOpenSubBold>
                      </FilterMenuOpenSub>
                      <FilterMenuOpenEdit>
                        <FilterMenuOpenTitle>
                          <Skeleton width={100} height={15}></Skeleton>
                          <FilterMenuOpenSubBold>
                            {" "}
                            <Skeleton width={100} height={15}></Skeleton>
                          </FilterMenuOpenSubBold>
                        </FilterMenuOpenTitle>
                      </FilterMenuOpenEdit>
                    </FilterMenuOpenWrap>
                  </Col>

                  <Col lg={3} md={12}>
                    <FilterMenuOpenWrap>
                      <FilterMenuOpenSub>
                        <Skeleton width={100} height={15}></Skeleton>
                        <FilterMenuOpenSubBold>
                          {" "}
                          <Skeleton width={100} height={15}></Skeleton>
                        </FilterMenuOpenSubBold>
                      </FilterMenuOpenSub>
                      <FilterMenuOpenEdit>
                        <FilterMenuOpenTitle>
                          <Skeleton width={100} height={15}></Skeleton>
                          <FilterMenuOpenSubBold>
                            {" "}
                            <Skeleton width={100} height={15}></Skeleton>
                          </FilterMenuOpenSubBold>
                        </FilterMenuOpenTitle>
                      </FilterMenuOpenEdit>
                    </FilterMenuOpenWrap>
                  </Col>
                </Row>
              </FilterMenuRow>
            </FilterMenuWrap>
          ) : (
            <FilterMenuWrap>
              <ToasterPolicyChange
                callToaster={policyChangeToast}
                setCall={setPolicyChangeToast}
                setToasterShown={setToasterPolicyChange}
                content={"Policy Expiry is assumed as you have not selected it"}
                type={type}
                Theme={Theme}
              />

              <Toaster
                callToaster={callToaster}
                setCall={setCallToaster}
                setToasterShown={setToasterShown}
                content={"Not your vehicle details please edit it"}
                buttonText={"Edit"}
                setEdit={setEditInfoPopup}
                type={type}
                Theme={Theme}
              />
              <FilterMenuRow>
                <Row style={{ margin: "auto" }}>
                  <Col lg={3} md={12}>
                    <FilterMenuOpenWrap highlighted={showAbiblPopup}>
                      <FilterMenuOpenTitle>
                        {location.pathname === `/${type}/review` ? (
                          <span className="mmvTexts">
                            {reviewData?.manfName}-{reviewData?.modelName}{" "}
                            {userData?.temp_data?.versionName}
                          </span>
                        ) : (
                          <span className="mmvTexts">
                            {" "}
                            {userData?.temp_data?.manfName}-
                            {userData?.temp_data?.modelName}-
                            {userData?.temp_data?.versionName}{" "}
                            {/* <VisibilityOutlinedIcon
								onClick={() => setVehicleDetailsPopup(true)}
							/> */}
                          </span>
                        )}
                      </FilterMenuOpenTitle>
                      <FilterMenuOpenSub>
                        {location.pathname === `/${type}/review` ? (
                          <>
                            <span className="subTypeContainer">
                              <span className="subTypeName">
                                {" "}
                                {reviewData?.productSubTypeCode}{" "}
                              </span>{" "}
                              | {reviewData?.fuel} |{reviewData?.regNo}
                            </span>
                          </>
                        ) : (
                          <>
                            {" "}
                            <span
                              onClick={
                                userData?.temp_data
                                  ?.corporateVehiclesQuoteRequest?.isRenewal !==
                                "Y"
                                  ? () => {
                                      setEditInfoPopup2(true);
                                      setShowAbiblPopup(false);
                                      setToasterShown(false);
                                    }
                                  : () => {}
                              }
                            >
                              <span className="subTypeName">
                                {" "}
                                {userData?.temp_data?.productSubTypeCode &&
                                userData?.temp_data?.productSubTypeCode.length >
                                  15
                                  ? userData?.temp_data?.productSubTypeCode.slice(
                                      0,
                                      15
                                    ) + "..."
                                  : userData?.temp_data?.productSubTypeCode}
                                {" | "}
                              </span>
                              <span
                                style={{
                                  marginLeft: "2px",
                                  marginRight: "2px",
                                  marginTop: "2px",
                                }}
                              >
                                {userData?.temp_data?.fuel}
                              </span>{" "}
                              |{" "}
                              {!newCar
                                ? userData?.temp_data?.regNo ||
                                  userData?.temp_data?.rtoNumber
                                : userData?.temp_data?.rtoNumber ||
                                  userData?.temp_data?.regNo}{" "}
                              {/* // ? reg1?.length > 10 ? reg1.slice(0, 10) + "..." : reg1
                                // : reg2?.length > 10 ? reg2.slice(0, 10) + "..." : reg2}{" "} */}
                              <FiEdit
                                className="blueIcon"
                                id="rtoId"
                                style={
                                  userData?.temp_data
                                    ?.corporateVehiclesQuoteRequest
                                    ?.isRenewal === "Y"
                                    ? { visibility: "hidden" }
                                    : {}
                                }
                                // onClick={() => {
                                //   setEditInfoPopup2(true);
                                //   setShowAbiblPopup(false);
                                //   setToasterShown(false);
                                // }}
                              />
                            </span>
                          </>
                        )}{" "}
                        {}
                      </FilterMenuOpenSub>
                    </FilterMenuOpenWrap>
                  </Col>

                  <Col lg={3} md={12}>
                    <FilterMenuOpenWrap>
                      <FilterMenuOpenSub
                        style={{ cursor: "pointer" }}
                        onClick={
                          userData?.temp_data?.corporateVehiclesQuoteRequest
                            ?.isRenewal !== "Y" && !newCar
                            ? () => {
                                document.getElementById("policyPopupId") &&
                                  document.getElementById("policyPopupId")
                                    .click !== undefined &&
                                  document
                                    .getElementById("policyPopupId")
                                    .click();
                              }
                            : () => {}
                        }
                      >
                        PREVIOUS POLICY TYPE:{" "}
                        <FilterMenuOpenSubBold
                          style={{
                            textTransform: "capitalize",
                            pointerEvents: allQuoteloading ? "none" : "",
                          }}
                        >
                          {location.pathname === `/${type}/review` ? (
                            <>{reviewData?.policyType}</>
                          ) : (
                            <>
                              {" "}
                              {/* {bundledPolicy && "Bundled Policy".toUpperCase()} */}
                              {tempData?.policyType === "Not sure"
                                ? "Not sure".toUpperCase()
                                : tempData?.policyType === "Third-party"
                                ? "Third-party".toUpperCase()
                                : tempData?.policyType === "Own-damage" ||
                                  (!bundledPolicy &&
                                    userData?.temp_data?.odOnly)
                                ? "Own-damage".toUpperCase()
                                : bundledPolicy
                                ? "Bundled Policy".toUpperCase()
                                : prevPolicy}
                              {}{" "}
                            </>
                          )}{" "}
                          {!newCar &&
                            location.pathname === `/${type}/quotes` &&
                            userData?.temp_data?.corporateVehiclesQuoteRequest
                              ?.isRenewal !== "Y" && (
                              <FiEdit
                                onClick={() => {
                                  setPolicyPopup(true);
                                }}
                                id="policyPopupId"
                                className="blueIcon"
                              />
                            )}
                        </FilterMenuOpenSubBold>
                      </FilterMenuOpenSub>
                      <FilterMenuOpenEdit>
                        <FilterMenuOpenTitle
                          onClick={
                            userData?.temp_data?.corporateVehiclesQuoteRequest
                              ?.isRenewal !== "Y"
                              ? () => {
                                  setJourneyCategoryPopup(true);
                                }
                              : () => {}
                          }
                        >
                          OWNERSHIP:
                          <FilterMenuOpenSubBold>
                            {" "}
                            {location.pathname === `/${type}/quotes` && (
                              <span>
                                {userData?.temp_data?.ownerTypeId === 2
                                  ? "COMPANY "
                                  : "INDIVIDUAL "}

                                {userData?.temp_data
                                  ?.corporateVehiclesQuoteRequest?.isRenewal !==
                                  "Y" && (
                                  <FiEdit
                                    className="blueIcon"
                                    onClick={() => {
                                      setJourneyCategoryPopup(true);
                                    }}
                                  />
                                )}
                              </span>
                            )}{" "}
                            {location.pathname === `/${type}/review` && (
                              <>
                                {reviewData?.ownerTypeId === 2
                                  ? "COMPANY"
                                  : "INDIVIDUAL"}
                              </>
                            )}
                          </FilterMenuOpenSubBold>
                        </FilterMenuOpenTitle>
                      </FilterMenuOpenEdit>
                    </FilterMenuOpenWrap>
                  </Col>

                  <Col lg={3} md={12}>
                    <FilterMenuOpenWrap>
                      <FilterMenuOpenSub
                        style={{ cursor: "pointer" }}
                        onClick={
                          userData?.temp_data?.corporateVehiclesQuoteRequest
                            ?.isRenewal !== "Y"
                            ? () => {
                                document.getElementById("prevAndEditPopId") &&
                                  document.getElementById("prevAndEditPopId")
                                    .click !== undefined &&
                                  document
                                    .getElementById("prevAndEditPopId")
                                    .click();
                              }
                            : () => {}
                        }
                      >
                        PREVIOUS POLICY EXPIRY:{" "}
                        <FilterMenuOpenSubBold>
                          {" "}
                          {location.pathname === `/${type}/review` ? (
                            <>
                              {reviewData?.currentPolicyType === "newbusiness"
                                ? "N/A"
                                : reviewData?.expiry}
                            </>
                          ) : (
                            <>
                              {userData?.temp_data?.currentPolicyType ===
                                "newbusiness" || newCar
                                ? "N/A"
                                : userData?.temp_data?.breakIn
                                ? userData?.temp_data?.expiry === "New" ||
                                  moment(
                                    subMonths(new Date(Date.now()), 9)
                                  ).format("DD-MM-YYYY") ===
                                    userData?.temp_data?.expiry
                                  ? "N/A"
                                  : userData?.temp_data?.expiry
                                : userData?.temp_data?.expiry || "N/A"}{" "}
                              {!newCar &&
                                tempData?.policyType !== "Not sure" &&
                                userData?.temp_data
                                  ?.corporateVehiclesQuoteRequest?.isRenewal !==
                                  "Y" && (
                                  <FiEdit
                                    className="blueIcon"
                                    onClick={() => {
                                      setPrevPopup(true);
                                      setEditDate(true);
                                    }}
                                    id="prevAndEditPopId"
                                  />
                                )}
                            </>
                          )}{" "}
                        </FilterMenuOpenSubBold>
                      </FilterMenuOpenSub>
                      <FilterMenuOpenEdit>
                        <FilterMenuOpenTitle
                          onClick={
                            userData?.temp_data?.corporateVehiclesQuoteRequest
                              ?.isRenewal !== "Y"
                              ? () => {
                                  document.getElementById("regNoId") &&
                                    document.getElementById("regNoId").click !==
                                      undefined &&
                                    document.getElementById("regNoId").click();
                                }
                              : () => {}
                          }
                        >
                          REGISTERED ON:{" "}
                          <FilterMenuOpenSubBold>
                            {location.pathname === `/${type}/review` ? (
                              <>{reviewData?.regDate} </>
                            ) : (
                              <span>{userData?.temp_data?.regDate} </span>
                            )}
                            {location.pathname === `/${type}/quotes` &&
                              userData?.temp_data?.corporateVehiclesQuoteRequest
                                ?.isRenewal !== "Y" && (
                                <FiEdit
                                  className="blueIcon"
                                  onClick={() => setEditInfoPopup(true)}
                                  id="regNoId"
                                />
                              )}
                            {dateEditor && (
                              <div
                                className="py-2 dateTimeOne"
                                style={{
                                  position: "relative",
                                  bottom: "33px",
                                }}
                                ref={dateOutRef}
                              >
                                <Controller
                                  control={control}
                                  name="regDate"
                                  render={({
                                    onChange,
                                    onBlur,
                                    value,
                                    name,
                                  }) => (
                                    <DateInput
                                      filterDate
                                      autoFocus={true}
                                      maxDate={policyMax}
                                      minDate={subYears(
                                        new Date(Date.now() - 86400000),
                                        15
                                      )}
                                      value={value}
                                      name={name}
                                      onChange={onChange}
                                      ref={register}
                                    />
                                  )}
                                />
                                {!!errors.regDate && (
                                  <ErrorMsg fontSize={"12px"}>
                                    {errors.regDate.message}
                                  </ErrorMsg>
                                )}
                              </div>
                            )}
                          </FilterMenuOpenSubBold>
                        </FilterMenuOpenTitle>
                      </FilterMenuOpenEdit>
                    </FilterMenuOpenWrap>
                  </Col>

                  <Col lg={3} md={12}>
                    <FilterMenuOpenWrap>
                      <FilterMenuOpenSub
                        onClick={() =>
                          document.getElementById("ncbPopupId") &&
                          document.getElementById("ncbPopupId").click !==
                            undefined &&
                          document.getElementById("ncbPopupId").click()
                        }
                      >
                        PREVIOUS NCB
                        <span style={{ fontSize: "10px" }}>
                          {userData.temp_data?.isNcbVerified !== "Y"
                            ? "(Assumed)"
                            : ""}
                          :{" "}
                        </span>
                        <FilterMenuOpenSubBold>
                          {location.pathname === `/${type}/review` ? (
                            <>
                              <b>{reviewData?.ncb}</b>{" "}
                            </>
                          ) : (
                            <>
                              <b>
                                {userData.temp_data?.newCar ||
                                userData?.temp_data?.expiry === "New" ||
                                moment(
                                  subMonths(new Date(Date.now()), 9)
                                ).format("DD-MM-YYYY") ===
                                  userData?.temp_data?.expiry ||
                                (userData?.temp_data?.expiry &&
                                  differenceInDays(
                                    toDate(moment().format("DD-MM-YYYY")),
                                    toDate(userData?.temp_data?.expiry)
                                  ) > 90)
                                  ? "N/A"
                                  : userData.temp_data?.tab === "tab2"
                                  ? "0%"
                                  : userData.temp_data?.ncb || "0%"}
                              </b>{" "}
                            </>
                          )}

                          {location.pathname === `/${type}/quotes` &&
                            userData.temp_data?.tab !== "tab2" &&
                            tempData.policyType !== "Third-party" &&
                            !newCar &&
                            userData?.temp_data?.expiry && (
                              // differenceInDays(
                              //   toDate(moment().format("DD-MM-YYYY")),
                              //   toDate(userData?.temp_data?.expiry)
                              // ) <= 90
                              // &&
                              <FiEdit
                                className="blueIcon"
                                onClick={() => setNcbPopup(true)}
                                id="ncbPopupId"
                              />
                            )}
                        </FilterMenuOpenSubBold>
                      </FilterMenuOpenSub>
                      <FilterMenuOpenEdit>
                        <FilterMenuOpenTitle>
                          NEW NCB:{" "}
                          <FilterMenuOpenSubBold>
                            {location.pathname === `/${type}/review` ? (
                              <>
                                {" "}
                                {reviewData?.newNcb ? reviewData?.newNcb : "0%"}
                              </>
                            ) : (
                              <>
                                {" "}
                                {userData.temp_data?.newNcb
                                  ? userData.temp_data?.tab === "tab2" ||
                                    (userData?.temp_data?.expiry &&
                                      differenceInDays(
                                        toDate(moment().format("DD-MM-YYYY")),
                                        toDate(userData?.temp_data?.expiry)
                                      ) > 90)
                                    ? "N/A"
                                    : userData.temp_data?.newCar
                                    ? "0%"
                                    : userData?.temp_data?.newNcb
                                  : "0%"}
                              </>
                            )}
                          </FilterMenuOpenSubBold>
                        </FilterMenuOpenTitle>
                      </FilterMenuOpenEdit>
                    </FilterMenuOpenWrap>
                  </Col>
                </Row>
              </FilterMenuRow>
            </FilterMenuWrap>
          )}
        </FilterContainerMain>
        {(userData?.temp_data?.breakIn ||
          (tempData?.policyType === "Third-party" &&
            userData?.temp_data?.tab !== "tab2")) &&
          TypeReturn(type) !== "bike" &&
          userData?.temp_data?.tab !== "tab2" && (
            <AlertCover
              scroll={
                scrollPosition >
                (Theme?.QuoteBorderAndFont?.scrollHeight
                  ? Theme?.QuoteBorderAndFont?.scrollHeight
                  : 78.4)
              }
            >
              {" "}
              Vehicle inspection is required as your previous policy is{" "}
              {tempData.policyType === "Not sure"
                ? "not available"
                : tempData?.policyType === "Third-party"
                ? //&&		userData?.temp_data?.tab !== "tab2"
                  "Third-party"
                : "expired"}
            </AlertCover>
          )}
        {policyPopup && (
          <PolicyTypePopup
            setPolicy={setPolicyType}
            policyType={policyType}
            show={policyPopup}
            onClose={setPolicyPopup}
            setPreviousPopup={setPrevPopup}
            type={TypeReturn(type)}
            setToasterPolicyChange={setToasterPolicyChange}
          />
        )}

        {prevPopup && (
          <PrevInsurerPopup
            show={prevPopup}
            onClose={setPrevPopup}
            edit={editDate}
            setEdit={setEditDate}
            type={TypeReturn(type)}
            showPrevPopUp={showPrevPopUp}
            assistedMode={assistedMode}
            ConfigNcb={ConfigNcb}
          />
        )}
        {ncbPopup && (
          <NCBPopup show={ncbPopup} setNcb={setPrevNcb} onClose={setNcbPopup} />
        )}
        {vehicleDetailsPopup && (
          <VehicleDetails
            show={vehicleDetailsPopup}
            setNcb={setVehicleDetailsPopup}
            onClose={setVehicleDetailsPopup}
          />
        )}
        {journeyCategoryPopup && (
          <JourneyCategoryPopup
            show={journeyCategoryPopup}
            onClose={setJourneyCategoryPopup}
          />
        )}
        {editInfoPopup && (
          <EditInfoPopup
            show={editInfoPopup}
            onClose={setEditInfoPopup}
            type={type}
            TypeReturn={TypeReturn}
          />
        )}
        {editInfoPopup2 && (
          <EditInfoPopup2
            show={editInfoPopup2}
            onClose={setEditInfoPopup2}
            type={type}
            TypeReturn={TypeReturn}
          />
        )}
        {idvPopup && (
          <IDVPopup
            show={idvPopup}
            onClose={setIdvPopup}
            quote={quote}
            visualIdv={visualIdv}
          />
        )}
        {showAbiblPopup && (
          <AbiblPopup
            type={type}
            typeId={typeId}
            token={token}
            enquiryId={enquiry_id}
            show={showAbiblPopup}
            setShow={setShowAbiblPopup}
            editPopUp={editInfoPopup}
            setEditPopUp={setEditInfoPopup}
            setToasterShown={setToasterShown}
          />
        )}
      </>
    </>
  );
};

export const Filters = ({
  setSortBy,
  quote,
  gstToggle,
  setGstToggle,
  setDaysToExpiry,
  allQuoteloading,
  setPopupOpen,
}) => {
  const dispatch = useDispatch();
  const lessthan993 = useMediaPredicate("(max-width: 993px)");
  const lessthan600 = useMediaPredicate("(max-width: 600px)");
  const { temp_data, prefillLoading, isRedirectionDone } = useSelector(
    (state) => state.home
  );
  const { updateQuoteLoader } = useSelector((state) => state.quotes);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  //----getting IDV Values ----//

  const getAverageIdv = () => {
    let filteredQuote = quote?.map((item) =>
      Number(item?.idv) ? Number(item?.idv) : 0
    );
    let newFilterQuote = filteredQuote.filter((cv) => cv != 0);

    let Avg = _.meanBy(newFilterQuote);

    return parseInt(Avg);
  };

  const getLowestIdv = () => {
    let Min = _.minBy(quote, "idv");
    return parseInt(Min?.idv);
  };

  const getHighestIdv = () => {
    let filteredQuote = quote?.map((item) =>
      Number(item?.maxIdv) ? Number(item?.maxIdv) : 0
    );
    let newFilterQuote = filteredQuote.filter((cv) => cv != 0);

    let Avg = _.meanBy(newFilterQuote);

    return parseInt(Avg);
    // let Max = _.maxBy(quote, "maxIdv");
    // return parseInt(Max?.maxIdv);
  };
  console.log(quote, "quotes");
  console.log(getLowestIdv(), "lowest idv");
  useEffect(() => {
    !_.isEmpty(quote) &&
      dispatch(
        setTempData({
          minIdv: getLowestIdv(),
        })
      );
  }, [quote]);

  //Renewbuy IDV - POS Eligibility
  // useEffect(() => {
  //   if (
  //     process.env.REACT_APP_BROKER === "RB" &&
  //     temp_data?.isRedirectionDone &&
  //     temp_data?.isRedirectionDone !== "Y" &&
  //     isRedirectionDone === "N" &&
  //     getHighestIdv() &&
  //     getHighestIdv() * 1 > 5000000 &&
  //     token
  //   )
  //     swal(
  //       "Please Note",
  //       "Dear Partner, IDV greater than ₹ 50 Lakhs sum-insured is above the eligiblity limit and is a Non-POS product.It can be purchased by the customer directly through our website.Please wait while we redirect the customer to the RenewBuy website.",
  //       "info"
  //     ).then(() => {
  //       dispatch(setRedirectionFlag());
  //     });
  // }, [quote]);

  const { tempData } = useSelector((state) => state.quoteFilter);
  const [idvPopup, setIdvPopup] = useState(false);
  const { handleSubmit, register, watch, control, errors, setValue } = useForm(
    {}
  );

  // not relevant now
  const sortBy = watch("sory-by");

  useEffect(() => {
    setSortBy(sortBy?.id);
    dispatch(
      setTempData({
        sortBy: sortBy?.id,
      })
    );
  }, [sortBy]);

  //--------------------prefill idv---------------------------------------
  useEffect(() => {
    if (temp_data?.isIdvChanged) {
      dispatch(
        setTempData({
          idvChoosed: temp_data?.vehicleIdv,
          idvType: temp_data?.vehicleIdvType,
        })
      );
    }
  }, [
    temp_data?.vehicleIdv,
    temp_data?.vehicleIdvType,
    temp_data?.isIdvChanged,
  ]);

  // useEffect(() => {
  //   if (temp_data?.isOdDiscountApplicable) {
  //     dispatch(
  //       setTempData({
  //         idvChoosed: temp_data?.vehicleIdv,
  //         idvType: temp_data?.vehicleIdvType,
  //       })
  //     );
  //   }
  // }, [temp_data?.isOdDiscountApplicable,]);

  const [visualIdv, setVisualIdv] = useState(0);

  //-----------------------checking days to expiry----------------------------

  useEffect(() => {
    let b = moment().format("DD-MM-YYYY");
    let c = temp_data?.expiry;
    let diffDaysExpiry = c && b && differenceInDays(toDate(c), toDate(b));
    if (Number(diffDaysExpiry) > 0 && Number(diffDaysExpiry) < 30) {
      setDaysToExpiry(diffDaysExpiry);
    } else {
      setDaysToExpiry(false);
    }
  }, [temp_data?.expiry]);

  // checking any popup open or not

  useEffect(() => {
    if (idvPopup) {
      setPopupOpen(true);
    } else {
      setPopupOpen(false);
    }
  }, [idvPopup]);

  return (
    <FilterCont>
      <Row
        className="d-flex justify-content-end filterPadding "
        //	style={{ padding: "0px 3% 0px 0px" }}
      >
        <Col sm={12} xl={3} lg={3} md={12}></Col>
        <Col sm={12} xl={3} lg={3} md={12}></Col>
        <Col sm={12} xl={3} lg={3} md={12}>
          {prefillLoading || updateQuoteLoader ? (
            <FilterMenuQuoteBoxWrap
              exp={true}
              onClick={() => temp_data?.tab !== "tab2" && setIdvPopup(true)}
              style={{ position: "relative", bottom: "6px" }}
            >
              <Skeleton
                width={236}
                height={30}
                //	style={{ display: lessthan993 ? "none" : "inline-block" }}
              ></Skeleton>
            </FilterMenuQuoteBoxWrap>
          ) : (
            <FilterMenuQuoteBoxWrap
              exp={true}
              style={{
                ...(lessthan993 && { display: "none" }),
                pointerEvents: allQuoteloading ? "none" : "",
              }}
              onClick={() => temp_data?.tab !== "tab2" && setIdvPopup(true)}
            >
              <FilterTopBoxChange>
                {temp_data?.tab !== "tab2" && <FiEdit className="blueIcon" />}
              </FilterTopBoxChange>
              {/* IDV changes  */}
              {temp_data?.isOdDiscountApplicable ||
              temp_data?.corporateVehiclesQuoteRequest?.isRenewal === "Y" ||
              temp_data?.tab === "tab2" ? (
                <FilterTopBoxTitle>
                  {Number(tempData?.idvChoosed) !== getLowestIdv()
                    ? temp_data?.tab === "tab2"
                      ? "IDV"
                      : tempData?.idvType === "highIdv"
                      ? "Highest IDV"
                      : "IDV"
                    : tempData?.idvType === "lowIdv"
                    ? "Lowest IDV"
                    : "IDV"}{" "}
                  :{" "}
                  <span>
                    {" "}
                    {temp_data?.isOdDiscountApplicable ||
                    temp_data?.corporateVehiclesQuoteRequest?.isRenewal ===
                      "Y" ||
                    temp_data?.tab === "tab2"
                      ? temp_data?.tab !== "tab2"
                        ? visualIdv !== 0
                          ? `₹ ${currencyFormater(visualIdv)}`
                          : tempData?.idvChoosed
                          ? `₹ ${currencyFormater(tempData?.idvChoosed)}`
                          : `₹ ${currencyFormater(getLowestIdv())}`
                        : "Not Applicable"
                      : "Choose your IDV"}
                  </span>
                </FilterTopBoxTitle>
              ) : (
                <FilterTopBoxTitle>Choose your IDV</FilterTopBoxTitle>
              )}
            </FilterMenuQuoteBoxWrap>
          )}
        </Col>

        <Col sm={12} xl={2} lg={2} md={12}>
          {prefillLoading || updateQuoteLoader ? (
            <Skeleton
              width={134}
              height={30}
              style={{ display: lessthan993 ? "none" : "inline-block" }}
            ></Skeleton>
          ) : (
            <Switch
              lessthan600={lessthan600}
              value={gstToggle}
              onChange={setGstToggle}
              Content="GST"
            />
          )}
        </Col>
      </Row>
      {idvPopup && (
        <IDVPopup
          show={idvPopup}
          onClose={setIdvPopup}
          quote={quote}
          visualIdv={visualIdv}
        />
      )}
    </FilterCont>
  );
};

const FilterContainerMain = styled.div`
  //border-bottom: solid 1px #bebebe;
  margin-bottom: 16px;
  height: auto;
  display: flex;
  align-items: end;
  justify-content: center;
  position: ${(props) => (props.scroll ? "fixed" : "absolute")};
  top: ${({ theme, scroll }) =>
    scroll ? "0px" : theme?.QuoteBorderAndFont?.headerTopQuotesPage || "60px"};

  background: white;
  width: 100vw;
  left: 1px;
  overflow-x: clip;
  box-shadow: ${({ theme, scroll, highlighted }) =>
    process.env.REACT_APP_BROKER === "TATA"
      ? "none"
      : highlighted
      ? "none"
      : scroll
      ? " 0 9px 13px #f7f7fa"
      : theme?.QuoteBorderAndFont?.filterShadow || "0 9px 13px #f7f7fa"};

  z-index: ${(props) => (props.highlighted ? "9999999" : "1000")};

  @media (max-width: 993px) {
    width: 100%;
    position: relative !important;
    top: 0px;
  }
  .blueIcon {
    max-height: 17px !important;
    font-size: 17px !important;
    margin-left: 0px !important;
    color: ${({ theme }) =>
      theme?.FilterConatiner?.editIconColor
        ? theme?.FilterConatiner?.editIconColor
        : "none"};
  }
`;
const FilterMenuWrap = styled.div`
  margin: 10px 0px 0px 20px;

  padding: 8px 0;
  font-family: ${({ theme }) =>
    theme.QuoteBorderAndFont?.fontFamily || "Inter-Regular"};
  color: #333;
  @media (max-width: 992px) {
    margin: 10px 20px 0px 20px;
  }
`;

const FilterMenuRow = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 23px;
  text-transform: capitalize;
  &:last-child {
    margin-bottom: 0;
    margin-top: -10px;
    margin-left: 80px;
    @media (max-width: 992px) {
      margin-left: 0px;
    }
  }
`;

const FilterMenuOpenWrap = styled.div`
  padding-left: ${(props) => (props.highlighted ? "10px" : "")};
  &:nth-child(2) {
    width: 291px;
    padding-left: 26px;
    margin-right: 16px;
  }
  float: left;
  position: relative;
  padding-bottom: 10px;
  margin-top: 8px;

  z-index: ${(props) => (props.highlighted ? "5000000000 !important" : "")};
  background-color: ${(props) => (props.highlighted ? " white" : "")};
  padding-top: ${(props) => (props.highlighted ? "10px" : "")};

  @media (max-width: 992px) {
    display: flex !important;
    min-height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap !important;
  }
`;
//

const FilterMenuOpenEdit = styled.div`
  position: absolute;
  top: 25px;
  line-height: 15px;
  padding: 2px 0px;
  cursor: pointer;
  color: #000000;
  font-weight: 600;
  font-size: 12 px;
  line-height: 17px;
  color: ${({ theme }) => theme.regularFont?.textColor || "#707070"};
  min-width: 250px;

  @media (max-width: 992px) {
    margin-bottom: 15px;
    min-width: auto;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 12px !important;
  }
`;

const FilterMenuOpenTitle = styled.div`
  float: left;
  // width: 100%;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 8px;
  padding-right: 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
  user-select: none;
  // cursor: pointer;

  @media (max-width: 992px) {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 10px !important;
  }
  .mmvTexts {
    font-weight: 600;
    float: left;
    // font-size: 12px;
    line-height: 17px;
    color: ${({ theme }) => theme.regularFont?.textColor || "#707070"};
  }
`;

const FilterMenuOpenSub = styled.div`
  color: #000000;
  font-weight: 600;
  float: left;
  // width: 100%;
  font-size: 12px;
  line-height: 17px;
  color: ${({ theme }) => theme.regularFont?.textColor || "#707070"};
  min-width: 250px;
  user-select: none;
  cursor: pointer;

  @media (max-width: 992px) {
    margin-bottom: 15px;
    min-width: auto;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 10px !important;
  }
  .subTypeName {
    max-width: 90px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .subTypeContainer {
    display: flex;
    top: 3px;
    position: relative;
    cursor: pointer;
    @media only screen and (max-width: 993px) {
      top: 0px;
    }
  }
`;

const FilterMenuOpenSubBold = styled.span`
  color: #000000;
  font-weight: 600;
  cursor: pointer;
  :hover {
    color: ${({ theme }) =>
      theme?.FilterConatiner?.color &&
      (theme?.FilterConatiner?.color || "#bdd400")};
  }
`;

const FilterMenuQuoteBoxWrap = styled.button`
  width: 85%;

  margin-left: -75px;
  background: ${(props) => (props?.compare ? "#bdd400" : "#fff")};
  border-radius: 8px;
  padding-top: 10px;
  line-height: 27px;

  border-bottom: 2px solid #e0e0e0;
  border-bottom: 2px solid #e0e0e0;
  border: ${(props) =>
    props.exp ? "0px solid #e0e0e0;" : "1px solid #e0e0e0;"};

  float: right;
  position: relative;
  left: ${(props) => (props.exp ? "" : "")};

  padding: 5px 16px 7px;
  z-index: 2;
  @media only screen and (max-width: 992px) {
    width: 90%;
    margin: 10px 30px;
    float: initial;
  }
`;

const FilterTopBoxChange = styled.div`
  float: right;
  font-size: 12px;
  line-height: 15px;
  color: #000;
  border-radius: 4px;
  padding: 6px 6px;
  cursor: pointer;
  margin-right: -5px;
`;

const FilterTopBoxTitle = styled.div`
  font-size: ${(props) => (props.exp ? "15px" : "14px")};
  line-height: 20px;
  margin-bottom: 6px;
  border-bottom: ${({ theme }) =>
    theme.FilterConatiner?.lightBorder1 || "1px solid #bdd400"};

  padding: 5px;
  float: initial;
  white-space: nowrap;
  text-align: ${(props) => props.align || "left"};
  color: ${({ theme }) => theme.regularFont?.textColor || "#707070"};
  .quoteLen {
    color: black;
    font-family: ${({ theme }) =>
      theme?.fontFamily ? theme?.fontFamily : `"basier_squareregular"`};
  }
  .foundMessageQuote {
    color: ${({ theme }) => theme.regularFont?.textColor || "#707070"};
    font-family: ${({ theme }) =>
      theme?.fontFamily ? theme?.fontFamily : `"basier_squareregular"`};
  }
  .expiryText {
    color: ${({ theme }) => theme.regularFont?.textColor || "#707070"};
  }
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;
const SortContainer = styled.div`
  width: 100%;
  float: left;
  position: relative;

  @media only screen and (max-width: 992px) {
    width: 90%;
    margin: 10px 30px;
    position: relative;
    left: 0px;
  }
`;

const AlertCover = styled.div`
  width: 100%;
  position: ${(props) => (props.scroll ? "fixed" : "absolute")};
  top: ${(props) => (props.scroll ? "70px" : "126px")};

  top: ${({ theme, scroll }) =>
    scroll ? "70px" : theme?.QuoteBorderAndFont?.alertTop || "126px"};

  z-index: 1000;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  left: 0px;
  background: ${({ theme }) => theme.FilterConatiner?.color || "#bdd400"};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.QuoteBorderAndFont?.fontColor || " #000"};
  @media only screen and (max-width: 992px) {
    position: absolute;
    top: 320px;
  }
  @media only screen and (max-width: 993px) {
    position: absolute;
    display: none;
  }
`;
const ExpiryContainer = styled.span`
  color: ${(props) =>
    props.diffDaysExpiry < 5
      ? "red"
      : props.diffDaysExpiry < 10
      ? "#bbbb18;"
      : "green"};

  .expiryText {
    margin-left: 10px;
  }
`;
const FilterCont = styled.span`
  .filterPadding {
    padding: 0px 12px 0px 0px !important;

    @media only screen and (max-width: 1401px) {
      padding: 0px 35px 0px 0px !important;
    }
  }
  .blueIcon {
    max-height: 17px !important;
    font-size: 17px;
    margin-left: 0px !important;
    color: ${({ theme }) =>
      theme?.FilterConatiner?.editIconColor
        ? theme?.FilterConatiner?.editIconColor
        : "none"};
  }
`;
const FilterContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const FilterMobileTop = styled.div`
  background: white;
  padding: 2px 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  z-index: ${(props) => (props.highlighted ? "5000000000 !important" : "")};
`;
const FilterMobileBottom = styled.div`
  display: flex;
  background: #dddddd;
`;
const FilterMobileTopItem = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 10px;
  width: 100%;
  justify-content: space-between;
  .rtoNameMobile {
    font-size: ${({ isMobileIOS }) => (isMobileIOS ? "10.5px" : "11px")};
    font-weight: 600;
    ${({ mask }) =>
      mask
        ? `text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;`
        : ``}
    @media only screen and (max-width: 400px) {
      font-size: ${({ isMobileIOS }) => (isMobileIOS ? "10px" : "10.5px")};
      font-weight: 600;
    }
    @media only screen and (max-width: 330px) {
      font-size: 9px;
      font-weight: 600 !important;
    }
  }
  .editImageMobile {
    height: ${({ isMobileIOS }) => (isMobileIOS ? "8.5px" : "9.5px")};
    bottom: 2px;
    position: relative;
    color: ${({ theme }) =>
      theme?.FilterConatiner?.editIconColor
        ? theme?.FilterConatiner?.editIconColor
        : "none"};
    @media only screen and (max-width: 400px) {
      font-size: 7px;
    }
    @media only screen and (max-width: 360px) {
      height: 8.5px;
    }
  }
  .noWrapExpiry {
    white-space: nowrap;
  }
  @media only screen and (max-width: 400px) {
    padding: 3px 4px;
  }
`;
const FilterMobileBottomItem = styled.div`
  display: flex;
  width: 33.33%;
  flex-direction: column;
  padding: 0px 5px;
  margin: 5px 0px 5px 0px;
  border-right: 1px solid #cccccc;
  .caption {
    font-size: ${({ isMobileIOS }) => (isMobileIOS ? "10.5px" : "11.5px")};
    display: flex;
    font-weight: 600 !important;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 400px) {
      font-size: ${({ isMobileIOS }) => (isMobileIOS ? "10px" : "11px")};
      font-weight: 600 !important;
    }
    @media only screen and (max-width: 330px) {
      font-size: 9.5px;
      font-weight: 600 !important;
    }
  }

  .selection {
    font-size: 11.5px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 400px) {
      font-size: ${({ isMobileIOS }) => (isMobileIOS ? "10.5px" : "11px")};
    }
    @media only screen and (max-width: 330px) {
      font-size: 9.5px;
      font-weight: 600 !important;
    }
  }
  .arrowDown {
    margin-left: 8px;
    font-size: ${["SRIDHAR", "ACE", "BAJAJ", "PINC"].includes(
      process.env.REACT_APP_BROKER
    )
      ? "15px"
      : "18px"};
    color: #909090;
  }
  .selectionText {
    font-size: 11.5px;
    font-weight: 600;
    margin-left: 7px;
    @media only screen and (max-width: 400px) {
      font-size: ${({ isMobileIOS }) => (isMobileIOS ? "10.5px" : "11px")};
    }
    @media only screen and (max-width: 330px) {
      font-size: 9.5px;
      font-weight: 600 !important;
    }
  }
`;

const AlertCoverMobile = styled.div`
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  border-radius: 0px 0px 15px 15px;
  background: ${({ theme }) => theme.FilterConatiner?.color || "#bdd400"};
  color: ${({ theme }) => theme.QuoteBorderAndFont?.fontColor || " #000"};
`;
