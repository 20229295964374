import { createSlice } from "@reduxjs/toolkit";
import { feedbackApi } from "./serviceApi";
import { serializeError, actionStructre } from "../../utils";

export const feedbackSlice = createSlice({
	name: "feedback",
	initialState: {
		loading: null,
		error: null,
		success: null,
	},
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
			state.success = null;
		},
		success: (state, { payload }) => {
			state.loading = null;
			state.error = null;
			state.success = payload;
		},
		error: (state, { payload }) => {
			state.loading = null;
			state.error = serializeError(payload);
			state.success = payload;
		},
	},
});

export const { loading, success, error } = feedbackSlice.actions;

export const FeedBack = (data) => {
	return async (dispatch) => {
		try {
			actionStructre(dispatch, success, error, feedbackApi, data);
			// if (response?.data?.status) {
			//     dispatch(success(response?.data)) //variable name
			// } else {
			//     dispatch(error(response?.data?.msg));
			// }
		} catch (err) {
			dispatch(error("Something went wrong"));
			console.error("Error", err);
		}
	};
};

export default feedbackSlice.reducer;
