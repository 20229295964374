import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import _ from "lodash";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import { Prefill, clear } from "./proposal.slice";
import { BackButton } from "components";
import { DashboardCard, Div, DivHeader, DivValue, H3 } from "./style.js";
import { scrollToTop, Encrypt } from "utils";
import { Url } from "modules/proposal/proposal.slice";
import { ShareQuote, clear as clr } from "../../modules/Home/home.slice";
import { Button } from "components";
import { useMediaPredicate } from "react-media-hook";
import OTPPopup from "./otp";
import { createGlobalStyle } from "styled-components";
import ThemeObj from "modules/theme-config/theme-config";
import SecureLS from "secure-ls";

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

const PaymentConfirmation = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enquiry_id = query.get("enquiry_id");
  const token = query.get("token") || localStorage?.SSO_user_motor;
  const { type } = props?.match?.params;
  const lessthan767 = useMediaPredicate("(max-width: 767px)");

  const { temp_data, error_other } = useSelector((state) => state.proposal);
  const { share } = useSelector((state) => state.home);

  //otp state
  const [show, setShow] = useState(false);
  /*---------------- back button---------------------*/
  const back = () => {
    history.push(
      `/${type}/proposal-page?enquiry_id=${enquiry_id}&dropout=${true}${
        token ? `&token=${token}` : ``
      }`
    );
  };
  /*----------x----- back button-------x-------------*/

  //scroll to top
  useEffect(() => {
    scrollToTop();
  }, []);

  //vehicle details
  const VehicleData = !_.isEmpty(temp_data?.quoteLog?.premiumJson?.mmvDetail)
    ? temp_data?.quoteLog?.premiumJson?.mmvDetail
    : {};

  const CardData = !_.isEmpty(temp_data)
    ? temp_data?.userProposal?.additonalData
      ? temp_data?.userProposal?.additonalData
      : {}
    : {};

  //Url
  useEffect(() => {
    if (enquiry_id)
      dispatch(
        Url({
          proposalUrl: window.location.href,
          quoteUrl: window.location.href
            ? window.location.href?.replace(/payment-confirmation/g, "quotes")
            : "",
          userProductJourneyId: enquiry_id,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  //load prefill data
  useEffect(() => {
    if (enquiry_id) dispatch(Prefill({ enquiryId: enquiry_id }, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiry_id]);

  //onError
  useEffect(() => {
    if (error_other) {
      swal("Error", error_other, "error");
    }
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error_other]);

  //Trigger
  // useEffect(() => {
  //   if (enquiry_id && CardData?.owner?.email)
  //     dispatch(
  //       ShareQuote({
  //         enquiryId: enquiry_id,
  //         notificationType: "email",
  //         domain: `http://${window.location.hostname}`,
  //         type: "sharePayment",
  //         emailId: CardData?.owner?.email,
  //         action: window.location.href,
  //         link: window.location.href,
  //         productName: temp_data?.selectedQuote?.productName,
  //       })
  //     );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [enquiry_id, CardData]);

  const payment = () => {
    if (enquiry_id) {
      if (
        Number(temp_data?.quoteLog?.icId) !== 32 &&
        Number(temp_data?.quoteLog?.icId) !== 35
      ) {
        history.push(
          `/${type}/payment-gateway?enquiry_id=${enquiry_id}${
            token ? `&token=${token}` : ``
          }${
            temp_data?.userProposal?.isBreakinCase
              ? `&breakin=${Encrypt(true)}`
              : ``
          }`
        );
      } else {
        dispatch(
          ShareQuote(
            {
              enquiryId: enquiry_id,
              notificationType: "sms",
              domain: `http://${window.location.hostname}`,
              type: "otpSms",
              applicableNcb:
                temp_data?.corporateVehiclesQuoteRequest?.applicableNcb,
              mobileNo: CardData?.owner?.mobileNumber,
              policyEndDate: temp_data?.selectedQuote?.policyEndDate,
              policyStartDate: temp_data?.selectedQuote?.policyStartDate,
              premiumAmount: temp_data?.quoteLog?.finalPremiumAmount,
              productName: temp_data?.selectedQuote?.productName,
              registrationNumber: CardData?.vehicle?.vehicaleRegistrationNumber,
              emailId: CardData?.owner?.email,
            },
            true
          )
        );
      }
    } else {
      swal("Error", "No enquiry id found", "error");
    }
  };

  /*--------------OTP--------------*/
  //clear previous success
  useEffect(() => {
    dispatch(clr("share"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (share) {
      setShow(true);
    }

    return () => {
      dispatch(clr("share"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [share]);

  //on otp enter
  const otpSuccess = () => {
    if (enquiry_id) {
      history.push(
        `/${type}/payment-gateway?enquiry_id=${enquiry_id}${
          token ? `&token=${token}` : ``
        }${
          temp_data?.userProposal?.isBreakinCase
            ? `&breakin=${Encrypt(true)}`
            : ``
        }`
      );
    } else {
      swal("Error", "No enquiry id found", "error");
    }
  };
  /*------x-------OTP------x-------*/

  const redirectTo = (elem, item) => {
    swal({
      title: "Confirm Action",
      text: `Are you sure you want to edit ${elem}?`,
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        catch: {
          text: "Confirm",
          value: "confirm",
        },
      },
      dangerMode: true,
    }).then((caseValue) => {
      switch (caseValue) {
        case "confirm":
          history.push(
            `/${type}/proposal-page?enquiry_id=${enquiry_id}${
              token ? `&token=${token}` : ``
            }&openTo=${item}`
          );
          break;
        default:
      }
    });
  };

  return (
    <>
      {!_.isEmpty(temp_data?.userProposal) ? (
        <>
          {temp_data?.userProposal?.isBreakinCase !== "Y" ? (
            <div className="backBtn" style={{ paddingBottom: "30px" }}>
              <BackButton
                type="button"
                onClick={back}
                style={{ marginTop: "-20px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className=""
                  viewBox="0 0 24 24"
                >
                  <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
                <text style={{ color: "black" }}>Back</text>
              </BackButton>
            </div>
          ) : (
            <noscript />
          )}

          <Div style={{ fontFamily: "sans-serif" }}>
            <Row className="w-100 mt-5 d-flex justify-content-center mx-auto">
              <Col
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="w-100 d-flex justify-content-center mx-auto"
              >
                <h3
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    marginBottom: "30px",
                  }}
                  className="w-100 text-center d-flex justify-content-center mx-auto"
                >
                  Payment Confirmation
                </h3>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} xl={12} sm={12} className="p-3">
                <DashboardCard>
                  <Row>
                    <Col md={12} lg={12} xl={12} sm={12}>
                      {/*Personal Details*/}
                      <div className="mb-2">
                        <H3 className="-nowrap text-left">
                          {Number(temp_data?.ownerTypeId) === 1
                            ? "Personal Details"
                            : "Company Details"}
                          {temp_data?.userProposal?.isBreakinCase !== "Y" ? (
                            <i
                              className="fa fa-pencil ml-2"
                              aria-hidden="true"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                redirectTo("personal details", "owner")
                              }
                            ></i>
                          ) : (
                            <noscript />
                          )}
                        </H3>
                        <div className="d-flex flex-wrap">
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>NAME</DivHeader>
                            <DivValue>{`${temp_data?.userProposal?.firstName?.toUpperCase()} ${temp_data?.userProposal?.lastName?.toUpperCase()}`}</DivValue>
                          </Col>
                          <Col
                            md={4}
                            lg={3}
                            xl={3}
                            sm={6}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>EMAIL</DivHeader>
                            <DivValue>{`${temp_data?.userProposal?.email}`}</DivValue>
                          </Col>
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>MOBILE NUMBER</DivHeader>
                            <DivValue>{`${temp_data?.userProposal?.mobileNumber}`}</DivValue>
                          </Col>
                        </div>
                        {/* <div style={{ textAlign: 'left', overflowX: 'auto' }}>
                          <table className="table table-hover w-100">
                            <thead className="">
                              <tr style={{ fontSize: lessthan767 ? '10px' : '14px', background: 'linear-gradient(81.67deg,#b3ffb3 0%,#ffffcc 100%)' }}>
                                <th scope="col">NAME</th>
                                <th scope="col">EMAIL</th>
                                <th scope="col">MOBILE NUMBER</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ fontSize: lessthan767 ? "10px" : '14px' }}>
                                <td>{`${temp_data?.userProposal?.firstName?.toUpperCase()} ${temp_data?.userProposal?.lastName?.toUpperCase()}`}</td>
                                <td>{`${temp_data?.userProposal?.email}`}</td>
                                <td>{`${temp_data?.userProposal?.mobileNumber}`}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </div>
                      {/*Vehicle Details*/}
                      <div className="mb-2 mt-3">
                        <H3 className="-nowrap text-left">
                          Vehicle Details
                          {temp_data?.userProposal?.isBreakinCase !== "Y" ? (
                            <i
                              className="fa fa-pencil ml-2"
                              aria-hidden="true"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                redirectTo("vehicle details", "vehicle")
                              }
                            ></i>
                          ) : (
                            <noscript />
                          )}
                        </H3>
                        <div className="d-flex flex-wrap">
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>REGISTRATION NUMBER</DivHeader>
                            <DivValue>{`${
                              temp_data?.userProposal?.vehicaleRegistrationNumber?.toUpperCase() ||
                              temp_data?.userProposal?.rtoLocation?.toUpperCase()
                            }`}</DivValue>
                          </Col>
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>VEHICLE MODEL</DivHeader>
                            <DivValue>{`${
                              temp_data?.userProposal?.icVehicleDetails
                                ?.manufactureName
                                ? `${temp_data?.userProposal?.icVehicleDetails?.manufactureName?.toUpperCase()} ${
                                    temp_data?.userProposal?.icVehicleDetails
                                      ?.modelName
                                  }`
                                : `${VehicleData?.manfName?.toUpperCase()} ${VehicleData?.modelName?.toUpperCase()}`
                            }`}</DivValue>
                          </Col>
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>VEHICLE VERSION</DivHeader>
                            <DivValue>{`${
                              temp_data?.userProposal?.icVehicleDetails?.version?.toUpperCase() ||
                              VehicleData?.versionName?.toUpperCase()
                            }`}</DivValue>
                          </Col>
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>SEATING CAPACITY</DivHeader>
                            <DivValue>{`${
                              temp_data?.userProposal?.icVehicleDetails
                                ?.seatingCapacity ||
                              VehicleData?.seatingCapacity
                            }`}</DivValue>
                          </Col>
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>FUEL TYPE</DivHeader>
                            <DivValue>{`${
                              temp_data?.userProposal?.icVehicleDetails?.fuelType?.toUpperCase() ||
                              VehicleData?.fuelType?.toUpperCase()
                            }`}</DivValue>
                          </Col>
                        </div>
                        {/* <div style={{ textAlign: 'left', overflowX: 'auto' }}>
                          <table className="table table-hover w-100">
                            <thead className="">
                              <tr style={{ fontSize: lessthan767 ? '10px' : '14px', background: 'linear-gradient(81.67deg,#b3ffb3 0%,#ffffcc 100%)' }}>
                                <th>REGISTRATION NUMBER</th>
                                <th>VEHICLE MODEL</th>
                                <th>VEHICLE VERSION</th>
                                <th>SEATING CAPACITY</th>
                                <th>FUEL TYPE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ fontSize: lessthan767 ? '10px' : '14px' }}>
                                <td>{`${
                              temp_data?.userProposal?.vehicaleRegistrationNumber?.toUpperCase() ||
                              temp_data?.userProposal?.rtoLocation?.toUpperCase()
                            }`}</td>
                                <td>{`${
                              temp_data?.userProposal?.icVehicleDetails
                                ?.manufactureName
                                ? `${temp_data?.userProposal?.icVehicleDetails?.manufactureName?.toUpperCase()} ${
                                    temp_data?.userProposal?.icVehicleDetails
                                      ?.modelName
                                  }`
                                : `${VehicleData?.manfName?.toUpperCase()} ${VehicleData?.modelName?.toUpperCase()}`
                            }`}</td>
                                <td>{`${
                              temp_data?.userProposal?.icVehicleDetails?.version?.toUpperCase() ||
                              VehicleData?.versionName?.toUpperCase()
                            }`}</td>
                                <td>{`${
                              temp_data?.userProposal?.icVehicleDetails
                                ?.seatingCapacity ||
                              VehicleData?.seatingCapacity
                            }`}</td>
                                <td>{`${
                              temp_data?.userProposal?.icVehicleDetails?.fuelType?.toUpperCase() ||
                              VehicleData?.fuelType?.toUpperCase()
                            }`}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}
                      </div>
                      {/*Policy Details*/}
                      <div className="mb-2">
                        <H3 className="-nowrap text-left">
                          Policy Details
                          <i
                            className="fa fa-pencil ml-2"
                            aria-hidden="true"
                            style={{ visibility: "hidden" }}
                          ></i>
                        </H3>
                        <div className="d-flex flex-wrap">
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>INSURANCE COMPANY NAME</DivHeader>
                            <DivValue
                              icName
                            >{`${temp_data?.userProposal?.icName?.toUpperCase()}`}</DivValue>
                          </Col>
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>POLICY TYPE</DivHeader>
                            <DivValue>{`${_.capitalize(
                              temp_data?.corporateVehiclesQuoteRequest
                                ?.policyType
                            )?.toUpperCase()}`}</DivValue>
                          </Col>
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>START DATE</DivHeader>
                            <DivValue>{`${
                              temp_data?.userProposal?.policyStartDate?.toUpperCase() ||
                              "N/A"
                            }`}</DivValue>
                          </Col>
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>END DATE</DivHeader>
                            <DivValue>{`${
                              temp_data?.userProposal?.policyEndDate?.toUpperCase() ||
                              "N/A"
                            }`}</DivValue>
                          </Col>
                          <Col
                            md={6}
                            lg={4}
                            xl={3}
                            sm={12}
                            className="py-2 px-0 text-nowrap text-left"
                          >
                            <DivHeader>APPLICABLE NCB</DivHeader>
                            <DivValue>{`${
                              temp_data?.corporateVehiclesQuoteRequest?.applicableNcb?.toUpperCase() ||
                              "0"
                            }`}</DivValue>
                          </Col>
                        </div>
                        {/* <div style={{ textAlign: 'left', overflowX: 'auto' }}>
                        <table className="table table-hover w-100">
                            <thead className="">
                              <tr style={{ fontSize: lessthan767 ? '10px' : '14px', background: 'linear-gradient(81.67deg,#b3ffb3 0%,#ffffcc 100%)' }}>
                                <th>INSURANCE COMPANY NAME</th>
                                <th>POLICY TYPE</th>
                                <th>START DATE</th>
                                <th>END DATE</th>
                                <th>APPLICABLE NCB</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ fontSize: lessthan767 ? '10px' : '14px' }}>
                                <td>{`${temp_data?.userProposal?.icName?.toUpperCase()}`}</td>
                                <td>{`${_.capitalize(
                              temp_data?.corporateVehiclesQuoteRequest
                                ?.policyType
                            )?.toUpperCase()}`}</td>
                                <td>{`${
                              temp_data?.userProposal?.policyStartDate?.toUpperCase() ||
                              "N/A"
                            }`}</td>
                                <td>{`${
                              temp_data?.userProposal?.policyEndDate?.toUpperCase() ||
                              "N/A"
                            }`}</td>
                                <td>{`${
                              temp_data?.corporateVehiclesQuoteRequest?.applicableNcb?.toUpperCase() ||
                              "0"
                            }`}</td>
                              </tr>
                            </tbody>
                          </table>
                          </div> */}
                      </div>
                    </Col>
                  </Row>
                </DashboardCard>
              </Col>
              <Col md={12} lg={12} xl={12} sm={12} className="p-3">
                <DashboardCard>
                  <Row>
                    <Col md={12} lg={12} xl={12} sm={12}>
                      <h4
                        style={{ color: "rgba(48, 68, 80, 0.6)" }}
                        className="text-left w-100"
                      >
                        Premium Details
                      </h4>
                      <Table
                        striped
                        bordered
                        hover
                        variant={
                          Theme?.paymentConfirmation?.table
                            ? Theme?.paymentConfirmation?.table
                            : "success"
                        }
                        responsive
                        className="mt-2"
                        style={{
                          fontFamily: "Inter-SemiBold",
                          // background:
                          //   "linear-gradient(81.67deg, #b3ffb3 0%, #ffffcc 100%)",
                        }}
                      >
                        <thead>
                          <tr>
                            {true ? <th>OWN DAMAGE</th> : <noscript />}
                            {true ? <th>THIRD PARTY</th> : <noscript />}
                            {true ? <th>ADDON PREMIUM</th> : <noscript />}
                            {true ? <th>TOTAL DISCOUNT</th> : <noscript />}
                            {true ? <th>TOTAL (WITHOUT GST)</th> : <noscript />}
                            {true ? <th>GST</th> : <noscript />}
                            {true ? <th>TOTAL (WITH GST)</th> : <noscript />}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {true ? (
                              <td>{`₹ ${
                                temp_data?.userProposal?.odPremium || 0
                              }`}</td>
                            ) : (
                              <noscript />
                            )}
                            {true ? (
                              <td>{`₹ ${
                                temp_data?.userProposal?.tpPremium || 0
                              }`}</td>
                            ) : (
                              <noscript />
                            )}
                            {true ? (
                              <td>{`₹ ${
                                temp_data?.userProposal?.addonPremium || 0
                              }`}</td>
                            ) : (
                              <noscript />
                            )}
                            {true ? (
                              <td>{`₹ ${
                                temp_data?.userProposal?.totalDiscount || 0
                              }`}</td>
                            ) : (
                              <noscript />
                            )}
                            {true ? (
                              <td>{`₹ ${
                                temp_data?.userProposal?.totalPremium || 0
                              }`}</td>
                            ) : (
                              <noscript />
                            )}
                            {true ? (
                              <td>{`₹ ${
                                temp_data?.userProposal?.serviceTaxAmount || 0
                              }`}</td>
                            ) : (
                              <noscript />
                            )}
                            {true ? (
                              <td>{`₹ ${
                                temp_data?.userProposal?.finalPayableAmount || 0
                              }`}</td>
                            ) : (
                              <noscript />
                            )}
                          </tr>
                        </tbody>
                      </Table>
                      <Col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        className="d-flex justify-content-end mt-1 w-100 mr-0 pr-0"
                      >
                        <Button
                          type="submit"
                          buttonStyle="outline-solid"
                          onClick={() => payment()}
                          hex1={
                            Theme?.paymentConfirmation?.Button?.hex1
                              ? Theme?.paymentConfirmation?.Button?.hex1
                              : "#4ca729"
                          }
                          hex2={
                            Theme?.paymentConfirmation?.Button?.hex2
                              ? Theme?.paymentConfirmation?.Button?.hex2
                              : "#4ca729"
                          }
                          borderRadius="5px"
                          color={
                            Theme?.PaymentConfirmation?.buttonTextColor
                              ? Theme?.PaymentConfirmation?.buttonTextColor
                              : "white"
                          }
                        >
                          <text
                            style={{
                              fontSize: "15px",
                              padding: "-20px",
                              margin: "-20px -5px -20px -5px",
                              fontWeight: "400",
                            }}
                          >
                            {"Proceed to payment"}
                            <i className="fa fa-arrow-circle-right ml-2"></i>
                          </text>
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </DashboardCard>
              </Col>
            </Row>
          </Div>
          <OTPPopup
            enquiry_id={enquiry_id}
            show={show}
            onHide={() => setShow(false)}
            mobileNumber={CardData?.owner?.mobileNumber}
            otpSuccess={() => otpSuccess()}
          />
        </>
      ) : (
        <noscript />
      )}
    </>
  );
};

export default PaymentConfirmation;
