import React from "react";
import { useSelector } from "react-redux";
// Broker Name
export const BrokerName = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return "Ola Financial Services Private Limited";
    case "UNILIGHT":
      return "UNILIGHT Insurance Brokers Limited";
    case "CP":
      return "Compare Policy Private Limited";
    case "FYNTUNE":
      return "Fyntune Solutions Pvt. Ltd.";
    case "ABIBL":
      return "Aditya Birla Insurance Broker Limited";
    case "GRAM":
      return "GramCover Insurance Brokers Private Limited";
    case "ACE":
      return "ACE Insurance Broker Pvt. Ltd.";
    case "SRIYAH":
      return "Sriyah Insurance Brokers Pvt. Ltd";
    case "PINC":
      return "Pioneer Insurance & Reinsurance Brokers Pvt. Ltd.";
    case "EPOCH":
      return "Epoch Insurance Brokers Pvt. Ltd.";
    case "RB":
      return "D2C Insurance Broking Pvt. Ltd.";
    case "SPA":
      return "SPA Insurance Broking Services Ltd.";
    case "BIMA":
      return "SS Insurance Broking Services Ltd.";
    case "BAJAJ":
      return "Bajaj Capital Insurance Broking Limited";
    case "ANALAH":
      return "ANALAH Insurance Broking Limited";
    case "UIB":
      return "UIB Insurance Brokers (India) Pvt. Ltd.";
    case "SRIDHAR":
      return "Sridhar Insurance Brokers (P) Ltd.";
    case "SHREE":
      return "LAKSHMISHREE CAPITAL SERVICES PRIVATE LIMITED.";
    case "POLICYERA":
      return "Policy Era Insurance Broking LLP.";
    case "TATA":
      return "Tata Motors Insurance Broking And Advisory Services Limited.";
    default:
      return "Ola Financial Services Private Limited";
  }
};
//  Broker Category
export const BrokerCategory = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return "Corporate Agent";
    case "UNILIGHT":
      return "";
    case "CP":
      return "Web Aggregator";
    case "FYNTUNE":
      return "Composite Broker";
    case "ABIBL":
      return "Composite Broker";
    case "GRAM":
      return "Composite Broker";
    case "ACE":
      return "Composite Broker";
    case "SRIYAH":
      return "Direct Broker";
    case "PINC":
      return "Composite Broker";
    case "EPOCH":
      return "Direct (Life & Non-Life)";
    case "RB":
      return "Direct Broker (Life & General)";
    case "SPA":
      return "Direct Broker";
    case "BIMA":
      return "Direct Broker";
    case "BAJAJ":
      return "Direct Broker";
    case "ANALAH":
      return "Direct Broker";
    case "UIB":
      return "Composite Broker";
    case "SRIDHAR":
      return "Direct Broker";
    case "SHREE":
      return "Composite Broker";
    case "POLICYERA":
      return "Direct Broker";
    case "TATA":
      return "Composite Broker";
    default:
      return "Composite Broker";
  }
};

// broker email  funtion
export const brokerEmailFunction = (brokerValue) => {
  switch (brokerValue || process.env?.REACT_APP_BROKER) {
    case "OLA":
      return "insurance@olacabs.com";
    case "UNILIGHT":
      return "Support@policyongo.com";
    case "CP":
      return "help@comparepolicy.com";
    case "FYNTUNE":
      return "help@fyntune.com";
    case "ABIBL":
      return "clientfeedback.abibl@adityabirlacapital.com";
    case "GRAM":
      return "info@gramcover.com";
    case "ACE":
      return "operations@aceinsurance.com";
    case "SRIYAH":
      return "care@nammacover.com";
    case "EPOCH":
      return "support@policylo.com";
    case "PINC":
      return process.env.REACT_APP_PROD === "YES"
        ? "customer.care@pinctree.com"
        : "customer.care.uat@pinctree.com";
    case "RB":
      return "customersupport@renewbuy.com";
    case "SPA":
      return "care@insuringall.com";
    case "BIMA":
      return "contact@bimaplanner.com";
    case "ANALAH":
      return "contactus@analahinsurance.com";
    case "BAJAJ":
      return "info@bajajcapitalinsurance.com";
    case "UIB":
      return "services@uibindia.com";
    case "SRIDHAR":
      return "motor@sibinsure.com";
    case "SHREE":
      return "insure@lakshmishree.com";
    case "POLICYERA":
      return "support@policyera.com";
    case "TATA":
      return "support@tmibasl.com";
    default:
      break;
  }
};
// broker contact number funtion
export const ContactFn = (brokerValue) => {
  switch (brokerValue || process.env?.REACT_APP_BROKER) {
    case "OLA":
      return "08037101822";
    case "UNILIGHT":
      return "18002101225";
    case "CP":
      return "1800120000055";
    case "FYNTUNE":
      return "1800120000065";
    case "ABIBL":
      return "18002707000";
    case "GRAM":
      return "+91 9311672463";
    case "ACE":
      return "+918750881122";
    case "SRIYAH":
      return "+1800 203 0504";
    case "PINC":
      return "18002672670";
    case "EPOCH":
      return "9711615784";
    case "RB":
      return "18004197852";
    case "SPA":
      return "+91-11-45675555";
    case "BIMA":
      return "9999366494";
    case "ANALAH":
      return "+91 9920878181";
    case "BAJAJ":
      return "1800 212 123123";
    case "UIB":
      return "+91 79820 39210";
    case "SRIDHAR":
      return "1800-102-6099";
    case "SHREE":
      return "9235395895";
    case "POLICYERA":
      return "7039839239";
    case "TATA":
      return "18002090060";
    default:
      return "18002669639";
  }
};
// broker contact number funtion
export const cinNO = (brokerValue) => {
  switch (brokerValue || process.env?.REACT_APP_BROKER) {
    case "OLA":
      return "U67200MH2003PTC141621";
    case "UNILIGHT":
      return "";
    case "CP":
      return "U74140DL2015PTC276540";
    case "FYNTUNE":
      return "U67200MH2003PTC141621";
    case "ABIBL":
      return "U67200MH2003PTC141621";
    case "GRAM":
      return "U66000DL2016PTC292037";
    case "ACE":
      return "U74999DL2001PTC110729";
    case "SRIYAH":
      return "U66010KA2003PTC031462";
    case "PINC":
      return "U67200MH2002PTC137896";
    case "EPOCH":
      return "U74900DL2013PTC251990";
    case "RB":
      return "U66030DL2013PTC249265";
    case "SPA":
      return "U67120MH1995PLC088462";
    case "BIMA":
      return "U67120MH1995PLC088462";
    case "ANALAH":
      return "U66010MH2020PTC337611";
    case "BAJAJ":
      return "U67200DL2002PLC117625";
    case "UIB":
      return "U66030MH2009PTC195794";
    case "SRIDHAR":
      return "U67120CH2002PTC025491";
    case "SHREE":
      return "U66000MH2014PTC257770";
    case "POLICYERA":
      return "AAX-7485";
    case "TATA":
      return "U50300MH1997PLC149349";
    default:
      return "U67200MH2003PTC141621";
  }
};
// IRDIA number
export const getIRDAI = (brokerValue) => {
  switch (brokerValue || process.env?.REACT_APP_BROKER) {
    case "OLA":
      return "CA0682";
    case "UNILIGHT":
      return "446";
    case "CP":
      return "IRDAI/WBA23/15";
    case "FYNTUNE":
      return "CA0682";
    case "ABIBL":
      return "CA0682";
    case "GRAM":
      return "CB 691/17";
    case "ACE":
      return "CB/246";
    case "SRIYAH":
      return "203";
    case "PINC":
      return "118 ";
    case "EPOCH":
      return "555 valid upto 10/03/2025";
    case "RB":
      return "DB 571/14";
    case "SPA":
      return "DB053/03";
    case "BIMA":
      return "DB/655";
    case "ANALAH":
      return "DB842/20";
    case "BAJAJ":
      return "CB 042/02";
    case "UIB":
      return "410";
    case "SRIDHAR":
      return "212";
    case "SHREE":
      return "CA0625";
    case "POLICYERA":
      return "DB 897/2021";
    case "TATA":
      return "375";
    default:
      break;
  }
};
// cv logo
export const getLogoCvType = (productSubTypeId) => {
  switch (productSubTypeId) {
    case 5:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/auto.png`;
    case 6:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/taxi-car1.png`;
    case 9:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/trck.png`;
    case 13:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/dumper2.png`;
    case 14:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/pickup.png`;
    case 15:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/tractor.png`;
    case 16:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/tanker.png`;
    default:
      return `${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/auto-car.png`;
  }
};
// broker logo url funtion
export const getBrokerLogoUrl = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/ola.png`;
    case "UNILIGHT":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/unilight.png`;
    case "CP":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/cp.png`;
    case "FYNTUNE":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/fyntune.png`;
    case "POLICYERA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/policy-era.png`;
    case "ABIBL":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/abiblPdf.jpeg`;
    case "GRAM":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/gc.png`;
    case "ACE":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/ace.png`;
    case "SRIYAH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/sriyah.png`;
    case "PINC":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/pinc.png`;
    case "EPOCH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/epochlogo.png`;
    case "RB":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/rb.png`;
    case "SPA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/insuringall.jpeg`;
    case "BIMA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/bima.png`;
    case "ANALAH":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/analah.jpeg`;
    case "BAJAJ":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/bajaj.png`;
    case "UIB":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/uib.png`;
    case "SRIDHAR":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/sridhar.png`;
    case "SHREE":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/shree.png`;
    case "TATA":
      return `${window.location.origin}${
        process.env.REACT_APP_BASENAME !== "NA"
          ? `/${process.env.REACT_APP_BASENAME}`
          : ""
      }/assets/images/vehicle/tata.gif`;
    default:
      break;
  }
};

// email and phone number with text
export const ContentFn = (brokerValue) => {
  switch (brokerValue || process.env?.REACT_APP_BROKER) {
    case "OLA":
      return (
        <>
          In case of any challenges, please contact us at
          <b> insurance@olacabs.com</b> or call us at our number
          <b> 7829-41-1222</b>
        </>
      );
    case "UNILIGHT":
      return (
        <>
          In case of any challenges, please contact us at
          <b> Support@policyongo.com</b> or call us at our number
          <b> 1800-210-1225</b>
        </>
      );
    case "CP":
      return (
        <>
          In case of any challenges, please contact us at
          <b> help@comparepolicy.com</b> or call us at our number
          <b> 1800-12000-0055</b>
        </>
      );
    case "FYNTUNE":
      return (
        <>
          In case of any challenges, please contact us at
          <b> help@fyntune.com</b> or call us at our number
          <b> 9711615784</b>
        </>
      );
    case "ABIBL":
      return (
        <>
          In case of any challenges, please contact us at
          <b> Support@abibl.com</b> or call us at our number
          <b> 1800 270 7000</b>
        </>
      );
    case "GRAM":
      return (
        <>
          In case of any challenges, please contact us at
          <b> info@gramcover.com</b> or call us at our number
          <b> +91 9311672463</b>
        </>
      );
    case "ACE":
      return (
        <>
          In case of any challenges, please contact us at
          <b> operations@aceinsurance.com</b> or call us at our number
          <b> +918750881122</b>
        </>
      );
    case "SRIYAH":
      return (
        <>
          In case of any challenges, please contact us at
          <b> care@nammacover.com</b> or call us at our number
          <b> 1800 203 0504</b>
        </>
      );
    case "PINC":
      return (
        <>
          In case of any further requirements, please contact us at
          <b>
            {" "}
            {process.env.REACT_APP_PROD === "YES"
              ? "customer.care@pinctree.com"
              : "customer.care.uat@pinctree.com"}
          </b>{" "}
          or call us at our number
          <b> 18002672670 </b>
        </>
      );
    case "EPOCH":
      return (
        <>
          In case of any further requirements, please contact us at
          <b> support@policylo.com</b> or call us at our number
          <b> 9711615784</b>
        </>
      );
    case "RB":
      return (
        <>
          In case of any further requirements, please contact us at
          <b> customersupport@renewbuy.com</b> or call us at our number
          <b> 18004197852</b>
        </>
      );
    case "SPA":
      return (
        <>
          In case of any further requirements, please contact us at
          <b> care@insuringall.com</b> or call us at our number
          <b> +91-11-45675555</b>
        </>
      );
    case "BIMA":
      return (
        <>
          In case of any further requirements, please contact us at
          <b> contact@bimaplanner.com</b> or call us at our number
          <b> 9999366494</b>
        </>
      );
    case "ANALAH":
      return (
        <>
          In case of any further requirements, please contact us at
          <b> contactus@analahinsurance.com</b> or call us at our number
          <b> +91 9920878181</b>
        </>
      );
    case "BAJAJ":
      return (
        <>
          In case of any further requirements, please contact us at
          <b> info@bajajcapitalinsurance.com</b> or call us at our number
          <b> 1800 212 123123</b>
        </>
      );
    case "UIB":
      return (
        <>
          In case of any further requirements, please contact us at
          <b> services@uibindia.com</b>
        </>
      );
    case "SRIDHAR":
      return (
        <>
          In case of any further requirements, please contact us at
          <b> motor@sibinsure.com</b>
        </>
      );
    case "SHREE":
      return (
        <>
          In case of any further requirements, please contact us at
          <b> insure@lakshmishree.com</b>
        </>
      );
    case "POLICYERA":
      return (
        <>
          In case of any further requirements, please contact us at
          <b> support@policyera.com</b>
        </>
      );
    case "TATA":
      return (
        <>
          In case of any further requirements, please contact us at
          <b> support@tmibasl.com</b>
        </>
      );
    default:
      return (
        <>
          In case of any challenges, please contact us at
          <b> insurance@olacabs.com</b> or call us at our number
          <b> 7829-41-1222</b>
        </>
      );
  }
};

// site login url
export const UrlFn = (ut) => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return `https://ola-dashboard.fynity.in/${ut ? "employee" : "pos"}/login`;
    case "UNILIGHT":
      return `https://www.policyongo.com/${ut ? "employee" : "pos"}/login`;
    case "CP":
      return `https://www.comparepolicy.com/`;
    case "FYNTUNE":
      return "";
    case "ABIBL":
      if (
        process.env?.REACT_APP_API_BASE_URL ===
        "https://apiabibl-preprod-carbike.fynity.in/api"
      ) {
        return `http://preprod-dasbhoard-abibl.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
      } else if (
        process.env?.REACT_APP_API_BASE_URL ===
        "https://apiabibl-carbike.fynity.in/api"
      ) {
        return `http://uat-dasbhoard-abibl.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
      } else {
        return `http://abibl-prod-dashboard.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
      }
    case "GRAM":
      if (
        process.env?.REACT_APP_API_BASE_URL ===
        "https://apigramcover-carbike.fynity.in/api"
      ) {
        return `http://uat-dasbhoard-gramcover.fynity.in/${
          ut ? "employee" : "pos"
        }/login`;
      } else {
        return `https://dashboard.gramcover.com/${
          ut ? "employee" : "pos"
        }/login`;
      }
    case "ACE":
      return "https://crm.aceinsurance.com:5555/";
    case "SRIYAH":
      return process.env.REACT_APP_PROD === "YES"
        ? "https://nammacover.com/"
        : "https://uat.nammacover.com/";
    case "PINC":
      return localStorage.getItem("setupTime")
        ? process.env.REACT_APP_PROD === "YES"
          ? "https://pinctreepos.app.link/prod_logo"
          : "https://pinctreepos.app.link/dev_logo"
        : "https://pinctree.com/";
    case "EPOCH":
      return `http://online.epochbrokers.com:8092/${
        ut ? "employee" : "customer"
      }/login`;
    case "RB":
      return "https://www.renewbuy.com/";
    case "SPA":
      return `https://uatdashboard.insuringall.com/${
        ut ? "employee" : "pos"
      }/login`;
    case "BIMA":
      return ``;
    case "BAJAJ":
      return Bajaj_rURL();
    case "UIB":
      return ``;
    case "SRIDHAR":
      return `https://uatdashboard.sibinsure.com/${
        ut ? "employee" : "pos"
      }/login`;
    case "SHREE":
      return `https://shreeinsure.com/`;
    case "POLICYERA":
      return process.env.REACT_APP_PROD === "YES"
        ? `https://dashboard.policyera.com/${ut ? "employee" : "pos"}/login`
        : `https://uatdashboard.policyera.com/${ut ? "employee" : "pos"}/login`;
    case "ANALAH":
      return process.env.REACT_APP_PROD === "YES"
        ? `https://dashboard.analahinsurance.com/${ut ? "employee" : "pos"}/login`
        : `https://uatdashboard.analahinsurance.com/${ut ? "employee" : "pos"}/login`;
    case "TATA":
      return process.env.REACT_APP_PROD === "YES"
        ? "https://lifekaplan.com/"
        : "https://uat.lifekaplan.com/";
    default:
      return `https://ola-dashboard.fynity.in/${ut ? "employee" : "pos"}/login`;
  }
};

//bajaj url
export const Bajaj_rURL = (b2c) => {
  if (
    process.env.REACT_APP_API_BASE_URL ===
    "https://uatapimotor.bajajcapitalinsurance.com/api"
  ) {
    return b2c
      ? "https://buypolicyuat.bajajcapitalinsurance.com"
      : "https://partneruat.bajajcapitalinsurance.com";
  }
  if (
    process.env.REACT_APP_API_BASE_URL ===
    "https://stageapimotor.bajajcapitalinsurance.com/api"
  ) {
    return b2c
      ? window.location.href
      : "https://partnerstage.bajajcapitalinsurance.com";
  }
  if (
    process.env.REACT_APP_API_BASE_URL ===
    "https://apimotor.bajajcapitalinsurance.com/api"
  ) {
    return b2c
      ? window.location.href
      : "https://partner.bajajcapitalinsurance.com";
  }
};

// site base url
export const BaseUrlFn = () => {
  switch (process.env?.REACT_APP_BROKER) {
    case "OLA":
      return "https://ola-dashboard.fynity.in/";
    case "UNILIGHT":
      return "https://www.policyongo.com/pos/login";
    case "CP":
      return "https://www.comparepolicy.com/";
    case "FYNTUNE":
      return "";
    case "ABIBL":
      if (
        process.env?.REACT_APP_API_BASE_URL ===
        "https://apiabibl-preprod-carbike.fynity.in/api"
      ) {
        return "http://preprod-dasbhoard-abibl.fynity.in/";
      } else if (
        process.env?.REACT_APP_API_BASE_URL ===
        "https://apiabibl-carbike.fynity.in/api"
      ) {
        return "http://uat-dasbhoard-abibl.fynity.in/";
      } else {
        return "http://abibl-prod-dashboard.fynity.in/";
      }
    case "GRAM":
      if (
        process.env?.REACT_APP_API_BASE_URL ===
        "https://apigramcover-carbike.fynity.in/api"
      ) {
        return "http://uat-dasbhoard-gramcover.fynity.in/";
      } else {
        return "https://dashboard.gramcover.com/";
      }
    case "ACE":
      return "https://crm.aceinsurance.com:5555/";
    case "SRIYAH":
      return process.env.REACT_APP_PROD === "YES"
        ? "https://nammacover.com/"
        : "https://uat.nammacover.com/";
    case "PINC":
      return process.env.REACT_APP_PROD === "YES"
        ? "https://live.pincnews.co.in/"
        : "https://dev.pincnews.co.in/";
    case "EPOCH":
      return `http://online.epochbrokers.com:8092/`;
    case "RB":
      return "https://www.renewbuy.com/";
    case "SPA":
      return "https://uatdashboard.insuringall.com/";
    case "BIMA":
      return "";
    case "ANALAH":
      return process.env.REACT_APP_PROD === "YES"
        ? "https://dashboard.analahinsurance.com/"
        : "https://uatdashboard.analahinsurance.com/";
    case "BAJAJ":
      return "https://www.bajajcapitalinsurance.com/";
    case "UIB":
      return "";
    case "SRIDHAR":
      return "https://uatdashboard.sibinsure.com/";
    case "SHREE":
      return "https://shreeinsure.com/";
    case "POLICYERA":
      return process.env.REACT_APP_PROD === "YES"
        ? "https://dashboard.policyera.com/"
        : "https://uatdashboard.policyera.com/";
    case "TATA":
      return process.env.REACT_APP_PROD === "YES"
        ? "https://lifekaplan.com/"
        : "https://uat.lifekaplan.com/";
    default:
      break;
  }
};

// GST
export const DefaultGSTCondition = (brokerValue) => {
  return ["ABIBL", "ACE", "RB", "OLA", "GRAM", "SPA", "PINC"].includes(
    brokerValue
  )
    ? "Yes"
    : "No";
  // master ON/OFF condition for config
};

// CPA
export const DefaultCPACondition = (brokerValue, odOnly) => {
  return ["ABIBL", "RB", "OLA", "BAJAJ", "SPA", "POLICYERA"].includes(
    brokerValue
  ) && odOnly
    ? "Yes"
    : "No";
};
