import React from "react";
import { Table } from "react-bootstrap";
import Badges from "../Badges";
import { currencyFormater } from "utils";
import { TypeReturn } from "modules/type";
import { BlockedSections } from "modules/quotesPage/addOnCard/cardConfig";

export const DiscountTable = ({ addOnsAndOthers, quote, type, temp_data }) => {
  return (
    <Table className="discountTable">
      <>
        {temp_data?.journeyCategory !== "GCV" && (
          <tr>
            <td className="discountValues">
              {addOnsAndOthers?.selectedDiscount?.includes(
                "Is the vehicle fitted with ARAI approved anti-theft device?"
              ) ? (
                !quote?.antitheftDiscount ? (
                  <Badges title={"Not Available"} />
                ) : (
                  <Badges
                    title={
                      quote?.antitheftDiscount === "" ||
                      quote?.antitheftDiscount === 0
                        ? "Not Available"
                        : `
                          ₹
                          ${currencyFormater(quote?.antitheftDiscount)}
                        `
                    }
                  />
                )
              ) : (
                <Badges title={"Not Selected"} />
              )}
            </td>
          </tr>
        )}
        {TypeReturn(type) !== "cv" &&
          !BlockedSections(process.env.REACT_APP_BROKER).includes(
            "voluntary discount"
          ) && (
            <tr>
              <td className="discountValues">
                {addOnsAndOthers?.selectedDiscount?.includes(
                  "Voluntary Discounts"
                ) ? (
                  !quote?.voluntaryExcess ? (
                    <Badges title={"Not Available"} />
                  ) : (
                    <Badges
                      title={
                        quote?.voluntaryExcess == 0
                          ? "Not Available"
                          : `₹ ${Math.round(quote?.voluntaryExcess)} `
                      }
                    />
                  )
                ) : (
                  <Badges title={"Not Selected"} />
                )}
              </td>
            </tr>
          )}
        {TypeReturn(type) === "cv" && (
          <tr>
            <td className="discountValues">
              {addOnsAndOthers?.selectedDiscount?.includes(
                "Vehicle Limited to Own Premises"
              ) ? (
                quote?.limitedtoOwnPremisesOD ? (
                  <Badges title={`₹ ${quote?.limitedtoOwnPremisesOD}`} />
                ) : (
                  <Badges title={"Not Available"} />
                )
              ) : (
                <Badges title={"Not Selected"} />
              )}
            </td>
          </tr>
        )}

        <tr style={{ display: temp_data?.odOnly && "none" }}>
          <td className="discountValues">
            {addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover") ? (
              quote?.tppdDiscount ? (
                <Badges title={`₹ ${quote?.tppdDiscount}`} />
              ) : (
                <Badges title={"Not Available"} />
              )
            ) : (
              <Badges title={"Not Selected"} />
            )}
          </td>
        </tr>
      </>
    </Table>
  );
};

export const DiscountTable1 = ({ addOnsAndOthers, quote, type, temp_data }) => {
  return (
    <Table className="discountTable">
      <>
        {temp_data?.journeyCategory !== "GCV" && (
          <tr>
            <td className="discountValues">
              {addOnsAndOthers?.selectedDiscount?.includes(
                "Is the vehicle fitted with ARAI approved anti-theft device?"
              ) ? (
                !quote?.antitheftDiscount ? (
                  <Badges title={"Not Available"} />
                ) : (
                  <Badges
                    title={
                      quote?.antitheftDiscount === "" ||
                      quote?.antitheftDiscount === 0
                        ? "Not Available"
                        : `
                    ₹
                    ${currencyFormater(quote?.antitheftDiscount)}
                  `
                    }
                  />
                )
              ) : (
                <Badges title={"Not Selected"} />
              )}
            </td>
          </tr>
        )}

        {TypeReturn(type) !== "cv" &&
          !BlockedSections(process.env.REACT_APP_BROKER).includes(
            "voluntary discount"
          ) && (
            <tr>
              <td className="discountValues">
                {addOnsAndOthers?.selectedDiscount?.includes(
                  "Voluntary Discounts"
                ) ? (
                  !quote?.voluntaryExcess ? (
                    <Badges title={"Not Available"} />
                  ) : (
                    <Badges
                      title={
                        quote?.voluntaryExcess == 0
                          ? "Not Available"
                          : ` ₹ ${Math.round(quote?.voluntaryExcess)} `
                      }
                    />
                  )
                ) : (
                  <Badges title={"Not Selected"} />
                )}
              </td>
            </tr>
          )}
        {TypeReturn(type) === "cv" && (
          <tr>
            <td className="discountValues">
              {addOnsAndOthers?.selectedDiscount?.includes(
                "Vehicle Limited to Own Premises"
              ) ? (
                quote?.limitedtoOwnPremisesOD ? (
                  <Badges title={`₹ ${quote?.limitedtoOwnPremisesOD}`} />
                ) : (
                  <Badges title={"Not Available"} />
                )
              ) : (
                <Badges title={"Not Selected"} />
              )}
            </td>
          </tr>
        )}

        <tr style={{ display: temp_data?.odOnly && "none" }}>
          <td className="discountValues">
            {addOnsAndOthers?.selectedDiscount?.includes("TPPD Cover") ? (
              quote?.tppdDiscount ? (
                <Badges title={`₹ ${quote?.tppdDiscount}`} />
              ) : (
                <Badges title={"Not Available"} />
              )
            ) : (
              <Badges title={"Not Selected"} />
            )}
          </td>
        </tr>
      </>
    </Table>
  );
};
