import React, { useState, useEffect } from "react";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import styled, { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import "./toaster.scss";
import SecureLS from "secure-ls";
import ThemeObj from "modules/theme-config/theme-config";
import _ from "lodash";
toast.configure();

const ls = new SecureLS();
const ThemeLS = ls.get("themeData");
const Theme = !_.isEmpty(ThemeLS) && ThemeLS ? ThemeLS : ThemeObj;

// toast component for edit details toaster

function Toaster({
  callToaster,
  setCall,
  content,
  buttonText,
  setEdit,
  type,
  setToasterShown,
  noButton,
}) {
  const Custom = cssTransition({
    enter: "bounce-bottom",
    exit: "slide-out-left",
  });

  const notify = () => {
    toast(
      <ToastMessageContainer Theme={Theme}>
        {/* <ContactImg
					src={"/assets/images/pointingup.png"}
					alt="pointer"
				></ContactImg> */}
        <ToastMessage>{content} </ToastMessage>{" "}
        {!noButton && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ToastButton
              onClick={() => setEdit(true)}
              className="btnText"
              Theme={Theme}
            >
              {buttonText}
            </ToastButton>
          </div>
        )}{" "}
      </ToastMessageContainer>,
      {
        transition: Custom,
        className: "toasterStyle",
        position: "top-left",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,

        style: {
          position: "relative",
          top: Theme?.QuoteBorderAndFont?.toasterTop
            ? Theme?.QuoteBorderAndFont?.toasterTop
            : "125px",
          left: "70px",
        },
      }
    ); // Default
  };
  useEffect(() => {
    if (callToaster) {
      notify();
      setCall(false);
      setToasterShown(false);
    }
  }, [callToaster]);
  return (
    <div>
      <GlobalStyle Theme={Theme} />
    </div>
  );
}

export default Toaster;

const GlobalStyle = createGlobalStyle`
body {
	.Toastify__progress-bar-theme--light {
		background: ${({ Theme }) =>
      `${
        Theme?.QuoteCard?.color
          ? `${Theme?.QuoteCard?.color} !important`
          : "#bdd400 !important"
      }`};
	}
}
`;

const ToastMessageContainer = styled.div`
  width: 100%;
  font-family: ${({ Theme }) =>
    Theme.regularFont?.fontFamily || " Inter-Medium"};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .toasterStyle {
    .Toastify__progress-bar-theme--light {
      background: ${({ Theme }) =>
        `${
          Theme.QuoteCard?.color
            ? `${Theme.QuoteCard?.color} !important`
            : "#bdd400 !important"
        }`};
    }
  }
`;

const ToastButton = styled.div`
  font-family: ${({ Theme }) =>
    Theme.regularFont?.fontFamily || " Inter-Medium"};
  background-color: ${({ Theme }) => Theme.QuotePopups?.color || "#bdd400"};
  border: ${({ Theme }) => Theme.QuotePopups?.border || "1px solid #bdd400"};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ Theme }) => Theme.QuoteBorderAndFont?.fontColor || "#000"};
  flex-direction: column;
  padding: 5px 10px;
  margin-top: 5px;
  border-radius: ${({ Theme }) =>
    Theme.QuoteBorderAndFont?.borderRadius || "30px"};
  .btnText {
    background-color: ${({ Theme }) => Theme.QuotePopups?.color || "#bdd400"};
  }
`;

const ToastMessage = styled.div`
  font-family: ${({ Theme }) =>
    Theme?.regularFont?.fontFamily || " Inter-Medium"};
  color: black;
`;

const ContactImg = styled.img`
  float: left;
  height: 40px;
`;
